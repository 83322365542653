

<span>
      <input 
        id="{{fieldId}}"
        placeholder="{{oneDate}}"
        name="dp" 
        size=10
        [(ngModel)]="oneDate" 
        (change)="whenChange()"
        (dateSelect)="whenChange()"
        [disabled]="!canEdit"
        [maxDate]="toDate"
        ngbDatepicker
        #d="ngbDatepicker"
        placement="bottom">

        <img 
          id="icon-{{fieldId}}"
          height="20px" 
          src="/frontend/assets/calendar-alt-regular.svg"
          (click)="d.toggle(); beforeChange();" 
          style="cursor: pointer; margin-left: 5px;"
        >
</span>

