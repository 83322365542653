
<!--
This directive receives the following parameters:
    {filtervalues}
    (checksearchfields)
    (updatesearch)
    (removefilter)
    (shownextresults)
    (showprevresults)
    (clearsearch)
    (closeduplmodal)
    dupmsg
    (filterhelp)
    (submitfilters)
    (adminvalidate)
    (addmore)
    (searchresults)
    (downloadsearchresults)
    resp
    flags
    form 
-->
    <div class="multiFilter">
        <div *ngIf="filtervalues && filtervalues.searchFields && (filtervalues.searchFields.length > 0)">
            <b>Search:</b>
            <div class="singleTable">
                <span *ngFor="let filter of filterlist; let fltIndex=index" class="widSf">
                    <single-filter
                        [addmore] = "addmore"
                        [checksearchfields] = "checksearchfields"
                        [filter] = "filter"
                        [flags] = "flags"
                        [filtervalues] = "filtervalues"
                        [filterlist] = "filterlist"
                        [index] = "fltIndex"
                        [lists] = "lists"
                        operators = "operators"
                        path = "path"
                        [removefilter] = "removefilter"
                        updatesearch = "updatesearch"
                        >
                    </single-filter>
                </span>
                <span class="addMore vTop">
                    <button id="addMoreSearchFilters" *ngIf="flags.showAddToSearch" class="btn btn-primary btn-sm boldFont sfBtn" (click)="addmore(filterlist);"><b>Add more search filters</b></button>
                </span>
                <!-- message for duplicate search filters -->
                <div class="row" *ngIf="(dupmsg !== null)">
                    <div class="col-md-6 col-lg-6 col-xl-6">
                        <div class="confirmModal">
                            {{dupmsg}}
                            <button (click)="closeduplmodal(filterlist)" class="btn btn-md" id="confRemDupBtn">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="searchBtn">
            <button class="btn btn-primary sfBtn"
                (click)="searchresults(path, resp)"
                uib-tooltip=""
                id="GET-{{path.replace('/','')}}"
                tooltip-placement="right"
                [disabled]="!form.searchBoxValue">
                <span class="fas fa-search"></span>
                <span class="bgFont">Search {{path}}</span>
            </button>
        </div>
        <div class="mt5 clear">
            <textarea
                id="searchTextArea-{{path.replace('/','')}}"
                type=text
                value=""
                class="searchInput col-md-10"
                [(ngModel)]="form.searchBoxValue"
                (ngModelChange)="adminvalidate(form.searchBoxValue)"
                (keyup)="submitfilters($event, resp)"
                placeholder="select from above or enter your search filters here">
            </textarea>
        </div>
        <table>
            <tr>
                <td colspan=4>
                    <button class="btn btn-primary sfBtn"
                            (click)="clearsearch(filterlist)"
                            uib-tooltip=""
                            id="clearSearchFilter-{{path.replace('/','')}}"
                            tooltip-placement="right"
                            [disabled]="!form.searchBoxValue"> Clear Search Filters

                    </button>
                    <button (click)="filterhelp(path);"
                            class="btn btn-primary sfBtn" id="help-{{path.replace('/','')}}">
                        {{flags.getSearchHelp ? 'No Help' : 'Help'}} 
                    </button>
                </td>
                <td *ngIf="!flags.getSearchHelp && filterrows && filterrows.length > 0" height="35px">
                    <button class="btn btn-primary sfBtn" (click)="downloadsearchresults(filterrows, 'csv')" id="downloadCsv-{{path.replace('/','')}}">Download All (CSV)</button>
                    <button class="btn btn-primary sfBtn" (click)="downloadsearchresults(filterrows, 'json')" id="downloadJson-{{path.replace('/','')}}">Download All (JSON)</button>
                </td>
                <td id="loading-overlay" *ngIf="flags.showSearchResults">
                  Loading...&nbsp;&nbsp; <div class="spinner"></div>
                </td>
            </tr>
        </table>
        <br>
        <table id="searchHelp-{{path.replace('/','')}}" class="clear filterNav" *ngIf="flags.getSearchHelp">
            <tr>
                <td colspan=4 width="800px" bgcolor="#eee">
                    <span *ngFor="let h of flags.help[path] | keyvalue">
                      <b>{{h.key}}</b><br>
                      <span *ngFor="let line of h.value; let aIndex = index">
                          {{line}}<br>
                      </span>
                    </span>
                </td>
            </tr>
        </table>
    </div>

