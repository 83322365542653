import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import {domainAdminModule} from '../../../../../../../../frontend/domainAdminModule';
import {editRecordModule} from '../../../../../../../../frontend/editRecordModule';
import {accountUpdateModule} from '../../../../../../../../frontend/accountUpdateModule';
import {projectAdminModule} from '../../../../../../../../frontend/projectAdminModule';
import { otherAccountsModule } from 'otherAccountsModule';

let t = null;
let scope: any = {};
let http: any = null;
let cookies: any = null;

@Component({
  selector: 'create-other-accounts-form',
  templateUrl: './create-other-accounts-form.component.html',
  styleUrls: ['./create-other-accounts-form.component.css']
})
export class CreateOtherAccountsFormComponent implements OnInit {
  sharedLists = {};
  selectedUnixGroup = '';
  selectedSystem = '';
  updateAccountSubmit = false;
  confirmSaveChecked = false;
  confirmBtn = false;
  privilege = null;
  fieldName = null;
  selected;
  alerts = [];
  authAlerts = [];
  state: any = {httpCallsPending: 0};
  fieldErrors = {};
  arrayFieldErrors = {};
  warnLevels = [];
  pageName = "Create Other Accounts Form";
  openSameServAccUgModal = false;
  servAccUgName = null;
  okToProceed = true;
  mobile : boolean;
  tablet : boolean;
  adminAlerts: any;
  openModal: boolean;

  form = {
    accountCategory: '',
    activationDate: null,
    domainAdmin: null,
    comment: null,
    status: null,
    firstName: '',
    lastName: '',
    preferredName: '',
    accountEmails: [],
    projectList: [],
    accountResources: {UnixGroup:[], System:[]}
  };
  lists = {
    userFavorRecords: []
  };
  flags = {
    createOtherAccount: false,
    delta: false,
    newServiceAcct: false,
    changedIdentity: false,
    hideFormSection: null,
    otherAccountAdmin: null,
    emailMatched: null
  };
  constructor(
    public h: Ub3httpService,
    public commonService: CommonService,
    private c: CookieService,
    private window: Window,
    private router: Router
  ) {
    scope = t = this;
    http = h;
    cookies = c;
    t.state             = commonService.getSharedObject('app','state');
    t.fieldErrors       = commonService.getSharedObject('warning', 'fieldErrors');
    t.arrayFieldErrors  = commonService.getSharedObject('warning', 'arrayFieldErrors');
    t.warnLevels        = Array.from(Array(10).keys());
    t.flags             = commonService.getSharedObject('create-other-accounts', 'flags');
    t.lists             = commonService.getSharedObject('create-other-accounts', 'lists');
  }

  ngOnInit(): void {
      document.body.classList.remove('public-page');
      var urlParams = t.commonService.urlSearchParamsArray();
      var userName = urlParams && urlParams.get('userName');
      var fromPage = urlParams && urlParams.get('fromPage');
      var category = urlParams && urlParams.get('category');
      if(Object.keys(t.flags).length === 0 && Object.keys(t.lists).length === 0){
        otherAccountsModule.init(t, http, http, t.pageName,  t.lists,  t.flags, null);
      }
      if(userName && userName !== null && userName !== 'null' && userName !== ''){
        domainAdminModule.getUserInfo(http, t.flags, t.form, t.lists, userName, t);
        t.flags.reqdFieldsMissing  = false;
      }
      if(category && category !== null && category !== 'null' && category !== ''){
        t.form.accountCategory = category;
        otherAccountsModule.createOtherAccount(http, t.flags, t.lists, category, t);
      }

      editRecordModule.overWriteScope(t);
      if(t.flags.createOtherAccount == true && t.form.accountCategory ==='Service'){
        t.form.shell = '';
        t.form.serviceEmail = '';
      }
      t.validateAccountUpdate();
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.formatDeactDt(t.form);
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
        t.flags.filter = {
          othAcc: {
            username: '',
            dispName: '',
            preferred_name:'',
            primary_email:'',
            sEmail:'',
            account_category:'',
            status:'',
            comment:''
          }
        };
      });
  }
  goToEditProject(pjName){
        t.router.navigate(['/projectEdit'], {queryParams: {name: pjName, opt: 'edit'}});
  }
  setId(item, fieldName, arr){
    t.commonService.onStopCalling(1000, function(){
      var searchArr = [];
      if (fieldName === 'ugId' &&  t.form.accountResources &&  t.form.accountResources.UnixGroup){
        searchArr = t.form.accountResources.UnixGroup;
      } else if(fieldName === 'systemId' &&  t.form.accountResources &&  t.form.accountResources.System){
        searchArr = t.form.accountResources.System;
      }
      var matchFound = [];
      if(searchArr.length > 0){
        matchFound = searchArr.filter(function(fld){
          return fld.resourceName.toLowerCase() === item.toLowerCase();
        });
      }
      var idx = arr.findIndex(function(row){
        return row.name.toLowerCase() === item.toLowerCase();
      });
      if(idx >= 0 && matchFound.length === 0){
        t.form[fieldName] = arr[idx].id;
      } else {
        t.form[fieldName] = '';
        if(fieldName === 'ugId')     t.selectedUnixGroup = '';
        if(fieldName === 'systemId') t.selectedSystem    = '';
        t.adminAlerts = [];
      }
    });
  }

  clearFilter(flags){
    flags.filter = {
      othAcc: {
        username: '',
        dispName: '',
        preferred_name:'',
        primary_email:'',
        sEmail:'',
        account_category:'',
        status:'',
        comment:''
      }
    };
  };
  addOtherAccount(cat){
    t.clearFilter(t.flags);
    t.openSameServAccUgModal = false;
    domainAdminModule.addOtherAccount(http, cookies, t.flags, t.form, t.lists, cat, t);
    t.commonService.doWhenAPICallsDone(function(){
      if(t.adminAlerts.length > 0){t.form.userName = '';}
    });
  };
  adminAddUnixSystem(id, type){
    t.adminAlerts = [];
    domainAdminModule.editUnixSystem(http, cookies, t.flags, t.form, t.lists, Promise, id, type, t);
  };
  adminFavorSync(){
    t.adminAlerts = [];
    domainAdminModule.favorSync(http, t.form, t.flags, t.lists, Promise, setTimeout, t);
  };
  adminSaveRecord(saveOption){
    t.adminAlerts = [];
    if (saveOption == 'cancel'){
      var urlParams = t.commonService.urlSearchParamsArray();
      var prevPath = urlParams.get('fromPage');
      if(prevPath === null) prevPath='createOtherAccounts';
      window.location.hash = '/'+prevPath;
      return;
    }
    t.flags.saveOption = saveOption;
    t.removeEmptyObjs();
    domainAdminModule.saveRecord(t.form, t.flags, t.lists, t.sharedLists, Promise, t);
  }

  removeEmptyObjs(){
    if(t.form && t.form.accountInstitutions){
      t.form.accountInstitutions.forEach(function(instn, index){
        if(instn.method && instn.method === 'post' && !instn.affiliation && !instn.institutionId){
          t.form.accountInstitutions.splice(index,1);
        }
      });
    }
    if(t.form && t.form.accountEmails){
      t.form.accountEmails.forEach(function(eml, index){
        if(eml.method && eml.method === 'post' && !eml.email){
          t.form.accountEmails.splice(index,1);
        }
      });
    }
  }
  confirmAdminPrivilege(priv, fn){
    t.openModal = true;
    t.privilege = priv;
    t.fieldName = fn;
    t.window.scrollTo(0,0);
  };

  closeModal(field, val){
    t.openModal = false;
    t.form[field] = val;
    t.validateAccountUpdate();
  }
  closeOwnerModal(privilege, val){
    t.privilege.owner = val;
    t.openModal = false;
    t.validateList('accountResources', privilege, privilege.id);
  }
  closeMailToAdmin(field, val, userName, project){
    t.adminAlerts = [];
    t.openModal = false;
    t.form[field] = val;
    if(val){
        domainAdminModule.sendMailToAdmin(http, cookies, t.flags, t.form, t.lists, Promise, userName, project, t);
     }
  };

  editOtherAccount(cat, option){
    t.commonService.clearFilterCache();
    domainAdminModule.editOtherAccount(http, cookies, t.flags, t.form, t.lists, cat, t, option);
  };


  selectDefault(row, array){
    t.authAlerts = [];
      array.forEach(function(b){
          b.default = row === b;
      });
  };

  selectPrimaryInstitution(row, array){
    if (!row || !row.affiliation || (row.affiliation == '')){
        return;
    }
    array.forEach(function(b){
        b.primaryInstitution = (row === b);
    });
  };

  selectPrimaryItem(row, array){
    array.forEach(function(b){
        b.primary = (row === b);
    });
  };
  validateList(formName, obj, id) {
    t.authAlerts = [];
    t.commonService.objCopyGuts({}, t.fieldErrors);
    t.commonService.objCopyGuts({}, t.arrayFieldErrors);
    accountUpdateModule.overWriteScope(t);
    t.commonService.onStopCalling(800, function(){
        accountUpdateModule.validateList(formName, obj, id, t);
    });
    t.commonService.doWhenAPICallsDone(function(){
      t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
    });
  };
  getInstitutionId(name){
    return t.commonService.getInstitutionId(name, scope.lists);
  }

  validateAccountUpdate(fldName?) {
    t.commonService.onStopCalling(700, function(){
      editRecordModule.overWriteScope(t);
      t.updateAccountSubmit = false;
      t.confirmSaveChecked = false;
      t.confirmBtn = false;
      t.authAlerts = [];
      editRecordModule.validateAccountUpdate(t.form,t.flags,false,fldName);
      t.commonService.doWhenAPICallsDone(function(){
        if(t.form.email && t.flags.createOtherAccount){t.adminValidate('email');}
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
      });
    });
  };
  checkDeactDate(form){
    form.deactivation_date = (form.accountType === 'Annual') ? t.flags.prevSavedForm.deactivation_date : null;
    t.validateAccountUpdate();
  }

  adminValidate (name) {
    t.adminAlerts = [];
    t.commonService.objCopyGuts({}, t.fieldErrors);
    t.commonService.objCopyGuts({}, t.arrayFieldErrors);
    domainAdminModule.validate(http, setTimeout, t.form, name, t.flags, t.adminAlerts, t);
    t.commonService.doWhenAPICallsDone(function(){
      t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
      t.showAddOtherAcctBtn();
    });
  };

  onNoCountry(obj) {
    accountUpdateModule.onNoCountry(obj, t.form);
  }
  aupLocateByZip (formName, obj, row) {
    t.commonService.locateByZipAup(formName, obj, row, t.form);
  };

  validateInput(fieldName, event, ind){
    t.FindError();
    var errorInput = event.target;
    var fieldID = fieldName+''+ind;
    t.confirmSaveChecked = false;
    t.confirmBtn = false;
    t.alerts = [];
    if(ind){

        if(errorInput.id == fieldID && errorInput.value.length > 0){
           t.removeInputErrors(errorInput);
        }
    }
    else{
        t.removeInputErrors(errorInput);
    }
  }

  autosizeFn(id){
    t.flags.isOnFocus = true;
    var elem = document.getElementById(id);
    if(elem){
      elem.style.overflow = 'hidden';
      elem.style.height = elem.scrollHeight + 'px';
    }
  }
  getSmall(id){
    t.flags.isOnFocus = false;
    var elem = document.getElementById(id);
    if(elem){
      elem.style.height = 'auto';
    }
  }
  countryChange(row){
    t.commonService.onStopCalling(1000, function(){
      t.commonService.onCountryChange(row, t.lists, function(){
        t.validateList('accountPhones', row, row.id);
      });
    });
  }


  FindError(){
    var errorInputElement = document.querySelectorAll('.has-error');
    if(errorInputElement && errorInputElement.length > 0){
        t.confirmSaveChecked = false;
        t.confirmBtn = false;
    }
  }

  removeInputErrors(errorInput){
    var errorInputParentsArr, i, val;
    errorInputParentsArr = [];
    while (errorInput) {
                errorInputParentsArr.unshift(errorInput);
                errorInput = errorInput.parentNode;
                if(errorInputParentsArr.length == 3){
                    break;
                }
    }
    for (i in errorInputParentsArr){
      val = errorInputParentsArr[i];
      if (val.classList.contains('error-section')){
        val.classList.remove('error-section');
      }
    }
  }




  showAddOtherAcctBtn(){
    if(!t.flags.createOtherAccount){return;}
    if(t.flags.emailMatched && t.form.userName && t.form.userName.length >= 3){
        if((t.form.accountCategory === 'Service' && t.okToProceed) && (!t.flags.division && t.form.serviceEmail || t.flags.division === 'cels') && !t.flags.fieldErrors.shell || t.form.accountCategory === 'Test'){return true;}
    } else if(t.form.accountCategory === 'System' && t.form.firstName && t.form.lastName && t.form.preferredName && t.form.email && !t.flags.emailMatched){
        return true;
    } else{
        return false;
    }
  }

  convertUTCtoCDT(d){
    return t.commonService.convertUTCtoCDT(d);
  }



  onInstSelected(selItem,f){
    t.commonService.onStopCalling(1000, function(){
      var selectedInst = t.lists.instActPend.filter(function(item){
        return item.name.toLowerCase() === selItem.toLowerCase();
      });
      if (selectedInst.length === 0){
          delete f['institutionId'];
          delete f['affiliation'];
      } else {
        f.institutionId = selectedInst[0].id;
        f.affiliation = selectedInst[0].name;
      }
      t.validateList('accountInstitutions', f, f.id);
    });
  }

  dialCode(str){
    return t.commonService.getCountryCode(str, scope.lists);
  }
  onStateChange(row){
    t.commonService.onStopCalling(1000, getStAbr);

    function getStAbr(){
      var ss = t.lists.usaStates.filter(function(st){
        return st.name === row.state;
      });
      row.state = ss[0] ? ss[0].abr : '';
      t.validateList('accountAddresses', row, row.id);
    }

  }

  searchUnixGroup = function(text$: Observable<string>){
  return text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.allUnixGroups && t.lists.allUnixGroups.filter(function(item){
          return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        }).map(function(group) {
          return (group.name);
        });
      })
    );
  }
  searchSystem = function(text$: Observable<string>){
  return text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.systems && t.lists.systems.filter(function(item){
          return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        }).map(function(sys) {
          return (sys.name);
        });
      })
    );
  }
  searchAddrCountry = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.countries.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(cnt) {
            return (cnt.name);
          });
        })
      );
  }
  searchAccAddrState = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.usaStates.filter(function(item){
            return item.abr.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(st) {
            return (st.abr, st.name);
          });
        })
      );
  }
  searchAccInstn = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.instActPend.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(inst) {
            return (inst.id, inst.name);
          });
        })
      );
  }
  searchEmplLevel = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.careerLevels.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(lvl) {
            return (lvl.name);
          });
        })
      );
  }
  onEmplLvlSelected(event){
    t.commonService.onStopCalling(1000, function(){
      var scl = t.lists.careerLevels.filter(function(cl){
        return cl.name === event;
      });
      t.form.employment_level = scl[0] ? scl[0].name : '';
      t.validateAccountUpdate();
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
      });
    });
  }
  checkForUnixGroup(userName){
    t.commonService.onStopCalling(1000, function(){
      var ugObj = [];
      t.openSameServAccUgModal = false;
      var p = {search : 'name='+userName};
      http.get('/svc/UnixGroup', {params : p})
          .then(function(response){
              var resp = response.data;
              if(resp && resp.success){
                if(resp.data.length > 0){
                  ugObj = resp.data;
                  if(ugObj.length > 0 && ugObj[0].name.toLowerCase() === userName.toLowerCase()) {
                    t.openSameServAccUgModal = true;
                    t.servAccUgName = ugObj[0].name;
                  }
                }
              }
          });
      t.commonService.doWhenAPICallsDone(function(){
        t.validateAccountUpdate();
      });
    });
  }
  sameServAccUgProceed(val){
    t.okToProceed = val;
    if(!t.okToProceed){
      t.form.userName = '';
      t.openSameServAccUgModal = false;
      t.validateAccountUpdate();
    }
  }

}
