import { Component, OnInit , Input } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AgreementAnlComponent } from './../agreement-anl/agreement-anl.component';
import { AgreementAlcfComponent } from './../agreement-alcf/agreement-alcf.component';
import {CommonService} from '@common/services';

var t = null;
var agreementParams = null;

@Component({
  selector: 'policy-agreement-option',
  templateUrl: './policy-agreement-option.component.html',
  styleUrls: ['./policy-agreement-option.component.css']
})
export class PolicyAgreementOptionComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private comMod: CommonService
  ){ 
        t = this;
        agreementParams = comMod.getSharedObject('agreement', 'params');
  }

  @Input() form: any;    // 'update', 'reactivate', 'dataSurvey', 'accountRequest'
  @Input() onCheckBoxChange: any;    // A callback function

  ngOnInit(): void {
  }

  cbChange(){
    if (t.onCheckBoxChange){
        t.onCheckBoxChange(t.form);
    }
  }

  openAgreement(sz, org){
    setTimeout(function(){
        t.form[ (org == 'anl') ? 'policyAck' : 'policyAlcfAck'] = false;
        if (t.onCheckBoxChange) t.onCheckBoxChange();
    }, 200);
    
    var comp = (org === 'anl') ? AgreementAnlComponent : AgreementAlcfComponent;
    const modalRef = this.modalService.open(comp, { size: sz});
    modalRef.componentInstance.form = t.form;
    modalRef.componentInstance.callback = t.onCheckBoxChange;
  }

}
