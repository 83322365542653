
var accountUpdateModule = (function(){
    var publicStuff;
    var address;

    var cookies, http, http172, scope, timeout, win, GCC ;     // cached services
    cookies = http = http172 = scope = timeout = win = GCC = null;
    var jsServices = {};
    var valMod = null;
    var comMod = null;
    var edrMod = null;
    var acrMod = null;
    var dayjs = null;

    publicStuff = {
        init : init,
        myFavor : myFavor,
        addressChecked : addressChecked,
        updateAccountInfo : updateAccountInfo,
        directingToFavor : directingToFavor,
        getData : getData,
        domainGlobals : domainGlobals,
        accountSystems : accountSystems,
        clearAdress : clearAdress,
        isSaveDisabled : isSaveDisabled,
        onNoCountry : onNoCountry,
        overWriteScope: overWriteScope,
        validateAllFields  : validateAllFields,
        validateAllFieldsNow  : validateAllFieldsNow,
        getMobileToken : getMobileToken,
        setServices : setServices,
        validateList:  validateList,
    };


    // http : the http service. When unit testing, it is the mock http service
    // lists: needed lists by the UI, for example: projects, institutions
    // flags: needed by the UI to know what options to show to the user

    function init(http, http172, flags, form, lists, q, s, alerts){
        scope = s;
        flags.accountUpdate = true;
        flags.delta = null;
        flags.submit = null;
        flags.prevSavedForm = null;
        flags.fieldErrors = {};
        flags.missing = null;
        flags.createUbRequestId = null;
        flags.arrayFieldErrors = {};
        flags.reqMobileCryptoKey = false;
        flags.updFormComplete = false;

        // To avoid delays in validation, initialize it here, so it retrieves 
        // validation rules from backend now.
        // Only when its done, retrieve the account data from backend, 
        // so it gets validated correctly
        valMod.init(comMod.formNames(), readAccountData);   // asynchronous call here

        function readAccountData(){
            // Call yo GETS
            getData(http172, form, flags, lists, null, q, alerts, scope, false, false);
            myFavor(http, flags, form);

            if(!lists.usaStates){
                lists.usaStates = comMod.getUsaStates();
            }
            if(!lists.careerLevels){
                comMod.careerLevels(lists);
            }

            var r = scope.user ? scope.user.role : 'public';

            if(!lists['institutions']){
                if ((r != 'public') && (r != 'limited')){
                    comMod.getInstitutionsList(http, lists);	// this sets up $scope.institutions
                }
            }
            if(!lists.countries){
                if ((r != 'public') && (r != 'limited')){
                    comMod.getCountries(lists);
                }
            }
            domainGlobals(http, lists, flags);

            comMod.doWhenAPICallsDone(function(){
                validateAllFieldsNow(form);
            });
            /*
            doThisLater();

            // do validations only after all the calls to the backend are done
            function doThisLater(){
                if (scope.httpCallsPending > 0){
                   timeout(doThisLater, 200);   // try this 5 times per second
                   return;
                }
                validateAllFields(form);
            }
            */
        }
    }

    /*
    function setServices(c, h, hNew, s, t, w, vm, cm, em, m){
        cookies = c;
        http = h;
        http172 = hNew;
        scope = s;
        timeout = t;
        win = w;
        valMod = vm ? vm : validationModule;    // in angular 1, validationModule is a global
        comMod = cm ? cm : commonModule;        // in angular 1, commonModule is a global
        edrMod = em ? em : editRecordModule;    // in angular 1, editRecordModule is a global
        mom    = m  ? m  : moment;              // in angular 1, moment is a global
    }
    */
    function setServices(services){
        // fixme - should only be included in alcf, should not be packaged with cels src
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http = jsServices.http;
        http172 = jsServices.httpNew;
        scope = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win = jsServices.window;

        comMod = jsServices.commonModule;
        valMod = jsServices.validationModule;
        edrMod = jsServices.editRecordModule;
        acrMod = jsServices.accountReactivateModule;
        dayjs    = jsServices.dayjs;
        GCC  = jsServices.GC;
    }
    function overWriteScope(s){
        scope = s;
    }

    function validateList(formName, obj, id, sc) {
        if(sc) scope = sc;
        var form = scope.form;
        if (!form) return;

        scope.updateAccountSubmit = false;
        if (formName == 'accountInstitutions'){
            form.accountInstitutions = comMod.uniqueUAInstitutions(form.accountInstitutions);
        }
        if (formName == 'projectInstitutions'){
            form.projectInstitutions = comMod.uniqueUAInstitutions(form.projectInstitutions);
            formName = 'accountInstitutions';
            scope.flags.origFormName = 'projectInstitutions';
        }
        comMod.validateList(formName, obj, id, scope.flags, form);
    };

    function validate(form, flags){
        flags.fieldErrors = {};
        flags.submit = false;
        flags.diff = {}
        //flags.delta = false;
        flags.okToSubmit = false;
        valMod.validate('accountUpdate', form, flags.fieldErrors);
        flags.missing = ((valMod.computeMissing(flags.fieldErrors))
            && (valMod.computeMissing(flags.arrayFieldErrors)));
        flags.okToSubmit = !flags.missing;
        // now see if there are any changes to be saved
        //flags.delta = false;

    }

    function allObjects(newForm, flags, form){

        angular.copy(newForm, form);

        if(form.accountAddresses.length == 0){
            form.accountAddresses = [
                {id : form.account.id, label : 'Work'},
                {id : form.account.id, label : 'Shipping'}
            ];
        }

        if(form.usCitizen == false && form.domainGlobals.daysWarn593 <= 60){
            form.myfavButton = true;
        } else{
            form.myfavButton = false;
        }

        if(!newForm.error){
            flags.viewDetails = true;
        } else{
            flags.viewDetails = false;
        }

        flags.prevSavedForm = {};
        angular.copy(newForm, flags.prevSavedForm);
        flags.prevSavedForm = comMod.objCopy(newForm);

    }

    function myFavor(http, flags, lists, form){
        http.get(flags.apiType + "favor").success(function(resp){
            if(resp.success){
                var f = resp.favor[0];
                if(f){
                    flags.newFavorDataNeeded = f.newFavorDataNeeded;
                    flags.expirationDate593 = f.expirationDate593;
                }
            } else{
                flags.newFavorDataNeeded = false;
            }
        }).error(function(data){
            lists.errorAccountUpdate = data.data.detail;
        });
    };

    function addressChecked(http, flags, form, lists){
        if ((typeof angular) == 'undefined'){
            // angular 2+
            // no need to negate value. in ng2+ we used the actual input checkbox element
        }else{
            // angular 1.  
            // in ng1, we use the checkbox icon instead of the actual checkbox input element
            form.addressVal = !form.addressVal;
        }
        copyWorkToShipping(form);
        /*
        var workAddress = form.accountAddresses[0];
        Object.keys(form.accountAddresses[0]).forEach(function(key, val){
            if (form.addressVal && (key != 'label') && (key != 'id') && (key != 'method')){
                form.accountAddresses[1][key] = workAddress[key];
            }
        });
        */
    }
    function copyWorkToShipping(form){
        /*
        This statement below is here just in case someone calls this function 
        in the future without the form parameter.
        */
        if (!form){
            comMod.ub3consoleLog(239, 'form not ready yet');
            return;
        }
        if (!form.addressVal) return;

        var workAddress = form.accountAddresses[0];
        Object.keys(form.accountAddresses[0]).forEach(function(key, val){
            if ((key != 'label') && (key != 'id') && (key != 'method')){
                form.accountAddresses[1][key] = workAddress[key];
            }
        });
    }

    function onNoCountry(obj, form){
        var i, value;
        for (i in form.accountAddresses){
            value = form.accountAddresses[i];
        //angular.forEach(form.accountAddresses, function(value, key){
            if(value.label == obj.label){
                Object.keys(value).forEach(function(k, val){
                    if(k != 'label' && k != 'id' && k != 'country' && k != 'method'){
                        value[k] = '';
                    }
                });
            }
        //});
        }

    }

    function domainGlobals(http, lists, flags){
        http.get("public/domainGlobals", {params : {}})
            .success(function(resp){
                if(resp.success){
                    lists.domainGlobals = resp.domainGlobals;
                }
            }).error(function(data){
            lists.errorAccountUpdate = data.data.detail;
        });
    }

    function accountSystems(http, lists, flags){
        var p = {};
        http.get('/auth/accountSystems', {params : p})
            .success(function(resp){
                if(resp.success){
                    lists.accountSystems = resp.systems;

                } else{

                    flags.systemError = resp.error;
                }

            }).error(function(data){
            lists.errorAccountUpdate = data.data.detail;
        });
    };

    var newProjectVal = [];

    // fixed nesting indent
    function directingToFavor(win, http, form, flags, lists, cookies, timeout, callback){
        var a = null;

        //page  = 'https://apps-test.anl.gov/registration/user_bases/new' ;
        var page = lists.domainGlobals.favorRedirectPath;

        if (!form.projectList || form.projectList.length == 0){
            lists.errorAccountUpdate = 'This user does not have a project';
            return;
        }
       
        if(!flags.reactivateAccount){
            a = comMod.commaDelimitedToArray(form.projectList[0].projectShortName);
            getFavorHostIdAndCreaterequestId();

        } else if(flags.reactivateAccount){
            a = comMod.commaDelimitedToArray(form.projectShortNameList);
            getFavorHostIdAndCreaterequestId();
        }

        function getFavorHostIdAndCreaterequestId(){
            //a[1] = 'more stuff'; 
            var p = {projectShortNameList : a};
            http.get(flags.apiType + "favorHostId", {params : p}).success(function(resp){
                if(resp.success){
                    // we have the FAVOR sponsor id
                    getUbRequestIdAndRedirectToFavor(resp.favorHostId);
                } else{
                    if(flags.reactivateAccount){
                        flags.disableReactivation = true;
                    }
                }
            }).error(function(data){
                lists.errorAccountUpdate = data.data.detail;
            });
        }

        function getUbRequestIdAndRedirectToFavor(favorHostId){
            // call saveForLater, so that usCitizen field gets saved
            // Also, Janet wants favorHost to be part of the form for Foreign Nationals

            if(!flags.reactivateAccount){
                flags.createUbRequestId = 'createUbRequestId';
            } else if(flags.reactivateAccount){
                flags.createUbRequestId = 'reactivateCreateUbRequestId';
            }

            var formCopy = comMod.objCopy(form);
            // // arrays are not copied correctly.
            // formCopy.affiliationList = form.affiliationList;

            formCopy.favorHost = favorHostId;
            var p = {favorHostId : favorHostId,}
            http.post(flags.apiType + flags.createUbRequestId, p)
                .success(function(hresp){
                    //gresp = hresp;
                    if(hresp.success){
                        var ubRequestId = hresp.code;
                        goToFavorUrl(favorHostId, ubRequestId);
                        flags.disableReactivation = false;
                    } else{
                        flags.disableReactivation = true;
                    }
                }).error(function(data){
                if(flags.reactivateAccount){
                    lists.errorAccountUpdate = 'Your session has expired. Please select "Cancel Reactivation Request", and try again.';

                } else{
                    lists.errorAccountUpdate = data.data.detail;
                }

            });
        }

        function goToFavorUrl(favorHostId, ubRequestId){
            //window.sessionStorage.setItem('finalData', form);

            // for issue 1295 : this next block is for the new FAVOR feature where we can prepopulate their form with more info
            var EXTRA_PARAMS = true; // enable this when FAVOR delivers their changes to their prod site  OR to test MOCK with more params
            // if you enable this, then also change the file src/backend/userbase/settings.py
            // look for EXTRA_PARAMS
            if (EXTRA_PARAMS){
                // in production or test site. POST method
                var iform = setFavorFormObject(form);
                iform.sponsor_id = favorHostId;
                iform.ub_request_id = ubRequestId ;
                // this next line redirects the browser to another website with POST method
                //alert('going to favor page '+page);
                comMod.sendForm(page, iform); 
            }else{
                url = page + '?sponsor_id=' + favorHostId + '&ub_request_id=' + ubRequestId;
                //url ="https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage";
                // this next line redirects the browser to another website
                win.location.assign(url);
                if(callback){
                    callback();
                }
            }
        }
        function setFavorFormObject(form){
            var i, e, ai, inst, email;
            inst = email = null;
            // get the primary one from this array
            for (i in form.accountEmails){
                    e = form.accountEmails[i];
                    if (e.primary){
                        email = e.email;
                    }
            }
            // get the primary Institution one from this array
            for (i in form.accountInstitutions){
                    ai = form.accountInstitutions[i];
                    if (ai.primaryInstitution){
                        inst = ai.affiliation;
                    }
            }
            var addr = (form.accountAddresses.length > 0) 
                        ? form.accountAddresses[0]
                        : {};

            if (inst.length > 40) { inst = inst.slice(0,40);}

            return {
                name_first: form.firstName,
                name_mid:   form.nmi ? 'NMI' : (form.middleName ? form.middleName : 'NMI'),
                name_last: form.lastName,
                email_address: email,
                alternate_email_address: form.altEmail ? form.altEmail : '',
                us_citizen: 'N',
                visit_purpose_code: 15,
                favor_created:'N',
                employer_name:  inst,
                employer_addr_ln_1: addr.street1,
                employer_addr_ln_2: addr.street2,
                employer_addr_ln_3: addr.street3,
                employer_addr_city: addr.city,
                employer_addr_state: addr.state,
                employer_addr_zip: addr.zip,
                employer_addr_country: addr.country ? addr.country.toUpperCase() : '',
            };
        }

    }

    function updateAccountInfo(form, flags, lists, q, alerts, beforeRedirect){
        var accepted, fn;

        form.accountEmails.forEach(function(dict){
            if(dict.hasOwnProperty('valid')){
                delete dict.valid;
            }
        });
        var accountUpdated = false;
        if (!beforeRedirect){
         if(flags.newFavorDataNeeded === 'true' && !flags.favorComplete && !flags.isReactivateSurvey){
            alerts.push({
                type : 'danger',
                msg : "Unable to process the request. Please Enter Foreign National Info"
            });
         }
        }
        //check what apis to call based on what changed
        var postMsg = '', putMsg = '';
        edrMod.apisToCall(flags, form);

        //keys with List
        if(flags.putsToCall.length > 0){
            //putMsg = 'Updated';
            edrMod.putRecord(http172, form, flags, lists, cookies, function(response){
                // Callback To display success OR error messages to user on page.
                var resp = response.success;
                if(resp){
                    alerts.push({
                        type : 'success',
                        msg : 'Successfully Updated ' + flags.putsToCall
                    });

                } else{
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });
        }
        if(flags.postsToCall.length > 0){
            edrMod.postRecord(http172, form, flags, lists, cookies, function(response){
                var resp = response.success;
                if(resp){
                    alerts.push({
                        type : 'success',
                        msg : 'Successfully Added ' + flags.postsToCall
                    });

                } else{
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });
        }
        comMod.doWhenAPICallsDone(function(){
            if(flags.error) return;
            // Done sending any KEYS with LIST
            // account not List so handled separate put function

            // we will always call account API on submit
            // 1. timestamp - validationDate
            // 2. Reset PRE-FAVOR after returning from FAVOR
            if(!flags.account){flags.account = {}}
            if(flags.account){
                flags.account.firstName = form.firstName;
                if(!flags.newFavorDataNeeded  || flags.newFavorDataNeeded ==='false'){
                    var rightNowUTC = dayjs.utc(); // get this moment in UTC based on browser
                    flags.account.validation_date = rightNowUTC.format('YYYY-MM-DD HH:mm:ss');
                }
                flags.account.id = form.id;
                if(flags.account.projectShortNameList){
                    // should never exist on this object OR included on account api form
                delete flags.account.projectShortNameList
                }

            }
            if(flags.account.id){
                //flags.account.extraneous_field = 'hello'; // to test that the extraneous fields are filtered out
                //var x = JSON.parse(JSON.stringify(flags.account));
                accepted = [
                    'accountCategory',
                    'accountStatus',
                    'activationDate',
                    'comment',
                    'cryptoKeyOption',
                    'deactivationDate',
                    'employment_level',
                    'firstName',
                    'id',
                    'lastName',
                    'middleName',
                    'nameTitle',
                    'nmi',
                    'orcid',
                    'policyAck',
                    'preferredName',
                    'scopusId',
                    'shell',
                    'usCitizen',
                    'userName',
                    'validation_date',
                ];

                for (fn in flags.account){
                    if (accepted.indexOf(fn) < 0){
                        delete flags.account[fn];
                    }
                }

                edrMod.putAccount(http172, form, flags, lists, cookies, function(response){
                    // Callback To display success OR error messages to user on page.
                    var resp = response.success;
                    if(resp){
                        alerts.push({
                            type : 'success',
                            msg : 'Successfully Updated Account'
                        });
                        // auth/account PUT gets called everytime and called last in order, so call GET on its success
                        // No need check if other PUT/POST APIs were called..
                        // Note: this is different behavior from ADMIN APIs
                        if(!accountUpdated){
                            accountUpdated = true;
                            // if reactivateAccount, getData calls reactivate function and leaves account update view.
                            // else calls GETS refersh for account update and remains on same page
                            // there is no callback used  on main function updateAccountInfo ()
                            //callback();
                        }
                        delete flags.account;

                    } else{
                        alerts.push({
                            type : 'danger',
                            msg : 'Account ' + response.error
                        });
                        delete flags.account;

                    }

                });
            }

            comMod.doWhenAPICallsDone(function(){
                if (beforeRedirect){
                    return; // if user is getting redirected to FAVOR, there is no
                    // point on reading and refreshing data right right now.
                }
                getData(http172, form, flags, lists, null, q, alerts, scope, accountUpdated, beforeRedirect);
            });
        });
    }

    function getData(http172, form, flags, lists, name, q, alerts, scope, accountUpdated, beforeRedirect){

        if(flags.reactivateAccount && accountUpdated){
            // Submit Reactivation was Clicked
            // Account Update POST/PUT was successful on backend OR you wouldn't be here
            // Its safe to call reactivation request now

            // do reactivateRequest if in
            if (!beforeRedirect || scope.isReactivateSurvey){
              scope.alerts = [];
              acrMod.reactivateRequest(scope, http, cookies, scope.form, scope.flags, scope.alerts);
            }
            return;
        } else{
            // Just GET the DATA
            flags.fieldErrors = {};
            flags.arrayFieldErrors = {};
            if (scope.isReactivateSurvey){
                flags.getApiNames = ['account', 'addresses', 'emails', 'institutions', 'phones'];
            }else{
                flags.getApiNames = ['account', 'addresses', 'questions', 'emails', 'institutions', 'phones', 'projects', 'resources', 'unixgroups'];
            }

            if(flags.accountUpdate){
                flags.apiType = 'auth/';
            } 
            if(flags.reactivateAccount){
                flags.apiType = 'limited/';
            }

            edrMod.getRecord(http172, flags, lists, name, function(response){
                var resp = response.success;
                if(resp){
                    // if they checked policyAlcfAck, don't make them select again while on page.
                    if(form.policyAlcfAck){response.policyAlcfAck = form.policyAlcfAck;}
                    // policyAck should be unchecked when page first loads
                    if(!accountUpdated){response.policyAck = false}
                    // if UNKNOWN clear and require user to choose from list
                    if(response.employment_level ==='UNKNOWN'){
                        response.employment_level = '';
                    }
                    if(form.addressVal){response.addressVal = form.addressVal;}  // fix for #3474
                    flags.prevSavedForm = {};
                    //angular.copy(response, form);
                    comMod.objCopyGuts(response, form);
                    //angular.copy(response, flags.prevSavedForm);
                    comMod.objCopyGuts(response, flags.prevSavedForm);

                    if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){
                        edrMod.validateAccountUpdate(form, flags, true);
                        comMod.validateListsAfterGet(flags, form);
                    }
                } else{
                    flags.bootstrapAlert = 'editRecordError';
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });

        }

    }

    // do validation for all fields in the form, including arrays.
    // do not take into account the bottom 3 checkboxes.
    //
    function validateAllFields(form, callback){
        comMod.onStopCalling(700, function(){
            scope.confirmSaveChecked = false;
            scope.confirmBtn = false;
            validateAllFieldsNow(form);
            if (callback) callback();
        });
    }
    function validateAllFieldsNow(form){

        var formNames, ar, i, j, row, formName, ok, skip, id, e;
        // for this to work, call:
        // validateInput(fieldName, event, index)

        copyWorkToShipping(form);
        scope.updateAccountSubmit = false;
        scope.alerts = [];
        scope.flags.missing = null;
        edrMod.validateAccountUpdate(form, scope.flags);
        
        if (scope.flags.fieldErrors.status) delete scope.flags.fieldErrors['status'];
        if (scope.flags.fieldErrors.temp_ub_request_id) delete scope.flags.fieldErrors['temp_ub_request_id'];
        if (scope.flags.reactivateAccount) {
            if (scope.flags.fieldErrors.accountQuestions) delete scope.flags.fieldErrors['accountQuestions'];
        }
        scope.flags.missing = valMod.computeMissing(scope.flags.fieldErrors);

        scope.flags.arrayFieldErrors = {};

        formNames = [  
            'accountEmails', 
            'accountPhones', 
            'accountAddresses',
            'accountInstitutions',
            'accountQuestions'
        ];
        ok = !scope.flags.missing ;
        for (i in formNames){
            formName = formNames[i];
            ar = form[formName];
            
            for (j in ar){
                row = ar[j];
                skip = (row.status === 'Deleted');
                if (!skip) {
                    
                    validateList(formName, row, row.id, scope);
                    ok = ok && (!scope.flags.missing);
                }
            }
        }
        scope.flags.updFormComplete = ok;

        //more special code for DataSurvey
        if (scope.isReactivateSurvey){
            if (!form.comment || (form.comment == '')){
                scope.flags.fieldErrors.comment = {label: 'Country of Citizenship', error: null, required:true, dispRequired: true};
            }
        }

        // these extra steps are for angular 2+.  hasError is used to determine which display class to use
        scope.flags.hasError = {};
        for (id in scope.flags.fieldErrors){
            e = scope.flags.fieldErrors[id];
            
            scope.flags.hasError[id] =  (e && (e.error || e.required) && scope.flags.scrollError);
        }
    }

    function isSaveDisabled(form){
        // if length then validation is called already and can't submit until form is complete
        if(!form.accountQuestions.length){return false; }
        if (scope.flags.accountUpdate && scope.flags.newFavorDataNeeded === 'true'){
            return true;
        }
        if(!form.accountQuestions[0].securityQuestion.length || !form.policyAck){
            return true;
        }
    }

    function clearAdress(labelName, form){

        angular.forEach(form.accountAddresses, function(val, key){
            if(val.label == labelName)
                Object.keys(val).forEach(function(k, value){
                    if(k != 'id' && k != 'label' && k != 'country' && k != 'method'){
                        val[k] = '';
                    }
                });
        });
    }

    function getMobileToken(form, flags, alerts) {
        var preMsg = "You are about to email the Accounts team for a mobile token. Are you sure you want to proceed?";
        if(confirm(preMsg)){
            sendMobileTokenEmail(form, flags, alerts);
        }
    }
    function sendMobileTokenEmail(form, flags, alerts) {
        var c, p, gresp;

        p = {userName : form.userName}
        http.post('/auth/sendMobileTokenEmail', p)
            .success(function(hresp){
                gresp = hresp;
                if(hresp.success){
                    flags.reqMobileCryptoKey = true;
                    var postMsg = "An email has been sent to the Accounts team.";
                    alerts.push({
                        type : 'success',
                        msg : postMsg
                    });
                } else {
                    flags.reqMobileCryptoKey = false;
                    alerts.push({
                        type : 'danger',
                        msg : hresp.error
                    });
                }
            })
            .error(function(data){

            });
    }

    // ----------- public stuff gets returned -----------
    return publicStuff;
})();
module.exports = {accountUpdateModule}
       
