
import { Component, OnInit, Input } from '@angular/core';
import { CommonService, Ub3httpService } from '@common/services';
import { dataCollModule } from "./dataCollModule";

var t: any = null;
var clog = null;
var http = null;

@Component({
  selector: 'data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.css']
})
export class DataCollectionComponent implements OnInit {

  questions = null;
  dataCollectionEnabled = false;
  enableSave = false;
  apiReady = true;
  saveError = null;
  fiscalYear = null;
  toggleHelp = false;
  pageName = '';
  demographicHeading = 'Demographic Data';
  introTxt1 = 'Please complete the following ';
  introTxt2 =' Demographic Data Survey. By providing your demographic information, you are assisting the Department of Energy (DOE) Office of Science’s continued commitment to broadening access and opportunity in sponsored programs and facilities. Alternatively, for information you wish not to disclose, please select, “Do Not Wish to Provide.”';
  helpTxt = 'Your individual demographic information will not be shared and will not be used for any decision-making regarding individual access to the facility. Aggregate, anonymized demographic information will be shared with the DOE Office of Science on a periodic basis, and aggregate, anonymized demographic information may be shared with confidential review committees who are charged to evaluate the facility operations and management.';

  constructor(private comMod: CommonService, ub3http: Ub3httpService) {
    t = this;
    clog = comMod.ub3consoleLog;
    http = ub3http;
  }

  @Input() option: string;
  @Input() callback: any;
  @Input() afterEnableCheck; any;

  ngOnInit(): void {
    dataCollModule.getData(http, t.option, function(data){
        if (data.enabled){
            t.questions = data.questions;
            t.fiscalYear = data.fiscalYear;
            t.afterEnableCheck(true); // so that parent page knows that data collection is on
            t.dataCollectionEnabled = true; // turn on data collection section in this component
            t.getPageName();
        }
    });
  }
  getPageName(){
    var pages = {
      'accountReactivate' : 'Account Reactivation',
      'dataSurvey'        : 'Data Survey',
      'update'            : 'Account Update'
    };
    t.pageName = pages[t.option];
  }
  setApiReady(){
    dataCollModule.setApiReady(t.apiReady);
  }
  onChange(choice){
    var before,after;
    before = choice.checked;
   t.saveError = null;
    // we need to wait here so the angular loop has time to update
    setTimeout(function(){
        after = choice.checked;

        // trick here to change behavior of radio button, if user clicks on a turned-on radio, allow it to be turned off
        if ((before == true) && (after == true)) choice.checked = false;

        t.enableSave = dataCollModule.validate(choice, t.questions);
    }, 100);
  }

  onSave(){
    t.enableSave = false;
    dataCollModule.save(http, t.questions, function(resp){
        // now set the flags correctly
        if (resp.success){
            setTimeout(function(){
                t.afterEnableCheck(false); // so that parent page knows that data collection is off
                t.dataCollectionEnabled = false; // turn off data collection section in this component
            }, 4000);
        }else{
            t.saveError = resp.error;
        }
    });
  }
}
