
<div class="manage-unix-groups">
    <div class="reversed-white">
        <h1>{{flags.displayedPageName}}</h1>
    </div>
    <div class="ub3-card">
        <div class="card-body">
            <!--YOUR UNIX GROUP-->
            <div *ngIf="authAlerts.length > 0">
                <div id="grpAlert" class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index">
                    <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
                        {{ alert.msg }}
                    </ngb-alert>
                </div>
            </div>
            <div *ngIf="!flags.hideManageUnixGroups" class="page-heading">
                <table class="table group-table">
                    <thead>
                        <th colspan="12">
                            <h4 class="table-heading">Your UNIX Groups</h4>
                        </th>
                    </thead>
                    <tbody>
                        <tr colspan="12">
                            <td colspan="12">
                                <span>
                                    Below is a list of your current UNIX group memberships on ALCF systems. 
                                    To view the UNIX group details, click on the group name. 
                                    If you are the owner of any groups, you will be able to perform additional 
                                    administrative tasks.<br><br>
                                    If you make adjustments to your UNIX groups and have an open 
                                    session on a computing system, you will need to log out and back 
                                    in on that system for the change to take place.</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="user-records-table smooth-scroll" id="user-records-table">
                    <table class="table group-table">
                        <tr>
                            <ngx-datatable
                                #pjTable
                                id="pjTable"
                                class="bootstrap card expandable medium-font"
                                columnMode="flex"
                                [rowHeight]="50"
                                [headerHeight]="50"
                                [footerHeight]="50"
                                [scrollbarH]="true"
                                [rows]="form.manageYourUnixGroups">
                                <ngx-datatable-row-detail [rowHeight]="auto">
                                    <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-row-detail-template>
                                        <div class="grey-background group-view">
                                            
                                            <div *ngIf="authAlerts.length > 0 && flags.currGrpId === form.ugRow.id" class="alert-section">
                                                <div id="rowAlert" *ngFor="let alert of authAlerts; let bIndex = index">
                                                    <ngb-alert [type]="alert.type" (close)="closeAlert(bIndex)">
                                                        {{ alert.msg }}
                                                    </ngb-alert>
                                                </div>
                                            </div>
                                            <div class="row-heading">
                                                <ul class="list-unstyled list-name">
                                                    <li>Accessibility: <span>{{form.public == true?'Public':'Private'}}</span></li>
                                                    <li>UNIX Members</li>
                                                </ul>
                                                <ngx-datatable
                                                    #unixGroup
                                                    id="unixGroup"
                                                    class="bootstrap card expandable medium-font"
                                                    columnMode="flex"
                                                    [rowHeight]="50"
                                                    [headerHeight]="50"
                                                    [footerHeight]="50"
                                                    [scrollbarH]="true"
                                                    [rows]="form.users"
                                                    [sorts]="[{prop: 'userName', dir: 'asc'}]">
                                                    <ngx-datatable-column name="Username" prop="userName" [flexGrow]=".6">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide" id="username_{{row.userName}}">{{row.userName}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Preferred Name" prop="preferredName" [flexGrow]="1">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide">{{row.preferredName}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Email" prop="email" [flexGrow]="1" *ngIf="flags.groupOwner">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide">{{row.email}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Account" prop="status" [flexGrow]=".5" *ngIf="flags.groupOwner">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide" id="{{row.status}}_{{row.userName}}">{{row.status}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Owner" prop="owner" [flexGrow]=".5">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide">
                                                                <div *ngIf="flags.groupOwner" class="cBox" (click)="row.owner = row.owner ? false:true; flags.currGrpId = form.ugRow.id; validateList('manageUnixGroups', form.ugRow, form.ugRow.id);">
                                                                    <span id="checkOwner_{{row.userName}}" class="cr">
                                                                        <i 
                                                                            id="own-{{row.userName}}"
                                                                            *ngIf="row.owner"  
                                                                            class="fas fa-check checkmark">
                                                                        </i>
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="!flags.groupOwner">
                                                                    <span class="cr">
                                                                        <i *ngIf="row.owner"  class="fas fa-check checkmark"></i>
                                                                        <i *ngIf="!row.owner" class="fas fa-times removeIcon"></i>
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Membership" prop="memberStatus" [flexGrow]=".5" *ngIf="flags.groupOwner">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span *ngIf="!row.hide">{{row.memberStatus}}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column [sortable]='false' [flexGrow]=".5" *ngIf="flags.groupOwner">
                                                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                            <span  *ngIf="!row.hide"
                                                                (click)="flags.currGrpName = form.ugRow.resourceName; flags.currGrpId = form.ugRow.id; manageUnixGroupsEdit(row.id,'deleteMember','edit',form.users.indexOf(row));" id="delete_{{row.userName}}">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </span>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>
                                            </div>
                                            <div class="row-heading mtl">
                                                <div colspan="12" *ngIf="flags.groupOwner">
                                                    <div class="addAdditional" (click)="flags.showAddUser=!flags.showAddUser; matchUsers(filterStr, false)">
                                                        <span class="fa add-additional" [ngClass]="flags.showAddUser ? 'fa-minus-circle' : 'fa-plus-circle'" id="addUser">
                                                        </span>
                                                        <span class="addUser">{{flags.showAddUser ? 'Hide list of other users' : 'Add new user'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-heading">
                                                <table class="table showAddUserDetails addUser" *ngIf="flags.showAddUser && flags.groupOwner">
                                                    <tr>
                                                        <td colspan="12" class="filter-desc">
                                                            Filter the list of users by entering username, first name, last name, or
                                                            preferred
                                                            name:
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="12">
                                                            <input type="text" class="form-control"
                                                                    [(ngModel)]="filterStr"
                                                                    id="filterUserUnixGroup"
                                                                    placeholder="Filter Users"
                                                                    (ngModelChange)="matchUsers(filterStr, false)">
                                                            <button class="btn btn-primary btn-md" id="filterButton">
                                                                FILTER
                                                            </button>
                                                        </td>
                                                    </tr>
                                            <!--  </table>
                                                <table class="table addUserTable" style="background-color: white" *ngIf="flags.showAddUser && lists.matchedUsers && lists.matchedUsers.length > 0">-->
                                                    <tr>
                                                        <td colspan="12">
                                                            <ngx-datatable
                                                                #pjau
                                                                id="pjau"
                                                                class="bootstrap card expandable medium-font"
                                                                columnMode="flex"
                                                                [rowHeight]="50"
                                                                [headerHeight]="50"
                                                                [footerHeight]="50"
                                                                [scrollbarH]="true"
                                                                [rows]="lists.matchedUsers"
                                                                [sorts]="[{prop: 'userName', dir: 'asc'}]"
                                                                *ngIf="flags.showAddUser && lists.matchedUsers && lists.matchedUsers.length > 0">
                                                                <ngx-datatable-column name="Username" prop="userName" [flexGrow]=".5">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.userName}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="First<br>Name" prop="firstName" [flexGrow]=".5">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.firstName}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="Last<br>Name" prop="lastName" [flexGrow]=".5">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.lastName}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column name="Preferred<br>Name" prop="preferredName" [flexGrow]="1">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.preferredName}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>

                                                                <ngx-datatable-column name="Email" prop="email" [flexGrow]="1">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.email}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>

                                                                <ngx-datatable-column name="Account<br>Status" prop="status" [flexGrow]=".5">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <span>{{row.status}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                                <ngx-datatable-column [sortable]='false' [flexGrow]="1">
                                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                                        <button class="btn btn-primary btn-sm"
                                                                        *ngIf="!form.unixGroupIndex[row.userName] && row.status ==='Active'"
                                                                        (click)="addAUserToUgrp(row, form); flags.currGrpId = form.ugRow.id;" id= "add_{{row.userName}}">
                                                                            ADD
                                                                        </button>
                                                                        <span>{{displayMemberStatus(form.unixGroupIndex[row.userName],row.status)}}</span>
                                                                    </ng-template>
                                                                </ngx-datatable-column>
                                                            </ngx-datatable>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-row-detail>
                                <ngx-datatable-column name="Group Name" prop="resourceName" [flexGrow]="1" class="reso-name">
                                    <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                                        <a 
                                            (click)="form.ugRow = row; manageUnixGroupsView(row, form.manageYourUnixGroups.indexOf(row), expanded)"
                                            id="{{row.resourceName}}" 
                                            class="reso-name">
                                        {{row.resourceName}}
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Owner" prop="owner" [flexGrow]=".6">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{row.owner == true?'Yes':'No'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Default" prop="pi" [flexGrow]=".6">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <div *ngIf="row.default">
                                            <input class="styled-checkbox"
                                                    [(ngModel)]="row.default"
                                                    [disabled]="row.default"
                                                    id="styled-checkbox-{{row.resourceName}}"
                                                    type="checkbox" checked>
                                            <label for="styled-checkbox-{{row.resourceName}}"></label>
                                        </div>
                                        <div *ngIf="!row.default">
                                            <input class="styled-checkbox"
                                                    [(ngModel)]="row.default"
                                                    id="styled-checkbox-{{row.resourceName}}"
                                                    type="checkbox"
                                                    (click)="selectDefault(row, form.manageYourUnixGroups);validateList('manageUnixGroups', row, row.id); flags.currGrpId = row.id;flags.isYourGroupOpen = {};">
                                            <label for="styled-checkbox-{{row.resourceName}}"></label>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [flexGrow]=".6" [sortable]='false'>
                                    <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                        <div colspan="2">
                                            <button
                                                    class="btn btn-danger btn-xs"
                                                    (click)="flags.currGrpId = row.id; manageUnixGroupsEdit(row.id,'remove','edit',form.manageYourUnixGroups.indexOf(row));flags.groupOwner = row.owner;" id="{{row.resourceName}}_delete">
                                                REMOVE
                                            </button>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </tr>
                        <tr *ngIf="flags.okToSubmit">
                            <td colspan="12" class="centerText">
                                <button class="btn btn-success btn-md"
                                        (click)="saveUnixGroupChanges()" id="saveUnixGroup">SAVE CHANGES
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <!--FIND UNIX GROUP-->
                <div class="user-records-table search">
                    <table class="table group-table">
                        <thead>
                            <th colspan="12">
                                <h4 class="table-heading">Find a UNIX Group to Join</h4>
                            </th>
                        </thead>
                        <tbody>
                            <tr colspan="12">
                                <td colspan="12">
                                    <h5>Search for UNIX group:</h5>
                                    <div class="edit-group">
                                        <input type="text"
                                                class="form-control pl10"
                                                id="project_Search"
                                                [(ngModel)]="selectedGroupName"
                                                placeholder="UNIX Group Search"
                                                autocomplete="nope"
                                                [ngbTypeahead]="searchUG"
                                                (ngModelChange)="selectNewUG(selectedGroupName)">

                                        <button [disabled]="!flags.ugSearch || !form.selectedGroup" class="btn btn-primary btn-sm"
                                                (click)="manageUnixGroupsSearch(form.selectedGroup.id)" id="searchUnixGroup"
                                                uib-tooltip="">SEARCH
                                        </button>
                                    </div>
                                    <div class="group-search-results" *ngIf="form.selectedGroup">
                                        <ul *ngFor="let group of lists.groupSearch" class="list-unstyled list-name">
                                            <li id="{{group.approval?'Requires_Approval':'No_Approval_Required'}}">Group Access : {{group.approval?'Requires Approval':'No Approval Required'}}</li>
                                            <li>Group Name : {{group.name}}</li>
                                            <li>Owner :
                                                <ul style="display:inline-block" class="list-inline" *ngFor="let f of group.owners"  [ngClass]="{'multiple-owners': group.owners.length > 1}">
                                                    <li>{{f.name}}</li>
                                                </ul>
                                            </li>
                                            <li *ngIf="group.approval">If you need to become a group member, please email the owner or <a href="mailto:{{domainGlobals.helpDeskEmail}}">{{domainGlobals.helpDeskEmail}}</a></li>
                                            <li *ngIf="!group.approval">
                                                <button class="btn btn-success btn-md" id="joinUnixGroup"
                                                [disabled]="flags.joinedUnixGroup"
                                                (click)="manageUnixGroupsEdit(group.id, 'addYourMembership', 'edit'); selectedGroupName='';">JOIN UNIX GROUP
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>