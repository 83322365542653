

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {CommonService } from '@common/services';

var t = null;
var functions = null;
var ub3consoleLog = null;

@Component({
  selector: 'select-projects',
  templateUrl: './select-projects.component.html',
  styleUrls: ['./select-projects.component.scss']
})
export class SelectProjectsComponent implements OnInit {

  pjBy = 'pjName';  // default search by project short name
  pjSelect1 = '' ;
  pjSelect2 = '' ;
  pjSelect3 = '' ;
  selectedPjs = [];
  maxReached = false;

  constructor( private commonService: CommonService) { 
    t = this;
    ub3consoleLog = t.commonService.ub3consoleLog;
    functions = t.commonService.getSharedObject('select-projects', 'functions');
    functions['setSelectedProjects'] = t.setSelectedProjects;
  }

  @Input() form: any;
  @Input() field: string;
  @Input() projects: any;
  @Input() max: number;
  @Input() after: any;  // a callback function


  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }
  // this funciton gets called from accountRequestModule.js
  setSelectedProjects() {
    t.selectedPjs = (t.form && t.field && t.form[t.field]) ? t.form[t.field].split(', ') : [];
    t.maxReached = (t.selectedPjs && t.selectedPjs.length === t.max);
  }
  oneSelected(e){
    var s, name;
    name = e.item.split(':')[0];
    if (t.max == 1){
      s = name;
      t.form[t.field] = s;
      if (t.after){
        t.after();
      }
    }
    if (t.max > 1){
        if (t.selectedPjs.indexOf(name) < 0){
            // if the selected project is already in the list, do nothing
            // otherwise, Add it
            t.selectedPjs.push(name);
        }
        t.updateString();

        if (t.selectedPjs && (t.selectedPjs.length > 0)){
           // user has selected at least one
           if (t.after){
               t.after(t.selectedPjs);
           }
        }
    }
  }
  done(){
        t.after();
  }
  updateString(){
        var s, i, ii;
        s = '';
        for (i in t.selectedPjs){
            ii = parseInt(i);
            s = (s + t.selectedPjs[ii]);
            if (ii < (t.selectedPjs.length -1)) s = s + ', ';
        }
        t.form[t.field] = s;
        setTimeout(function(){
            t.pjSelect1 = t.pjSelect2 = t.pjSelect3 = '';
            t.maxReached = (t.selectedPjs && t.selectedPjs.length === t.max);
        },200);
  }
  rmFromSelected(pjName){
        var index = t.selectedPjs.indexOf(pjName);
        if (index >= 0){
            t.selectedPjs.splice(index, 1);
        }
        t.updateString();
        if (t.after){
            t.after(t.selectedPjs);
        }
  }

  //ngbTypeahead gets list of matches
  fnSelByPjName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 
        ? []
        : matchProjects(term, 'name', t.projects))
  );
   

  //ngbTypeahead gets list of matches
  fnSelByPIName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 
        ? []
        : matchProjects(term, 'pi', t.projects))
  );

  //ngbTypeahead gets list of matches
  fnSelByTitle = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 
        ? []
        : matchProjects(term, 'title', t.projects))
  );



}

function matchProjects(str, fdName, projects){
  var i, obj, postfix, result;
  result = [];
  for (i in projects){
    obj = projects[i];
    if (obj && obj[fdName] && obj[fdName].toLowerCase().indexOf(str.toLowerCase()) >= 0){
      postfix = '';
      if (fdName == 'pi'   ) postfix = ': ' + obj.pi;
      if (fdName == 'title') postfix = ': ' + obj.title;
      result.push(obj.name + postfix);
    }
  }
  return result;
}
