
import {Component, OnInit, ViewChild} from '@angular/core';
import {svcApiModule} from 'svcApiModule';
import {validationModule} from 'validationModule';
import { domainAdminModule as domainAdminModuleAlcf } from '../../../../../../../../frontend/domainAdminModule';
import { domainAdminModule as domainAdminModuleCels } from '../../../../../../../../frontend/cels/js/domainAdminModule';
import {multiFilterModule} from '../../../../../../../../frontend/multiFilterModule';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

let scope: any = {
    publicPage: function (b) {
    }, // dummy function
    sortSvcInfo: {},
    lists: {svcOut: {}, rows: {}},
    urls: {},
    flags: {openSection: {}, filters: {}, send: {}, showSearchResults:false, dupWarnMsg: null},
    form: {},
    forms: {},
    all: {},
    yesno: {},
    vRules: {},
};
let t = null;   // pointer to this(instantiated object of class SvcApiComponent)
let http = null;
var clog = null;
let state = null;

@Component({
    selector: 'svc-api',
    template: '<json-editor [options]="editorOptions" [data]="data"></json-editor>',
    templateUrl: './svc-api.component.html',
    styleUrls: ['./svc-api.component.css']
})
export class SvcApiComponent implements OnInit {

    // these objects are available to the template
    someForm = {date: {}};
    someKey = 'date';
    form = null;
    forms = null;
    flags = {abbreviate: true, specJson: false
    };
    apiFunctions = [];
    apiFunctionColumns = [];
    v9rules = null;
    vRules = {};
    lists = null;
    all = null;
    aCopy = null;
    yesno = null;
    format = "mediumDate";
    resp = {response: {showIt: false, data: {length: 0}}};  // response from a PUT or POST
    method = null;
    sampleDate = '';
    rowsArr = [];
    func = null;
    colDispSettings = false;
    colsToDisplay = [];
    colCount = 0;
    colDispCount = 0;
    enableFormSubmitButton = false;
    jsonObjs = {};
    //public editorOptions: JsonEditorOptions;
    @ViewChild(JsonEditorComponent, {static:false}) editor: JsonEditorComponent;

    constructor(
        h: Ub3httpService,
        private window: Window,
        private commonService: CommonService,
        private cookies: CookieService
    ) {
        t = this;
        http = h;
        clog = t.commonService.ub3consoleLog;
        scope.flags.initialRowLimit = 100;
        state = t.commonService.getSharedObject('app', 'state');
    }
    ngOnInit(){
        if (state.domain == 'alcf') validationModule.init(t.commonService.formNames());
        if (state.domain == 'cels') validationModule.init(t.commonService.formNamesCels());
        scope.flags.dupWarnMsg = null;

        svcApiModule.init(scope, 'Other Screens', function () {
            var method: any, funcDef: any, formName: string, arrayOfParameterNames: any;
            var path: string;
            var obj: any;
            t.vRules = scope.vRules;
            t.lists = scope.lists;
            t.flags = scope.flags;
            t.forms = scope.forms;
            t.flags.arrayFieldErrors = t.commonService.getSharedObject('WarningSvcComponent', 'arrayFieldErrors');
            t.all = scope.all;
            t.yesno = scope.yesno;
            t.form = scope.form;
            t.flags.download = false;

            // convert to angular 9 template needs - Angular 9 templates are a little bit more stupid than in version 1
            // angular9 *ngFor wants arrays, not dictionaries
            var crud = {'POST': 'Create', 'GET': 'Search', 'PUT': 'Update', 'DELETE': 'Delete'};
            for (path in scope.urls.api_svc) {
                obj = scope.urls.api_svc[path];
                obj.methods = [];
                for (method in obj.params) {
                    arrayOfParameterNames = obj.params[method];
                    obj.methods.push({
                        name: method,
                        crud: crud[method],
                        params: arrayOfParameterNames
                    })
                    obj.name = obj.func;
                    // angular 9 templates are dumb,  they cannot create storage
                    if (!t.flags.openSection) t.flags.openSection = {};
                    if (!t.flags.openSection[path]) t.flags.openSection[path] = {};
                    t.flags.openSection[path][method] = false;
                    // more dumb stuff
                    if (!t.flags.arrayFieldErrors) t.flags.arrayFieldErrors = {};
                    if (!t.flags.arrayFieldErrors[path]) t.flags.arrayFieldErrors[path] = {};
                    t.flags.arrayFieldErrors[path][method + '_more'] = {missing: null};
                    // more dumb stuff
                    if (!t.flags.showHelp) t.flags.showHelp = {};
                    if (!t.flags.showHelp[path]) t.flags.showHelp[path] = false;
                }
                if (t.flags.svcExclude.indexOf(path) < 0) {
                    t.apiFunctions.push(obj);
                }
            }

            // now break it up into columns of 10 rows each
            var i, r, rows, cols;
            for (i = 0, r = 0, cols = [], rows = []; i < t.apiFunctions.length; i++) {
                rows.push(t.apiFunctions[i]);
                r++;
                if (r >= 10) {
                    r = 0;
                    cols.push(rows);
                    rows = [];
                }
            }
            if ((r < 10) && (rows.length > 0)) {
                cols.push(rows);
            }
            t.apiFunctionColumns = cols;

            // more conversion to angular 9
            t.v9rules = {};
            for (formName in t.vRules) {
                var formRulesObj = t.vRules[formName];
                var fieldName: string, rules: any, formRules: any = [];
                for (fieldName in formRulesObj) {
                    rules = formRulesObj[fieldName];
                    formRules.push({name: fieldName, rules: rules});
                }
                ;
                t.v9rules[formName] = formRules;
            }

        });

        document.body.classList.remove('public-page');
        t.rows2display = t.flags.initialRowLimit;
    }

    getRules(path, formName, vRules) {

        svcApiModule.getRules(formName, vRules);

        // more conversion to angular 9
        t.v9rules = {};

        svcApiModule.getChoices(path, vRules[formName], scope.lists, function(){

            var formRulesObj = t.vRules[formName];
            var fieldName: string, rules: any, formRules: any = [];
            for (fieldName in formRulesObj) {
                rules = formRulesObj[fieldName];
                formRules.push({name: fieldName, rules: rules});
            }
            t.v9rules[formName] = formRules;
        });

    };

    clearSearch(filterlist) {
      multiFilterModule.clearSearch(scope.flags, scope.form, scope.lists, filterlist);
    }
    filterHelp = function(path) {
      scope.flags.getSearchHelp = !scope.flags.getSearchHelp;
      scope.flags.showEmptyResults = false;
      svcApiModule.getHelp(scope.flags, true, path);
    }
    addMore(filterlist){
      multiFilterModule.addMore(filterlist, scope.flags);
    }
    removeFilter(index, filterlist) {
      multiFilterModule.removeFilter(index, scope.form, scope.lists, scope.flags, filterlist);
    }
    downloadSearchResults = function(filterrows, fileType) {
      multiFilterModule.downloadSearchResults(scope.lists, scope.flags, filterrows, fileType);
    }

    clickFunction(path, formName, row){
        var i, m;
        t.func = row;
        t.func.canEdit = false;
        for (i in t.func.methods){
            m = t.func.methods[i];
            if (m.name == 'PUT') t.func.canEdit = true;
        }
        t.method = null;

        t.getRules(t.func.path, t.func.formName, t.vRules);
        t.clearSearch(t.flags.filters[t.func.path]);
        t.flags.abbreviate = true;
    }

    //clickMethodSection(formName, path, method) 
    clickMethodSection(methodObj, formName, path){

        scope.flags.dupWarnMsg = null;
        t.method = methodObj;
        var method = t.method.name;     // a string
        t.flags.specJson = false;

        var m;
        var formRules = t.vRules[formName];

        scope.resp = {response: null};
        //turn off all the method secions
        for (m in scope.flags.openSection[path]) {
            scope.flags.openSection[path][m] = false;
        }
        // turn on the clicked one
        scope.flags.openSection[path][method] = true;
        scope.lists.svcOut[path] = null;
        scope.lists.rows[path] = {all: [], columns: []};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.flags.send) scope.flags.send = {};
        if (!scope.flags.send[path]) scope.flags.send[path] = {};
        if (!scope.flags.send[path][method]) scope.flags.send[path][method] = {};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.lists.searchValues) scope.lists.searchValues = {};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.lists.filterValues) scope.lists.filterValues = {};
        if (!scope.lists.filterValues[path]) scope.lists.filterValues[path] = {searchFields: []};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.flags.filters) scope.flags.filters = {};
        if (!scope.flags.filters[path]) scope.flags.filters[path] = [];

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.flags.isNull) scope.flags.isNull = {};
        if (!scope.flags.isNull[path]) scope.flags.isNull[path] = {};
        if (!scope.flags.isNull[path][method]) scope.flags.isNull[path][method] = {};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.flags.isBlank) scope.flags.isBlank = {};
        if (!scope.flags.isBlank[path]) scope.flags.isBlank[path] = {};
        if (!scope.flags.isBlank[path][method]) scope.flags.isBlank[path][method] = {};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.flags.arrayFieldErrors) {
            // this is not supposed to happen, halt
            return;
        }
        if (!scope.flags.arrayFieldErrors[path]) scope.flags.arrayFieldErrors[path] = {};
        if (!scope.flags.arrayFieldErrors[path][method]) scope.flags.arrayFieldErrors[path][method] = {};
        var errs = scope.flags.arrayFieldErrors[path][method];
        var fid;
        var firstFieldName = null;
        for (fid in formRules) {
            errs[fid] = {};
            if (!firstFieldName) firstFieldName = fid;
        }

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.yesno) scope.yesno = {};
        if (!scope.yesno[path]) scope.yesno[path] = {};
        if (!scope.yesno[path][method]) scope.yesno[path][method] = {};
        if (!scope.yesno[path][method].form) scope.yesno[path][method].form = {};

        // more dumb stuf here.  angular 9 templates want everything initialized
        if (!scope.all) scope.all = {};
        if (!scope.all[path]) scope.all[path] = {};
        if (!scope.all[path][method]) scope.all[path][method] = {};
        if (!scope.all[path][method].form) scope.all[path][method].form = {};

        svcApiModule.clickMethodSection(path, method, formName);
        if ((method == 'POST') || (method == 'PUT')){
            t.svcValidate(path, method, formName, t.form, firstFieldName);
        }
        t.mkJsonObjs(formName, path, method);
    };

    svcRequest(method, path, data, resp) {
        var formName = scope.urls['api_svc'][path].formName;
        var rules = scope.vRules[formName];
        var c3 = {
            send:   scope.flags.send   [path][method], 
            snull:  scope.flags.isNull [path][method], 
            sblank: scope.flags.isBlank[path][method]
        } ;
        svcApiModule.request(rules, method, path, data, resp, scope.lists, c3, function(success){
            if ((method == 'PUT') || (method == 'POST')){
                if(success) t.enableFormSubmitButton = false;
            }
            if(method === "PUT" && path === "svc/Domain" && data){
                data.form && data.form.system_message && t.commonService.addSystemMessage(data.form.system_message);
            }
        });
    }

    svcValidate(path, method, formName, form, fieldName, col3, e) {

        svcApiModule.validate(path, method, formName, form, fieldName, col3, function(){
            if (t.flags.sideJson){
                t.aCopy[path][method] = t.commonService.objCopy(t.all[path][method]);
                t.jsonStrings2jsonObjs(t.aCopy[path][method].form, t.v9rules[formName]);
                t.aCopy[path][method].form = t.commonService.sortOnKeys(t.aCopy[path][method].form, false);
            }
            if ((method == 'PUT') || (method == 'POST')) {
                t.enableFormSubmitButton = t.flags.arrayFieldErrors[path][method + '_more'].okToSend ;
                t.mkJsonObjs(formName, path, method);
            }
        });
    }
    jsonStrings2jsonObjs(formCp, defs){
        var i, key, value, j, fieldRules, label;
        for (i in defs){
            key = defs[i].name ;
            fieldRules = defs[i].rules;
            label =   fieldRules.label.toLowerCase();
            value = formCp[key];
            if ((label.indexOf('json') >= 0) && value && (typeof(value) == 'string')){
                if (t.isValidJSON(value)){
                    j = JSON.parse(value);
                }else{
                    j = 'invalid JSON string'
                }
                formCp[key] = j;
            }
        }
    }
    isValidJSON(str){
            try {
              JSON.parse(str);
              return true;
            } catch (e) {
              return false;
            }
    };

    toggleSideJson(path, method, formName){
        if (!t.flags.sideJson){
            if (!t.aCopy      ) t.aCopy       = {}; 
            if (!t.aCopy[path]) t.aCopy[path] = {};
            t.aCopy[path][method] = t.commonService.objCopy(t.all[path][method]);
            t.aCopy[path][method].form = t.commonService.sortOnKeys(t.aCopy[path][method].form, false);
            t.jsonStrings2jsonObjs(t.aCopy[path][method].form, t.v9rules[formName]);
            t.aCopy[path][method].form = t.commonService.sortOnKeys(t.aCopy[path][method].form, false);
        }
        t.flags.sideJson =  ! t.flags.sideJson ;
    }

    svcInitPutForm(path, formName, oneRow, methods, methodName) {
        var i, oneRowSimple, fn;
        for (i in methods) {
            if (methods[i].name == methodName) {
                t.method = methods[i];
            }
        }
        oneRowSimple = {};
        for (fn in oneRow) {
            oneRowSimple[fn] = oneRow[fn].val;
        }
        svcApiModule.initPutForm(path, formName, oneRowSimple);
    }
    svcInitPutForm2(path, formName, oneRow, methods, methodName) {
        var i, id, originalRow, tableName;
        var form, key, rules;
        for (i in methods) {
            if (methods[i].name == methodName) {
                t.method = methods[i];
            }
        }
        // extract the id out of oneRow
        id = oneRow.id;
        tableName = path + '-GET';
        // get the original row, before the column filtering
        originalRow = svcApiModule.getOriginalRow(tableName, id);
        svcApiModule.initPutForm(path, formName, originalRow);
        t.v9rules[formName] = t.commonService.sortArrayOnKey(t.v9rules[formName], 'name', false);

        t.mkJsonObjs(formName, path, methodName);
    }
    mkJsonObjs(formName, path, method){
        var form, i, key, rules;
        var form = t.all[path][method].form;
        t.jsonObjs = {};

        for (i in t.v9rules[formName]){
            key = t.v9rules[formName][i].name;
            rules=t.v9rules[formName][i].rules;
            if (rules.label.toLowerCase().indexOf('json') >= 0){
                t.jsonObjs[key] = t.isValidJSON(form[key]) ? JSON.parse(form[key]) : '';
            }
        }
    }
    mkJsonOptions(){
        var p = new JsonEditorOptions();
        p.modes = ['code', 'text', 'tree', 'view'];
        return p;
    }
    getData(e, path, method, fieldName){
        t.all[path][method].form[fieldName] = JSON.stringify(e);
    }

    initMultiFilter() {
        //clog('called initMultiFilter');
    }

    adminValidate(name) {
        scope.adminAlerts = [];
        if(state.domain == 'cels') {
            domainAdminModuleCels.validate(http, setTimeout, scope.form, name, scope.flags, scope.adminAlerts, scope);
        } else {
            domainAdminModuleAlcf.validate(http, setTimeout, scope.form, name, scope.flags, scope.adminAlerts, scope);
        }
    }

    submitFilters(event, resp) {
        if (event.keyCode === 13) {
            scope.searchResults(scope.lists.searchPath, resp);
        }
    }

    checkSearchFields(filterlist, filter, val, id, filtervalues) {
        if(id === "searchField" && filtervalues){
            var idx = filtervalues.searchFields.findIndex(function(item){
                return item.filter_name === val;
            });
            if(idx >= 0){
                filter.selectedSf = filtervalues.searchFields[idx];
                val = filter.selectedSf;
            }
        }
        multiFilterModule.checkSearchFields(filterlist, filter, val, id, scope.lists, scope.flags, scope.form);
    }

    searchResults(path, resp) {
        var i, method, searchVal, formName, rules, currRow, fieldName, val;
        t.rows2display = t.flags.initialRowLimit;
        scope.flags.showNext = false;
        scope.flags.showPrev = false;
        scope.flags.showSearchResults = true;
        method = 'GET';
        searchVal = {search: scope.form.searchBoxValue};
        formName = scope.urls['api_svc'][path].formName;
        rules = scope.vRules[formName];
        svcApiModule.request(rules, method, path, searchVal, resp, scope.lists, null, function () {
            // do extra Angular 9 processing - for performance
            // performance still sucks.
            // now try it with ngx-datatable
            scope.lists.rows[path] = {};
            scope.lists.rows[path].all = [];
            scope.lists.rows[path].columns = [];
            if(!scope.lists.svcOut[path]) {
                scope.flags.showSearchResults = false;
                return;
            };
            for (i in scope.lists.svcOut[path]) {
                currRow = scope.lists.svcOut[path][i];
                scope.lists.rows[path].all.push(currRow);
                scope.lists.svcOut[path][i] = {
                    id: currRow.id,
                    htmlId: currRow.htmlId,
                };
            }

            // now put together the headers for ngx-datatable
            var firstRow = scope.lists.rows[path].all[0];
            for (fieldName in firstRow){
                scope.lists.rows[path].columns.push({prop: fieldName});
            }
            scope.flags.showSearchResults = false;
            if(t.lists.rows[path].all.length < t.flags.initialRowLimit){
                t.rows2display = t.lists.rows[path].all.length;
            }
            scope.lists.rows[path].disp = svcApiModule.colToggle(path + '-' + method, scope.lists.rows[path].all);
            t.colsToDisplay = setDispFormat(scope.lists.rows[path].disp);
            countColumns(path);
            t.flags.dupWarnMsg = null;
        });
    }
    colDispChange(path, colName){
        scope.lists.rows[path].disp = svcApiModule.colToggle(
            path + '-GET', 
            scope.lists.rows[path].all, 
            colName, 
            scope.lists.rows[path].disp[colName]
        );
        t.colsToDisplay = setDispFormat(scope.lists.rows[path].disp);    // this triggers dom repaint in angular
        countColumns(path);
    }
    closeDuplModal(filterlist){
        multiFilterModule.closeDuplModal(t.flags, t.form, t.lists, filterlist);
    }
}

function mkid(v: any): string {
    if ((typeof v) == 'string') {
        //return v.split(' ').join('_');
        return 'x';
    }
    return v;
}

function countColumns(path){
    var key;
    t.colCount = 0;
    t.colDispCount = 0;
    for (key in t.lists.rows[path].disp){
        if(key !== "htmlId"){
            t.colCount ++;
            if (t.lists.rows[path].disp[key] == true){
                t.colDispCount ++ ;
            }
        }
    }
}

function setDispFormat(disp){
    var result = [];
    var count, col, key ;

    count = 0;
    col = [];

    for (key in disp){
        col.push(key);
        count ++;
        if (count >= 8){
            result.push(col);
            count = 0;
            col = [];
        }
    }
    // do the remaining
    if (col.length > 0){
        result.push(col);
    }
    return result;
}

