

// Project Admin module

var projectAdminModule =
(function(){
    var publicStuff;
   //var cookies = http = httpNew = scope = timeout = win = alerts = null;  // cached services
    var cookies , http , httpNew , scope , timeout , win, alerts, GCC, comMod, valMod, domMod;
    cookies = http = httpNew = scope = timeout = win = alerts = GCC = comMod = valMod = domMod = null;    // cached services
    var jsServices = {};
    var clog = null;

    publicStuff ={
        addProjectAccount   : addProjectAccount,
        addProjectE         : addProjectE,
        bpiListAdd          : bpiListAdd,
        bpiListChange       : bpiListChange,
        bpiListDel          : bpiListDel,
        filterProjects      : filterProjects,
        filterProjectsExcl  : filterProjectsExcl,
        filterProjectsByParent: filterProjectsByParent,
        filterUsers         : filterUsers,
        getAllocations      : getAllocations,
        getCatalysts        : getCatalysts,
        getProjectAccounts  : getProjectAccounts,
        getSystemsList      : getSystemsList,
        init                : init,
        matchFavorHostToCatalyst     : matchFavorHostToCatalyst,
        mkMemberStatusTransitionRules: mkMemberStatusTransitionRules,
        mkMemStatTransRulesForEditUser: mkMemStatTransRulesForEditUser,
        postProject         : postProject,
        pjPjUnlink          : pjPjUnlink,
        putProject          : putProject,
        resetProjectAccounts: resetProjectAccounts,
        setupEditForm       : setupEditForm,
        setupView           : setupView,
        setServices         : setServices,
        updateProjectAccounts: updateProjectAccounts,
        validate            : validate,
        validateCels        : validateCels,
        validateNow         : validateNow,
        validatePA          : validatePA,
    };

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;
        GCC     = jsServices.GC;
        comMod  = jsServices.commonModule;
        valMod =  jsServices.validationModule;
        if (GCC.domain == 'alcf') domMod = jsServices.domainAdminModuleAlcf;
        if (GCC.domain == 'cels') domMod = jsServices.domainAdminModuleCels;
        clog    = comMod.ub3consoleLog;

        // GCC = gc ? gc : GC;
    }

    // http : the http service. When unit testing, it is the mock http service
    // scope.lists: needed lists by the UI, for example: projects, institutions
    // scope.flags: needed by the UI to know what options to show to the user
    //
    function init(s, pageName, pjName, newOrEdit){
        scope = s;
        comMod.publicPage(false);
        win.scrollTo(0,0);

        comMod.deleteContext(pageName);

        scope.flags = {
            okToSubmit: false,
            fieldErrors: {},
            fullWidth : true,
            administration : true,
            showProjectsList: true,
            showProjectEditForm: false,
            delta: null,
            missing: null,
            displayedPageName: pageName
        };
        scope.form = {};
        scope.authAlerts = [];
        scope.lists.matchedUsers = [];

        if (!scope.lists){
            scope.lists = {};
        }
        if (!scope.sharedLists){
            scope.sharedLists = {};
        }
        if (!scope.lists.scienceTypes) {
            comMod.getScienceValues(function(a){
                scope.lists.scienceTypes = a.sort();
            });
        }
        if (!scope.lists.instActPend) {
            comMod.getInstActPend(scope.lists);
        }
        if(!scope.lists.projects){
            getProjectsList(scope.flags, scope.lists);
        }
        if (!scope.lists.users){
        //    comMod.getUsersList(http,  scope.lists);
        }
        if (!scope.lists.systems){
            getSystemsList(scope.flags, scope.lists);
        }
        if (!scope.sharedLists.certifiedHosts){
            comMod.getCertifiedHosts(scope.flags, scope.sharedLists);
        }

        scope.lists.resourceList = [
            {v:'Mira',     d:'Mira and Cetus, IBM Blue Gene/Q'},
            {v:'Theta',    d:"Cray XC40 w/ Intel KNL processors"},
            {v:'Vesta',    d:'IBM Blue Gene/Q'},
            {v:'Cooley',   d:'Visualization Cluster'}
        ] ;
        if(scope.flags.showProjectEditForm){
            getProjectAccounts(scope.form.projectShortName, scope.flags, scope.lists);
        }
        if (pjName && (newOrEdit != '')){
            var urlParams = comMod.urlSearchParamsArray();
            // we just got redirected from angular 2+,
            // user wants to edit a specific project
            if (!pjName) pjName = urlParams && urlParams.get('edit');
            if (pjName && (pjName != '')){
                scope.flags.cand = pjName;
                checkIfNewProj(pjName, function(resp){
                    scope.flags.newProject = resp;
                    var newOrEdit = (scope.flags.newProject) ? 'new' : 'edit';
                    initProjectEditForm(pjName, newOrEdit);
                });
            }
        }

        // from talk with Haritha on 2022 Dec 7, we are disabling the localStorage use
        // for this scenario
        /*
        if (!pjName && !newOrEdit){
            // we are in search option.  Not in edit
            var obj = JSON.parse(localStorage.getItem('projectAdmin'));
            if (obj && obj.flags && obj.flags.cand){
                scope.flags.cand = obj.flags.cand;
                filterProjectsNow(scope.flags, scope.lists, 'byProjectName');
            }
            if (obj && obj.flags && obj.flags.piCand){
                scope.flags.piCand = obj.flags.piCand;
                filterProjectsNow(scope.flags, scope.lists, 'byPiName');
            }
        }
        */
    }

    //check if the project name in the url is new or exisitng
    function checkIfNewProj(pjName, callback){
        projectExists(pjName , function(exists){
            callback(!exists);
        });
    }
    function projectExists(shortPjName, cb){
        var found, matches;
        http.get('/admin/projects', {params: {projectShortName: shortPjName}})
            .subscribe(function(resp){
                if (resp.success){
                    if (resp.projects){
                        matches = resp.projects.filter(function(item){
                            return (item.name.toLowerCase() == shortPjName.toLowerCase());
                        });
                        found = (matches.length >= 1);
                        cb(found);
                    }
                }else{
                    // connectivity problem ?
                    clog('unexpected problem. Connectivity issues ? ');
                }
            });

    }


    function initProjectEditForm(pjName, newOrEdit){
        scope.pjToEdit = pjName;
        scope.newOrEdit = newOrEdit;
        setupEditForm(pjName, newOrEdit, scope.form, scope.flags, scope.lists);
        scope.activeIds = ['projectEdit'];
    }

    function emptyObj(obj){
        var key;
        for (key in obj){
            delete obj[key];
        }
    }

    // !cels
    function setupView(pjName, flags, lists, callback){
        if (!flags.currBranch){
            return;
        }
        flags.currBranch.form = {};
        flags.showProjectEditForm = false;
        flags.showProjectsList = false;
        getTheProjectData(pjName, 'edit', flags.currBranch.form, flags, lists, callback);
    }
    function getProjAcls(proj, lists){
        var p = {search : 'project='+proj};
        http.get('/svc/ProjectAclTag', {params : p})
            .then(function(response){
                var resp = response.data;
                if(resp && resp.success){
                  if(resp.data.length > 0){
                    lists.projAclList = resp.data;
                  }
                }
            }).catch(function(response, status){
              t.authAlerts.push({
                type : 'danger',
                msg: response.data.detail
              });
            });
    }
    function setupEditForm(pjName, newOrEdit, form, flags, lists, callback){
        emptyObj(form);
        flags.view = null;
        flags.showProjectEditForm = true;
        flags.showProjectsList = false;
        if(pjName && newOrEdit === 'edit') getProjAcls(pjName, lists);
        getTheProjectData(pjName, newOrEdit, form, flags, lists, callback);
    }
    function getTheProjectData(pjName, newOrEdit, form, flags, lists, callback){

        flags.readyToEdit = false;
        flags.dispLoading = true;
        flags.loaded = {allocations:false, allCatalysts:false, catalysts:false, projectAccounts:false, projectData:false};
        form.projectShortName = pjName;
        if (GCC.projectExplorer){
            flags.currBranch.state.newProject  = (newOrEdit == 'new');
            flags.currBranch.state.editProject = (newOrEdit == 'edit');
        }else{
            flags.newProject  = (newOrEdit == 'new');
            flags.editProject = (newOrEdit == 'edit');
        }
        flags.submitError = null;
        flags.readError = null;

        if (newOrEdit == 'edit'){
            flags.loaded.projectData = false;
            getProject(flags, form.projectShortName, function(newForm){
                comMod.objCopyGuts(newForm, form);    // left --> right
                //Object.assign(form, newForm); // tgt, src
                form.projectShortName = pjName;

                delete form['favorHostFirstName'];
                delete form['favorHostIdentityId'];
                delete form['favorHostLastName'];
                delete form['favorHostPrefName'];
                if (!form.bpiList){
                    form['bpiList'] = [];
                }
                if (!form.bpiObjList){
                    form['bpiObjList'] = [];
                }

                if (GCC.projectExplorer){
                    flags.currBranch.state.prevSavedForm = {};
                    comMod.objCopyGuts(form, flags.currBranch.state.prevSavedForm);
                    validateNow(form, '', flags.currBranch.state, lists, true);
                }else{
                    flags.prevSavedForm = {};
                    comMod.objCopyGuts(form, flags.prevSavedForm);
                    validateNow(form, '', flags, lists, true);
                }
                flags.loaded.projectData = true;
                step2();
            });
        }else{  // new
            comMod.objCopyGuts({projectShortName: pjName}, form);
            //Object.assign(form, {projectShortName: pjName}); // tgt, src
            form.status = 'Active' ;
            form['bpiList'] = [];
            form['bpiObjList'] = [];
            form['public'] = true;
            form['requires_approval'] = true;
            flags.view = form;
            flags.viewType = 'project';
            flags.loaded.catalysts = false;
            getCatalysts('all', function(all){
                lists.allCatalysts = all;
                flags.loaded.catalysts = true;
            });
            if (GCC.projectExplorer){
                validate(form, '', flags.currBranch.state, lists);
            }else{
                validate(form, '', flags, lists);
            }
            flags.sameUnixGroupWarning = false;
            lists.allocations = null;
            flags.loaded.unixGroups = false;
            http.get('/svc/UnixGroup', {params: {search: 'name='+pjName}})
                .success(function(resp){
                    if (resp.success && resp.data && resp.data.length > 0){
                        flags.sameUnixGroupWarning = true;
                    }
                    flags.loaded.unixGroups = true;
                });
        }
        function step2(){
            flags.loaded.projectAccounts = false;
            getProjectAccounts(form.projectShortName, flags, lists, function(){
                //remember the original unfiltered project accounts
                // setup a new array, but the array should retain the original pointers
                // to each object
                var i;
                lists.ufPjAccnts = [];
                for (i in lists.projectAccounts){
                    lists.ufPjAccnts.push(lists.projectAccounts[i]);
                }
                flags.loaded.projectAccounts = true;
                step3();
            });
        }
        function step3(){
            flags.loaded.allocations = false;
            getAllocations(pjName, lists, function(){
                flags.loaded.allocations = true;
                step4();
            });
        }
        function step4(){
                if (form.fieldOfStudy){
                    flags.loaded.catalysts = false;
                    getCatalysts(form.fieldOfStudy, function(clist){
                        lists.catalystsForFieldOfStudy = clist ;
                        flags.loaded.catalysts = true;
                        step5();
                    });
                }else{
                    step5();
                }
        }
        function step5(){
            flags.loaded.allCatalysts = false;
            getCatalysts('all', function(all){
                lists.allCatalysts = all;
                flags.loaded.allCatalysts = true;
                step6();
            });
        }
        function step6(){
            flags.readyToEdit = true;
            if (callback) callback(form, lists);
            timeout(function(){flags.dispLoading=false;},6000);
        }
    }
    function getAllocations(pjName, lists, callback){
        var p = {search: 'is_deleted=False project='+pjName};
        lists.allocations = null;
        http.get('/svc/Allocation', {params: p})
            .success(function(resp){
                if (resp.success){
                    if(!scope.cels){
                        lists.allocations = resp.data;
                    } else {
                     domMod.celsAllocationInfo(resp.data, function(response){
                            lists.allocations = response;
                        });
                    }
                }else{
                    scope.flags.readError = resp.error;
                }
                if (callback) callback();
            })
            .error(function(data, status, headers, config) {
                if (callback) callback();
            });
    }
    function getCatalysts(fieldOfStudy, callback){
        var p = {fieldOfStudy: fieldOfStudy};
        http.get('/admin/catalysts', {params: p})
            .success(function(resp){
                var sortedArray;
                if (resp.success){
                    sortedArray = comMod.sortArrayOnKey(resp.catalysts, 'preferredName');
                    callback(sortedArray);
                }else{
                    scope.flags.readError = resp.error;
                    callback(null);
                }
            })
            .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        callback(null);
            });
    }

    function bpiListChange(bpiList, bpiObjList, idx, newIdx, institutions){
        var institution;
        bpiList[idx] = newIdx;
        institution = findInstitutionById(parseInt(newIdx), institutions);
        bpiObjList[idx] = {id      : idx,
                           idStr   : newIdx,
                           name    : institution.name,
                           status  : institution.status,
                           primary : (idx==0) ? true : false};
    }
    function bpiListDel(bpiList, bpiObjList, idx){
        bpiList.splice(idx,1);
        bpiObjList.splice(idx,1);
    }
    function bpiListAdd(bpiList, bpiObjList, idx, institutions){
        var institution ;
        institution = findInstitutionById(idx, institutions);
        if (institution && (bpiList.indexOf(idx) < 0)){
            bpiList.push(idx);
            bpiObjList.push({id      : parseInt(idx),
                             idStr   : idx,
                             name    : institution.name,
                             'status': institution.status ? institution.status : 'Active',
                             primary : (idx=='0') ? true : false});
        }
    }
    function findInstitutionById(idx, institutions){
        var institution, i, inst ;
        institution = null;
        for (i in institutions){
            inst = institutions[i];
            if (inst.id == idx){
                institution = inst;
                break;
            }
        }
        return institution;
    }

    // This is similar to comMod.selectUsers
    // It's slightly different: it sets a hide field inside the users array objects
    // instead of returning an array as the result
    //
    function filterUsers(str, users){
        if (!str || (str.length < 2)) {
            setAllTo('show');
            return ;
        }
        var i, p, ret, match;
        if (!users){
            return ;
        }

        var s = str.toLowerCase();
        setAllTo('hide');
        for (i in users){
            u = users[i];
            if (u.userName.toLowerCase().indexOf(s) > -1){
                u.hide = false;
            }
            if (u.firstName.toLowerCase().indexOf(s) > -1){
                u.hide = false;
            }
            if (u.lastName.toLowerCase().indexOf(s) > -1){
                u.hide = false;
            }
            if (u.preferredName.toLowerCase().indexOf(s) > -1){
                u.hide = false;
            }
        }

        function setAllTo(option){
            var v = ((option == 'hide') ? true : false);
            for (i in users){
                u = users[i];
                u.hide = v ;
            }
        }
    }

    // finds list of projects by partial name match
    function filterProjectsByParent(branch, filterName, parentName, callback){
        var obj = {cand: branch.cand, piCand: branch.piCand};
        comMod.onStopCalling(800, function(){
            if (filterName == 'byProjectName'){
//                if (branch.cand.length < 2) {return;}
                filterProjectsByName(obj, obj, nextStep);
            }

            if (filterName == 'byPiName'){
                branch.allowPjCreate = false;
//                if (branch.piCand.length < 2) {return;}
                filterProjectsByPiName(obj, obj, nextStep);
            }
        });

        function nextStep(){
            // now filter by parent name
            http.get('/svc/ProjectProject', {params: {search: 'parent_project=='+parentName+' member_status=Active'}})
                .success(function(resp){
                    var kids, i, row, p, results;
                    if (resp.success){
                        if (resp.data && resp.data.length > 0){
                            kids = {};
                            for (i in resp.data){
                                row = resp.data[i];
                                kids[row.child_project] = row.id; // the bridge id
                            }
                            results = [];
                            for (i in obj.matchedProjects){
                                p = obj.matchedProjects[i];
                                if (kids[p.name]){
                                    p['bridgeId'] = kids[p.name];
                                    results.push(p);
                                }
                            }
                            branch.matchedProjects = results;
                            branch.allowPjCreate = obj.allowPjCreate;
                            branch.candMatches = obj.candMatches;
                            branch.pjCount = resp.data.length;
                        }
                    }
                    if (callback) callback();
                })
                .error(function(data, status, headers, config) {
                    if (callback) callback();
                    //clog('should something be reported to user?');
                });
        }
    }

    // finds list of projects by partial name match
    function filterProjects(flags, lists, filterName, callback){
        comMod.onStopCalling(700, function(){
            filterProjectsNow(flags, lists, filterName, callback);
        });
    }
    function filterProjectsNow(flags, lists, filterName, callback){
            flags.allowPjCreate = false;
            if (filterName == 'byProjectName'){

                // from talk with Haritha on 2022 Dec 7, we are disabling the localStorage use
                // for this scenario
                //localStorage.setItem('projectAdmin',JSON.stringify({flags:flags}));

                if (!flags.cand || (flags.cand.length < 2)) {
                    lists.matchedProjects = [];
                    return;
                }
                filterProjectsByName(flags, lists, callback);
            }

            if (filterName == 'byPiName'){

                // from talk with Haritha on 2022 Dec 7, we are disabling the localStorage use
                // for this scenario
                //localStorage.setItem('projectAdmin',JSON.stringify({flags:flags}));

                if (!flags.piCand || (flags.piCand.length < 2)) {
                    lists.matchedProjects = [];
                    return;
                }
                filterProjectsByPiName(flags, lists, callback);
            }
    }

    function filterProjectsExcl(branch, parentName, status, callback){
        comMod.onStopCalling(800, function(){
            filterProjectsExclNow(branch, parentName, status, callback);
        });
    }

    // finds list of projects by partial name match
    // Exclude the ones already under the given parent project
    function filterProjectsExclNow(branch, parentName, status, callback){
        var cand = (status === 'new') ? branch.ancand : branch.aecand;
        scope.flags.exactMatch = true ; //so that the Create buton is disabled immediately

        var obj = {cand: cand, piCand: ''};
        filterProjectsByName(obj, obj, step2);

        function step2(exactMatch){
            // now filter OUT the ones that are already under parent name
            http.get('/svc/ProjectProject', {params: {search: 'parent_project=='+parentName+' member_status=Active'}})
                .success(function(resp){
                    var kids, i, row, p, results;
                    if (resp.success){
                        results = [];
                        kids = {};
                        if (resp.data && resp.data.length > 0){
                            for (i in resp.data){
                                row = resp.data[i];
                                kids[row.child_project] = true;
                            }
                        }
                        for (i in obj.matchedProjects){
                            p = obj.matchedProjects[i];
                            if (!kids[p.name]){
                                results.push(p);
                            }
                        }
                        var finalResults = (cand && cand.length > 0) ? results : [];
                        if(status === 'new'){
                            branch.allNewMatchedProjects = finalResults;
                        } else {
                            branch.allOldMatchedProjects = finalResults;
                        }
                    }
                    scope.flags.exactMatch = exactMatch;
                    if (callback) callback();
                })
                .error(function(data, status, headers, config) {
                    //clog('should something be reported to user?');
                    if (callback) callback();
                });

        }
    }
    function filterProjectsByName(flags, lists, callback){
        flags.candMatches = false;
        flags.allowPjCreate = false;

        var filterValue = flags.cand = comMod.sanitizeRule1(flags.cand);
        if (!filterValue || (filterValue == '')){
            filterValue = 'all';
        }

        lists.matchedProjects = null ;
        flags.readError = null;

        http.get('/admin/projects', {params: {projectShortName: filterValue}})
        .success(function(resp){
            var i, p, cand, exactMatch;
            cand = (flags && flags.cand) ? flags.cand : null;
            exactMatch = false;
            if (resp.success){
                lists.matchedProjects = resp.projects ;
                flags.candMatches = (resp.projects.length > 0);
                // find out if the candidate already exists
                if (cand){
                  for (i in lists.matchedProjects){
                    p = lists.matchedProjects[i];
                    exactMatch = (p.name.toLowerCase() == cand.toLowerCase());
                    if (exactMatch){
                        break;
                    }
                  }
                }
                flags.allowPjCreate = !exactMatch ;
            }else{
                flags.readError = resp.error;
            }
            if (callback) callback(exactMatch);
        })
        .error(function(data, status, headers, config) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
            if (callback) callback(false);
        });
    }
    function filterProjectsByPiName(flags, lists, callback){
        var filterValue;
        flags.candMatches = false;

        filterValue = flags.piCand = comMod.sanitizeRule1(flags.piCand).toLowerCase();

        lists.matchedProjects = null ;
        flags.readError = null;

        // get all prjects
        http.get('/admin/projects', {params: {projectShortName: 'all'}})
        .success(function(resp){
            var i, p;
            if (resp.success){
                lists.matchedProjects = [];

                // now filter by piName
                for (i in resp.projects){
                    p = resp.projects[i];
                    if (p.pi.toLowerCase().indexOf(filterValue) >= 0){
                        lists.matchedProjects.push(p);
                    }
                }
                flags.candMatches = (lists.matchedProjects.length > 0)
            }else{
                flags.readError = resp.error;
            }
            if (callback) callback();
        })
        .error(function(data, status, headers, config) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
            //clog('should we tell the user?');
            if (callback) callback();
        });
    }


    // finds info on one project by exact name match
    function getProject(flags, projectShortName, callback){
        var url ;
        var p = {projectShortName : projectShortName};

        flags.submitError = null;
        flags.readError = null;
        flags.delta = false;
        http.get('/admin/project', {params: p})
            .success(function(resp){
                // there is no success response for this api,  expecting {"success":true, or false
                // commenting out as danger alert getting included incorrectly

              /*  if (resp.success === false){

                    //flags.submitError = resp.error;
                    scope.authAlerts.push({type: 'danger', msg: resp.error});

                }else{
                    //flags.readError = resp.error;
                    scope.authAlerts.push({type: 'danger', msg: resp.error});
                }*/
                resp.bpiObjList = resp.bpiList;
                resp.bpiList = [];
                for (var i in resp.bpiObjList){
                    var obj = resp.bpiObjList[i];
                    obj.idStr = obj.id.toString();
                    resp.bpiList.push(obj.idStr);
                }
                callback(resp);
            })
            .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        //clog('should we tell the user?');
                        callback(null);
            });

    }

    function resetProjectAccounts(lists){
        var cb = GCC.projectExplorer ? scope.flags.currBranch : scope.flags;
        if(!scope.cels){
            lists.projectAccounts = comMod.objCopyArray(cb.lastSavedProjectAccounts);
            lists.ufPjAccnts = comMod.objCopyArray(lists.projectAccounts);
        } else {
            angular.copy(cb.lastSavedProjectAccounts, cb.projectAccounts);
            if (cb.pages){
                cb.pages = comMod.listSplit(cb.projectAccounts, scope.flags.maxUsersPerPage);
                cb.matchedList = cb.pages[cb.currPage];
            }
        }

    }

    function insertDomIds(pas){  // pas is list of project accounts
        var i, pa;
        for (i in pas){
            pa = pas[i];
            pa.domId =  pa.userName.split('@').join('_').split('.').join('_') ;
            //pa.domId =  'hi-there';
        }
    }
    function getProjectAccounts(projectShortName, flags, lists, callback){
        var url ;

        var p = {projectShortName : projectShortName};

        // fixme - scope.flags.loading null in commonModule
        comMod.loading('projectAccounts', 1);
        http.get('/admin/projectAccounts', {params: p})
            .success(function(resp){
                comMod.loading('projectAccounts', -1);
                var pages;
                if (resp.success){
                    lists.projectAccounts = comMod.sortArrayOnKey(resp.projectAccounts, 'userName');
                    insertDomIds(lists.projectAccounts);

                    //pjaToCheckBoxes(lists.projectAccounts);
                    if (GCC.projectExplorer){
                      // CELS
                      flags.currBranch.lastSavedProjectAccounts = comMod.objCopyArray(lists.projectAccounts);
                      mkMemberStatusTransitionRules(flags.currBranch);

                      flags.view = scope.form;  // hack ?
                      if (!flags.currBranch){
                        //alert('currBranch not set');
                      }
                      flags.currBranch.projectAccounts = lists.projectAccounts;
                      if (lists.projectAccounts.length > flags.maxUsersPerPage){
                        //flags.currBranch.matchedList = lists.projectAccounts.slice(0, flags.maxUsersPerPage);
                        pages = comMod.listSplit(lists.projectAccounts, flags.maxUsersPerPage);
                        flags.currBranch.pages = pages;
                        // this next call takes care of inheriting these settings to the Users child
                        // go to page 1 by default
                        projectExplorerModule.changePage(flags.currBranch, 'to', 1);
                      }else{
                        // few users found.  NO paging
                        flags.currBranch.matchedList = lists.projectAccounts;
                        flags.currBranch.pages = flags.currPage = null;
                      }
                    }else{
                        // LCF
                        flags.lastSavedProjectAccounts = comMod.objCopyArray(lists.projectAccounts);
                        mkMemberStatusTransitionRules(flags);

                        lists.matchedProjectAccounts = lists.projectAccounts;
                    }
                    flags.pjaDelta = false;
                    // this next dictionary is usefull for fast indexing
                    lists.pjaIndex = {};
                    lists.ufPjAccnts = [];
                    for (var i in lists.projectAccounts){
                        var pa = lists.projectAccounts[i];
                        lists.pjaIndex[pa.userName] = pa;
                        if(!scope.cels){lists.ufPjAccnts.push(pa);}
                    }
                    validatePA(flags, lists);
                    flags.pjaOkToSave = false;
                }else{
                    flags.readError = resp.error;
                    lists.projectAccounts = null;
                }
                if (callback) { callback();}
            })
            .error(function(data, status, headers, config) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                lists.projectAccounts = null;
                if (callback) { callback();}
            });
    }

    function mkMemberStatusTransitionRules(state){
        // fixme ALCF uses lists.validMemberStatus
        // lists is global and gets out of sync on project explorer
        var projectAccounts = state.lastSavedProjectAccounts;
        state.validMemberStatus = {};
        for (var i in projectAccounts){
            var pa = projectAccounts[i];
            state.validMemberStatus[pa.userName] = getTransitionOptions(pa.memberStatus);
        }
    }
    function mkMemStatTransRulesForEditUser(list, uiState){
        var i, row;
        for (i in list){
            row = list[i];
            if (!uiState[row.projectShortName]) {
                uiState[row.projectShortName] = {};
            }
            uiState[row.projectShortName].statusOptions = getTransitionOptions(row.membershipStatus);
        }
    }
    function getTransitionOptions(currStatus){
        var s = {
            'act'  : 'Active',
            'acpe' : 'Activation Pending',
            'app'  : 'Approved',
            'appm' : 'Approved-pending MUA/Ack',
            'del'  : 'Deleted',
            'rej'  : 'Rejected',
            'req'  : 'Requested',
        }
        if (currStatus == s.req ){ return [ s.app,        s.req, s.appm,               s.rej ]; }
        if (currStatus == s.act ){ return [ s.app, s.act, s.req, s.appm, s.del, s.acpe ]; }
        if (currStatus == s.app ){ return [ s.app,        s.req, s.appm, s.del ]; }
        if (currStatus == s.appm){ return [ s.app,        s.req, s.appm, s.del ]; }
        if (currStatus == s.acpe){ return [ s.app, s.act, s.req, s.appm, s.del, s.acpe ]; }
    }

    // Fixme - not used/called anywhere
    function pjaToCheckBoxes(pjAccounts){
        var i,pa;
        for (i in pjAccounts){
            pa = pjAccounts[i];
            pa.pi = pa.copi = pa.proxy = pa.member = false;
            if (pa.projectRole == 'Member'){ pa.member = true; }
            if (pa.projectRole == 'Proxy') { pa.proxy = true; }
            if (pa.projectRole == 'Co-PI') { pa.copi  = true; }
            if (pa.projectRole == 'PI')    { pa.pi  = true;  }
        }
    }

    // only call this after all validations pass
    // Fixme - not used/called anywhere
    function checkBoxesToPja(pjAccounts){
        var i,pa;
        for (i in pjAccounts){
            pa = pjAccounts[i];
            pa.projectRole = null;
            if (pa.member) {pa.projectRole = 'Member';  }
            if (pa.proxy)  {pa.projectRole = 'Proxy';   }
            if (pa.copi)   {pa.projectRole = 'Co-PI';   }
            if (pa.pi)     {pa.projectRole = 'PI'   ;   }
        }
    }

    function validatePA(flags, lists){
        if (!lists) return;
        if (!lists.projectAccounts || (!scope.cels && !flags.lastSavedProjectAccounts)) return;

        var a,b,i, pa, count, prev, s_des, s_prev, block;
        var diff = false;
        flags.paError = null;
       //scope.authAlerts = [];
        for (i in lists.projectAccounts){
            a = lists.projectAccounts[i];
            b = GCC.projectExplorer ? flags.currBranch.lastSavedProjectAccounts[i] : flags.lastSavedProjectAccounts[i];
            diff = !comMod.isEqual(a,b);
            if (diff){
                break;
            }
        }
        flags.pjaDelta = diff ;
        if (diff){
            flags.pjaOkToSave = false ;

            // look for PIs that the user is trying to wack
            // and make them just members
            for (i in lists.projectAccounts){
                pa = lists.projectAccounts[i];
                if ((pa.projectRole == 'PI') &&
                    ((pa.memberStatus == 'Rejected') || (pa.memberStatus == 'Deleted'))){
                 pa.projectRole = 'Member' ;
                }
            }

            // check to make sure only one primary pi is checked
            // but make sure that this check is done with all the members of the project,
            // not just from the filtered list
            count = 0;
            for (i in lists.ufPjAccnts){
                pa = lists.ufPjAccnts[i];
                count += ((pa.projectRole == 'PI') &&
                          (pa.memberStatus != 'Rejected') &&
                          (pa.memberStatus != 'Deleted'));
            }
            if (count != 1) {
                flags.paError = 'Set one, and only one member as the PI';
                return;
            }
            // enforce that if a person is a PI then they are also a proxy
            for (i in lists.projectAccounts){
                pa = lists.projectAccounts[i];
                if (pa.projectRole == "PI"){
                    pa.projectProxy = true;
                }
            }

            // check for correct status transitions
            for (i in lists.projectAccounts){
                pa   = lists.projectAccounts[i];        // new desired set of values
                prev = GCC.projectExplorer ? flags.currBranch.lastSavedProjectAccounts[i] : flags.lastSavedProjectAccounts[i];     // last saved set of values

                s_des  = pa.memberStatus ;       // new desired status value
                s_prev = prev.memberStatus ;     // last saved status value

                block = false;

                // list of rules follows: these transitions are not allowed
                block |= ((s_prev == 'Requested') && (s_des == 'Deleted'  )) ;
                block |= ((s_prev == 'Approved' ) && (s_des == 'Requested')) ;
                block |= ((s_prev == 'Approved-pending MUA/Ack' ) && (s_des == 'Requested')) ;

                if (block){   // transition not allowed, set it to last saved value
                    pa.memberStatus = prev.memberStatus ;
                }
            }

            flags.pjaOkToSave = true ;

            //fixme steve 1860
            //flags.delta = true;
        }else{
            // no differences.  no need to save
            flags.pjaOkToSave = false;
        }
    }

    function validateCels(form, fName, state, lists){
        if(scope.explorerType !=='project'){return}
        validateNow(form, fName, state, lists, false);
    }

    function validate(form, fName, state, lists){
        if(scope.cels && scope.explorerType !=='project'){return}
        comMod.onStopCalling(1000, function(){
            comMod.doWhenAPICallsDone(function(){
                validateNow(form, fName, state, lists, false);
            });
        });
    }

    // new parameter state has the same purpose as the previous name of 'flags'
    // But now, it has a different name on purpose.  It is up to the caller
    // to make sure that state points to an independent place in the current explorer.
    // state typicaly will point to flags.currBranch.state
    //
    function validateNow(form, fName, state, lists, fromPageLoad){
        var at, id, same, oldVal, newVal, emailAndNameGood, e, p ;
        state.fieldErrors = {};
        state.readError = null;
        //scope.authAlerts = [];

        if (['piFirstName','piLastName'].indexOf(fName) < 0) {
            if (!form.piPreferredName && form.piFirstName && form.piLastName){
                form.piPreferredName = form.piFirstName + ' ' + form.piLastName ;
            }
        }

        if(form.bpiList && !scope.cels){
            form.bpiList = uniq_fast(form.bpiList); // eliminate duplicates
        }

        valMod.validate('projectAdmin', form, state.fieldErrors, null, fromPageLoad);
        state.missing = valMod.computeMissing(state.fieldErrors) ;

        state.okToSubmit = !state.missing;

        // if email has just changed or if name info is empty
        if ((fName == 'piEmail') || (!form.piFirstName || !form.piLastName)){

            // if email is the last field changed. clear out name info because we will try to get it from backend
            if (fName == 'piEmail') {
                form.piFirstName = '';
                form.piLastName = '';
                form.piPreferredName = '';
            }
            // call backend to see if email exists in
            // identityEmail
            var e = state.fieldErrors.piEmail;
            if ((!e || !e.error) && form.piEmail){
                // we have a good email format
                comMod.onStopCalling(700, function(){
                        var p = {email : form.piEmail};
                        state.emailMatched = false;
                        http.get('/admin/searchEmail', {params: p})
                            .success(function (resp) {
                                if (resp.success && resp.match){
                                    form.piFirstName = resp.data.firstName;
                                    form.piLastName = resp.data.lastName;
                                    form.piPreferredName = resp.data.preferredName;
                                    state.emailMatched = true;
                                    validate(form, null, state, lists);
                                }else{
                                    state.okToSubmit = false;
                                    if(!state.fieldErrors.piEmail){
                                        state.fieldErrors.piEmail = {};
                                    }
                                    state.fieldErrors.piEmail.error = "No identity records found for email";
                                }
                            })
                            .error(function(e){
                                //clog(e);
                            });
                });
            }
        }

        // now see if there are any changes to be saved
       // state.delta = !angular.equals(state.prevSavedForm, form);
        state.delta = !comMod.isEqual(state.prevSavedForm, form);
        //  There are changes if its a new project (no prevSavedForm)
        //  or if form is diff than prevSavedForm
    }

    // this function came from here: https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
    function uniq_fast(a) {
        var seen = {};
        var out = [];
        var len = a.length;
        var j = 0;
        for(var i = 0; i < len; i++) {
            var item = a[i];
            if(seen[item] !== 1) {
                seen[item] = 1;
                out[j++] = item;
            }
        }
        return out;
    }

    // add exiting project to a project
    // !cels
    function addProjectE(parentPjName, childPjName, childPjId){
        var parentPjBranch, parentPjId, cdata;

        parentPjBranch = projectExplorerModule.findParentProject(scope.flags.currBranch);
        if (!parentPjBranch){
                // show error to user here too
                scope.authAlerts.push({type: 'danger', msg: 'cannot find parent project'});
                return;
        }
        // the found branch better have the same name that was given
        if (parentPjBranch.name != parentPjName){
                // someone is confused
                scope.authAlerts.push({
                    type: 'danger',
                    msg: 'parent project of current branch does not match the given parent name'
                });
                return;

        }
        parentPjId = parentPjBranch.form.id ;
        if (!parentPjId){
                scope.authAlerts.push({
                    type: 'danger',
                    msg: 'Parent project does not have an id'
                });
                return;
        }

        step1();

        // find out if the bridge record already exists
        function step1(){
            http.get('/svc/ProjectProject', {params: {search: 'parent_project=='+parentPjName+' child_project='+childPjName}})
                .success(function(resp){
                    var kids, i, row, p, results;
                    if (resp.success && resp.data){
                        if (resp.data.length == 0){
                            step2post();    // do a post to add the bridge record
                        }
                        if (resp.data.length > 0){
                            step2put(resp.data[0].id);    // do a put to change the member_status to Active
                        }
                    }
                });

        }

        // insert bridge project-project
        function step2post(){
            var cdata, c;

            cdata = { form: {
                member_status    : 'Active',
                parent_project_id: parentPjId,
                child_project_id : childPjId,
            }};

            http.post("/svc/ProjectProject", cdata)
            .success(function(hresp){
                    if (hresp.success) {
                        step3();
                    }else{
                        scope.flags.submitError = hresp.error ;
                        scope.authAlerts.push({type: 'danger', msg: hresp.error});
                    }
            })
            .error(function(data){
                    scope.authAlerts.push({type: 'danger', msg: data});
            });
        }

        // modify bridge project-project
        function step2put(bridgeId){
            var cdata, c;
            cdata = {
                search: 'id='+bridgeId,
                form: { member_status : 'Active'}
            };

            http.put("/svc/ProjectProject", cdata)
            .success(function(hresp){
                    if (hresp.success) {
                        step3();
                    }else{
                        scope.flags.submitError = hresp.error ;
                        scope.authAlerts.push({type: 'danger', msg: hresp.error});
                    }
            })
            .error(function(data){
                    scope.authAlerts.push({type: 'danger', msg: data});
            });
        }

        // now refresh display of list of projects (where the new one should appear)
        function step3(){
            getProjectsList(scope.flags, scope.lists, function(){
                projectExplorerModule.refreshPjBranch(parentPjBranch, function(){
                    scope.peMatchCandidateProject('byProjectName', scope.flags.currBranch, parentPjBranch.name);
                    scope.flags.currBranch.state.delta = false;
                    scope.flags.currBranch.aecand = null;
                });
            });
            scope.flags.confirmAdd = false;
            scope.flags.confirmUnlink = {};
            scope.flags.pjToAdd = null;
            scope.flags.pjIdToAdd = null;

            // also refresh the match list below the filter string in the template
            filterProjectsExclNow(scope.flags.currBranch, parentPjName, 'old');
        }
    }

    // !cels
    function pjPjUnlink(ppBridgeId, childPjName){
        // modify bridge project-project
        var cdata, c;
        cdata = {
            search: 'id='+ppBridgeId,
            form: { member_status : 'Deleted'}
        };

        http.put("/svc/ProjectProject", cdata)
            .success(function(hresp){
                    if (hresp.success) {
                        scope.authAlerts.push({type: 'success', msg: 'removed project '+childPjName });
                        step2();
                    }else{
                        scope.flags.submitError = hresp.error ;
                        scope.authAlerts.push({type: 'danger', msg: hresp.error});
                    }
            })
            .error(function(data){
                    scope.authAlerts.push({type: 'danger', msg: data});
            });

        // now refresh display of list of projects (where the removed one should NOT appear)
        function step2(){
            getProjectsList(scope.flags, scope.lists, function(){
                var parentPjBranch = projectExplorerModule.findParentProject(scope.flags.currBranch);
                projectExplorerModule.refreshPjBranch(parentPjBranch, function(){
                    scope.peMatchCandidateProject('byProjectName', scope.flags.currBranch, parentPjBranch.name);
                    scope.flags.currBranch.state.delta = false;
                });
            });
        }
    }
    function postProject(form, flags, lists, linkToParentPj){
        var parentPjBranch, parentPjId;
        flags.delta = false;

        var tempForm = {};
        comMod.objCopyGuts(form, tempForm);
        delete tempForm['cand'];
        delete tempForm['bpiObjList'];
        var cdata = {projectShortName: form.projectShortName, form: tempForm};

        if (!cdata.form.internalContact){
             cdata.form.internalContact = false;
        }

        http.post("/admin/project", cdata)
        .success(function(hresp){
                if (hresp.success) {
                    flags.showEditForm = false;
                    flags.showProjectEditForm = false;
                    flags.showProjectsList = true;
                    flags.viewType = 'projects';
                    scope.form = {};

                    if (scope.cels){
                        // insert bridge project-project
                        flags.currBranch.state.newProject = false;
                        flags.currBranch.state.prevSavedForm = {};
                        step2(hresp.id);
                    }else{
                        flags.newProject = false;
                        flags.prevSavedForm = {};
                        step3();
                    }
                }else{
                    scope.authAlerts.push({type: 'danger', msg: hresp.error});
                    flags.submitError = hresp.error;
                }
        })
        .error(function(data){
                scope.authAlerts.push({type: 'danger', msg: data});
                flags.submitError = hresp.error;
        });

        // insert bridge project-project
        function step2(newProjectId){
            var cdata = { form: {
                member_status: 'Active',
                parent_project_id: flags.parentPjId,
                child_project_id: newProjectId,
            }};
            http.post("/svc/ProjectProject", cdata)
            .success(function(hresp){
                    if (hresp.success) {
                        step3();
                    }else{
                        flags.submitError = hresp.error ;
                        scope.authAlerts.push({type: 'danger', msg: hresp.error});
                    }
            })
            .error(function(data){
                    scope.authAlerts.push({type: 'danger', msg: data});
                    flags.submitError = data;
            });
        }

        // now refresh display of list of projects (where the new one should appear)
        function step3(){

        }
    }
    function putProject(form, flags, lists, sc){
        if (sc) {
            //we are in ang2+
            scope = sc;
        }
        if (GCC.projectExplorer){
            var state = flags.currBranch.state;
        }else{
            var state = flags;
        }
        if(flags.saveOption === 'cancel'){
            // get out of editing page (if not cels)
           if (!state.newProject){
                // leaving page, you don't need prev state of form
                comMod.objCopyGuts(flags.currBranch.state.prevSavedForm, form);    // left --> right
            }
            state.delta = false;
            state.okToSubmit = false;
            if (state.newProject){
                flags.viewType = 'projects';
            }
            if (state.editProject){
                // data changed to preSaved
                // run validation on page again to show any missing fields
                scope.pjaValidate();

                //find parent 'projects' branch and go there
                var p = projectExplorerModule.findParent(flags.currBranch);
                if (p){
                    projectExplorerModule.goToBranch(p);
                }
            }
            return;
        }
        if(flags.saveOption === 'reset'){       // stay in editing form
            comMod.objCopyGuts(flags.currBranch.state.prevSavedForm, form);    // left --> right
            validateNow(form, null, state, lists, false);
            return;
        }

        var c, tempForm, cdata ;
        scope.authAlerts = [];

        tempForm = {};
        comMod.objCopyGuts(form, tempForm); // left --> right
        delete tempForm['pi'];
        delete tempForm['piFirstName'];
        delete tempForm['piLastName'];
        delete tempForm['piPreferredName'];
        delete tempForm['piEmail'];
        delete tempForm['bpiObjList'];
        delete tempForm['allocations'];
        delete tempForm['projectShortName'];

        if (!tempForm.internalContact){
             tempForm.internalContact = false;
        }

        delete tempForm['institution'];    // FIXME:  ignored for now.

        var cdata = {projectShortName: form.projectShortName, form: tempForm};

        http.put("/admin/project", cdata)
        .success(function(hresp){
                if (hresp.success) {
                    scope.authAlerts.push({
                        type : 'success',
                        msg : 'Successfully updated project ' + form.projectShortName
                    });
                    if (flags.saveOption == 'getOut'){
                        flags.saveOption = null;
                        flags.showEditForm = false;
                        flags.showProjectEditForm = false;
                        flags.showProjectsList = true;
                    }
                    if (flags.saveOption == 'continue'){
                        flags.saveOption = null;
                        flags.showEditForm = true;
                        flags.okToSubmit = false;
                        setupEditForm(form.projectShortName,'edit', form, flags, lists);
                    }
                    if (flags.currBranch){
                        flags.currBranch.view = null;
                        projectExplorerModule.getProjectView(flags.currBranch, flags.currBranch.name);
                    }
                } else{

                    scope.authAlerts.push({
                        type : 'danger',
                        msg : hresp.error
                    });
                    flags.okToSubmit = flags.delta = false;
                    flags.submitError = hresp.error;
                    if (hresp.error.includes("You do not have permission to update project ")){
                        //User does not have permission to change the project (via ACLs)
                        //so we are reverting the changes to what is in the database
                        if (GCC.projectExplorer){
                            comMod.objCopyGuts(flags.currBranch.state.prevSavedForm, form);
                        }else{
                            comMod.objCopyGuts(flags.prevSavedForm, form);
                        }
                    };
                }
        })
        .error(function(err){
            flags.okToSubmit = flags.delta = false;
            flags.submitError = true;
            scope.authAlerts.push({
                type : 'danger',
                msg : err.data.detail
            });
        });
    }

    function refreshProjectsView(callback){
        if (scope.flags.cand && scope.flags.cand != ''){
            filterProjects(scope.flags, scope.lists, 'byProjectName');
        }else if (scope.flags.piCand && scope.flags.piCand != ''){
            filterProjects(scope.flags, scope.lists, 'byPiName');
        }
    }


    function updateProjectAccounts(pjName, lists, flags, callback, sc){
        if (sc) {
            //we are in ang2+
            scope = sc;
        }
        var a,b,i,diff, todo, processedRows;
        //checkBoxesToPja(lists.projectAccounts);
        todo = 0;
        processedRows = 0;
        flags.paError = null;
        flags.saving = true;
        scope.authAlerts = [];   // clear out error messages
        for (i in lists.projectAccounts){
            a = lists.projectAccounts[i];       // a == desired new changes
            b = GCC.projectExplorer ? flags.currBranch.lastSavedProjectAccounts[i] : flags.lastSavedProjectAccounts[i]  ;
            diff = !comMod.isEqual(a,b, ['idx']) ;
            if (diff && (a.projectRole != 'PI')){
                processedRows++ ;
                todo ++;
                updateProjectAccount(pjName, a, flags, function(){
                    todo -- ;
                    if (todo == 0){
                        doThePI();
                    }
                }, scope);
            }
        }
        if (!processedRows){
            doThePI();
        }
        function doThePI(){
          // there should be one and only one

          for (i in lists.projectAccounts){
            a = lists.projectAccounts[i];       // a == desired new changes
            b = GCC.projectExplorer ? flags.currBranch.lastSavedProjectAccounts[i] : flags.lastSavedProjectAccounts[i]  ;
            diff = !comMod.isEqual(a,b) ;
            if (diff && (a.projectRole == 'PI')){
                updateProjectAccount(pjName, a, flags, function(){
                    processedRows ++ ;
                    getProjectAccounts(pjName, flags, lists);
                    if (callback){
                        callback();
                    }
                }, scope);
                done();
                return;
            }
          }
          done();
        }
        function done(){
          flags.saving = false;
          getProjectAccounts(pjName, flags, lists);
          if (processedRows){
            flags.saveSuccessMsg = 'Saved changes for ' + processedRows + ' member(s)' ;
            scope.authAlerts.push({type: 'success', msg: flags.saveSuccessMsg});
              if (callback){
                  callback();
              }
            timeout(function(){ flags.saveSuccessMsg = null; }, 5000);
          }
        }
    }
    function updateProjectAccount(pjName, pjAccount, flags, callback, sc){
        if (sc) {
            //we are in ang2+
            scope = sc;
        }

        var cdata = {
            id          : pjAccount.id,
            projectRole : pjAccount.projectRole,
            projectProxy: pjAccount.projectProxy,
            receiveReports: pjAccount.receiveReports,
            defaultProject: pjAccount.defaultProject,
            memberStatus: pjAccount.memberStatus,
            comment     : pjAccount.comment
        };

        flags.saving = true;
        http.put("/admin/projectAccount", cdata)
        .success(function(hresp){
                flags.saving = false;
                if (hresp.success) {
                    callback();
                }else{
                    flags.submitError = hresp.error;
                    scope.authAlerts.push({type: 'danger', msg: flags.submitError});
                    // callback();
                    flags.currBranch.matchedList = comMod.objCopyArray(flags.currBranch.lastSavedProjectAccounts);
                }
        })
        .error(function(data){

                callback();
        });
    }
    function addProjectAccount(pjName, userName, comment, flags, lists, sc){
        if (sc) {
            //we are in ang2+
            scope = sc;
        }

        if (!comment){
                comment = '';
        }
        var cdata = {
                projectShortName: pjName, userName: userName, comment: comment
        };
        http.post("/admin/projectAccount", cdata)
        .success(function(hresp){
                    if (hresp.success) {
                        getProjectAccounts(pjName, flags, lists, function(){

                        });
                    }else{
                        if (GCC.projectExplorer){scope.authAlerts.push({type : 'danger', msg : hresp.error})}
                        flags.submitError = hresp.error;
                        scope.authAlerts.push({
                            type : 'danger',
                            msg : hresp.error
                        });
                    }
        })
        .error(function(data){
                    //clog(data);
        });
    }

    function getProjectsList(flags, lists, callback) {

        flags.pjsListError = null;
        http.get("/admin/projects")
            .success(function (resp) {
                    if (resp.success){
                        lists.projects = resp.projects;
                        if (callback) callback();
                    }else{
                        flags.pjsListError = resp.error;
                    }
            });
    }

    function getSystemsList(flags, lists) {

        var p = {selectable : true };
        http.get("/admin/systems", {params: p})
            .success(function (resp) {
                    if (resp.success){
                        lists.systems = resp.systems;
                    }else{
                        flags.readError = resp.error;
                    }
            });
    }

    function matchFavorHostToCatalyst(form, certifiedHosts, catalysts){
        var cat, host, selectedCatalyst, i;
        // find selected object in catalyst array
        selectedCatalyst = null;
        for (i in catalysts){
            cat = catalysts[i];
            if (form.catalyst == cat.id){
                selectedCatalyst = cat
                break;
            }
        }
        if (!selectedCatalyst){
            return;
        }
        var sc = selectedCatalyst;
        for (i in certifiedHosts){
            host = certifiedHosts[i];
            if ((sc.firstName == host.firstName) && (sc.lastName == host.lastName) && (sc.preferredName == host.preferredName)){
                form.paFavorHost = host.id.toString() ;
                return;
            }
        }
        // none found, set selected FAVOR host to none
        form.paFavorHost = null;
    }


    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = {projectAdminModule}

