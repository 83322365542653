import { Component, OnInit, Input } from '@angular/core';
import {CommonService} from '@common/services';

@Component({
  selector: 'warning-a',
  templateUrl: './warning-a.component.html',
  styleUrls: ['./warning-a.component.css']
})
export class WarningAComponent implements OnInit {

  arrayFieldErrors = null;

  constructor(private commonService: CommonService) { 
    this.arrayFieldErrors = this.commonService.getSharedObject('warning', 'arrayFieldErrors');
  }

  @Input() fn: string;
  @Input() fid: string;
  @Input() row: string;


  ngOnInit(): void {
  }

}

