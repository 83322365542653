import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';
import {environment as envPortal}  from '../../../../src/environments/environment';
import {Router} from '@angular/router';

const API_LOGIN_URL = environment.apiUrl + '/public/loginUser';
const API_WHOAMI_URL = environment.apiUrl + '/public/whoami';
const GRONK = 'https://status.alcf.anl.gov/'
let csrfname;let subdir;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
    currentURL: string;
    domainGlobalsList: any;
    dataMock :any;
    public whoAmUser = new Subject<object>();
    public whoAm$ = this.whoAmUser.asObservable();
    //public getUser: Subscription;
    public user: object;
    public app = new Subject<string>();
    public app$ = this.app.asObservable();
    public appName: string;
    constructor(
        private http: HttpClient,
        private _cookieService: CookieService,
        private router: Router,
    ) {
        this.currentURL = window.location.href;
         subdir = (location.pathname === '/') ? '':  location.pathname.replace(/\/+$/g, '');
        this.whoAm$.subscribe(user => {this.user = user});
        this.app$.subscribe(a => {this.appName = a});
    }

      public isAuthenticated(){return this.http.get(API_WHOAMI_URL)}
    private getAuthHeaders() {
        csrfname = (this.appName.endsWith('srv')) ? 'nisrv' :  'portal';
       let csrf = this._cookieService.get(csrfname + '_csrftoken');
        const httpHeaders = new HttpHeaders(
            {'Content-Type': 'application/json', 'X-CSRFToken': csrf});
        return {headers: httpHeaders, withCredentials: true};
    }

    login(userData: any): Observable<any> {
        return this.http.post(API_LOGIN_URL, userData, this.getAuthHeaders()).pipe(map(response => {
            if (response['success']) {this.whoAmI().subscribe((user) => {this.whoAmUser.next(user)})}
            return response;
        }));
    }

    logout(type): Observable<any> {
               return this.http.post(`${envPortal.apiUrl}/`+ type +`/logoutUser`, null, this.getAuthHeaders()).pipe(map(response => {
            if (response['success']) {
                // subscription updates user dict
               this.whoAmUser.next({});
               if(subdir){window.location.href = `${envPortal.apiUrl}/login`}
               else {this.router.navigate(['/login'])}
            }
            return response;
        }));
    }

    whoAmI(): Observable<any> {
         return this.http.get(API_WHOAMI_URL).pipe(map(user => {
             if (user) {
                 if (!user['limited_type']) {
                     this.whoAmUser.next(user);
                     return user;
                 } else {
                     this.logout(`limited`).subscribe(resp => {
                         if (resp.success) {
                             this.router.navigate(['/login'])
                         }
                     })
                 }
             }
        }));
    }

    public domainGlobals(): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + subdir + `/public/domainGlobals`, ).pipe(map(data => {
         this.domainGlobalsList = data['domainGlobals'];
         this.app.next(data['domainGlobals']['appName']);
            return data['domainGlobals'];
        }));
    }

    public getExternalResource(name): Observable<any> {
        const p = {name: name};
        return this.http.get(`${environment.apiUrl}/public/external`, {params: p}).pipe(map(data => {
            return data;
        }));
    }

    getProjectAndSystems(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/projectsAndSystems`).pipe(map(data => {
            return data
        }));
    }

    public getAllocationHoursBySize(cdata: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/allocationHoursBySize`, {params: cdata}).pipe(map(data => {
            return data;
        }));
    }
    public usageThroughProject(cdata: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/usageThroughProject`, {params: cdata}).pipe(map(data => {
            return data;
        }));
    }

    public computeDaysByJobSize(cdata: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/auth/computeDaysByJobSize`, {params: cdata}).pipe(map(data => {
            return data;
        }));
    }

    // IMPORTANT, easy way to pass TEST DATA
    getTestData(): Observable<any> {
     return of(this.dataMock);
    }

     public gronk(n): Observable<any> {
        return this.http.get(GRONK + n +'/activity.json').pipe(map(sys => {return sys}))
    }
}
