import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'alert-msg',
  templateUrl: './alert-msg.component.html',
  styleUrls: ['./alert-msg.component.css']
})
export class AlertMsgComponent implements OnInit {
  constructor() {
  }
  @Input() alerts;

  ngOnInit(): void {
  }
  closeAlert(idx){
    this.alerts.splice(idx, 1);
  }
}
