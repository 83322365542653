<div class="dashboard other-accounts">
    <div class="chooseAcctCat">
       <div *ngIf="!flags.createOtherAccount && !flags.editRecord" class="edit-user-details top">
          <h2>{{flags.displayedPageName}}</h2>
       </div>
       <!-- <domain-admin-dashboard-alert-msg></domain-admin-dashboard-alert-msg> -->
       <div *ngIf="lists.otherAccountData && !flags.createOtherAccount && !flags.editRecord" class="ub3-card acctCatSelectWrap">
          <div class="card-body">
             <ul class="list-inline form-control">
                <li>
                   <h4>Select Account Category:</h4>
                </li>
                <li class="radio">
                   <label><input type="radio" [(ngModel)]="accountCategory" id="service"
                      name="accountCategory"
                      value="Service">Service</label>
                </li>
                <li class="radio">
                   <label><input type="radio" [(ngModel)]="accountCategory" id="test"
                      name="accountCategory" value="Test">Test</label>
                </li>
                <li class="radio">
                   <label> <input type="radio" [(ngModel)]="accountCategory" id="system"
                      name="accountCategory"
                      value="System">System</label>
                </li>
             </ul>
             <div class="create-service-account-btn" *ngIf="accountCategory">
                <ul class="list-inline">
                   <li>
                      <button class="btn btn-default btn-lg"
                         (click)="accountCategory = ''">
                      Cancel
                      </button>
                   </li>
                   <li>
                      <button class="btn btn-primary btn-lg" id="createAccount"
                         (click)="goToCreateOtherAccountForm(accountCategory);"> Create
                      {{accountCategory}} Account
                      </button>
                   </li>
                </ul>
             </div>
          </div>
       </div>
    </div>
    <div class="request-section" *ngIf="!flags.editRecord && displayTable">
       <div *ngIf="flags.bootstrapAlert === 'adminOtherAcct'">
          <!-- <domain-admin-dashboard-alert-msg></domain-admin-dashboard-alert-msg> -->
       </div>
       <h3>Other Accounts</h3>
       <div class="user-records-table condensed" id="adminOtherAcct">
         <ngx-datatable
            #adminOtherAcct
            class="bootstrap card expandable adminOtherAcct"
            columnMode="flex"
            [rowHeight]="auto"
            [headerHeight]="30"
            [footerHeight]="30"
            [scrollbarH]="true"
            [draggable]="false"
            [rows]="lists.allOtherAccounts"
            [limit]="50">

            <ngx-datatable-column name="User Name"          prop="username"         [flexGrow]="1.2">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-userName"
                              type="text"
                              placeholder="Filter by User Name"
                              [(ngModel)]="flags.filter.othAcc.username"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('username')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">User Name</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a id="uName-{{row.username.replace('@','_').replace('.','_')}}"
                     class="user-name wordWrap"
                     (click)="adminEditRecord(row.username)">{{row.username}}
                  </a>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Legal Name"         prop="dispName"        [flexGrow]="0.7">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-LegalName"
                              type="text"
                              placeholder="Filter by Legal Name"
                              [(ngModel)]="flags.filter.othAcc.dispName"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('dispName')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Legal Name</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ul class="list-inline list-name">
                     <li>{{row.first_name}}</li>
                     <li>{{row.last_name}}</li>
                  </ul>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Preferred Name"     prop="preferred_name"   [flexGrow]="0.8">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-PrefName"
                              type="text"
                              placeholder="Filter by Preferred Name"
                              [(ngModel)]="flags.filter.othAcc.preferred_name"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('preferred_name')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Preferred Name</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span>
                     <span class="wordWrap">{{row.preferred_name}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Preferred Email"    prop="primary_email"    [flexGrow]="1.5">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-PrefEmail"
                              type="text"
                              placeholder="Filter by Preferred Email"
                              [(ngModel)]="flags.filter.othAcc.primary_email"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('primary_email')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Preferred Email</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span>
                     <span class="wordWrap">{{row.primary_email}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Service Email"      prop="service_email"    [flexGrow]="1">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-ServEmail"
                              type="text"
                              placeholder="Filter by Service Email"
                              [(ngModel)]="flags.filter.othAcc.service_email"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('service_email')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Service Email</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span>
                     <span class="wordWrap">{{row.service_email}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Account Category"   prop="account_category" [flexGrow]="0.85">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-AccCat"
                              type="text"
                              placeholder="Filter by Account Category"
                              [(ngModel)]="flags.filter.othAcc.account_category"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('account_category')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Account Category</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span>
                     <span class="wordWrap">{{row.account_category}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Account Status"     prop="status"           [flexGrow]="0.75">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-AccStat"
                              type="text"
                              placeholder="Filter by Account Status"
                              [(ngModel)]="flags.filter.othAcc.status"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('status')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Account Status</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span>
                     <span class="wordWrap">{{row.status}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Comments"           prop="comment"          [flexGrow]="2">
               <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <div>
                     <input  id="fltr-Comments"
                              type="text"
                              placeholder="Filter by Comments"
                              [(ngModel)]="flags.filter.othAcc.comment"
                              class="form-control filterDesc"
                              (keyup)="updateFilter('comment')"
                     >
                  </div>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Comments</span>
               </ng-template>
               <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span class="comment-column">
                     <span class="floating-label-wrapper wordWrap">{{row.comment}}
                     </span>
                  </span>
               </ng-template>
            </ngx-datatable-column>

         </ngx-datatable>
       </div>
    </div>
 </div>
 <div id="loading-overlay" *ngIf="lists.loadingAllOtherAccounts">
   <div class="spinner"></div>
   <br />
   Loading...
</div>
