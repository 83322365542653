


<!-- top level loop:  Loop through each python file (api_admin.py, api_auth.py, etc)-->
<!--   inner level loop:  Loop through each function inside the file -->
<div class="apiDevInterface">
    <ng-container *ngFor="let row of urls">
        <a (click)="toggle(row, urls)" style="cursor:pointer">
            <font color="#000099">{{row.file}}.py</font>
        </a><br>
        <ng-container id="file-{{row.file}}" *ngIf="row.on">
            <ng-container *ngFor="let rowb of row.paths">
                <a (click)="toggle(rowb, row.paths); clickPath(rowb.func.formName, forms, row.file, rowb.path);" 
                   style="cursor:pointer">
                    &nbsp;&nbsp;<font color="#4444ff">{{rowb.path}}</font>&nbsp;&nbsp;
                </a>
                <ng-container *ngIf="rowb.on">
                    <ng-container *ngFor="let rowc of rowb.func.params">
                        <a (click)="toggle(rowc, rowb.func.params); clickMethod(row.file, rowb.path, rowc.method, rowc.on);"
                           style="cursor:pointer"
                           id="apiDevFn_{{row.file}}_{{rowb.path.replace('/','_')}}_{{rowc.method}}" >
                           <font color="#009900">{{rowc.method}}</font>
                           &nbsp; 
                        </a>
                    </ng-container>
                    <ng-container *ngFor="let rowc of rowb.func.params">
                                    <table *ngIf="rowc.on">
                                        <ng-container *ngFor="let param of rowc.params; let i = index">

                                        <tr *ngIf="param != 'form'">
                                            <td colspan=4></td>
                                            <td> {{param}}</td>
                                            <td>

                                                <input 
                                                    *ngIf="!forms[rowb.func.formName][param] || (forms[rowb.func.formName][param].type != 'bool')"
                                                    [(ngModel)]="all[rowb.path][rowc.method][param]" 
                                                    (change)="diValidate(rowb.path, rowc.method, rowb.func.formName, all[rowb.path][rowc.method])"
                                                    id="{{rowb.path}}{{param}}{{i}}"
                                                    type="text">
                                                <input 
                                                    *ngIf="forms[rowb.func.formName][param] && (forms[rowb.func.formName][param].type == 'bool')"
                                                    [(ngModel)]="all[rowb.path][rowc.method][param]"
                                                    (change)="diValidate(rowb.path, rowc.method, rowb.func.formName, all[rowb.path][rowc.method])"
                                                    type="checkbox">
                                            </td>
                                            
                                            <td>
                                                <warning-a fn="{{rowb.path}}" row="{{rowc.method}}" fid="{{param}}"></warning-a>
                                            </td>
                                        </tr>

                                        </ng-container>

                                        <ng-container *ngIf="rowc.params.indexOf('form') >= 0">

                                        <tr bgcolor="#ddddff">
                                            <td>label</td>
                                            <td>type</td>
                                            <td>req</td>
                                            <td style="padding-left:7px; padding-right:7px;">error</td>
                                            <td colspan=2>
                                                <font color="#5555ff"><b>form</b></font>
                                            </td>
                                        </tr>

                                        <tr *ngFor="let rowf of forms[rowb.func.formName].array" bgcolor="#eeeeff" >
                                            <td valign="top" nowrap>{{rowf.fRules.label}}</td>
                                            <td valign="top">{{rowf.fRules.type}}</td>
                                            <td valign="top">{{rowf.fRules.required ? 'yes' : ''}}</td>
                                            <td valign="top" style="padding-left:7px; padding-right:7px;" nowrap>
                                                <warning-a fn="{{rowb.path}}" row="{{rowc.method}}" fid="{{rowf.field}}"></warning-a>
                                            </td>
                                            <td valign="top">
                                                {{rowf.field}}
                                                <a *ngIf="rowf.fRules.type == 'array'"
                                                (click)="addRow(arrayObj[rowf.field])">
                                                <font color="#007700">+</font>
                                                </a>
                                            </td>
                                            <td valign="top">
                                            <ng-container *ngIf="rowf.fRules.type != 'bool'">
                                                <ng-container *ngIf="rowf.fRules.type != 'array'">
                                                <input 
                                                    [(ngModel)]="all[rowb.path][rowc.method].form[rowf.field]" 
                                                    (change)="diValidate(rowb.path, rowc.method, rowb.func.formName, all[rowb.path][rowc.method])"
                                                    type="text">
                                                </ng-container>
                                                <ng-container *ngIf="rowf.fRules.type == 'array'">
                                                <input 
                                                    [(ngModel)]="all[rowb.path][rowc.method].form[rowf.field]" 
                                                    [disabled]="true"
                                                    (change)="diValidate(rowb.path, rowc.method, rowb.func.formName, all[rowb.path][rowc.method])"
                                                    type="text">
                                                <table *ngIf="arrayObj[rowf.field]">
                                                        <tr>
                                                            <td></td>
                                                            <td *ngFor="let key of arrayObj[rowf.field].keys"  style="padding-right:7px;">
                                                                {{key}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let row of arrayObj[rowf.field].rows; let i = index;">
                                                            <td>
                                                                <a (click)="delRow(arrayObj[rowf.field].rows, i, arrayObj, rowf.field, all[rowb.path][rowc.method].form)">
                                                                    <font color="#ff0000">x</font>
                                                                </a>
                                                            </td>
                                                            <td *ngFor="let key of arrayObj[rowf.field].keys">
                                                                <input type="text" 
                                                                    (keyup)="convert(arrayObj, rowf.field, all[rowb.path][rowc.method].form)"
                                                                    [(ngModel)]="row[key]">
                                                            </td>
                                                        </tr>
                                                </table>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="rowf.fRules.type == 'bool'">
                                                <input 
                                                    [(ngModel)]="all[rowb.path][rowc.method].form[rowf.field]" 
                                                    (change)="diValidate(rowb.path, rowc.method, rowb.func.formName, all[rowb.path][rowc.method])"
                                                    type="checkbox">
                                            </ng-container>
                                            </td>

                                        </tr>
                                        </ng-container>

                                        <tr>
                                            <td colspan="5"></td>
                                            <td>
                                                <button 
                                                    class="btn btn-success btn-sm" 
                                                    id="{{rowc.method}}_{{rowb.path.replace('/','_')}}"
                                                    (click)="sendRequest(row.accessLevel, rowc.method, rowb.path, all[rowb.path][rowc.method])">
                                                    {{rowc.method}} {{rowb.path}}
                                                </button>

                                            </td>
                                        </tr>
                                        <tr *ngIf="resp.response">
                                            <td colspan="7">
                                                response: 
                                                <ngx-json-viewer 
                                                    [(json)]="resp.response"
                                                    [expanded]="true">
                                                </ngx-json-viewer> 
                                            </td>
                                        </tr>
                                        <tr *ngIf="resp.error">
                                            <td colspan="7">
                                                error: 
                                                <ngx-json-viewer 
                                                    [(json)]="resp.error"
                                                    [expanded]="true">
                                                </ngx-json-viewer> 
                                            </td>
                                        </tr>
                                    </table>

                    </ng-container>
                </ng-container>
                <br>
            </ng-container>
        </ng-container>
    </ng-container>


</div>
<!--

<ngx-json-viewer 
    [(json)]="forms[rowb.func.formName]"
    [expanded]="false">
</ngx-json-viewer>

-->

