
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {NgbCalendar} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from '@common/services';
import * as dayjs from 'dayjs';
// import * as utc from "dayjs/plugin/utc";


var ub3consoleLog = null;
const allowedFutureMonths = 14;

@Component({
  selector: 'ub3date',
  templateUrl: './ub3date.component.html',
  styleUrls: ['./ub3date.component.css']
})
export class Ub3dateComponent implements OnInit {

  constructor(private commonService: CommonService, private calendar: NgbCalendar) {
    ub3consoleLog = commonService.ub3consoleLog;
  }
  @Input() form: any;
  @Input() key: string;
  @Input() idPrefix: string;
  @Input() trigger: string;     // this one only gets sent from svc-api.component.html
  @Output() onChange = new EventEmitter();
  oneDate = null;
  canEdit = false;
  fieldId = null;
  toDate = null;

  ngOnInit(): void {
    var x = this;
    setTimeout(function(){
      // we need timeout because the parameters are ready only after the component finishes initialization.
      x.doTheActualInit();
    },500);
  }
  doTheActualInit(){
      this.oneDate = convertStrTo3(this.form[this.key]);
      // this is the html id for cypress
      this.fieldId = this.idPrefix ? this.idPrefix + '-' + this.key : this.key;

      if(this.key === 'deactivation_date'){
        this.toDate = this.calendar.getNext(this.calendar.getToday(), 'm', allowedFutureMonths);
      }
  }

  // a call to this function is triggered
  // when anything changes in the @Input parameters.
  ngOnChanges(changes){
    var prev, curr, t;
    t = this;

    if (changes.trigger){
        prev = changes.trigger.previousValue;
        curr = changes.trigger.currentValue;
        if (prev != curr){
                //date field changed in the form
                t.oneDate = convertStrTo3(curr);
        }
    }
    
  }

  beforeChange(){   // user just clicked the calendar icon
    this.canEdit = true;
    this.oneDate = convertStrTo3(this.form[this.key]);
  }

  whenChange(){ // user just picked a date from the visual calendar
    this.form[this.key] = convert3toStr(this.oneDate);
    this.canEdit = false;
    this.onChange.emit('stuff');
  }

} //  END OF CLASS

// functions below are known only inside this file and are NOT needed by the template

function convertStrTo3(str){
    var obj, dt, year, month, day;

    dt = Date.parse(str);
    if (!dt) {
      return null;
    }
    year  = dayjs(dt).year();
    month = dayjs(dt).month() + 1;
    day   = parseInt(dayjs(dt).format('DD'));

    obj = {year: year, month: month, day: day};

    return obj;
}

function convert3toStr(obj){
    // output needs to look like this:   2010-01-21T06:00:00Z
    var str;
    if (obj == null) return null;
    if (obj == ''  ) return '';
    str = obj.year + '-' + z2(obj.month) + '-' + z2(obj.day) + 'T06:00:00Z' ;
    return str;
}

function z2(num){
    var n = parseInt(num);
    if (n >9) return n.toString();
    return '0' + n.toString();
}


// NOT USED ANYWHERE
/*function utc2cdt(date){
  //return date.toString();
  
  if(!date){
      return 'N/A';
  }else{
      var checkDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
      var isValidDate = checkDate.isValid();
      if(isValidDate){
          // display medium date .format('MMM D, YYYY'), returns 'Dec 27, 2018'
          // to see converted date, test with hours .format("YYYY-MM-DD HH:mm");
        var cdt = moment.utc(date).tz('America/Chicago').format('MMM D, YYYY');
          return cdt;

      } else{
          // this isn't a valid date but a string, just show it...
          //example "Favor record missing"
          return date;
      }
  }
  
}*/
