import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import {domainAdminModule} from '../../../../../../../../frontend/domainAdminModule';
import {editRecordModule} from '../../../../../../../../frontend/editRecordModule';
import {accountUpdateModule} from '../../../../../../../../frontend/accountUpdateModule';
import {projectAdminModule} from '../../../../../../../../frontend/projectAdminModule';

let t = null;
let scope: any = {};
let http: any = null;
let cookies: any = null;
let ub3consoleLog = null;

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})

export class UserEditComponent implements OnInit {
  sharedLists = {};
  selectedUnixGroup = '';
  selectedSystem = '';
  updateAccountSubmit = false;
  confirmSaveChecked = false;
  confirmBtn = false;
  privilege = null;
  fieldName = null;
  selected;
  statusWarning = false;
  alerts = [];
  authAlerts = [];
  state: any = {httpCallsPending: 0};
  fieldErrors = {};
  arrayFieldErrors = {};
  warnLevels = [];
  form = {
    accountResources:{UnixGroup:[], System:[]},
    systemId: '',
    activationDate: null,
    userName: '',
    domainAdmin: '',
    accountType: '',
    status: '',
    cryptoKeyOption: '',
    home_dir: '',
    comment: '',
    firstName: '',
    lastName: '',
    middleName: '',
    preferredName: '',
    nmi: false,
    accountEmails: [],
    accountAddresses: [],
    projectList: [],
    ugId: false,
  };
  flags = {
    delta: false,
    hideFormSection: false,
    emailMatched: false,
    changedIdentity: false,
    readError: false,
    newServiceAcct: false,
    filter: null
  };
  lists = {
    accAclList:[],
    userFavorRecords:[],
    allUnixGroups:[],
    systems:[],
    instActPend:[]
  };
  mobile = false;
  tablet = false;
  missingReqdFields = [];
  adminAlerts = [];
  openModal = false;
  uiState = {};
  displayTable = true;

  constructor(
    public h: Ub3httpService,
    public commonService: CommonService,
    private c: CookieService,
    private window: Window,
    private router: Router
  ) {
    scope = t = this;
    http = h;
    cookies = c;
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.state            = commonService.getSharedObject('app','state');
    t.fieldErrors      = commonService.getSharedObject('warning', 'fieldErrors');
    t.arrayFieldErrors = commonService.getSharedObject('warning', 'arrayFieldErrors');
    t.warnLevels = Array.from(Array(10).keys());
    t.missingReqdFields = [];

    t.flags = t.commonService.getSharedObject('user-admin', 'flags');
    t.lists = t.commonService.getSharedObject('user-admin', 'lists');
    t.flags.prevSavedForm = {};
    t.lists.accAclList = [];

    if(!t.lists.countries){
      t.commonService.getCountries(t.lists);
    }
    if(!t.lists.systems){
      domainAdminModule.getSystemsList(http, t.flags, t.lists, t.alerts);
    }
  }

  ngOnInit(): void {
      document.body.classList.remove('public-page');
      t.flags.domainAdmin = (t.state.user.role === 'domainAdmin');
      var urlParams = t.commonService.urlSearchParamsArray();
      var userName = urlParams && urlParams.get('userName');
      t.userName = userName;
      t.getAccAcls(userName);
      domainAdminModule.getApiLists(http,t.lists, function(){
        domainAdminModule.getUserInfo(http, t.flags, t.form, t.lists, userName, t, function(){
            doMore();
        });
      });

     function doMore(){
      t.flags.reqdFieldsMissing  = false;
      editRecordModule.overWriteScope(t);
      t.validateAccountUpdate();
      t.flags.filter = {
        projMem: {
          projectShortName:'',
          allocationType:'',
          membershipStatus:'',
          piPreferredName:'',
          proxyPreferredName:'',
          projectRole:'',
          comment:''
        }
      };
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.formatDeactDt(t.form);
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
        projectAdminModule.mkMemStatTransRulesForEditUser(t.form.projectList, t.uiState);
        t.cacheOrigMstat(t.form.projectList);
      });
     }
  }

  updateFilter(colName){
    t.commonService.onStopCalling(700, function(){
      t.updateFilterNow(colName);
    });
  }
  updateFilterNow(colName){
        t.commonService.filter('projMem', t.form.projectList, colName, 'and', t.flags.filter['projMem']);
        // trigger table repaint
        t.displayTable = false;
        setTimeout(function(){t.displayTable = true;}, 100);
  }

  goToEditProject(pjName){
        t.router.navigate(['/projectEdit'], {queryParams: {name: pjName, opt: 'edit'}});
  }
  setId(item, fieldName, arr){
    t.commonService.onStopCalling(1000, function(){
      var searchArr = [];
      if (fieldName === 'ugId' &&  t.form.accountResources &&  t.form.accountResources.UnixGroup){
        searchArr = t.form.accountResources.UnixGroup;
      } else if(fieldName === 'systemId' &&  t.form.accountResources &&  t.form.accountResources.System){
        searchArr = t.form.accountResources.System;
      }
      var matchFound = [];
      if(searchArr.length > 0){
        matchFound = searchArr.filter(function(fld){
          return fld.resourceName.toLowerCase() === item.toLowerCase();
        });
      }
      var idx = arr && arr.length > 0 && arr.findIndex(function(row){
        return row.name.toLowerCase() === item.toLowerCase();
      });
      if(idx >= 0 && matchFound.length === 0){
        t.form[fieldName] = arr[idx].id;
      } else {
        t.form[fieldName] = '';
        if(fieldName === 'ugId')     t.selectedUnixGroup = '';
        if(fieldName === 'systemId') t.selectedSystem    = '';
        t.adminAlerts = [];
      }
    });
  }
  cacheOrigMstat(pjList){
    var i, pjs, options;
    for (i in pjList){
        pjs = pjList[i].projectShortName;
        options = t.uiState && t.uiState[pjs] && t.uiState[pjs].statusOptions ? t.uiState[pjs].statusOptions : [];
        t.uiState[pjs] = {origMstat: pjList[i].membershipStatus, editing: false , statusOptions:options};
    }
  }
  enableEdit(list, pjShortName){
        var i, pjs;
        for (i in list){
            pjs = list[i].projectShortName;
            list[i].membershipStatus = t.uiState[pjs].origMstat ;
            t.uiState[pjs].editing = (pjs == pjShortName);
        }
  }
  saveMemStatus(pjAccntRecord){
    domainAdminModule.updProjectAccountBridgeMemberStatus(pjAccntRecord, function(){
        t.form.projectList = [];
        domainAdminModule.getUserInfo(http, t.flags, t.form, t.lists, t.userName, t);
        t.commonService.doWhenAPICallsDone(function(){
            projectAdminModule.mkMemStatTransRulesForEditUser(t.form.projectList, t.uiState);
            t.cacheOrigMstat(t.form.projectList);
            t.cancelMemStatusEdit(t.form.projectList);
        });
    });
  }
  cancelMemStatusEdit(list){
        var i, pjs;
        for (i in list){
            pjs = list[i].projectShortName;
            list[i].membershipStatus = t.uiState[pjs].origMstat;
            t.uiState[pjs].editing = false;
        }
  }

  // FIXME: there are two parameters in accountAddItem() for phones
  accountAddItem(type){
    editRecordModule.addItem(t.form, t.flags, type);
    t.commonService.doWhenAPICallsDone(function(){
      t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
    });
  };
  adminAddUnixSystem(id, type){
    t.adminAlerts = [];
    domainAdminModule.editUnixSystem(http, cookies, t.flags, t.form, t.lists, Promise, id, type, t);
  };
  adminFavorSync(){
    t.adminAlerts = [];
    domainAdminModule.favorSync(http, t.form, t.flags, t.lists, Promise, setTimeout, t);
  };
  adminSaveRecord(saveOption){
    t.adminAlerts = [];
    if (saveOption == 'cancel'){
      var urlParams = t.commonService.urlSearchParamsArray();
      var userName = urlParams && urlParams.get('userName');
      if (userName.length >= 0) {
          window.location.hash = '/userAdmin';
          return;
      }
    }
    t.flags.saveOption = saveOption;
    t.removeEmptyObjs(function(){
      domainAdminModule.saveRecord(t.form, t.flags, t.lists, t.sharedLists, Promise, t);
    });
  }

  removeEmptyObjs(callback){
    if(!t.form) return;
    var formArrays = ['account', 'accountAddresses', 'accountInstitutions', 'accountPhones', 'accountQuestions', 'accountEmails', 'accountProjects', 'accountResources'];
    formArrays.forEach(function(key){
      if(Array.isArray(t.form[key])){
        t.form[key].forEach(function(obj, index){
          if(obj.method && (obj.method === 'put' || obj.method === 'post')){
            if(
              (key === 'accountEmails' && !obj.email) ||
              (key === 'accountInstitutions' && !obj.affiliation && !obj.institutionId) ||
              (key === 'accountQuestions' && (!obj.securityQuestion || !obj.securityAnswer)) ||
              (key === 'accountPhones' && (!obj.phoneCountry || !obj.phoneLabel || !obj.phoneNumber))
              ){
                t.form[key].splice(index,1);
              }
          }
        });
      }
    });
    if(callback) callback();
  }
  confirmAdminPrivilege(priv, fn){
    t.openModal = true;
    t.privilege = priv;
    t.fieldName = fn;
    t.window.scrollTo(0,0);
  };

  confirmMailToAdmin(fn, userName, project){
    t.openModal = true;
    t.fieldName = fn;
    t.userName = userName;
    t.project = project;
    t.window.scrollTo(0,0);
  };

  closeModal(field, val){
    t.openModal = false;
    t.form[field] = val;
    t.validateAccountUpdate();
  }
  closeOwnerModal(privilege, val){
    t.privilege.owner = val;
    t.openModal = false;
    t.ueValidate('accountResources', privilege, privilege.id);
  }
  closeMailToAdmin(field, val, userName, project){
    t.adminAlerts = [];
    t.openModal = false;
    t.form[field] = val;
    if(val){
        domainAdminModule.sendMailToAdmin(http, cookies, t.flags, t.form, t.lists, Promise, userName, project, t);
     }
  };

  editOtherAccount(cat, option){
    domainAdminModule.editOtherAccount(http, cookies, t.flags, t.form, t.lists, cat, t, option);
  };


  selectDefault(row, array){
    t.authAlerts = [];
      array.forEach(function(b){
          b.default = row === b;
      });
  };

  selectPrimaryInstitution(row, array){
    if (!row || !row.affiliation || (row.affiliation == '')){
        return;
    }
    array.forEach(function(b){
        b.primaryInstitution = (row === b);
    });
  };

  selectPrimaryItem(row, array){
    array.forEach(function(b){
        b.primary = (row === b);
    });
  };
  ueValidate(formName, obj, id) {
    t.commonService.onStopCalling(800, function(){
        
        t.authAlerts = [];
        t.commonService.objCopyGuts({}, t.fieldErrors);
        t.commonService.objCopyGuts({}, t.arrayFieldErrors);
        accountUpdateModule.overWriteScope(t);
        accountUpdateModule.validateList(formName, obj, id, t);

        t.commonService.doWhenAPICallsDone(function(){
            t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
            t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
            t.recomputeValidationFlags();
        });
    });
  };

  recomputeValidationFlags(){
    var fe = false;
    var arrFe = false;
    t.missingReqdFields = [];
    var fldErr = t.flags.fieldErrors;
    var arrFldErr = t.flags.arrayFieldErrors;

    Object.keys(fldErr) && Object.keys(fldErr).forEach(function(key) {
      if(fldErr[key].error || fldErr[key].dispRequired){
        fe = true;
        t.missingReqdFields.push(fldErr[key].label);
      }
    });

    Object.keys(arrFldErr) && Object.keys(arrFldErr).forEach(function(aKey) {
      Object.keys(arrFldErr[aKey]).forEach(function(bKey) {
        if(bKey !== 'Deleted'){
          Object.keys(arrFldErr[aKey][bKey]).forEach(function(cKey) {
            if ((aKey == 'accountResources') && ((cKey == 'status') || (cKey == 'resource_type'))){
                // do nothing
            }else{
                if(arrFldErr[aKey][bKey][cKey].error || arrFldErr[aKey][bKey][cKey].dispRequired){
                    arrFe = true;
                    t.missingReqdFields.push(arrFldErr[aKey][bKey][cKey].label);
                }
            }
          });
        }
      });
    });

    t.flags.reqdFieldsMissing = (fe || arrFe);
    t.flags.delta = ! t.commonService.isEqual(t.flags.prevSavedForm, t.form);
    t.flags.okToSubmit = !t.flags.reqdFieldsMissing && t.flags.delta;
  }

  getInstitutionId(name){
    return t.commonService.getInstitutionId(name, scope.lists);
  }

  confirmYesRejected(){
        t.statusWarning = false;
  }
  confirmNoRejected(){
        t.form.status = t.flags.prevSavedForm.status; 
        t.statusWarning = false;
        t.validateAccountUpdate();
  }

  validateAccountUpdate(fldName?:string) {
    t.commonService.onStopCalling(700, function(){
      if (fldName == 'status' ){
        if ((t.form.status == 'Rejected') && (t.form.status != t.flags.prevSavedForm.status)){
            // user just changed status to Rejected, give warning
            t.statusWarning = true;
        }else{
            t.statusWarning = false;
        }
      }
      editRecordModule.overWriteScope(t);
      t.updateAccountSubmit = false;
      t.confirmSaveChecked = false;
      t.confirmBtn = false;
      t.authAlerts = [];
      editRecordModule.validateAccountUpdate(t.form,t.flags,false,fldName);
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
        t.recomputeValidationFlags();
      });
    });
  };
  checkDeactDate(form){
    form.deactivation_date = (form.accountType === 'Annual') ? t.flags.prevSavedForm.deactivation_date : null;
    t.validateAccountUpdate();
  }

  adminValidate (name) {
    t.adminAlerts = [];
    t.commonService.objCopyGuts({}, t.fieldErrors);
    t.commonService.objCopyGuts({}, t.arrayFieldErrors);
    domainAdminModule.validate(http, setTimeout, t.form, name, t.flags, t.adminAlerts, t);
    t.commonService.doWhenAPICallsDone(function(){
      t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
    });
  };

  onNoCountry(obj) {
    accountUpdateModule.onNoCountry(obj, t.form);
  }
  aupLocateByZip (formName, obj, row) {
    t.commonService.locateByZipAup(formName, obj, row, t.form);
  };

  validateInput(fieldName, event, ind?){
    t.FindError();
    var errorInput = event.target;
    var fieldID = fieldName+''+ind;
    t.confirmSaveChecked = false;
    t.confirmBtn = false;
    t.alerts = [];
    if(ind){
        if(errorInput.id == fieldID && errorInput.value.length > 0){
           t.removeInputErrors(errorInput);
        }
    }
    else{
        t.removeInputErrors(errorInput);
    }
  }

  autosizeFn(id){
    t.flags.isOnFocus = true;
    var elem = document.getElementById(id);
    if(elem){
      elem.style.overflow = 'hidden';
      elem.style.height = elem.scrollHeight + 'px';
    }
  }
  getSmall(id){
    t.flags.isOnFocus = false;
    var elem = document.getElementById(id);
    if(elem){
      elem.style.height = 'auto';
    }
  }
  countryChange(row){
    t.commonService.onStopCalling(1000, function(){
      t.commonService.onCountryChange(row, t.lists, function(){
        t.ueValidate('accountPhones', row, row.id);
      });
    });
  }


  FindError(){
    var errorInputElement = document.querySelectorAll('.has-error');
    if(errorInputElement && errorInputElement.length > 0){
        t.confirmSaveChecked = false;
        t.confirmBtn = false;
    }
  }

  removeInputErrors(errorInput){
    var errorInputParentsArr, i, val;
    errorInputParentsArr = [];
    while (errorInput) {
                errorInputParentsArr.unshift(errorInput);
                errorInput = errorInput.parentNode;
                if(errorInputParentsArr.length == 3){
                    break;
                }
    }
    for (i in errorInputParentsArr){
      val = errorInputParentsArr[i];
      if (val.classList.contains('error-section')){
        val.classList.remove('error-section');
      }
    }
  }


  convertUTCtoCDT(d){
    return t.commonService.convertUTCtoCDT(d);
  }


  onInstSelected(selItem,f){
    t.commonService.onStopCalling(1000, function(){
      var selectedInst = t.lists.instActPend.filter(function(item){
        return item.name.toLowerCase() === selItem.toLowerCase();
      });
      if (selectedInst.length === 0){
          delete f['institutionId'];
          delete f['affiliation'];
      } else {
        f.institutionId = selectedInst[0].id;
        f.affiliation = selectedInst[0].name;
      }
      t.ueValidate('accountInstitutions', f, f.id);
    });
  }

  dialCode(str){
    console
    return t.commonService.getCountryCode(str, scope.lists);
  }
  onStateChange(row){
    t.commonService.onStopCalling(1000, getStAbr);

    function getStAbr(){
      var ss = t.lists.usaStates.filter(function(st){
        return st.name === row.state;
      });
      row.state = ss[0] ? ss[0].abr : '';
      t.ueValidate('accountAddresses', row, row.id);
    }

  }

  searchUnixGroup = function(text$: Observable<string>){
  return text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.allUnixGroups && t.lists.allUnixGroups.filter(function(item){
          return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        }).map(function(group) {
          return (group.name);
        });
      })
    );
  }
  searchSystem = function(text$: Observable<string>){
  return text$
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.systems && t.lists.systems.filter(function(item){
          return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        }).map(function(sys) {
          return (sys.name);
        });
      })
    );
  }
  searchAddrCountry = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.countries.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(cnt) {
            return (cnt.name);
          });
        })
      );
  }
  searchAccAddrState = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.usaStates.filter(function(item){
            return item.abr.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(st) {
            return (st.abr, st.name);
          });
        })
      );
  }
  searchAccInstn = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.instActPend && t.lists.instActPend.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(inst) {
            return (inst.id, inst.name);
          });
        })
      );
  }
  searchEmplLevel = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return  t.lists.careerLevels && t.lists.careerLevels.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(lvl) {
            return (lvl.name);
          });
        })
      );
  }
  onEmplLvlSelected(event){
    t.commonService.onStopCalling(1000, function(){
      var scl = t.lists.careerLevels.filter(function(cl){
        return cl.name === event;
      });
      t.form.employment_level = scl[0] ? scl[0].name : '';
      t.validateAccountUpdate();
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
      });
    });
  }
  getAccAcls(selectedUser){
    var p = {search : 'username='+selectedUser};
    http.get('/svc/AccountAclTag', {params : p})
        .then(function(response){
            var resp = response.data;
            if(resp && resp.success){
              if(resp.data.length > 0){
                t.lists.accAclList = resp.data;
              }
            }
        }).catch(function(response, status){
          t.authAlerts.push({
            type : 'danger',
            msg: response.data.detail
          });
        });
  }
  navigateToAclPage(acltagname){
    t.router.navigateByUrl('/aclAdmin?name=' + acltagname);
  }

}

