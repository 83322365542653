import { Component, OnInit } from '@angular/core';
import { apiDevIntModule } from 'apiDevIntModule';
import {CommonService} from '@common/services';
import { RoutesRecognized } from '@angular/router';

var t = null;
var state = null;
var clog = null;
var cleanBlanksInArrays = true;

@Component({
  selector: 'appApiDevInt',
  templateUrl: './api-dev-int.component.html',
  styleUrls: ['./api-dev-int.component.css']
})
export class ApiDevIntComponent implements OnInit {

  urls = [];
  forms = {};
  all = {};
  activeIds = [];
  bActiveIds = [];
  cActiveIds = [];
  arrayFieldErrors = null;
  flags = {};
  resp = {};
  special = {};
  arrayObj = {};

  constructor(private commonService: CommonService) { 
    t = this;
    t.arrayFieldErrors = t.commonService.getSharedObject('warning', 'arrayFieldErrors');
    state = t.commonService.getSharedObject('app','state');
    clog = t.commonService.ub3consoleLog;
         
    apiDevIntModule.overWriteScope(t);
  }

  ngOnInit(): void {
        document.body.classList.remove('public-page');
        apiDevIntModule.init(function(ourls, surls, forms){
            t.urls = surls;
            t.forms = forms;
            console.log(42, {forms: forms});
        });
  }
  toggle(row, rowParent){
    var val = row.on ;
    rowParent.map(function(a){a.on = false;});
    row.on = !val;
  }
  clickPath(formName, forms, file, path){
        var pathId, pathIsOn, oneForm, key, field, obj;

        pathId =  'apiDevRule_' + file + '_' + path.replace('/','_') ;
        pathIsOn = (t.bActiveIds.indexOf(pathId) >= 0) ;

        if (pathIsOn){
            t.bActiveIds = [];
        }else{
            apiDevIntModule.getRules(formName, forms);
            t.all[path] = {};
            t.bActiveIds = [ pathId ];
            
            // set arrayObj, this is for the array of dictionary fields
            // search fields in this form and see if any are of type array of dict
            oneForm = forms[formName];
            for (key in oneForm){
                field = oneForm[key];
                if ((field.type == 'array') && (field.array_type == 'dict') && field.dict_keys){
                    obj = {};
                    var j = JSON.parse(field.dict_keys);
                    j.map(function(s){
                        obj[s] = '';
                    });
                    t.arrayObj[key] = {
                        keys: j,
                        rows: [obj]
                    }
                }
            }
            console.log(76, {arrayObj: t.arrayObj, forms:forms});
        }
  }
  clickMethod(file, path, method){
        var methodId, methodIsOn;
 
        methodId = 'apiDevFn_' + file + '_' + path.replace('/','_') + '_' + method ;
        methodIsOn = (t.cActiveIds.indexOf(methodId) >= 0) ;

        if (methodIsOn){
            t.cActiveIds = [];
        }else{
            t.all[path][method] = {form: {}};
            t.cActiveIds = [ methodId ];
            t.resp = {};
        }
  }
  diValidate(path, method, formName, data){
        apiDevIntModule.validate(path, method, formName, data);
        t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors );
  };
  addRow(fieldInfo){
    var idx, i, rowIsEmpty, val, row, key;
    // if last row is empty, do not add extra row
    idx = fieldInfo.rows.length - 1;
    row = fieldInfo.rows[idx];
    //examine each field in this last row
    rowIsEmpty = true;
    for (key in row){
        val = row[key];
        if (val != '') {
            rowIsEmpty = false;
            break;
        }
    }
    if (!rowIsEmpty){
        // add the last row
        row = {};
        for (i in fieldInfo.keys){
            key = fieldInfo.keys[i];
            row[key] = '';
        }
        fieldInfo.rows.push(row);
    }
  }
  delRow(rows, i, arrayObj, fieldName, formValues){
    if (rows.length <= 1) return;
    rows.splice(i,1);
    t.convert(arrayObj, fieldName, formValues);
  }
  convert(arrayObj, fieldName, formValues){
    var key, str, rows, i , row, index;
    rows = JSON.parse(JSON.stringify(arrayObj[fieldName].rows));
    if (cleanBlanksInArrays){
        for (i in rows){
            row = rows[i];
            for (key in row){
                if (row[key] == ''){
                    delete row[key];
                }
                if (row[key] == 'true') { row[key] = true; }
                if (row[key] == 'false'){ row[key] = false; }

                if ((index = key.indexOf('__')) >= 0){
                    var ar = key.split('__');
                    var newKey = ar[0];
                    row[newKey] = row[key];
                    delete row[key];
                }
            }
        }
    }
    str = JSON.stringify(rows);
    formValues[fieldName] = str;
  }
  sendRequest(accessLevel, method, path, data){
        var cp;
        t.resp = {};
        /*
        var special = accessLevel 
                      ? {level: accessLevel, userName: t.credUserName, password: t.credPassword}
                      : null;
        */
        if (accessLevel){
            t.special.level = accessLevel;
            t.special.daUserName = state.user.userName;
        }else{
            t.special = {};
        }
        cp = JSON.parse(JSON.stringify(data));
        // if any, turn array fields from strings to objects
        t.str2arObj(cp.form);
        apiDevIntModule.sendRequest(t.special, method, path, cp, t.resp);
  };

  str2arObj(form){
    var key;
    for (key in t.arrayObj){
        if (form[key]){
            if ((typeof form[key]) == 'string'){
                form[key] = JSON.parse(form[key]);
            }
        }
    }
  }

}
