var projectProxyModule = (function(){
    var publicStuff;
    var alerts = [];
    var alertSuccess = {type : 'success'};
    var alertError = {type : 'danger'};
    var hasAllocationRequest;
    var cookies , http , httpNew , scope , timeout , win ;
    cookies = http = httpNew = scope = timeout = win = null;    // cached services
    var jsServices = {};
    var comMod;
    var valMod;
    var dayjs = null;

    publicStuff = {
        init : init,
        validate : validate,
        validateRequestProject : validateRequestProject,
        projectRequestSystemAllocations : projectRequestSystemAllocations,
        setServices : setServices,
        addAllocationInfo : addAllocationInfo,
        allocationStatusLists : allocationStatusLists,
        toolTipInfo : toolTipInfo,
        showAllocationYear : showAllocationYear,
        postProjectRequest : postProjectRequest,
        showAllocation : showAllocation,
        updatePiProxies : updatePiProxies,
        isUserPI : isUserPI,
        getFiscalQuarter : getFiscalQuarter,
        isJLSEProject : isJLSEProject,
        putProjectRecord :  putProjectRecord,
        systemsList : systemsList,
        projectAllocation : projectAllocation,
        convertTBtoGB : convertTBtoGB,
        convertGBtoTB : convertGBtoTB,
        projectRequiresAllocations : projectRequiresAllocations,
        postProjectRecord : postProjectRecord,
        postReport : postReport,
        selectPI : selectPI,
        reapprovedUser : reapprovedUser
    };

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;
        comMod = jsServices.commonModule;
        valMod = jsServices.validationModule;
        dayjs    = jsServices.dayjs;
    }

    function init(flags, form, lists, s){
        scope = s;

        flags.fieldErrors = {};
        flags.missing = null;
        scope.authAlerts = [];
   if(!scope.lists.systems || !scope.lists.systems.length){
            systemsList(function(response){
                scope.lists.systems = response;
                Object.keys(scope.lists.systems).forEach(function(dict){
                    if(scope.lists.systems[dict].amount_requested){delete scope.lists.systems[dict].amount_requested}
                });
            });


    }

        if(flags.currBranch.division){flags.celsTooltips = toolTipInfo(flags.currBranch.division);}
        if(flags.viewType === "requestProject"){
            hasAllocationRequest = false;
            scope.form = {};
            scope.form.projectShortName = '';
            if(flags.currBranch.division === 'lcrc'){
                scope.form.storage = 1;
                hasAllocationRequest = true} else {
                scope.form.storage = '100';
            }

            scope.form.parentProjectShortName = flags.currBranch.division;
            scope.form.projectInstitutions = [{primaryInstitution : true}];

            // Show required fields on page load for project request
            validateRequestProjectNow(scope.form, scope.flags);
            scope.flags.delta = true;
            scope.flags.okToSubmit = false;
            flags.celsTooltips = toolTipInfo(flags.currBranch.division);
        }

    }

    function validate(form, flags){
        flags.fieldErrors = {};
        flags.submit = false;
        flags.okToSubmit = false;
        valMod.validate('manageProjects', flags.view, flags.fieldErrors);

        // hack for fields that we need to send in form to api
        // but we don't display them in UI
        delete flags.fieldErrors.parentProjectShortName;
        delete flags.fieldErrors.memberStatus;
        delete flags.fieldErrors.affiliation;
        flags.missing = (valMod.computeMissing(flags.fieldErrors));
        if(form.projectAllocations && form.projectAllocations.length){
            checkForAllocation(form.projectAllocations, flags);
        }
    }

    function validateRequestProject(form, flags){
      //  comMod.onStopCalling(700, function(){
            validateRequestProjectNow(form, flags);
     //   });
    }
    function validateRequestProjectNow(form, flags){
        if(scope.authAlerts){scope.authAlerts = [];}
        flags.fieldErrors = {};
        flags.submit = false;
        flags.okToSubmit = false;
        valMod.validate('projectRequest', form, flags.fieldErrors);
        if(flags.currBranch.division === 'lcrc' && form.projectAllocations && form.projectAllocations.length){
            checkForAllocation(form.projectAllocations, flags);
        }
        // this needs to happen after checking allocations and before computeMissing
        if(form.projectInstitutions && form.projectInstitutions[0].affiliation){
            delete flags.fieldErrors.bpiList}

        flags.missing = (valMod.computeMissing(flags.fieldErrors));
        flags.okToSubmit = !flags.missing;
    }

    function hasUnicode(s){
        return /[^\u0000-\u007f]/.test(s);
    }

    // new project and existing project request
    function checkForAllocation(allocationList, flags){
        flags.fieldErrors.allocation = {
            dispRequired : true,
            required : true,
            label : 'allocation'
        };
        if(!flags.amount_requested && flags.view){delete flags.view.reason}
        for(var dict in allocationList) {
            if(allocationList[dict].amount_requested){
                flags.amount_requested =  /\d/.test(allocationList[dict].amount_requested);
                delete flags.fieldErrors.allocation;
                if(flags.view && !flags.view.reason){
                    flags.fieldErrors.reason = {
                        dispRequired : true,
                        required : true,
                        label : 'Please add reason'
                    };
                    flags.missing = (valMod.computeMissing(flags.fieldErrors));
                } else{
                    if(hasUnicode(flags.view.reason)){
                        flags.fieldErrors.reason = {
                            dispRequired : true,
                            required : false,
                            error : 'Only UTF-8 characters allowed. Please modify your input to only use UTF-8 characters.',
                            label : 'reason'
                        };
                    }
                    else {
                         flags.okToSubmit = !flags.missing;
                    }
                }
                flags.missing = (valMod.computeMissing(flags.fieldErrors));
                if(flags.amount_requested){break}

            } else {
               flags.amount_requested  = false;
            }
        }

        Object.keys(allocationList).forEach(function(obj){
            if(allocationList[obj].maxAllocRequest && (allocationList[obj].amount_requested > allocationList[obj].maxAllocRequest)){
                allocationList[obj].exceedsLimit = true;//flags.okToSubmit = false;
                flags.delta = false;
            } else {delete allocationList[obj].exceedsLimit; flags.delta = true;}
        });
        delete flags.fieldErrors.parentProjectShortName;
    }

    function putProjectRecord(form, flags, lists, cookies, callback){
        var promises = flags.putsToCall.map(function(apiName){
            var objectToSend = {form : flags.putsToSendBack[apiName].filter(Boolean)};
           //alert(JSON.stringify(flags.putsToSendBack[apiName]));
            if(apiName == 'projectInstitutions' || apiName ==='publications' || apiName ==='grants' || apiName ==='attachments'){
                objectToSend.projectShortName = form.projectShortName;
               // objectToSend.form = objectToSend.form[0]
            }
            // fixme too complicated, make function
            if(apiName ==='publications'){apiName ='manageReportPublication'}
            if(apiName ==='grants'){apiName ='manageReportGrant'}
            if(apiName ==='attachments'){apiName ='manageReportAttachment'}
            return http.put('proxy/' + apiName, objectToSend);
        });

        return Promise.all(promises)
            .then(function(response){
                var resp = response[0];
                if(resp.success){
                    callback(resp);
                } else{
                    // there is an error, display message on page
                    callback(resp.data);
                    flags.error = resp.error;
                }
            }).catch(function(data){
                lists.errorEditRecord = data.detail;

            }).finally(function(){
            });
    }

    async function postProjectRecord(form, flags, cookies, callback){
        var role = 'proxy/', project_id, api, emailParams = {id : form.id};
        flags.postsToCall.map(async(apiName) => {
            // remove any nulls
            var hasAllocation = flags.postsToCall.includes("projectAllocations");

            if(hasAllocation){
                scope.flags.view.reason = flags.view.reason;
                completeAllocationRequest(flags.postsToSendBack.projectAllocations, form, function(response){
                    flags.postsToSendBack.projectAllocations = response;
                });
            }
            var array = flags.postsToSendBack[apiName].filter(Boolean);
            var allocationIdList = [];
            var count = 0;
            // need for loop else await won't work...
            for(const oneDict of array){
                var resp = {};
                if(oneDict.exceedsLimit){delete oneDict.exceedsLimit}
                var paramsToSend = {form : oneDict}
                if(apiName == 'projectInstitutions'){
                    paramsToSend.projectShortName = form.projectShortName;
                } else if(apiName == 'projectAllocations'){
                    apiName = "/allocationRequest", role = 'auth';
                    if(project_id){paramsToSend.form.project_id = project_id;}
                }

                await promiseFulfilled(role, apiName, paramsToSend).then((response) => {
                    resp = response.data;
                    if(resp.success){
                        callback(resp);
                        allocationIdList.push(resp.id);
                    } else{
                        callback(resp);
                    }
                }).finally(function(){
                    count += 1;
                    if(resp.success && count == array.length){
                        resp['postsComplete'] = true;
                        if(hasAllocation){
                             // send email request after all allocations have finished..
                            if(flags.projectRequest){
                                emailParams.parentProjectShortName = form.parentProjectShortName;
                                api = 'projectRequestHDEmail';
                                sendEmailRequest(api, emailParams);
                                delete flags.projectRequest;
                                 callback(resp);

                            } else{
                                emailParams.allocationIdList = JSON.stringify(allocationIdList);
                                emailParams.parentProjectShortName = 'lcrc';
                                api = 'allocationRequestHDEmail';
                                sendEmailRequest(api, emailParams);
                                delete flags.amount_requested;
                                 callback(resp);
                            }
                        } else {
                            // institution
                             callback(resp);
                        }
                    }
                });
            }
        });
    }

    function promiseFulfilled(role, apiName, paramsToSend){
        return new Promise(resolve => {
            // dont need timeout hack crap,
            //  setTimeout(() => {
            var userRequest = httpNew.post(role + apiName, paramsToSend);
            resolve(userRequest);
            //  }, 10);
        });
    }

    function postReport(form, flags, cookies, callback){
        var  role = 'proxy/',  emailParams = {id : form.id};
        var promise = flags.postsToCall.map(function(apiName){
            var array = flags.postsToSendBack[apiName].filter(Boolean);
            var count = 0;
            array.forEach(function(oneDict){
                oneDict.report_id = form.report_id;
                if(oneDict.publication_id){delete oneDict.publication_id}
                if(oneDict.grant_id){delete oneDict.grant_id}
                oneDict.status = 'Active';
                var paramsToSend = {form : oneDict};
                paramsToSend.projectShortName = form.projectShortName;
                if(apiName == 'publications'){
                    //delete oneDict.publication_id;
                    apiName = 'manageReportPublication';
                }

                if(apiName == 'grants'){
                    delete oneDict.grant_id;
                    apiName = 'manageReportGrant';
                }

                httpNew.post(role + apiName, paramsToSend)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            callback(resp);
                        } else{
                            callback(resp);
                        }
                    }).finally(function(){
                    // call email request after all allocations have finished..
                    count += 1;
                    if (count == array.length) {
                    }
                });
            });
        });
    }




    function reapprovedUser(id){
        var codes = scope.lists.reapprovedCollab;
        if(typeof codes !== "undefined"){
            for(var i = 0 ; i < codes.length ; i++){
                if(codes[i] === id){
                    return true;
                }
            }
        }
    }
    function updatePiProxies(pjName, proxyChanges, callback){
        proxyChanges.forEach(function(oneDict){
            if(oneDict.uName && oneDict.projectRole){
                oneDict.userName = oneDict.uName;
                delete oneDict.uName;
                replacePI(pjName, oneDict, callback)
                return
            } else{
                var resp;
                oneDict.memberStatus = "Active";
                oneDict.projectShortName = pjName;
                http.put("/proxy/manageProjects", oneDict)
                    .then(function(response){
                        resp = response.data;
                        callback(resp)
                    }).catch(function(data){
                })
            }
        });
    }

    function replacePI(pjName, obj, callback){
        //  It has 2 required parameters userName and shortProjectName.
        //  Notice: no form parameter, so no uirules changes required.
        //  Only a PI of the project can perform this api.
        //  Once you call this api then the user with userName will be the next PI.
        //  The user with userName has to be a member of the project, so the PI can not replace himself as PI with a non-member user.
        var cdata = {projectShortName : pjName,userName : obj.userName};
        var resp;
        http.put("/proxy/replacePI", cdata)
            .then(function(hresp){
                resp = hresp.data;
                callback(resp)
            }).catch(function(data){
        })
    }
    function postProjectRequest(http, form, flags, cookies, callback){
        if(form.id){delete form.id}
        if(form.parentProjectShortName !=='lcrc'){ delete form.projectAllocations}
        else{var projectAllocations = form.projectAllocations}
        var allocationIdList = [];
        var submitForm = {};
        var projectId = 0;
        var gceStorageValueInGB;
        var parentProjectShortName = flags.currBranch.division;
        if(parentProjectShortName === 'lcrc'){
            if(!form.storage){form.storage = 1 }
        } else{
            if(!form.storage){
                form.storage = 100;
            }
            if(form.storage) {
                gceStorageValueInGB = form.storage;
                // GCE convert GB to TB
                form.storage = convertGBtoTB(form.storage);
            }
        }
        buildBpiList(form.projectInstitutions, function(response){
            form.bpiList = response;
            submitForm = JSON.parse(JSON.stringify(form));
            delete submitForm.projectAllocations;
            delete submitForm.projectInstitutions;
            if(parentProjectShortName !== 'lcrc'){delete submitForm.scienceDescription}
            else {delete submitForm.reason}
            http.post("auth/projectRequest", {form : submitForm})
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        if(projectAllocations){
                            // LCRC only, always >= 1 allocation request
                            flags.postsToCall = ['projectAllocations'];
                            flags.postsToSendBack = {};
                            flags.postsToSendBack.projectAllocations = projectAllocations;
                            form.id = resp.id;
                            projectId = resp.id;
                            flags.projectRequest = true;
                            postProjectRecord(form, flags, cookies, function(response){
                                allocationIdList.push(response.id);
                                callback(response);
                            });

                        } else{
                            // ready to send email for GCE OR JLSE
                            sendEmailRequest('projectRequestHDEmail', {id : response.data.id, parentProjectShortName : form.parentProjectShortName});
                            callback(response.data);
                        }
                    } else{
                        if(resp.error === "{'__all__': ['Project with this Domain and Name already exists.']}" || resp.error === "We are having difficulty processing your request. Please try again. If you need help, contact support."){ resp.error = 'Project with this Name already exists.'}
                        else {resp.error += ' ' + scope.domainGlobals['helpDeskReplyClean']}
                        if(!scope.authAlerts.length){
                            scope.authAlerts.push({
                                type : 'danger',
                                msg : resp.error,
                                // just display message in container
                               // id : 'project-name-exists-already'
                            });
                            // we are still displaying value to user, show in GB
                            if(parentProjectShortName === 'gce'){
                                form.storage = gceStorageValueInGB;
                            }
                        }
                        // show dem da error
                        callback(response);
                    }
                }).catch(function(data){
                    }).finally(function(){

                    });
        });
    }

    function sendEmailRequest(api, p){
        httpNew.get('/auth/'+ api, {params : p})
            .then(function(resp){

            }).finally(function(){
            scope.flags.postsToCall = [];
            scope.flags.postsToSendBack = {};
        });
    }

    function buildBpiList(list, callback){
        var bpiList = [];
        Object.keys(list).forEach(function(dict){bpiList.push(list[dict].institutionId)});
        callback(bpiList);
    }

    function qtrDateSpan(){
        var currentQtr = dayjs().quarter() + 1;
        var currentYear = dayjs().format('YYYY');
        var nextYear = dayjs().add('years', 1).format('YYYY');
        var showYear;
        if(currentQtr === 2){showYear = currentYear}
        else if(currentQtr === 3){showYear = currentYear}
        else if(currentQtr === 4){showYear = currentYear}
        else if(currentQtr === 5){
            scope.flags.currentAllocYearOnly = true;
            showYear = nextYear;currentQtr = 1;}
         // quarter 1  October 1-December 31
        // quarter 2 January 1-March 31;
        // quarter 3 April 1-June 30;
        // quarter 4  July 1-September 30;
        return "Q" + currentQtr + " " + ' FY' + showYear.slice(-2);
    }

    function systemsList(callback){
        var standardQtr = dayjs().quarter();
        var currentYear = dayjs().format('YYYY');
        httpNew.get("auth/systems")
            .then(function(response){
                var resp = response.data;
                if(resp.success){
                    var systems = response.data.systems.filter(function(dict){
                        return dict.selectable
                    });
                    systems.sort(function (a, b) {return a.name.localeCompare(b.name)});
                    if(systems){
                        Object.keys(systems).forEach(function(dict){
                            systems[dict].method = 'post';
                            systems[dict].standardQtr = standardQtr;
                            systems[dict].year = currentYear;
                            systems[dict].qtr_date_span = qtrDateSpan();
                            systems[dict].nameAndType = systems[dict].name + ' : ' + systems[dict].systemUnit;
                            delete systems[dict].systemType;
                            delete systems[dict].systemUnit;
                        });
                    }
                    callback(systems);
                } else{
                    alertError.msg = resp.error;
                    alerts.push(alertError);
                }
            }).catch(function(response, status){
            alertError.msg = response.data.detail;
            alerts.push(alertError);
        });
    }

    function projectRequiresAllocations(projectShortName, parentProjectShortName, callback){
        var p = {projectShortName : projectShortName, parentProjectShortName : parentProjectShortName};
        http.get('/auth/projectParent', {params : p})
            .then(function(resp){
                callback(resp.data);
            }).catch(function(response, status){
        });
    }

    function completeAllocationRequest(allocationList, form, callback){
        var completeAllocationList = [], q, y, startDate, endDate;
        Object.keys(allocationList).forEach(function(dict){
            var d = allocationList[dict];
            // add if system was selected.
            if(d.amount_requested){
                if(d.standardQtr){q = d.standardQtr;delete d.standardQtr;}
                else if(d.standQtr){q = d.standQtr;delete d.standQtr;}
                if(d.year){y = d.year;delete d.year}
                else if(d.qYear){y = d.qYear;delete d.qYear;}
                if(scope.flags.view && scope.flags.view.reason){
                    // if there is reason this is an existing project
                    // same reason for each request in this submitted list
                    d.reason = scope.flags.view.reason;
                } else {
                    // if no reason was submitted it is a new project request
                    // same default reason is included
                    d.reason = "New Project Created LCRC"}
                if(d.name){delete d.name}
                if(d.method){delete d.method}
                // Generic setter, accepting unit as first argument, and value as second, returns a new instance with the applied changes.
                dayjs.tz.setDefault("America/Chicago");
                let qtrStartDate = dayjs().set('year', y).quarter(q).startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                let cdtTZstart = dayjs.tz(qtrStartDate);
                let qtrEndDate = dayjs().set('year', y).quarter(q).endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                let cdtTZend = dayjs.tz(qtrEndDate);
                startDate = dayjs.utc(cdtTZstart);
                endDate = dayjs.utc(cdtTZend);
                d.start_date = startDate;
                d.end_date = endDate;
                if(d.qtr_date_span){delete d.qtr_date_span}
                if(d.qYear){delete d.qYear}
                if(d.standQtr){delete d.standQtr}
                if(form.id){d.project_id = form.id; }
                delete d.selected;
                delete d.system;
                delete d.id;
                delete d.maxAllocRequest;
                delete d.minAllocRequest;
                delete d.nameAndType;
                completeAllocationList.push(d);
            }
        });
        callback(completeAllocationList);
    }

    function projectRequestSystemAllocations(systemList, callback){
        var Q1 = [], Q2 = [], Q3 = [], Q4 = [];
        var Q1NextQtrYear = [], Q2NextQtrYear = [], Q3NextQtrYear = [], Q4NextQtrYear = [];
        // CELS QTR Year ends on Sept 30,
        // does not follow standard Fiscal Calendar
        var celsQtr = dayjs().quarter() + 1;
        var currentYear = dayjs().format('YYYY');
        var nextYear = dayjs().add(1, 'y').format('YYYY');
        var forCurrentYear =  ' FY' + currentYear.slice(-2);
        var forNextYear =  ' FY' + nextYear.slice(-2);
        var Q1Name = "Q1";
        var Q2Name = "Q2";
        var Q3Name = "Q3";
        var Q4Name = "Q4";
        scope.flags.displayYears = [];
        // Show all for next year set here
        // August 9th show current QTR + All Next Qtr Year
        var targetStartDate = currentYear + '-08-12'  // YYYY-MM-DD
        var targetEndDate = currentYear + '-10-01'; // YYYY-MM-DD
        var displayAll;
        scope.flags.displayYears.push(currentYear.slice(-2));
        // https://day.js.org/docs/en/query/is-before
        // This indicates whether the Day.js object, (current date) is before OR after the supplied start/end date-time.
        if(dayjs().isAfter(dayjs(targetStartDate, 'month')) && dayjs().isBefore(dayjs(targetEndDate, 'month'))){
            displayAll = true;
            scope.flags.currentAllocYearOnly = false;
        } else{
            displayAll = false;
            scope.flags.currentAllocYearOnly = true;
        }

        if(displayAll){scope.flags.displayYears.push(nextYear.slice(-2))}
        Object.keys(systemList).forEach(function(dict){
            // we don't want any of these fields for adding allocation.
            delete systemList[dict].description;
            delete systemList[dict].purpose;
            delete systemList[dict].memberStatus;
            delete systemList[dict].selectable;
            delete systemList[dict].systemState;
        });
        if(celsQtr === 2){
            Q2 = JSON.parse(JSON.stringify(systemList));
            Q3 = JSON.parse(JSON.stringify(systemList));
            Q4 = JSON.parse(JSON.stringify(systemList));
        } else if (celsQtr === 3){
            Q3 = JSON.parse(JSON.stringify(systemList));
            Q4 = JSON.parse(JSON.stringify(systemList));
        } else if (celsQtr === 4){
            if(displayAll){
                // August 12th show current QTR + All Next Qtr Year
                // Q4 Jul 1-Sept 30 current year
                // Q1 Oct 1-Dec 31 next calendar year
                // Q2 Jan 1-Mar next year
                // Q3 Apr 1-Jun 30 next year
                // Q4 Jul 1-Sept 30 next year
                Q4 = JSON.parse(JSON.stringify(systemList))
                Q1NextQtrYear = JSON.parse(JSON.stringify(systemList));
                Q2NextQtrYear = JSON.parse(JSON.stringify(systemList));
                Q3NextQtrYear = JSON.parse(JSON.stringify(systemList));
                Q4NextQtrYear = JSON.parse(JSON.stringify(systemList));
            } else {
                Q4 = JSON.parse(JSON.stringify(systemList));
            }
        } else if (celsQtr === 5){
            //  CELS (fiscal) new year begins
            scope.flags.displayYears = [];
            scope.flags.displayYears.push(nextYear.slice(-2));
            Q1NextQtrYear = JSON.parse(JSON.stringify(systemList));
            Q2NextQtrYear = JSON.parse(JSON.stringify(systemList));
            Q3NextQtrYear = JSON.parse(JSON.stringify(systemList));
            Q4NextQtrYear = JSON.parse(JSON.stringify(systemList));
        }
        if(Q1.length){
            Object.keys(Q1).forEach(function(dict){
                Q1[dict].standardQtr = 4;
                Q1[dict].year = currentYear;
                Q1[dict].qtr_date_span = Q1Name + forCurrentYear;
            });
        }
        if(Q2.length){
            Object.keys(Q2).forEach(function(dict){
                Q2[dict].standardQtr = 1;
                Q2[dict].year = currentYear;
                Q2[dict].qtr_date_span = Q2Name + forCurrentYear;
            });
        }
        if(Q3.length){
            Object.keys(Q3).forEach(function(dict){
                Q3[dict].standardQtr = 2;
                Q3[dict].year = currentYear;
                Q3[dict].qtr_date_span = Q3Name  + forCurrentYear;
            });
        }
        if(Q4.length){
            Object.keys(Q4).forEach(function(dict){
                Q4[dict].standardQtr = 3;
                Q4[dict].year = currentYear;
                Q4[dict].qtr_date_span = Q4Name + forCurrentYear;
            });
        }
        if(Q1NextQtrYear.length){
            // begins new QTR Oct 1st of same year
            Object.keys(Q1NextQtrYear).forEach(function(dict){
                Q1NextQtrYear[dict].standardQtr = 4;
                Q1NextQtrYear[dict].year = currentYear;
                Q1NextQtrYear[dict].qtr_date_span = Q1Name + forNextYear;
            });
        }
        if(Q2NextQtrYear.length){
            Object.keys(Q2NextQtrYear).forEach(function(dict){
                Q2NextQtrYear[dict].standardQtr = 1;
                Q2NextQtrYear[dict].year = nextYear;
                Q2NextQtrYear[dict].qtr_date_span = Q2Name + forNextYear;
            });
        }
        if(Q3NextQtrYear.length){
            Object.keys(Q3NextQtrYear).forEach(function(dict){
                Q3NextQtrYear[dict].standardQtr = 2;
                Q3NextQtrYear[dict].year = nextYear;
                Q3NextQtrYear[dict].qtr_date_span = Q3Name  + forNextYear;
            });
        }
        if(Q4NextQtrYear.length){
            Object.keys(Q4NextQtrYear).forEach(function(dict){
                Q4NextQtrYear[dict].standardQtr = 3;
                Q4NextQtrYear[dict].year = nextYear;
                Q4NextQtrYear[dict].qtr_date_span = Q4Name + forNextYear;
            });
        }

        var allocationList = [].concat(Q1, Q2, Q3, Q4, Q1NextQtrYear, Q2NextQtrYear, Q3NextQtrYear, Q4NextQtrYear);
        // first is default/selected on page
        // No default selected
        // if(allocationList.length){allocationList[0].selected = true}
        callback(allocationList);
    }

    function addAllocationInfo(object, form){
        if(!object.selected){
            delete object.amount_requested;
            validate(form, scope.flags);
            return object;
        }
        else {
            object.system_id = object.id;
            object.system = object.name;
            object.qYear = object.year;
            object.standQtr = object.standardQtr;
           // object.max = object.maxAllocRequest;
            return object;
        }
    }

    function showAllocationYear(qy, year){
        if(!qy){return}
        var last2 = qy.slice(-2);
        var fy = last2.indexOf(year);
        if (fy > -1){
            return true
        }
    }
    function showAllocation(status, list, year){
        if(!list){return}
        if(typeof list !== "undefined"){
            for(var i = 0 ; i < list.length ; i++){
                for(var item in list[i]){
                    var endDate = list[i].end_date
                    var endDateYear = endDate.slice(0, 4);
                    if(list[i][item] === status && (endDateYear === year || year ==='any')){
                        return true
                    }
                }
            }
        }
    }

    function allocationStatusLists(allocations, lists){
        if(lists){scope.lists = lists}
        scope.lists.allocationsApproved = [];
        scope.lists.allocationsRequested = [];
        Object.keys(allocations).forEach(function(dict){
            var d = allocations[dict];
            if(d.status === 'Approved'){scope.lists.allocationsApproved.push(d)}
            if(d.status === 'Requested'){scope.lists.allocationsRequested.push(d)}
        });
    }

    // Existing Projects
    function projectAllocation(dict, callback){
        var p = {projectShortName : dict.projectShortName};
        var completeAllocationList = [];
        http.get("auth/projectAllocations", {params : p})
            .then(function(resp){
                resp = resp.data
                if(resp.success){
                    if(resp.projectAllocations.length){
                        Object.keys(resp.projectAllocations).forEach(function(dict){
                            var d = resp.projectAllocations[dict];
                            // Add Fiscal Quarter
                            getFiscalQuarter(d, function(year){
                                d.cels_quarter = year;
                                completeAllocationList.push(resp.projectAllocations[dict]);
                            });
                        });
                    }
                    // separate allocation lists for requested/approved types instead of creating conditionals in template
                    allocationStatusLists(completeAllocationList);
                    // Show all remaining QTRs - New Project Request & Existing Projects displayed same
                    projectRequestSystemAllocations(scope.lists.systems, function(allSystems){
                        dict.projectAllocations = allSystems;
                        callback(dict);
                    });

                } else{
                    alertError.msg = resp.error;
                }
            }).catch(function(response, status){
            alertError.msg = response.data.detail;
        });
    }

    function isJLSEProject(projectShortName, parentProjectShortName, callback){
        var p = {projectShortName : projectShortName, parentProjectShortName : parentProjectShortName};
        httpNew.get('/auth/projectParent', {params : p})
            .then(function(resp){
                callback(resp.data);
            }).catch(function(response, status){
        }).finally(function(){
        });
    }


    function convertTBtoGB(TB){
        var GB = TB * 1000;
        return GB;
    }
    function convertGBtoTB(GB){
        var TB = parseInt(GB)/1000;
        return TB;
    }

    function isUserPI(members){
        var uName = scope.user.userName;
        scope.flags.userIsPI = false;
        members.forEach(function(oneDict){
            if(uName === oneDict.userName && oneDict.projectRole ==='PI'){
                scope.flags.userIsPI = uName;
                // return true;
            }
        });
    }

    function selectPI(row, array){
        array.forEach(function(b){
            if(b.userName === row.userName && row.projectRole === 'PI'){b.projectRole = 'PI'}
            else {
                if(scope.flags.userIsPI === b.userName ){return}
                b.projectRole = 'Member'
            }
        });

    }

    function getFiscalQuarter(d, callback){
        // need to handle one object at a time.
        // page views displayed information differ based on status
        if(!d.start_date){return}
        var year = d.start_date.slice(0, 4);
        var qtrAndYear;
        // CELS quarter1  is October 1-December 31, not standard Jan 1st.
        var cels_qtr = dayjs.utc(d.start_date).quarter() + 1;
        if(cels_qtr === 5){cels_qtr = 1;year = parseInt(year) + 1}
        qtrAndYear = "Q" + cels_qtr + " " + year;
        callback(qtrAndYear);
    }

    function toolTipInfo(division){
        var toolTip = {
            title : 'Formal title for the project.',
            funding : 'Please list any funding agencies that support this project.',
            systems : 'Description of any other computing systems to which you have access, both internal and external to Argonne, including the amount of time you have on them.',
            science : 'Description of the science objective(s) for the project.',
            industry : 'If this project involves active, ongoing participation of industry partners with industry as the primary beneficiary, please describe.',
            description : 'A detailed description of the project and its goals. This may include such things as:\n' +
                'computational methods\n' +
                'programming model\n' +
                'experiments planned\n' +
                'software requirements\n' +
                'technical details\n' +
                'expected number of project members\n' +
                'If you plan on running non-parallel (single core/cpu) jobs, please specify the approximate percentage of the allocation that will be used for those jobs.',
        };

        if(division === 'lcrc'){
            toolTip.name = 'This will be the short name of the project on the LCRC cluster. It can only contain alphanumeric characters(A-Z,a-z,0-9), dashes(-), and underscores(_). It cannot have spaces.';
            toolTip.storage = 'Projects will be given 1 TB (Terabyte) of disk storage for its members’ shared use. If this project requires more than 1 TB of storage, please indicate how much is required and explain why it is needed.';
            toolTip.allocation = 'The number of core-hours of computation desired for each (remaining) quarter of the fiscal year. To estimate the number of core-hours needed, multiply the number of processor cores per node × the number of nodes for a run × the number of hours for a run × the number of runs. Include time for development and debugging in the total. Remember to take into consideration the number of people working on the project.\n' +
                'Allocation requests totaling 800,000 core-hours or more must provide evidence that the project can use the hours effectively. This evidence might include, but is not limited to, the following:\n' +
                '\n' +
                'scaling data for similar runs (most useful), and supporting single CPU performance analysis\n' +
                'details about improvements made to address bottlenecks, e.g., load balance, communications, I/O\n' +
                'how the time request was computed (substantiates the size of the request).\n' +
                'performance achieved either on LCRC or other systems\n' +
                'efficiency of the code'

        } else{
            // GCE
            toolTip.name = 'This will be the short name of the project. It can only contain alphanumeric characters(A-Z,a-z,0-9), dashes(-), and underscores(_). It cannot have spaces.';
            toolTip.storage = 'Projects may request 100 GB of disk storage for its members\' shared use. If this project requires more than 100 GB of storage, please indicate how much is required and explain why it is needed.';
        }
        return toolTip;
    }

    // ----------- public stuff gets returned -----------
    return publicStuff;
})();

module.exports = {projectProxyModule}

