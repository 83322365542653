<div class="manage-projects">
    <div class="reversed-white">
       <h1>{{flags.displayedPageName}}</h1>
       <div id="manageProject">Manage your project team and proxies</div>
    </div>
    <div class="ub3-card">
       <div class="card-body">
          <div class="alert alert-danger" *ngIf="lists.error">{{lists.error}}</div>
          <div *ngIf="!lists.error" class="user-records-table acc-upd myProjects">
             <table id="pjTable" class="table update-account">
                <thead>
                    <tr>
                        <th>
                            <span class="pjHeading"><h4 class="table-heading">Your Projects</h4></span>
                            <span class="apo">
                                <input type="checkbox"
                                [(ngModel)]="isProjActive"
                                (ngModelChange)="showYourProjects()"
                                id="activePjOnly"
                                class="activePjOnly">
                                Active Projects Only
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                   <tr>
                      <td colspan="12" *ngIf="currentProxyProjects && currentProxyProjects.length > 0">
                         Click on the name of the project to add and remove proxies and team members.
                      </td>
                   </tr>
                   <tr>
                      <td colspan="12" class="updateRecordTableSection">
                        <!-- To show a Spinner until page loads properly -->
                        <div class="loading-overlay" *ngIf="showSpinner">
                            <div class="spinner-border" role="status">
                                <span class="sr-only" id="spinner"></span>
                            </div>
                        </div>

                        <ngx-datatable
                            #projTable
                            id="projTable"
                            class="bootstrap card expandable updateRecordTable"
                            columnMode="flex"
                            [rowHeight]="auto"
                            [headerHeight]="30"
                            [footerHeight]="30"
                            [scrollbarH]="true"
                            [rows]="currentProxyProjects"
                            [sorts]="[{prop: 'projectShortName', dir: 'asc'}]">

                            <ngx-datatable-column name="Project Name"  prop="projectShortName" [flexGrow]=".5">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <a  class="projTableHeader bold"
                                        id="{{row.projectShortName}}"
                                        *ngIf="!row.noShow"
                                        (click)="goToMngPjEdit(row.projectShortName)"
                                        tooltip-placement="right">
                                        {{row.projectShortName}}
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Title"   prop="projectTitle" [flexGrow]="1">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span class="projTableHeader">
                                        {{row.projectTitle}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Project Status"   prop="projectStatus" [flexGrow]="1">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span class="projTableHeader" id="{{row.projectStatus}}_projectStatus">
                                        {{row.projectStatus}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                      </td>
                   </tr>
                </tbody>
             </table>
          </div>
       </div>
    </div>
</div>