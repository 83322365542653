import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { CommonService } from './../../../../../common/services/common.service';

let commonModule = null;
@Injectable({
    providedIn: 'root'
})

export class SweetAlert {
    constructor(private commonService: CommonService) {
        commonModule = commonService;
    }

    confirmSwitchRequests() {
        return swal.fire({
            html: `
                <h3 id="saHeader">
                    You are in the middle of a request.
                </h3>`,
            background: "#F8F9FA",
            padding: "20px 0 30px",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: `
                <span style="font-size: larger;" id="saContinue">
                    Continue Request
                <span>`,
            confirmButtonColor: "#337AB7",
            cancelButtonText: `
                <a style="color: #337AB7; text-decoration: underline; font-size: larger;" id="saCancel">
                    Cancel Request
                </a>`,
            cancelButtonColor: "#F8F9FA",
            allowOutsideClick: false
        }).then((result) =>{
            if (result.isConfirmed) {
                return false;
            } else if(result.isDismissed) {
                commonModule.signOut();
                return true;
            }
        });
    }
}