var apiDevIntModule = 
(function (){

    var cookies , http , httpNew , scope , timeout , win, comMod, valMod ;
    cookies = http = httpNew = scope = timeout = win = null; // cache for services
    var jsServices = {};
    var clog = null;

    var publicStuff ={ 
        getRules     : getRules,
        init         : init,
        overWriteScope: overWriteScope,
        sendRequest  : sendRequest,
        setServices  : setServices,
        validate     : validate,
    };

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;
        comMod  = jsServices.commonModule;
        valMod  = jsServices.validationModule;
        clog = comMod.ub3consoleLog;
    }
    function overWriteScope(s){
        scope = s;
    }

    function init(cb){

          scope.all = {};
          scope.flags = {};

          http.get('/admin/api')
               .success(function(resp){
                    var urls, forms, f, file, formArray;
                    urls = parseUrls(resp.urls);
                    forms = {};
                    for (file in urls){
                        parseApiFile(file, urls[file], resp[file], forms);
                        formArray=[];
                        for (f in forms){formArray.push(f)};
                    }
                    valMod.init(formArray);
                    scope.urls = urls;
                    scope.forms = forms;
                    var aUrls = [];
                    turnToArrays(scope.urls, aUrls, forms);
                    if (cb){cb(scope.urls, aUrls, scope.forms)}
                })
               .error(function(e){
                   //clog('need to report something to usr?');
                });

    };

    function turnToArrays(urls, aUrls, forms){
        var key, surls, paths, aPaths, k, func, aMethods, method, par, sortedPaths ;

        surls = comMod.sortOnKeys(urls, false);
        for (key in surls){
            paths = surls[key];
            aPaths = [];
            sortedPaths = comMod.sortOnKeys(paths, false);
            for (k in sortedPaths){
                func = comMod.objCopy(sortedPaths[k]);
                aMethods = [];
                // func.params needs to be an array of {method:method, params:params}
                for (method in func.params){
                    par = func.params[method];
                    aMethods.push({method:method, params:par});
                }
                // now replace it
                func.params = aMethods;
                aPaths.push({path:k, func:func});
            }
            var specialAccess = ((key == 'api_proxy') || (key == 'api_owner') || (key == 'api_limited'))
                                ? key.replace('api_','')
                                : null;
            aUrls.push({file: key, paths: aPaths, accessLevel:specialAccess});
        }
    }

    function getRules(formName, forms){
        var rules, ar, key, ruleObj;

        rules = valMod.getRules(formName);
        ar = [];
        for (key in rules){
            ruleObj = rules[key];
            ar.push({field:key, fRules:ruleObj});
        }
        if (!rules) rules = {};
        rules.array = ar;
        forms[formName] = rules;

    };
    function sendRequest(special, method, path, data, resp){
        if (special && special.level){
            tSignIn(special, function(success){
                if (success){
                  // at this point we are signed in as something other than domain admin
                  doSendRequest(method, path, data, resp, function(){
                    comMod.ub3consoleLog(118, '// now sign in as original domain admin user');
                    // now sign in as original domain admin user
                    comMod.signInNg(special.daUserName, special.password, scope, function(r){
                        comMod.ub3consoleLog(121, {flags:scope.flags, r: r});
                    });
                  });
                }else{
                    resp.error = special.error;
                    // now sign in as original domain admin user
                    comMod.signInNg(special.daUserName, special.password, scope, function(r){
                        comMod.ub3consoleLog(128, {flags:scope.flags, r: r});
                    });
                }
            });
        }else{
                  doSendRequest(method, path, data, resp, function(r){
                        comMod.ub3consoleLog(134, {flags:scope.flags, r: r});
                  });
        }
    }
    function doSendRequest(method, path, data, resp, cb){
        if (method == 'GET'){
            var cdata = comMod.objCopy(data);
            if (cdata.form) delete cdata['form'];
            http.get('/'+path, {params: cdata})
                 .success(function(hresp){ resp.response = hresp; cb(resp);})
                 .error(function(e) { resp.error = e; cb(resp);});
        }
        if (method == 'POST'){
            http.post('/'+path, data)
                .success(function(hresp){ resp.response = hresp; cb(resp);})
                .error(function(e){ resp.error = e; cb(resp);});       
        }
        if (method == 'PUT'){

            http.put('/'+path, data)
                .success(function(hresp){ resp.response = hresp; cb(resp);})
                .error(function(e){ resp.error = e; cb(resp);});       
        }
    };
    function validate(path, method, formName, form){
        var errors;

        if (!scope.flags.arrayFieldErrors){ 
            scope.flags.arrayFieldErrors = {};
        }
        if (!scope.flags.arrayFieldErrors[path]){
            scope.flags.arrayFieldErrors[path] = {};
        }
        errors = scope.flags.arrayFieldErrors[path][method] = {} ;
        
        if (form.form){
            // form.form gets ignored,  validate it sepparately
            valMod.validate(formName, form.form, errors);
        }else{
            valMod.validate(formName, form, errors);
        }
    };

    // don;t forget to call init()   from somewhere

function parseUrls(source){
    var p, files, line, path, file, func;

    // point it past the non-interesting stuff
    p = foo(source, 'API GEN BEGIN');

    files = {};
    while (p = foo(p, "    url(r'^")){
        line = p.split(')')[0];
        path = line.split('$')[0].split('^')[1];
        file = line.split(',')[1].split('.')[0].trim();
        func = line.split(',')[1].split('.')[1].trim();
        if (file.indexOf('api_') == 0){
            if (!files[file]){
                files[file] = {};
            }
            files[file][path] = {func:func};
        }

        p = foo(p,')');
    }
    return files;
}

function parseApiFile(filePrefix, funcs, source, forms){
    var methods, files, p, line, roles, params, i, method, sect, formName, func, comment;

    methods =  ['GET','POST','PUT','DELETE'] ;
    p = foo(source, '@api_view');

    files = {};
    
    while (p = foo(p, '@api_view')){
        line  = nextEnclosedBy(p, '@api_view', ':');
        roles = nextEnclosedBy(line,'@validate((',')');
        roles = roles.replace("@validate((",'');
        
        params = {};
        for (i in methods){
            method = methods[i];
            sect = foo(line, method+'_required_parameters');
            if (sect){
                params[method] = nextEnclosedBy(sect, '[',']');
                params[method] = convertStringsStringToArray(params[method]);
            }
        }
        sect = foo(line,'form_name');
        formName = null ;
        if (sect){
            formName = nextEnclosedBy(sect, '=', ')').trim().slice(1);
            formName = UCtoStr(formName, source);
        }
        addTo(forms,formName);
        func = null;
        sect = foo(line,'def ');
        if (sect){
            func = sect.replace('def ','').split('(')[0].trim();
        }

        comment = nextEnclosedBy(p,"'''","'''");

        insert(funcs, func, roles, params, formName, comment);

        p = foo(p,':');
    }

}
function addTo(dict,item){
    if (!item) return;
    
    if (!dict[item]){
        dict[item] = {};
    }
}
function UCtoStr(varName, sourceCode){
    var s, str ;
    if (!varName) return null;
    varName = varName.trim();
    if (varName == '') return null;
    if (varName == "''") return null;
    if (varName == 'None') return null;
    // now convert the UPPERCSE_FORM_NAME to the actual string
    var s = foo(sourceCode, varName);
    s = s.slice(0,200);
    str = s.split("'")[1];
    return str;
}

function insert(funcs, func, roles, params, formName, comment){
    var path, obj;
    for (path in funcs){
        obj = funcs[path];
        if (obj.func == func){
            obj.roles = roles;
            obj.params = params;
            obj.formName = formName;
            obj.comment = comment;
            return;
        }
    }
    comMod.ub3consoleLog('missing in urls.py');
}


function firstOccurrenceOf(s,sub){
	if (!s || (s == '')) return '';
	var i = s.indexOf(sub);
	if (i < 0) return '';
	return s.slice(i);
}
var foo = firstOccurrenceOf ;

function nextEnclosedBy(s, a, b){
    var sub;
	if (!s || (s == '')) return '';
	var ia = s.indexOf(a);
    if (ia < 0) return '';

    //sub = s.slice(ia);
    sub = s.slice(ia + a.length);

	var ib = sub.indexOf(b);
    if (ib < 0) return '';

    var r = sub.slice(0,ib);
    return r;
}

// s is a string that looks like this:
// "'firstName', 'lastName', 'email', 'reactivate'"
//
function convertStringsStringToArray(s){
    var s1 = comMod.replaceAll(s,"'",'"');
    var ar = JSON.parse('['+s1+']');
    return ar;
}

    function tSignIn(special, cb){
        if (special.level == 'limited'){
            var dt, ms ;
            dt = new Date() ;
            ms = dt.getTime();      // milli seconds since 1970
            special.email = 'e'+ms+'@ab.cd';
            special.firstName = 'F'+ms;
            special.lastName  = 'L'+ms;

            var form = {email: special.email, firstName: special.firstName, lastName: special.lastName};
            comMod.limitedLogin(form, scope, function(LLState){
                if (LLState.error){
                    special.error = LLState.error;
                    cb(false);
                    return;
                } 
                if (LLState.askForCode){
                    comMod.verifyEmailCode(scope, "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", LLState.nextAction, function(){
                        if (scope.flags.emailCodeVerified){
                            tCreateQA(function(result){
                                if (result.error) special.error = result.error;
                                cb(!result.error)
                            });
                        }
                    });
                }
            });
            return;
        }

        var p = {
            userName : special.userName,
            password : special.password
        };

        http.post("/public/loginUser", p)
            .success(function(resp){
                if(resp.success){
                    cb(true);
                } else{
                    cb(false);// could not login in
                }
            })
            .error(function(data){
                    cb(false); // could not login in
            });
    }
    function tCreateQA(cb){
        var p, result = {};
        p = {securityQuestion: 'what is this', securityAnswer: 'something'};
        http.post("/limited/createQuestionAnswer", p)
            .success(function(resp){
                if (resp.success){
                    result = {savedQ: true};
                    http.post("/limited/verifySecurityAnswer", p)
                          .success(function(r2){
                            if (r2.success){
                                result.verifiedA = true;
                                cb(result);
                            }else{
                                result.verifiedA = false;
                                cb(result);
                            }
                          });
                    
                }else{
                    result.error = resp.error ;
                    cb(result);
                }
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        result.error = 'Your session has expired. Please select "Cancel account request", and try again.'
                    }
                    result.error = msg.data.detail;
                }
                cb(result);
            });
      }

    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = { apiDevIntModule } ;


