

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { commonModule  } from 'commonModule';

var t: any = null;

@Component({
  selector: 'timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.css']
})
export class TimeoutModalComponent implements OnInit {

  state = null;

  constructor(private activeModal: NgbActiveModal) { 
      t = this;
      t.state = commonModule.getSharedObject('app','state');
  }

  ngOnInit(): void {
  }

}
