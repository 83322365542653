
<div>
    <input
       id="pjSelect1"
       *ngIf="pjBy=='pjName'"
       type="text"
       size=90
       autocomplete="nope"
       class="form-control"
       [(ngModel)]="pjSelect1"
       placeholder="Type Project Short Name here"
       ngDefaultControl
       [ngbTypeahead]="fnSelByPjName"
       (selectItem)="oneSelected($event)"
       [disabled]="maxReached"
       >
    <input
       *ngIf="pjBy=='PIName'"
       type="text"
       id="pjSelect2"
       size=90
       autocomplete="nope"
       class="form-control"
       [(ngModel)]="pjSelect2"
       placeholder="Type Principal Investigator Name here"
       ngDefaultControl
       [ngbTypeahead]="fnSelByPIName"
       (selectItem)="oneSelected($event)"
       [disabled]="maxReached"
       >
    <input
       *ngIf="pjBy=='pjTitle'"
       type="text"
       id="pjSelect3"
       size=90
       autocomplete="nope"
       class="form-control"
       [(ngModel)]="pjSelect3"
       placeholder="Type Project Title here"
       ngDefaultControl
       [ngbTypeahead]="fnSelByTitle"
       (selectItem)="oneSelected($event)"
       [disabled]="maxReached"
        >
        <select [(ngModel)]="pjBy"  id="pName" >
            <option value="" disabled>Search project by:</option>
            <option value="pjName" >Select by: Project Short Name</option>
            <option value="PIName" >Select by: PI Name</option>
            <option value="pjTitle">Select by: Project Title</option>
        </select>
    <!-- List of selected projects, so user can remove from the list -->
    <ng-container *ngIf="max > 1 && selectedPjs && selectedPjs.length > 0">
        <div class="seleProjs">Selected Projects</div>
        <div *ngFor="let pj of selectedPjs; let j = index" class="projs">
            <span class="projName" id="{{pj}}">{{pj}}</span>
            <span id="{{pj}}_remove" (click)="rmFromSelected(pj)">
                <span class="redFont">Remove</span>
                <span class="fa fa-times-circle"></span>
            </span>
        </div>
    </ng-container>

</div>

