import { Component, OnInit, Input } from '@angular/core';
import {CommonService, Ub3httpService} from '@common/services';
import { GlobalConstants } from '@common/constants/global-constants';

let t = null;

@Component({
  selector: 'alcf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  email   = '';
  url     = 'http://anl.us6.list-manage1.com/subscribe/post';
  params  = {
    u:'7d7e32ea6092c85ad51b75087',
    id:'61c971d0da'
  }
  domainGlobals: any = {};
  ALCFPolicyAgreementURL = GlobalConstants.ALCFPolicyAgreementURL;
  GetStartedURL = GlobalConstants.GetStartedURL;
  MachineStatusURL = GlobalConstants.MachineStatusURL;
  ALCFPolicyAckURL = GlobalConstants.ALCFPolicyAckURL;
  OverviewURL = GlobalConstants.OverviewURL;
  StaffDirectoryURL = GlobalConstants.StaffDirectoryURL;
  CareersURL = GlobalConstants.CareersURL;
  VisitUsURL = GlobalConstants.VisitUsURL;

  @Input() dmg;
  constructor(
    public http: Ub3httpService,
    public commonService: CommonService
  ) {
    t= this;
  }

  ngOnInit(): void {
    t.commonService.doWhenAPICallsDone(function(){
      t.domainGlobals = t.dmg;
      // window.scrollTo(0,document.body.scrollHeight);
    });
  }

  submit(){
    t.params.EMAIL = t.email;
    t.commonService.sendForm(t.url, t.params, '_blank');
  }
}
