var otherAccountsModule =
    (function(){
            "use strict";
            var publicStuff;
            var cookies, httpLegacy, http, scope, timeout, win, domMod, domModCels;
            cookies = httpLegacy = http = scope = timeout = win = domMod = domModCels = null;
            var jsServices = {};
            var comMod = null;
            var domMod = null;
            var alertError = {type : 'danger'};
            var alertSuccess = {type : 'success'};

            publicStuff = {
                init                    : init,
                accountServiceEmail     : accountServiceEmail,
                createOtherAccount      : createOtherAccount,
                getAllOtherAccounts     : getAllOtherAccounts,
                mailingList             : mailingList,
                serviceAccountEmails    : serviceAccountEmails,
                setServices             : setServices,
                showAddOtherAcctBtn     : showAddOtherAcctBtn
            };
            function setServices(services){
                var i, serv;
                for (i in services){
                  serv = services[i];
                  jsServices[serv.sname] = serv;
                }
                cookies     = jsServices.cookies;
                httpLegacy  = jsServices.http;
                http        = jsServices.httpNew;
                scope       = jsServices.scope;
                timeout     = jsServices.timeoutObj.timeout;
                win         = jsServices.window;
                comMod      = jsServices.commonModule;
                domMod      = jsServices.domainAdminModuleAlcf;
                domModCels  = jsServices.domainAdminModuleCels;
            }

            function init(scope, httpLegacy, http, pageName, lists, flags, alerts){
                comMod.publicPage(false);
                // shouldn't break lcf
                comMod.explorerPage(false);
                scope.containerFluid = true;
                var alerts = scope.domainAdminDashboardAlerts = [];
                // When we go back to Create Other Accounts page after adding a new account or after editing an existing account or when we click on back button, getAllOtherAccounts() should be called. We are currently facing many problems because of not calling the below function for every navigation back to the Create Other Accounts page.
                getAllOtherAccounts(http, lists, alerts, function(){
                // now add service emails to this list (service accts)
                serviceAccountEmails(http, lists, alerts);
                });
                if(!scope.flags){
                    scope.flags = {};
                }

                comMod.getSetContext(scope, pageName, function(){
                    var tmpFlags = {
                        // Steve clean this out, much is bs
                        displayedPageName : pageName,
                        hideHomePage : true,
                        otherAccountAdmin : true,
                        adminPageLevel : 4,
                        level : 4,
                        prevSavedForm : null,
                        fieldErrors : {},
                        delta : false,
                        editRecord : false,
                        submit : null,
                        altEmail : null,
                        showSubmit : null,
                        index : null,
                        requestType : null,
                        hideFormSection : null,
                        createOtherAccount : false,
                        missing : null,
                        viewDetails : false,
                        changedIdentity : false,
                        newServiceAcct : false,
                        admin_testing : false,
                    };
                    comMod.objCopyGuts(tmpFlags,flags);

                    if(!scope.form){
                        scope.form = {};
                    }
                    if(!scope.lists){
                        scope.lists = {};
                    }

                    if(!lists.systems && !scope.cels){
                       domMod.getSystemsList(http, flags, lists, alerts);
                    }

                    if(!lists.allUnixGroups && !scope.cels){
                        comMod.unixGroupList(http, lists, alerts);
                    }
                    if(!lists.allNetGroups && scope.cels){
                        domModCels.netGroupList(http, lists, alerts);
                    }
                    if(!lists.allProjects  && scope.cels){
                        domModCels.projectList(http, lists, alerts);
                    }
                    if(!lists.countries && scope.cels){
                        comMod.getCountries(lists);
                    }

                    if(!lists.unixShell && !scope.cels){
                        //temp until we have api
                        lists.unixShell = [
                            {name : "/bin/tcsh"},
                            {name : "/bin/bash"},
                            {name : "/bin/zsh"},
                            {name : "/bin/false"},
                            {name : "/sbin/nologin"}
                        ]
                    }
                });
            }

            function getAllOtherAccounts(http, lists, alerts, callback){
                var p = {search : "account_category=[system,test,service]"};
                http.get("/svc/Account", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            lists.allOtherAccounts = resp.data;
                            if(!scope.cels){
                                comMod.addDisplayName(lists.allOtherAccounts);
                            }
                            lists.loadingAllOtherAccounts = false;
                            lists.otherAccountData = true;
                            if(callback){callback();}
                        } else {
                            alertError.msg = resp.error + ' on api ' + (response.config.url)?response.config.url:'';
                            alerts.push(alertError);
                            lists.otherAccountData = false;
                        }
                    }).catch(function(response, status){
                    lists.otherAccountData = false;
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function accountServiceEmail(http, lists, flags, form, alerts, callback){
                var searchString = '*account_by_username=' + flags.userName;
                //var searchString =   "'account_by_username=="+flags.userName+"' 'member_status==active'";
                //var searchString = "'levelname==ERROR' 'action==TRIGGER-PLUGIN' 'created_date=>="+TIME_BEFORE_15_MINS+"'";
                var p = {search : searchString};
                http.get("/svc/AccountMailingList", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            if(resp.data[0] && resp.data[0].member_status ==='Active'){
                                form.serviceEmail = resp.data[0].MailingList.resource_ptr.email;
                            }
                            if(callback) callback();
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function serviceAccountEmails(http, lists, alerts, callback){
                var serviceUsersList = lists.allOtherAccounts.filter(function(obj){
                    return obj.account_category === 'Service';
                }).map(function(obj) {
                    return obj.username;
                });
                if(serviceUsersList.length > 0){
                    var searchString = '*account_by_username=[' + serviceUsersList + ']';
                    var p = {search : searchString};
                    http.get("/svc/AccountMailingList", {params : p})
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                // most service accts in cels do not have service email
                                var accEmailList = resp.data;
                                lists.allOtherAccounts.forEach(function(item){
                                    if(item.account_category === 'Service'){
                                        accEmailList.filter(function(acc){
                                            if(acc.member_status ==='Active' && acc.MailingList && acc.MailingList.resource_ptr && acc.username === item.username){
                                                item.service_email = acc.MailingList.resource_ptr.email;
                                            }
                                        });
                                    }
                                });
                                if(callback)callback();
                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);
                            }
                        }).catch(function(response, status){
                            if(response.data){
                                alertError.msg = response.data.detail;
                                alerts.push(alertError);
                            }
                        });
                } else {
                    if(callback)callback();
                }
            }

            function mailingList(http, lists, alerts, callback){
                var p = {search : "%"};
                http.get("/svc/MailingList", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            // sort alphabetically and ascending : objects in array by name
                            resp.data.sort(function (a, b) {
                                return a.name.localeCompare(b.mailing_list);
                            });
                            lists.mailingList = resp.data;
                            if(callback) callback();

                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }
            function adminValidate(name) {
                scope.adminAlerts = [];
                domMod.validate(http, timeout, scope.form, name, scope.flags, scope.adminAlerts, scope);
            };

            function createOtherAccount(http, flags, lists, acct_cat, scope, cb){
                var cat = acct_cat;
                if(scope.form){scope.form = {}}
                scope.form.accountCategory = cat;
                flags.editRecord = true;
                flags.createOtherAccount = true;
                flags.hideFormSection = true;
                flags.delta = false;

                if(acct_cat === 'Service' || acct_cat === 'Test'){
                    mailingList(http, lists, scope.adminAlerts);
                    if(!scope.cels){
                        if(!flags.fieldErrors){
                            flags.fieldErrors = {};
                        }
                        flags.fieldErrors.shell = {};
                        flags.fieldErrors.shell.dispRequired = true;
                        flags.fieldErrors.shell.required = true;
                        domMod.validate(http, timeout, scope.form, acct_cat, flags, scope.adminAlerts, scope)
                    } else{
                        // default shell for new service acct
                        scope.form.shell = "/usr/sbin/nologin";
                        scope.flags.division = "cels";
                    }
                    var d = new Date();
                    d.setFullYear(d.getFullYear() + 1);
                    if(!scope.cels){
                        adminValidate();   
                    } 
                    // else {   // removing this, as suggested by Steve
                    //     domModCels.validate(http, timeout, scope.form, acct_cat, flags, scope.adminAlerts, scope);
                    // }
                    flags.fieldErrors.email = {};
                    flags.fieldErrors.userName = {};

                    flags.fieldErrors.userName.dispRequired = true;
                    flags.fieldErrors.email.dispRequired = true;
                    flags.account_cat_selected = true;

                } else if(acct_cat === 'System'){
                    flags.account_cat_selected = true;
                } else{
                    flags.account_cat_selected = false;
                }
                comMod.doWhenAPICallsDone(function(){
                    if(cb){cb();}
                });
            }

            // defunct in cels
            function showAddOtherAcctBtn(flags, form){
                if(!flags.createOtherAccount){return}
                if(flags.emailMatched && form.userName){
                    if(form.accountCategory === 'Service' && (!flags.division && form.serviceEmail || flags.division === "cels") && !flags.fieldErrors.shell || form.accountCategory === 'Test'){return true}
                } else if(form.accountCategory === 'System' && form.firstName && form.lastName && form.preferredName && form.email && !flags.emailMatched){
                    return true
                } else{
                    return false
                }
            }

// ----------- public stuff gets returned -----------
            return publicStuff;

        }

    )
    ();

module.exports = {otherAccountsModule}

