import { Component, OnInit } from '@angular/core';
//import { Ub3httpService }    from '../../../shared/services/ub3http.service';
import { commonModule }      from 'commonModule'; // the UB3 one
import { validationModule }  from 'validationModule';
import { institutionModule } from '../../../../../../../../frontend/institutionModule';
// import { CommonService } from '@app/shared/services';
import {CommonService, Ub3httpService } from '@common/services';
import {CookieService} from 'ngx-cookie-service';
//import { DomainAdminModule } from 'projects/cels/src/app/domain-admin/domain-admin.module';



let scope:any = {
  sortInfo: {},
  lists: {institutionRecords: [], originalInstitution: []},
  urls: {},
  //cookies: null,
  flags: {openSection : {}, filters: {}, send: {}, arrayFieldErrors: {}, 
          showSubmit: false, garbage: 10, matchedInstitution: false, searchCompleted: false, bootstrapAlert: [],index: 0, tableSelected: ""},
  form: {searchInstitutions: ''},
  forms: {},
  all: {},
  yesno : {},
  domainAdminDashboardAlerts: {},
  adminAlerts: {}, 
};

let t = null;
let http = null;
let path = {};
let gcookies = null;

@Component({
  selector: 'institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.css']
})

export class InstitutionsComponent implements OnInit {
  // these objects are referenced from template (institutions.component.html)
  flags = null;
  lists = null;
  form = null;
  domainAdminDashboardAlerts = [];


  constructor(h: Ub3httpService, private cookies: CookieService, private window: Window, private commonService: CommonService ) { 
    t = this;
    http = h;
    this.flags = scope.flags;
    this.lists = scope.lists;
    t.lists.loadingInstitutions = false;
    this.form = scope.form;
    gcookies = cookies;
    
  }
      
  ngOnInit(): void {
    document.body.classList.remove('public-page');
    this.initInstitution('Institutions');
  }
    
  initInstitution(pageName){
      scope.flags['displayedPageName'] = pageName;
      institutionModule.init(scope, http, pageName, null);
      // have to set flags again since scope.flags is allocated in 
      //institutionModules.js
      this.flags = scope.flags;
  }
    
  institutionRequestAction(requestType, ms_state, id, index){
      t.domainAdminDashboardAlerts = [];
      institutionModule.requestAction(http, gcookies, scope.flags, scope.lists, requestType, ms_state, id, index, t.domainAdminDashboardAlerts, null);
  }
  adminSearchInstitutions(filter){
    t.domainAdminDashboardAlerts = [];
    institutionModule.adminSearchInstitutions(http, setTimeout, scope.flags, scope.form, scope.lists, filter, t.domainAdminDashboardAlerts);
  }

  adminValidateInstitutions(name){
        scope.adminAlerts = [];
        institutionModule.validate(http, setTimeout, scope.form, name, scope.flags, scope.adminAlerts, scope);
  }

  onFieldChange(action, index, tableName, type){
    t.domainAdminDashboardAlerts = [];
    // get the information of the manipulated object, and set flags.same when data same as original   

    // change empty comment textbox to null, so it matches with original value if originally untouched
    if(scope.lists.institutionRecords[index].comment == "")
      scope.lists.institutionRecords[index].comment = null; 

    scope.flags.same = t.commonService.isEqual(scope.lists.originalInstitutions,scope.lists.institutionRecords);
    commonModule.doWhenAPICallsDone(()=>{
      institutionModule.selectOption(action, scope.lists, scope.flags, index, tableName, type, t.domainAdminDashboardAlerts);
    });
  }
}
