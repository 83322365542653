import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {Router} from '@angular/router';
import { projectAdminModule } from 'projectAdminModule';
import {CommonService, Ub3httpService} from '@common/services';

var scope = null;
var t = null;
let http: any = null;
var ub3consoleLog = null;

@Component({
  selector: 'project-admin',
  templateUrl: './project-admin.component.html',
  styleUrls: ['./project-admin.component.css']
})
export class ProjectAdminComponent implements OnInit {

  flags = {displayedPageName: '', 
           cand:'', 
           piCand:null, 
           allowPjCreate:null, 
           pjsListError:null, 
           newProject:null, 
           pjaOkToSave:null,
           editProject:null
  };

  lists = {matchedProjects:null};
  pjToEdit = '';
  newOrEdit = null;

  constructor(public h: Ub3httpService, private commonService: CommonService,  private router: Router) {
      scope = t = this;
      http = h;
      ub3consoleLog = t.commonService.ub3consoleLog;
  }

  // The popstate event of the Window interface is fired when the active history entry changes while the user navigates the session history
  @HostListener('window:popstate')
  onPopState(){
    t.ngOnInit();
  }

  ngOnInit(): void {
      document.body.classList.remove('public-page');
      var pageName = 'Project Administration';
      scope.flags['displayedPageName'] = pageName;
      t.clearValues();
      projectAdminModule.init(scope, pageName);
  }

  clearValues(){
    t.pjToEdit = '';
    t.newOrEdit = null;
    t.lists.matchedProjects = [];
    t.lists.projAclList = [];
  }

  matchCandidateProject(filterName){
      projectAdminModule.filterProjects(scope.flags, scope.lists, filterName);
  }
  initProjectEditForm(pjName, newOrEdit){
    t.router.navigate(['/projectEdit'], {queryParams: {name: pjName, opt: newOrEdit}});
  }
  onEditDone(){
      t.pjToEdit = '';
      t.router.navigateByUrl('/projectAdmin');
      t.matchCandidateProject('byProjectName');
  }

}

