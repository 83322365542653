import { Component, OnInit } from '@angular/core';
import {CommonService} from '@common/services';

var t: any = null;
var ub3consoleLog = null;

@Component({
  selector: 'account-update',
  templateUrl: './account-update.component.html',
  styleUrls: ['./account-update.component.css']
})
export class AccountUpdateComponent implements OnInit {

    state: any = null;
    userName: string = null;

    constructor(
          private commonService: CommonService,
    ){
          ub3consoleLog = commonService.ub3consoleLog;
          t = this;
          t.state = t.commonService.getSharedObject('app','state');
    }

    ngOnInit(): void {
          document.body.classList.add('public-page');
          t.commonService.doWhenAPICallsDone(function(){
            t.userName = t.state.user.signedIn ? t.state.user.userName : null;
          });
    }

}
