
import { Component, OnInit, Input } from '@angular/core';
import {CommonService} from '@common/services';

@Component({
  selector: 'warning-svc',
  templateUrl: './warning-svc.component.html',
  styleUrls: ['./warning-svc.component.css']
})
export class WarningSvcComponent implements OnInit {

  arrayFieldErrors = null;

  constructor(private commonService: CommonService) { }

  @Input() path: string;
  @Input() method: string;
  @Input() fid: string;

  ngOnInit(): void {
    this.arrayFieldErrors = this.commonService.getSharedObject('WarningSvcComponent', 'arrayFieldErrors');
  }

}
