import { Router, CanActivate, CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import {CommonService} from '@common/services';
import {ConfigService} from "@portal/services/config.service";

let t = null;
var appFunctions;

@Injectable({providedIn: 'root'})
class AuthGuard {
  state: any = {};
  constructor(private router: Router,
              private commonService: CommonService,
              private configService: ConfigService
  ) {
    t = this;
    t.state = t.commonService.getSharedObject('app', 'state');
    appFunctions = t.commonService.getSharedObject('app', 'functions');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      var currPg = state.url.replace('/','').split('?')[0];
      return t.checkAccessRights(currPg);
  }
  checkAccessRights(currPg){
    appFunctions.setUser();
    t.commonService.doWhenAPICallsDone(function(){
      var portalOrigin  = t.configService.appRoutes.portal;
      if(t.state.user.signedIn) {
        var menus = t.state && t.state.menuPaths && t.commonService.getMenuItems(t.state);
        if(menus && menus.includes(currPg)) {
          return true;
        } else {
          t.router.navigate(['/dashboard']);
          return false;
        }
      } else {
        var hashVal = window.location.hash;
        // if query params exist in the url, use '&', otherwise use '?', before 'domain=ub3'
        var join = (hashVal.includes('?')) ? '&' : '?';
        var href =  portalOrigin + '/' + hashVal + join + 'domain=ub3';
        window.location.href = href;
        return true;
      }
    });
  }
}

export const AuthGuardService: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(route, state);
}
