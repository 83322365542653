
// Service API Module

var svcApiModule =
(function () {

    var cookies , http , httpNew , scope , timeout , win, comMod, valMod ;
    cookies = http = httpNew = scope = timeout = win = comMod = valMod = null;  // cached services
    var jsServices = {};
    var clog = null;

    var publicStuff = {
        clickMethodSection:  clickMethodSection,
        colToggle   : colToggle,
        init        :init,
        initPutForm :initPutForm,
        validate    :validate,
        request     :request,
        getHelp     :getHelp,
        getChoices  :getChoices,
        getOriginalRow :getOriginalRow,
        getRules    :getRules,
        setServices :setServices
    };

    /*
    // when this function is called from angular 1, it receives 6 parameters
    // when this function is called from angular 9, it receives 8 parameters
    //
    function setServices(c, h, hNew, s, t, w, cm, vm){
        cookies = c;
        http = h;
        httpNew = hNew;
        scope = s;
        timeout = t;
        win = w;
        comMod = (cm) ? cm : commonModule;
        valMod = (vm) ? vm : validationModule;
    }
    */
    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;

        comMod = jsServices.commonModule;
        valMod = jsServices.validationModule;
        clog = comMod.ub3consoleLog;
    }

    function init(s, pageName, callback){      // callback is only used when called from angular 9
     scope = s;
     scope.publicPage(false);
     comMod.explorerPage(false);
     scope.containerFluid = true;

     comMod.getSetContext(scope, pageName, firstTime, subsequentTimes);

     function firstTime(){
      scope.all = {};
      scope.yesno = {};
      if (!scope.flags) scope.flags = {};   // this change might break angular 1
      scope.flags.displayedPageName = pageName ;
      scope.flags.abbreviate = true ;
      scope.flags.administration = true ;
      scope.sortSvcInfo[pageName] = {sortBy:'id', reverse:false} ;
      scope.flags.svcExclude = [
        'svc/UserGroup',
        'svc/genToken',
        'svc/getClientApiList',
        'svc/revokeAllTokens',
        'svc/revokeToken',
        'svc/ub3cache_builder.py',
        'svc/userbase3_expire_email.py',
        'svc/whoami',
        'svc/refreshToken',
      ];
/*
      new ones since last update:
        AccountMailingList
        AccountType
        ProjectSourceOfFunding
        ResourceType
        SourceOfFunding
*/
      //LDAPSync - change like gentoken. it just runs without parameters
      //   no need , if you read the help, it tells you how to search

      if (!scope.vRules) scope.vRules = {};
      if (!scope.lists) scope.lists = {};

      http.get('/admin/api')
           .success(function(resp){
                var forms, urls, file, formArray, f;
                urls = parseUrls(resp.urls);
                forms = {};
                for (file in urls){
                    parseApiFile(file, urls[file], resp[file], forms);
                    formArray=[];
                    for (f in forms){formArray.push(f)};
                }
                valMod.init(formArray);
                const api_svc = comMod.sortOnKeys(urls.api_svc);
                const api_svc_arm = comMod.sortOnKeys(urls.api_svc_arm);
                const api_svc_ni = comMod.sortOnKeys(urls.api_svc_ni);
                urls.api_svc = {
                    ...api_svc,
                    ...api_svc_arm,
                    ...api_svc_ni,
                };
                scope.urls = urls;
                scope.forms = forms;
                if (callback) callback();
            })
           .error(function(e){
                //clog('error', e);
            });       
     }
     function subsequentTimes(){
                if (callback) callback();
     }

    }

    function initPutForm(path, formName, oneRow){
        var a, params, i, v, bv, fn, fieldName, formRules, fieldType;

        scope.flags.openSection[path]['PUT'] = true; 
        a = scope.all;
        if (!a[path])           a[path]={};
        if (!a[path].PUT)       a[path]['PUT']={};
        //if (!a[path].PUT.form)  a[path]['PUT']['form']={};
        a[path]['PUT']['form']={};

        // clear out previous errors
        if (scope.flags.arrayFieldErrors && scope.flags.arrayFieldErrors[path]){
            scope.flags.arrayFieldErrors[path] = null;
        }
        // copy fields of interest
        params = scope.all[path]['PUT'];
        params['search'] = 'id='+oneRow.id;

        // initialize yesno structure,  for boolean special radio buttons
        if (!scope.yesno)           scope.yesno = {};
        if (!scope.yesno[path])     scope.yesno[path] = {};
        if (!scope.yesno[path].PUT) scope.yesno[path].PUT = {};
        if (!scope.yesno[path].PUT.form) scope.yesno[path].PUT.form = {};

        if (!scope.flags.send)           scope.flags.send = {};
        if (!scope.flags.send[path])     scope.flags.send[path] = {};
        if (!scope.flags.send[path].PUT) scope.flags.send[path].PUT = {};

        if (!scope.flags.isBlank)           scope.flags.isBlank = {};
        if (!scope.flags.isBlank[path])     scope.flags.isBlank[path] = {};
        if (!scope.flags.isBlank[path].PUT) scope.flags.isBlank[path].PUT = {};

        if (!scope.flags.isNull)           scope.flags.isNull = {};
        if (!scope.flags.isNull[path])     scope.flags.isNull[path] = {};
        if (!scope.flags.isNull[path].PUT) scope.flags.isNull[path].PUT = {};

        formRules = valMod.getRules(formName);

        if(scope.lists.dbInfo[path].update_fields !== "Not Applicable") {
            for (i in scope.lists.dbInfo[path].update_fields){
                fn = scope.lists.dbInfo[path].update_fields[i];
                if (fn in formRules){
                    v = oneRow[fn];
                    fieldType = formRules[fn].type;
                    params.form[fn] = oneRow[fn];

                    // special processing for booleans
                    if (fieldType == 'bool'){
                        bv = v;
                        if ((v != true) && (v != false)) bv = formRules[fn].bool_default;
                        params.form[fn] = bv;

                        scope.yesno[path].PUT.form[fn] = bv ? 'yes' : 'no';
                    }
                    if (v && v !== false){
                        scope.flags.send[path].PUT[fn] = true;
                        scope.flags.isBlank[path].PUT[fn] = false;
                        scope.flags.send[path].PUT[fn] = false;
                    }
                }
            }
        }

        // handle _id fields with this special loop
        i = 0;
        for (fieldName in params.form){
            if (fieldName.endsWith('_id')){
                // asynchronous call:
                var counter = {count: 0};
                handleGroupedFields(formName, params, fieldName, counter, function(){
                    if (counter.count == 0){
                        // validate again.  Validation from line after the for loop was already done
                        validateNow(path, 'PUT', formName, params, null, true);
                    }
                });
            }
        }
        validateNow(path, 'PUT', formName, params, null, true);
    }
    function initBooleans(path, method, formName, currValues, form){
        var formRules, i, v, fn, fieldType, bv;

        formRules = valMod.getRules(formName);

        if (!scope.lists.dbInfo[path]) return;

        if(scope.lists.dbInfo[path].update_fields !== "Not Applicable") {
            for (i in scope.lists.dbInfo[path].update_fields){
                fn = scope.lists.dbInfo[path].update_fields[i];
                if (fn in formRules){
                    v = currValues[fn];
                    fieldType = formRules[fn].type;
                    form[fn] = v;

                    // special processing for booleans
                    if (fieldType == 'bool'){
                        bv = v;
                        if ((v != true) && (v != false)) bv = formRules[fn].bool_default;
                        form[fn] = bv;

                        scope.yesno[path][method].form[fn] = bv ? 'yes' : 'no';
                    }
                    v = form[fn];
                    if ((v != undefined) || (v == true) || (v == false)){
                        scope.flags.send   [path][method][fn] = true;
                        scope.flags.isBlank[path][method][fn] = false;
                        scope.flags.isNull [path][method][fn] = false;
                    }
                }
            }
        }
    }

    function clickMethodSection(path, method, formName){

        var fields, field, form, fieldName, v;
        if (method !== 'POST') return;

        // section to initialize booleans to false when doing a POST
        fields = scope.vRules[formName];

        if (!scope.all) scope.all = {};
        if (!scope.all[path]) scope.all[path] = {};
        if (!scope.all[path][method]) scope.all[path][method] = {form: {}};

        if (!scope.yesno) scope.yesno = {};
        if (!scope.yesno[path]) scope.yesno[path] = {};
        if (!scope.yesno[path][method]) scope.yesno[path][method] = {form: {}};

        if (!scope.flags.send) scope.flags.send = {};
        if (!scope.flags.send[path]) scope.flags.send[path] = {};
        if (!scope.flags.send[path][method]) scope.flags.send[path][method] = {};

        if (!scope.flags.isBlank) scope.flags.isBlank = {};
        if (!scope.flags.isBlank[path]) scope.flags.isBlank[path] = {};
        if (!scope.flags.isBlank[path][method]) scope.flags.isBlank[path][method] = {};

        if (!scope.flags.isNull) scope.flags.isNull = {};
        if (!scope.flags.isNull[path]) scope.flags.isNull[path] = {};
        if (!scope.flags.isNull[path][method]) scope.flags.isNull[path][method] = {};

        form = scope.all[path][method].form ;
        initBooleans(path, method, formName, {}, form);
/*
        for (fieldName in fields){
            field = fields[fieldName];
            if (field.type == 'bool'){
                // initialize only if not already set
                v = form[fieldName];
                if ((v !== false) && (v !== true)){
                    v = form[fieldName] = fields[fieldName].bool_default;
                }
                scope.yesno[path][method].form[fieldName] = v ? 'yes' : 'no';
                scope.flags.send [path][method][fieldName] = true;

                // this exception condition is for issue 2790
                if ((fieldName == 'force_delete') || (fieldName == 'deleted')){
                    delete scope.yesno[path][method].form[fieldName];
                    delete form[fieldName];
                    scope.flags.send[path][method][fieldName] = false;
                }
            }
        }
*/
    }

    var colDispCache = {};
    // a call to this function does 3 things:
    //  1 - modifies the contents of the large data table. Adds columns or deletes columns
    //  2 - saves the column display settings as a cookie (what to display , what not to)
    //  3 - returns the new column display settings (a dictionary with true, false values)
    //
    function colToggle(tableName, table, colName, dispv){
        var key, on, i, c, original, cookieName, infoDict, cv, disp, dispAll ;
        disp = {};

        if (!colName){
            // data just came from backend. Overwrite cache
            colDispCache[tableName] = comMod.objCopyArray(table);   
        }
        if (!colDispCache[tableName]){      
            // if not in cache, copy it to cache
            colDispCache[tableName] = comMod.objCopyArray(table);   
        }
        original = colDispCache[tableName];

        // retrieve settings from cookie, if no cookie, set defaults to true
        cookieName = 'oth-scr-col-disp';
        //c = cookies.get(cookieName);
        c = localStorage.getItem(cookieName);
        dispAll = {};   // this object has all fields defaulted to display
        for (key in table[0]){
            dispAll[key] = true;
        }
        if (c){
                infoDict = JSON.parse(c);
                cv = infoDict[tableName];
                if (cv){
                    disp = jsonDecode(cv, original[0]);
                }else{
                    disp = dispAll;
                }
        }else{ //           if no cookie, set all columns to display
                infoDict = {};
                disp = dispAll;
        }


        // check if there is a change and set it
        if (colName){
            if ((colName == 'check-all') || (colName == 'un-check-all')){
                // set all to checked or unchecked
                dispv = (colName == 'check-all');
                for (key in disp){
                    disp[key] = dispv;
                }
            }else if ((dispv == true) || (dispv == false)){
                disp[colName] = dispv;
            }
        }
        // htmlId has to be always on, no matter what.  It does not get displayed
        disp.htmlId = true;
        // id has to be always on, no matter what
        if (disp.id === false) disp.id  = true;

        for (key in disp){              // check all columns
                on = disp[key];
                if (on && table.length && (!(key in table[0]))){    // add it if not there already

                    // this loop modifies the large data table 
                    for (i = 0; i < original.length; i++){
                        table[i][key] = original[i][key];
                    }
                }
                if (!on && table.length && (key in table[0])){    // delete it if there

                    // this loop modifies the large data table 
                    for (i = 0; i < original.length; i++){
                        delete table[i][key];
                    }
                }
        }
        infoDict[tableName] = jsonEncode(disp);

        // now save settings in cookie
        //const dateNow = new Date();
        //dateNow.setDate(dateNow.getDate() + 8); // cookie expiration date maxes out at 7 days
        var str = JSON.stringify(infoDict);

        //var maxAgeSeconds = 60 * 60 * 24 * 10 ; // 10 days
        localStorage.setItem(cookieName, str);
        //cookies.set(cookieName, str, dateNow);
        return comMod.sortOnKeys(disp, false);
    }

    // These 2 functions are very specific for Other Screens column settings.
    function jsonEncode(disp){
        var s, key, tableSettings, result, tCount, fCount, defVal, exVal ;
        tableSettings = comMod.sortOnKeys(disp);
        tCount = fCount = 0;
        for (key in tableSettings){
            if (tableSettings[key] == true ) tCount ++;
            if (tableSettings[key] == false) fCount ++;
        }
        defVal = (tCount > fCount) ? true : false;
        exVal = !defVal;
        s = '';
        for (key in tableSettings){
            if (tableSettings[key] == exVal) s += (key + ' ');
        }
        result = {v: defVal, e:s};
        return result;
    }
    function jsonDecode(infoDict, oneRow){
        var sorted, idx, result, key, ar,i;

        sorted = comMod.sortOnKeys(oneRow);
        idx = 0;
        result = {};
        // set all to default value
        for (key in sorted){
            result[key] = infoDict.v ;
        }
        // now set the exception values
        ar = infoDict.e.split(' ');
        for (i in ar){
            key = ar[i];
            if (key != '') result[key] = (!infoDict.v) ;
        }
        return result;
    }

    function getOriginalRow(tableName, id){
        var i, tbl, row, result ;
        tbl = colDispCache[tableName];
        for (i in tbl){
            row = tbl[i];
            if (row.id == id){
                result = comMod.objCopy(row);
                return result;
            }
        }
        return {};
    }

    function getHelp(flags, getIt, path){
        var ex,i; // exception flag for how to get help
        if (!getIt) return;
        if (flags.help && flags.help[path]){
            //already have it
            return;
        }
        if (!flags.help){
            flags.help={};
        }
        var data = {search: '?doc'};
        http.get('/'+path, {params: data})
            .success(function(hresp){ 
                var h, line, heading;
                if (hresp.success){
                    h = {};
                    if (hresp.data.search_doc){
                      for (i in hresp.data.search_doc){
                        line = hresp.data.search_doc[i];
                        if (line == line.toUpperCase()){
                            heading = line ;
                            h[heading] = [];
                        }else{
                            h[heading].push(line);
                        }
                      }
                      flags.help[path] = h;
                    }else{
                      jjj = JSON.stringify(hresp.data) ;
                      jjj = comMod.replaceAll(jjj, '","','", "');
                      flags.help[path] = {'Generic Help': [jjj]} ;
                    }
                }
            })
            .error(function(e) { 
                    //clog(e);
            });
        
    }

    function getRules(formName, vRules){  
        var rules, comment;
        var key, row, fdName, othFdName;
        rules = valMod.getRules(formName);
        if (!rules) return;
        vRules[formName] = rules;    

        // do a trick so the 'comment' field goes to the end of the form
        // for the institutions screen
        comment = rules.comment;
        if (comment){
            delete rules['comment'];
            rules.comment = comment;
        }

        //set the yellow coloring for associated fields
        // no need to do work here. This work was done in backend. The flag to look for 
        // is update_name
    };

    // this function will update the rules in vRules1
    // It does this only for the path.  Meaning,  for one API name
    // vRules1 = Validation Rules.  same as $scope.vRules[formName]
    //
    function getChoices(path, vRules1, lists, callback) {
        var data;
        lists.searchPath = path;

        // instead of trying to reuse cached value of dbInfo, set this to 
        // null so it gets the info from backend again
        lists.dbInfo = {};
        
        if (!lists.filterValues){
            lists.filterValues = {};
        }
        if (!lists.filterRows){
            lists.filterRows = {};
        }
        if (!scope.flags.filterRowNumber){
            scope.flags.filterRowNumber = {};
        }
        if (!scope.flags.filters){
            scope.flags.filters = {};
            scope.form.searchBoxValue = '';
        }
        if (!scope.flags.filters[path]){
            scope.flags.filters[path] = [{
                selectedSf: null,
                filter_name: '',
                selectedOp: null,
                selectedVal: null,
                presetWithValue: null,
                active: false,
                selectedValues: [],
                showRemove: false
            }];
        }
        if (lists.dbInfo[path]){
            if (callback) callback();
        }else{
            data = {search: '?'};
            var par = {params: data};
            var apiFname = '/'+path;
            http.get(apiFname, par)
                .success(function(hresp){ 
                    if (hresp.success){
                        // We are now hiding the 'ALL' search filter in svc api - search
                        hresp = comMod.removeALLsearchFilter(hresp);
                        lists.dbInfo[path] = hresp.data;
                        lists.filterValues[path] = {
                            searchFields : hresp.data.search_info_list || []
                        };
                        scope.flags.fldDelimiter = hresp.data.filter_delimiter;
                        scope.flags.listDelimiter = hresp.data.list_delimiter || ',';
                        scope.flags.nullTag = hresp.data.null_tag;
                        scope.flags.showSearchPath = true;
                        updateVRules();
                        setDisabledFields();
                    }
                    if (callback) callback();
                })
                .error(function(e) { 
                    lists.dbInfo[path] = 'empty';
                    lists.filterValues[path] = {
                        searchFilterErrorMsg : e.error
                    };
                    if (callback) callback();
                });
        }
        function setDisabledFields(){
            var fn, fdObj, prefix;
            if (!vRules1) return;

            if (vRules1.deleted) {
                //vRules1.deleted.disabled = true;
            }
            if (vRules1.gid) {
                vRules1.gid.disabled = true;
            }
            for (fn in vRules1){
                fdObj = vRules1[fn];
                fdObj.display = true;
                fdObj.disabled = false;

                if (fn == 'deleted'){
                    //fdObj.display = false;
                }
            }
        }
        function updateVRules(){
            var i, choices, updFields, enumChoices, fieldName;
            updFields   = lists.dbInfo[path].update_fields;
            enumChoices = lists.dbInfo[path].enum_choices;
            if (!updFields || (updFields == "Not Applicable")) return

            if (!vRules1){
                vRules1 = {};
            }
            // delete the ones that are not needed
            // the ones in vRules1, but not in updFields
            for (fieldName in vRules1){
                if (updFields.indexOf(fieldName) < 0) {
                    //Not in the array
                    delete vRules1[fieldName];
                }
            }

            // add the ones needed
            // the ones not in vRules1, but in updFields
            for (i in updFields){
                fieldName = updFields[i];
                if (fieldName && !vRules1[fieldName]){
                    vRules1[fieldName] = {
                        label: fieldName,
                        type : 'str',   // default
                        required: false,
                        min_chars: 0,   // default
                        max_chars: 255, // default
                        max_array: null
                    }
                };
                if (fieldName.indexOf('_date') >= 0){
                    vRules1[fieldName].type = 'date';
                }
            }

            // now add the missing properties into vRules1
            // set .type to 'enum' if needed, and add enum array if so
            for (fieldName in enumChoices){
                choices = enumChoices[fieldName];   // this is an array
                if (vRules1[fieldName]){
                    vRules1[fieldName].type = 'enum';
                    vRules1[fieldName].enum = choices ;
                }else{
                    comMod.ub3consoleLog('confused !!', fieldName, 'not in vRules1 for', path);
                }
            }
            for (fieldName in vRules1){
 //               vRules1[fieldName].required = false ; // don't use the required flag

                // Exceptions below
                if (fieldName == 'mail_text'){
                    vRules1[fieldName].type = 'textArea';
                }
                if (fieldName == 'project_description'){
                    vRules1[fieldName].type = 'textArea';
                }
                if (fieldName == 'maillist_meta_data'){
                    vRules1[fieldName].type = 'textArea';
                }
            }

        }
    }
    //
    // c3 has the overwrite rules from the 3 columns with checkboxes: send, null, blank
    //
    function request(rules, method, path, data, resp, lists, c3, callback){
        resp.error = null;
        if (!lists.svcOut) {
            lists.svcOut = {};
        }
        lists.svcOut[path] = null;

        if (method == 'GET'){
            var rc ;
            scope.flags.limitNumber = 0;
            scope.flags.clickNext = 0;
            scope.flags.getSearchHelp = false;
            resp.response = null;
            if (data){
                if (!data) data = {};
                if (!data.search) data.search = '';
                rc = http.get('/'+path, {params: data});
            } else {
                rc = http.get('/'+path);
            }
            rc
                 .success(function(hresp){
                    var i, row, len ;
                    // var lim = 5000;
                    if(hresp.success){
                        resp.response = hresp;
                        resp.response.showIt = Array.isArray(resp.response.data);
                        if (resp.response.showIt){
                            len = resp.response.data.length;
//                            if (len > lim){
//                                resp.response.data = resp.response.data.splice(0,lim);
//                                resp.error =  len + ' records found. Your output has been truncated to ' + lim + ' records.';
//                            }
                            lists.filterRows[path] = resp.response.data;
                            lists.svcOut[path] = resp.response.data;
                            var noOfRows = lists.filterRows[path].length;
                            lists.filterValues[path].searchFilterErrorMsg = null;
                            if(noOfRows > 0 && noOfRows <=  scope.flags.initialRowLimit) {
                                scope.flags.filterRowNumber[path] = noOfRows;
                            } else if (noOfRows >  scope.flags.initialRowLimit){
                                scope.flags.filterRowNumber[path] =  scope.flags.initialRowLimit.toString();
                                scope.flags.showNext = true;
                            }
                            
                            var dict = lists.svcOut[path];
                            
                            // try to come up with a Cypress friendly id
                            for (i in dict){
                                row = dict[i];
                                if (row.username){
                                    row.htmlId = 'e-' + row.username ;
                                }else if (row.userName){
                                    row.htmlId = 'e-' + row.userName ;
                                }else if (row.name){
                                    var ss = comMod.replaceAll(row.name,' ','-') ;
                                    ss = comMod.replaceAll(ss, ',','-') ;
                                    row.htmlId = 'e-' + ss;
                                }else{
                                    row.htmlId = 'e-' + row.id;
                                }
                            }
                            
                        } else {
                            resp.error = hresp.data.error;
                        }
                        if (callback) callback();
                    } else {
                        lists.filterRows[path]= !lists.filterRows[path];
                        resp.error = hresp.error;
                        if (callback) callback();
                    }
                 })
                 .error(function(e) { 
                    resp.error = e;
                    lists.filterRows[path]= !lists.filterRows[path];
                    lists.filterValues[path].searchFilterErrorMsg = resp.error;
                    if (callback) callback();
                });
        }
        if (method == 'POST'){
            var cdata;
            cdata = comMod.objCopy(data);
            cleanNumbers(rules, cdata.form, c3);
            deleteUnwantedFields(method, cdata, rules);
            resp.response = null;
            resp.error = null;
            http.post('/'+path, cdata)
                .success(function(hresp){ 
                    resp.response = hresp; 
                    if (hresp.success){
                        // clear out the form only when success. Otherwise, allow user to correct the form
                        data.form = {};
                        //here
                        var formName = scope.urls['api_svc'][path].formName ;
                        initBooleans(path, 'POST', formName, {}, data.form);
                    }
                    lists.svcOut[path] = null; // this erases search results
                    if (callback) callback(hresp.success);
                })
                .error(function(e){ 
                    resp.error = e; 
                    if (callback) callback(false);
                });       
        }
        if (method == 'PUT'){
            var cdata;
            cdata = comMod.objCopy(data);
            cleanNumbers(rules, cdata.form, c3);
            deleteUnwantedFields(method, cdata, rules);
            resp.response = null;
            lists.svcOut[path] = null; // this erases search results
            resp.error = null;
            http.put('/'+path, cdata)
                .success(function(hresp){ 
                    resp.response = hresp; 
                    if (!hresp.success){
                        resp.error = hresp.error;
                    }
                    if (callback) callback(hresp.success);
                })
                .error(function(e){ 
                    resp.error = e; 
                    if (callback) callback(false);
                });       
        }
    };
    function cleanNumbers(rules, form, c3){
        var k, keep, val;
        for (k in form){
            val = form[k];
            if (rules[k] && ((rules[k].type == 'int') || (rules[k].type == 'float'))){
                if (val && (val != '')){
                    form[k] = parseInt(val);
                }else{
                    // data is null or empty string
                    keep = k.endsWith('_id');
                    keep |= (c3.send[k] && (
                                        (c3.sblank[k] && (val == ''))
                                        ||
                                        (c3.snull[k] && (val == null))
                    ));
                    if(!keep){
                        delete form[k];
                    }
                }
            }
            if (rules[k] && (rules[k].type == 'enum')){
                if (form[k] == ''){
                    delete form[k];
                }
            }
            if (!rules[k]){
                comMod.ub3consoleLog(618, 'WARNING !!!!  no validation rules exist for field ', k);
            }
        }
    }
    function deleteUnwantedFields(method, obj, rules){
        var k;
        //delete obj.form['deleted'];
        //delete obj.form['force_delete'];
        delete obj.form['email_list__email'];

        // delete any fields with value == null
        // exception, make null dates be empty string
        for (k in obj.form){
            if (obj.form[k] == null){
                if (k.endsWith('_date')){
                    obj.form[k] = '';
                }else{
//                    delete obj.form[k];
                }
            }
        }
        // now delete extra fields that are not needed because we already have *_id
        // these deleted fields reside in other tables
        for (k in obj.form){
            if (rules[k] && rules[k].update_name && (rules[k].update_name != k)){
                delete obj.form[k];
            }
            if (!rules[k]){
                comMod.ub3consoleLog(646, 'WARNING !!!!  no validation rules exist for field ', k);
            }
        }
    }
    
    function setEmptyBooleans(form, formRules){
        for (fieldName in formRules){
            fieldRules = formRules[fieldName];
            if (fieldRules.type == 'bool'){
                if (!form[fieldName]){
                    form[fieldName] = false;
                }
            }
        }
    }
    function setEmptyDates(form, formRules){
        var fieldName, fieldRules;
        for (fieldName in formRules){
            fieldRules = formRules[fieldName];
            if (fieldName.endsWith('_date')){
                if (!form[fieldName] || (form[fieldName]=='')){
                    form[fieldName] = null;
                }
            }
        }
    }

    function validate   (path, method, formName, form, fieldName, col3, cb ){ 
        var fieldRules;
        if (!fieldName) return;

        fieldRules = scope.vRules[formName][fieldName.replace('yesno_','')];

        if ((fieldRules && (fieldRules.type == 'bool')) || col3){
                validateNow (path, method, formName, form, fieldName, col3, false, cb);
        }else{
            comMod.onStopCalling(700, function(){
                validateNow (path, method, formName, form, fieldName, col3, false, cb);
            });
        }
    }
    function validateNow(path, method, formName, form, fieldName, col3, fromPageLoad, cb){
        var errors, more, s, flags, field, special;

        if (!form){
            comMod.ub3consoleLog('no form defined yet');
            if (cb) cb();
            return;
        }
        if (!scope.flags.arrayFieldErrors){ 
            scope.flags.arrayFieldErrors = {};
        }
        if (!scope.flags.arrayFieldErrors[path]){
            scope.flags.arrayFieldErrors[path] = {};
        }
        errors = scope.flags.arrayFieldErrors[path][method] = {} ;
        more   = scope.flags.arrayFieldErrors[path][method+'_more'] = {} ;
        
        flags = scope.flags ;

        // Special processing for booleans,  because of the radio buttons.
        // HTML radio buttons don't like having boolean values, so instead we use 'yes', 'no'
        if (fieldName && (fieldName.startsWith('yesno_'))){     
            fieldName = fieldName.replace('yesno_', '');
            var v = scope.yesno[path][method].form[fieldName];   // v is radio value
            form.form[fieldName] = (v == 'yes' ? true : (v == 'no' ? false : undefined)) ;
        }
        if ((fieldName == 'deleted') && (method == 'PUT') && form.form){
            form.search = form.search.replace(' deleted==true','');
            if (form.form.deleted == false){
                form.search += ' deleted==true' ;
            }
        }

        special = false;
        if (fieldName){
            field = scope.vRules[formName][fieldName];  // field == field rules.  It is not a field name
            special = true;
        }
        setDefaultValues(flags, path, formName);

        if (special){
            if (form.form){
                if (col3){              // data flows this way:  col2 <-- col3
                    if (col3 == 'blank'){    // user clicked on the blank column
                        if (flags.isBlank[path][method][fieldName]){    // checked
                            flags.isNull [path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = true;
                            form.form[fieldName] = '';
                        }else{                                          // unchecked
                            flags.send   [path][method][fieldName] = false;
                            delete form.form[fieldName];
                        }
                    }
                    if (col3 == 'null'){    // user clicked on the null column
                        comMod.ub3consoleLog(857, {flags:flags, form:form});
                        if (flags.isNull[path][method][fieldName]){    // checked
                            flags.isBlank[path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = true;
                            form.form[fieldName] = null;
                        }else{                                          // unchecked
                            flags.send   [path][method][fieldName] = false;
                            delete form.form[fieldName];
                        }
                    }
                    if (col3 == 'send'){    // user clicked on the send column
                        if (flags.send[path][method][fieldName]){    // checked
                            flags.isBlank [path][method][fieldName] = true;
                            flags.isNull  [path][method][fieldName] = false;
                            if (form.form[fieldName] == undefined){
                                form.form[fieldName] = (field.type == 'bool') ? false : null;
                            }
                        }else{                                       // unchecked
                            flags.isBlank [path][method][fieldName] = false;
                            flags.isNull  [path][method][fieldName] = false;
                            delete form.form[fieldName];
                        }
                    }
                }else{                  // data flows this way:  col2 --> col3
                    if (fieldName in form.form){
                        flags.isNull [path][method][fieldName] = (form.form[fieldName] == null);
                        flags.isBlank[path][method][fieldName] = (form.form[fieldName] == '');
                        flags.send   [path][method][fieldName] = true;
                    }else{
                        flags.isNull [path][method][fieldName] = false;
                        flags.isBlank[path][method][fieldName] = false;
                        flags.send   [path][method][fieldName] = false;
                    }
                }
            }
        }
        if (form.form){
            // form.form gets ignored,  validate it sepparately
            var counter = {count: 0};
            handleGroupedFields     (formName, form, fieldName, counter, function(){
                //setEmptyBooleans(form.form, scope.vrules[formName]);      // see issue 2326. it asks for this to not happen
                setEmptyDates(form.form, scope.vRules[formName]);
                //form.form.owner = false;
                finalPass('c');
            });
        }else{
            valMod.validateNow(formName, form, errors, null, fromPageLoad);
        }
        finalPass('e');
        if (cb) cb();

        //do a final pass so col3 is consistent in ALL rows with the data
        function finalPass(w){
            var fSpec, val;
            valMod.validateNow(formName, form.form, errors, null, fromPageLoad);
            more.missing = valMod.computeMissing(errors);
            more.okToSend = !more.missing;

            var rules = scope.vRules[formName];
            
            for (fieldName in rules){
                fSpec = rules[fieldName];
                val = undefined;
                if (form.form && (fieldName in form.form)) {
                    val = form.form[fieldName];
                    if (val === ''){
                            flags.isNull [path][method][fieldName] = false;
                            flags.isBlank[path][method][fieldName] = true;
                            flags.send   [path][method][fieldName] = true;
                    }else if (val === null){
                            flags.isNull [path][method][fieldName] = true;
                            flags.isBlank[path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = true;
                    }else if (val === undefined){
                            flags.isNull [path][method][fieldName] = false;
                            flags.isBlank[path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = false;
                    }else{ // it has a visible value  (except false, which is not visible)
                            flags.isNull [path][method][fieldName] = false;
                            flags.isBlank[path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = true;
                    }
                }else{
                            flags.isNull [path][method][fieldName] = false;
                            flags.isBlank[path][method][fieldName] = false;
                            flags.send   [path][method][fieldName] = false;
                }
                if (fSpec.type == 'bool'){
                    scope.yesno[path][method].form[fieldName] = (val === true ? 'yes' : (val === false ? 'no' : undefined));
                }
            }
        }

    };

    function setDefaultValues(flags, path, formName){
        var rule, fieldName, rules;
        rules = scope.vRules[formName];
        if (!flags.send || !flags.send[path]){
            for (fieldName in rules){
                rule = rules[fieldName];
                if (rule.type != 'bool'){
                    setDefaultValue(flags, false, ['isBlank', path, 'PUT' , fieldName]);
                    setDefaultValue(flags, false, ['isBlank', path, 'POST', fieldName]);
                }
                setDefaultValue(flags, false, ['isNull',  path, 'PUT' , fieldName]);
                setDefaultValue(flags, false, ['isNull',  path, 'POST', fieldName]);
                setDefaultValue(flags, false, ['send',    path, 'PUT' , fieldName]);
                setDefaultValue(flags, false, ['send',    path, 'POST', fieldName]);
            }
        }
    }
    function setDefaultValue(topObj, defaultValue, subObjectsArray){
        var i, obj, objName, ss;
        if (subObjectsArray.length == 0){
            return;
        }
        else if (subObjectsArray.length == 1){
            topObj[subObjectsArray[0]] = defaultValue;
        }else{
            obj = topObj;
            for (i in subObjectsArray){
                objName = subObjectsArray[i];
                if (!objName) return;
                if (!scope.hasKey(obj, objName)){
                    if (i == (subObjectsArray.length - 1)){ // is this the last one ?
                        obj[objName] = defaultValue;
                    }else{
                        obj[objName] = {};
                    }
                }
                obj = obj[objName];
                //ss = 'isBlank ' + JSON.stringify(topObj.isBlank) + ' isNull ' + JSON.stringify(topObj.isNull);
            }
        }
    }

    function handleGroupedFields(formName, form, fieldName, counter, callback){
        if (! fieldName)                         {return;}
        if (! scope.vRules)                      {return;}
        if (! scope.vRules[formName])            {return;}
        if (! scope.vRules[formName][fieldName]) {return;}

        var rules = scope.vRules[formName][fieldName]; // rules for one field
        
        if (!rules.update_name || !rules.update_api){
            return;
        }

            var prefix, searchBy, v, p;
            var rules = scope.vRules[formName][fieldName]; // rules for one field
            //apiNameSuffix = rules.update_api.replace('svc/','').toLowerCase() ;
            
            searchBy = rules.search_name;
            if (!searchBy)  return;

            v = form.form[fieldName];   // current value of this field
            if (v && ((typeof v) != 'number') && (v.indexOf(' ') >= 0)){
                v = "'" + v + "'" ;
            }
            if ( !v || v == ''){
                eraseRelatedfields();
                return;
            }
            p = {params: {search: searchBy + '=' + v}} ;
            counter.count ++;
            http.get(rules.update_api, p)
                .success(function(resp){
                    var fn, fr;
                    if (resp.success && resp.data && (resp.data.length == 1)){
                        // now update any other field that is related
                        // meaning, find the ones that have the same update_name
                        for (fn in scope.vRules[formName]){
                            fr = scope.vRules[formName][fn];
                            if (fr.update_name && (fr.update_name == rules.update_name)){
                                form.form[fn] = resp.data[0][fr.search_name] ;
                            }
                        }
                    }else{
                        eraseRelatedfields();
                    }
                    counter.count -- ;
                    callback();
                })
                .error(function(data){
                    counter.count -- ;
                    callback();
                });  

            function eraseRelatedfields(){
                // now update any other field that is related
                // meaning, find the ones that have the same update_name
                // and erase them
                var fn, fr;
                for (fn in scope.vRules[formName]){
                    if (fieldName != fn){
                        fr = scope.vRules[formName][fn];
                        if (fr.update_name && (fr.update_name == rules.update_name)){
                            form.form[fn] = null;
                        }
                    }
                }          
            }
    }

function parseUrls(source){
    var p, files, line, path, file, func;

    // point it past the non-interesting stuff
    p = foo(source, 'API GEN BEGIN');

    files = {};
    while (p = foo(p, "    url(r'^")){      // assignemt to p, then check if p is not null
        line = p.split(')')[0];
        path = line.split('$')[0].split('^')[1];
        file = line.split(',')[1].split('.')[0].trim();
        func = line.split(',')[1].split('.')[1].trim();
        if (file.indexOf('api_') == 0){
            if (!files[file]){
                files[file] = {};
            }
            files[file][path] = {func:func};
        }

        p = foo(p,')');
    }
    return files;
}

function parseApiFile(filePrefix, funcs, source, forms){
    var methods, method, formName, func, comment, p, files, line, roles, params, i, sect;

    methods =  ['GET','POST','PUT','DELETE'] ;
    p = foo(source, '@api_view');

    files = {};
    
    while (p = foo(p, '@api_view')){    // assignment to p, then compare if not null
        line  = nextEnclosedBy(p, '@api_view', ':');
        roles = nextEnclosedBy(line,'@validate((',')');
        roles = roles.replace('@validate((','');
        
        params = {};
        for (i in methods){
            method = methods[i];
            sect = foo(line, method+'_required_parameters');
            if (sect){
                params[method] = nextEnclosedBy(sect, '[',']');
                params[method] = convertStringsStringToArray(params[method]);
            }
        }
        sect = foo(line,'form_name');
        formName = null ;
        if (sect){
            formName = nextEnclosedBy(sect, '=', ')').trim().slice(1);
            formName = UCtoStr(formName, source);
        }
        addTo(forms,formName);
        func = null;
        sect = foo(line,'def ');
        if (sect){
            func = sect.replace('def ','').split('(')[0].trim();
        }

        comment = nextEnclosedBy(p,"'''","'''");

        insert(funcs, func, roles, params, formName, comment);

        p = foo(p,':');
    }

}
function addTo(dict,item){
    if (!item) return;
    
    if (!dict[item]){
        dict[item] = {};
    }
}
function UCtoStr(varName, sourceCode){
    var s, str ;
    if (!varName) return null;
    varName = varName.trim();
    if (varName == '') return null;
    if (varName == "''") return null;
    if (varName == 'None') return null;
    // now convert the UPPERCSE_FORM_NAME to the actual string
    var s = foo(sourceCode, varName);
    s = s.slice(0,200);
    str = s.split("'")[1];
    return str;
}

function insert(funcs, func, roles, params, formName, comment){
    var path, obj;

    for (path in funcs){
        obj = funcs[path];
        if (obj.func == func){
            obj.path = path;
            obj.roles = roles;
            obj.params = params;
            obj.formName = formName;
            obj.comment = comment;
            return;
        }
    }
    comMod.ub3consoleLog('missing in urls.py :', func);
}


function firstOccurrenceOf(s,sub){
	if (!s || (s == '')) return '';
	var i = s.indexOf(sub);
	if (i < 0) return '';
	return s.slice(i);
}
var foo = firstOccurrenceOf ;

function nextEnclosedBy(s, a, b){
    var sub;
	if (!s || (s == '')) return '';
	var ia = s.indexOf(a);
    if (ia < 0) return '';

    //sub = s.slice(ia);
    sub = s.slice(ia + a.length);

	var ib = sub.indexOf(b);
    if (ib < 0) return '';

    var r = sub.slice(0,ib);
    return r;
}

    // s is a string that looks like this:
    // "'firstName', 'lastName', 'email', 'reactivate'"
    //  MAKE sure that s does not have comma after the last single quoted string
    //
    function convertStringsStringToArray(s){
        var s1 = comMod.replaceAll(s,"'",'"');
        var ar = JSON.parse('['+s1+']');
        return ar;
    }

    return publicStuff;

})();

module.exports = {svcApiModule};
