<div class="modal-header">
    <img width="900" src="/frontend/assets/agreementAlcf_091319.svg">

   <br><br>
    Link to policies: 
    <a href="{{ALCFPolicyAgreementURL}}" target="_po">
        {{ALCFPolicyAgreementURL}}
    </a>
    <br><br>
    <div class="cBox" id="alcfAgreementCheck">
        <input
        id="formAlcfPolicy"
        [(ngModel)]="form.policyAlcfAck"
        ngDefaultControl
        name="formAlcfPolicy"
        type="checkbox"
        (change)="onClickAgreement()">
        <label class="option" for="formAlcfPolicy" uib-tooltip="to change this, please Read the terms of service" tooltip-placement="bottom">
            By checking this box I affirm that I will adhere to the rules in the above policy.
        </label>
    </div>

   <div style="text-align:center">
      <button class="btn btn-primary btn-lg" (click)="ok()" id="okBtn"> 
      OK
      </button>
   </div>
   <br><br>
</div>
