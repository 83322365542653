
import { Injectable } from '@angular/core';
import { commonModule  } from 'commonModule';

var t = null;
var ub3consoleLog = null;

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  sessTimeOutSecs = null;  // length of seconds before an inactive session expires
                          // inactive == time since last API call
  warningSeconds = 5*60;  // warn user of upcoming timeout 5 minutes before expiration

  tpPromise = null;         // the timeout popup promise
  counterPromise = null;    // the timeout promise for the countdown
  ip = null;                // initial promise
  state = null;
  domainGlobals = null;
  lastApiAct = null;
  lastUIact = null;

  constructor() { 
        t = this;
        ub3consoleLog = commonModule.ub3consoleLog;
        t.state = commonModule.getSharedObject('app','state');
        t.domainGlobals = commonModule.getSharedObject('app', 'domainGlobals');
        t.setValues();

        document.documentElement.onkeyup = function(){
            t.resetTimer('', null, 'UI');
        };
        document.documentElement.onclick = function(){
            t.resetTimer('', null, 'UI');
        };
  }
  setValues(){
    if (!t.sessTimeOutSecs){

        //ub3consoleLog('42 sess-timeout.resetTimer, checking', {domGlob:t.domainGlobals, state: t.state});
        if ((!t.domainGlobals) || (!t.state) || (!t.state.user) || (!t.state.user.role)){
            // not finished loading the needed info, wait
            setTimeout(t.setValues, 500);
            return;
        }
        t.sessTimeOutSecs = (t.state.user.role == 'domainAdmin') 
                            ? t.domainGlobals.inactivityTimeoutAdminSession
                            : t.domainGlobals.inactivityTimeoutUserSession ;

        //ub3consoleLog('52 sess-timeout.resetTimer, just set', {sessTimeOutSecs: t.sessTimeOutSecs});

        // FOR EASY TESTING: uncomment next 3 lines
        //t.sessTimeOutSecs = 2*60;    // 2 minutes
        //t.warningSeconds = 60;
        //ub3consoleLog('57 sess-timeout.resetTimer, overwritten', {sessTimeOutSecs: t.sessTimeOutSecs});
    }
  }
  setOpenModalFunction(f){
      t.openModal = f;
  }
  setCloseModalFunction(f){
      t.closeModal = f;
  }

  resetTimer(path, info, origin){

        //ub3consoleLog('62 sess-timeout.resetTimer', {path:path, info:info, origin:origin, state:t.state, sessTimeOutSecs: t.sessTimeOutSecs, domGlob: t.domainGlobals});
        if (!t.sessTimeOutSecs) return;
        if (!t.state) return;
        if (t.state && t.state.user && (!t.state.user.role)) return;
        if (t.state && t.state.user && t.state.user.role  && (t.state.user.role == 'public')) {
                // no session timeouts on public access
                return;
        }
        if ((origin == 'API') && (path.indexOf('ogout') >= 0)){
            return; // no need to reset timer on logout
        }
        if (path && path.indexOf('.html') > 0) return;

        var wakeUpAfter;

        clearTimeout(t.counterPromise);
        t.state.dispTimeout = false;

        var rightNow = new Date().getTime();
        if (origin == 'UI'){
            if ((rightNow - t.lastUIact) < (60*1000)){
                return;  // don't go crazy processing every keystroke
            }
            t.lastUIact = rightNow;
            // if last API call is very recent, ignore this event
            if ((rightNow - t.lastApiAct) < (60*1000)){
                return;
            }

            // if we get this far, we need to ask the backend to refresh its session
            commonModule.whoami(function(resp){ // this call triggers another call to this function
                //ub3consoleLog('97 sent i-am-alive message to server, and got reply', resp);
                if (!resp.signedIn){
                    t.stopDisplayCounter(); // triggers frontend sign out
                }
            });    
            //ub3consoleLog(95, {now:rightNow, ui:this.lastUIact, api: this.lastApiAct});
            return;
        }
        if (origin == 'API'){
            t.lastApiAct = rightNow;
        }

        if (t.tpPromise) {
            clearTimeout(t.tpPromise);
            if (t.closeModal){
                t.closeModal();
            }
        }
        wakeUpAfter = t.sessTimeOutSecs - t.warningSeconds; // units = seconds

        /*
        ub3consoleLog('114, ses-tim.resetTimer',{
            state: t.state, 
            sessTimeOutSecs: t.sessTimeOutSecs,
            warningSeconds: t.warningSeconds,
            path: path, 
            dom: t.domainGlobals, 
            apiTime: t.lastApiAct,
            uiTime: t.lastUIact,
            wake: wakeUpAfter,
            origin:origin});
        */
        t.tpPromise = setTimeout(function(){ 
            t.localWakeUp(t.warningSeconds); 
        }, wakeUpAfter * 1000);
  }
  cancelTimer(){    // this needs to be called on user sign out
      clearTimeout(t.tpPromise);
      t.tpPromise = null;
      t.sessTimeOutSecs = null;
  }

  // This function will get called after a timeout,  or after computer wakes up
  localWakeUp(secs){
      var dt = new Date();
      var milis = dt.getTime();
      //ub3consoleLog('138 localWakeUp', {'now.dt': dt, 'now.mil': milis, lastApiAct: t.lastApiAct, lastUIact: t.lastUIact});
      // session will end in ' + secs + ' seconds');
      // check if there has been user activity on the browser');
      t.updateDisplayCounter();

      if (t.openModal){
         t.openModal(); 
      }
  }
  updateDisplayCounter(){
      var dt, milis, elapsed, remaining;
      dt = new Date();
      milis = dt.getTime();
      elapsed = milis - t.lastApiAct ;
      remaining = (t.sessTimeOutSecs * 1000) - elapsed; // units = milis

      t.state.dispTimeout = true;                   // app component can see this
      t.state.counter = Math.trunc(remaining/1000); // units = seconds  app component can see this

      if (t.state.counter < 0){
        t.stopDisplayCounter();                     // thi striggers a signout
        return;        
      }

      t.counterPromise = setTimeout(function(){
          t.updateDisplayCounter();
      }, 1000);
  }
  stopDisplayCounter(){
      clearTimeout(t.counterPromise);
      t.state.dispTimeout = false;
      t.state.counter = null;

      if (t.closeModal) t.closeModal();
      commonModule.signOut(null, null, true);   // 3rd parameter: stay in current page
      setTimeout(function(){window.location.reload();}, 1000);
  }
}
