var joinProjectModule =
(function(){
        "use strict";
        var alertError = {type : 'danger'};
        var alertSuccess = {type : 'success'};
        var publicStuff;
        var cookies , http , httpNew , scope , timeout , win, comMod;
        cookies = http = httpNew = scope = timeout = win = comMod = null;  // cached services
        var jsServices = {};
        publicStuff = {
            init : init,
            getProjects : getProjects,
            setProjectInfo : setProjectInfo,
            getProjectInfo : getProjectInfo,
            joinProject : joinProject,
            setServices: setServices
            //filterProjectName: filterProjectName
            
        };
    function setServices(services){
        var i, serv;
        for (i in services){
            serv = services[i];
            jsServices[serv.sname] = serv;
        }
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;

        comMod = jsServices.commonModule;
    }
        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user
    function init(scope, http, pageName, lists, flags, alerts){
        scope.publicPage(true);
        comMod.getSetContext(scope, pageName, function(){

            scope.flags = {showEditForm: false, displayedPageName: pageName};
            scope.form = {};

            if(!scope.lists) {
                scope.lists = {};
            }

            if (!scope.lists.activeProjects){
                getProjects(http,  scope.lists, scope.flags);
            }

             if(scope.authAlerts){
                scope.authAlerts = [];
            }
        });
    }

    function getProjects(http,lists, flags){
        flags.showEditForm = false;
        http.get('/auth/activeProjects')
        .success(function(resp){
            if (resp.success){
                lists.activeProjects = resp.activeProjects;
            }
        
        }).error(function(data, status, headers, config) {
               // called asynchronously if an error occurs
               // or server returns response with an error status.
              lists.projectFailure = data.data.detail;
        });

    };

    function hasMembershipRequested(http, flags, alerts) {
        var pjId = flags.currentProjectId;
        http.get("/auth/projectMembershipStatus?id="+pjId)
        .then(function(response){
            var resp = response.data ? response.data : response;
            if(resp.success){
                var memStatus = resp.projectMembershipStatus;
                var statusValues = ["Approved-pending MUA/Ack", "Approved", "Requested", "Active"];
                // if the membership status is one from the above array, then flags.membershipRequested should be true
                flags.membershipRequested =  statusValues.includes(memStatus);
                if(flags.membershipRequested){
                    flags.membershipStatus = memStatus && memStatus.toUpperCase();
                }
            } else{
                alertError.msg = resp.error;
                alerts && alerts.push(alertError);
            }
        }).catch(function(response, status){
            alertError.msg = response.data.detail;
            alerts && alerts.push(alertError);
        });
    }

    function setProjectInfo(pjName, edit, http, form, flags, lists, cookies){
        flags.showEditForm = true;
        form.projectShortName = pjName;
        flags.editProject = (edit == 'edit');
        flags.submitError = null;
        if (edit == 'edit'){
            getProject();
        }
           
        //get the information about that particular project
        function getProject(){
            getProjectInfo(http, form.projectShortName, flags, form, cookies);
        }

    }

    function getProjectInfo(http, projectShortName, flags, form, cookies) {
        var url ;

        var p = {projectShortName : projectShortName};

        http.get('/auth/projectInfoActiveMembersOnly', {params: p})
            .success(function(resp){
               if (resp.success){
                    var f = resp.projectInfo;
                    if(f) {
                        // check if the membership is already requested
                        if(f.id){
                            flags.currentProjectId = f.id;
                            hasMembershipRequested(http, flags);
                        }

                        flags.prevSavedForm = comMod.objCopy(f);
                        Object.assign(form, f);

                    }
           
                }
        }).error(function(data, status, headers, config) {
               // called asynchronously if an error occurs
               // or server returns response with an error status.
              lists.projectFailure = data.data.detail;
            });

    }

    function joinProject(http, cookies, flags, form, alerts) {

        var cdata = {projectShortName : form.projectShortName};

        http.post('/auth/joinProject', cdata)
            .success(function(resp){
               if (resp.success){
                    flags.joinProjectMsg = resp.joinProject;
                    comMod.doWhenAPICallsDone(function(){
                        hasMembershipRequested(http, flags, alerts);
                        alerts.push({
                            type : 'success',
                            msg : flags.joinProjectMsg
                        });
                    });
                } else {
                    alerts.push({
                        type : 'danger',
                        msg : resp.error
                    });
                }

            }).error(function(data, status, headers, config) {
               // called asynchronously if an error occurs
               // or server returns response with an error status.
              lists.projectFailure = data.data.detail;
        });

    }

        // ----------- public stuff gets returned -----------
        return publicStuff;

})();
module.exports = {joinProjectModule}
