
import { Component, OnInit, Input } from '@angular/core';

let scope: any = {};
let t = null;

@Component({
  selector: 'multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.css']
})
export class MultiFilterComponent implements OnInit {

  constructor() {
    t = this;
  }


  @Input() adminvalidate : any;
  @Input() adminvalidateInstitutions : string;
  @Input() addmore : string;
  @Input() checksearchfields : string;
  @Input() clearsearch : any;
  @Input() closeduplmodal : any;
  @Input() downloadsearchresults : string;
  @Input() dupmsg : any;
  @Input() flags : any;
  @Input() filtervalues : any;
  @Input() filterlist : any;
  @Input() filterrows : string;
  @Input() filterhelp : any;
  @Input() form : any;
  @Input() init : any;
  @Input() lists : any;
  @Input() operators : string;
  @Input() path : string;
  @Input() removefilter : string;
  @Input() resp : any;
  @Input() rows2display : string;
  @Input() shownextresults : string;
  @Input() showprevresults : string;
  @Input() submitfilters : any;
  @Input() searchresults : any;
  @Input() updatesearch : string;


  ngOnInit(): void {
      t.init(t.filterrows, t.rows2display, t.filterlist);
      t.flags.download=false;
      scope.flags = t.flags;
  }

}
