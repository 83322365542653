import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { allocRequestModule } from 'allocRequestModule';
import { CookieService } from 'ngx-cookie-service';
import { CommonService, Ub3httpService } from '@common/services';
import { GlobalConstants } from '@common/constants/global-constants';
import { SweetAlert } from '../../../shared/services/sweetalert.service';

let t = null;
let http = null;
let authAlerts = [];
let scope = {
  flags: {
    allocReq          : true,
    fieldErrors       : null,
    askForCode        : false,
    askForCodeError   : null,
    emailTimeSent     : new Date(),
    codeLength        : 0,
    confirmingCode    : false,
    emailCodeVerified : false,
    ok2Submit         : false,
    submitError       : null,
    displayARForm     : null,
    hasSession        : null,
    fsOnlyAlloc       : false,
    okToSubmit        : false,
    subResoLength     : {},
    resourceListHasStorageType : false,
    newProjwStorage   : true,
    multiResources    : false,
    displayedPageName : {},
    missingData       : '',
    showMissingData   : false
  },
  lists : {
    allocResources: null,
    institutions : null,
    scientific_field: null,
    preparation_for: null
  },
  form  : {
    allocRequest:{
      new_project: true,
      scientific_field: '',
      preparation_for: ''
    }
  },
  user: {},
}
let wFieldErrors = null;
let userRoles = ['user', 'piproxy', 'domainAdmin', 'owner'];
let state;
var ub3consoleLog = null;

@Component({
  selector: 'app-allocation-request',
  templateUrl: './allocation-request.component.html',
  styleUrls: ['./allocation-request.component.css']
})

export class AllocationRequestComponent implements OnInit {
  user = scope.user;
  arLogin: any = {};
  helpDeskEmail:string = "accounts@alcf.anl.gov";
  authAlerts = [];
  durReqd = {};

  form = {
    allocRequest : {
      scientific_field: '',
      preparation_for: '',
      industry_related: ''
    }
  };

  lists = {
    allocResources : [],
    institutions : [],
    scientific_field : [],
    preparation_for : []
  };

  flags = {
    hasSession: null,
    displayARForm: null,
    subResoLength : {},
    fsOnlyAlloc : false,
    okToSubmit : false,
    emailTimeSent: new Date(),
    emailCodeVerified: false,
    askForCodeError: null,
    askForCode: false,
    confirmingCode: false,
    codeLength: 0,
    submitError: null,
    ok2Submit: false,
    missingData: '',
    showMissingData: false
  };

  reqSubAllocHelp = {};
  reqAllocHelp = {};
  fieldErrors  = null;
  DDAllocationDataPolicyURL = GlobalConstants.DDAllocationDataPolicyURL;

  constructor(
    public commonService: CommonService,
    public cookies: CookieService,
    public h: Ub3httpService,
    public sweetAlert: SweetAlert
  ) {
    t = this;
    http = h;
    wFieldErrors = t.commonService.getSharedObject('warning', 'fieldErrors');
    t.fieldErrors = t.commonService.getSharedObject('warning', 'fieldErrors');
    state = t.commonService.getSharedObject('app','state');
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.commonService.doWhenAPICallsDone(function(){
        t.flags.emailCodeVerified = state.user.limited_email_verified;
        var role = state.user.role;
        scope.user = state.user;
        t.flags.hasSession = (role === 'limited' && t.flags.emailCodeVerified) || (userRoles.indexOf(role) >= 0);
        t.flags.displayARForm = t.flags.hasSession;
        scope.flags.hasSession = t.flags.hasSession;
        scope.flags.displayARForm = t.flags.displayARForm;
        t.authAlerts = authAlerts;
    });
    
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    t.commonService.doWhenAPICallsDone(function(){
      allocRequestModule.setScope(scope);
      let pageName = (!t.flags.hasSession) ? 'Allocation Requests' : 'Request an Allocation';
      t.callInitFeature(pageName);
      if(t.user && t.user.signedIn && t.user.role === 'limited'){
        t.commonService.objCopyGuts(t.user, t.arLogin);
      }
    });
  }

  // get the scope from angularJS module for angular 10 component
  getScope = function(scope:any){
      t.lists = scope.lists;
      t.form  = scope.form;
      t.flags = scope.flags;
      t.user  = scope.user;
  }

  callInitFeature(pageName){
    allocRequestModule.initFeature(pageName, function(){
      allocRequestModule.setScope(scope);
      t.getScope(scope);
      t.commonService.objCopyGuts(scope.flags.fieldErrors, t.fieldErrors);
    });
  }
  afterLimitedLogin(LLF){
    t.flags = scope.flags;
    t.form.email = LLF.email;
    t.form.firstName = LLF.firstName;
    t.form.lastName = LLF.lastName;
    t.flags.hasSession = (t.user.role === 'limited' && t.flags.emailCodeVerified) || (userRoles.indexOf(t.user.role) >= 0);
    t.flags.displayARForm = t.flags.hasSession;
    t.callInitFeature('Request an Allocation');
  }

  // allocation request form functions
  validateAllocReq = function(){
    allocRequestModule.validate(t.form, t.flags, t.lists);
    t.commonService.objCopyGuts(scope.flags.fieldErrors, t.fieldErrors); // copies from object from left to right
  }
  getAllocResources = function(res){
      allocRequestModule.getAllocResources(res, t.lists, t.flags, t.form);
      t.getScope(scope);
  }
  verifyAllocVal = function(parentName, childName){
      allocRequestModule.verifyAllocVal(parentName, childName, t.lists, function(){
        t.validateAllocReq();
        t.commonService.doWhenAPICallsDone(function(){
          // duration warning message depending on subresources
          t.getSubResoDurWarning(parentName);
        });
      });
  }
  getSubResoDurWarning = function(reso){
    t.lists.allocResources.filter(function(itemArray){
      if(itemArray.name === reso && itemArray.subResources){
        t.durReqd[itemArray.name] = false;
         itemArray.subResources.forEach(function(subItem){
          var subItemDur = "duration_"+subItem.name;
          if(t.flags.fieldErrors.hasOwnProperty(subItemDur)){
            t.durReqd[itemArray.name] = (!t.flags.fieldErrors["duration_"+itemArray.name] && t.flags.fieldErrors[subItemDur] && t.flags.fieldErrors[subItemDur].dispRequired);
            return;
          }
        })
      }
    })
  }
  convertToLocaleString = function(resName, fldName, fldType, parentName){
    t.commonService.onStopCalling(700, function(){
      allocRequestModule.convertToLocaleString(resName, fldName, fldType, parentName, t.lists, function(){
        t.getScope(scope);
        t.validateAllocReq();
      });
    });
  }
  validateProjName = function(){
      allocRequestModule.validateProjName(t.form);
      t.getScope(scope);
  }
  setReqSpfcChanges = function(){
    allocRequestModule.setReqSpfcChanges(t.form, t.flags, t.lists);
  }
  closeAlert = function(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }
  submitAllocRequest = function(){
      t.authAlerts = [];
      allocRequestModule.submitAllocRequest(t.form, t.authAlerts, t);
      t.flags.displayARForm = scope.flags.displayARForm;
  }
  cancelAllocRequest = function(){
      allocRequestModule.cancelAllocRequest(scope);
      t.flags.displayARForm = scope.flags.displayARForm;
  }

  //ngbTypeahead
  searchKey = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.institutions && t.lists.institutions.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(inst) {
            return (inst.name);
          });
        })
      );
  }
  goHome = function(){
    window.location.assign("/");
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(state.user && state.user.signedIn && (state.user.role === 'limited')){
      return t.sweetAlert.confirmSwitchRequests();
    } else {
      return true;
    }
  }

}
