
<div id="data-collection" *ngIf="dataCollectionEnabled">

    <div>
        <h3 class="demoHead">{{demographicHeading}}</h3>
        <div id="introTxt" class="smTxt">{{introTxt1}}{{fiscalYear}}{{introTxt2}}</div>
        <button id="helpBtn" class="helpBtn">
            <span class="left">How is your data used?</span>
            <span (click)="toggleHelp = !toggleHelp" class="right">
                <i class="lgFnt fa-solid" [ngClass]="{'fa-angle-up':toggleHelp, 'fa-angle-down':!toggleHelp}"></i>
            </span>
        </button>
        <div *ngIf="toggleHelp" id="helpTxt" class="smTxt mb20">{{helpTxt}}</div>
    </div>

    <div *ngFor="let q of questions" class="mb20">
        <div class="bold">{{q.name}}</div>
        <div *ngIf="q.help_text" class="smTxt indent40">{{q.help_text}}</div>
        <div *ngFor="let c of q.choices" class="indent40 ht24">
            <label class="alignVal">
                <input type="checkbox"
                    *ngIf="q.field_type == 'multiple'"
                    (ngModelChange)="onChange(c)"
                    id="{{c.hid}}"
                    [(ngModel)]="c.checked">
                <input type="radio" 
                    *ngIf="q.field_type == 'single'"
                    name="{{c.hname}}"
                    [value]=true
                    (click)="onChange(c)"
                    id="{{c.hid}}"
                    [(ngModel)]="c.checked">
                <span id="chName" class="smTxt">{{c.name}}</span>
            </label>
        </div>
    </div>

    <div class="smCaps">
        <button id="save" class="indent40 saveBtn" (click)="onSave()" [disabled]="!enableSave">Continue to {{pageName}}</button>
    </div>

    <div *ngIf="saveError">
        Error: <font color="#ff0000"> {{saveError}} </font>
    </div>
</div>
