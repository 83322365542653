<div class="modal-header">
    <img width="900" src="/frontend/assets/ANL-714.svg">
    <br>
    <br>
    <div class="cBox" id="anlAgreementCheck">
        <input
          id="formPolicy"
          [(ngModel)]="form.policyAck"
          ngDefaultControl
          name="formPolicy"
          type="checkbox"
          (ngModelChange)="onClickAgreement()">
        <label class="option" for="formPolicy" uib-tooltip="to change this, please Read the terms of service"
                tooltip-placement="bottom">
            By checking this box I affirm that I will adhere to the rules in the above policy.
        </label>
    </div>

   <div style="text-align:center">
      <button class="btn btn-primary btn-lg" (click)="ok()" id="okBtn">
      OK
      </button>
   </div>
   <br><br>
</div>
