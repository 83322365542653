
<table id="bigfooter">
    <tr>
        <td>
            <div class="home-footer row">
                <div class="col-md-4 contact-support footerDiv">
                    <h5>Contact Support</h5>
                    <ul>
                        <li>{{domainGlobals.helpDeskAddress1}}</li>
                        <li>{{domainGlobals.helpDeskAddress2}}</li>
                        <li>{{domainGlobals.helpDeskAddress3}}</li>
                        <li>{{domainGlobals.helpDeskAddress5}}</li>
                        <li><br></li>
                        <li>Account related Help: {{domainGlobals.helpDeskEmail}}</li>
                        <li>All other support: {{domainGlobals.supportEmail}}</li>
                        <li>Phone: {{domainGlobals.helpDeskPhoneString}}</li>
                        <li></li>
                    </ul>

                    <div id="mc_embed_signup">
                        <form   class="validate"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form">
                            <span class="form-group">
                                <label for="mce-EMAIL">Subscribe to our monthly newsletter</label>
                                    <input  class="form-control email"
                                            id="mce-EMAIL"
                                            [(ngModel)]="email"
                                            name="email"
                                            placeholder="email address"
                                            [required]
                                            type="email">
                            </span>
                            <button class="s_button" name="submit" type="submit" (click)="submit()">SUBMIT</button>
                        </form>
                    </div>
                    <div><img src="/frontend/assets/img/footer_logos.png" style="width:250px"></div>
                    <div class="ver-text" id="ver-text-alcf">
                        version: {{domainGlobals.version}}
                    </div>
                </div>
                <div class="col-md-4 for-users footerDiv">
                    <h5>For Users</h5>
                    <ul>
                        <li><a href="{{GetStartedURL}}" title="Get Started">Onboarding Guides</a></li>
                        <li><a href="{{MachineStatusURL}}" title="Machine Status">Machine Status</a></li>
                        <li><a href="{{ALCFPolicyAgreementURL}}" title="Facility Policies">Facility Policies</a></li>
                        <li><a href="{{ALCFPolicyAckURL}}">Acknowledgement Policy</a></li>
                    </ul>
                    <h5>About ALCF</h5>
                    <ul>
                        <li><a href="{{OverviewURL}}" title="Overview">Overview</a></li>
                        <li><a href="{{StaffDirectoryURL}}" title="Staff Directory">Staff Directory</a></li>
                        <li><a href="{{CareersURL}}" title="Careers">Careers</a></li>
                        <li><a href="{{VisitUsURL}}" title="Visiting Us">Visiting Us</a></li>
                        <li><a href="mailto:support@alcf.anl.gov" title="Contact Us">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-md-4 notice-to-users footerDiv">
                    <h5>NOTICE TO USERS</h5>
                    <p>This is for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation
                        of privacy.</p>

                    <p>Any or all uses of this system and all files on this system may be intercepted, monitored, recorded, copied,
                        audited, inspected, and disclosed to authorized site, Department of Energy, and law enforcement personnel,
                        as well as authorized officials of other agencies, both domestic and foreign. By using this system, the user
                        consents to such interception, monitoring, recording, copying, auditing, inspection, and disclosure at the
                        discretion of authorized site or Department of Energy personnel.
                    </p>
                    <p>Unauthorized or improper use of this system may result in administrative disciplinary action and civil and
                        criminal penalties. By continuing to use this system you indicate your awareness of and consent to these
                        terms and conditions of use. LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this
                        warning.
                    </p>
                </div>
            </div>
        </td>
    </tr>
</table>
