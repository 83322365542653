
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {Router} from '@angular/router';
import {accountReactivateModule} from '../../../../../../../frontend/accountReactivateModule';
import {accountRequestModule} from '../../../../../../../frontend/accountRequestModule';
import {accountUpdateModule} from '../../../../../../../frontend/accountUpdateModule';
import {validationModule} from '../../../../../../../frontend/validationModule';
import {editRecordModule} from '../../../../../../../frontend/editRecordModule';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import { GlobalConstants } from '@common/constants/global-constants';
// moment not used
// import * as moment from 'moment';

var t: any = null;
var http: any = null;
var scope: any = {};
var clog = null;
var comMod = null;

@Component({
  selector: 'account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.css']
})
export class AccountEditComponent implements OnInit {

  @Input() option: string;    // 'update', 'reactivate', 'dataSurvey'
  @Input() userName: string;
  @Input() user: any;

  flags: any = null;
  lists: any = {};
  form: any = null;
  state: any = null;
  alerts: any = [];
  domainGlobals: any = {};
  dataCollectionEnabled = false;
  fieldErrors = null;
  arrayFieldErrors = null;
  confirmBtn = false;
  confirmSaveChecked = false;
  disableReactivateSubmit = true;
  isReactivateSurvey = false;
  dataSurveyOkToSave = false;
  addNewPhone = false;
  ALCFPasscodeTokensURL = GlobalConstants.ALCFPasscodeTokensURL;
  constructor(
      h: Ub3httpService,
      private cookies: CookieService, 
      private commonService: CommonService, 
      private router: Router,
      private window: Window) 
  { 
    scope = t = this;
    http = h;
    comMod = t.commonService;
    clog = comMod.ub3consoleLog;

    editRecordModule.init();
    t.fieldErrors      = t.commonService.getSharedObject('warning', 'fieldErrors');
    t.arrayFieldErrors = t.commonService.getSharedObject('warning', 'arrayFieldErrors');
    t.form             = t.commonService.getSharedObject('account-edit', 'form');
    t.state            = t.commonService.getSharedObject('app','state');
    t.flags            = t.commonService.getSharedObject('account-edit', 'flags');
    t.flags.hasError = {};

    t.form.accountPhones =  [];
    t.form.accountQuestions =  [];
    t.form.projectList = []
    t.form.nmi = false;
    t.flags.fldHasError = false;
    if (!t.user) t.user = t.state.user;
  }


  ngOnInit(): void {
      if (t.option == 'update'){
            validationModule.overWriteScope(scope);
            t.commonService.publicPage(true);

            scope.oldForm ={};    
            scope.myQuestionsData = [];
            scope.securityData=[];
            scope.changedDataModified = {};
            scope.submitted = false;

            accountUpdateModule.init(http, http, scope.flags, scope.form, scope.lists, Promise, scope, scope.alerts);
            this.commonService.doWhenAPICallsDone(function(){
                t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
                //t.domainGlobals = t.lists.domainGlobals;
                t.lists.simpleInstitutions = [];
                for (var i in t.lists.institutions){
                    t.lists.simpleInstitutions.push(t.lists.institutions[i].name);
                }
                t.form.addressVal = t.commonService.compareAddresses(t.form);
                t.valAllAcUpdFields(t.form);
            });
      }
      if (t.option == 'dataSurvey'){
        t.commonService.publicPage(true);
        validationModule.overWriteScope(scope);
        scope.flags.reactivateAccount = true;
        scope.oldForm ={};    
        scope.myQuestionsData = [];
        scope.securityData=[];
        scope.changedDataModified = {};
        scope.submitted = false;
        t.isReactivateSurvey = true;

        accountUpdateModule.init(http, http, scope.flags, scope.form, scope.lists, Promise, scope, scope.alerts);
        accountReactivateModule.init(http, http, t.cookies, scope, 'Reactivate an Account', false, Promise, setTimeout, true);

        t.commonService.doWhenAPICallsDone(function(){
            t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
            //t.domainGlobals = t.lists.domainGlobals;
            t.lists.simpleInstitutions = [];
            for (var i in t.lists.institutions){
                t.lists.simpleInstitutions.push(t.lists.institutions[i].name);
            };
            t.valAllAcUpdFields(t.form);
        });

      }
  }

  cancelAccountReactivation(){
    localStorage.removeItem('limLoginReason');
    t.commonService.signOut(null, scope);
  }

  submitReactivate(){
    t.dataSurveyOkToSave = false;
    if (scope.isReactivateSurvey){
      scope.form.projectShortNameList = 'ALCF_Data_Survey';
      t.commonService.removeNewDeletedObjs(scope.form, scope.flags.arrayFieldErrors);
    }
    accountReactivateModule.submitReactivate(scope);

    if (scope.isReactivateSurvey){
      t.commonService.doWhenAPICallsDone(function(){
              if (t.flags.reactivateSubmitted){
                t.commonService.signOut(null, scope, true);
              }
      });
    }
  }

  /*
        This function below is needed so that it can be called as a callback
        from the policy-agreement-option component without a parameter.
        Once it is called, then it can use the local (local to this object) t.form
        to call valAllAcUpdFields with the needed parameter. for issue 4777
  */
  valAllAcUpdFieldsH(){
    t.valAllAcUpdFields(t.form);
  }
  valAllAcUpdFields(form){
      t.flags.fldHasError = false;
      accountUpdateModule.validateAllFields(form, function(){
          //t.form.orcid = t.num11(t.form.orcid);
          t.form.scopusId = t.num11(t.form.scopusId);
          // check if we need to show an option to 'add a new phone number'
          t.addNewPhone = form.accountPhones && form.accountPhones.filter(function(item){
            return (!item.status) || (item.status == 'Active') ;
          }).length < 2;
          // email validation for uniqueness
          form.accountEmails = t.commonService.getUniqueEmails(form.accountEmails);
          for (var key in form.accountEmails) {
            var elem = form.accountEmails[key];
            if(elem.email && elem.status !== 'Deleted'){
              t.validateUniqueEmail(elem, t.flags.arrayFieldErrors);
            }
          }
          t.commonService.doWhenAPICallsDone(function(){
            t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
            t.commonService.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
            if(t.isReactivateSurvey){
              t.dataSurveyOkToSave = setDataSurveyOkToSave();
            }
            //check whether to enable Foreign National Info button or not (special case: issue#3701)
            t.flags.allReqdFieldsComplete = t.flags.updFormComplete;
            for (var key in t.arrayFieldErrors.accountEmails) {
              var elem = t.arrayFieldErrors.accountEmails[key];
              if(elem.email && elem.email.error){
                t.flags.allReqdFieldsComplete = false;
              }
            }
          });
      });
  }

  validateUniqueEmail(row, arrayFieldErrors){
    if(!row || !row.email) return;
    var emValReqd = false;
    var prevEmailArr = [];
    var checkNewEmail = false;
    var prevEmailChanged = false;
    // check if the email is in the previously saved emails list and has changed
    prevEmailArr = t.flags.prevSavedForm && t.flags.prevSavedForm.accountEmails.filter(function(pEmailItem){
        return (pEmailItem.id === row.id);
    });

    prevEmailChanged = (prevEmailArr && (prevEmailArr.length === 1) && (prevEmailArr[0].email !== row.email));

    // check for new emails
    if(prevEmailArr && prevEmailArr.length === 0){
        var nameInPrevEmailsArr = false;
        var delNameInAccEmailsArr = false;
        // check if the new name was already added to the account and now in deleted status
        nameInPrevEmailsArr = t.flags.prevSavedForm && t.flags.prevSavedForm.accountEmails.filter(function(pEmailItem){
            return (pEmailItem.email === row.email);
        }).length>0;
        if(nameInPrevEmailsArr){
            delNameInAccEmailsArr = t.form.accountEmails.filter(function(aEmailItem){
                return (aEmailItem.email === row.email && aEmailItem.status === 'Deleted');
            }).length>0;
        }

        checkNewEmail = (!nameInPrevEmailsArr || (nameInPrevEmailsArr && !delNameInAccEmailsArr));
    }

    emValReqd = (checkNewEmail || prevEmailChanged);
    // do email validation
    if(emValReqd) {
      validationModule.validateUniqueEmail(row, function(resp){
        arrayFieldErrors.accountEmails[row.id]['email'] = {error: resp, label: 'Email'};
      });
    }
  }

  goToMngPj(pjName){
        t.router.navigateByUrl('/manageProjectsEdit?name='+pjName);
  }

  num11(str){
    var i, c, result = '';
    if (!str) return '';

    for (i=0; (i < str.length); i++){
        c = str[i];
        if (('0' <= c) && (c <= '9')){
            result = result + c;
        }
    }
    result = result.slice(0,11);
    return result;
  }

  // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
  convertUTCtoCDT(s){
    return t.commonService.convertUTCtoCDT(s);
  }

  // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
  hasPrimary(arr){
    return t.commonService.hasPrimary(arr);
  }

  updateAccountInfo(beforeRedirect){
      scope.alerts = [];
      scope.confirmBtn = false;
      if(!beforeRedirect) {t.commonService.removeNewDeletedObjs(scope.form, scope.flags.arrayFieldErrors);}
      accountUpdateModule.updateAccountInfo(scope.form, scope.flags, scope.lists, Promise, scope.alerts, beforeRedirect);
      t.commonService.doWhenAPICallsDone(function(){
        t.confirmBtn = scope.confirmBtn;
        t.alerts = scope.alerts;
      });
  };

  initAccountUpdate(pageName) {
    t.commonService.publicPage(true);
    //t.commonService.getSetContext(scope, pageName, function(){
        scope.lists = {};
        scope.flags = {};   
        scope.form = {};
        scope.oldForm ={};    
        scope.myQuestionsData = [];
        scope.securityData=[];
        scope.changedDataModified = {};
        scope.submitted = false;
        t.flags.hasError = {};
    //});
    accountUpdateModule.init(http, http, scope.flags, scope.form, scope.lists, Promise, scope, scope.alerts, setTimeout);
  }

  verifyAccount(){
      t.commonService.scrollToHasError(scope, function(){
        scope.confirmBtn = (!scope.flags.errorStatus && t.confirmSaveChecked);
      });
  }

  isAccountUpdateSaveDisabled(form){
    if(!t.confirmBtn || !form.policyAlcfAck) return true;
    return accountUpdateModule.isSaveDisabled(form);
  }
  removeNmiErr(nmi) {
    if (nmi && document.querySelector("#nmi-div").classList.contains("error-section"))
      document.querySelector("#nmi-div").classList.remove('error-section');
  }
  selectPrimaryItem(row, array){
    // checkboxes in the browser take a few milliseconds to set the DOM after the click
    // wait for that to happen, then run this loop below
    setTimeout(function(){
      array.forEach(function(b){
          b.primary = (row === b);
      })
    }, 300);
  }
  accountAddItem(type){
    if(type === 'Phones'){t.addNewPhone = false;}
    editRecordModule.addItem(scope.form, scope.flags, type);
  }

  setPrimaryEmail(obj, id, email) {
    t.validateEmail(obj, email);
    t.selectPrimaryItem(obj, scope.form.accountEmails);
    if(obj.valid){
        t.hasPrimary(scope.form.accountEmails);
        accountUpdateModule.validateList('accountEmails', obj, id, t);
    }
  }
  validateEmail = function (obj, email) {
    obj.valid = validationModule.validEmailFormat(email);
  }
  addressChecked(){
    accountUpdateModule.addressChecked(http, scope.flags, scope.form, scope.lists);
  }
  selectPrimaryInstitution(row, array){

    // checkboxes in the browser take a few milliseconds to set the DOM after the click
    // wait for that to happen, then run this loop below
    setTimeout(function(){
      if (!row || (!row.affiliation && array.length > 1) || (row.affiliation == '') || (row.affiliation && row.affiliation.length < 3)){
          return;
      }
      array.forEach(function(b){
          b.primaryInstitution = (row === b);
      });
    }, 200);
  }
  
  getInstitutionId(name){
    return t.commonService.getInstitutionId(name, scope.lists);
  }

    //ngbTypeahead
    instTypeAhead = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
        : t.lists.simpleInstitutions && t.lists.simpleInstitutions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1))
    )

    // this is specific for account update
    setUUPreferredName(){
      accountRequestModule.setPreferredName(scope.form);
      t.validateAccountUpdate();
    };

    validateAccountUpdate = function () {
      scope.updateAccountSubmit = false;
      scope.confirmSaveChecked = false;
      scope.confirmBtn = false;
      scope.alerts = [];
      editRecordModule.validateAccountUpdate(scope.form, scope.flags);
    };

    addressChange(value){
          var workAddr, shipAddr;
          if (scope.form['addressVal']){
              workAddr = scope.form.accountAddresses[0];
              shipAddr = scope.form.accountAddresses[1];
              Object.keys(workAddr).forEach(function(key,val){
                  if (scope.form.addressVal && key != 'label' && key != 'id' ){
                    shipAddr[key] = workAddr[key];
                  }
              });
              //$timeout(function(){
                t.validateList('accountAddresses', shipAddr, shipAddr.id);
              //}, 2000);
          }
    }

    validateList(formName, obj, id) {
      t.commonService.onStopCalling(800, function(){
          accountUpdateModule.validateList(formName, obj, id, t);
      });
    };

    aupLocateByZip(formName, obj, row) {
      t.commonService.locateByZipAup(formName, obj, row, scope.form);
    }

    confirmDelete(obj, form, objName){
      obj.status = 'Deleted';
      t.commonService.removeEmailErrClass(obj.id, objName);
      t.valAllAcUpdFields(form);
    }


    onNoCountry(obj) {
      accountUpdateModule.onNoCountry(obj, scope.form);
    }

    getMobileToken() {
      scope.alerts = [];
      accountUpdateModule.getMobileToken(scope.form, scope.flags, scope.alerts);
    }

    goToTopOfCitizenArea(whichPage){
      if (whichPage == 'req'){
          var elem = document.getElementById('arCit');
          elem.scrollIntoView();
      }
      if (whichPage == 'upd'){
          var elem = document.getElementById('auCit');
          elem.scrollIntoView();
      }
      if (whichPage == 'react'){
          var elem = document.getElementById('auCit');
          elem.scrollIntoView();
      }
    }

    tryToGoToFavor(){
      // do validation first
      //$scope.validateAccountUpdate();
      accountUpdateModule.validateAllFieldsNow(scope.form);

      if (scope.flags.accountUpdate && (scope.flags.missing == 'Required: Status, ')){
          scope.flags.missing = '';
      }
      if (!scope.flags.missing){
          scope.flags.preFavorNotice=false; 
          t.updateAccountInfo(true);
          t.commonService.doWhenAPICallsDone(function(){
            scope.flags.loading = !scope.flags.error;
            scope.flags.fldHasError = scope.flags.error;
            if(!scope.flags.error) { t.directingToFavor(); }
          });
      }else{
          scope.flags.preFavorReq = scope.flags.missing;
          setTimeout(function(){scope.flags.preFavorReq = null;}, 2000);
      }
    }

    directingToFavor() {
      accountUpdateModule.directingToFavor(window, http, scope.form, scope.flags, scope.lists, t.cookies, setTimeout, null);
    }
    cancelAccountUpdate(){
      window.location.assign("/");
    }

    // Angular 2+ does not like to call these functions thousands of times from the template.  Redo this soon
    dialCode(name){
      return t.commonService.getCountryCode(name, scope.lists);
    }

    countryChange(row, form){
      t.commonService.onCountryChange(row, scope.lists, function(){
        t.valAllAcUpdFields(form);
      });
    }

    //ngbTypeahead for country code
    // currently we're not using this.
    // keeping for future reference

    searchCountry = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(function(term){
        return t.lists.countries.filter(function(c){
          if(/\d/.test(term)){
            return c.dialCode === term;
          } else {
            return (c.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
          }
        }).map(function (c) {
          return c.name;
        });
      })
    )

    onDataCollectionExit(){
    }
    setDataCollFlag(val){
        t.dataCollectionEnabled = val;
    }

    // specific to the states in USA
    onStateChange(row, form){
      t.commonService.onStopCalling(1000, getStAbr);

      function getStAbr(){
        var ss = t.lists.usaStates.filter(function(st){
          return st.name === row.state;
        });
        row.state = ss[0] ? ss[0].abr : '';
        t.valAllAcUpdFields(form);
      }

    }
    //ngbTypeahead for state abbreviation
    searchState = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          var sl = term.toLowerCase();
          return t.lists.usaStates.filter(function(c){
            return ((c.abr.toLowerCase().indexOf(sl) >= 0) || (c.name.toLowerCase().indexOf(sl) >= 0));
          }).map(function (c) {
            return (c.abr, c.name);
          });
        })
    )
}

/* ----------------- ---------------- ----------------- ----------------- ----------------- */


function setDataSurveyOkToSave(){
      var i, missing , extraneous, key, row, m;

      // these fields are not supposed to be part of the data survey
      // but for some obscure reason they are here.  Deleting them now
      extraneous = [
          'temp_ub_request_id',
          'accountQuestions',
          'certFavorHost',
          'domainAdmin',
          'status', 
          'email',
          'emailNew',
          'firstNameNew',
          'lastNameNew',
          'projectShortName',
          'svcApiWrite',
          'uiApiWrite',
      ];
      for (i in extraneous){
          if (scope.flags.fieldErrors[extraneous[i]]){
              delete scope.flags.fieldErrors[extraneous[i]];
          }
      }
      missing = validationModule.computeMissing(scope.flags.fieldErrors);
      // we also need to compute for the array fields: 
      var names = ['accountAddresses','accountPhones','accountEmails','accountInstitutions'];
      names.forEach(function(name){
        if(missing === null) missing = '';
         // now loop through each row
         for (key in scope.flags.arrayFieldErrors[name]){
            row = scope.flags.arrayFieldErrors[name][key];
            m = validationModule.computeMissing(row);
            if (m) missing = missing + m;
         };
      });

      return !missing;
}
