


import { Component, OnInit } from '@angular/core';
import {manageResourcesModule} from '../../../../../../../../frontend/manageResourcesModule';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';

var scope = null;
var t = null;
var http = null;
var cookies = null;
var ub3consoleLog = null;

@Component({
  selector: 'system-request',
  templateUrl: './system-request.component.html',
  styleUrls: ['./system-request.component.css']
})
export class SystemRequestComponent implements OnInit {

  flags = null;
  lists = null;
  listHasActive = null;
  authAlerts = [];
  publicPage(b) { };

  constructor(
                    h: Ub3httpService,
                    private c: CookieService,
                    private commonService: CommonService) 
  { 
    t = scope = this;
    ub3consoleLog = t.commonService.ub3consoleLog;
    http = h;
    cookies = c;
    scope.flags = {} ;
    scope.lists = {} ;
  }

  ngOnInit(): void {
        document.body.classList.add('public-page');
        var pageName = 'View Systems';
        t.flags.displayedPageName = pageName;
        manageResourcesModule.init(scope, http, scope.lists, scope.flags, pageName);
        t.commonService.doWhenAPICallsDone(function(){
            t.listHasActive  = manageResourcesModule.listHasActive (t.lists.allSystems, scope);
        });
  }

}

/*
test scripts:
2294                    has probelm with line:         var start_date = Cypress.moment().subtract(20, 'days').calendar();
issue3377.spec.js       ?


ng10-issue3377.spec.js  passes


*/
