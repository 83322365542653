

const { commonModule } = require("../../../../../../../frontend/commonModule");

var dataCollModule =
(function(){

    var clog = commonModule.ub3consoleLog;
    var obj = {
        getData: getData,
        validate: validate,
        save: save,
        setApiReady: setApiReady
    };
    var fiscalYear = null;
    var apiReady = true;
    var apiPath = '';

    function getData(http, option, cb){

        if (option == 'accountReactivate') apiPath = '/limited/';
        if (option == 'update')            apiPath = '/auth/';
        if (option == 'dataSurvey')        apiPath = '/limited/';

        // first find out if this user has this feature turned on
        getEnableFlag(http, function(enabled){
            var data = {};
            data.enabled = enabled;
            if (enabled){
                getYear(http, function(yr){
                  getQuestionsAndChoices(http, function(info){
                    data.questions = info;
                    fiscalYear = yr;
                    data.fiscalYear = yr;
                    // generate html ids for automated testing
                    data.questions.map(function(q){
                        q.choices.map(function(c){
                            c.hid = q.name.replaceAll(' ','-') + '-' + c.name.replaceAll(' ','-');
                            c.hid = c.hid.replaceAll('/','-').toLowerCase();
                            c.hname = c.hid.replaceAll('-',''); // for radio buttons
                        });
                    });
                    cb(data);
                  });
                });
            }else{
                cb(data);
            }
        });
    }

    function setApiReady(v){
        apiReady = v;
    }

    function getYear(http, cb){
        http.get(apiPath + 'getSurveyFiscalYear').subscribe(function(resp){
            if (resp.success){
                cb(resp.surveyFiscalYear);
            }else{
                // error message here
                cb(null);
            }
        });
    }

    function getEnableFlag(http, cb){
        if (!apiReady) {cb(true); return;}

        http.get(apiPath + 'getShouldUserSeeDataSurveyDemographics').subscribe(function(resp){

            if (resp.success){
                cb(resp.userShouldSeeDataSurveyDemographics);
            }else{
                cb(false);
            }
        });
    }
    function getQuestionsAndChoices(http, cb){
        var questions;

        questions = [
            {name: 'Gender',
             field_type: 'single',
             choices:[
                {name: 'Male'},
                {name: 'Female'},
                {name: 'Another Gender Not Listed'},
                {name: 'Do Not Wish To Provide'}
            ]},
            {name: 'Race',
             field_type: 'multiple',
             choices:[
                {name: 'American Indian or Alaska Native'},
                {name: 'Asian'},
                {name: 'Black or African American'},
                {name: 'Native Hawaiian or Other Pacific Islander'},
                {name: 'White'},
                {name: 'Do Not Wish To Provide', exclusive: true}
            ]},
            {name: 'Ethnicity',
             field_type: 'single',
             choices:[
                {name: 'Hispanic or Latino'},
                {name: 'Not Hispanic or Latino'},
                {name: 'Do Not Wish To Provide'}
            ]},
            {name: 'Disability',
             help_text: 'Do you have any of the following, check all that apply',
             field_type: 'multiple',
             choices:[
                    {name: 'Hearing Impairment'},
                    {name: 'Visual Impairment'},
                    {name: 'Mobility/Orthopedic Impairment'},
                    {name: 'Other Disability'},
                    {name: 'None of the Above Conditions Apply', exclusive: true},
                    {name: 'Do Not Wish To Provide', exclusive: true}
                ]
            },
        ];

        if (!apiReady) {cb(questions); return;}

        http.get(apiPath + 'getDemographicCategoriesAndSelections').subscribe(function(resp){
            var data={};
            if (resp.success){
                data = JSON.parse(JSON.stringify(resp.categoriesAndSelections));
                data.map(function(q){
                    q.choices = q.selections;
                    q.field_type = q.field_type.toLowerCase();
                });
                cb(data);
            }else{
                cb(null);
            }
        });

    }

    function validate(lastC, questions){
        var count;

        // first process the questions that are of type single
        questions.map(function(q){
            if (q.field_type == 'single'){
                // see how many are checked
                count = q.choices.filter(function(c){ return c.checked; }).length;

                if (count > 1){ //user trying to check more than is allowed

                    // try to leave only one as checked
                    q.choices.map(function(c){
                        if ((c == lastC) && (lastC.checked)){
                            c.checked = true;
                        }else{
                            c.checked = false;
                        }
                    });
                };
            }
        });

        // process the questions that are of type multiple
        questions.map(function(q){
            if (q.field_type == 'multiple'){
                // not much to do,
                // just turn off siblings if an exclusive one is on
                q.choices.map(function(c){
                    if ((c == lastC) && (c.exclusive) && (c.checked)){
                        // turn off siblings
                        var siblings = q.choices.filter(function(obj){ return obj != lastC;});
                        siblings.map(function(s){ s.checked = false;});
                    }
                });

                // if at least one non-exclusive is on, then turn off all the exclusives
                count = q.choices.filter(function(c){ return (!c.exclusive && c.checked);}).length ;
                if (count > 0){
                    q.choices.map(function(c){
                        if (c.exclusive){
                            c.checked = false;
                        }
                    });
                }
            }
        });

        // last step, see if all questions have at least one answer
        var enableSave = true;
        questions.map(function(q){
            count = q.choices.filter(function(c){ return c.checked; }).length;
            if (!count){
                enableSave = false;
            }
        });
        return enableSave;
    }

    function save(http, questions, cb){
        // prepare the object that the backend needs
        var obj = {};
        var str;
        questions.map(function(q){
            var ar =[];
            q.choices.map(function(c){
                if (c.checked){
                    ar.push(c.name);
                }
            });
            obj[q.name] = ar;
        });

        str = JSON.stringify(obj);
        var payload = {form: {responses: str, survey_fiscal_year: fiscalYear }} ;

        if (!apiReady) {cb({success:true}); return;}

        http.post(apiPath + 'addDemographicResponse', payload).subscribe(function(resp){
            if (resp.success && resp.message && (resp.message != '')){
                resp.success = false;
                resp.error = resp.message;
            }
            cb(resp);
        });

        //cb();
    }

    return obj;

})();

module.exports = {dataCollModule};


