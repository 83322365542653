

<div class="modal-header">
    <h2>
       Your session will timeout in <font color=red>{{state.counter}}</font> seconds because of inactivity.
       <br><br>
       To continue click anywhere in the page.
    </h2>
</div>

