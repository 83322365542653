
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import { manageProjectModule } from 'manageProjectModule';

let t = null;
let http = null;
let cookies = null;
let ub3consoleLog = null;
let comMod = null;

@Component({
  selector: 'project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.css']
})
export class ProjectManagementComponent implements OnInit {
  lists= {
    error: false
  };
  flags= {
    displayedPageName: ''
  };
  fieldErrors = {};
  arrayFieldErrors = {};
  isProjActive = true;
  activeProxyProjects = [];
  currentProxyProjects = [];
  showSpinner = true;
  publicPage(b) { };

  constructor(
    private commonService: CommonService,
    public h: Ub3httpService,
    public cookie: CookieService,
    public router: Router
  ) {
    t = this;
    http = h;
    cookies = cookie;
    t.flags.displayedPageName = 'Project Management';
    comMod = t.commonService;
    ub3consoleLog = comMod.ub3consoleLog;
    t.fieldErrors = comMod.getSharedObject('warning', 'fieldErrors');
    t.arrayFieldErrors = comMod.getSharedObject('warning', 'arrayFieldErrors');
  }
  @HostListener('window:popstate')
  onPopState() {
    t.ngOnInit();
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    t.initManageProject(t.flags.displayedPageName);
  }

  initManageProject(pageName) {
    t.flags.displayedPageName = pageName;
    manageProjectModule.init(t, http, http, t.lists, t.flags, pageName, function(lst){
      comMod.objCopyGuts(lst, t.lists);
      t.activeProxyProjects = t.lists.proxyProjects && t.lists.proxyProjects.filter(function(pj){
        return pj.projectStatus === 'Active';
      });
      t.showYourProjects();
    });
  }
  showYourProjects(){
    t.currentProxyProjects = (t.isProjActive) ? t.activeProxyProjects : t.lists.proxyProjects;
    t.showSpinner = false;
  }
  goToMngPjEdit(pjName){
      t.initManageProjectInfo(pjName, 'edit');
  }

  initManageProjectInfo(pjName, newOrEdit){
    t.hasChanges = false;
    t.previousAccounts = [];
    t.currentAccounts = [];
    t.authAlerts = [];
    t.flags.ok2Submit = false;
    t.form.projectShortName = pjName;
    manageProjectModule.setProjectDetails(pjName, newOrEdit, http, t.form, t.flags, t.lists, cookies, function(){
      t.previousAccounts = t.lists;
      comMod.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      t.router.navigate(['/manageProjectsEdit'], {queryParams: {name: pjName}});
      window.scrollTo(0,0);
    });
  }

}