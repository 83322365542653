import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {accountRequestModule} from '../../../../../../../frontend/accountRequestModule';
import {editRecordModule} from '../../../../../../../frontend/editRecordModule';
import {CommonService} from '@common/services';

var t: any = null;
var params = null;

@Component({
  selector: 'agreement-anl',
  templateUrl: './agreement-anl.component.html',
  styleUrls: ['./agreement-anl.component.css']
})
export class AgreementAnlComponent implements OnInit {
  flags: any = {};
  lists: any = {};

  constructor(private activeModal: NgbActiveModal, private comMod: CommonService) {
    t = this;
    params = comMod.getSharedObject('agreement', 'params');
  }
  @Input() form: any;
  @Input() callback: any;

  ngOnInit(): void {
  }
  onClickAgreement(){
    if (t.callback){
        t.callback();
        return;
    }
   
    if (params.pageName == 'Request an Account'){
        accountRequestModule.validateNow(t.form, t.flags);
    } else {
        t.updateAccountSubmit = false;
        t.confirmSaveChecked = false;
        t.confirmBtn = false;
        t.alerts = [];
        editRecordModule.validateAccountUpdate(t.form, t.flags);
    }
  }
  ok(){
    t.activeModal.dismiss();
  }
}
