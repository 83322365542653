var pluginsAdminModule =
    (function(){
        "use strict";
        var publicStuff;
        var cookies , http , httpNew , scope , timeout , win, comMod;
        cookies = http = httpNew = scope = timeout = win = comMod = null;  // cached services
        var jsServices = {};
        var alertError = {type : 'danger'};
        var alertSuccess = {type : 'success'};
        publicStuff = {
            init : init,
            pluginActivateOrDeactivate: pluginActivateOrDeactivate,
            pluginsInfo:pluginsInfo,
            postPluginDirInstall: postPluginDirInstall,
            postPluginDirActivate: postPluginDirActivate,
            postPluginDirDeactivate: postPluginDirDeactivate,
            postPluginDirUninstall: postPluginDirUninstall,
            pluginRunPeriodics: pluginRunPeriodics,
            setupPlugin: setupPlugin,
            savePlugin: savePlugin,
            setServices: setServices,
            pluginsDirs: pluginsDirs
        };

        function setServices(services){
          var i, serv;
          for (i in services){
            serv = services[i];
            jsServices[serv.sname] = serv;
          }
          cookies = jsServices.cookies;
          http    = jsServices.http;
          httpNew = jsServices.httpNew;
          scope   = jsServices.scope;
          timeout = jsServices.timeoutObj.timeout;
          win     = jsServices.window;

          comMod = jsServices.commonModule;
      }

        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user
        function init(scope, http, lists, pageName, callback){
            scope.containerFluid = true;
            comMod.getSetContext(scope, pageName, function(){
              scope.flags = {
                displayedPageName: pageName,
                isPluginActivated: true
              };
              scope.authAlerts = [];
              scope.form = {};
              scope.lists = {};
              scope.isClicked = false;
              lists.isPeriodicUibOpen = false;
              lists.isTriggerUibOpen = false;
              if (!scope.lists.length){
                  scope.lists = {};
              }
              pluginsInfo(http, scope.lists);
              pluginsDirs(http, scope.lists);
          });
          comMod.doWhenAPICallsDone(function(){
            if(callback) callback();
          });
        };

        function pluginsInfo(http, lists){
          scope.authAlerts = [];
            http.get("/admin/pluginsInfo")
                .success(function(resp){
                    if(resp.success){
                        lists.plugins = resp.plugins_info;
                        if(lists.plugins.length > 0) {
                          lists.install = true;
                          lists.plugins.forEach(function(value){
                            value.splitArr = {};
                            value.splitArr.periodics =[];
                            value.splitArr.trigger = [];
                            value.plugins.forEach(function(val){
                              if(val.plugin_type === 'periodics'){
                                value.splitArr.periodics.push(val);
                              }else if(val.plugin_type === 'triggers'){
                                value.splitArr.trigger.push(val);
                              }
                            });
                          });
                        }
                    } else {
                        alertError.msg = resp.error;
                        scope.authAlerts.push(alertError);
                    }
                }).error(function(response, status, headers, config){
                  alertError.msg = response.data.detail;
                  scope.authAlerts.push(alertError);
                });
        }

        function pluginsDirs(http, lists){
            http.get("/admin/pluginDirs")
                .success(function(resp){                  
                    if(resp.success){
                       lists.pluginsDirs = resp.plugins_dirs;
                    }
            });
        }

        function postPluginDirInstall(http, lists, cookies, callback) {
          scope.authAlerts = [];
          lists.active = true;
          var cdata = {active: lists.active};

          http.post("/admin/pluginDirInstall", cdata)
            .success(function(hresp){
                    if (hresp.success) {
                        pluginsInfo(http, lists);
                        lists.install = true;
                        comMod.doWhenAPICallsDone(function(){
                          if(callback) callback(scope, lists);
                        });
                    } else {
                        alertError.msg = hresp.error;
                        scope.authAlerts.push(alertError);
                        if(callback) callback(scope, lists);
                    }
            }).error(function(response, status, headers, config){
              alertError.msg = response.data.detail;
              scope.authAlerts.push(alertError);
              if(callback) callback(scope, lists);
            });
        }

        function postPluginDirUninstall(http, lists, cookies, callback) {
          scope.authAlerts = [];
          lists.active = false;

          http.post("/admin/pluginDirUninstall")
              .success(function(hresp){
                      if (hresp.success) {
                          pluginsInfo(http, lists);
                          lists.install = false;
                          if(callback) callback(scope.authAlerts);
                      } else {
                          alertError.msg = hresp.error;
                          scope.authAlerts.push(alertError);
                          if(callback) callback(scope.authAlerts);
                      }
              }).error(function(response, status, headers, config){
                  alertError.msg = response.data.detail;
                  scope.authAlerts.push(alertError);
                  if(callback) callback(scope.authAlerts);
              });
        }

        function postPluginDirActivate(http, cookies, callback) {
          scope.authAlerts = [];
          http.post("/admin/pluginDirActivate")
              .success(function(hresp){
                      if (hresp.success) {
                          if(callback) callback(scope.authAlerts);
                      } else {
                          alertError.msg = hresp.error;
                          scope.authAlerts.push(alertError);
                          if(callback) callback(scope.authAlerts);
                      }
              }).error(function(response, status, headers, config){
                  alertError.msg = response.data.detail;
                  scope.authAlerts.push(alertError);
                  if(callback) callback(scope.authAlerts);
              });
        }

        function postPluginDirDeactivate(http, cookies, callback) {
          scope.authAlerts = [];
          http.post("/admin/pluginDirDeactivate")
              .success(function(hresp){
                  if (hresp.success) {
                      if(callback) callback(scope.authAlerts);
                  } else {
                      alertError.msg = hresp.error;
                      scope.authAlerts.push(alertError);
                      if(callback) callback(scope.authAlerts);
                  }
              }).error(function(response, status, headers, config){
                  alertError.msg = response.data.detail;
                  scope.authAlerts.push(alertError);
                  if(callback) callback(scope.authAlerts);
              });
        }

        function postPluginActivate(http, cookies, type, callback) {
          scope.authAlerts = [];

          //var cdata = {plugin: type.plugin, firesWhen: type.firesWhen, triggerModel: type.triggerModel};
          if(type.plugin_type === 'periodics') {
            var cdata = {plugin: type.plugin, firesWhen: 'periodic', triggerObject: "null"};
          } else {
             var cdata = {plugin: type.plugin, firesWhen: type.trigger, triggerObject: type.trigger_obj};
          }
          
          http.post("/admin/pluginActivate", cdata)
          .success(function(hresp){
                  if (hresp.success) {
                      if(callback) callback(scope.authAlerts);
                  } else {
                      alertError.msg = hresp.error;
                      scope.authAlerts.push(alertError);
                      if(callback) callback(scope.authAlerts);
                  }
          }).error(function(response, status, headers, config){
              alertError.msg = response.data.detail;
              scope.authAlerts.push(alertError);
              if(callback) callback(scope.authAlerts);
          });
        }

        function postPluginDeactivate(http, cookies, type, callback) {
          scope.authAlerts = [];
          //var cdata = {plugin: type.plugin, firesWhen: type.firesWhen, triggerModel: type.triggerModel};
          if(type.plugin_type === 'periodics') {
            var cdata = {plugin: type.plugin, firesWhen: 'periodic', triggerObject: "null"};
          } else {
             var cdata = {plugin: type.plugin, firesWhen: type.trigger, triggerObject: type.trigger_obj};
          }

          http.post("/admin/pluginDeactivate", cdata)
          .success(function(hresp){
                  if (hresp.success) {
                      if(callback) callback(scope.authAlerts);
                  } else {
                      alertError.msg = hresp.error;
                      scope.authAlerts.push(alertError);
                      if(callback) callback(scope.authAlerts);
                  }
          }).error(function(response, status, headers, config){
              alertError.msg = response.data.detail;
              scope.authAlerts.push(alertError);
              if(callback) callback(scope.authAlerts);
          });       
        }

        function pluginActivateOrDeactivate(type, active, http, lists, cookies, callback){
          type.plugins.forEach(function(item){
              item.active = active;
              lists.isPeriodicUibOpen = true;
              lists.isTriggerUibOpen = true;
              setupPlugin(http, cookies, item, callback);
          });
        }

        function setupPlugin(http, cookies, type, callback) {
          if(type.active == true){
            postPluginActivate(http, cookies, type, callback);
          } else {
            postPluginDeactivate(http, cookies, type, callback);
          }
        }
        function pluginRunPeriodics(http, lists, type, callback) {
          scope.authAlerts = [];
          if(type.plugin_type === 'periodics'){
            var cdata = {plugin: type.plugin};
            var pluginIndex = lists.plugins[0].splitArr.periodics.findIndex(function (item){
              return item.plugin === type.plugin;
            });
            var runError = null;
            http.post("/admin/pluginRunPeriodic", cdata)
            .success(function(hresp){
              if (hresp.success) {
                alertSuccess.msg = "Successfully started the Periodic plugin: " + type.plugin + ". Please look at Logs for Plugin results."
                scope.authAlerts.push(alertSuccess);
                if(callback) callback(scope.authAlerts);

              } else {
                runError = hresp.error;
                alertError.msg = hresp.error;
                scope.authAlerts.push(alertError);
                if(callback) callback(scope.authAlerts);
              }
              if(pluginIndex !== -1) {
                lists.plugins[0].splitArr.periodics[pluginIndex].runError =  runError;
              }
            }).error(function(response, status, headers, config){
              alertError.msg = response.data.detail;
              scope.authAlerts.push(alertError);
              if(callback) callback(scope.authAlerts);
            });
          }
        }

        function savePlugin(http, lists, cookies, type, callback) {
          scope.authAlerts = [];

          if(type.plugin_type === 'periodics'){
            var data = {plugin: type.plugin, crontab: type.crontab};
            var pluginIndex = lists.plugins[0].splitArr.periodics.findIndex(function (item) {
              return item.plugin === type.plugin;
            });
            var cronError = null;
            http.post("/admin/pluginChangeCrontab", data)
            .success(function(hresp){
              if (hresp.success) {
                alertSuccess.msg = "Successfully updated the Crontab for " + type.plugin + "."
                scope.authAlerts.push(alertSuccess);
              } else {
                cronError = hresp.error;
              }
              if(pluginIndex !== -1) {
                lists.plugins[0].splitArr.periodics[pluginIndex].cronError =  cronError;
                if(callback) callback(scope.authAlerts);
              }
            }).error(function(response, status, headers, config){
              alertError.msg = response.data.detail;
              scope.authAlerts.push(alertError);
              if(callback) callback(scope.authAlerts);
            });
          }
        }

        // ----------- public stuff gets returned -----------
        return publicStuff;

    })();

module.exports = {pluginsAdminModule}
/*
  if ( (typeof module) != 'undefined'){
    // we are in angular 2+
    var c = "module.exports = {pluginsAdminModule}" ;
    eval(c);
  }*/
