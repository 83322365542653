import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {accountRequestModule} from '../../../../../../../frontend/accountRequestModule';
import {editRecordModule} from '../../../../../../../frontend/editRecordModule';
import {CommonService} from '@common/services';
import { GlobalConstants } from '@common/constants/global-constants';

var t: any = null;

@Component({
  selector: 'agreement-alcf',
  templateUrl: './agreement-alcf.component.html',
  styleUrls: ['./agreement-alcf.component.css']
})
export class AgreementAlcfComponent implements OnInit {

  flags: any = {};
  lists: any = {};
  ALCFPolicyAgreementURL = GlobalConstants.ALCFPolicyAgreementURL;

  constructor(private activeModal: NgbActiveModal, private commonService: CommonService) {
    t = this;
  }

  @Input() form: any;
  @Input() callback: any;

  ngOnInit(): void {
  }
  onClickAgreement = function(){
    if (t.callback){
        t.callback();
        return;
    }
    if (t.flags.displayedPageName == 'Request an Account'){
        accountRequestModule.validateNow(t.form, t.flags);
    } else {
        t.updateAccountSubmit = false;
        t.confirmSaveChecked = false;
        t.confirmBtn = false;
        t.alerts = [];
        editRecordModule.validateAccountUpdate(t.form, t.flags);
    }
  }
  ok(){
    t.activeModal.dismiss();
  }
}
