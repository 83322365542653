

// Account Reactivate Module
var multiFilterModule =
(function(){
        "use strict";
        var publicStuff;
        var headerKeys =[];
        var cookies , http , httpNew , scope , timeout , win  ;  // cached services
        cookies = http = httpNew = scope = timeout = win = null;  // cached services
        var jsServices = {};
        var comMod = null;

        var uibModal = null;
        publicStuff = {
            setServices: setServices,
            clearSearch: clearSearch,
            closeDuplModal: closeDuplModal,
            checkSearchFields: checkSearchFields,
            updateSearch: updateSearch,
            addMore: addMore,
            removeFilter: removeFilter,
            initMultiFilter: initMultiFilter,
            searchResults : searchResults,
            showNextResults : showNextResults,
            showPrevResults : showPrevResults,
            changeLimits : changeLimits,
            downloadSearchResults : downloadSearchResults
        };

        /*
        // angular 9 sends the extra parameter cm
        //
        function setServices(c, h, hNew, s, t, w, cm){
          cookies = c;
          http = h;
          httpNew = hNew;
          scope = s;
          timeout = t;
          win = w;
          comMod = cm ? cm : commonModule;
        }
        */
        function setServices(services){
            var i, serv;
            for (i in services){
              serv = services[i];
              jsServices[serv.sname] = serv;
            }    
            cookies = jsServices.cookies;
            http    = jsServices.http;
            httpNew = jsServices.httpNew;
            scope   = jsServices.scope;
            timeout = jsServices.timeoutObj.timeout;
            win     = jsServices.window;
    
            comMod = jsServices.commonModule;
        }
        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user

        function clearSearch(flags, form, lists, filterlist) {
          if(filterlist && filterlist.length > 0) {
            filterlist.splice(0, filterlist.length);
          }
          if(!filterlist){
            filterlist = [];

          }

          filterlist.push({
            selectedSf: null,
            filter_name: '',
            selectedOp: null,
            selectedVal: null,
            presetWithValue: null,
            active: false,
            selectedValues: [],
            showRemove: false
          });
          lists.searchKeyword = [];
          lists.filterValues.searchFilterErrorMsg = null;
          lists.selectedlistVal = '';
          lists.searchValues = {};
          flags.showAddToSearch = false;
          lists.filterRows = [];
          lists.svcOut = [];
          flags.toggleFilterData = false;
          updateSearch(filterlist, form, lists, flags);
        };

        function closeDuplModal(flags, form, lists, filterlist) {
          if(flags) {
            if(flags.dupWarnMsg && filterlist && flags.itemIdx){
              filterlist.splice(flags.itemIdx, 1);
            }
            flags.dupWarnMsg = null;
            updateSearch(filterlist, form, lists, flags);
          }
        }

        function initMultiFilter(s, lists, flags, form, um, filterrows, rows2display, filterlist){
          scope = s;
          clearSearch(flags, form, lists, filterlist);
          closeDuplModal(flags, form, lists, filterlist);
          uibModal = um;
          flags.initialRowLimit = 100;
          rows2display = flags.initialRowLimit;
          form.downloadFormat = '';
          flags.getSearchHelp = false;
          flags.showEmptyResults = false;
          flags.download = false;
          filterrows = null;
        };

        function formatDt(val, isDt) {
          var dt;
          if ((typeof val) == 'object'){  // angular 1
            dt = isDt? moment(val).format('YYYY-MM-DD') : val;
          }
          if ((typeof val) == 'string'){  // angular 2+
            dt = val.substr(0,10);
          }
          return dt;
        }
        function checkSearchFields(filterlist, filter, val, id, lists, flags, form) {
            flags.showEmptyResults = false;
            lists.filterValues.searchFilterErrorMsg = null;
            if(id==="searchField") {
              filter.showList = false;
              filter.showCalendar = (val && val.filter_name.endsWith('_date'));
              filter.selectedOp = null;
              filter.selectedVal = null;
              filter.selectedValues = [];
              filter.showRange = false;
              if(val && !val.requires_value && val.operators.length == 0 && val.preset_value.length > 0){
                filter.showNull = false;
                if(val.preset_value === '%') {
                  clearSearch(flags, form, lists, filterlist);
                  filterlist[0] = {
                    selectedSf: val,
                    filter_name: '',
                    selectedOp: null,
                    selectedVal: null,
                    presetWithValue: val.preset_value,
                    active: false,
                    selectedValues: [],
                    showRemove: true
                  };
                  form.searchBoxValue = val.preset_value;
                  flags.showAddToSearch = false;
                } else {
                  checkDuplicates(filterlist, filter, form, lists, flags);
                }
              }
              if(val && val.requires_value && val.operators.length > 0){
                if(Array.isArray(val.preset_value)) {
                  filter.showNull = false;
                  lists.searchValues.preset_values = val.preset_value;
                } else {
                  lists.searchValues.searchField = val.preset_value;
                }
              }
            } else if(id==="searchOpr" && val !== null) {
              filter.showRange = (val.value === '..') ? true: false;
              filter.showList =  (val.value === '[]') ? true: false;
              filter.showNull =  (val.value === '=' || val.value === '!') ? true: false;
              lists.searchValues.operator = val.value;
              if(filter.selectedVal){
                updateSearch(filterlist, form, lists, flags);
              }
            } else if(id === "searchVal") {
              if(val == '<<null>>') {
                filter.selectedVal = val;
              }
              if(filter.showList === true) {
                var listval = val.split(',');
                for( var i in listval) { listval[i] = listval[i].trim();}
                filter.selectedVal = listval.join(',');
              }
              checkDuplicates(filterlist, filter, form, lists, flags);
            }
        };
        function addMore(filterlist, flags) {
          flags.showAddToSearch = false;
          filterlist.push({
            selectedSf: null,
            filter_name: '',
            selectedOp: null,
            selectedVal: null,
            presetWithValue: null,
            active: true,
            selectedValues: [],
            showRemove: false
          });
        };

        function findSearchFilter(presetValue) {
          var sf;
          if(Array.isArray(presetValue)){
            sf = presetValue[0].split('=')[0];
          } else {
            sf = presetValue && presetValue.split('=')[0];
            sf = (sf=='%') ? 'action' : sf;
          }
          return sf;
        }
        function checkDuplicates (filterlist, filter, form, lists, flags) {
          comMod.onStopCalling(700, function(){
            flags.showAddToSearch = true;
            filter.showRemove = true;
            var filteredItems = [];
            if(filterlist.length > 1) {
              var searchFilter = findSearchFilter(filter.selectedSf.preset_value);
              var itemIdx = filterlist.findLastIndex(function(item){
                var sf = (item.selectedSf === null) ? '' : findSearchFilter(item.selectedSf.preset_value);
                return searchFilter === sf;
              });
              filteredItems = filterlist.filter(function(item){
                var sf = (item.selectedSf === null) ? '' : findSearchFilter(item.selectedSf.preset_value);
                return searchFilter === sf;
              });
              if(filteredItems.length > 1) {
                var duplWarnMsg = "Duplicate search for '"+searchFilter+"'.\n"+"Removing line#"+(itemIdx+1)+" from this list";
                flags.dupWarnMsg = duplWarnMsg;
                flags.itemIdx = itemIdx;
                return;
              }
            }
            updateSearch(filterlist, form, lists, flags);
          });
        }
        function updateSearch(filterlist, form, lists, flags) {
          var searchKeyword, i;

          searchKeyword = [];
          for (var idx in filterlist) {
            if(filterlist[idx].selectedSf !== null && filterlist[idx].selectedSf.filter_name){
              var searchField = filterlist[idx].selectedSf.preset_value;
              var operator = (filterlist[idx].selectedOp === null) ? '' : filterlist[idx].selectedOp.value;
              var searchValue = filterlist[idx].selectedVal;
              var isDt = (filterlist[idx].selectedSf.filter_name.endsWith('_date'));
              var showRange = (operator === '..') ? true: false;
              var showCalendar = isDt;
              if(showRange) {
                var selectedValues = filterlist[idx].selectedValues;
                searchValue = formatDt(selectedValues[0], isDt) + '..' + formatDt(selectedValues[1], isDt);
              } else if(showCalendar) {
                searchValue = (searchValue == '<<null>>') ? searchValue: formatDt(searchValue, isDt);
              }
              var kword='';
              if((operator === undefined) || (operator === '') || (operator === '..')) {
                operator = '';
              } else if(operator === '[]') {
                searchValue = operator[0]+searchValue+operator[1];
                operator = '';
              } else if((typeof operator === 'number') && (filterlist[idx].selectedSf.preset_value.length > 1)) {
                searchField = filterlist[idx].selectedSf.preset_value[operator];
                operator = '';
              }
              var val = searchField.replace('<value>', operator + searchValue);
              var keyword = (val === '%') ? val : "'" + val + "'";
              searchKeyword.push(keyword);
              filterlist[idx].presetWithValue = val;
            }
          }
          if(searchKeyword.length > 1) {
              for (i in searchKeyword) {
                kword += searchKeyword[i] + flags.fldDelimiter;
              }
              form.searchBoxValue = kword.trim();
          } else {
              form.searchBoxValue = keyword;
          }
        }

        function removeFilter(index, form, lists, flags, filterlist) {
          filterlist.splice(index, 1);
          if(filterlist.length > 0) {
            updateSearch(filterlist, form, lists, flags);
          } else {
            clearSearch(flags, form, lists, filterlist);
          }
        }

        function searchResults(lists, flags, callback){
          lists.filterRows = [];
          flags.showSearchResults = true;
          flags.limitNumber = 0;
          flags.clickNext = 0;
          flags.getSearchHelp = false;

          var s = scope.form.searchBoxValue;
          s = s ? s : '';
          httpNew.get(lists.searchPath, {params : {search : s}})
            .then(function(response){
              var res = response.data;
              var resp = Array.isArray(res) ? response : res;
                if(resp.success) {
                  lists.filterRows = resp.data;
                  /*
                  angular.forEach(lists.filterRows, function(value, key){

                  });
                  */
                  flags.showEmptyResults = false;
                  var noOfRows = lists.filterRows.length;
                  lists.isuibOpen = noOfRows > 0;
                  lists.filterValues.searchFilterErrorMsg = null;
                  if(noOfRows > 0 && noOfRows <= flags.initialRowLimit) {
                    flags.filterRowNumber = noOfRows;
                  } else if (noOfRows > flags.initialRowLimit){
                    flags.filterRowNumber = flags.initialRowLimit.toString();
                    flags.showNext = true;
                  } else if (noOfRows == 0){
                    flags.showEmptyResults = true;
                  }
                } else {
                  lists.filterRows= !lists.filterRows;
                  lists.filterValues.searchFilterErrorMsg = resp.error;
                  flags.showEmptyResults = false;
                }
            })
            .finally(function() {
              flags.showSearchResults = false;
              if(callback) callback();
            });
        }
        function showNextResults(lists, flags, filterrows, rows2display){
          flags.clickNext++;
          var rowsToDisplay = parseInt(rows2display);
          flags.limitNumber = flags.clickNext*rowsToDisplay;
          var total = parseInt(flags.limitNumber) + rowsToDisplay;
          flags.showNext = (total >= filterrows.length) ? false : true;
          flags.showPrev = true;
        }
        function showPrevResults(lists, flags, filterrows, rows2display){
          flags.clickNext--;
          var rowsToDisplay = parseInt(rows2display);
          flags.limitNumber = flags.clickNext*rowsToDisplay;
          flags.showNext = (parseInt(flags.limitNumber) >= filterrows.length) ? false : true;
          var total = parseInt(flags.limitNumber) + rowsToDisplay;
          flags.showPrev = (total <= rowsToDisplay) ? false : true;
        }
        function changeLimits(lists, flags, filterrows, rows2display){
            flags.limitNumber = 0;
            flags.clickNext = 0;
            flags.showPrev = false;
            flags.showNext = (parseInt(rows2display) >= filterrows.length) ? false : true;
        }

        // Download in CSV format
        function convertToCSV(objArray) {
          var jsonArr = objArray;
          var fields = Object.keys(jsonArr[0]);
          var replacer = function(key, value) {
            if(Array.isArray(value)){
              for(var i in value) {
                value[i] = value[i].replace(/,/g,' ').replace(/(['"\[\]])/g,'');
              }
              value= JSON.stringify(value);
              value = value.replace(/,/g,' || ').replace(/(["\[\]])/g,'');
            }
            return value === null ? '' : value;
          }
          var csv = jsonArr.map(function(row){
            return fields.map(function(fieldName){
              return JSON.stringify(row[fieldName], replacer)
            }).join(',')
          })
          csv.unshift(fields.join(',')) // add header column

          return csv.join('\r\n');
        }
        function download(filename, data, format) {
          var element = document.createElement('a');
          if (element.download !== undefined) {
            var url = (format == 'csv') ? URL.createObjectURL(data) : ('data:text/plain;charset=utf-8,' + data);
            element.setAttribute('href', url);
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }
        }
        function exportCSVFile(items) {
          var jsonObject = items;
          var csv = convertToCSV(jsonObject);
          var exportedFileName = 'export.csv';
          var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          if (navigator.msSaveBlob) { // IE 10+
              navigator.msSaveBlob(blob, exportedFileName);
          } else {
            download(exportedFileName, blob, 'csv');
          }
        }

        function downloadJsonFile(data){
            var newArr = [];
            comMod.objCopyGuts(data, newArr);
            if(newArr){
                newArr.forEach(function(dict){
                    if(dict['action_change']){
                       actionChange(dict['action_change'], function(rsp){dict['action_change'] = rsp})
                    }
                    // hashKey angular crap added when $index is not included on ng-repeat, remove
                    if(dict['$$hashKey']){delete dict['$$hashKey']}
                    if(dict['msg'] === null){delete dict['msg']}
                });
                // Creating a blob object from non-blob data using the Blob constructor
                var blob = new Blob([JSON.stringify(newArr)], { type: 'application/json' });
                var url = URL.createObjectURL(blob);
                // Create a new anchor element
                var a = document.createElement('a');
                a.href = url;
                a.download = 'logs-data.json';
                a.click();
                a.remove();
            }
        }

        function actionChange(arr, callback){
            var object = arr.reduce(function(acc, curr){
            return acc[curr] = curr.split(': ')[1], acc;
            }, {});
            var newObject = {};
            Object.keys(object).forEach(function(key){
            var keyName = object[key] = key.split(':')[0];
            var oldValue = key.split(':')[1];
            oldValue = oldValue.split('->')[0].trim();
            var newValue = key.split('->')[1].trim();
            newObject[keyName] = {old : oldValue, new : newValue}
            });
            return callback(newObject);
        }

      // Download All
      function downloadSearchResults(lists, flags, filterrows, fileType) {
        flags.download = true;
        if(fileType === 'csv'){exportCSVFile(filterrows)}
        if(fileType === 'json'){downloadJsonFile(filterrows)}
      }

        // ----------- public stuff gets returned -----------
        return publicStuff;
})();

module.exports = {multiFilterModule}

/*if ( (typeof module) != 'undefined'){
  // we are in angular 2+
  var c = "module.exports = {multiFilterModule}" ;
  eval(c);
}*/
