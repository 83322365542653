
<br>
<div>
    <h3>Institutions</h3>
    <div *ngIf="true || flags.showInstitutionAdminDashboard">
        <div >
            <div >
                Search by Institution name or division
            </div>
            <input 
                (keyup)="adminSearchInstitutions(form.searchInstitutions)"
                [(ngModel)]="form.searchInstitutions" 
                id="searchInstitutions" 
                name="a1"
                type="text"
                class="searchInst"
                size=24>
            <warning fid="searchInstitutions"></warning>
            <div class="match-not-found" 
                *ngIf="!flags.matchedInstitution && flags.searchCompleted">
                Match not found
            </div>
        </div>
        <div style="visibility: hidden; display: inline_block" id="errorMsg">""</div>

        <div *ngIf="flags.bootstrapAlert === 'getInstitutionsError'">
            <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
        </div>
        <div class="request-section" *ngIf="!flags.editRecord && lists.institutionRecords.length">
            <div *ngIf="flags.bootstrapAlert === 'admEditInstitutionRecord'">
                   <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
            </div>
            <div class="user-records-table condensed">
                <table class="table table-striped table-hover main-table admEditInstitutionRecord" id="admEditInstitutionRecord">
                    <thead id="admEditInstitutionHeader" style="background-color :#ADD8E6; color: white" [ngClass]="{'fixed-ahead': domainAdminFixedNav}"  name="#table-0" du-scrollspy>
                    <tr>
                        <th class="user-name">
                            <th-institution-sort table="admEditInstitutionRecord" txt="Institution Name" col="institutionName"></th-institution-sort>
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="Division" col="division"></th-institution-sort>
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="Institution State" col="ms_state"></th-institution-sort>
                                <input type="text" class="form-control" [(ngModel)]="lists.institutionRecords.ms_state"
                                    name="a2" style="background-color : white"
                                    placeholder="Filter Institution State">

                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="MUA Category" col="mua_agreement_category"></th-institution-sort>
                            <input type="text" class="form-control" [(ngModel)]="lists.institutionRecords.mua_agreement_category"
                                name="a3" style="background-color : white"
                                placeholder="Filter MUA Category">
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="MUA Start Date" col="mua_start_date"></th-institution-sort>
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="MUA End Date" col="mua_expiration_date"></th-institution-sort>
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="MUA State" col="mua_ms_state"></th-institution-sort>
                                <input type="text" class="form-control" [(ngModel)]="lists.institutionRecords.mua_ms_state"
                                    name="a4" style="background-color: white"
                                    placeholder="Filter MUA State">
                        </th>
                        <th>
                            <th-institution-sort table="admEditInstitutionRecord" txt="MUA Comment" col="comment"></th-institution-sort>
                            <input type="text" class="form-control" [(ngModel)]="lists.institutionRecords.comment"
                                    name="a5" style="background-color : white"
                                    placeholder="Filter Comments">
                        </th>
                    </tr>
                    </thead>
                    <tbody id="table-0">
                        <tr *ngFor="let institution of lists.institutionRecords; let i = index">
                            
                            <td id="institution{{i}}" class="user-name"> <b>{{institution.name}}</b></td> 
                            <td id="division{{i}}"><b>{{institution.division}}</b></td>
                            <td>
                                <select class="form-control selcls"
                                    name="a6"
                                
                                    (change)="onFieldChange(institution.ms_state, i, 'admEditInstitutionRecord','ms_state')"
                                    [(ngModel)]="institution.ms_state" id="statusBtn_{{i}}">
                                    <option value="Pending">Pending</option>
                                    <option value="Active">Active</option>
                                    <option value="Deleted">Deleted</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control selcls" style="width:auto;"
                                    name="a7"
                                    [(ngModel)]="institution.mua_agreement_category"
                                    (change)="onFieldChange(institution.mua_agreement_category, i, 'admEditInstitutionRecord','mua_agreement_category')" id="muaAgreementBtn_{{i}}">
                                    <option value="Not Specified">Not Specified</option>
                                    <option value="Proprietary">Proprietary</option>
                                    <option value="Non-proprietary">Non-proprietary</option>
                                    <option value="Proprietary & Non-proprietary">Proprietary & Non-proprietary</option>
                                    <option value="Not Required">Not Required</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                            <td>
                                <ub3date [form]="institution" key="mua_start_date" idPrefix={{institution.id}}
                                    (onChange)="onFieldChange(institution.mua_start_date, i, 'admEditInstitutionRecord','mua_start_date')"
                                    >
                                </ub3date>
                            </td>
                            
                            <td>
                                <ub3date [form]="institution" key="mua_expiration_date" idPrefix={{institution.id}}
                                    (onChange)="onFieldChange(institution.mua_expiration_date, i, 'admEditInstitutionRecord', 'mua_expiration_date')">
                                </ub3date>
                            </td>

                            <td>
                                <select class="form-control selcls"
                                    name="a8"
                                    [(ngModel)]="institution.mua_ms_state"
                                    (change)="onFieldChange(institution.mua_ms_state, i, 'admEditInstitutionRecord','mua_ms_state')" id="mua_State_{{i}}">
                            
                                    <option value="Pending">Pending</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </td> 
                        
                            <td class="comment-column">
                                <span class="floating-label-wrapper">
                                    <textarea 
                                        autosize rows="1" 
                                        type="text" 
                                        class="form-control autosize" 
                                        placeholder="Comment"
                                        name="a9"
                                        [(ngModel)]="institution.comment"
                                        (keyup)="onFieldChange(institution.comment, i, 'admEditInstitutionRecord','comment')"></textarea>
                                </span> 
                                <div class="action"
                                    
                                    *ngIf="flags.index == i && flags.tableSelected === 'admEditInstitutionRecord' && flags.showSubmit && !flags.same">
                                        
                                        <button style="width:150px" class="btn btn-primary btn-md"
                                            (click)="institutionRequestAction('institutionRecord', flags.ms_state, institution.id, lists.institutionRecords.indexOf(institution))" id="submitBtn">
                                            Submit
                                        </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div id="loading-overlay" *ngIf="lists.loadingInstitutions">
    <div class="spinner"></div>
    <br />
    Loading...
</div>

<ngx-json-viewer *ngIf="false" [json]="flags" [expanded]="false"></ngx-json-viewer>

