import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'single-filter',
  templateUrl: './single-filter.component.html',
  styleUrls: ['../multi-filter/multi-filter.component.css','./single-filter.component.css']
})
export class SingleFilterComponent implements OnInit {

  constructor() { }

  @Input() addmore : any;
  @Input() checksearchfields : any;
  @Input() filter : any;
  @Input() filtervalues : any;
  @Input() filterlist : any;
  @Input() flags : any;
  @Input() index : any;
  @Input() lists : any;
  @Input() operators : any;
  @Input() removefilter : any;
  @Input() updatesearch : any;

  ngOnInit(): void {
    if (!this.filter.selectedSf) {
      this.filter.selectedSf = {operators: []}; // angular 9 needs everything initialized
    }
  }

  doNothing(){
  }

}
