import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CommonService, Ub3httpService} from '@common/services';
import { otherAccountsModule } from 'otherAccountsModule';
import {domainAdminModule} from '../../../../../../../../frontend/domainAdminModule';

let t = null;
let http = null;
let scope: any = {};
let functions = null;
let ub3consoleLog = null;
let currentPath = null;

@Component({
  selector: 'create-other-accounts',
  templateUrl: './create-other-accounts.component.html',
  styleUrls: ['./create-other-accounts.component.css']
})
export class CreateOtherAccountsComponent implements OnInit {
  flags:any = {};
  lists = {
    otherAccountData: false,
    loadingAllOtherAccounts: true
  };
  timeout: any;
  pageName = "Create Other Accounts";
  adminAlerts: any;
  displayTable = true;


  constructor(
    private router: Router,
    private h: Ub3httpService,
    private commonService: CommonService
  ) {
    scope = t = this;
    http = h;
    ub3consoleLog = t.commonService.ub3consoleLog;

    scope.fieldErrors  = commonService.getSharedObject('warning', 'fieldErrors');
    scope.arrayFieldErrors = commonService.getSharedObject('warning', 'arrayFieldErrors');
    functions = commonService.getSharedObject('create-other-accounts', 'functions');
    functions.adminEditRecord = t.adminEditRecord;
    t.flags = commonService.getSharedObject('create-other-accounts', 'flags');
    t.lists = commonService.getSharedObject('create-other-accounts', 'lists');
  }

  ngOnInit(): void {
    document.body.classList.remove('public-page');
    currentPath = window.location.hash.replace('#/','');
    t.flags.createOtherAccount = false;
    t.flags.editRecord = false;
    t.flags.displayedPageName = t.pageName;;
    t.flags.bootstrapAlert = false;
    t.flags.account_cat_selected = false;
    t.flags.delta = false;

    t.lists.otherAccountData = false;
    t.lists.loadingAllOtherAccounts = true;
    otherAccountsModule.init(t, http, http, t.pageName,  t.lists,  t.flags, null);
    t.clearFilter(t.flags);
  }

  clearFilter(flags){
    flags.filter = {
      othAcc: {
        username: '',
        dispName: '',
        preferred_name:'',
        primary_email:'',
        sEmail:'',
        account_category:'',
        status:'',
        comment:''
      }
    };
  };

  goToCreateOtherAccountForm(acct_cat){
    t.commonService.clearFilterCache();
    t.lists.allOtherAccounts = [];
    t.router.navigateByUrl('createOtherAccountsForm?fromPage='+currentPath+'&category='+acct_cat);
  };
  adminEditRecord(name){
    t.router.navigateByUrl('createOtherAccountsForm?fromPage='+currentPath+'&userName='+name);
  };
  updateFilter(colName){
    t.commonService.onStopCalling(700, function(){
      t.updateFilterNow(colName);
    });
  }
  updateFilterNow(colName){
        t.commonService.filter('othAcc', t.lists.allOtherAccounts, colName, 'and', t.flags.filter['othAcc']);
        // trigger table repaint
        t.displayTable = false;
        setTimeout(function(){t.displayTable = true;}, 100);
  }
}