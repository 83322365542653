
<div class="join-project">
    <div class="reversed-white">
        <h1>{{flags.displayedPageName}}</h1>
        <p class="join-proj-desc">Request to become a team member of an existing project.</p>
    </div>
    <div class="ub3-card">
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="lists.projectFailure">{{lists.projectFailure}}</div>
            <div *ngIf="lists.activeProjects">
                <table id="pjTable" class="table update-account join-project-table">
                    <thead>
                        <th colspan="12">
                            <h3 class="table-heading">Available Projects</h3>
                        </th>
                    </thead>
                    <tbody>
                        <tr colspan="12">
                            <td colspan="12">
                                Click on the name of the project to add and remove proxies and team members.
                            </td>
                        </tr>
                        <tr colspan="12">
                            <ngx-datatable
                                #jpTable
                                id="jpTable"
                                class="bootstrap card expandable"
                                columnMode="flex"
                                [rowHeight]="auto"
                                [headerHeight]="50"
                                [footerHeight]="50"
                                [scrollbarH]="true"
                                [limit]=50
                                [rows]="lists.activeProjects"
                                [sorts]="[{prop: 'name', dir: 'asc'}]">
                                <ngx-datatable-row-detail [rowHeight]="auto">
                                    <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-row-detail-template>
                                        <div class="projectInfoData">
                                            <div class="projectInfo">
                                                <div>Project Name: {{projResultData.projectShortName}}</div>
                                                <div>Project Title: {{projResultData.projectTitle}}</div>
                                                <div>URL: {{projResultData.url}}</div>
                                                <div>Project Status: {{projResultData.projectStatus}}</div>
                                                <div>Principal Investigator: {{projResultData.piFirstName}} {{projResultData.piLastName}} ({{projResultData.piPreferredName}})</div>
                                                <div>Proxies: {{projResultData.proxies}}</div>
                                                <div>Members: {{projResultData.members}}</div>
                                                <div class="projResultDesc" [ngClass]="projDescShow ? 'active-arrow' : ''">
                                                    <h4 (click)="projDesc()"><span class="caret"></span><a class="alink">Project Description:</a></h4>
                                                    <div class="projInfo" *ngIf="projDescShow">
                                                        <p class="wordWrap">{{projResultData.projDescription}}</p>
                                                    </div>
                                                </div>
                                                <button class="btn btn-success btn-lg" (click)="joinProject(row,projResultData,rowIndex)" id="membershipBtn" [disabled]="flags.membershipRequested">{{flags.membershipRequested ? 'MEMBERSHIP STATUS: '+flags.membershipStatus :'REQUEST MEMBERSHIP'}}</button>
                                            
                                                <div>
                                                    <!-- *ngIf="p.isOpen" -->
                                                    <div *ngIf="(rowIndex == requestMember) && isVisiblemsg && (authAlerts && authAlerts.length > 0)">
                                                        <div uib-alert *ngFor="let alert of authAlerts; let aIndex = index" id="membershipReqMsg" class="alert"
                                                            [ngClass]="'alert-' + (alert.type || 'warning')"
                                                            (close)="closeAlert(aIndex)" dismiss-on-timeout="99999">{{alert.msg}}
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-row-detail>
                                <ngx-datatable-column name="Project Name" prop="name" [flexGrow]=".55">
                                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                        <div>
                                            <input id="projFilter"
                                                type="text"
                                                placeholder="Filter by Project Name"
                                                [(ngModel)]="flags.filter.actProj.name"
                                                class="form-control filterDesc"
                                                (keyup)="updateFilter('name')">
                                        </div>
                                        <span class="datatable-header-cell-label draggable" (click)="sort()">Project Name</span>
                                    </ng-template>
                                    <ng-template
                                                let-row="row"
                                                let-value="value"
                                                let-rowIndex="rowIndex"
                                                let-expanded="expanded"
                                                ngx-datatable-cell-template>
                                        <a (click)="initProjectInfo(row, 'edit', rowIndex, expanded)" id="projectName_{{row.name}}" class="alink">
                                            {{row.name}}
                                            </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Title" prop="title" [flexGrow]="1.25">
                                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                        <div>
                                            <input id="titleFilter"
                                                type="text"
                                                class="form-control filterDesc"
                                                placeholder="Filter by Title"
                                                [(ngModel)]="flags.filter.actProj.title"
                                                (keyup)="updateFilter('title')">
                                        </div>
                                        <span class="datatable-header-cell-label draggable" (click)="sort()">Title</span>
                                    </ng-template>
                                    <ng-template
                                                let-row="row"
                                                let-value="value"
                                                ngx-datatable-cell-template>
                                        <div class="wordWrap mediumFont">{{row.title}}</div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="PI" prop="pi" [flexGrow]=".6">
                                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                        <div>
                                            <input id="piFilter"
                                                type="text"
                                                class="form-control filterDesc"
                                                placeholder="Filter by PI"
                                                [(ngModel)]="flags.filter.actProj.pi"
                                                (keyup)="updateFilter('pi')">
                                        </div>
                                        <span class="datatable-header-cell-label draggable" (click)="sort()">PI</span>
                                    </ng-template>
                                    <ng-template
                                                let-row="row"
                                                let-value="value"
                                                ngx-datatable-cell-template>
                                        <div class="mediumFont">{{row.pi}}</div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
 </div>
