<div class="edit-user-details top" [ngClass]="{'fixed-ahead ':!mobile}">
    <h2>Edit User Account</h2>
    <div class="dom-adm-edit-rec-btns">
        <ul class="list-inline">
            <li *ngIf="flags.delta">
                <button 
                    id="saveContinueEdit" 
                    [disabled]="!flags.okToSubmit || flags.reqdFieldsMissing || statusWarning" 
                    class="btn btn-primary btn-lg"
                    (click)="adminSaveRecord('continue')">
                    Save and Continue to Edit
                </button>
            </li>
            <li *ngIf="flags.delta">
                <button 
                    id="saveExit" 
                    [disabled]="!flags.okToSubmit || flags.reqdFieldsMissing || statusWarning" 
                    class="btn btn-primary btn-lg"
                    (click)="adminSaveRecord('exit')">
                    Save and Exit
                </button>
            </li>
            <li>
                <button id="exitOrCancelBtn" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('cancel')">
                    {{flags.changedIdentity ? 'Exit' : 'Cancel'}}
                </button>
            </li>
            <li>
                <button id="ldapSync" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('LDAPSync')">LDAPSync
                </button>
            </li>
        </ul>
    </div>
    <div *ngIf="missingReqdFields.length > 0">
        <font color=red>
            Missing Fields: {{missingReqdFields}}
        </font>
    </div>
    <div class="alert-wrap">
        <alert-msg [(alerts)]="adminAlerts"></alert-msg>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-6" style="margin-left:25%;">
             <div class="confirmModal" *ngIf="openModal">
                 <h3 id="modal-title">Confirm
                     {{fieldName === 'mailToAdmin'?'Mail to Admin': (privilege === true ? 'Removing Privileges' : 'Elevated Privileges')}}
                 </h3>
                 <div *ngIf="fieldName === 'domainAdmin'">
                     ARE YOU SURE YOU WANT TO
                     {{privilege === true?' REMOVE DOMAIN ADMIN PRIVILEGES FOR THIS USER?':' MAKE THIS USER A DOMAIN ADMIN WITH ELEVATED PRIVILEGES?'}}
                 </div>
                 <div *ngIf="fieldName === 'uiApiWrite' || fieldName === 'svcApiWrite'">
                     ARE YOU SURE YOU WANT TO
                     {{privilege === true?' REMOVE ':' ADD '}}
                     {{fieldName === 'uiApiWrite'?' UI Access ':' Service API read-write '}}
                     PRIVILEGES FOR THIS USER?
                 </div>
                 <div *ngIf="fieldName === 'owner'">
                    ARE YOU SURE YOU WANT TO
                    <span>{{privilege.owner === true?' REMOVE ':' ADD '}} </span>
                    Owner PRIVILEGES FOR THIS USER?
                </div>
                 <div *ngIf="fieldName === 'mailToAdmin'">
                      ARE YOU SURE YOU WANT TO SEND MAIL TO ADMIN ?
                  </div>
                 <div *ngIf="fieldName !== 'owner' && fieldName !== 'mailToAdmin'">
                     <button (click)="closeModal(fieldName, false);" class="btn btn-md btn-primary">
                        {{privilege !== true?'No':'Yes'}}
                     </button>
                     <button (click)="closeModal(fieldName, true)" class="btn btn-md btn-default">
                        {{privilege === true?'No':'Yes'}}
                     </button>
                 </div>
                 <div *ngIf="fieldName === 'owner'">
                    <button type="button" class="btn btn-primary btn-lg"
                       (click)="closeOwnerModal(privilege, false)">
                    {{privilege.owner !== true?'No':'Yes'}}
                    </button>
                    <button type="button" class="btn btn-default btn-lg"
                       (click)="closeOwnerModal(privilege, true)">
                    {{privilege.owner === true?'No':'Yes'}}
                    </button>
                 </div>
                 <div *ngIf="fieldName === 'mailToAdmin'">
                      <button type="button" class="btn btn-lg btn-primary"
                        (click)="closeMailToAdmin(fieldName, false, userName, project)">
                          {{form.fieldName !== true?'No':'Yes'}}
                     </button>
                     <button type="button" class="btn btn-lg btn-default"
                        id="smta-yes"
                        (click)="closeMailToAdmin(fieldName, true, userName, project)" >
                         {{form.fieldName === true?'No':'Yes'}}
                     </button>
                  </div>
             </div>
         </div>
     </div>
</div>


<div class="row">
     <!--ACCOUNT INFORMATION-->
    <div class="col-md-5 col-lg-4 col-xl-3 domain-acct-info mb20">
        <div class="card ub3-card">
            <!-- get-column-height -->
            <div class="card-body">
                <h3>Account Information</h3>
                <table class="table-condensed table-user-details" id="otherAcc-acc-info">
                    <tbody>
                        <tr *ngIf="!flags.hideFormSection && form.activationDate !== null">
                            <td class="col-md-4 col-sm-5">
                                Creation Date
                            </td>
                            <td class="col-md-8">
                                <span class="text-primary">{{convertUTCtoCDT(form.activationDate)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                Policy Agreement
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" (click)="form.policyAck = !form.policyAck; validateAccountUpdate()">
                                        <i *ngIf="form.policyAck"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.policyAck" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection" class="citizen">
                            <td>
                                US Citizen
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" id="usCitizenBox" (click)="form.usCitizen = !form.usCitizen; validateAccountUpdate()">
                                        <i *ngIf="form.usCitizen"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.usCitizen" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <button *ngIf="!form.usCitizen" class="btn btn-default btn-lg favorSync" (click)="adminFavorSync()">FAVORSync</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 150px;">
                                Domain Admin
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" (click)="confirmAdminPrivilege(form.domainAdmin, 'domainAdmin')">
                                        <i *ngIf="form.domainAdmin"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.domainAdmin" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.domainAdmin"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.domainAdmin" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.domainAdmin">
                            <td>
                                Service API Write
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" name="form.svcApiWrite"
                                        (click)="confirmAdminPrivilege(form.svcApiWrite, 'svcApiWrite')">
                                        <i *ngIf="form.svcApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.svcApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.svcApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.svcApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.domainAdmin">
                            <td>
                                UI API Write
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" (click)="confirmAdminPrivilege(form.uiApiWrite, 'uiApiWrite')">
                                        <i *ngIf="form.uiApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.uiApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.uiApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.uiApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Host Cert
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" (click)="form.certFavorHost = !form.certFavorHost; validateAccountUpdate()">
                                        <i *ngIf="form.certFavorHost"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.certFavorHost" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Exp Date
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.expirationDate593)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Status
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.status593)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Warning Level
                            </td>
                            <td>
                                <span>
                                    <select id="favorWarnLevel"
                                        [(ngModel)]="form.favorWarnLevel"
                                        ngDefaultControl
                                        name="favorWarnLevel"
                                        (ngModelChange)="validateAccountUpdate()"
                                        class="form-control warnLvl">
                                        <option value={{num}} *ngFor="let num of warnLevels">{{num}}</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                Deactivation Warning Level
                            </td>
                            <td>
                                <span>
                                    <select id="deactivationWarnLevel"
                                        [(ngModel)]="form.deactivationWarnLevel"
                                        ngDefaultControl
                                        name="deactivationWarnLevel"
                                        (ngModelChange)="validateAccountUpdate()"
                                        class="form-control warnLvl">
                                        <option value={{num}} *ngFor="let num of warnLevels">{{num}}</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                ANL Person Id
                            </td>
                            <td>
                                <span>
                                    <input type="text" [disabled]="form.usCitizen == false" class="form-control"
                                        id="personId"
                                        placeholder="Person Id"
                                        [(ngModel)]="form.personId"
                                        (keyup)="validateAccountUpdate()">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Account Type
                            </td>
                            <td>
                                <div class="floating-label-wrapper">
                                    <select [(ngModel)]="form.accountType"
                                        id="accountType"
                                        (change)="checkDeactDate(form);">
                                        <option value="Annual">Annual</option>
                                        <option value="Permanent">Permanent</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status
                            </td>
                            <td>
                                <div>
                                    <select id="accountStatusRequested" class="form-control" *ngIf="form.status ==='Requested'"
                                        disabled>
                                        <option value="Requested">Requested</option>
                                    </select>
                                    <select id="accountStatus" *ngIf="form.status !=='Requested'" [(ngModel)]="form.status"
                                        (change)="validateAccountUpdate('status')">
                                        <option value="Requested">Requested</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Deleted">Deleted</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                    <div *ngIf="statusWarning">
                                        Are you sure you want to reject the account? 
                                        WARNING: this action is irreversible after you save this form.
                                        <br><br>
                                        <button class="btn btn-xs btn-danger" (click)="confirmYesRejected()" id="userEditStatusYes">
                                                Yes
                                        </button>
                                        Reject Account
                                        <br><br>
                                        <button class="btn btn-xs btn-primary" (click)="confirmNoRejected()" id="userEditStatusNo">
                                                No
                                        </button>
                                        set status back to {{flags.prevSavedForm.status}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Deactivation Date
                            </td>
                            <td>
                                <div *ngIf="form.accountType === 'Annual' && form.status !=='Requested'">
                                    <ub3date
                                        id="usrDeactDt"
                                        [form]="form"
                                        key="deactivation_date"
                                        [idPrefix]="form.userName"
                                        (onChange)="validateAccountUpdate()"
                                    >
                                    </ub3date>
                                </div>
                                <div *ngIf="form.accountType === 'Permanent' || form.status ==='Requested'">
                                    <div class="deactDt" id="usrDeactDt">{{(form.status ==='Requested') ? 'None' : form.deactivation_date}}</div>
                                     <div *ngIf="form.status !== 'Requested'" id="helpText" class="helpText">
                                        Please go to other screens to change the date for this type of account
                                     </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                User Updated
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.validationDate)}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Crypto Key Token</td>
                            <td>
                                <span>
                                    <select (change)="validateAccountUpdate()" [(ngModel)]="form.cryptoKeyOption"
                                        class="form-control">
                                        <option value="Physical">Physical</option>
                                        <option value="Mobile">Mobile</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Home Directory
                            </td>
                            <td>
                                <span>
                                    <input type="text" class="form-control"     style="width:100%"
                                        id="home_dir"
                                        placeholder="Home Directory"
                                        [(ngModel)]="form.home_dir"
                                        (ngModelChange)="validateAccountUpdate()">
                                </span>
                                <warning fid="home_dir"></warning>
                            </td>
                        </tr>
                        <tr>
                            <td class="comments" colspan="2">
                                <span>Comment</span>
                                <warning fid="comment"></warning>
                                <div class="floating-label-wrapper">
                                    <label for="comment"></label>
                                    <textarea size="40" autosize type="text" rows="2" class="form-control autosize" id="comment"
                                        placeholder="Comment"
                                        [(ngModel)]="form.comment"
                                        (focus)="autosizeFn('comment')"
                                        (blur)="getSmall('comment')"
                                        (ngModelChange)="validateAccountUpdate()"
                                        with-floating-label></textarea>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-md-7 col-lg-8 col-xl-9">
        <!-- EMAIL AND ACCOUNT NAME -->
        <div id="email-and-account-name" class="col-md-7 col-lg-6 col-xl-6 email-account mb20 inline">
            <div class="card ub3-card">
                <div class="card-body">
                    <h3>Email and Account Name</h3>
                    <table class="table-condensed table-user-details">
                        <tbody>
                            <tr>
                                <td class="emailAccInputLbl user-name">
                                    Username
                                </td>
                                <td class="emailAccInput">
                                    <span *ngIf="form.status !=='Requested'"
                                        class="text-primary bold">{{form.userName}}</span>
                                    <div *ngIf="form.status ==='Requested'"
                                        class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="userName"
                                            [(ngModel)]="form.userName"
                                            autocomplete="nope"
                                            (ngModelChange)="validateAccountUpdate()">
                                    </div>
                                    <warning fid="userName"></warning>
                                </td>
                            </tr>
                            <tr>
                                <td id="legalFirstName" class="emailAccInputLbl">
                                    Legal First/Given Name
                                </td>
                                <td class="emailAccInput">
                                    <div class="floating-label-wrapper">
                                        <input  type="text" class="form-control"
                                                id="firstName"
                                                autocomplete="nope"
                                                placeholder="First Name"
                                                [(ngModel)]="form.firstName"
                                                (keyup)="validateInput('firstName',$event)"
                                                (ngModelChange)="validateAccountUpdate()">
                                    </div>
                                    <warning fid="firstName"></warning>
                                </td>
                            </tr>
                            <tr>
                                <td id="legalMiddleName" class="emailAccInputLbl">
                                    Legal Middle Name
                                </td>
                                <td class="emailAccInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control"
                                            id="middleName"
                                            autocomplete="nope"
                                            placeholder="Legal Middle Name"
                                            [(ngModel)]="form.middleName"
                                            (ngModelChange)="validateAccountUpdate()" [disabled]="form.nmi">
                                    </div>
                                    <warning fid="middleName"></warning>
                                </td>
                                <td>
                                    <div class="cBox"
                                        (click)="form.nmi = !form.nmi; validateAccountUpdate();">
                                        <span class="cr" id="nmi">
                                            <i *ngIf="form.nmi"  class="cr-icon fas fa-check checkmarkBlk"></i>
                                        </span>
                                        <div class="nmiLbl">No Middle Name</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td id="legalLastName" class="emailAccInputLbl">
                                    Legal Last/Family Name
                                </td>
                                <td class="emailAccInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control"
                                            id="lastName"
                                            autocomplete="nope"
                                            placeholder="Last Name"
                                            [(ngModel)]="form.lastName"
                                            (ngModelChange)="validateAccountUpdate()">
                                    </div>
                                    <warning fid="lastName"></warning>
                                </td>
                            </tr>
                            <tr>
                                <td class="emailAccInputLbl">
                                    Preferred Name
                                </td>
                                <td class="emailAccInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text"
                                            class="form-control"
                                            id="preferredName"
                                            autocomplete="nope"
                                            placeholder="Preferred Name"
                                            [(ngModel)]="form.preferredName"
                                            (ngModelChange)="validateAccountUpdate()">
                                    </div>
                                    <warning fid="preferredName"></warning>
                                </td>
                            </tr>
                            <ng-container *ngFor="let f of form.accountEmails; let i = index; let last = last">
                                <tr *ngIf="!f.method">
                                    <td class="emailAccInputLbl">
                                        {{f.primary == true ? 'Preferred' : 'Alternate'}} Email
                                    </td>
                                    <td class="emailAccInput" id="emailtd-{{i}}">
                                        <div class="floating-label-wrapper">
                                            <input type="text" class="form-control" id="email-{{f.id}}"
                                                placeholder="Email"
                                                [(ngModel)]="f.email"
                                                autocomplete="nope"
                                                (ngModelChange)="ueValidate('accountEmails', f, f.id)">
                                        </div>
                                        <warning-a fn="accountEmails" fid="email" row={{f.id}}></warning-a>
                                    </td>
                                    <td class="add-remove">
                                        <div class="cBox">
                                            <span class="cr" id="emailCheckbox-{{i+1}}" (click)="selectPrimaryItem(f, form.accountEmails);ueValidate('accountEmails', f, f.id);">
                                                <i *ngIf="f.primary"  class="cr-icon fas fa-check checkmarkBlk"></i>
                                            </span>
                                            <div>Primary</div>
                                            <div *ngIf="!flags.reqdFieldsMissing && !f.method && f.primary !==true"
                                                title="Delete Email"
                                                class="delete-item">
                                                <button   class="fas fa-trash-alt"
                                                        id="del-alt-email"
                                                        (click)="f.status = 'Deleted';ueValidate('accountEmails', f, f.status);adminSaveRecord('continue')">
                                                </button>
                                                <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                            </div>
                                        </div>
                                        <div class="addAdditional" title="Add Email"
                                            *ngIf="flags.okToSubmit && flags.delta && f.method && last">
                                            <span (click)="adminSaveRecord('continue')"
                                                class="fas fa-plus-circle">
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let f of form.accountEmails; let i = index; let last = last;">
                                <tr *ngIf="!f.method && last">
                                    <div class="addAdditional" title="Add Email" (click)="accountAddItem('Emails')">
                                        <td> <span class="fas fa-plus-circle" id="addEmail"></span></td>
                                        <td style="margin-top: 5px;"><span>Add Email</span></td>
                                    </div>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let f of form.accountEmails; let i = index">
                                <tr *ngIf="f.method">
                                    <td class="emailAccInputLbl">
                                        Alternate Email
                                    </td>
                                    <td class="emailAccInput" id="emailtd-{{i}}">
                                        <div class="floating-label-wrapper">
                                            <input type="text" class="form-control" id="email-{{f.id}}"
                                                placeholder="Email"
                                                [(ngModel)]="f.email"
                                                autocomplete="nope"
                                                (ngModelChange)="ueValidate('accountEmails', f, f.id)">
                                        </div>
                                        <warning-a fn="accountEmails" fid="email" row={{f.id}}></warning-a>
                                    </td>
                                    <td>
                                        <span
                                            title="Delete Email"
                                            class="delete-item"
                                            *ngIf="!flags.reqdFieldsMissing">
                                            <button  id="del_{{i}}" class="fas fa-trash-alt"
                                                    (click)="f.status = 'Deleted';ueValidate('accountEmails', f, f.status);adminSaveRecord('continue')">
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <div class="addAdditional" title="Add Email" *ngIf="!flags.reqdFieldsMissing && flags.delta && flags.okToSubmit">
                                            <span (click)="adminSaveRecord('continue')"
                                                class="fas fa-plus-circle"></span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="2">
                                    <button *ngIf="flags.emailMatched && !flags.changedIdentity"
                                        class="btn btn-primary btn-lg"
                                        [disabled]="!flags.okToSubmit"
                                        (click)="editOtherAccount('User')">
                                    Update User Account
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- ACCOUNT QUESTIONS -->
        <div id="account-questions" class="col-md-12 col-lg-6 col-xl-6 questions mb20 inline" *ngIf="!flags.hideFormSection">
            <div class="card ub3-card">
                <div class="card-body">
                    <h3>Security Question and Answer</h3>
                    <div>
                        <p>
                            Please provide us with a question and answer that we can use to verify your identity.
                            <br>This will be used when you need to contact our help desk with matters concerning your account.
                            <br>
                            <b>Example Question:</b> What is the name of my pet iguana?
                            <br>
                            <b>Example Answer:</b> Spot.
                        </p>
                    </div>
                    <ng-container *ngFor="let f of form.accountQuestions; let i = index">
                        <table class="table-condensed table-user-details security" *ngIf="!f.method">
                            <tbody>
                                <tr>
                                    <td class="title">Question {{i + 1}}:</td>
                                    <td>
                                        <div class="floating-label-wrapper ">
                                            <input type="text" class="form-control" name="security_question" size="40"
                                                [(ngModel)]="f.securityQuestion"
                                                autocomplete="nope"
                                                id="securityQuestion_{{f.id}}"
                                                (keyup)="ueValidate('accountQuestions', f, f.id)">
                                        </div>
                                        <warning-a fn="accountQuestions" fid="securityQuestion" row={{f.id}}></warning-a>
                                    </td>
                                    <td>
                                        <div *ngIf="!flags.reqdFieldsMissing && form.accountQuestions && form.accountQuestions.length > 1 && !flags.hideDeleteQA"
                                            title="Delete Question/Answer"
                                            class="delete-item">
                                            <button class="fas fa-trash-alt" id="security_Question_Delete_{{i}}"
                                                    (click)="f.status = 'Deleted';ueValidate('accountQuestions', f, f.status);adminSaveRecord('continue')">
                                            </button>
                                            <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Answer {{i + 1}}:</td>
                                    <td>
                                        <div class="floating-label-wrapper ">
                                            <input type="text" class="form-control" name="security_answer" size="40"
                                                [(ngModel)]="f.securityAnswer"
                                                id="securityAnswer_{{f.id}}"
                                                autocomplete="nope"
                                                (keyup)="ueValidate('accountQuestions', f, f.id)">
                                        </div>
                                        <warning-a fn="accountQuestions" fid="securityAnswer" row={{f.id}}></warning-a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <!--This should never happen - questions length 0, but adding in case for testing...-->
                    <div *ngIf="!form.accountQuestions || !form.accountQuestions.length" class="addAdditional"
                        (click)="accountAddItem('Questions')">
                        <span class="fas fa-plus-circle"></span><span>Add Security Question</span>
                    </div>
                    <ng-container *ngFor="let f of form.accountQuestions; let i = index; let last = last">
                        <div *ngIf="!f.method && last && form.accountQuestions.length <= 2" class="addAdditional" (click)="accountAddItem('Questions')" id="addAdditionalSecurity">
                            <span class="fas fa-plus-circle"></span>
                            <span>Add Security Question</span>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let f of form.accountQuestions; let i = index">
                        <table class="table-condensed table-user-details security" *ngIf="f.method">
                            <tbody>
                                <tr>
                                    <td class="title">Security Question:</td>
                                    <td>
                                        <div class="floating-label-wrapper ">
                                            <input type="text" class="form-control" name="security_question"
                                                [(ngModel)]="f.securityQuestion" size="40"
                                                id="securityQuestion_{{form.userName}}"
                                                autocomplete="nope"
                                                (keyup)="ueValidate('accountQuestions', f, f.id)">
                                            <warning-a fn="accountQuestions" fid="securityQuestion" row={{f.id}}></warning-a>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="addAdditional" *ngIf="flags.delta && flags.okToSubmit && !flags.reqdFieldsMissing"
                                            title="Add Question/Answer">
                                            <span (click)="adminSaveRecord('continue')"
                                                class="fas fa-plus-circle"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Security Answer:
                                    </td>
                                    <td>
                                        <div class="floating-label-wrapper ">
                                            <input type="text" class="form-control" name="security_answer"
                                                [(ngModel)]="f.securityAnswer" size="40"
                                                autocomplete="nope"
                                                id="securityAnswer_{{form.userName}}"
                                                (keyup)="ueValidate('accountQuestions', f, f.id)">
                                            <warning-a fn="accountQuestions" fid="securityAnswer" row={{f.id}}></warning-a>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="errorMessage" *ngIf="errorMessage">
                                    <td>Please Enter the Security Question and answer</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- IdentityPhones -->
        <div *ngIf="!flags.hideFormSection" class="col-md-12 col-lg-12 col-xl-12 phones mb20">
            <div class="card ub3-card">
                <div class="card-body">
                    <h3>Phones</h3>
                    <table class="table-condensed table-user-details">
                        <tbody>
                            <tr *ngFor="let f of form.accountPhones; let i = index; let last = last">
                                <td>
                                    {{f.primary == true ? 'Primary' : 'Alternate'}}
                                </td>
                                <td>
                                    <table class="phoneTable">
                                        <thead>
                                            <tr>
                                            <th>Country</th>
                                            <th>Number</th>
                                            <th>Ext</th>
                                            <th>Type</th>
                                            <th></th>
                                            </tr>
                                        </thead>
                                        <tr>
                                            <td>
                                                <div class="floating-label-wrapper">
                                                    <input type="text"
                                                        [(ngModel)]="f.selectedCountry"
                                                        id="{{'phoneCountry_'+ i}}"
                                                        placeholder="{{f.phoneCountry ? dialCode(f.phoneCountry):'Country'}}"
                                                        (ngModelChange)="countryChange(f)"
                                                        [ngbTypeahead]="searchAddrCountry"
                                                        class="form-control"
                                                        autocomplete="nope"
                                                        with-floating-label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="floating-label-wrapper ">
                                                    <input type="text" class="form-control"
                                                        id='phoneNumber{{i}}'
                                                        placeholder="number"
                                                        autocomplete="nope"
                                                        [(ngModel)]="f.phoneNumber"
                                                        (ngModelChange)="ueValidate('accountPhones', f, f.id)">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="floating-label-wrapper ">
                                                    <input type="text" id="extension"
                                                        [(ngModel)]="f.extension"
                                                        class="form-control"
                                                        (keyup)="ueValidate('accountPhones', f, f.id)"
                                                        >
                                                </div>
                                            </td>
                                            <td>
                                            <div>
                                                <div class="floating-label-wrapper ">
                                                    <select (change)="ueValidate('accountPhones', f, f.id)"
                                                        [(ngModel)]="f.phoneLabel" class="form-control" id="{{'type_'+ i}}">
                                                        <option value='Mobile'>Mobile</option>
                                                        <option value='Work'>Work</option>
                                                        <option value='Home'>Home</option>
                                                    </select>
                                                </div>
                                            </div>
                                            </td>
                                            <td class="add-remove" *ngIf="!flags.reqdFieldsMissing">
                                                <div class="cBox" *ngIf="!f.method">
                                                    <span class="cr"id= "primary_{{i}}" (click)="selectPrimaryItem(f, form.accountPhones);ueValidate('accountPhones', f, f.id);">
                                                        <i *ngIf="f.primary"  class="cr-icon fas fa-check checkmarkBlk"></i>
                                                    </span>
                                                    <span>Primary</span>
                                                    <div *ngIf="f.primary !== true"
                                                        title="Delete Phone"
                                                        class="delete-item">
                                                        <button class="fas fa-trash-alt"
                                                                id="delete" (click)="f.status = 'Deleted';ueValidate('accountPhones', f, f.status);adminSaveRecord('continue')">
                                                        </button>
                                                        <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                                    </div>
                                                </div>
                                                <div class="addAdditional" title="Add Phone"
                                                    *ngIf="flags.okToSubmit && flags.delta && f.method && last">

                                                    <button (click)="adminSaveRecord('continue')" class="btn btn-primary btn-sm" id="save">Save</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="errorMsg">
                                            <td>
                                                <warning-a fn="accountPhones" fid="phoneCountry" row={{f.id}}></warning-a>
                                            </td>
                                            <td>
                                                <warning-a fn="accountPhones" fid="phoneNumber" row={{f.id}}></warning-a>
                                            </td>
                                            <td>
                                                <warning-a fn="accountPhones" fid="extension" row={{f.id}}></warning-a>
                                            </td>
                                            <td colspan="2">
                                                <warning-a fn="accountPhones" fid="phoneLabel" row={{f.id}}></warning-a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!form.accountPhones || !form.accountPhones.length"
                        class="addAdditional"
                        (click)="accountAddItem('Phones',form.accountPhones)">
                        <span class="fas fa-plus-circle"></span>
                        <span>Add Phone</span>
                    </div>
                    <ng-container *ngFor="let f of form.accountPhones; let i = index; let last = last">
                        <div *ngIf="!f.method && last && form.accountPhones.length <= 1"
                            class="addAdditional"
                            (click)="accountAddItem('Phones',form.accountPhones)">
                            <span class="fas fa-plus-circle" id="addPhone"></span>
                            <span>Add Phone </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <!-- IdentityAddress -->
    <div *ngFor="let f of form.accountAddresses; let i = index" class="col-md-12 col-lg-6 col-xl-6 mb20">
        <div *ngIf="!flags.hideFormSection" class="address">
            <div class="card ub3-card">
                <div class="card-body">
                    <h3>{{f.label}} Address</h3>
                    <table class="table-condensed table-user-details">
                        <tbody>

                            <tr>
                                <td>{{f.label}} Country
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text"
                                            [(ngModel)]="f.country"
                                            id="{{f.label}}-country"
                                            autocomplete="nope"
                                            (keyup)="onNoCountry(f);ueValidate('accountAddresses', f, f.id)"
                                            placeholder="type country name"
                                            [ngbTypeahead]="searchAddrCountry"
                                            class="form-control">
                                        <warning-a fn="accountAddresses" fid="country" row={{f.id}}></warning-a>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="f.country == 'United States'">
                                <td style="width:150px">{{f.label}}
                                    Zip
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-zipcode"
                                            placeholder="Zip Code"
                                            [(ngModel)]="f.zip"
                                            autocomplete="nope"
                                            (keyup)="aupLocateByZip('accountAddresses', f, i); ueValidate('accountAddresses', f, f.id)">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{f.label}} Address 1
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-street"
                                            placeholder="Address Line 1"
                                            [(ngModel)]="f.street1"
                                            autocomplete="nope"
                                            (keyup)="ueValidate('accountAddresses', f, f.id);">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="street1" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                            <tr>
                                <td> {{f.label}} Address 2
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-street2"
                                            placeholder="Address Line 2 (optional)"
                                            [(ngModel)]="f.street2"
                                            autocomplete="nope"
                                            (keyup)="ueValidate('accountAddresses', f, f.id);">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="street2" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                            <tr>
                                <td> {{f.label}} Address 3
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-street3"
                                            placeholder="Address Line 3 (optional)"
                                            [(ngModel)]="f.street3"
                                            autocomplete="nope"
                                            (keyup)="ueValidate('accountAddresses', f, f.id);">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="street3" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                            <tr>
                                <td>{{f.label}}
                                    City
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-city"
                                            placeholder="City"
                                            [(ngModel)]="f.city"
                                            autocomplete="nope"
                                            (keyup)="ueValidate('accountAddresses', f, f.id)">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="city" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{f.label}} State
                                </td>
                                <td class="addrInput">
                                    <div *ngIf="f.country == 'United States'" class="floating-label-wrapper">
                                        <input type="text"
                                            [(ngModel)]="f.state"
                                            id="{{f.label}}-state"
                                            placeholder="State Abbreviation"
                                            (ngModelChange)="onStateChange(f)"
                                            [ngbTypeahead]="searchAccAddrState"
                                            typeahead-template-url="abrStateSelect.html"
                                            class="form-control"
                                            autocomplete="nope"
                                            typeahead-editable="ctryStateEditable(f.country)"
                                            (focus)="zipHint = true"
                                            >
                                        <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                                    </div>
                                    <div *ngIf="f.country !== 'United States'" class="floating-label-wrapper">
                                        <input type="text"
                                            [(ngModel)]="f.state"
                                            (keyup)="ueValidate('accountAddresses', f, f.id)"
                                            id="{{f.label}}-state"
                                            placeholder="State/Province Region"
                                            class="form-control"
                                            autocomplete="nope"
                                            (focus)="zipHint = true"
                                            >
                                        <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="f.country !== 'United States'">
                                <td style="width:150px">{{f.label}}
                                    Zip
                                </td>
                                <td class="addrInput">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="{{f.label}}-zip"
                                            placeholder="Zip Code"
                                            [(ngModel)]="f.zip"
                                            autocomplete="nope"
                                            (keyup)="aupLocateByZip('accountAddresses', f, i); ueValidate('accountAddresses', f, f.id)">
                                    </div>
                                    <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- AFFILIATION -->
    <div *ngIf="!flags.hideFormSection" class="col-md-12 col-lg-12 col-xl-12 institution mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Employer/Institute information
                </h3>
                <div>
                    University or Institution of Employment:
                    check if the user has an affiliation that is new and not approved
                </div>
                <table class="table-condensed table-user-details">
                    <tbody>
                        <ng-container *ngFor="let f of form.accountInstitutions; let i = index; let last = last">
                            <tr *ngIf="!f.method">
                                <td style="width:130px">
                                    {{f.primaryInstitution == true ? 'Primary' : 'Alternate'}}
                                </td>
                                <td>
                                    <div class="floating-label-wrapper employer">
                                        <input
                                            type="text"
                                            [(ngModel)]="selectedInst"
                                            id="affiliation{{i}}"
                                            placeholder="{{f.affiliation}}"
                                            [ngbTypeahead]="searchAccInstn"
                                            (selectItem)="ueValidate('accountInstitutions', f, f.id);f.institutionId = getInstitutionId(f.affiliation);"
                                            class="form-control"
                                            [disabled]="(f.id >= 0)"
                                            autocomplete="nope">
                                    </div>
                                </td>
                                <td style="width:20%">
                                    <div class="cBox">
                                        <span class="cr" id="temp_{{f.affiliation.split(' ').join('_')}}" (click)="selectPrimaryInstitution(f, form.accountInstitutions);ueValidate('accountInstitutions', f, f.id);">
                                            <i *ngIf="f.primaryInstitution"  class="cr-icon fas fa-check checkmarkBlk"></i>
                                        </span>
                                        <span>Primary</span>
                                        <span class="delete-item ml10"
                                        *ngIf="!flags.reqdFieldsMissing">
                                            <button class="fas fa-trash-alt"
                                                    title="Delete Institution"
                                                    *ngIf="f.primaryInstitution !== true"
                                                    id="deleteInst_{{f.affiliation.split(' ').join('_')}}"
                                                    (click)="f.status = 'Deleted';ueValidate('accountInstitutions', f, f.status);adminSaveRecord('continue')">
                                            </button>
                                            <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                        </span>
                                        <span class="cr ml10" id="userAck{{i}}"
                                            (click)="f.ack = !f.ack; ueValidate('accountInstitutions', f, f.ack);">
                                            <i *ngIf="f.ack"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!f.ack" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                        <span>User Ack</span>
                                        <span class="ml10 mr10 disabled-item">
                                            <i *ngIf="f.mua"  class="fas fa-check checkmark"></i>
                                            <i *ngIf="!f.mua" class="fas fa-times removeIcon"></i>
                                        </span>
                                        <span>MUA</span>
                                    </div>
                                    <warning-a fn="accountInstitutions" fid="affiliation" row={{f.id}}>
                                    </warning-a>
                                </td>
                            </tr>
                            <tr *ngIf="f.method">
                                <td style="width:100px" id="instLbl">
                                    {{form.accountInstitutions.length === 1 ? 'Primary' : 'Alternate'}}
                                </td>
                                <td>
                                    <div class="floating-label-wrapper employer">
                                        <input type="text"
                                            [(ngModel)]="f.affiliation"
                                            id="newAffiliation"
                                            placeholder="{{f.affiliation}}"
                                            [ngbTypeahead]="searchAccInstn"
                                            (ngModelChange)="onInstSelected($event,f)"
                                            class="form-control"
                                            autocomplete="nope">
                                            
                                    </div>
                                </td>
                                <td style="width:20%">
                                    <div class="addAdditional"
                                        (click)="adminSaveRecord('continue')"
                                        *ngIf="!flags.reqdFieldsMissing && flags.delta && f.affiliation && f.institutionId">
                                        <span class="fas fa-plus-circle" id="complete"></span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="!f.method && last" >
                                <td colspan=2 class="addInst" (click)="accountAddItem('Institutions')">
                                    <span class="fas fa-plus-circle"></span>
                                    <span id="domainaddInst">Add Institution</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div *ngIf="!form.accountInstitutions || !form.accountInstitutions.length"
                    class="addAdditional" (click)="accountAddItem('Institutions')">
                    <span class="fas fa-plus-circle"></span>
                    <span>Add Institution</span>
                </div>
                <br>

                <!---------- Career Level ---------->
                <h3>Career Level</h3>
                <div class="form-group floating-label-wrapper">
                    <label for="employment_level"></label>
                    <input type="text" class="form-control"
                        [(ngModel)]="form.employment_level"
                        id="careerLevel"
                        placeholder="Choose your career level"
                        autocomplete="nope"
                        [ngbTypeahead]="searchEmplLevel"
                        (keyup)="validateInput('employment_level',$event)"
                        (ngModelChange)="onEmplLvlSelected($event)">
                    <warning fid="employment_level"></warning>
                </div>
                <!---------- ORCID ---------->
                <h3>ORCID</h3>
                <div class="form-group floating-label-wrapper">
                    <span class="orcId">
                        <input type="text" class="form-control inBox"
                        id="orcid"
                        placeholder=" "
                        [(ngModel)]="form.orcid"
                        autocomplete="nope"
                        (keyup)="flags.orcid = true;validateAccountUpdate('orcId')">
                        <label for="orcid" class="inputLbl">Enter your ORCID</label>
                    </span>
                    <span *ngIf="flags.prevSavedForm.orcid && form.orcid && flags.prevSavedForm.orcid != form.orcid">
                        Current value in database: {{flags.prevSavedForm.orcid}}
                    </span>
                    <warning fid="orcid"></warning>
                 </div>
                <!---------- SCOPUSID ---------->
                <h3>SCOPUSID</h3>
                <div class="form-group floating-label-wrapper">
                    <input type="text" class="form-control inBox" id="scopusId"
                       placeholder=" "
                       [(ngModel)]="form.scopusId"
                       autocomplete="nope"
                       (keyup)="validateInput('scopusId',$event);flags.orcid = false;validateAccountUpdate()">
                    <label for="scopusId" class="inputLbl">Enter your SCOPUSID</label>
                    <warning fid="scopusId"></warning>
                 </div>
            </div>
        </div>
    </div>

    <!-- PROJECT Membership -->
    <div class="col-md-12 col-lg-12 col-xl-12 projects mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Project Membership Status for <span class="name">  </span><span
                    class="name bold">{{form.preferredName}}</span>
                </h3>
                <div id="projMemStatTable" 
                     *ngIf="form.projectList && displayTable && flags && flags.filter" 
                     class="requests-table admProjStatus otherAccTable">
                    <ngx-datatable
                        #admProjStatus
                        class="bootstrap card expandable centerText projMemStatTableTest"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.projectList"
                        [sorts]="[{prop: 'projectShortName', dir: 'asc'}]"
                        *ngIf="membershipStatus !== 'Rejected' && membershipStatus !== 'Deleted'">
                            <ngx-datatable-column name="Name" prop="projectShortName" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-projectShortName"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Project Name"
                                            [(ngModel)]="flags.filter.projMem.projectShortName"
                                            (keyup)="updateFilter('projectShortName')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Project Name</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="projects projShNameTest">
                                        <a  style="cursor: pointer"
                                            id="{{row.projectShortName}}"
                                            (click)="goToEditProject(row.projectShortName)">
                                            <font color=blue>{{row.projectShortName}}</font>
                                        </a>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Allocation Type" prop="allocationType" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-allocationType"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Allocation Type"
                                            [(ngModel)]="flags.filter.projMem.allocationType"
                                            (keyup)="updateFilter('allocationType')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Allocation Type</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="{{row.projectShortName}}-allocType">{{row.allocationType}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Allocation End Date" prop="allocationEndDate" [flexGrow]=".6">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div>
                                      <input
                                           type="text"
                                           [readonly]="true"
                                           placeholder=""
                                           class="noBorder"
                                      >
                                   </div>
                                    <span class="datatable-header-cell-label">Allocation End Date</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="pm-enddate-{{row.projectShortName}}-{{row.allocationEndDate | date: 'yyyy-MM-dd'}}">{{convertUTCtoCDT(row.allocationEndDate)}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Project Membership" prop="membershipStatus" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                        <input
                                            id="projMem-filter-membershipStatus"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Project Membership"
                                            [(ngModel)]="flags.filter.projMem.membershipStatus"
                                            (keyup)="updateFilter('membershipStatus')"
                                        >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Project Membership</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span 
                                        *ngIf="row.membershipStatus"
                                        id="{{row.membershipStatus.split(' ').join('_').split('/').join('_')}}_{{row.projectShortName}}">
                                        <span *ngIf="form.preferredName == row.piPreferredName">{{row.membershipStatus}}</span>
                                        <ng-container *ngIf="form.preferredName != row.piPreferredName">
                                            <span 
                                                *ngIf="!uiState[row.projectShortName] || !uiState[row.projectShortName].editing"
                                                (click)="enableEdit(form.projectList, row.projectShortName)" 
                                                id="edit-stat-{{row.projectShortName}}"
                                                style="cursor:pointer">
                                                <img height="14px" width="14px" src="/frontend/assets/pen-solid.svg" 
                                                     id="pen-{{row.projectShortName}}">
                                                {{row.membershipStatus}}
                                            </span>
                                            <ng-container *ngIf="uiState[row.projectShortName] && uiState[row.projectShortName].statusOptions && uiState[row.projectShortName].editing">
                                                <select id="mem-stat-{{row.projectShortName}}" 
                                                        [(ngModel)]="row.membershipStatus" 
                                                        class="form-control inBox">
                                                    <option *ngFor="let t of uiState[row.projectShortName].statusOptions" value="{{t}}">
                                                        {{t}}
                                                    </option>
                                                </select>
                                                <ng-container 
                                                    *ngIf="uiState[row.projectShortName] && (uiState[row.projectShortName].origMstat != row.membershipStatus)">
                                                    <button class="btn btn-primary btn-xs" 
                                                            id="save"
                                                            (click)="saveMemStatus(row)"> 
                                                        save 
                                                    </button>
                                                </ng-container>
                                                <button class="btn btn-primary btn-xs" 
                                                        id="cancel"
                                                        (click)="cancelMemStatusEdit(form.projectList)">
                                                        cancel 
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="PI" prop="piPreferredName" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-piPreferredName"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by PI"
                                            [(ngModel)]="flags.filter.projMem.piPreferredName"
                                            (keyup)="updateFilter('piPreferredName')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">PI</span>
                                 </ng-template>
                                 <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="{{row.projectShortName}}-piPreferredName">{{row.piPreferredName}}</span> 
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Proxies" prop="proxyPreferredName" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-proxyPreferredName"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Proxies"
                                            [(ngModel)]="flags.filter.projMem.proxyPreferredName"
                                            (keyup)="updateFilter('proxyPreferredName')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Proxies</span>
                                 </ng-template>
                                 <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="{{row.projectShortName}}-proxyPreferredName">{{row.proxyPreferredName}}</span> 
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Project Role" prop="projectRole" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-projectRole"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Project Role"
                                            [(ngModel)]="flags.filter.projMem.projectRole"
                                            (keyup)="updateFilter('projectRole')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Project Role</span>
                                 </ng-template>
                                 <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="{{row.projectShortName}}-projectRole">{{row.projectRole}}</span> 
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Proxy" prop="proxy" [flexGrow]=".6">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div>
                                      <input
                                           type="text"
                                           [readonly]="true"
                                           placeholder=""
                                           class="noBorder">
                                   </div>
                                    <span class="datatable-header-cell-label">Proxy</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr disabled">
                                            <i *ngIf="row.approver"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.approver" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Default Project" prop="default_project" [flexGrow]=".6">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div>
                                      <input
                                           type="text"
                                           [readonly]="true"
                                           placeholder=""
                                           class="noBorder">
                                   </div>
                                    <span class="datatable-header-cell-label">Default Project</span>
                                 </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr disabled">
                                            <i *ngIf="row.defaultProject"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.defaultProject" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comment" prop="comment" [flexGrow]=".6">
                                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                    <div>
                                       <input
                                            id="projMem-filter-comment"
                                            type="text"
                                            class="filterPH"
                                            placeholder="Filter by Comment"
                                            [(ngModel)]="flags.filter.projMem.comment"
                                            (keyup)="updateFilter('comment')"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label" (click)="sort()">Comment</span>
                                 </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span class="project-membership-comment">{{row.comment}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Action" prop="action" [flexGrow]=".6">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                     <div>
                                       <input
                                            type="text"
                                            [readonly]="true"
                                            placeholder=""
                                            class="noBorder"
                                       >
                                    </div>
                                    <span class="datatable-header-cell-label">Action</span>
                                 </ng-template>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <button class="btn btn-primary btn-md mail2Admn"
                                                (click)="confirmMailToAdmin('mailToAdmin',form.userName,row)" id="submitBtn_{{row.projectShortName}}">
                                        <span>Mail to Admin</span>
                                        </button>
                                </ng-template>
                            </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>

    <!-- UNIX SHELL -->
    <div *ngIf="!flags.hideFormSection" class="col-md-6 col-lg-6 col-xl-6 mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Unix Shell</h3>
                <table class="unix-shell">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <ul class="list-inline">
                                    <li>Current Shell:</li>
                                    <li>{{form.shell}}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <ul class="list-inline form-control">
                                    <li>New Shell:</li>
                                    <li class="radio">
                                        <label><input type="radio" [(ngModel)]="form.shell" (change)="validateAccountUpdate()"
                                        name="shell"
                                        value="/bin/tcsh">tcsh</label>
                                    </li>
                                    <li class="radio disabled">
                                        <label><input type="radio" [(ngModel)]="form.shell" (change)="validateAccountUpdate()"
                                        name="shell" value="/bin/bash">bash</label>
                                    </li>
                                    <li class="radio disabled">
                                        <label> <input type="radio" [(ngModel)]="form.shell" (change)="validateAccountUpdate()"
                                        name="shell"
                                        value="/bin/zsh">zsh</label>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    NOTE: It may take as long as 20 minutes for your change of shell to propagate across all systems.
                </p>
            </div>
        </div>
    </div>

    <!-- Account ACL Bridge -->
    <div *ngIf="lists.accAclList && lists.accAclList.length" class="col-md-6 col-lg-6 col-xl-6 projects mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>ACL Tags associated with the Account: <span class="name bold">{{form.preferredName}}</span></h3>
                <div id="accAclTagsTable" class="requests-table admProjStatus otherAccTable">
                    <ngx-datatable
                        #accAclTable
                        id="accAclTable"
                        class="bootstrap card expandable"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="lists.accAclList"
                        [sorts]="[{prop: 'acltagname', dir: 'asc'}]">
                            <ngx-datatable-column name="Name" prop="acltagname" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="projects projShNameTest">
                                        <a  style="cursor: pointer"
                                            id="{{row.acltagname}}"
                                            (click)="navigateToAclPage(row.acltagname)">
                                            <font color=blue>
                                                {{row.acltagname}}
                                            </font>
                                        </a>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comment" prop="comment" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span class="project-membership-comment">
                                        {{row.comment}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Status" prop="deleted" [flexGrow]=".6">
                            </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
    <!-- FAVOR RECORDS -->
    <div *ngIf="lists.userFavorRecords && lists.userFavorRecords.length" class="col-md-12 col-lg-12 col-xl-12 projects mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Favor Records for <span
                    class="name bold">{{form.preferredName}}</span>
                </h3>
                <div class="requests-table admProjStatus">
                    <ngx-datatable
                        #admFavorRecords
                        class="bootstrap card expandable centerText"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [sorts]="[{prop: 'anl_status', dir: 'asc'}]"
                        [rows]="lists.userFavorRecords">

                        <ngx-datatable-column name="Status" prop="anl_status" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Begin Date" prop="anl_begin_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_begin_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="End Date" prop="anl_end_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_end_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Computer Access" prop="anl_computer_access" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div>
                                    <span class="disabled">
                                        <i *ngIf="row.anl_computer_access"  class="fas fa-check checkmark"></i>
                                        <i *ngIf="!row.anl_computer_access" class="fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Access Type" prop="anl_computer_access_type" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Created Date" prop="anl_created_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_created_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Host Name" prop="anl_host_name" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="User submitted date" prop="favor_submitted_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.favor_submitted_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="edit-acc-ug-sys" class="row">
    <!-- UNIX GROUPS -->
    <div id="edit-acc-unixgroup" class="col-md-12 col-lg-7 col-xl-7 account-resources mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Unix Groups</h3>
                <div class="dashboard-search resources">
                    <input  type="text"
                            class="form-control"
                            (ngModelChange)="setId($event, 'ugId', lists.allUnixGroups)"
                            [(ngModel)]="selectedUnixGroup"
                            id="selectedUnixGroup"
                            placeholder="type group name"
                            [ngbTypeahead]="searchUnixGroup"
                            size=14>
                    <button type='submit'
                            [disabled]="!form.ugId"
                            class="btn btn-primary btn-lg"
                            (click)="adminAddUnixSystem(form.ugId, 'UnixGroup');selectedUnixGroup = ''"
                            id="addGroup"
                            ngbTooltip=""
                            placement="right">
                        <span class="fas fa-plus"></span>Add Group
                    </button>
                    <warning fid="searchName"></warning>
                </div>
                <div id="UnixGrpTable" class="resources otherAccTable" *ngIf="form.accountResources && form.accountResources.UnixGroup && form.accountResources.UnixGroup.length">
                    <ngx-datatable
                        #acctUnixGroups
                        id="acctUnixGroups"
                        class="bootstrap card expandable centerText"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.accountResources.UnixGroup"
                        [sorts]="[{prop: 'resourceName', dir: 'asc'}]">
                            <ngx-datatable-column name="Name" prop="resourceName" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div id="{{row.resourceName}}" class="ugResoNameTest">
                                        {{row.resourceName}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Default" prop="default" [flexGrow]=".4">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr" [ngClass]="{'disabled': flags.disableInput}" id="{{row.resourceName}}_Default"
                                            (click)="selectDefault(row, form.accountResources.UnixGroup);ueValidate('accountResources', row, row.id)">
                                            <i *ngIf="row.default"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.default" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Membership Status" prop="membershipStatus" [flexGrow]=".8">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span [textContent]="row.status"></span>
                                    <select class="form-control memStatus"
                                        [(ngModel)]="row.memberStatus" [disabled]="flags.disableInput" id="{{row.resourceName}}_Status"
                                        (change)="ueValidate('accountResources', row, row.id)">
                                        <option value="Active">Active</option>
                                        <option value="Requested">Requested</option>
                                        <option value="Approved-pending MUA/Ack">Approved-pending MUA/Ack</option>
                                        <option *ngIf="!(row.default && row.resourceName ==='users')" value="Deleted">Deleted
                                        </option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Owner" prop="owner" [flexGrow]=".3">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span *ngIf="row.resourceName !=='users'" class="cr" [ngClass]="{'disabled': flags.disableInput}" (click)="row.owner = !row.owner; ueValidate('accountResources', row, row.id)">
                                            <i *ngIf="row.owner"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.owner" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                        <span *ngIf="row.resourceName ==='users'" class="cr" (click)="confirmAdminPrivilege(row, 'owner')">
                                            <i *ngIf="row.owner"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.owner" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comments" prop="comment" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <textarea autosize
                                                rows="1"
                                                type="text"
                                                class="form-control autosize comment"
                                                id="ugComment-{{row.resourceName}}"
                                                placeholder="Comment"
                                                [(ngModel)]="row.comment"
                                                (focus)="autosizeFn('ugComment-'+row.resourceName)"
                                                (blur)="getSmall('ugComment-'+row.resourceName)"
                                                (keyup)="ueValidate('accountResources', row, row.id)">
                                    </textarea>
                                </ng-template>
                            </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>

    <!-- SYSTEMS -->
    <div id="edit-acc-system" class="col-md-12 col-lg-5 col-xl-5 account-resources mb20">
        <!--SYSTEMS -->
        <div class="card ub3-card">
            <div class="card-body">
                <div *ngIf="flags.readError" class="alert alert-warning">
                    <a href="" (click)="flags.readError = !flags.readError" class="close">&times;</a>
                    <strong>{{flags.readError}}</strong>
                </div>
                <h3>Systems</h3>
                <div class="dashboard-search resources">
                    <input  type="text"
                            class="form-control"
                            (ngModelChange)="setId($event, 'systemId', lists.systems)"
                            type="text"
                            id="selectedSystem"
                            [(ngModel)]="selectedSystem"
                            placeholder="type group name"
                            [ngbTypeahead]="searchSystem"
                            size=14>
                    <button [disabled]="!form.systemId"
                            class="btn btn-primary btn-lg"
                            (click)="adminAddUnixSystem(form.systemId, 'System');selectedSystem = ''"
                            id="addSystem"
                            ngbTooltip=""
                            placement="right">
                        <span class="fas fa-plus"></span> Add System
                    </button>
                </div>
                <div class="resources otherAccTable" *ngIf="form.accountResources && form.accountResources.System && form.accountResources.System.length">
                    <ngx-datatable
                        #acctSysGroups
                        id="acctSysGroups"
                        class="bootstrap card expandable centerText"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.accountResources.System">
                        <ngx-datatable-column name="System Name" prop="resourceName" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div id="{{row.resourceName}}">
                                    {{row.resourceName}}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Membership Status" prop="membershipStatus" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <select class="form-control"
                                    id="sys-status-for-{{row.resourceName}}"
                                    [(ngModel)]="row.memberStatus"
                                    (change)="ueValidate('accountResources', row, row.id)">
                                    <option value="Active">Active</option>
                                    <option value="Requested">Requested</option>
                                    <option value="Deleted">Deleted</option>
                                </select>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Comments" prop="comment" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <textarea autosize rows="1" type="text"
                                    class="form-control autosize comment"
                                    id="sysComment-{{row.resourceName}}"
                                    placeholder="Comment"
                                    [(ngModel)]="row.comment"
                                    (focus)="autosizeFn('sysComment-'+row.resourceName)"
                                    (blur)="getSmall('sysComment-'+row.resourceName)"
                                    (keyup)="ueValidate('accountResources', row, row.id)">
                                </textarea>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
    <!-- <script type="text/ng-template" id="abrStateSelect.html">
        <a>
        <span>{{match.model.name | uibTypeaheadHighlight:query}}
        </span>
        </a>
    </script> -->
</div>

<br>
<div class="alert-wrap">
    <alert-msg [(alerts)]="adminAlerts"></alert-msg>
</div>
<div class="edit-user-details">
    <div class="dom-adm-edit-rec-btns">
        <ul class="list-inline">
            <li *ngIf="flags.delta">
                <button id="saveContinueEdit" [disabled]="!flags.okToSubmit || flags.reqdFieldsMissing" class="btn btn-primary btn-lg"
                        (click)="adminSaveRecord('continue')">
                    Save and Continue to Edit
                </button>
            </li>
            <li *ngIf="flags.delta">
                <button id="saveExit" [disabled]="!flags.okToSubmit || flags.reqdFieldsMissing" class="btn btn-primary btn-lg"
                        (click)="adminSaveRecord('exit')">
                    Save and Exit
                </button>
            </li>
            <li>
                <button id="exitOrCancelBtn" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('cancel')">
                    {{flags.newServiceAcct == true || flags.changedIdentity ? 'Exit' : 'Cancel'}}

                </button>
            </li>
            <li>
                <button id="ldapSync" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('LDAPSync')">LDAPSync
                </button>
            </li>
        </ul>
    </div>
</div>
