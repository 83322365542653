// Account Reactivate Module
var logsAdminModule =
    (function(){
        "use strict";
        var publicStuff;
        var logsData;
        var headerKeys =[];
        var cookies , http , httpNew , scope , timeout , win, comMod;
        cookies = http = httpNew = scope = timeout = win = comMod = null;  // cached services
        var jsServices = {};
        var svcUrl = "svc/Log";
        publicStuff = {
            setServices: setServices,
            initLogsAdmin: initLogsAdmin,
            matchReferenceId: matchReferenceId,
            matchSessionId:matchSessionId,
            documentLog: documentLog,
            fields: fields
        };

      function setServices(services){
          var i, serv;
          for (i in services){
            serv = services[i];
            jsServices[serv.sname] = serv;
          }    
          cookies = jsServices.cookies;
          http    = jsServices.http;
          httpNew = jsServices.httpNew;
          scope   = jsServices.scope;
          timeout = jsServices.timeoutObj.timeout;
          win     = jsServices.window;

          comMod = jsServices.commonModule;
      }

        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user

        function initLogsAdmin(scope, lists, flags, pageName, callback){
          scope.containerFluid = true;
          http.get(svcUrl, {params : {search : '?'}})
          .success(function(resp){
            if(resp.success){
              // We are now hiding the 'ALL' search filter in svc/logs search
                resp = comMod.removeALLsearchFilter(resp);
                lists.filterValues = {
                  searchFields : resp.data.search_info_list || []
                };
                flags.filters = [{
                  selectedSf: null,
                  filter_name: '',
                  selectedOp: null,
                  selectedVal: null,
                  presetWithValue: null,
                  active: false,
                  selectedValues: [],
                  showRemove: false
                }];
                lists.searchPath = svcUrl;
                lists.filterRows = '';
                flags.fldDelimiter = resp.data.filter_delimiter;
                flags.listDelimiter = resp.data.list_delimiter || ',';
                flags.nullTag = resp.data.null_tag;
                flags.showSearchPath = false;
                if (callback) callback();
              } else {
                lists.filterValues = {
                  searchFilterErrorMsg : resp.error
                };
                if (callback) callback();
              }
          });
          if(!scope.lists.documentLog){
            documentLog(scope.lists)
          }

          if(!scope.lists.fields){
              fields(scope.lists)
          }
        };

      var data="";
      function documentLog(lists) {
          http.get("/admin/logSearchDoc")
          .success(function(resp) {
            if(resp.success) {
              lists.documentLog = resp.doc.fields_doc;
            data = lists.documentLog;
            }
          });
           return data;
        }


    function fields(lists) {
      http.get("admin/logSearchField")
      .success(function(resp) {
        if(resp.success) {
            lists.fields = resp.fields;
        }
      });
    }
        //var referenceArr = [];

        // function referenceLogs(http, lists){
        //   // angular.forEach(logsData, function(val, key){
        //   //     referenceArr.push(val.referenceid);
        //   // });
        //   http.get("/admin/logReferenceInfo", {params: {referenceId: ''}})
        //   .success(function(resp) {
        //     if(resp.success) {
        //       lists.referenceLogs = resp.log_reference_info;
        //     }
        //   })
        // }


        //  function sessionLogs(http, lists){
        //   http.get("/admin/logSessionInfo", {params: {sessionId: ''}})
        //   .success(function(resp) {  
        //     if(resp.success){
        //       lists.sessionLogs = resp.log_session_info;
        //     }
        //   })
        // }

        function matchReferenceId(lists,index,item) {
          http.get("/admin/logReferenceInfo", {params: {referenceId: item.reference_id.info}})
          .success(function(resp) {
            if(resp.success) {
              lists.referenceLogs = resp.log_reference_info[0].reference;
            }
          })
          // angular.forEach(lists.referenceLogs, function(val, key){
          //     // if(item.log.reference_id === val.reference.reference_id){
          //     //  scope.finalData = val;       
          //     // }
          // });
        }


        function matchSessionId(lists,index,item) {
          http.get("/admin/logSessionInfo", {params: {sessionId: item.session_id.info}})
          .success(function(resp) {
            if(resp.success){
              lists.sessionLogs = resp.log_session_info;
              lists.sessionUibOpen = lists.sessionLogs.length > 0 ? true : false;
            }
          })
          // angular.forEach(lists.sessionLogs, function(val, key){
          //     // if(item.log.session_id === val.session.session_id){
          //     //  scope.finalSesData = val;       
          //     // }
          // });
        }



        // ----------- public stuff gets returned -----------
        return publicStuff;

    })();
     module.exports = {logsAdminModule}
/*    if ( (typeof module) != 'undefined'){
      // we are in angular 2+
      var c = "module.exports = {logsAdminModule}" ;
      eval(c);
  }*/
