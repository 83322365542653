
import { Component, OnInit } from '@angular/core';
import { CommonService } from '@common/services';

var t = null;

@Component({
  selector: 'docs-needed',
  templateUrl: './docs-needed.component.html',
  styleUrls: ['./docs-needed.component.scss']
})
export class DocsNeededComponent implements OnInit {

  constructor(private commonService: CommonService) {
        t = this;
        t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
  }

  domainGlobals = null;

  ngOnInit(): void {

  }

}
