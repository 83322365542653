
<div class="content-wrapper plugin-module"> 
    <div class="plugin">
        <div class="edit-user-details" [ngClass]="{'fixed-ahead ':!mobile}">
            <h3>Manage Plugins</h3>
            <div *ngIf="authAlerts.length > 0">
                <div class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index">
                    <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
                        {{ alert.msg }}
                    </ngb-alert>
                </div>
            </div>
        </div>
    </div>

    <div class="plugins-info">
        <div>
            <h4 class="domainHeading">Domain Plugins</h4>
            <button *ngIf="!lists.install" class="btn plugins-md-btn plugins-btn-primary" (click)="pluginDirInstall()"   id="install"  > Install   </button>
            <button *ngIf="lists.install"  class="btn plugins-md-btn plugins-btn-primary" (click)="pluginDirUninstall()" id="uninstall"> Uninstall </button>
        </div>
        <div class="panel-default" *ngFor = "let group of lists.plugins">
            <div class="searchName">
                <span class="form-group floating-label-wrapper">Enable</span>&nbsp;
                <input type="checkbox" id="pluginDirActivate"
                        [(ngModel)]="group.active"
                        (change)="pluginDirActivateOrDeactivate(group)">
            </div>
            <div class="activateAllPlugins">
                <span class="panel-default" (click)="pluginActivateOrDeactivate(group, active)">
                    <button class="btn btn-default btn-md" (click)="active=true;">Activate All</button>
                    <button class="btn btn-default btn-md" (click)="active=false;">Deactivate All</button>
                </span>
            </div>
            <div  id="pluginTable" class="plugins-panel">
                <a class="plugins-panel-title" (click)="lists.isPeriodicUibOpen = !lists.isPeriodicUibOpen">Periodic</a>
                <div *ngIf="lists.isPeriodicUibOpen">
                    <ngx-datatable
                        #pluginsTable
                        class="bootstrap card expandable"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="30"
                        [footerHeight]="30"
                        [scrollbarH]="true"
                        [rows]="periodics">
                        <ngx-datatable-column name="Active"   prop="active" [flexGrow]=".1">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <input type = "checkbox" [(ngModel)] = "row.active" (change)="setupPlugin(row)" id="{{row.plugin.split('.').join('_')}}">
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Plugins"  [flexGrow]=".6">
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                                <table>
                                    <tr class="col-md-12">
                                        <td class="col-md-8">{{row.plugin}}</td>
                                        <td class="col-md-2">
                                            <button class="btn plugins-sm-btn plugins-btn-primary"
                                                    [disabled] = "!(group.active && row.active)"
                                                    (click)="pluginRunPeriodic(row)" id="{{row.plugin.split('.').join('_')}}-run">
                                                Run
                                            </button>
                                        </td>
                                        <td class="col-md-2">
                                            <span><font color=red>{{row.runError}}</font></span>
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Crontab" [flexGrow]=".6">
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                                <table>
                                    <tr class="col-md-12">
                                        <td class="col-md-8" *ngIf = "!row.edit">{{row.crontab}}</td>
                                        <td class="col-md-8" *ngIf="row.edit"> <input type = "text" [(ngModel)] = "row.crontab" class="editRow"></td>
                                        <td class="col-md-2" *ngIf="!row.edit">
                                            <button class="btn plugins-sm-btn plugins-btn-primary"
                                                    (click)="row.edit=!row.edit">
                                                edit
                                            </button>
                                        </td>
                                        <td class="col-md-2" *ngIf="row.edit">
                                            <button class="btn plugins-sm-btn plugins-btn-primary"
                                                    (click)="savePlugin(row);row.edit=!row.edit">
                                                Save
                                            </button>
                                        </td>
                                        <td class="col-md-2">
                                            <span><font color=red>{{row.cronError}}</font></span>
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
            <div id="pjTable" class="plugins-panel">
                <a class="plugins-panel-title" (click)="lists.isTriggerUibOpen = !lists.isTriggerUibOpen">Trigger</a>
                <div *ngIf="lists.isTriggerUibOpen">
                    <ngx-datatable
                        #pjTable
                        class="bootstrap card expandable"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="30"
                        [footerHeight]="30"
                        [scrollbarH]="true"
                        [rows]="triggers">
                        <ngx-datatable-column name="Active"   prop="active" [flexGrow]=".2">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <input type = checkbox [(ngModel)] = "row.active" (change)="setupPlugin(row)">
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Plugins"  prop="plugin" [flexGrow]=".8"></ngx-datatable-column>
                        <ngx-datatable-column name="Trigger"  prop="trigger" [flexGrow]=".6"></ngx-datatable-column>
                        <ngx-datatable-column name="Trigger Object"  prop="trigger_obj" [flexGrow]=".6"></ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>

