import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import { manageUnixGroupsModule } from '../../../../../../../../frontend/manageUnixGroupsModule';
import { accountUpdateModule } from '../../../../../../../../frontend/accountUpdateModule';
import { manageProjectModule } from '../../../../../../../../frontend/manageProjectModule';


let t = null;
let http = null;
let cookies = null;

@Component({
  selector: 'manage-unix-groups',
  templateUrl: './manage-unix-groups.component.html',
  styleUrls: ['./manage-unix-groups.component.css']
})
export class ManageUnixGroupsComponent implements OnInit {
  lists = {
    matchedUsers: [],
    groupSearch : []
  };
  flags = {
    displayedPageName: '',
    okToSubmit : false,
    isYourGroupOpen : {},
    showAddUser : false,
    currGrpId : '',
    currGrpName: '',
    hideManageUnixGroups: false
  };
  form = {
    selectedGroup : {},
    ugRow : {}
  };
  filterStr = '';
  authAlerts = [];
  domainGlobals: any = {};
  @ViewChild('pjTable') table: any;
  expanded: any = false;
  publicPage(b) { };
  constructor(
    private commonService: CommonService,
    public h: Ub3httpService,
    public cookie: CookieService,
  ) {
    t = this;
    http = h;
    cookies = cookie;
    t.flags.displayedPageName = 'Manage UNIX Groups';
    t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    manageUnixGroupsModule.init(t, http, http, t.flags.displayedPageName, t.lists, t.flags, t.form, window);
  }


  closeAlert = function(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }
  toggleExpandRow(row, isOpen) {
    t.table.rowDetail.collapseAllRows();
    if(!isOpen) {
      t.table.rowDetail.toggleExpandRow(row);
    }
  }

  manageUnixGroupsView(row, index, expanded){
    t.filterStr = '';
    manageUnixGroupsModule.viewGroup(http, http, t.flags, t.form, t.lists, row.owner, row.unixgroup_id, row.resourceName, index, t);
    t.commonService.doWhenAPICallsDone(function(){
      t.toggleExpandRow(row, expanded);
    });
  };

  selectDefault(row, array){
    t.authAlerts = [];
      array.forEach(function(b){
          b.default = row === b;
      });
  };

  validateList(formName, obj, id) {
    t.commonService.onStopCalling(800, function(){
        accountUpdateModule.validateList(formName, obj, id, t);
    });
  };

  manageUnixGroupsEdit(selected, type, action, index){
    t.authAlerts = [];
    manageUnixGroupsModule.editGroup(http, t.form, t.flags, cookies, t.lists, selected, type, action, index, t.authAlerts, setTimeout);
  };

  matchUsers(str, allAccounts){
    t.commonService.onStopCalling(800, function(){
      t.commonService.matchUsers(str, allAccounts, t);
    });
  };
  displayMemberStatus(userObj, acctStatus){
    return t.commonService.displayMemberStatus(userObj, acctStatus);
  }

  addUser(pjName, row){
    t.authAlerts = [];
    manageProjectModule.addUser(pjName, row, http, cookies, t.flags, t.lists, t.authAlerts);
  };

  addAUserToUgrp(row, form){
    t.authAlerts = [];
    var pjName = form.ugRow.resourceName;
    var uid = form.unixgroup_id;
    manageProjectModule.checkIfProjExists(http, uid, cookies, t.authAlerts, function(resp){
      if(resp !== null){
        t.addUser(pjName, row);
        t.commonService.doWhenAPICallsDone(function(){
          document.getElementById('rowAlert').scrollIntoView();
          manageUnixGroupsModule.updateGroupView(http, form, t.flags, form.unixgroup_id);
        });
      } else {
        t.manageUnixGroupsEdit(row.userName,'addUser','edit',form.manageYourUnixGroups.indexOf(row));
      }
    });
  }

  saveUnixGroupChanges(){
    t.authAlerts = [];
    manageUnixGroupsModule.saveChanges(http, t.form, t.flags, t.lists, cookies, t.authAlerts);
  };

  manageUnixGroupsSearch(searchId){
    t.authAlerts = [];
    manageUnixGroupsModule.findGroup(http, t.flags, t.form, t.lists, searchId, t.authAlerts);
  };

  selectNewUG(selectedItem){
    t.commonService.onStopCalling(1000, function(){
      if(selectedItem.length ===0){
        t.form.selectedGroup = '';
        return;
      }
      var selectedUG = t.lists.allUnixGroups.filter(function(item){
        return item.name.toLowerCase() === selectedItem.toLowerCase();
      });
      if (selectedUG.length > 0){
        t.form.selectedGroup = selectedUG[0];
        t.authAlerts = [];
        manageUnixGroupsModule.selectNewUG(t.flags, t.form, t.lists, t.form.selectedGroup);
      }
    });
  };

  searchUG = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.allUnixGroups.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(group) {
            return (group.name);
          });
        })
      );
  }
}
