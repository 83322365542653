
// Account Request module

var accountRequestModule = 
(function(){
    var publicStuff, waitCount; 
    var cookies , http , httpNew , scope , timeout , win, comMod, valMod, arMod;
    cookies = http = httpNew = scope = timeout = win = comMod = valMod = arMod = null;  // cached services
    var jsServices = {};
    var clog = null;

    publicStuff ={ 
        addAffiliation  : addAffiliation,
        blurPrefName    : blurPrefName,
        createQA        : createQA,
        delSaveForLater : delSaveForLater,
        getForm         : getForm,
        getLists        : getLists,
        goToFAVOR       : goToFAVOR,
        init            : init,           
        limitedLogin    : limitedLogin,
        locateByZip     : locateByZip,
        onPhoneCountry  : onPhoneCountry,
        onlyOnePrimary  : onlyOnePrimary,
        requestEmailCode: requestEmailCode,
        saveForLater    : saveForLater,
        setPreferredName: setPreferredName,
        setServices     : setServices,
        submit          : submit,
        validateDelayed : validateDelayed,
        validateNow     : validateNow,  
        validateLL      : validateLL,
        validateLLNow   : validateLLNow,
        verifyEmailCode : verifyEmailCode,
        verifySecurityAnswer : verifySecurityAnswer,
        overWriteScope  : overWriteScope
    };

    /*
    function setServices(c, h, hNew, s, t, w){
        cookies = c;
        http = h;
        httpNew = hNew;
        scope = s;
        timeout = t;
        win = w;
    }
    */
    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http = jsServices.http;
        httpNew = jsServices.httpNew;
        scope = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win = jsServices.window;
        comMod = jsServices['commonModule'];
        valMod = jsServices['validationModule'];
        arMod = jsServices['accountReactivateModule'];
        clog = comMod.ub3consoleLog;
    }
    function overWriteScope(s){
        scope = s;
    }

    // http : the http service. When unit testing, it is the mock http service
    // lists: needed lists by the UI, for example: projects, institutions
    // flags: needed by the UI to know what options to show to the user
    //
    function init(backFromFavor){
        comMod.publicPage(true);
        waitCount = 0;
        waitThenInit(backFromFavor);
    }
    function waitThenInit(backFromFavor){
        if (!scope.user || comMod.isEqual(scope.user,{})){
            // not ready, wait a little bit
            waitCount ++ ;
            if (waitCount > 40) {   // 40 times 3/10 secs = 12 seconds
                comMod.ub3consoleLog('waited too long for scope.user to get set');
                return;
            }
            comMod.ub3consoleLog('waiting for scope.user to get set', scope.user);
            timeout(function(){ waitThenInit(backFromFavor);}, 300);
            return;
        }
        doTheInit(backFromFavor);
    }
    function doTheInit(backFromFavor){
        var pageName = 'Request an Account';
    
        scope.lists = {};
        scope.flags = {};
        scope.flags.moreLL = false;
        scope.flags.LLStage = 'A';  
        // A==before security code, 
        // B==asks for sec code, 
        // C==asks Sec Q&A, 
        // D==asks sec answer
        // see uirules.py, look for limitedLoginA , B, C, D
                    
        scope.form = {
            accountType : 'Annual',
            cryptoKeyOption : 'Mobile',
            email       : '',
            //workCountry : 'United States',
            affiliationList: [{name:'', primaryInstitution: false}]
        };
        scope.flags.needNewAccount = true;   
        scope.flags.q1             = 'noAccount'; 
        scope.flags.q2             = 'joining'; 
        if ((typeof angular) == 'undefined'){   // angular 2+
           scope.flags.level          = 1;
        }else{          // angular 1
           scope.flags.level          = 4;
        }
        scope.flags.okToSubmit = false;
        scope.flags.fieldErrors = {};
        scope.flags.delta = null ;
        scope.flags.prevSavedForm = null ;
        scope.flags.missing = null;
        scope.flags.reactivateAccount = false;
        scope.flags.redirectToReactivate = false;

        scope.lists.usaStates = comMod.getUsaStates();
        comMod.careerLevels(scope.lists);

        comMod.setDomainGlobals(scope);

        scope.flags.displayedPageName = pageName ;

        var u = scope.user ;
        if (u && u.signedIn && (u.role == 'limited')){
                // We are in the init function of accountRequest, meaning, user was not supposed to be signed in,
                // but scope.user says that it is. 
                // why ?
                // This is the case when the user does:  refresh, or restart, killed browser, or is coming back later,
                // or hit back from the FAVOR site
                // or user is here because the FAVOR part is complete and got redirected to here
                // continue where the user left off

                if (backFromFavor) {
                    // we know that there is a saveForLater record if favor redirected to here
                    // read the saveForLater record by calling getForm()
                    getLists(scope.lists);
                    getForm(function(){
                        comMod.ub3consoleLog('159 accReqMod.doTheInit', {form: scope.form, flags: scope.flags, lists: scope.lists});
                    });
                    return;
                }
                // when not backFromFavor, this api was called /public/loginLimitedUser, which will sign out the user
                // but will send back the correct state to continue in the limited login sequence
                scope.LLF = {
                    email       : u.email,
                    firstName   : u.firstName,
                    lastName    : u.lastName,
                };

        }

    }

    function onPhoneCountry(origin){

        var s = scope.flags.selectedCountry;
        if (s){
            if ((typeof s) != 'string'){
                scope.form.phoneCountry = s.name; 
                scope.flags.selectedCountry = s.dialCode; 
            }   // else do nothing,  it already has a code
        }else{
            scope.form.phoneCountry = null;
        }
        scope.validate();
    }

    function addAffiliation(form){
        var al,i,count,row, blank;

        blank = {name:'', primaryInstitution:false};
        if (form.affiliationList){
            // count how many empty ones. Only allow one empty
            al = form.affiliationList;
            count = 0;
            for (i in al){
                row = al[i];
                count += (!row.name || (row.name.trim() == '')) ? 1 : 0 ;
            }
            if (count == 0){
                form.affiliationList.push(blank);
            }
        }
        else{
            form.affiliationList = [blank];
        }
    }

    function getLists(lists){
       if (!lists.institutions){
                comMod.getLimitedInstitutionsList(http, lists);  // this sets up $scope.institutions
       }
       if (!lists.activeProjects){
                comMod.getLimitedProjectsList(lists);
       }
       if(!lists.countries){
                comMod.getLimitedCountries(lists);
       }
    }

    function goToFAVOR(form, flags, favorRedirectPath, callback){
        var formCopy, page = favorRedirectPath, a = null, p = null;

        a = comMod.commaDelimitedToArray(form.projectShortNameList);

        flags.readError = null;
        flags.submitError = null;
        step1(a);

        // retrieve the FAVOR sponsor id from backend
        flags.loading = 0;
        function step1(pjList){
            //a[1] = 'more stuff';
            p = {projectShortNameList: pjList};
            flags.loading ++;
            http.get("/limited/favorHostId", {params: p}).success( function(resp) {
                flags.loading --;
                if (resp.success){
                    // we have the FAVOR sponsor id
                    step2(resp.favorHostId);
                }else{
                    flags.readError = 'cannot retrieve FAVOR Sponsor Id. Server returned:' + resp.error;
                }
            });
        }

        function step2(favorHostId){

            // call saveForLater, so that usCitizen field gets saved
            // Also, Janet wants favorHost to be part of the form for Foreign Nationals

            formCopy = comMod.objCopy(form);
            // arrays are not copied correctly. 
            formCopy.affiliationList = form.affiliationList;

            formCopy.favorHost = favorHostId ;
            flags.loading ++;
            http.post('/limited/createUbRequestId')
            .success(function(hresp){

                var ubRequestId;
                flags.loading --;
                if (hresp.success) {
                    ubRequestId = hresp.code ;
                    step3(favorHostId, ubRequestId);
                }else{
                    flags.submitError = hresp.error ;
                }              
            })
            .error(function(e){
                flags.loading --;
            });  
        }

        function step3(favorHostId, ubRequestId){
            var url, iform;
            flags.loading ++;

            saveForLater(formCopy, flags, function(){
                flags.loading --;
                // for issue 1295 : this next block is for the new FAVOR feature where we can prepopulate their form with more info
                //page = 'https://apps-test.anl.gov/registration/user_bases/new';

                // this next line is for testing if the feature of accepting many fields
                // in a POST method is in FAVOR production yet
                //page =        'https://apps.anl.gov/registration/user_bases/new'

                var EXTRA_PARAMS = true; // enable this when FAVOR delivers their changes to their prod site  OR to test mock w extra params
                                  // if you enable this, then also change the file src/backend/userbase/settings.py
                                  // look for EXTRA_PARAMS

                if (EXTRA_PARAMS){
                    iform = setFavorFormObject(form);
                    iform.sponsor_id = favorHostId;
                    iform.ub_request_id = ubRequestId ;
                    if (iform.employer_name.length >= 40){
                        iform.employer_name = iform.employer_name.slice(0,39);
                    }
                    // this next line redirects the browser to another site
                    //alert('going to favor page '+page) ;

                    comMod.sendForm(page, iform);
                }else{
                    // send only 2 parameters
                    url = page + '?sponsor_id=' + favorHostId + '&ub_request_id=' + ubRequestId ;
                    //alert('going to favor url ' + url);
                    // this next line redirects the browser to another website GET method
                    flags.extUrl = url;
                    win.location.assign(url);
                }

            });
        }
    }

    function setFavorFormObject(form){
        var inst = form.affiliationList && form.affiliationList[0] ? form.affiliationList[0].name : '' ;
        if (inst.length > 40) { inst = inst.slice(0,40);}
        return {
            name_first: form.firstName,
            name_mid:   form.nmi ? 'NMI' : (form.middleName ? form.middleName : 'NMI'),
            name_last: form.lastName,
            email_address: form.email,
            alternate_email_address: form.altEmail ? form.altEmail : '',
            us_citizen: 'N',
            visit_purpose_code: 15,
            favor_created:'N',
            employer_name:  inst,
            employer_addr_ln_1: form.workStreet1,
            employer_addr_ln_2: form.workStreet2,
            employer_addr_ln_3: form.workStreet3,
            employer_addr_city: form.workCity,
            employer_addr_state: form.workState,
            employer_addr_zip: form.workZip,
            employer_addr_country: form.workCountry ? form.workCountry.toUpperCase() : '',
        };
    }

    // This function creates a session, calls whoami(by calling setUser), 
    // and also triggers the backend to send an email code to the user
    //
    // /public/loginLimitedUser     returns   a few diferent values as output
    // returns  {success: true/false,  action: <string>, securityQuestion: <string>, error: <string>}
    function limitedLogin(form, callback){
        var gresp, cform;



        scope.flags.err1 = null ;
        scope.flags.submitError = null ;
        cform = {
            email       : form.email, 
            firstName   : form.firstName, 
            lastName    : form.lastName,
            purpose     : scope.flags.reactivateAccount ? 'REACTIVATE' : 'REQUEST'
        };

        http.post("/public/loginLimitedUser", cform)
        .success(function(hresp){
                var err;
                gresp = hresp;
                if (hresp.success) {
                    if(hresp.action ==='reactivateEnterEmailCode'){
                        scope.flags.disableHref = 'Request an Account';
                    }else{
                        scope.flags.disableHref = 'Reactivate an Account';
                    }

                    comMod.setUser(scope, doMore);
                }else{
                    err = hresp.error;
                    scope.flags.err1 = hresp.error;
                    if(hresp.error === 'Your name, email address, and username is already in our Account and Project Management system. Please use Reactivate an Account instead to establish your account'){
                        scope.flags.redirectToReactivate = true;

                    }
                    scope.flags.submitError = hresp.error;
                }              
        })
        .error(function(e){
        });  

        function doMore(){
                    scope.user.action = gresp.action ;
                    scope.user.securityQuestion = gresp.securityQuestion ;
                    http.post("/limited/sendEmailCode")
                        .success(function(eresp){
                            if (eresp.success) {
                                scope.flags.askForCode = true;
                                scope.flags.codeLength = eresp.codeLength ;
                                scope.flags.emailTimeSent = new Date();
                            }else{
                                scope.flags.submitError = eresp.error;
                            }
                            callback();
                        })
                        .error(function(serr){
                            //clog('we need to show some kind of error to the user' );
                        });
        }
    }

    function validateLL(form, flags, timeout, callback){
        comMod.onStopCalling(700, function(){
            validateLLNow(form,flags);
            if (callback) callback();
        });
    }
    function validateLLNow(form, flags){

        flags.fieldErrors = {};
        if (form.userName){
            form.userName = form.userName.toLowerCase();
        }
        var p1 = 'limitedLogin'+flags.LLStage;
        valMod.validateNow(p1, form, flags.fieldErrors);

        /*  this section commented out because of issue #506, uncomment if users change their mind
        if (flags.LLStage == 'C'){
            sa  = form.securityAnswer ;
            csa = form.confirmSecurityAnswer ;
            if (sa && (!csa || (csa == '') || (csa != sa))){
                flags.fieldErrors['confirmSecurityAnswer'] = {
                    required:true, 
                    label: 'confirm security answer',
                    error: 'retype security answer correctly'
                };
            }
        }
        */

        if (flags.needNewAccount && flags.fieldErrors.userName){    // for issue 4146
           delete flags.fieldErrors['userName'];
        }

        setPreferredName(form);

        // moreLL is true only on account request
        if (!flags.moreLL){
            if (flags.fieldErrors.nmi) delete flags.fieldErrors.nmi;
            if (flags.fieldErrors.middleName) delete flags.fieldErrors.middleName;
            if (flags.fieldErrors.userName) delete flags.fieldErrors.userName;
        }
        flags.missing = valMod.computeMissing(flags.fieldErrors) ;
        if(flags.reactivateAccount && flags.missing === "Required: Desired User Name, "){
            delete flags.missing;
        }
        flags.okToSaveQA = !flags.missing;
        flags.okToSubmit = !flags.missing;
        if (flags.missing){
            flags.Aerror = flags.missing;
        }else{
            // clear out error message displayed to user
            flags.Aerror = '';
        }
    }

    function emptyAddressIfNoCountry(form){
        var prefix, country;
        for (prefix in {'work':1, 'shipping':1}){
            country = form[prefix+'Country'];
            if (!country || (country=='')){
                form[prefix+'Street1'] = '' ;
                form[prefix+'Street2'] = '' ;
                form[prefix+'Street3'] = '' ;
                form[prefix+'City'] = '' ;
                form[prefix+'State'] = '' ;
                form[prefix+'Zip'] = '' ;
            }
        }
    }

    function validateDelayed(form, flags, fieldName){
        comMod.onStopCalling(700, function(){
            validateNow(form, flags, fieldName, false);
        });
    }

    // this one can be called directly from other places
    function validateNow(form, flags, fieldName, fromPageLoad){
        var same, oldVal, newVal, emailAndNameGood ;

        flags.fieldErrors = {};
        if (!flags.missing) flags.missing = '';
        emptyAddressIfNoCountry(form);
        cpAddress(form);    // do the copy if checkbox is checked

        if (form.usCitizen){
            flags.preFavorNotice = false;
        }

        if (form.userName){
            form.userName = form.userName.toLowerCase().trim();
        }

        if ((fieldName == 'userName') || (form.userName != flags.lastKnownGoodUserName)){
            var p = {userName: form.userName};
            var apiName = flags.accountUpdate ? '/auth/accountExists' : '/limited/accountExists' ;
            http.get(apiName, {params: p}).success(function(resp){
                if (resp.success){
                    if (resp.match){
                     if (!flags.fieldErrors) flags.fieldErrors = {};
                     if (!flags.fieldErrors.userName) flags.fieldErrors.userName = {};
                     flags.fieldErrors.userName.error = 'this username is already taken';
                     if (!flags.missing) flags.missing = '' ;
                     flags.missing +=  'Desired Username: already taken' ;
                     flags.okToSubmit = false;
                    }else{
                        // good username, not taken
                        flags.lastKnownGoodUserName = form.userName ;
                    }
                }else{
                    flags.readError = resp.error;
                }
            });
        }

        setPreferredName(form);

        // force a complain when the agreement checkboxes are not checked
        if (form && (form.policyAck != true)){
            form.policyAck = null; 
        }

        if ((typeof form.nmi) == 'undefined'){
            form.nmi = false;
        }
        valMod.validate('accountRequest', form, flags.fieldErrors, null, fromPageLoad);
        
        // exception: extra check for the special field form.policyAlcfAck
        if (form.policyAlcfAck == true){
            flags.fieldErrors['policyAlcfAck'] = {
                error: null, label: 'Agree with ALCF policy', required: false
            }
        }else{
            flags.fieldErrors['policyAlcfAck'] = {
                error: null, label: 'Agree with ALCF policy', required: true
            }
        }

        // make sure that at least one institution is set as primary
        if (! form.affiliationList){
            form.affiliationList = [];
        }
        var list = form.affiliationList = comMod.uniqueInstitutions(form.affiliationList);
        var primaries = 0;
        var row;
        for (var i in list){
            row = list[i];
            row.inSFL = existsInSFL(row.name, flags.prevSavedForm);
            if (row.primaryInstitution){
                primaries ++ ;
            }
            if (row.name && (row.name.trim().length < 3)){
                flags.fieldErrors['affiliationList'] = {error: 'enter at least 3 characters', label: 'Employer List' };
            }
        }
     
        if (list && (list.length > 0) && primaries > 1){
            flags.fieldErrors['affiliationList'] = {
                error: 'set only one employer as primary', 
                label: 'Employer List', 
                required: true,
                dispRequired: true
            }
        }
        if (list && (list.length > 0) && (primaries == 0)){
            list[0].primaryInstitution = true;  // default the first one as primary
        }
        
        if (!list || (!list.length) || ((list.length == 1) && (list[0].name == '') )){ 
            flags.fieldErrors['affiliationList'] = {
                //error: 'Enter at least one employer', label: 'Employer List', 
                required: true,
                dispRequired: true,
                label: 'Employer'
            }
        }
        comMod.doWhenAPICallsDone(function(){
            flags.missing = valMod.computeMissing(flags.fieldErrors) ;
            if ((form.usCitizen == false) && (!flags.favorComplete)){
                flags.missing = commaAppend(flags.missing, 
                    'You need to enter Foreign National Documentation') ;
            }
    /*
            if (!form.policyAck || (form.policyAck == false)){
                flags.missing = commaAppend(flags.missing, 'Argonne ALCF User agreement needed') ;
            }
    */
            flags.okToSubmit = !flags.missing ;
            //alert(flags.missing);

            emailAndNameGood = (!flags.fieldErrors.firstName && 
                                !flags.fieldErrors.lastName && 
                                !flags.fieldErrors.email);

            // if we already done do not change the level
            if (!flags.accountRequestDone) {
                if (emailAndNameGood){
                    // person name is good
                    if (!form.projectShortNameList || (form.projectShortNameList == '')){
                        flags.level = 6;
                    }
                    if (form.projectShortNameList && (form.projectShortNameList != '')){
                            flags.level = 7 ;
                    }
                }else{
                    flags.level = 5;
                }
            }

            // now see if there are any changes to save for later
            flags.delta = false ;
            if (!flags.prevSavedForm) {
                flags.delta = true;
            }else{
                for (var f in form) {    // loop and compare each value in dictionaries
                    newVal = form[f];
                    oldVal = flags.prevSavedForm[f];
                    same = ((!oldVal && (newVal == '')) || (!newVal && (oldVal == '')) || (oldVal == newVal)  ) ;
                    if (!same){
                        flags.delta = true;
                    }
                }
            }
        });
    }

    // find out if this affiliation name already exists in the
    // backend in saveForLater table
    function existsInSFL(afName, prevSavedForm){
        var list, i, row;
        
        if (!prevSavedForm || !afName || (afName == '')) return false;

        list = prevSavedForm.affiliationList;
        for (i in list){
            row = list[i];
            if (row.name == afName) return true;
        }
        return false;
    }
    function commaAppend(s,newString){
        if (!s || (s == '')){
            return newString;
        }
        return s + ', ' + newString;
    }

    var prefNameVisited = false;
    function blurPrefName(form){
        prefNameVisited = true;
        setPreferredName(form);
    }
    function setPreferredName(form){
        var f = form ;
        if (!prefNameVisited) return;

        if (f.firstName)     f.firstName = f.firstName.trim();
        if (f.lastName)      f.lastName  = f.lastName.trim();
        if (f.preferredName) f.preferredName = f.preferredName.trim();
        if (!f.preferredName || (f.preferredName == '')){
            f.preferredName = 
                (f.firstName  ? f.firstName+' '  : '') +
                (f.lastName   ? f.lastName   : '');
            f.preferredName = f.preferredName.trim();
        }
    }

    function requestEmailCode(email, flags){

        var url ;

        flags.askForCodeError = null ;

        http.post("/limited/sendEmailCode", {email: email})
             .success(function(resp){
                if (resp.success){
                    // email was sent
                    flags.askForCode = true;
                    flags.askForCodeError = null ;
                    flags.codeLength = resp.codeLength;
                }else{
                    flags.askForCodeError = resp.error ;
                }
              });
    }

    function verifyEmailCode(flags, code, action, callback){
        code = code ? code.trim() : code ;
        var p = {emailCode: code};
        if (flags.codeLength != code.length){
            flags.askForCodeError = 'Please copy & paste the exact code from the email that we sent you.' ;
            if (callback) callback();
            return;
        }
        flags.confirmingCode = true;
        flags.emailCodeVerified = false;
        flags.askForCodeError = null;
        flags.readError = null;

        http.post("/limited/verifyEmailCode", p)
            .success(function(resp){
                if (resp.success){
                    flags.emailCodeVerified = true;
                    flags.askForCode = false;
                    // determine which fields are required
                    if (action=='requestEnterEmailCodeEnterQA')  flags.LLStage = 'C';
                    if (action=='requestEnterAnswer')            flags.LLStage = 'D';
                    if (action=='requestEnterEmailCode')         flags.LLStage = 'D';
                    if (action=='reactivateEnterAnswer')         flags.LLStage = 'D';
                    if (action=='reactivateEnterEmailCode')      flags.LLStage = 'D';

                    // determine if we need to challenge user for the answer
                    if ( action.endsWith('EnterAnswer') || 
                        (action=='reactivateEnterEmailCode') || 
                        (action=='requestEnterEmailCode')){

                        http.get('/limited/securityQuestion')
                            .success(function(resp2){
                                if (resp2.success){
                                    scope.user.securityQuestion = resp2.question;
                                }else{
                                    flags.readError = resp2.error;
                                }
                            });
                    }
                }else{
                    flags.askForCodeError = resp.error ;
                }
                flags.confirmingCode = false;
                if (callback){
                    callback();
                }
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "EMAIL ACCESS CODE" again.'
                    }
                    flags.submitError = msg.data.detail;
                    flags.askForCodeError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
            });
    }

    function createQA(form, callback){
        p = {securityQuestion: form.securityQuestion, securityAnswer: form.securityAnswer};
        scope.flags.saving = true;
        scope.flags.QAsaved = false;
        scope.flags.Aerror = null;
        http.post("/limited/createQuestionAnswer", p)
            .success(function(resp){
                if (resp.success){
                    scope.flags.QAsaved = true;
                    scope.flags.level = 5;
                    http.post("/limited/verifySecurityAnswer", p)
                        .success(function(r2){
                            if (r2.success){
                                getFormH();
                                if (callback){
                                    callback();
                                }
                            }else{
                                scope.flags.Aerror = r2.error;
                            }
                        });
                    
                }else{
                    scope.flags.Aerror = resp.error ;
                }
                scope.flags.saving = false;
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "Cancel account request", and try again.'
                    }
                    scope.flags.readError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
            });
    }
    function verifySecurityAnswer(answer, callback){
        var p = {
            securityQuestion: scope.user.securityQuestion, 
            securityAnswer  : answer
        };
        scope.flags.verifyingAnswer = true;
        scope.flags.answerVerified = false;
        scope.flags.wrongAnswer = null;
        http.post("/limited/verifySecurityAnswer", p)
            .success(function(resp){
                if (resp.success){
                    scope.flags.answerVerified = true;
                    scope.flags.level = 5;
                    if(scope.flags.reactivateAccount){
                        arMod.reactAnswerVerified(cookies, scope);
                    }
                    if ((scope.user.action == 'requestEnterAnswer') || (scope.user.action =='requestEnterEmailCode')){
                        // ***** redirect here to accountRequest form
                        getFormH();
                    }
                    if (callback){
                        callback();
                    }
                }else{
                    scope.flags.wrongAnswer = resp.error ;
                }
                scope.flags.verifyingAnswer = false;
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        var limitedName = !scope.flags.reactivateAccount ? 'request' : 'reactivate';
                        msg.data.detail = 'Your session has expired. Please select "Cancel account ' + limitedName + ', and try again.'
                    }
                    scope.flags.readError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
            });
    }

    function cpAddress(form){
        var postfix, src, tgt ;
        if (form.copy_address){
            for (postfix in {'Street1':1,'Street2':1, 'Street3':1, 'City':1, 'State':1,'Zip':1, 'Country':1}){
                src = 'work' + postfix;
                tgt = 'shipping' + postfix;
                if (form[src]){
                    form[tgt] = form[src] ;
                }else{
                    form[tgt] = '';
                }
            }
        }
    }
    function delSaveForLater(callback){

        http.put("/limited/delSaveForLater")
        .success(function(hresp){
                if (hresp.success) {
                }else{
                    //clog('some error message should be shown to the user');
                }              
                if (callback){
                    callback();
                }
        })
        .error(function(data){
                if (callback){
                    callback();
                }
        });  
    }

    function saveForLater(form, flags, callback){

        var modForm, email;

        email =  form.email.trim().toLowerCase();
        flags.saving = true;
        flags.submitError = null;

        modForm = comMod.objCopy(form);

        // special case for policyAlcfAck
        delete modForm.policyAlcfAck ;

        // special processing for projectShortNameList, affiliation
        if (modForm.projectShortNameList){
            modForm.projectShortNameList = comMod.commaDelimitedToArray(modForm.projectShortNameList);
        }
        var currAffList = comMod.objCopy(form.affiliationList);
        modForm.affiliationList = form.affiliationList ; // preserve the original array
        delEmptyItems(modForm.affiliationList);

        var cdata = {form: modForm};

        http.post("/limited/saveForLater", cdata)
        .success(function(hresp){
                if (hresp.success) {
                    form.affiliationList = comMod.objCopy(currAffList);
                    flags.prevSavedForm = comMod.objCopy(form);
                    validateNow(form, flags, null, false);    // so it updates the flags.delta flag
                    flags.saveForLaterSaved = true ;

                    // hack. erase message after 4 seconds
                    timeout(function(){flags.saveForLaterSaved = false;}, 4 * 1000); 

                }else{
                    flags.submitError = hresp.error;
                }              
                flags.saving = false ;
                comMod.doWhenAPICallsDone(function(){
                    if (callback){callback();}
                });
        })
        .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "Cancel account request", and try again.'
                    }
                    flags.submitError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
        });       

        // recursive function
        function delEmptyItems(ar){
            var i,af;
            for (i in ar){
                af = ar[i];
                if (!af || !af.name){
                    ar.splice(i,1);
                    delEmptyItems(ar);
                    return;
                }
            }
        }
    }

    function getFormH(){
        if (!scope.lists){
            scope.lists = {};
        }
        init(false);
        getForm();
    }
    function getForm(callback){
        var pjSelectFns = comMod.getSharedObject('select-projects', 'functions');
        var url, email, form, flags, lists ;
        email = scope.email;
        form = scope.form;
        flags = scope.flags;
        lists = scope.lists;
        flags.readError = null ;

        http.get('/limited/saveForLater')
            .success(function(sflResp){
                if (sflResp.success){
                        var f = sflResp.form ;
                        if (f.affiliation_list) {
                            f.affiliationList = f.affiliation_list;
                            delete f['affiliation_list'];
                        }
                        if (f){
                            Object.assign(form, f);     // This assignment makes sure that $scope.form finds 
                                                        // these new values in the controller.
                                                        // Object.assign() copies from right to left
                                                        // it replaces things from f to form,  but does not remove from 
                                                        // form items that do not exist in f.  
                        }
    
                        if (form.projectShortNameList){
                        //if (form.projectShortNameList && ((typeof form.projectShortNameList) == 'object')){
                            form.projectShortNameList = comMod.arrayToCommaDelimited(form.projectShortNameList);
                        }
                        if (!form.affiliationList || (form.affiliationList.length==0)){
                            var blank = {name:'', primaryInstitution:false};
                            form.affiliationList = [blank] ;
                            // so that at least one pull down menu shows up
                        }

                        if (scope.LLF){
                            var i, fn;
                            var names = ['firstName','middleName','lastName','preferredName',
                                         'email','altEmail','userName', 'nmi'];

                            for (i in names){
                                fn = names[i];
                                if (!form[fn]){
                                    form[fn] = scope.LLF[fn];
                                }
                            }
                        }
                        setPreferredName(form);
                        if (form.phoneCountry){
                            var cobj;
                            for (i in scope.lists.countries){
                                cobj = scope.lists.countries[i];
                                if (cobj.name == form.phoneCountry){
                                    scope.flags.selectedCountry = cobj.dialCode ;
                                    break;
                                }
                            }
                        }
    
                        if (form.resource){
                            var a = [];
                            if (!form) return;
                            for (var i in form.resource){
                                a.push(form.resource[i]);
                            }
                            form.resource = a ;
                        }
                        if (pjSelectFns && pjSelectFns.setSelectedProjects){
                            pjSelectFns.setSelectedProjects();
                        }
                        if (lists.activeProjects){
                            validateProjectNames(form.projectShortNameList, lists.activeProjects);
                        }
                        flags.prevSavedForm = comMod.objCopy(form);
                        flags.favorComplete = (sflResp.favorCompleteDate != null);
                        validateNow(form, flags, 'userName', true);
                        if (callback){
                            callback();
                        }
                }else{
                    flags.readError = sflResp.error ;
                }
            })
            .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        //clog('need to show error to user');
            });
    }

    function validateProjectNames(names, projects){
        // implement this later. for now pretend its ok
        return ;

        var i,p ;
        
        for (i in projects){
            p = projects[i];
            if (names == p.name){
                form.projectShortNameList = selectedItem.name;
                return;
            }
        }
        form.projectShortNameList = '' ;
    }

    function submit(form, flags, cb){

        // first of all, the user can only click Submit if okToSubmit is set to true.
        //  but lets check  here again to make sure

        if (!flags.okToSubmit) {
            comMod.ub3consoleLog('Something is out of sync');
            return;
        }

        flags.saving = true ;
        flags.missing = null ;
        flags.submitError = null ;

        http.post("/limited/accountRequest")
        .success(function(hresp){
                if (hresp.success) {
                    flags.accountRequestSuccess = hresp.success ;

                    // submitted with no errors
                    flags.accountRequestDone = true ;
                    flags.level = 1 ;
                    scope.signOutNowStay(); // sign out immediately, but stay in the 'thank you' page
                }else{
                    flags.submitError = hresp.error ;
                    flags.missing = valMod.computeMissing(hresp.error) ;
                    if (flags.missing){
                        flags.missing = 'From the server: ' + flags.missing ;
                    }
                    flags.okToSubmit = !flags.missing ;
                    flags.accountRequestSuccess =  false;
                }              
                flags.saving = false ;
                if (cb) cb();
        })
        .error(function(msg){
                flags.saving = false ;
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "Cancel account request", and try again.'
                    }
                    flags.submitError = msg.data.detail;
                }
                if (cb) cb();
        });       
    }

    function onlyOnePrimary(i, affiliationList){
        var j, newValue, primaries;
        // this is different now because the checkboxes are not HTML-form-checkboxes
        // they are fake

        // only accept primary if the name is not empty
        if (!affiliationList[i].name) return;
        if (affiliationList[i].name == '') return;

        // implement checkbox toggle here
        newValue = ! affiliationList[i].primaryInstitution;
        affiliationList[i].primaryInstitution = newValue ;

        // new fake checkbox can select if not already true
        if (newValue == true){
            // set the other ones to false
            for (j=0; j < affiliationList.length; j++){
                if (i != j){
                    affiliationList[j].primaryInstitution = false;
                }
            }
        }
        if (newValue == false){
            // if no other one is true, do not accept.  reset it to true
            primaries = 0;
            for (j=0; j < affiliationList.length; j++){
                if (affiliationList[j].primaryInstitution){
                    primaries ++ ;
                }
            }
            if (primaries == 0){
                affiliationList[i].primaryInstitution = true;
            }
        }     
    }

    function locateByZip(form, prefix){
        var z = form[prefix + 'Zip'];
        var country = form[prefix + 'Country'];
        comMod.locateByZip(country, z, function(state,city){
            form[prefix + 'State'] = state;
            form[prefix + 'City' ] = city;
        });
    }

    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = {accountRequestModule}

