
// Allocation Request module
var allocRequestModule = 
(function(){
    var prepList = ['an INCITE proposal', 'an ALCC proposal', 'Other'];
    var allocFormName = 'ddAllocationRequestSvc'; // allocation request form name from backend
    var publicStuff;
    var cookies, http, httpNew, scope, timeout, win, alerts, comMod, valMod;  // cached services
    cookies = http = httpNew = scope = timeout = win = alerts = comMod = valMod = null;  // cached services
    var userRoles = ['user', 'piproxy', 'domainAdmin', 'owner'];
    var allResourcesList = [];
    var jsServices = {};
    var TestbedAllocAmt = '1000';
    const IND_RELATED = 'ARF-INDUSTRY-RELATED';
    var clog = null;


    publicStuff = {
        name: 'allocRequestModule',
        allocReqLogin               : allocReqLogin,
        cancelAllocRequest          : cancelAllocRequest,
        convertToLocaleString       : convertToLocaleString,
        getAllocResources           : getAllocResources,
        initFeature                 : initFeature,
        setServices                 : setServices,
        setScope                    : setScope,
        submitAllocRequest          : submitAllocRequest,
        validate                    : validate,
        validateARLogin             : validateARLogin,
        validateProjName            : validateProjName,
        verifyAllocVal              : verifyAllocVal,
        verifyEmailCodeForAllocReq  : verifyEmailCodeForAllocReq,
        setReqSpfcChanges           : setReqSpfcChanges
    };

    function setServices(services){
      var i, serv;
      for (i in services){
        serv = services[i];
        jsServices[serv.sname] = serv;
      }    
      cookies = jsServices.cookies;
      http = jsServices.http;
      httpNew = jsServices.httpNew;
      scope = jsServices.scope;
      timeout = jsServices.timeoutObj.timeout;
      win = jsServices.window;
      comMod = jsServices.commonModule;
      valMod = jsServices.validationModule;
      clog = comMod.ub3consoleLog;
    }

    function setScope(s){
      scope = s;
    }

    function initFeature(pageName, callback) {
        comMod.doWhenAPICallsDone(function(){
            initFeatureH(pageName, callback); // H means Helper function
        });
    }
    function initFeatureH(pageName, callback){
        // scope.publicPage(true);
        scope.authAlerts = [];
        win.scrollTo(0,0);
        if (!scope.flags) {
          scope.flags = {};
        }
        if (typeof angular !== 'undefined') {
          scope.flags.hasSession = scope.user  &&
                                  ((scope.user.role === 'limited' && scope.flags.emailCodeVerified) ||
                                  (userRoles.indexOf(scope.user.role) >= 0));
          scope.flags.displayARForm = scope.flags.hasSession;
        }
        if(!scope.flags.hasSession) {
          initAllocReqEmailVerify();
          if(callback) callback();
          return;
        }
        scope.flags.fsOnlyAlloc = false;
        scope.flags.newProjwStorage = true;
        scope.flags.okToSubmit = false;
        scope.flags.multiResources = false;
        scope.flags.subResoLength = {};
        scope.flags.displayedPageName = pageName;
        scope.flags.showMissingData = false;
        scope.flags.missingData = '';
        scope.flags.storResoSelectedManually = false;
        if (!scope.form){
            scope.form = {};
        }
        if (!scope.form.allocRequest) {
            scope.form.allocRequest = {};
        }
        if (!scope.lists){
            scope.lists = {};
        }
        if (!scope.lists.preparation_for) {
            scope.lists.preparation_for = prepList;
        }
        if(!scope.lists.scientific_field) {
            scope.lists.scientific_field = [];
            getSciFields(scope.lists);
        }
        if(!scope.lists.institutions || scope.lists.institutions.length === 0){
            getInstitutions(scope.lists);
        }
        if(!scope.lists.allocResources){
          getResourcesList(scope.flags, scope.lists, scope.form);
        }
        checkIfDone();

        function checkIfDone(){
          comMod.doWhenAPICallsDone(function(){
            validate(scope.form, scope.flags, scope.lists);
            if(callback) callback();
          });
        }
    }

    // allResourcesList (global variable) & lists.allocResources get values from ddAllocResources.json
    function getResourcesList(flags, lists, form, callback) {
        lists.allocResources = [];
        allResourcesList = [];
        http.get('/frontend/assets/data/ddAllocResources.json')
            .success(function(resp) {
                var resList = resp.resources;
                // use only those resources that are not hidden
                var publicReso = resList && resList.filter(function(item){
                    return !item.hide;
                });
                publicReso.forEach(function(obj1){
                    if(obj1.subResources){
                        // use only those sub-resources that are not hidden
                        var subres = obj1.subResources.filter(function(item2){
                            return !item2.hide;
                        });
                        flags.multiResources = (subres.length > 1);
                        obj1.subResources = subres;
                    }
                    lists.allocResources.push(obj1);
                    allResourcesList.push(obj1);
                });
                // checking if lists.allocResources has storage type resources
                flags.resourceListHasStorageType = lists.allocResources.some(function (item) {
                  return item.type === 'storage';
                });
                lists.allocResources = remRetireReso4New(lists.allocResources, form.allocRequest.new_project);
                if(callback)  callback();
            })
            .error(function(data, status, headers, config) {
                //clog('need to report something to user');
            });
    }

    function initAllocReqEmailVerify() {
        // scope.publicPage(true);
        scope.flags.LLStage = 'A';
        scope.flags.emailCodeVerified = false;
        scope.flags.ok2Submit = false;
        var c;
        c = comMod.getLocalStorage('anl-UB3-LLF');
        if (!c || c == '') c = {};

        var u = scope.user;
        setFlags();
        if(u && (u.role == 'limited')){
            c.email = u.email;
            c.firstName = u.firstName;
            c.lastName = u.lastName;
            scope.AR = c;
        }
        if(scope.AR){
            validateARLogin(scope.AR, scope.flags);
        }
    }

    function setFlags() {
      if(!scope.user) return;
        // show email verfication form on prelogin page
        if(scope.user.role === 'public' || (scope.user.role === 'limited' && !scope.flags.emailCodeVerified)){
            scope.flags.hasSession = false;
            scope.flags.displayARForm = false;
        }
        // show allocation request form on postlogin page
        if((scope.user.role === 'limited' && scope.flags.emailCodeVerified) || userRoles.indexOf(scope.user.role) >= 0){
            scope.flags.hasSession = true;
            scope.flags.displayARForm = true;
        }
    }

    // validate the Email verification page before opening the Allocation Request Form
    function validateARLogin(form, flags, callback){
      comMod.onStopCalling(700, function(){
            flags.fieldErrors = {};
            valMod.validate('limitedLogin', form, flags.fieldErrors);
            flags.missing = valMod.computeMissing(flags.fieldErrors);
            flags.ok2Submit = !flags.missing;
            if (callback) callback();
        });
    }

    // This function creates a session, calls whoami(by calling setUser), 
    // and also triggers the backend to send an email code to the user
    //
    // /public/loginLimitedUser returns a few diferent values as output
    // returns  {success: true/false,  action: <string>, error: <string>}
    function allocReqLogin(form, callback){
        var c, cform;
        var LLState = {};
        scope.flags.submitError = null ;
        cform = {
            email       : form.email, 
            firstName   : form.firstName, 
            lastName    : form.lastName, 
            purpose     : "DDALLOC_REQUEST" 
        };
        LLState.purpose = cform.purpose;
        http.post("/public/loginLimitedUser", cform)
        .success(function(hresp){
            if (hresp.success) {
              LLState.nextAction = hresp.action;
              comMod.setUser(scope, doMore);
            } else {
              err = hresp.error;
              scope.flags.submitError = hresp.error;
            }
        })
        .error(function(e){
            //clog('nned to report something to user');
        });  

        function doMore(){

            http.post("/limited/sendEmailCode")
                .success(function(eresp){
                    if (eresp.success) {
                        scope.flags.askForCode = true;
                        scope.flags.codeLength = eresp.codeLength ;
                        scope.flags.emailTimeSent = new Date();
                        LLState.askForCode = true;
                        LLState.codeLength = eresp.codeLength;
                        if(callback) callback(LLState);
                    }else{
                        scope.flags.submitError = eresp.error;
                    }
                })
                .error(function(serr){
                    //clog('need to report something ot user');
                });
        }
    }

    function verifyEmailCodeForAllocReq(flags, code, callback){
        var p,c;
        code = code.trim();
        p = {emailCode: code};
        if (flags.codeLength != code.length){
            flags.askForCodeError = 'Please copy & paste the exact code from the email that we sent you.' ;
            return;
        }
        flags.confirmingCode = true;
        flags.emailCodeVerified = false;
        flags.askForCodeError = null;

        http.post("/limited/verifyEmailCode", p)
            .success(function(resp){
                if (resp.success){
                    flags.emailCodeVerified = true;
                    flags.askForCode = false;
                    flags.hasSession = true;
                    flags.displayARForm = true;
                    flags.confirmingCode = false;
                    var obj = comMod.getLocalStorage('anl-UB3-LLF');
                    obj.emailCodeVerified = true;
                    obj.askForCode = false;
                    comMod.setLocalStorage('anl-UB3-LLF', obj);
                    if(typeof angular === 'undefined'){ // angular 10
                      if(callback) callback();
                      return;
                    }
                    initFeature('Request an Allocation');
                } else {
                    flags.askForCodeError = resp.error ;
                    if(callback) callback();
                }
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "EMAIL ACCESS CODE" again.'
                    }
                    flags.submitError = msg.data.detail;
                    flags.askForCodeError = msg.data.detail;
                    if(callback) callback();
                }
            });
    }

    function convertToLocaleString(resName, fldName, fldType, parentName, lists, callback){
        var indx = lists.allocResources.findIndex(function(item){
          var searchName = (parentName === null) ? resName : parentName;
            return item.name === searchName;
        });
        if(indx >= 0){
            var r = lists.allocResources[indx];
            var str = r[fldName][resName];
            if(str) {
              str = str.replace(/[^.,0-9]/g, ''); // allows only digits, comma and decimal point
              str = str.split('.').length > 2 ? '' : str;
              r[fldName][resName] = str;
              str = str.replace(/[^.0-9]/g, '');
              if (isNaN(Number(str))) str = null;
              r[fldName][resName] = comMod.getLocaleString(str, fldType);
              if(callback) callback();
            } else {
              if(callback) callback();
            }
        }
    }

    function verifyAllocVal(parentName, childName, lists, callback){
        var idx = lists.allocResources.findIndex(function(item){
            return item.name === parentName;
        });
        if(idx>=0){
            var allcnAmt = lists.allocResources[idx].allocation_amount;
            if(lists.allocResources[idx].category === "testbeds") {
                allcnAmt[childName] = TestbedAllocAmt;
            }
            var reso = lists.allocResources[idx].subResources.filter(function(res){
                return res.name === childName;
            });
            if(reso && reso[0].selected === false && allcnAmt && allcnAmt[childName]){
                delete lists.allocResources[0].allocation_amount[childName];
                if(Object.keys(lists.allocResources[idx].allocation_amount).length === 0) {
                    lists.allocResources[idx].allocation_amount = {};
                }
            }
            if(callback) callback();
        }
    }

    // set default values for Eagle
    function setDefaultStorageVal(lists, flags){
       // find how many compute resources are selected
        var seleComputeSysRes = lists.allocResources.filter(function (item1) {
          return (item1.category === 'computeWStorage' && item1.selected === true);
        });
        // if compute resources are selected, Eagle should be selected automatically
        var storResoAutoSelected = seleComputeSysRes.length > 0;
        // if Crux is the only one compute resource selected, or if there are no compute resources selected, then enable the checkbox for Eagle.
        var enableStorReso =  ((seleComputeSysRes.length === 1
                              && seleComputeSysRes[0].canEditStorage)
                              || (seleComputeSysRes.length === 0));
        // If compute resources are selected, Eagle should also be selected with a default value 1TB, which we get from the json file.
        // Crux should have a 1TB storage default when its the only resource selected, but have the ability for storage checkbox to be edited (to decline the storage).
        lists.allocResources.forEach(function(item){
          if(item.type === 'storage' && item.category === 'combined'){ //Eagle
            item.selected = flags.storResoSelectedManually ? flags.storResoSelectedManually: storResoAutoSelected;
            item.disable = !enableStorReso;
            if(item.selected && !item.quota_amount[item.name]){item.quota_amount[item.name] = 1;}
          }
        });
    }
    // #2449
    function isZeroValid(amt, key, fld, form, lists){
        if(form.allocRequest && form.allocRequest.new_project === true && amt === '0' && fld === 'allocation_amount'){
            var idx = lists.allocResources.findIndex(function(elem){
                var keyName = (key.primary === false) ? key.parentResoName : key.name;
                return elem.name.toLowerCase() === keyName.toLowerCase();
            });
            if(idx !== -1) {
                delete lists.allocResources[idx][fld][key];
                return null;
            }
        }
        return amt;
    }

    // validate Allocation Request Form
    function validate(form, flags, lists){
        var i;
        var state = comMod.getSharedObject('app','state');
        if(state.httpCallsPending > 0) {
          timeout(function(){
            validate(form, flags, lists);
          }, 200);
          return;
        }
        flags.fsOnlyAlloc = false;
        flags.fieldErrors = {};
        form.allocReqArray = [];
        var selectedResources = [];
        var resourceList = lists.allocResources.filter(function(item){
            return item.selected === true;
        });
        // if no resource is selected yet (if resourceList is empty), validate common allocation request information
        if(resourceList.length === 0) {
            valMod.validate(allocFormName, form.allocRequest, flags.fieldErrors);
            flags.missing = valMod.computeMissing(flags.fieldErrors);
        } else {
            var stRes = resourceList.filter(function(item){
                return (item.type === 'storage');
            });
            flags.fsOnlyAlloc = (stRes.length === resourceList.length);
            resourceList.forEach(function(key){
              var checkedSubRes = [];
              checkedSubRes =  key.subResources && key.subResources.filter(function(res){
                  return res.selected === true;
              });
              flags.subResoLength[key.name] = checkedSubRes ? checkedSubRes.length : 0;
              if(flags.subResoLength[key.name] > 0){
                  checkedSubRes.forEach(function(item){
                      var keyRes = {
                          name: item.name,
                          allocation_amount: key.allocation_amount && key.allocation_amount[item.name],
                          quota_amount: (checkedSubRes.length > 1 &&
                                        item.primary === false &&
                                        key.category === 'computeWStorage')?
                                        '0': key.quota_amount && key.quota_amount[item.name],
                          duration: key.duration && key.duration[key.name],
                          type: key.type,
                          category: key.category,
                          parentResoName: item.parentResource,
                          primary: item.primary
                      };
                      selectedResources.push(keyRes);
                  });
              } else {
                  var keyRes = {
                      name: key.name,
                      allocation_amount: key.allocation_amount && key.allocation_amount[key.name],
                      quota_amount: key.quota_amount && key.quota_amount[key.name],
                      duration: key.duration && key.duration[key.name],
                      type: key.type,
                      category: key.category
                  }
                  selectedResources.push(keyRes);
              }
          });
          // create separate form for each resource for effective validations
          selectedResources.forEach(function(key){
              var allocationRequest = {};
              // copy all the common allocation request information to the new variable: allocationRequest
              allocationRequest = comMod.objCopy(form.allocRequest);

              // copy resource specific information to the new variable: allocationRequest
              allocationRequest.resource_name = key.name.toLowerCase();
              allocationRequest.allocation_amount = (key.type !== 'storage') ? isZeroValid(key.allocation_amount, key, 'allocation_amount', form, lists) : '0';
              allocationRequest.quota_amount = key.quota_amount ? key.quota_amount : '0';
              allocationRequest.duration = key.duration;
              allocationRequest.type = key.type;
              allocationRequest.category = key.category;
              // now validate the new form
              valMod.validate(allocFormName, allocationRequest, flags.fieldErrors);
              flags.missing = valMod.computeMissing(flags.fieldErrors);
              flags.fieldErrors['allocation_amount_'+key.name.replace('-','_')]=flags.fieldErrors.allocation_amount;
              delete flags.fieldErrors.allocation_amount;
              flags.fieldErrors['quota_amount_'+key.name.replace('-','_')]=flags.fieldErrors.quota_amount;
              delete flags.fieldErrors.quota_amount;
              flags.fieldErrors['duration_'+key.name.replace('-','_')]=flags.fieldErrors.duration;
              delete flags.fieldErrors.duration;
              // storage type resources do not have allocation amount
              if((key.type === 'storage') && (flags.missing === "Required: Other Systems, allocation_amount, application_name, ")){
                  delete flags.missing;
              }
              form.allocReqArray.push(allocationRequest);
          })
          // check if any quota amount missing for selected storage resources
          // since quota is a required field for storage resources, it should be handled separately.
          var missingQuota = false;
          var seleStorageRes = selectedResources.filter(function(res){
            return res.type === 'storage';
          });
          for (i in seleStorageRes){
            var reso = seleStorageRes[i];
              if(reso.quota_amount === '' || reso.quota_amount <= 0){
                missingQuota = true;
                break;
              }
          };
          // if it is storage type resources only selection, the fields 'application name' and 'Other Systems' won't be there
          if(flags.fsOnlyAlloc && (flags.missing === "Required: Other Systems, application_name, ")){
              delete flags.missing;
          }
        }
        flags.missingData = getMissingInfo(form, flags, missingQuota);
        flags.showMissingData = flags.missingData.length > 0;
        flags.okToSubmit = !flags.showMissingData;
    }
    function getMissingInfo(form, flags, missingQuota){
      var missingInfo = [];
      if(flags.missing){
        Object.keys(flags.fieldErrors).forEach(function(key,val){
          var fe = flags.fieldErrors[key];
          if (fe && fe.hasOwnProperty('dispRequired') && fe.dispRequired){
            missingInfo.push(' '+key);
          }
        });
      }
      if(missingQuota){
        missingInfo.push(' quota');
      }
      if(!form.allocRequest.dataPolicy){
        missingInfo.push(' data_Policy');
      }
      if(!form.allocRequest.sensitiveProjInfo){
        missingInfo.push(' sensitive_Project_Info');
      }
      if(typeof(form.allocRequest.industry_related) !== 'boolean'){
        missingInfo.push(' industry_related');
      }
      return missingInfo;
    }

    // For a new project:
      // Storage resources (Eagle and Grand) will be displayed.
      // For compute resources, the field “How much space allocation do you need on X? (quota in TB) will be hidden. Now this field is only required for storage resources (#5313)
      // If the AI testbeds is the only resource selected, then Storage resources (Eagle and Grand) will be hidden #3768, but this requirement is no longer valid as per #5313.


    // For a renewal project:
      // Storage resources (Eagle and Grand) will be hidden.
      // For compute resources, the field “How much space allocation do you need on X? (quota in TB) will be displayed. but this requirement is no longer valid as per #5313.

    // this is needed only when storage resources are available
    function modifySelection(flags, form, lists) {
      flags.newProjwStorage = (form.allocRequest.new_project === true && flags.resourceListHasStorageType);
      if(flags.newProjwStorage === false){
        // if it is a renewal project, hide storage resources
        lists.allocResources = allResourcesList.filter(function(item){
          return (item.type !== 'storage');
        });
      } else {
        // if it is a new project, we need all the resources
        lists.allocResources = allResourcesList;
        setDefaultStorageVal(lists, flags);
      }
      lists.allocResources = remRetireReso4New(lists.allocResources, form.allocRequest.new_project);
    }

    function getAllocResources(res, lists, flags, form) {
      if (res.type === 'storage'){
        flags.storResoSelectedManually = res.selected;
        if(res.selected && !res.quota_amount[res.name]){res.quota_amount[res.name] = 1;}
        if(!res.selected){res.quota_amount[res.name] = null;}
      } else {
        modifySelection(flags, form, lists);
      }
    }
    function remRetireReso4New(allocResList, reqType){
      if(reqType){
        return allocResList.filter(function(item){
          return (!item.retireSoon);
        });
      }
      return allocResList;
    }
    // get all the Scientific Fields
    function getSciFields(lists) {
        var fosUrl = (scope.user.role === 'limited') ? '/limited' : '/auth';
        fosUrl+='/fieldOfStudy';
        // Excluding the following fields of study from the form as per #2436
        var fosExcludeList = ['Training', 'Internal', 'UNKNOWN', 'Support'];
        http.get(fosUrl)
            .success(function(resp){
                if (resp.success){
                    resp.fieldOfStudy.forEach(function(fld){
                        if(fosExcludeList.indexOf(fld.name) < 0)
                            lists.scientific_field.push(fld.name);
                    });
                }
            })
            .error(function(data, status, headers, config) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                //clog('need to report something to user');
            });
    }

    // get all the institutions
    function getInstitutions(lists) {
        if(scope.user.role == 'limited') {
          comMod.getLimitedInstitutionsList(http, lists);
        } else {
          comMod.getInstitutionsList(http, lists);
        }
    }
    // Duration for Grand/Eagle will be inherited from the compute resources (greatest value if there are more than one compute resource is selected).
    function findGreatestCompVal(allcForm){
      var computeDur = [];
      allcForm.forEach(function(res){
        if(res.type === 'compute'){
          computeDur.push(res.duration);
        }
      });
      if(computeDur.length > 0){
        var sortAll = function(a, b){
          return b.localeCompare(a, 'en', { numeric: true })
        }
        var sortedComputeDur = computeDur.sort(sortAll);
        allcForm.forEach(function(res){
          if(res.category === 'combined'){  // Grand & Eagle are the resources in 'combined' category
            res.duration = sortedComputeDur[0];
          }
        });
      }
    }
    // Submit the form
    function submitAllocRequest(form, alerts, scope) {
      if(form.allocRequest.new_project === true){
        findGreatestCompVal(form.allocReqArray);
      }
      // update the user role before submitting the request (see #3130: Allocations request bug triggered by an edge case (user reported))
      comMod.setUser(scope, function(){ 
        submitAllocRequestNow(form, alerts, scope);
      });
    }
    // submit the allocation request form separately for each resource
    function submitAllocRequestNow(form, alerts, scope) {
        win.scrollTo(0,0);
        var arUrl = (scope.user.role == 'limited') ? '/limited/DDAllocationRequest' : '/auth/DDAllocationRequest';
        var submittedResources = [];
        var allocReqArrLen, arlen;
        allocReqArrLen = arlen = form.allocReqArray.length;
        form.allocReqArray.forEach(function(ar){
            var alertError = {type : 'danger'};
            var resName = '';
            var reqParams = {
                "new_project": ar.new_project,
                "preparation_for": ar.preparation_for,
                "resource_name": ar.resource_name,
                "allocation_amount": ar.allocation_amount && ar.allocation_amount.toString(),
                "quota_amount": ar.quota_amount && ar.quota_amount.toString(),
                "duration": ar.duration,
                "principal_investigator_name": ar.principal_investigator_name,
                "principal_investigator_email": ar.principal_investigator_email,
                "project_institution": ar.project_institution,
                "project_formal_title": ar.project_formal_title,
                "project_name": ar.project_name,
                "project_website": ar.project_website,
                "scientific_field": ar.scientific_field,
                "project_funding_agency": ar.project_funding_agency,
                "description": (ar.industry_related ? IND_RELATED+'\n\n'+ ar.description : ar.description)
            };
            if(ar.type !== 'storage'){
                reqParams.quota_amount =  (scope.flags.newProjwStorage &&
                                          ar.category === 'computeWStorage') ?
                                          '0' : ar.quota_amount.toString();
                reqParams.application_name = ar.application_name;
                reqParams.other_systems = ar.other_systems;
            } else {
                // as suggested by @rojas
                reqParams.allocation_amount = '0';
                reqParams.application_name = 'not applicable';
                reqParams.other_systems = 'not applicable';
            }
            var cdata = {form: reqParams};
            resName = ar.resource_name;
            http.post(arUrl, cdata)
                .success(function(resp){
                    if (resp.success) {
                        submittedResources.push(resName);
                        arlen --;
                        closeAllocRequestForm(alerts, scope);
                    } else{
                        alertError.msg = resp.error;
                        alerts.push(alertError);
                        arlen--;
                        closeAllocRequestForm(alerts, scope);
                    }
                })
                .error(function(data){
                    alertError.msg = data.error;
                    alerts.push(alertError);
                    arlen--;
                    closeAllocRequestForm(alerts, scope);
                });
        })
        function closeAllocRequestForm(alerts, scope) {
          if(arlen === 0){
            getResourcesList(scope.flags, scope.lists, scope.form, function() {
              cleanAllocRequestForm('continue', scope);
              var srl = submittedResources.length;
              if( srl > 0){
                var alertSuccess = {
                  type : 'success',
                  msg  : 'The allocation request has been submitted successfully'
                };
                if(allocReqArrLen === srl){
                  alertSuccess.msg += '.';
                } else {
                  var resNames = submittedResources.join(', ');
                  var n = resNames.lastIndexOf(',');
                  if (srl > 1 && n >= 0) {
                    resNames = resNames.substring(0, n) + ' &' + resNames.substring(n+1);
                  }
                  alertSuccess.msg += ' for '+resNames +'.';
                }
                alerts.push(alertSuccess);
              }
            });
          }
        }
    }

    function cleanAllocRequestForm(status, scope){
        scope.form.allocRequest = {};
        scope.flags.okToSubmit = false;
        scope.flags.multiResources = false;
        scope.flags.fsOnlyAlloc = false;
        scope.flags.displayARForm = false;
        scope.flags.missingData = '';
        scope.flags.showMissingData = false;
        if(status === 'continue') {
          timeout(function(){
              if(scope.user.role === 'limited') {
                comMod.signOut(null, scope, false);
              } else {
                win.location.assign("/");
              }
          }, 3000);
        }
    }

    // cancellation of allocation request can be done from pre-login and post-login pages.
    function cancelAllocRequest(scope){
        if(scope.flags.displayARForm){
          cleanAllocRequestForm('cancel', scope);
        }
        scope.authAlerts = [];
        if(scope.user.role === 'limited'){
          comMod.signOut(null, scope, false);
        } else {
          win.location.assign("/");
        }
    }
    // Project Short Name can only be made up of alphanumeric characters (A-Z, a-z, 0-9), dashes (-), and underscores (_). It can not have spaces in it.
    function validateProjName(form){
        form.allocRequest.project_name = comMod.sanitizeRule1(form.allocRequest.project_name);
    }

    function setReqSpfcChanges(form, flags, lists){
        lists.allocResources = [...allResourcesList];
        if(flags.resourceListHasStorageType) {
          modifySelection(flags, form, lists);
        }
    }

    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = {allocRequestModule}
