
<ng-container  
 *ngIf="arrayFieldErrors && arrayFieldErrors[fn] && arrayFieldErrors[fn][row] && arrayFieldErrors[fn][row][fid]" >
    <div class="warning inputwarning has-error"
        *ngIf="arrayFieldErrors[fn][row][fid].error && (arrayFieldErrors[fn][row][fid].error.length > 0)">
            <div id="err-{{fid}}">
                {{arrayFieldErrors[fn][row][fid].error}}
            </div>
    </div>
    <div class="warning inputwarning required fields has-error" 
        *ngIf="arrayFieldErrors[fn][row][fid].dispRequired" 
        id="{{fid}}-is-required">
            <span>
                <font id="req-{{fid}}" color="#cc0000">
                Required Field
                </font>
            </span>
    </div>
</ng-container>