

<div class="edit-user-details top" [ngClass]="{'fixed-ahead ':!mobile}">
    <h2>{{flags.createOtherAccount == true ? 'Create' : 'Edit'}} {{form.accountCategory}} Account</h2>
    <div class="dom-adm-edit-rec-btns">
        <ul class="list-inline">
            <li *ngIf="flags.delta">
                <button id="saveContinueEditBtn" [disabled]="!flags.okToSubmit" class="btn btn-primary btn-lg"
                        (click)="editOtherAccount(form.accountCategory,'continue')">
                    Save and Continue to Edit
                </button>
            </li>
            <li *ngIf="flags.delta">
                <button id="saveAndExitBtn" [disabled]="!flags.okToSubmit" class="btn btn-primary btn-lg"
                        (click)="editOtherAccount(form.accountCategory, 'exit')">
                    Save and Exit
                </button>
            </li>
            <li>
                <button id="exitOrCancelBtn" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('cancel')">
                    {{flags.newServiceAcct == true || flags.changedIdentity ? 'Exit' : 'Cancel'}}

                </button>
            </li>
            <li *ngIf="!flags.createOtherAccount">
                <button id="ldapSync" class="btn btn-default btn-lg"
                        (click)="adminSaveRecord('LDAPSync')">LDAPSync
                </button>
            </li>
        </ul>
    </div>
    <div class="alert-wrap">
        <alert-msg [(alerts)]="adminAlerts"></alert-msg>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-6" style="margin-left:25%;">
            <div class="confirmModal" *ngIf="openModal">
                <h3 id="modal-title">Confirm
                    {{fieldName === 'mailToAdmin'?'Mail to Admin': (privilege === true ? 'Removing Privileges' : 'Elevated Privileges')}}
                </h3>
                <div *ngIf="fieldName === 'domainAdmin'">
                    ARE YOU SURE YOU WANT TO
                    {{privilege === true?' REMOVE DOMAIN ADMIN PRIVILEGES FOR THIS USER?':' MAKE THIS USER A DOMAIN ADMIN WITH ELEVATED PRIVILEGES?'}}
                </div>
                <div *ngIf="fieldName === 'uiApiWrite' || fieldName === 'svcApiWrite'">
                    ARE YOU SURE YOU WANT TO
                    {{privilege === true?' REMOVE ':' ADD '}}
                    {{fieldName === 'uiApiWrite'?' UI Access ':' Service API read-write '}}
                    PRIVILEGES FOR THIS USER?
                </div>
                <div *ngIf="fieldName === 'owner'">
                    ARE YOU SURE YOU WANT TO
                    <span>{{privilege.owner === true?' REMOVE ':' ADD '}} </span>
                    Owner PRIVILEGES FOR THIS USER?
                </div>
                <div *ngIf="fieldName === 'mailToAdmin'">
                    ARE YOU SURE YOU WANT TO SEND MAIL TO ADMIN ?
                </div>
                <div *ngIf="fieldName !== 'owner' && fieldName !== 'mailToAdmin'">
                    <button (click)="closeModal(fieldName, false);" class="btn btn-md btn-primary confirmBtn">
                        {{privilege !== true?'No':'Yes'}}
                    </button>
                    <button (click)="closeModal(fieldName, true)" class="btn btn-md btn-default confirmBtn">
                        {{privilege === true?'No':'Yes'}}
                    </button>
                </div>
                <div *ngIf="fieldName === 'owner'">
                    <button type="button" class="btn btn-primary btn-lg confirmBtn"
                    (click)="closeOwnerModal(privilege, false)">
                    {{privilege.owner !== true?'No':'Yes'}}
                    </button>
                    <button type="button" class="btn btn-default btn-lg confirmBtn"
                    (click)="closeOwnerModal(privilege, true)">
                    {{privilege.owner === true?'No':'Yes'}}
                    </button>
                </div>
                <div *ngIf="fieldName === 'mailToAdmin'">
                    <button type="button" class="btn btn-lg btn-primary confirmBtn"
                        (click)="closeMailToAdmin(fieldName, false, userName, project)">
                        {{form.fieldName !== true?'No':'Yes'}}
                    </button>
                    <button type="button" class="btn btn-lg btn-default confirmBtn"
                        (click)="closeMailToAdmin(fieldName, true, userName, project)" >
                        {{form.fieldName === true?'No':'Yes'}}
                    </button>
                </div>
            </div>
            <div class="confirmModal" *ngIf="openSameServAccUgModal" id="sameServAccUgAlert">
                <div class="sameServAccUgAlertMsg">Unix Group with name {{servAccUgName}} already exists.</div>
                <div class="procdBtns">
                    Would you like to proceed?
                    <button id="seleBtnYes" type="button" class="btn btn-lg btn-default confirmBtn" [ngClass]="{'btn-primary': okToProceed}" (click)="sameServAccUgProceed(true)">
                        Yes 
                    </button>
                    <button id="seleBtnNo" type="button" class="btn btn-lg btn-default confirmBtn" [ngClass]="{'btn-primary': !okToProceed}" (click)="sameServAccUgProceed(false)">
                        No
                    </button>
                </div>
                <div class="note">Note: If you proceed, this service account will only be added to svcAccts unixgroup and it will be the default.</div>
            </div>
        </div>
    </div>
</div>


<div class="row">
     <!--ACCOUNT INFORMATION-->
    <div class="col-md-5 col-lg-4 col-xl-3 domain-acct-info mb20">
        <div class="card ub3-card" [ngClass]="{'createOtherAccount': flags.createOtherAccount}">
            <!-- get-column-height -->
            <div class="card-body">
                <h3>Account Information</h3>
                <table class="table-condensed table-user-details" id="otherAcc-acc-info">
                    <tbody>
                        <tr *ngIf="!flags.hideFormSection && !flags.createOtherAccount && form.activationDate !== null">
                            <td class="col-md-4 col-sm-5">
                                Creation Date
                            </td>
                            <td class="col-md-8">
                                <span class="text-primary">{{convertUTCtoCDT(form.activationDate)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                Policy Agreement
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" (click)="form.policyAck = !form.policyAck; validateAccountUpdate()">
                                        <i *ngIf="form.policyAck"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.policyAck" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection" class="citizen">
                            <td>
                                US Citizen
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" id="usCitizenBox" (click)="form.usCitizen = !form.usCitizen; validateAccountUpdate()">
                                        <i *ngIf="form.usCitizen"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.usCitizen" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <button *ngIf="!form.usCitizen" class="btn btn-default btn-lg favorSync" (click)="adminFavorSync()">FAVORSync</button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.accountCategory !== 'System'">
                            <td style="width: 150px;">
                                Domain Admin
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" (click)="confirmAdminPrivilege(form.domainAdmin, 'domainAdmin')">
                                        <i *ngIf="form.domainAdmin"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.domainAdmin" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.domainAdmin"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.domainAdmin" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.accountCategory !== 'System' && form.domainAdmin">
                            <td>
                                Service API Write
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" name="form.svcApiWrite"
                                        (click)="confirmAdminPrivilege(form.svcApiWrite, 'svcApiWrite')">
                                        <i *ngIf="form.svcApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.svcApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.svcApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.svcApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.accountCategory !== 'System' && form.domainAdmin">
                            <td>
                                UI API Write
                            </td>
                            <td>
                                <div class="cBox">
                                    <span *ngIf="form.userName !== state.user.userName" class="cr" (click)="confirmAdminPrivilege(form.uiApiWrite, 'uiApiWrite')">
                                        <i *ngIf="form.uiApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.uiApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                    <!--User can't take away their own privileges -->
                                    <span *ngIf="form.userName === state.user.userName" class="cr disabled">
                                        <i *ngIf="form.uiApiWrite"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.uiApiWrite" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Host Cert
                            </td>
                            <td>
                                <div class="cBox">
                                    <span class="cr" (click)="form.certFavorHost = !form.certFavorHost; validateAccountUpdate()">
                                        <i *ngIf="form.certFavorHost"  class="cr-icon fas fa-check checkmark"></i>
                                        <i *ngIf="!form.certFavorHost" class="cr-icon fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Exp Date
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.expirationDate593)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Status
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.status593)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                593 Warning Level
                            </td>
                            <td>
                                <span>
                                    <select id="favorWarnLevel"
                                        [(ngModel)]="form.favorWarnLevel"
                                        ngDefaultControl
                                        name="favorWarnLevel"
                                        (ngModelChange)="validateAccountUpdate()"
                                        class="form-control warnLvl">
                                        <option value={{num}} *ngFor="let num of warnLevels">{{num}}</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                Deactivation Warning Level
                            </td>
                            <td>
                                <span>
                                    <select id="deactivationWarnLevel"
                                        [(ngModel)]="form.deactivationWarnLevel"
                                        ngDefaultControl
                                        name="deactivationWarnLevel"
                                        (ngModelChange)="validateAccountUpdate()"
                                        class="form-control warnLvl">
                                        <option value={{num}} *ngFor="let num of warnLevels">{{num}}</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                ANL Person Id
                            </td>
                            <td>
                                <span>
                                    <input type="text" [disabled]="form.usCitizen == false" class="form-control"
                                        id="personId"
                                        placeholder="Person Id"
                                        [(ngModel)]="form.personId"
                                        (keyup)="validateAccountUpdate()">
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.createOtherAccount">
                            <td>
                                Account Type
                            </td>
                            <td>
                                <div class="floating-label-wrapper">
                                    <select [(ngModel)]="form.accountType" id={{form.accountType}}
                                        (change)="checkDeactDate(form)">
                                        <option value="Annual">Annual</option>
                                        <option value="Permanent">Permanent</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.createOtherAccount && form.accountCategory !=='System'">
                            <td>
                                Status
                            </td>
                            <td>
                                <div>
                                    <select id="accountStatus" *ngIf="form.status !=='Requested' || ( form.accountCategory ==='Test' || form.accountCategory ==='Service')" [(ngModel)]="form.status"
                                        (change)="validateAccountUpdate()">
                                        <option value="Requested">Requested</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Deleted">Deleted</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.createOtherAccount">
                            <td>
                                Deactivation Date
                            </td>
                            <td>
                                <div *ngIf="form.accountType === 'Annual' && form.status !=='Requested'">
                                    <ub3date
                                        id="otherAccDeactDt"
                                        [form]="form"
                                        key="deactivation_date"
                                        [idPrefix]="form.userName"
                                        (onChange)="validateAccountUpdate()"
                                    >
                                    </ub3date>
                                </div>
                                <div *ngIf="form.accountType === 'Permanent' || form.status === 'Requested'">
                                    <div class="deactDt" id="otherAccDeactDt">{{(form.status === 'Requested') ? 'None' : form.deactivation_date}}</div>
                                     <div *ngIf="form.status !== 'Requested'" id="helpText" class="helpText">
                                        Please go to other screens to change the date for this type of account
                                     </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.hideFormSection">
                            <td>
                                User Updated
                            </td>
                            <td>
                                <span class="text-primary">{{convertUTCtoCDT(form.validationDate)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="form.accountCategory ==='Service'">
                            <td> Unix Shell</td>
                            <td>
                                <select id="servUnixShell"
                                    [(ngModel)]="form.shell"
                                    ngDefaultControl
                                    name="shell"
                                    (ngModelChange)="validateAccountUpdate()"
                                    class="form-control">
                                    <option value="" [disabled]="true">Unix Shell</option>
                                    <option value={{item.name}} *ngFor="let item of lists.unixShell">{{item.name}}</option>
                                </select>
                                <warning fid="shell"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.createOtherAccount">
                            <td>Crypto Key Token</td>
                            <td>
                                <span>
                                    <select (change)="validateAccountUpdate()" [(ngModel)]="form.cryptoKeyOption"
                                        class="form-control">
                                        <option value="Physical">Physical</option>
                                        <option value="Mobile">Mobile</option>
                                    </select>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!flags.createOtherAccount">
                            <td>
                                Home Directory
                            </td>
                            <td>
                                <span>
                                    <input type="text" class="form-control"     style="width:100%"
                                        id="home_dir"
                                        placeholder="Home Directory"
                                        [(ngModel)]="form.home_dir"
                                        (ngModelChange)="validateAccountUpdate()">
                                </span>
                                <warning fid="home_dir"></warning>
                            </td>
                        </tr>
                        <tr>
                            <td class="comments" colspan="2">
                                <span>Comment</span>
                                <warning fid="comment"></warning>
                                <div class="floating-label-wrapper">
                                    <label for="comment"></label>
                                    <textarea size="40" autosize type="text" rows="2" class="form-control autosize" id="comment"
                                        placeholder="Comment"
                                        [(ngModel)]="form.comment"
                                        (focus)="autosizeFn('comment')"
                                        (blur)="getSmall('comment')"
                                        (ngModelChange)="validateAccountUpdate()"
                                        with-floating-label></textarea>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- EMAIL AND ACCOUNT NAME -->
    <div id="email-and-account-name" class="col-md-7 col-lg-8 col-xl-4 email-account mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Email and Account Name</h3>
                <table class="table-condensed table-user-details">
                    <tbody>
                        <tr>
                            <td [ngClass]="{'user-name': !flags.otherAccountAdmin, 'emailAccOthInputLbl':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                Username
                            </td>
                            <td [ngClass]="{'emailAccOthInput':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                <span *ngIf="!flags.createOtherAccount && form.status !=='Requested' && form.accountCategory !=='System'"
                                    class="text-primary bold">{{form.userName}}</span>
                                <div *ngIf="flags.createOtherAccount && form.accountCategory !== 'System'"
                                    class="floating-label-wrapper">
                                    <input type="text" class="form-control" id="userName"
                                        [(ngModel)]="form.userName"
                                        autocomplete="nope"
                                        (ngModelChange)="checkForUnixGroup(form.userName)">
                                </div>
                                <div *ngIf=" form.accountCategory === 'System' "
                                    class="floating-label-wrapper">
                                    <input *ngIf="flags.createOtherAccount" type="text" class="form-control disabled-item" id="userName"
                                        autocomplete="nope"
                                        [(ngModel)]="form.email" disabled
                                        >
                                    <input *ngIf="!flags.createOtherAccount && form.accountCategory !=='Service'" type="text"
                                        class="form-control disabled-item" id="userName"
                                        autocomplete="nope"
                                        [(ngModel)]="form.userName" disabled
                                        >
                                </div>
                                <warning *ngIf="form.accountCategory !=='System'" fid="userName"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="flags.createOtherAccount || (!flags.createOtherAccount && (form.accountCategory === 'Service' || form.accountCategory === 'Test'))">
                            <td id="prefEmail">{{flags.createOtherAccount ? 'Owner' : 'Primary'}} Email</td>
                            <td [ngClass]="{'emailAccOthInput':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                <input class="form-control service-account-email" type="text"
                                    [(ngModel)]="form.email"
                                    autocomplete="nope"
                                    placeholder=""
                                    id="email"
                                    (ngModelChange)="adminValidate('email')">
                                <warning fid="email"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="!(flags.createOtherAccount && !form.firstName && form.accountCategory !== 'System')">
                            <td id="legalFirstName" [ngClass]="{'emailAccOthInputLbl':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                Legal First/Given Name
                            </td>
                            <td [ngClass]="{'emailAccOthInput':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                <div class="floating-label-wrapper">
                                    <span *ngIf="form.accountCategory == 'Service' || form.accountCategory == 'Test'" class="" id="firstName">{{form.firstName}}</span>
                                    <input  *ngIf="form.accountCategory !== 'Service' && form.accountCategory !== 'Test'"
                                            type="text" class="form-control"
                                            id="firstName"
                                            autocomplete="nope"
                                            placeholder="First Name"
                                            [(ngModel)]="form.firstName"
                                            (keyup)="validateInput('firstName',$event,$index)"
                                            (ngModelChange)="validateAccountUpdate()">
                                </div>
                                <warning *ngIf="(form.accountCategory !=='Service' && form.accountCategory !=='Test')" fid="firstName"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="!(flags.createOtherAccount && !form.lastName && form.accountCategory !== 'System')">
                            <td id="legalLastName" [ngClass]="{'emailAccOthInputLbl':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                Legal Last/Family Name
                            </td>
                            <td [ngClass]="{'emailAccOthInput':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                <div class="floating-label-wrapper">
                                    <span *ngIf="form.accountCategory == 'Service' || form.accountCategory == 'Test'" class="" id="lastName">{{form.lastName}}</span>
                                    <input *ngIf="form.accountCategory !== 'Service' && form.accountCategory !== 'Test'" type="text" class="form-control"
                                        id="lastName"
                                        autocomplete="nope"
                                        placeholder="Last Name"
                                        [(ngModel)]="form.lastName"
                                        (ngModelChange)="validateAccountUpdate()">
                                </div>
                                <warning *ngIf="(form.accountCategory !=='Service' && form.accountCategory !=='Test')" fid="lastName"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="!(flags.createOtherAccount && !form.preferredName && form.accountCategory !== 'System')">
                            <td [ngClass]="{'emailAccOthInputLbl':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                Preferred Name
                            </td>
                            <td [ngClass]="{'emailAccOthInput':(form.accountCategory === 'Test' || form.accountCategory === 'System')}">
                                <div class="floating-label-wrapper">
                                    <span *ngIf="form.accountCategory == 'Service' || form.accountCategory == 'Test'" class="">{{form.preferredName}}</span>
                                    <input *ngIf="form.accountCategory !== 'Service' && form.accountCategory !== 'Test'"
                                        type="text"
                                        class="form-control"
                                        id="preferredName"
                                        autocomplete="nope"
                                        placeholder="Preferred Name"
                                        [(ngModel)]="form.preferredName"
                                        (ngModelChange)="validateAccountUpdate()">
                                </div>
                                <warning *ngIf="(form.accountCategory !=='Service' && form.accountCategory !=='Test')" fid="preferredName"></warning>
                            </td>
                        </tr>
                        <tr *ngIf="form.accountCategory ==='Service'">
                            <td>Service Account Email</td>
                            <td class="emailAccInput">
                                <select class="form-control"
                                        id="servAccEmail"
                                        [(ngModel)]="form.serviceEmail"
                                        ngDefaultControl
                                        name="servAccEmail"
                                        (ngModelChange)="validateAccountUpdate()">
                                    <option value="" [disabled]="true">Service Account Email</option>
                                    <option value={{item.mailing_list}} *ngFor="let item of lists.mailingList">{{item.mailing_list}}</option>
                                </select>
                                <warning fid="serviceEmail"></warning>
                            </td>
                        </tr>
                        <ng-container *ngFor="let f of form.accountEmails; let i = index; let last = last">
                            <tr *ngIf="!flags.createOtherAccount && (form.accountCategory !== 'Service' && form.accountCategory !== 'Test') && !f.method">
                                <td [ngClass]="{'emailAccOthInputLbl': form.accountCategory === 'System'}">
                                    {{f.primary == true ? 'Preferred' : 'Alternate'}} Email
                                </td>
                                <td [ngClass]="{'emailAccOthInput': form.accountCategory === 'System'}">
                                    <div class="floating-label-wrapper">
                                        <input type="text" class="form-control" id="preferredEmail"
                                            placeholder="Email"
                                            [(ngModel)]="f.email"
                                            autocomplete="nope"
                                            (ngModelChange)="validateList('accountEmails', f, f.id)">
                                    </div>
                                </td>
                                <td class="add-remove">
                                    <div class="cBox">
                                        <span class="cr" id="emailCheckbox-{{i+1}}" (click)="selectPrimaryItem(f, form.accountEmails);validateList('accountEmails', f, f.id);">
                                            <i *ngIf="f.primary"  class="cr-icon fas fa-check checkmarkBlk"></i>
                                        </span>
                                        <div>Primary</div>
                                        <div *ngIf="!f.method && f.primary !==true"
                                            title="Delete Email"
                                            class="delete-item">
                                            <button   class="fas fa-trash-alt"
                                                    id="del-alt-email"
                                                    (click)="f.status = 'Deleted';validateList('accountEmails', f, f.status);adminSaveRecord('continue')">
                                            </button>
                                            <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                        </div>
                                        <warning-a fn="accountEmails" fid="email" row={{f.id}}></warning-a>
                                    </div>
                                    <div class="addAdditional" title="Add Email"
                                        *ngIf="flags.okToSubmit && flags.delta && f.method && last">
                                        <span (click)="adminSaveRecord('continue')"
                                            class="fas fa-plus-circle">
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="2">
                                <button *ngIf="showAddOtherAcctBtn()"
                                    class="btn btn-primary btn-lg" id="addAccount" (click)="addOtherAccount(form.accountCategory)">
                                    <span>Add {{form.accountCategory}} Account</span>
                                </button>
                                <button id= "Update_{{form.accountCategory}}_Account"  *ngIf="flags.emailMatched && !flags.createOtherAccount && !flags.changedIdentity && form.accountCategory !=='System'"
                                    class="btn btn-primary btn-lg"
                                    [disabled]="!flags.okToSubmit"
                                    (click)="editOtherAccount(form.accountCategory)">
                                Update {{form.accountCategory}} Account
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <!-- PROJECT Membership -->
    <div *ngIf="!flags.createOtherAccount && form.projectList && form.projectList.length" class="col-md-12 col-lg-12 col-xl-12 projects mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Project Membership Status for <span class="name">  </span><span
                    class="name bold">{{form.preferredName}}</span>
                </h3>
                <div class="requests-table admProjStatus otherAccTable">
                    <ngx-datatable
                        #admProjStatus
                        class="bootstrap card expandable centerText"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.projectList"
                        [sorts]="[{prop: 'projectShortName', dir: 'asc'}]"
                        *ngIf="membershipStatus !== 'Rejected' && membershipStatus !== 'Deleted'">
                            <ngx-datatable-column name="Name" prop="projectShortName" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="projects">
                                        <a  style="cursor: pointer"
                                            id="{{row.projectShortName}}"
                                            (click)="goToEditProject(row.projectShortName)">
                                            <font color=blue>
                                                {{row.projectShortName}}
                                            </font>
                                        </a>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Allocation Type" prop="allocationType" [flexGrow]=".6">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Allocation End Date" prop="allocationEndDate" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="pm-enddate-{{row.projectShortName}}-{{row.allocationEndDate | date: 'yyyy-MM-dd'}}">{{convertUTCtoCDT(row.allocationEndDate)}}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Project Membership" prop="membershipStatus" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span id="{{row.membershipStatus.split(' ').join('_').split('/').join('_')}}_{{row.projectShortName}}">{{row.membershipStatus}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="PI" prop="piPreferredName" [flexGrow]=".6">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Proxies" prop="proxyPreferredName" [flexGrow]=".6">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Project Role" prop="projectRole" [flexGrow]=".6">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Proxy" prop="proxy" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr disabled">
                                            <i *ngIf="row.approver"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.approver" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Default Project" prop="default_project" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr disabled">
                                            <i *ngIf="row.defaultProject"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.defaultProject" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comment" prop="comment" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span class="project-membership-comment">
                                        {{row.comment}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>


    <!-- FAVOR RECORDS -->
    <div *ngIf="!flags.createOtherAccount && lists.userFavorRecords && lists.userFavorRecords.length" class="col-md-12 col-lg-12 col-xl-12 projects mb20">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Favor Records for <span
                    class="name bold">{{form.preferredName}}</span>
                </h3>
                <div class="requests-table admProjStatus">
                    <ngx-datatable
                        #admFavorRecords
                        class="bootstrap card expandable centerText"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [sorts]="[{prop: 'anl_status', dir: 'asc'}]"
                        [rows]="lists.userFavorRecords">

                        <ngx-datatable-column name="Status" prop="anl_status" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Begin Date" prop="anl_begin_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_begin_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="End Date" prop="anl_end_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_end_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Computer Access" prop="anl_computer_access" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div>
                                    <span class="disabled">
                                        <i *ngIf="row.anl_computer_access"  class="fas fa-check checkmark"></i>
                                        <i *ngIf="!row.anl_computer_access" class="fas fa-times removeIcon"></i>
                                    </span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Access Type" prop="anl_computer_access_type" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Created Date" prop="anl_created_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.anl_created_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Host Name" prop="anl_host_name" [flexGrow]=".6">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="User submitted date" prop="favor_submitted_date" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{convertUTCtoCDT(row.favor_submitted_date)}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="edit-acc-ug-sys" class="row">
    <!-- UNIX GROUPS -->
    <div id="edit-acc-unixgroup" class="col-md-12 col-lg-7 col-xl-7 account-resources mb20"
        *ngIf="!flags.createOtherAccount &&  (form.accountCategory !== 'System' || (form.accountCategory === 'System' && form.accountResources && form.accountResources.UnixGroup && form.accountResources.UnixGroup.length > 0))">
        <div class="card ub3-card">
            <div class="card-body">
                <h3>Unix Groups</h3>
                <div *ngIf="form.accountCategory !== 'System'" class="dashboard-search resources">
                    <input  type="text"
                            class="form-control"
                            (ngModelChange)="setId($event, 'ugId', lists.allUnixGroups)"
                            [(ngModel)]="selectedUnixGroup"
                            id="selectedUnixGroup"
                            placeholder="type group name"
                            [ngbTypeahead]="searchUnixGroup"
                            size=14>
                    <button type='submit'
                            [disabled]="!form.ugId"
                            class="btn btn-primary btn-lg"
                            (click)="adminAddUnixSystem(form.ugId, 'UnixGroup');selectedUnixGroup = ''"
                            id="addGroup"
                            uib-tooltip=""
                            tooltip-placement="right">
                        <span class="fas fa-plus"></span>Add Group
                    </button>
                    <warning fid="searchName"></warning>
                </div>
                <div class="resources otherAccTable" *ngIf="form.accountResources && form.accountResources.UnixGroup && form.accountResources.UnixGroup.length">
                    <ngx-datatable
                        #acctUnixGroups
                        id="acctUnixGroups"
                        class="bootstrap card expandable centerText"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.accountResources.UnixGroup"
                        [sorts]="[{prop: 'resourceName', dir: 'asc'}]">
                            <ngx-datatable-column name="Name" prop="resourceName" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div id="{{row.resourceName}}">
                                        {{row.resourceName}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Default" prop="default" [flexGrow]=".4">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span class="cr" [ngClass]="{'disabled': flags.disableInput}" id="{{row.resourceName}}_Default"
                                            (click)="selectDefault(row, form.accountResources.UnixGroup);validateList('accountResources', row, row.id)">
                                            <i *ngIf="row.default"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.default" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Membership Status" prop="membershipStatus" [flexGrow]=".8">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <span [textContent]="row.status"></span>
                                    <select class="form-control memStatus"
                                        [(ngModel)]="row.memberStatus" [disabled]="flags.disableInput" id="{{row.resourceName}}_Status"
                                        (change)="validateList('accountResources', row, row.id)">
                                        <option value="Active">Active</option>
                                        <option value="Requested">Requested</option>
                                        <option value="Approved-pending MUA/Ack">Approved-pending MUA/Ack</option>
                                        <option *ngIf="!(row.default && row.resourceName ==='users')" value="Deleted">Deleted
                                        </option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Owner" prop="owner" [flexGrow]=".3">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div class="cBox">
                                        <span *ngIf="row.resourceName !=='users'" class="cr" [ngClass]="{'disabled': flags.disableInput}" (click)="row.owner = !row.owner; validateList('accountResources', row, row.id)">
                                            <i *ngIf="row.owner"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.owner" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                        <span *ngIf="row.resourceName ==='users'" class="cr" (click)="confirmAdminPrivilege(row, 'owner')">
                                            <i *ngIf="row.owner"  class="cr-icon fas fa-check checkmark"></i>
                                            <i *ngIf="!row.owner" class="cr-icon fas fa-times removeIcon"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comments" prop="comment" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <textarea autosize
                                                rows="1"
                                                type="text"
                                                class="form-control autosize comment"
                                                id="ugComment-{{row.resourceName}}"
                                                placeholder="Comment"
                                                [(ngModel)]="row.comment"
                                                (focus)="autosizeFn('ugComment-'+row.resourceName)"
                                                (blur)="getSmall('ugComment-'+row.resourceName)"
                                                (keyup)="validateList('accountResources', row, row.id)">
                                    </textarea>
                                </ng-template>
                            </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>

    <!-- SYSTEMS -->
    <div id="edit-acc-system" class="col-md-12 col-lg-5 col-xl-5 account-resources mb20"
        *ngIf="!flags.createOtherAccount &&  (form.accountCategory !== 'System' || (form.accountCategory === 'System' && form.accountResources && form.accountResources.System && form.accountResources.System.length > 0))">
        <!--SYSTEMS -->
        <div class="card ub3-card">
            <div class="card-body">
                <div *ngIf="flags.readError" class="alert alert-warning">
                    <a href="" (click)="flags.readError = !flags.readError" class="close">&times;</a>
                    <strong>{{flags.readError}}</strong>
                </div>
                <h3>Systems</h3>
                <div class="dashboard-search resources" *ngIf="form.accountCategory !== 'System'">
                    <input  type="text"
                            class="form-control"
                            (ngModelChange)="setId($event, 'systemId', lists.systems)"
                            type="text"
                            id="selectedSystem"
                            [(ngModel)]="selectedSystem"
                            placeholder="type group name"
                            [ngbTypeahead]="searchSystem"
                            size=14>
                    <button [disabled]="!form.systemId"
                            class="btn btn-primary btn-lg"
                            (click)="adminAddUnixSystem(form.systemId, 'System');selectedSystem = ''"
                            id="addSystem"
                            uib-tooltip=""
                            tooltip-placement="right">
                        <span class="fas fa-plus"></span> Add System
                    </button>
                </div>
                <div class="resources otherAccTable" *ngIf="form.accountResources && form.accountResources.System && form.accountResources.System.length">
                    <ngx-datatable
                        #acctSysGroups
                        id="acctSysGroups"
                        class="bootstrap card expandable centerText"
                        [limit]="20"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="form.accountResources.System">
                        <ngx-datatable-column name="System Name" prop="resourceName" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div id="{{row.resourceName}}">
                                    {{row.resourceName}}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Membership Status" prop="membershipStatus" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <select class="form-control"
                                    id="sys-status-for-{{row.resourceName}}"
                                    [(ngModel)]="row.memberStatus"
                                    (change)="validateList('accountResources', row, row.id)">
                                    <option value="Active">Active</option>
                                    <option value="Requested">Requested</option>
                                    <option value="Deleted">Deleted</option>
                                </select>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Comments" prop="comment" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <textarea autosize rows="1" type="text"
                                    class="form-control autosize comment"
                                    id="sysComment-{{row.resourceName}}"
                                    placeholder="Comment"
                                    [(ngModel)]="row.comment"
                                    (focus)="autosizeFn('sysComment-'+row.resourceName)"
                                    (blur)="getSmall('sysComment-'+row.resourceName)"
                                    (keyup)="validateList('accountResources', row, row.id)">
                                </textarea>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
    <!-- <script type="text/ng-template" id="abrStateSelect.html">
        <a>
        <span>{{match.model.name | uibTypeaheadHighlight:query}}
        </span>
        </a>
    </script> -->
</div>

<br>
<div class="alert-wrap">
    <alert-msg [(alerts)]="adminAlerts"></alert-msg>
</div>