// THIS MODULE USED BY BOTH AUTH AND ADMIN

var manageUnixGroupsModule =
    (function(){
            "use strict";
            var publicStuff;
            var alertError = {type : 'danger'};
            var alertSuccess = {type : 'success'};
            var cookies, httpLegacy, http, scope, timeout, win, form, lists, flags, alerts;     // cached services
            var jsServices = {};
            cookies = httpLegacy = http = scope = timeout = win = form = lists = flags = alerts = null;  // cached services
            var comMod;
            var userIsOwner;
            publicStuff = {
                init : init,
                editGroup : editGroup,
                saveChanges : saveChanges,
                getAllUnixGroups : getAllUnixGroups,
                updateGroupIndex : updateGroupIndex,
                setServices : setServices,
                unixGroupAllMembers : unixGroupAllMembers,
                refreshViewGroup : refreshViewGroup,
                refreshActiveGroupList : refreshActiveGroupList

            };

            function setServices(services){
                var i, serv;
                for (i in services){
                  serv = services[i];
                  jsServices[serv.sname] = serv;
                }    
                cookies = jsServices.cookies;
                httpLegacy    = jsServices.http;
                http    = jsServices.httpNew;
                scope   = jsServices.scope;
                timeout = jsServices.timeoutObj.timeout;
                win     = jsServices.window;
        
                comMod = jsServices.commonModule;
            }

            function init(s, pageName){
                scope = s;
                scope.flags.prevSavedForm = {};
                scope.authAlerts = [];
                scope.lists.matchedUsers = [];

                comMod.getSetContext(scope, pageName, function(){
                    if(!scope.flags){
                        scope.flags = {
                            pageName : pageName,
                            hideManageUnixGroups : false,
                            hideHomePage : true,
                            adminPageLevel : 4,
                            level : 4,
                            fieldErrors : {},
                            delta : false,
                            submit : null,
                            index : null,
                            missing : null,
                            ug_testing : false
                        };
                    }
                    if(!scope.form){
                        scope.form = {};
                    }
                    if(!scope.lists){
                        scope.lists = {};
                    }
                    getAllUnixGroups(http, scope.lists, scope.authAlerts);
                });
                initScopeNames();
            }



            function initScopeNames(){
                form = scope.form, flags = scope.flags, lists = scope.lists, alerts = scope.authAlerts;

            }

          // validate removed, not used here

            function refreshViewGroup(alerts, role, cb){
                if(!form.unixgroup_id){return}
                if(role === 'owner'){role = 'auth'}
                http.get(role + '/unixGroupAllMembers', {params : {id : form.unixgroup_id}})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            resp.users.forEach(function(dict){
                                if(!dict.id){dict.inheritedMember = true;dict.memberStatus = 'Inherited from Project:'}
                            });
                            scope.form.users = resp.users;
                            scope.form.public = resp.public;
                            scope.flags.prevSavedForm = JSON.parse(JSON.stringify(scope.form));
                            scope.form.requestingMembership = resp.users.filter(function(o1){
                                   return o1.memberStatus === 'Requested'
                            });
                            scope.flags.numCurrentMembers = scope.form.users.length - scope.form.requestingMembership.length;
                            userIsOwner = false;
                            // check to see if user is an owner
                            scope.form.users.forEach(function(item, index){
                                // user explorer only not admins
                                if(scope.form.users[index].owner){
                                    if(scope.form.users[index].userName === scope.user.userName){
                                        userIsOwner = true;
                                        scope.flags.userIsOwner = true;
                                    }
                                }
                     // defunct
                            });
                            scope.flags.groupOwner = userIsOwner;
                            if(cb){cb(response, userIsOwner)}

                        }
                        else {
                            if(cb){cb(response, userIsOwner)}
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                   updateGroupIndex(form);
                });
            }

            function saveChanges(role, dataChange, callback){
                initScopeNames();
                flags.okToSubmit = false;
                flags.delta = false;
                if(role === 'cancel'){
                    return
                } else{
                    alerts = scope.authAlerts = [];
                    if(dataChange.users && dataChange.users.length){
                        if(alerts.length){alerts =[]}
                        var usersArray = dataChange.users.filter(Boolean);
                        usersArray.sort(function(a, b) {
                            return ((a.owner < b.owner) ? 1 : -1);
                        });
                        var count = 0;
                        usersArray.forEach(function(item, index){
                            // sending one dict per api call,
                            // api can't handle deleting and adding an owner in same request
                             setTimeout(() => {
                            if(usersArray[index].memberStatus !== 'deleted'){
                                if(usersArray.length){

                                    http.put(role + '/manageUnixGroups', {form : item})
                                        .then(function(response){
                                            var resp = response.data;
                                            if(resp.success){
                                                alertSuccess.msg = 'Successfully Updated Owner in group ';
                                                alerts.push(alertSuccess);
                                                if(callback){callback(alerts[0])}
                                            } else{
                                                alertError.msg = resp.error;
                                                alerts.push(alertError);
                                                if(callback){callback(alerts[0])}
                                            }
                                        }).catch(function(response, status){
                                        alertError.msg = response.data.detail;
                                        alerts.push(alertError);
                                        if(callback){callback(alerts[0])}
                                    }).finally(function(){
                                        count += 1;
                                        // refresh user list after loop finishes
                                        if (count == usersArray.length) {
                                            refreshViewGroup(alerts, role);
                                        }
                                    });
                                }

                            } else{
                                 form.users.forEach(function(itm, userListIndex){
                                    if(form.users[userListIndex].id === usersArray[index].id){
                                        ///call api removeMemberUnixGroup
                                        editGroup(usersArray[index].id, 'deleteMember', 'edit', userListIndex, role, function(resp){
                                            callback(resp);
                                        });
                                    }
                                });
                            }
                            }, index * 500);
                        });
                    }

                }
            }

            // this only used for auth
            // main Unix Groups Page - show all your unix groups in right side / branch view
            function refreshActiveGroupList(alerts){
                flags.okToSubmit = false;
                http.get('auth/manageUnixGroups')
                    .then(function(response){
                        var resp = response.data;
                        // leave this until confirm odd behavior is gone.

                        /*    if(response.data){
                                resp = response.data;
                            } else{
                                resp = response;
                            }*/
                        if(resp.success){
                           scope.form.manageUnixGroups = resp.manageUnixGroups;
                            comMod.objCopyGuts(form, flags.prevSavedForm);
                            updateGroupIndex(form);

                        } else{
                            alerts.push({
                                type : 'danger', msg : resp.error
                            });
                        }
                    }).catch(function(response, status){
                    flags.hideManageUnixGroups = true;
                    alertError.msg = response.data;
                    alerts.push(alertError);
                });
            }

            function updateGroupIndex(form){
                // useful for fast indexing
                // message current membership status on user search always
                form.unixGroupIndex = {};
                scope.flags.unixGroupIndex = {};
                for(var i in form.users){
                    var member = form.users[i];
                    scope.flags.unixGroupIndex[member.userName] = member;
                }
            }

            function unixGroupAllMembers(id, callback){
                http.get('auth/unixGroupAllMembers', {params : {id : id}})
                    .then(function(resp){
                        callback(resp.data);
                    }).catch(function(response, status){});
            }

            function editGroup(selected, type, action, index, role, cb){
                initScopeNames();
                if(!form){form = scope.form}
                    var object = {}, apiToCall = "manageUnixGroups", method = 'PUT',
                    msg = '', alerts = scope.authAlerts = [], requestNotSent;
                // identify api type by explorerType
                if(role === 'user'){role = 'owner'}
                else if(role === 'project' || role === 'resource'){role = 'admin'}

                if(type === 'statusActive'){
                    object.memberStatus = 'Active';
                    object.id = selected;
                    msg = 'changed membership to Active for ' + form.users[index].preferredName;
                } else if(type === 'remove'){
                    object.memberStatus = 'Deleted';
                    object.id = selected;
                    msg = 'Removed From ' + scope.form.manageUnixGroups[index].resourceName + ' Group';

                } else if(type === 'deleteMember'){
                    apiToCall = "removeMemberUnixGroup";
                    object.memberStatus = 'Deleted';
                    object.id = selected;
                    msg = 'Removed ' + form.users[index].preferredName + ' from Group';

                } else if(type === 'approve'){
                    if(role === 'owner'){
                        apiToCall = "ownerAccountUnixGroup";
                        object.memberStatus = 'Approved';
                    } else{
                        object.memberStatus = 'Active';
                    }
                    object.id = selected;
                    msg = 'Approved ' + form.users[index].preferredName + ' to Group';

                } else if(type === 'reject'){
                    if(role === 'owner'){apiToCall = "ownerAccountUnixGroup"}
                    object.memberStatus = 'Rejected';
                    object.id = selected;
                    msg = 'Rejected ' + form.users[index].preferredName + ' from Group';

                } else if(type === 'addUser'){
                    object.unixgroup_id = scope.form.unixgroup_id;
                    object.userName = selected;
                    method = 'POST';
                    msg = 'Added User to Group';
                    apiToCall = 'addMemberUnixGroup';
                } else if(type === 'requestMembership'){
                    object.unixgroup_id = selected;
                    object.account_id = flags.account_id;
                    if(form.requiresApproval){msg = 'Membership has been requested'}
                    else{msg = 'Membership has been added'}
                    method = 'POST';
                    apiToCall = 'requestUnixGroup';
                    flags.searchCompleted = false;
                    lists.groupSearch = [];
                    delete form.selectedGroupId;
                    document.getElementById("selectUnixGroup").value = '';
                }
                // NOW PUT OR POST ?
                var cdata = {form : object};
                if(method === 'PUT'){
                    http.put(role + '/' + apiToCall, cdata)
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                alertSuccess.msg = 'Successfully ' + msg;
                                alerts.push(alertSuccess);
                                if(cb){ cb(alerts[0])}
                                //delay for plugins to finish
                                // fixme steve check if this is still needed.
                                if(type === 'deleteMember'){
                                    // next api will error unless you remove
                                        form.users.splice(index, 1);
                                }

                                if(type !== 'remove'){
                                    //  timeout(function(){
                                    refreshViewGroup(alerts, role);
                                    // }, 100);
                                } else{
                                    refreshActiveGroupList(alerts);
                                    scope.form.manageUnixGroups.splice(index, 1);
                                }


                            } else{
                                alertError.msg = response.data.error;
                                alerts.push(alertError);
                                if(cb){ cb(alerts[0])}
                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                        if(cb){ cb(alerts[0])}
                    });
                } else if(method === 'POST'){
                    http.post(role + '/' + apiToCall, cdata)
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                if(resp.joinUnixGroup){msg = resp.joinUnixGroup}
                                alertSuccess.msg = msg;
                                alerts.push(alertSuccess);
                                if(cb){ cb(alerts[0])}
                                refreshViewGroup(alerts, role);
                                if(type === 'addUser'){
                                    flags.view.filterUser = null;
                                }

                            } else{
                                alertError.msg = response.data.error;
                                requestNotSent = true;
                                alerts.push(alertError);
                                if(cb){ cb(alerts[0])}

                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                        if(cb){ return alerts}
                    }).finally(function(){
                      //  if(!requestNotSent && type === 'requestMembership'){userExplorerModule.addUnixGrpReqToTree('unixReq', selected);}

                    });
                }
            }

            function getAllUnixGroups(http, lists, alerts){
                http.get("auth/publicUnixGroupsNoProject")
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            lists.allUnixGroups = resp.publicUnixGroupsNoProject;

                        } else{
                            alerts.push({
                                type : 'danger',
                                msg : resp.error
                            });
                        }

                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                });
            }


            return publicStuff;
        }
    )();
module.exports = {manageUnixGroupsModule}

