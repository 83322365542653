// Everything in here should be only related to editing form object on a user account
// Functions included determine what apis and methods to call based on changes to form and sends appropriate data to apis as required.
// Anything else like updating lists should not be included in here..

var editRecordModule =
    (function(){

        var publicStuff, info;
        var cookies , http , httpLegacy , scope , timeout , win, GCC;
        cookies = http = httpLegacy = scope = timeout = win = GCC = null;  // cached services
        var jsServices = {};
        var valMod = null;
        var comMod = null;
        var domMod = null;
        var sco = null;     // a pointer to angular 1 $scope,  a pointer to angular 2+ local variable scope inside a module

        info = null;
        publicStuff = {
            name: 'editRecordModule',
            init : init,
            addItem : addItem,
            getRecord : getRecord,
            overWriteScope: overWriteScope,
            putRecord : putRecord,
            putAccount : putAccount,
            postRecord : postRecord,
            apisToCall : apisToCall,
            getFormChanges : getFormChanges,
            setServices  : setServices,
            validateAccountUpdate : validateAccountUpdate
        };
        function setServices(services){
            var i, serv;
            for (i in services){
              serv = services[i];
              jsServices[serv.sname] = serv;
            }
            cookies = jsServices.cookies;
            http    = jsServices.httpNew;
            httpLegacy = jsServices.http;
            sco     = jsServices.scope;
            timeout = jsServices.timeoutObj.timeout;
            win     = jsServices.window;
            GCC     = jsServices.GC;
            comMod  = jsServices.commonModule;
            valMod  = jsServices.validationModule;
            if (GCC.domain == 'alcf') domMod = jsServices.domainAdminModuleAlcf;
            if (GCC.domain == 'cels') domMod = jsServices.domainAdminModuleCels;
        }
        function overWriteScope(s){
            sco = s;
        }

        function init(){
            if(!sco.lists){
                sco.lists = {};
            }

        }

        function getFormChanges(a, b){
            // compare preSavedForm (original) "b" with current form object "a"
            // Creates new object of all keys and values in current form that changed.
            // Includes 'id' for PUT method OR 'primary' , 'label' for POST
            var diffObj = {};
            if(Array.isArray(a)){
                a.forEach(function(elem, index){
                    if(!Array.isArray(diffObj)){
                        diffObj = [];
                    }
                    diffObj[index] = getFormChanges(elem, (b || [])[index]);
                });
            } else if(a != null && typeof a == 'object'){
                Object.keys(a).forEach(function(key, i){
                    if(Array.isArray(a[key])){
                        if(JSON.stringify(b) === '{}' || !b ) { return}
                        var arr = getFormChanges(a[key], b[key]);
                        if(!Array.isArray(arr)){
                            arr = [];
                        }
                        arr.forEach(function(elem, index){
                            if(!Array.isArray(diffObj[key])){
                                diffObj[key] = [];
                            }
                            diffObj[key][index] = elem;
                        });
                    } else if(typeof a[key] === 'object'){
                        // these are empty strings
                       if(JSON.stringify(b) === '{}' || !b ) { return}
                        diffObj[key] = getFormChanges(a[key], b[key]);
                    } else if(a[key] != (b || {})[key]){

                        // Of the item that changed, check to see if it has id
                        // if id, send as PUT, Else we need send primary or label key in POST
                        for(var item in b){
                            if(item === 'id'){
                                diffObj.id = b.id;
                            } else if(item === 'method'){
                                diffObj.method = b.method;
                            } else if(item === 'primary'){
                                diffObj.primary = b.primary;
                            } else if(item === 'label'){
                                diffObj.label = b.label;
                            }
                        }

                        // now we add name/value of item that changed
                        diffObj[key] = a[key];

                    } else if(a[key] == (b || {})[key]){
                        // if they equal remove from diff
                        delete a[key];
                    }
                });
            }
            Object.keys(diffObj).forEach(function(key){
                if(typeof diffObj[key] == 'object' && JSON.stringify(diffObj[key]) == '{}'){
                    delete diffObj[key];
                }
            });
            return diffObj;
        }

        function checkForEmptySections(name, arr, isCels){
            // cels doesn't need this
            if(!arr || isCels){return}
            var id = arr.length + 1;
            var found = arr.some(function(el){
                return el.label === name;
            });
            if(!found){
                arr.push({id : id, label : name, method : 'post'});

                if(name === 'Work'){
                    arr.reverse();
                }
            }

        }

        function getRecord(http, flags, lists, name, callback){
            var newForm = {};
          // scs removed Date 7/29, not used
            var promises = flags.getApiNames.map(function(apiName){
                var a1 = flags.apiType + apiName ;
                var a2 = {params : {userName : name}};
                if (name){
                    return http.get(a1, a2);
                }else{
                    return http.get(a1);
                }
            });

            return Promise.all(promises)
                .then(function(data){
                    data.forEach(function(response, index){
                        var dataArray = response.data;
                        if(dataArray){
                            var baseObject = {'method' : 'post', 'status' : 'Active', 'id' : -1};
                            //key=success/error/apioutput
                            Object.keys(dataArray).forEach(function(key){
                                if(key === 'account'){
                                    newForm = Object.assign({}, dataArray.account);
                                    if(newForm && Object.keys(newForm).length){
                                        if(newForm.accountCategory === 'Service' ||
                                            newForm.accountCategory === 'Test' ||
                                            newForm.accountCategory === 'System'
                                        ){
                                            flags.hideFormSection = true;
                                        } else {
                                            flags.hideFormSection = false;
                                        }

                                        if(newForm.comment === null){
                                            newForm.comment = '';
                                        }
                                        if(newForm.middleName === null){
                                            newForm.middleName = '';
                                        }
                                    }
                                } else if(key === 'accountEmails'){
                                    if(dataArray[key].length === 0) {
                                        // if there is no email add empty object so they may add one.
                                       baseObject.primary = true;
                                        baseObject.valid = false;
                                        dataArray[key].push(baseObject);
                                    }
                                    newForm [key] = dataArray[key];
                                } else if(key === 'accountInstitutions'){
                                    if(dataArray[key].length === 0) {
                                        baseObject.primaryInstitution = true;
                                        dataArray[key].push(baseObject);
                                        flags.addInstitutions = true;
                                    } else if(dataArray[key].length === 1){
                                        dataArray[key][0].primaryInstitution = true;
                                    }
                                    newForm [key] = dataArray[key];
                                } else if(key === 'accountQuestions'){
                                    if(dataArray[key].length === 0) {
                                        dataArray[key].push(baseObject);
                                    }
                                    newForm [key] = dataArray[key];
                                } else {
                                    newForm [key] = dataArray[key];
                                }

                            });
                        }
                    });
                    checkForEmptySections('Work', newForm.accountAddresses, flags.cels);
                    checkForEmptySections('Shipping', newForm.accountAddresses, flags.cels);
                    callback(newForm);

                }).catch(function(data){
                    lists.errorEditRecord = data.detail;
                }).finally(function(){
                });
        }

        function putRecord(http, form, flags, lists, cookies, callback){
            // Anything with an ID that CHANGED
            // And these are all LISTs! [ ]

            // Sending back List with dict(s) id and field name that changed
            flags.putsToCall.map(function(apiName){
                // remove any nulls from array
                if(flags.putsToSendBack[apiName]){var arrayToSend = flags.putsToSendBack[apiName].filter(Boolean);}
                if(apiName == 'UnixGroup'){
                    // if dict has name/value  - default : false  - make sure it is last in array index order.
                    for(var x in arrayToSend) arrayToSend[x].default === false ? arrayToSend.push(arrayToSend.splice(x, 1)[0]) : 0;
                }
                var objectToSend = {form : arrayToSend};
                if(apiName == 'UnixGroup' || apiName == 'System' || apiName == 'NetGroup'){
                    flags.apiType = 'admin/';
                    apiName = 'accountResources';
                }
                if(apiName == 'accountSshPublicKeys'){
                    if(flags.domainAdmin || flags.otherAccountAdmin){
                        // only needed admin NOT auth
                        objectToSend.userName = form.userName;
                    }
                }
                if(apiName == 'accountProjects'){
                    // exists cels only
                    apiName = 'accountProjects'; /* jrj 3669 */
                    flags.apiType = 'admin/'
                }
                var path = flags.apiType + apiName;
                if (path == 'admin/accountPhones'  || path == 'auth/phones'  || path == 'limited/phones'){
                    objectToSend.form.forEach(function(item){
                        delete item.selectedCountry;
                    })
                }
                //return http.put(path, objectToSend);
                comMod.doWhenAPICallsDone(function(){
                    http.put(path, objectToSend)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            callback(resp);
                        } else{
                            // there is an error, display message on page
                            flags.error = resp.error;
                            callback(resp);
                        }
                    }).catch(function(data){
                        lists.errorEditRecord = data.detail;
    
                    }).finally(function(){
                        // Note: Finally doesn't return the response, .then does
                    });
                });
            });

        }

        function putAccount(http, form, flags, lists, cookies, callback){
            // this is not LIST, single dict from account object

            var url    = flags.apiType + "account";
            var params = {form : flags.account};
            var paramsCopy = comMod.objCopy(params);
            if(url === 'admin/account'){
                delete paramsCopy.form.serviceEmail;
                if(paramsCopy.form.email)        delete paramsCopy.form.email;
            }
            http.put(url, paramsCopy)
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        callback(response.data);
                    } else{
                        // there is an error, we will display message on page to user
                        callback(resp);
                        flags.error = resp.error;
                    }

                }).catch(function(data){
                    lists.errorEditRecord = data.detail;

                });
        }

        function postRecord(http, form, flags, lists, cookies, callback){
            var url;
            var promise = flags.postsToCall.map(function(apiName){
                // remove any nulls
                var array = flags.postsToSendBack[apiName].filter(Boolean);
                array.forEach(function(oneDict){

                    comMod.doWhenAPICallsDone(function(oneDictP){
                        //We are calling api for each object in the array
                        var paramsToSend = comMod.objCopy({form : oneDictP});
                        if(flags.domainAdmin || flags.otherAccountAdmin){
                            paramsToSend.userName = form.userName;
                        }
                        var url = flags.apiType + apiName;
                        if (url == 'admin/accountPhones' || url == 'auth/phones' || url == 'limited/phones'){
                            delete paramsToSend.form['selectedCountry'];
                        }

                        http
                        .post(url, paramsToSend)
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                callback(resp);

                            } else{
                                // If there is an error, display message on page sent in callback
                                callback(resp);
                                flags.error = resp.error;
                            }
                        })
                        .catch(function(data){
                            lists.errorEditRecord = data.detail;
                        });
                    }, oneDict);
                });

            });
        }

        function apisToCall(flags, form){
            flags.error = null;
            flags.finalSavedForm = {};
            comMod.objCopyGuts(form, flags.finalSavedForm);
            var formDataThatChanged = getFormChanges(flags.finalSavedForm, flags.prevSavedForm);
            flags.putsToCall = [];
            flags.postsToCall = [];
            flags.putsToSendBack = [];
            flags.postsToSendBack = {};
            var postKeyNames = [];
            for(var key in formDataThatChanged){
                if(Array.isArray(formDataThatChanged[key])){
                    // var postArrayData = [], putKeyNames = [],postKeyNames = [];
                    var postArrayData = [], putKeyNames = [];
                    // if user account update / change key names
                    if(flags.accountUpdate){
                        // if we use same api name for /auth/ and /admin/ we won't need to do this
                        if(key === "accountAddresses"){
                            formDataThatChanged.addresses = formDataThatChanged.accountAddresses;
                            delete formDataThatChanged.accountAddresses;
                            key = "addresses";

                        } else if(key === "accountQuestions"){
                            formDataThatChanged.questions = formDataThatChanged.accountQuestions;
                            delete formDataThatChanged.accountQuestions;
                            key = "questions";
                        } else if(key === "accountEmails"){
                            formDataThatChanged.emails = formDataThatChanged.accountEmails;
                            delete formDataThatChanged.accountEmails;
                            key = "emails";
                        } else if(key === "accountPhones"){
                            formDataThatChanged.phones = formDataThatChanged.accountPhones;
                            delete formDataThatChanged.accountPhones;
                            key = "phones";
                        } else if(key === "accountInstitutions"){
                            formDataThatChanged.institutions = formDataThatChanged.accountInstitutions;
                            delete formDataThatChanged.accountInstitutions;
                            key = "institutions";
                        }
                    }
                    for(var item in formDataThatChanged[key]){
                        var dict = formDataThatChanged[key][item];
                        if(dict.method === 'post'){
                            //means POST key dict so delete this flag name
                            delete dict.method;
                            // fake id only used for validation, kill it
                            if(key ==="projectAllocations"){dict.system_id = dict.id}
                            if(key ==="projectInstitutions" && !dict.affiliation){return}
                            delete dict.id;
                            // we don't want affiliation name, only institution ID
                            //delete dict.affiliation;
                            // This means institution was added that is NOT from Active List
                            /*   if(dict.institutionId === false){
                                   dict.status = 'Pending';
                                   delete dict.institutionId;
                               }*/
                            postKeyNames.push(key);
                            if(key === 'accountInstitutions' && form.accountInstitutions.length === 1){
                                formDataThatChanged[key][item].primaryInstitution = true;
                            }
                            postArrayData.push(formDataThatChanged[key][item]);
                            flags.postsToSendBack[key] = postArrayData;
                            delete formDataThatChanged[key][item];

                        } else{
                            if(dict.label){
                                // this is used on post, kill it on put
                                delete dict.label;
                            }
                            if(key !== 'affiliationList' && key !== 'allocations'){
                                flags.putsToCall.push(key);
                            }
                        }
                    }

                    flags.putsToCall = flags.putsToCall.filter(function(item, i, ar){
                        return ar.indexOf(item) === i;
                    });

                    flags.postsToCall = postKeyNames.filter(function(item, i, ar){
                        return ar.indexOf(item) === i;
                    });

                    flags.putsToSendBack = formDataThatChanged;
                } else{
                    // otherwise handling accountResources api like this...
                    for(var i in formDataThatChanged[key]){
                        //var dict = formDataThatChanged[key];
                        if(i === 'UnixGroup' || i === 'System' || i === 'NetGroup' || i === 'SshPublicKey'){
                            flags.putsToCall.push(i);
                            flags.putsToSendBack[i] = formDataThatChanged[key][i];
                        }
                    }
                    delete formDataThatChanged[key];
                }
            }
        }

        function validateAccountUpdate(form, flags, fromPageLoad, fldName){
            var formName = '';
            flags.fieldErrors = {};
            if(form.userName){
                form.userName = form.userName.toLowerCase();
            }
            flags.submit = false;
            flags.account = {};
            flags.delta = false;
            flags.okToSubmit = false;
            if(flags.accountUpdate){
                formName = 'accountUpdate';
            } else if(flags.domainAdmin || flags.otherAccountAdmin || (flags.reactivateType == 'survey')){
                formName = 'accountInfo';
                if(flags.otherAccountAdmin && !form.email && form.accountCategory !== 'User'){
                    adminValidate('email');
                }
            }
            // there are single object, rows = false
            valMod.validate(formName, form, flags.fieldErrors, false, fromPageLoad, fldName, flags.prevSavedForm);
            comMod.doWhenAPICallsDone(function(){
                if (sco && sco.isReactivateSurvey && (!form.comment)){
                    // make country a required field
                    flags.fieldErrors.comment.error = 'Country is required'
                    flags.fieldErrors.comment.required = true;
                }
                //special case for Domain Admin & middle name
                var fe = flags.fieldErrors;
                if ((flags.domainAdmin || (flags.otherAccountAdmin && form.accountCategory !== 'User')) && fe.middleName && fe.middleName.error == 'Middle name is required'){
                    fe.middleName.error = null;
                }
                if(form.accountCategory !== 'Service' && fe.serviceEmail && fe.serviceEmail.dispRequired === true){
                    delete fe.serviceEmail;
                }
                if(flags.otherAccountAdmin && (form.accountCategory === 'Service' || form.accountCategory === 'Test')) {
                    if(!form.userName){
                        fe.userName = {};
                        fe.userName.dispRequired =  true;
                    }
                }
                if(!flags.createOtherAccount && form.accountCategory === 'User' && flags.domainAdmin) {
                    if(fe && fe.orcid && fe.orcid.error){
                        if(form.orcid === flags.prevSavedForm.orcid){
                            delete fe.orcid.error;
                        }
                    }
                }
                if(flags.prevSavedForm){
                    for(var key in flags.prevSavedForm){
                        if(!comMod.isEqual(flags.prevSavedForm[key], form[key]) && !Array.isArray(form[key])){
                            flags.account[key] = form[key];
                            flags.account.id = form.id;
                        }
                    }
                }
                //temporary hack for Required: UB3 Request ID, Status, question list,
                if(flags.account.id){
                    flags.delta = true;
                    // user made change to form so hide has-error
                    flags.scrollError = false;
                    flags.missing = valMod.computeMissing(flags.fieldErrors);
                    // Temporary Hack for account update until we fix in UI rules
                    if(flags.accountUpdate){
                        if(flags.missing == "Required: UB3 Request ID, Status, question list, "){
                            delete flags.missing;
                        }
                    }
                    flags.okToSubmit = !flags.missing;
                }
            });
        }
        function adminValidate(email) {
            sco.adminAlerts = [];
            domMod.validate(http, timeout, sco.form, email, sco.flags, sco.adminAlerts, sco);
        };

        function addItem(form, flags, type){
            var baseObject = {'method' : 'post', 'status' : 'Active', 'id' : -1};
            if(type === 'Institutions'){
                if(!form.accountInstitutions){form.accountInstitutions = []}
                flags.addInstitutions = true;
                baseObject.primaryInstitution = false;
                baseObject.id = (form.accountInstitutions.length + 1) / -1; //using array length +1 to accomodate accounts with no institution initially
                form.accountInstitutions.push(baseObject);
                comMod.validateListsAfterGet(flags, form);
            } else if(type === 'Emails'){
                baseObject.primary = false;
                baseObject.id = form.accountEmails.length / -1;
                form.accountEmails.push(baseObject);

            } else if(type === 'Questions'){
                if(flags.domainAdmin && form.accountQuestions.length === 1){
                    flags.hideDeleteQA = true;
                }
                baseObject.id = form.accountQuestions.length / -1;
                form.accountQuestions.push(baseObject);
                comMod.validateListsAfterGet(flags, form);
            } else if(type === 'Phones'){
                baseObject.phoneCountry = '';
                baseObject.id = form.accountPhones.length / -1;
                baseObject.primary = !form.accountPhones.length;
                form.accountPhones.push(baseObject);

            } else if(type === 'sshPublicKeys'){
                if(!form.accountSshPublicKeys){form.accountSshPublicKeys = []}
                baseObject.id = form.accountSshPublicKeys.length / -1;
                delete baseObject.status;
                form.accountSshPublicKeys.push(baseObject);

            } else if(type === 'projectInstitutions'){
                if(!form.projectInstitutions){form.projectInstitutions = []}
                baseObject.primaryInstitution = false;
                baseObject.id = (form.projectInstitutions.length + 1) / -1; //using array length +1 to accomodate projects with no institution initially
                form.projectInstitutions.push(baseObject);
            }
        }

        // ----------- public stuff gets returned -----------
        return publicStuff;

    })();

module.exports = {editRecordModule}

