import { Component, OnInit, ViewChild } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import {validationModule} from 'validationModule';
import { domainAdminModule as domainAdminModuleAlcf } from '../../../../../../../../frontend/domainAdminModule';
import { domainAdminModule as domainAdminModuleCels } from '../../../../../../../../frontend/cels/js/domainAdminModule';


import {logsAdminModule} from '../../../../../../../../frontend/logsAdminModule';
import {multiFilterModule} from '../../../../../../../../frontend/multiFilterModule';
import {svcApiModule} from 'svcApiModule';

let scope: any = {
  lists: {
    filterRows:{},
    filterValues: {},
    isuibOpen: false,
    sessionLogs: {},
    sessionUibOpen: false
  },
  flags: {
    dupWarnMsg: null,
    filters: {},
    filterRowNumber: {},
    getSearchHelp: false,
    limitNumber: 0,
    matchedName: null,
    searchCompleted: false,
    showEmptyResults: false,
    showSearchResults:false
  },
  form: {},
};
let t = null;   // pointer to this(instantiated object of class LogsComponent)
let http = null;
let cookies = null;
let win = null;
let pageName = 'Logs';
let path = "svc/Log";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  lists= {
    filterRows:{
      length: undefined
    },
    filterValues: {
      searchFilterErrorMsg: ''
    },
    isuibOpen: false,
    sessionLogs: {},
    sessionUibOpen: false,
    searchPath: ''
  };
  flags= {
    dupWarnMsg: null,
    filters: {},
    filterRowNumber: {},
    getSearchHelp: false,
    limitNumber: 0,
    matchedName: null,
    searchCompleted: false,
    showEmptyResults: false,
    showSearchResults:false
  };
  form = {};
  resp: any;
  rows2display: any;
  @ViewChild('logsTable') table: any;
  expanded: any = {};
  constructor(
    public cookie: CookieService,
    public h: Ub3httpService,
    public window: Window,
    public commonService: CommonService
  ) {
    t = this;
    http = h;
    win = window;
    cookies = cookie;
    scope.flags.displayedPageName = pageName;
    t.state = commonService.getSharedObject('app','state');
    if (!scope.lists.filterValues) scope.lists.filterValues = {};
    if (!scope.lists.searchPath) scope.lists.searchPath = path;
    if (!scope.flags.filters) scope.flags.filters = {};
    if (!scope.lists.searchValues) scope.lists.searchValues = {};
    if (!scope.flags.toggleFilterData) scope.flags.toggleFilterData = false;

    scope.flags.initialRowLimit = 100;
    validationModule.init(t.commonService.formNames());
    logsAdminModule.initLogsAdmin(scope, scope.lists, scope.flags, pageName, function(){
      t.lists = scope.lists;
      t.flags = scope.flags;
      t.form  = scope.form;
    });
  }

  ngOnInit(): void {
    document.body.classList.remove('public-page');
    t.lists.searchPath = path;
    t.rows2display = t.flags.initialRowLimit;
    t.flags.download = false;
    t.lists.filterValues.searchFilterErrorMsg = null;
    setTimeout(function(){
      t.flags.showEmptyResults = false;
      t.flags.getSearchHelp = false;
      t.flags.dupWarnMsg = null;
      t.form.searchBoxValue = '';
      t.flags.showAddToSearch = false;
    },500);
    multiFilterModule.initMultiFilter(t, t.lists, t.flags, t.form, null, null, null, null);
  }
  initMultiFilter() {
  }
  adminValidate(name) {
    scope.adminAlerts = [];
    if(t.state.cels){
      domainAdminModuleCels.validate(http, setTimeout, t.form, name, t.flags, scope.adminAlerts, t);
    }else{
      domainAdminModuleAlcf.validate(http, setTimeout, t.form, name, t.flags, scope.adminAlerts, t);
    }
  }
  submitFilters(event) {
    if (event.keyCode === 13) {
        t.searchResults();
    }
  }
  checkSearchFields(filterlist, filter, val, id, filtervalues) {
    if(id === "searchField" && filtervalues){
      var idx = filtervalues.searchFields.findIndex(function(item){
          return item.filter_name === val;
      });
      if(idx >= 0){
        filter.selectedSf = filtervalues.searchFields[idx];
        val = filter.selectedSf;
      }
    }
    multiFilterModule.checkSearchFields(filterlist, filter, val, id, t.lists, t.flags, t.form);
  }
  filterHelp = function(path) {
    t.flags.getSearchHelp = !t.flags.getSearchHelp;
    t.flags.showEmptyResults = false;
    svcApiModule.getHelp(t.flags, true, path);
  }
  addMore(filterlist){
    multiFilterModule.addMore(filterlist, t.flags);
  }
  removeFilter(index, filterlist) {
    multiFilterModule.removeFilter(index, t.form, t.lists, t.flags, filterlist);
    t.lists.filterRows = [];
  }
  searchResults() {
    t.rows2display = t.flags.initialRowLimit;
    multiFilterModule.searchResults(t.lists, t.flags, function(){
      if(t.lists.filterRows.length < t.flags.initialRowLimit){
        t.rows2display = t.lists.filterRows.length;
      }
      t.flags.toggleFilterData = false;
      t.flags.dupWarnMsg = null;
    });
  }
  clearSearch = function(filterlist) {
    multiFilterModule.clearSearch(t.flags, t.form, t.lists, filterlist);
  }
  downloadSearchResults = function(filterrows, fileType) {
    multiFilterModule.downloadSearchResults(t.lists, t.flags, filterrows, fileType);
  }
  toggleAction = function(){
    t.flags.toggleFilterData = !t.flags.toggleFilterData;
    if(t.flags.toggleFilterData){
      t.table.rowDetail.expandAllRows();
    } else {
      t.table.rowDetail.collapseAllRows();
    }
  }


  toggleExpandRow(row, isOpen) {
    t.table.rowDetail.collapseAllRows();
    if(!isOpen) {
      t.table.rowDetail.toggleExpandRow(row);
    }
  }
  closeDuplModal(filterlist){
    multiFilterModule.closeDuplModal(t.flags, t.form, t.lists, filterlist);
  }
}
