

<div class="manage-resources" >
    <div class="reversed-white">
       <h1>{{flags.displayedPageName}}</h1>
    </div>
    <div class="ub3-card">
       <div class="card-body">
          <div class="alert alert-danger" *ngIf="lists.systemFailure">{{lists.systemFailure}}</div>
          <div *ngIf="flags.systemError" class="alert alert-info" dismiss-on-timeout="5000"><b>{{flags.systemError}}</b></div>
          <div *ngIf="lists.allSystems">
             <table id="pjTable" class="table update-account">
                <thead class="bg1">
                   <th colspan="12">
                      <h4 class="table-heading">Available Resources</h4>
                   </th>
                </thead>
                <tbody>
                   <tr *ngIf="listHasActive"  class="whiteBg">
                      <td colspan="12">
                         <ng-container *ngFor="let active of lists.allSystems" >
                            <span 
                                *ngIf="active.memberStatus == 'Active'" 
                                id="{{active.name}}">
                               {{active.name}}{{!$last ? ',' : ''}}
                            </span>
                         </ng-container>
                      </td>
                   </tr>
                </tbody>
             </table>
          </div>
          <div *ngIf="!flags.isYourGroupOpen">
            <ng-container *ngFor="let alert of authAlerts">
                <font *ngIf="alert.type == 'success'" color=green>
                    {{alert.msg}}
                </font>
                <font *ngIf="alert.type == 'danger'" color=red>
                    {{alert.msg}}
                </font>
                <br>
            </ng-container>
          </div>
       </div>
    </div>
 </div>
 
