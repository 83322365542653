
import { Injectable } from '@angular/core';
import { commonModule } from 'commonModule';
import { validationModule } from 'validationModule';
import { allocRequestModule } from 'allocRequestModule';
import { accountRequestModule } from 'accountRequestModule';
import { accReactModule } from 'accReactModule';
import { accountReactModule } from 'accountReactModule';
import { accountReactivateModule } from 'accountReactivateModule';
import { accountUpdateModule } from 'accountUpdateModule';
import { domainAdminModule as domainAdminModuleAlcf } from 'domainAdminModule';
import { domainAdminModule as domainAdminModuleCels } from '../../../../frontend/cels/js/domainAdminModule';
import { institutionModule } from 'institutionModule';
import { manageProjectModule } from 'manageProjectModule';
import { multiFilterModule } from 'multiFilterModule';
import { joinProjectModule } from '../../../../frontend/joinProjectModule';
import { logsAdminModule } from 'logsAdminModule';
import { otherAccountsModule } from 'otherAccountsModule';
import { pluginsAdminModule } from 'pluginsAdminModule';
import { projectProxyModule } from 'projectProxyModule';
import { projectAdminModule } from 'projectAdminModule';
import { manageResourcesModule } from 'manageResourcesModule';
import { manageUnixGroupsModule as manageUnixGroupsModuleAlcf} from '../../../../frontend/manageUnixGroupsModule';
import { manageUnixGroupsModule } from 'manageUnixGroupsModule';
import { svcApiModule } from 'svcApiModule';
import { apiDevIntModule } from 'apiDevIntModule';
import { Ub3httpService } from './ub3http.service';
import { CookieService} from 'ngx-cookie-service';
import { editRecordModule} from 'editRecordModule';
import * as dayjs from 'dayjs';
import * as utc from "dayjs/plugin/utc";
import * as tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

var scope = {httpCallsPending: 0}; // commonModule makes heavy usage of this
var t = null ; // points to the instantiated object of CommonService class

@Injectable({
    providedIn: 'root'
})

export class CommonService {

  constructor( private http:Ub3httpService, private cookies: CookieService)
  {
      t = this;

  }
  name = 'commonModule';

  ngOnInit(): void {
        var jsModules = [];
        t.state = t.getSharedObject('app','state');
        scope = t.state;

        if(!scope['cels']){
            a('allocRequestModule', allocRequestModule);
            a('accountRequestModule', accountRequestModule);
            a('accountReactivateModule', accountReactivateModule);
            a('accReactModule', accReactModule);
            a('accountReactModule', accountReactModule);
            a('accountUpdateModule', accountUpdateModule);
            a('domainAdminModuleAlcf', domainAdminModuleAlcf);
            a('institutionModule', institutionModule);
            a('joinProjectModule', joinProjectModule );
            a('manageProjectModule', manageProjectModule);
            a('manageResourcesModule', manageResourcesModule);
            a('manageUnixGroupsModule', manageUnixGroupsModuleAlcf);
        } else {
            a('domainAdminModuleCels', domainAdminModuleCels);
            a('projectProxyModule', projectProxyModule);
            a('manageUnixGroupsModule', manageUnixGroupsModule);
        }
        a('commonModule', t);
        a('logsAdminModule', logsAdminModule );
        a('multiFilterModule', multiFilterModule);
        a('otherAccountsModule', otherAccountsModule);
        a('pluginsAdminModule', pluginsAdminModule);
        a('projectAdminModule', projectAdminModule);
        a('validationModule', validationModule);
        a('editRecordModule', editRecordModule);
        a('dayjs', dayjs);
        a('svcApiModule', svcApiModule);
        a('apiDevIntModule', apiDevIntModule);
  
        function a(name, obj){
          obj.sname = name; 
          jsModules.push(obj);
        }
        t.state.httpCallsPending = 0;
        commonModule.init(t.cookies, t.http, t.http, scope, setTimeout, window, jsModules);
  }

  addDisplayName(arr)           { return commonModule.addDisplayName(arr);}
  addSystemMessage(msg)         { return commonModule.addSystemMessage(msg);}
  arrayCopy(src, tgt)           { return commonModule.arrayCopy(src, tgt);}
  arrayCopyPointers(src, tgt)   { return commonModule.arrayCopyPointers(src, tgt);}
  arrayToCommaDelimited(a)      { return commonModule.arrayToCommaDelimited(a);}
  convertUTCtoCDT(s)            { return utc2cdt(s);}
  commaDelimitedToArray(str)    { return commonModule.commaDelimitedToArray(str);}
  compareAddresses(form)        { return commonModule.compareAddresses(form);}
  careerLevels(list)            { return commonModule.careerLevels(list);}
  changeOwner(owner, users)     { return commonModule.changeOwner(owner, users)}
  checkIfDataChanged(form, state){ return commonModule.checkIfDataChanged(form, state)}
  clearFilterCache()            { return commonModule.clearFilterCache()}
  convertMenu(items, user, st)  { return commonModule.convertMenu(items, user, st)}
  cut(str, delim, num)          { return commonModule.cut(str, delim, num)}
  deleteContext(pageName)       { return commonModule.deleteContext(pageName)}
  emptyObject(obj)              { return commonModule.emptyObject(obj);}
  explorerPage(e)               { return commonModule.explorerPage(e);}
  displayMemberStatus(obj, s)   { return commonModule.displayMemberStatus(obj, s)}
  doWhenAPICallsDone(cb, par?)  { return commonModule.doWhenAPICallsDone(cb, par);}
  filter(arName, data, colName, op, filters)
                                { return commonModule.filter(arName, data, colName, op, filters);}
  formatDeactDt(obj)            { return commonModule.formatDeactDt(obj);}
  formNames()                   { return commonModule.formNames();}
  formNamesCels()               { return commonModule.formNamesCels();}
  getALCFNews(lists)             { return commonModule.getALCFNews(lists);}
  getScienceValues(a)           { return commonModule.getScienceValues(a)}
  getUsersList(h, lists)        { return commonModule.getUsersList(h, lists)}
  getCertifiedHosts(f, sharedLists)  
                                { return commonModule.getCertifiedHosts(f, sharedLists)}
  getSharedObject(componentName, objectName)
                                { return commonModule.getSharedObject(componentName, objectName);}
  getSetContext(s, p, t1, tn)   { return commonModule.getSetContext(s, p, t1, tn);}
  getInstActPend(list)          { return commonModule.getInstActPend(list);}
  getLocalStorage(name)         { return commonModule.getLocalStorage(name);}
  getCountries(list)            { return commonModule.getCountries(list);}
  getCountryCode(str, list)     { return commonModule.getCountryCode(str, list);}
  getUsaStates()                { return commonModule.getUsaStates();}
  setUser(obj, callback)        { return commonModule.setUser(obj, callback);}
  getUserRole(callback)         { return commonModule.getUserRole(callback);}
  getLocaleString(str, type)    { return commonModule.getLocaleString(str, type);}
  getLimitedCountries(l)        { return commonModule.getLimitedCountries(l);}
  getLimitedInstitutionsList(h,l)
                                { return commonModule.getLimitedInstitutionsList(h,l);}
  getLimitedProjectsList(l)     { return commonModule.getLimitedProjectsList(l);}
  getInstitutionsList(h,l)      { return commonModule.getInstitutionsList(h,l);}
  getInstitutionId(name,l)      { return commonModule.getInstitutionId(name,l)}
  getProxyProjects(h, l, c)     { return commonModule.getProxyProjects(h, l, c)}
  getMenuItems(s)               { return commonModule.getMenuItems(s)}
  getUniqueEmails(arr)          { return commonModule.getUniqueEmails(arr);}
  getUsersNotSystemCreated(h,l,f)
                                { return commonModule.getUsersNotSystemCreated(h,l,f)}
  hasPrimary(a)                 { return commonModule.hasPrimary(a);}
  init(c, h, hn, s, t, w)       { return commonModule.init(c, h, hn, s, t, w);}
  isAdminPg()                   { return commonModule.isAdminPg();}
  isCollaborator(un)            { return commonModule.isCollaborator(un)}
  isEqual(a, b)                 { return commonModule.isEqual(a, b);}
  limitedLogin(form, sc, callback){ return commonModule.limitedLogin(form, sc, callback);}
  listFilter(f, l, ff)          { return commonModule.listFilter(f, l, ff)}
  loading(name, v)              { return commonModule.loading(name, v)}
  locateByZip(cou, zip, cb)     { return commonModule.locateByZip(cou, zip, cb);}
  locateByZipAup(fn, obj, row, form)
                                { return commonModule.locateByZipAup(fn, obj, row, form);}
  mkId(str)                     { return commonModule.mkId(str);}
  matchUsers(str, allAccounts, sc)
                                { return commonModule.matchUsers(str, allAccounts, sc)}
  matchUsersNG(str, allAccounts, flags, lists)  
                                { return commonModule.matchUsersNG(str, allAccounts, flags, lists)}
  onStopCalling(delay, callback){ return onStopCalling9(delay, callback);}
  objCopy(obj)                  { return commonModule.objCopy(obj);}
  objCopyGuts(src, tgt)         { return commonModule.objCopyGuts(src, tgt);}
  onCountryChange(obj,lists,cb) { return commonModule.onCountryChange(obj,lists,cb);}
  objCopyArray(lists)           { return commonModule.objCopyArray(lists)}
  ub3consoleLog(a,b)            { return commonModule.ub3consoleLog(a,b);}
  publicPage(b)                 { return commonModule.publicPage(b);}
  replaceAll(str, find, rep)    { return commonModule.replaceAll(str, find, rep);}
  removeALLsearchFilter(obj)    { return commonModule.removeALLsearchFilter(obj);}
  removeDuplicates(arr)         { return commonModule.removeDuplicates(arr);}
  removeEmailErrClass(id, name) { return commonModule.removeEmailErrClass(id, name);}
  removeNewDeletedObjs(form, arr){ return commonModule.removeNewDeletedObjs(form, arr);}
  sanitizeRule1(prjName)        { return commonModule.sanitizeRule1(prjName);}
  scrollToHasError(s, callback) { return commonModule.scrollToHasError(s, callback);}
  sendForm(path, params, target){ return commonModule.sendForm(path, params, target);}
  setLocalStorage(name, val)    { return commonModule.setLocalStorage(name, val);}
  setDomainGlobals(s, cb)       { return commonModule.setDomainGlobals(s, cb)}
  signInNg(uname, pwd, sc, cb)  { return commonModule.signInNg(uname, pwd, sc, cb);}
  signOut(d,sc, stay, r)        { return commonModule.signOut(d, sc, stay, r);}
  selectUsers(str, users, max)  { return commonModule.selectUsers(str, users, max);}
  sortArrayOnKey(dict, name, rev){ return commonModule.sortArrayOnKey(dict, name, rev)}
  sortOnKeys(dict, rev)         { return commonModule.sortOnKeys(dict, rev);}
  strArrWDelimiters(arr, d, ld) { return commonModule.strArrWDelimiters(arr, d, ld);}
  toCamel(str)                  { return commonModule.toCamel(str);}
  toDashCase(str)               { return commonModule.toDashCase(str);}
  toTitleCase(str)              { return commonModule.toTitleCase(str);}
  tweakOrcid(str, fldName,psf)  { return commonModule.tweakOrcid(str, fldName, psf);}
  uniqueInstitutions(ar)        { return commonModule.uniqueInstitutions(ar);}
  uniqueUAInstitutions(ar)      { return commonModule.uniqueUAInstitutions(ar);}
  unixGroupList(h, l, al)       { return commonModule.unixGroupList(h, l, al);}
  urlSearchParamsArray()        { return commonModule.urlSearchParamsArray();}
  validateList(formName, obj, row, state, form, fromPageLoad)
                                { return commonModule.validateList(formName, obj, row, state, form, fromPageLoad);}
  validateListsAfterGet(flags, form)
                                { return commonModule.validateListsAfterGet(flags, form);}
  validateLL(fo, fl, t, cb)     {return commonModule.validateLL(fo, fl, t, cb);}
  validateLLNow(fo, fl)         {return commonModule.validateLLNow(fo, fl);}
  verifyEmailCode(sc, code, ac, cb) {return commonModule.verifyEmailCode(sc, code, ac, cb);}
  verifySecurityAnswer(sc, ans, cb) {return commonModule.verifySecurityAnswer(sc, ans, cb);}
}

function utc2cdt(date){
    //return date ? date.toString() : null;

    if(!date){
        return 'N/A';
    }else{
        var checkDate = dayjs(date, 'YYYY-MM-DD HH:mm:ss');
        var isValidDate = checkDate.isValid();
        if(isValidDate){
            // display medium date .format('MMM D, YYYY'), returns 'Dec 27, 2018'
            // to see converted date, test with hours .format("YYYY-MM-DD HH:mm");
        var cdt = dayjs.utc(date).tz('America/Chicago').format('MMM D, YYYY');
            return cdt;

        } else{
            // this isn't a valid date but a string, just show it...
            //example "Favor record missing"
            return date;
        }
    }
}

let userIsTyping = false;
let typingPromise = null;

function onStopCalling9(delay, callback){
    clearTimeout(typingPromise);
    if (!userIsTyping){
        // this is the first keystroke from the user (or from cypress) for this input field
        userIsTyping = true;
        commonModule.changeCallsPending('user is typing', scope, 1);        // for cypress
    }
    typingPromise = setTimeout(function(){
        userIsTyping = false;
        if (scope.httpCallsPending == 0){
            //('callsPending confused !!!!  about to call', callback);
        }
        commonModule.changeCallsPending('user done typing', scope, -1);        // for cypress
        callback();
    }, delay);
}
