<br>
<div class="dashboard" *ngIf="!flags.editRecord">
   <div class="sub-menu-header">
       User Administration
   </div>

   <ng-container class="row" id="user-admin-sub-menu">
       <span class="sub-menu-item">
            <input id="subMenu-search" type=checkbox [(ngModel)]="flags.show.search">
            <a (click)="flags.show.search = !flags.show.search"
               class="userAdmOptions"
               [ngClass]="{'selected-option': flags.show.search}">
               Search Users
            </a>
            <span *ngIf="filteredCount" id="usCount">
               ({{filteredCount}})
            </span>
       </span>
       <span class="sub-menu-item">
            <input id="subMenu-acReq" type=checkbox [(ngModel)]="flags.show.acReq">
            <a (click)="toggleDisplay('acReq')"
               class="userAdmOptions"
               [ngClass]="{'selected-option': flags.show.acReq}">
               Account Requests
            </a>
            <span *ngIf="lists.accountRequests && (lists.accountRequests.length > 0)"
                  id="acReqCount">
               ({{acReqCount}})
            </span>
       </span>
       <span class="sub-menu-item">
            <input id="subMenu-pjMemReq" type=checkbox [(ngModel)]="flags.show.pjMemReq">
            <a (click)="flags.show.pjMemReq = !flags.show.pjMemReq"
               class="userAdmOptions"
               [ngClass]="{'selected-option': flags.show.pjMemReq}">
               Project Membership Requests
            </a>
            <span *ngIf="lists.projectMembershipRequests && (lists.projectMembershipRequests.length > 0)"
                  id="pjMemReqCount">
               ({{lists.projectMembershipRequests.length}})
            </span>
       </span>
       <span class="sub-menu-item">
            <input id="subMenu-reactReq" type=checkbox [(ngModel)]="flags.show.reactReq">
            <a (click)="flags.show.reactReq = !flags.show.reactReq"
               class="userAdmOptions"
               [ngClass]="{'selected-option': flags.show.reactReq}">
               Reactivation Requests
            </a>
            <span *ngIf="lists.reactivationRequests && (lists.reactivationRequests.length > 0)"
                  id="reacReqCount">
               ({{lists.reactivationRequests.length}})
            </span>
       </span>
   </ng-container>

       <!-- SEARCH -->
       <div  *ngIf="flags.show.search" class="request-section">
            <div class="admin-search-title">
                Search by name, username, or email
              <input
                style="width:150px"
                type="text"
                [(ngModel)]="form.searchName"
                id="searchName"
                (ngModelChange)="checkSearchName()"
                (keydown.enter)="searchUser($event)">
              <button id="userAdmSearchBtn"
                        class="btn btn-primary btn-md searchBtn"
                        (click)="adminSearchUserAndSave(form.searchName)"
                        [disabled]="(!form.searchName)">
                  SEARCH
              </button>
               <span class="match-not-found" *ngIf="!flags.matchedName && flags.searchCompleted">Match not found</span>
               <div><a id="searchNoEmail" class="noEmailLink" (click)='searchByNoEmail()'>Search users w/o emails</a></div>
            </div>
            <warning fid="searchName"></warning>
       </div>
       <div *ngIf="flags.bootstrapAlert === 'editRecordError' || flags.bootstrapAlert === 'getRequestsError'">
          <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
       </div>
       <div *ngIf="lists.errorEditRecord" class="alert alert-danger" [textContent]="lists.errorEditRecord"></div>

       <!-- SEARCH RESULTS-->
       <div class="request-section search-result-table userAdmnComp" 
            *ngIf="flags.show.search" 
            [hidden]="!lists.userRecords || (lists.userRecords.length == 0)">
          <div *ngIf="flags.bootstrapAlert === 'admEditRecord'">
             <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
          </div>
          <h4>User Records</h4>
          <span *ngIf="flags.hideUserSearch">Click to show Search Results</span>
          <div class="user-records-table condensed" *ngIf="!flags.hideUserSearch">

            <table id="uat1" class="table table-striped">
                <ub3tbl name="uat1"
                        opt="titles"
                        [titles]="uat1titles"
                        [rows]="lists.userRecords"
                        [rowsOut]="lists.uRecsOut"
                        [onDonePaging]="onDonePaging"
                        [outTrigger]="updateUIcount"
                        >
                </ub3tbl>

                <ng-container *ngIf="donePaging1">

                <tr *ngFor="let row of lists.uRecsOut; let rowIndex = index;">

                    <td nowrap>
                        <a
                            class="text-primary1 bold"
                            style="cursor:pointer"
                            id="{{row.userName.replace('@','').replace('.','')}}"
                            (click)="adminEditRecord(row.userName, row.status, row.accountCategory)">
                            {{row.userName}}
                        </a>
                        <ng-container *ngIf="row.hasRequest" style="cursor:pointer">
                           <span>
                              <i *ngIf="!flags.filterProjectMembershipRequest[row.userName]"
                                 (click)="clickPlusMinus(row.userName)"
                                 class="fas fa-plus"
                                 id="ua-plus-{{row.userName}}">
                              </i>
                              <i *ngIf="flags.filterProjectMembershipRequest[row.userName]"
                                 (click)="clickPlusMinus(row.userName)"
                                 class="fas fa-minus"
                                 id="ua-minus-{{row.userName}}">
                              </i>
                           </span>
                        </ng-container>
                    </td>
                    <td> <span [innerHtml]="row.dispName" class="wordWrap"></span> </td>
                    <td> <span class="breakText">{{row.primaryEmail}}</span> </td>
                    <td> <span class="breakText">{{row.alternateEmail}}</span> </td>
                    <td>
                            <select  [(ngModel)]="row.accountType"
                                    id="{{row.userName}}-accountType"
                                    (change)="domainAdminSelectOption(row.accountType, rowIndex, 'admEditRecord','accountType', row)">
                                <option value="Annual">Annual</option>
                                <option value="Permanent">Permanent</option>
                            </select>
                    </td>
                    <td>{{row.accountCategory}}</td>
                    <td>
                            <ul class="list-unstyled" *ngFor="let affiliation of row.affiliations">
                                <li #affiliationContents 
                                    [ngClass]="{'institution-primary': affiliation.primary}" 
                                    class="col1 affiliation wordWrap" 
                                    [textContent]="affiliation.institutionName">
                            </ul>
                    </td>
                    <td width="40px">
                            <ul class="list-unstyled affAck" *ngFor="let af of row.affiliations">
                                <li>
                                <span class="ack-border" 
                                      id="affiliationACK" 
                                      (click)="af.ack = af.ack ? false : true; domainAdminSelectOption(af.id, rowIndex, 'admEditRecord','ack', row);">
                                    <i *ngIf="af.ack"  class="fas fa-check checkmark"></i>
                                    <i *ngIf="!af.ack" class="fas fa-times removeIcon"></i>
                                </span>
                                <span class="affiliation wordWrap">
                                    <span style="visibility:hidden">{{af.institutionName}}</span>
                                </span>
                                </li>
                            </ul>
                    </td>
                    <td>
                            <span *ngIf="row.status === 'System Created'" [textContent]="row.status"></span>
                            <select
                                    *ngIf="row.status !== 'System Created'"
                                    class="selcls"
                                    [(ngModel)]="row.status"
                                    ng-disabled="row.status === 'Requested'"
                                    (change)="domainAdminSelectOption(row.status, rowIndex, 'admEditRecord','ms_state')"
                                    id="status-for-{{row.userName}}">

                                <option value="Rejected">Rejected</option>
                                <option value="Requested">Requested</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="Deleted">Deleted</option>
                            </select>
                    </td>
                    <td nowrap>
                            <div *ngIf="row.accountType === 'Annual' && row.status !=='Requested'">
                            <ub3date
                                id="{{row.userName}}-deact-date"
                                [form]="row"
                                key="deactivation_date"
                                [idPrefix]="row.userName"
                                (onChange)="domainAdminSelectOption(row.deactivation_date, rowIndex, 'admEditRecord','deactivation_date')">
                            </ub3date>
                            </div>
                            <div *ngIf="row.accountType === 'Permanent' || row.status ==='Requested'" >
                              <div class="deactDt" id="{{row.userName}}-deact-date"> {{(row.status ==='Requested') ? 'none' : row.deactivation_date}}</div>
                              <div *ngIf="row.status !== 'Requested'" id="{{row.userName}}-helpText" class="helpText">
                                 Please go to other screens to change the date for this type of account
                              </div>
                            </div>
                    </td>
                    <td>

                                <span id="{{row.userName}}-usCitizen" >
                                    <i *ngIf="row.usCitizen"  class="fas fa-check checkmark"></i>
                                    <i *ngIf="!row.usCitizen" class="fas fa-times removeIcon"></i>
                                </span>
                    </td>
                    <td> <span [textContent]="convertUTCtoCDT(row.favorExpirationDate)"></span> </td>
                    <td> <span [textContent]="convertUTCtoCDT(row.favorStatus)"></span> </td>
                    <td> <span [textContent]="convertUTCtoCDT(row.validationDate)"></span> </td>

                    <td>
                            <div class="comment-column">
                            <span class="floating-label-wrapper">
                                <textarea   autosize
                                            rows="1"
                                            id="search-comment-{{row.userName}}"
                                            type="text"
                                            class="form-control autosize"
                                            [ngClass]="{'normal-size': !flags.isOnFocus}"
                                            placeholder="Comment"
                                            [(ngModel)]="row.comment"
                                            (focus)="autosizeFn('search-comment-'+row.userName)"
                                            (blur)="getSmall('search-comment-'+row.userName)"
                                            (keyup)="domainAdminSelectOption(row.comment, rowIndex, 'admEditRecord','comment', row)">
                                </textarea>
                            </span>
                            </div>
                            <div class="action"
                                *ngIf="flags.index == rowIndex && flags.tableSelected === 'admEditRecord' && flags.showSubmit">
                                <button
                                    class="btn btn-primary btn-md"
                                    (click)="adminRequestAction('userRecord', flags.ms_state, row.accountId, rowIndex)"
                                    id="submitBtnSearch">
                                Submit
                                </button>
                            </div>
                    </td>

                </tr>
                </ng-container>

                <tr>
                    <td colspan=15>
                        <ub3tbl name="uat1" opt="paging" [maxPerPage]="20"></ub3tbl>
                    </td>
                </tr>
            </table>

          </div>
       </div>

       <!-- ACCOUNT REQUESTS -->
       <div class="request-section account-requests-table userAdmnComp" *ngIf="flags.show.acReq">
          <h4>Account Requests</h4>
          <p *ngIf="!flags.hideAcctRequest">
             These are account requests that are waiting on approval from the account
             approver and the
             sponsor. When both have been approved, the account can be created. If one of them rejects the
             account, it can be deleted. Either action can also be done prematurely if necessary.
          </p>
          <span *ngIf="flags.hideAcctRequest">Click to show Account Requests</span>
          <div *ngIf="flags.bootstrapAlert === 'admAcctRequest'">
             <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
          </div>
          <div class="requests-table condensed" *ngIf="!flags.hideAcctRequest">

            <table id="admAcctRequest" class="table table-striped">
                <ub3tbl name="admAcctRequest"
                        opt="titles"
                        [titles]="admAcctRequestTitles"
                        [rows]="lists.accountRequests"
                        [rowsOut]="lists.accReqsOut"
                        [onDonePaging]="onDonePaging2"
                        [outTrigger]="updateUIcount2"
                        >
                </ub3tbl>
                <ng-container *ngFor="let row of lists.accReqsOut">
                  <tr [style.background-color]= "flags.allowEdit[row.userName] ? '#eeeeee' : '#ffffff'">
                    <td>
                        <a style="cursor:pointer" id="edit-{{row.userName}}" (click)="allowEdit(row.userName)">
                            <img height="14px" width="14px" src="/frontend/assets/pen-solid.svg">
                        </a>          
                        <img width="20px" height="0px">
                        <a
                            class="text-primary1 bold"
                            style="cursor:pointer"
                            id="accUser-{{row.userName}}"
                            (click)="adminEditRecord(row.userName)">
                            {{row.userName}}
                        </a>          
                    </td>
                    <td>
                        <span [innerHtml]="row.dispName" class="wordWrap"></span>
                    </td>
                    <td>
                        <span [textContent]="convertUTCtoCDT(row.dateRequested)"></span>
                    </td>
                    <td>
                        <ng-container *ngIf="flags.allowEdit[row.userName]">
                          <select  [(ngModel)]="row.accountType" id="accType-{{row.userName}}"
                                (change)="domainAdminSelectOption(row.accountType, rowIndex, 'admAcctRequest','accountType')">
                            <option value="Annual">Annual</option>
                            <option value="Permanent">Permanent</option>
                          </select>
                        </ng-container>
                        <ng-container *ngIf="!flags.allowEdit[row.userName]">
                            {{row.accountType}}
                        </ng-container>
                    </td>
                    <td>
                        <ul class="list-unstyled" *ngFor="let affiliation of row.institutions">
                            <li [ngClass]="{'institution-primary': affiliation.primary}" 
                                class="col1 affiliation wordWrap"
                                [textContent]="affiliation.name">
                            </li>
                         </ul>
                    </td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let project of row.projectShortNameList">
                               <a (click)="goToPjEdit(project)"
                                  id="edit-{{project}}"
                                  class="wordWrap"
                                  [textContent]="project"></a>
                            </li>
                         </ul>
                    </td>
                    <td>
                        <li class="list-unstyled project-status" 
                           *ngFor="let pi of row.projectPIList ">
                            <span [textContent]="pi"></span>
                        </li>
                    </td>
                    <td>
                        <ul class="list-unstyled">
                            <li class="project-status"
                               *ngFor="let status of row.projectMemberStateList ">
                               <span [textContent]="status" id="acReq-PjMemStatus-{{row.userName}}"></span>
                            </li>
                        </ul>
                    </td>
                    <td>
                        <span>
                            <i *ngIf="row.usCitizen"  class="fas fa-check checkmark"></i>
                            <i *ngIf="!row.usCitizen" class="fas fa-times removeIcon"></i>
                         </span>
                    </td>
                    <td>
                        <span [textContent]="convertUTCtoCDT(row.expirationDate593)"></span>
                    </td>
                    <td>
                        <span>{{ convertUTCtoCDT(row.status593) }}</span>
                    </td>
                    <td>
                        <ng-container *ngIf="flags.allowEdit[row.userName]">
                         <div class="comment-column">
                            <span class="floating-label-wrapper">
                               <textarea
                                  autosize
                                  rows="1"
                                  id="admAcct-comment-{{row.userName}}"
                                  type="text"
                                  class="form-control autosize"
                                  [ngClass]="{'normal-size': !flags.isOnFocus}"
                                  placeholder="Comment"
                                  [(ngModel)]="row.comment"
                                  (focus)="autosizeFn('admAcct-comment-'+row.userName)"
                                  (blur)="getSmall('admAcct-comment-'+row.userName)"
                                  (keyup)="domainAdminSelectOption(row.comment, rowIndex, 'admAcctRequest','comment', row); adminValidate();"
                                  style="width:100%;">
                               </textarea>
                            </span>
                         </div>
                         </ng-container>
                        <ng-container *ngIf="!flags.allowEdit[row.userName]">
                            <span 
                                (mouseenter)="comHover(row.userName, true)" 
                                (mouseout)  ="comHover(row.userName, false)"
                                >
                                <span *ngIf="flags.big && flags.big[row.userName]">
                                    {{row.comment}}
                                </span>
                                <span *ngIf="!flags.big || !flags.big[row.userName]">
                                    {{row.comment | slice:0:20 }}
                                </span>
                            </span>
                        </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="flags.allowEdit[row.userName]">
                        <select
                            [(ngModel)]="row.ms_state_change"
                            id="status-{{row.userName}}"
                            (change)="domainAdminSelectOption(row.ms_state_change, rowIndex, 'admAcctRequest','ms_state', row)">
                            <option value="" selected disabled hidden>Requested</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Requested">Requested</option>
                        </select>

                        <ng-container *ngIf="flags.index == rowIndex && flags.tableSelected === 'admAcctRequest' && flags.showSubmit">
                            <br>
                            <button
                                class="btn btn-primary btn-md" 
                                id="submitBtnAcReq"
                                (click)="adminRequestAction('accountRequest', flags.ms_state, row.accountId, lists.accountRequests.indexOf(row))">
                              Submit
                            </button>
                        </ng-container>
                       </ng-container>
                       <ng-container *ngIf="!flags.allowEdit[row.userName]">
                            {{row.ms_state_change}}
                       </ng-container>
                    </td>
                  </tr>
                  <tr *ngIf="openPIRejectModal && (warningUser == row.userName)">
                    <td colspan="13" id="piRejectAlert" align="middle">
                        <span>This user is a PI. Do you want to reject a PI's account?</span>
                        <button id="seleBtnYes"
                           class="btn btn-primary btn-md confirmBtn" [ngClass]="{'btn-primary': okToProceed}" (click)="proceedRejectPI(row, true, 'admAcctRequest','ms_state')">
                           Yes
                        </button>
                        <button id="seleBtnNo"
                           class="btn btn-primary btn-md confirmBtn"
                           [ngClass]="{'btn-primary': !okToProceed}" (click)="proceedRejectPI(row, false)">
                           No
                        </button>
                    </td>
                  </tr>
                </ng-container>
                <tr>
                    <td colspan=13>
                        <ub3tbl name="admAcctRequest" opt="paging" [maxPerPage]="20"></ub3tbl>
                    </td>
                </tr> 
            </table>

            <ngx-datatable
                id="admAcctRequest2"
                *ngIf="false"
                #admAcctRequest
                class="bootstrap card expandable centerText"
                [limit]="20"
                columnMode="flex"
                [rowHeight]="auto"
                [headerHeight]="50"
                [footerHeight]="50"
                [scrollbarH]="true"
                [rows]="lists.accountRequests"
                [sorts]="[{prop: 'userName', dir: 'asc'}]">

                <ngx-datatable-column name="Username" prop="userName" [flexGrow]=".8">
                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <div>
                           <input  id="acReq-filter-userName"
                                    type="text"
                                    placeholder="Filter by User Name"
                                    [(ngModel)]="flags.filter.acReq.userName"
                                    (keyup)="updateFilter('acReq', lists.accountRequests, 'userName', 'and')"
                           >
                        </div>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>User Name</b></span>
                    </ng-template>

                    <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <a
                           class="text-primary1 bold"
                           style="cursor:pointer"
                           id="accUser-{{row.userName}}"
                           (click)="adminEditRecord(value)">
                           {{value}}
                        </a>
                     </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="1.00">
                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <div>
                           <input  id="acReq-filter-name"
                                    type="text"
                                    placeholder="Filter by Name"
                                    [(ngModel)]="flags.filter.acReq.dispName"
                                    (keyup)="updateFilter('acReq', lists.accountRequests, 'dispName', 'and')"
                           >
                        </div>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Name</b></span>
                    </ng-template>
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span [innerHtml]="row.dispName" class="wordWrap"></span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Date Requested" prop="dateRequested" [flexGrow]=".75">
                       <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                           <span class="datatable-header-cell-label draggable" (click)="sort()" id="sort-by-date-requested">
                               <b>Date Requested</b>
                           </span>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                           <span [textContent]="convertUTCtoCDT(row.dateRequested)"></span>
                        </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Account Type" prop="accountType" [flexGrow]=".75">
                     <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <div>
                           <input  id="acReq-filter-accountType"
                                    type="text"
                                    placeholder="Filter by Account Type Requested"
                                    [(ngModel)]="flags.filter.acReq.accountType"
                                    (keyup)="updateFilter('acReq', lists.accountRequests, 'accountType', 'and')"
                           >
                        </div>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Account Type</b></span>
                     </ng-template>
                    <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <select  [(ngModel)]="row.accountType" id="accType-{{row.userName}}"
                                 (change)="domainAdminSelectOption(row.accountType, rowIndex, 'admAcctRequest','accountType')">
                                <option value="Annual">Annual</option>
                                <option value="Permanent">Permanent</option>
                        </select>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Institution" prop="affiliation" [flexGrow]="2.00">
                     <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <div>
                           <input  id="acReq-filter-affiliation"
                                    type="text"
                                    placeholder="Filter by Affiliation"
                                    [(ngModel)]="flags.filter.acReq.filterAffiliation"
                                    (keyup)="updateFilter('acReq', lists.accountRequests, 'filterAffiliation', 'and')"
                           >
                        </div>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Affiliation</b></span>
                     </ng-template>
                    <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                        <ul class="list-unstyled" *ngFor="let affiliation of row.institutions">
                            <li [ngClass]="{'institution-primary': affiliation.primary}" class="col1 affiliation wordWrap"
                               [textContent]="affiliation.name">
                            </li>
                         </ul>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Projects"  [flexGrow]=".75">
                     <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <div>
                           <input  id="acReq-filter-projects"
                                    type="text"
                                    placeholder="Filter by Projects"
                                    [(ngModel)]="flags.filter.acReq.projectShortNameList"
                                    (keyup)="updateFilter('acReq', lists.accountRequests, 'projectShortNameList', 'and')"
                           >
                        </div>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Projects</b></span>
                     </ng-template>
                    <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                        <ul class="list-unstyled">
                            <li *ngFor="let project of row.projectShortNameList">
                               <a (click)="goToPjEdit(project)"
                                  id="edit-{{project}}"
                                  class="wordWrap"
                                  [textContent]="project"></a>
                               <!-- <a
                                  (click)="pjToEdit=row.name; flags.showProjectEditForm=true; newOrEdit = 'edit'; acc.toggle('projectEdit');"
                                  id="edit-{{project}}"
                                  style="cursor: pointer">
                                     <font class="projName">{{project}}</font>
                               </a> -->
                            </li>
                         </ul>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Project PI"  [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="acReq-filter-projectPI"
                                 type="text"
                                 placeholder="Filter by Project PI"
                                 [(ngModel)]="flags.filter.acReq.projectPIList"
                                 (keyup)="updateFilter('acReq', lists.accountRequests, 'projectPIList', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project PI</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                        <li class="list-unstyled project-status" *ngFor="let pi of row.projectPIList ">
                            <span [textContent]="pi"></span>
                        </li>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Project Membership Status"  [flexGrow]="1.00">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project Membership Status</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                        <ul class="list-unstyled">
                            <li class="project-status"
                               *ngFor="let status of row.projectMemberStateList ">
                               <span [textContent]="status" id="acReq-PjMemStatus-{{row.userName}}"></span>
                            </li>
                         </ul>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="US Citizen" prop="usCitizen" [flexGrow]=".65">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>US Citizen</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                        <span>
                           <i *ngIf="row.usCitizen"  class="fas fa-check checkmark"></i>
                           <i *ngIf="!row.usCitizen" class="fas fa-times removeIcon"></i>
                        </span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="593 Exp Date" prop="expirationDate593" [flexGrow]=".75">
                 <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>593 Expiration Date</b></span>
                  </ng-template>
                    <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span [textContent]="convertUTCtoCDT(row.expirationDate593)"></span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="593 Status" prop="status593" [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="acReq-filter-status593"
                                 type="text"
                                 placeholder="Filter by 593 Status"
                                 [(ngModel)]="flags.filter.acReq.status593"
                                 (keyup)="updateFilter('acReq', lists.accountRequests, 'status593', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>593 Status</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <span>{{ convertUTCtoCDT(row.status593) }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Comments" prop="comment" [flexGrow]="1.00">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="acReq-filter-comment"
                                 type="text"
                                 placeholder="Filter by Comments"
                                 [(ngModel)]="flags.filter.acReq.comment"
                                 (keyup)="updateFilter('acReq', lists.accountRequests, 'comment', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Comments</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <div class="comment-column">
                           <span class="floating-label-wrapper">
                              <textarea
                                 autosize
                                 rows="1"
                                 id="admAcct-comment-{{row.userName}}"
                                 type="text"
                                 class="form-control autosize"
                                 [ngClass]="{'normal-size': !flags.isOnFocus}"
                                 placeholder="Comment"
                                 [(ngModel)]="row.comment"
                                 (focus)="autosizeFn('admAcct-comment-'+row.userName)"
                                 (blur)="getSmall('admAcct-comment-'+row.userName)"
                                 (keyup)="domainAdminSelectOption(row.comment, rowIndex, 'admAcctRequest','comment', row); adminValidate();"
                                 style="width:100%;">
                              </textarea>
                           </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Account Status"  [flexGrow]="0.8">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Account Status</b></span>
                  </ng-template>
                    <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <select
                        [(ngModel)]="row.ms_state_change"
                        id="status-{{row.userName}}"
                        (change)="domainAdminSelectOption(row.ms_state_change, rowIndex, 'admAcctRequest','ms_state', row)">
                        <option value="" selected disabled hidden>Requested</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Requested">Requested</option>
                     </select>
                     <ng-container *ngIf="flags.index == rowIndex && flags.tableSelected === 'admAcctRequest' && flags.showSubmit">
                        <br>
                        <button
                           class="btn btn-primary btn-md" id="submitBtnAcReq"
                           (click)="adminRequestAction('accountRequest', flags.ms_state, row.accountId, lists.accountRequests.indexOf(row))">
                           Submit
                        </button>
                     </ng-container>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-row-detail [rowHeight]="auto" #piRejectRow>
                  <ng-template let-row="row" let-expanded="true" let-rowIndex="rowIndex" ngx-datatable-row-detail-template>
                     <div *ngIf="openPIRejectModal" id="piRejectAlert">
                        <span>This user is a PI. Do you want to reject a PI's account?</span>
                        <button id="seleBtnYes"
                           class="btn btn-primary btn-md confirmBtn" [ngClass]="{'btn-primary': okToProceed}" (click)="proceedRejectPI(row, true, 'admAcctRequest','ms_state')">
                           Yes
                        </button>
                        <button id="seleBtnNo"
                           class="btn btn-primary btn-md confirmBtn"
                           [ngClass]="{'btn-primary': !okToProceed}" (click)="proceedRejectPI(row, false)">
                           No
                        </button>
                     </div>
                  </ng-template>
              </ngx-datatable-row-detail>
            </ngx-datatable>

          </div>
       </div>

       <!-- PROJECT MEM REQUESTS -->
       <div class="request-section project-requests-table userAdmnComp" *ngIf="flags.show.pjMemReq">
          <h4> Project Membership Requests </h4>
          <p *ngIf="!flags.hideProjectMembershipRequest">These are project membership requests that are waiting on approval from the PI.
          </p>
          <span *ngIf="flags.hideProjectMembershipRequest">Click to show requests</span>
          <div *ngIf="flags.bootstrapAlert === 'admProjectMembershipRequest'" id="projMemAlerts">
             <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
          </div>
          <div class="requests-table condensed" *ngIf="!flags.hideProjectMembershipRequest">
            <ngx-datatable
               id="admPjMemReq"
               class="bootstrap card expandable centerText"
               [limit]="50"
               columnMode="flex"
               [rowHeight]="auto"
               [headerHeight]="50"
               [footerHeight]="50"
               [scrollbarH]="true"
               [rows]="lists.projectMembershipRequests"
               [sorts]="[{prop: 'userName', dir: 'asc'}]">

               <ngx-datatable-column name="Username" prop="userName" [flexGrow]=".5">
                   <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                         <input  id="pjMemReq-filter-userName"
                                 type="text"
                                 placeholder="Filter by User Name"
                                 [(ngModel)]="flags.filter.pjMemReq.userName"
                                 (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'userName', 'and')"
                         >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>User Name</b></span>
                  </ng-template>

                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <a
                        class="text-primary1 bold"
                        style="cursor:pointer"
                        id="projUser-{{value}}"
                        (click)="adminEditRecord(value, row.accountStatus)">
                        {{value}}
                     </a>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Name" prop="lastName" [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-dispName"
                                type="text"
                                placeholder="Filter by Name"
                                [(ngModel)]="flags.filter.pjMemReq.dispName"
                                (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'dispName', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Name</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span [innerHtml]="row.dispName" class="wordWrap"></span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Date Requested" prop="dateRequested" [flexGrow]=".5">
                   <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()" id="pmr-sort-by-date-requested"><b>Date Requested</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span [innerHtml]="convertUTCtoCDT(row.dateRequested)"></span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Affiliation" prop="dateRequested" [flexGrow]=".8">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-affiliation"
                                 type="text"
                                 placeholder="Filter by Affiliation"
                                 [(ngModel)]="flags.filter.pjMemReq.filterAffiliation"
                                 (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'filterAffiliation', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Affiliation</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <ng-container *ngIf="row.institutions != 'No affiliation'">
                        <ul class="list-unstyled" *ngFor="let affiliation of row.institutions">
                           <li [ngClass]="{'institution-primary': affiliation.primary}" class="col1 affiliation wordWrap"
                              [textContent]="affiliation.name">
                           </li>
                        </ul>
                     </ng-container>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="User Account Status" prop="accountStatus" [flexGrow]=".5">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-accountStatus"
                                type="text"
                                placeholder="Filter by Account Status"
                                [(ngModel)]="flags.filter.pjMemReq.accountStatus"
                                (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'accountStatus', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Account Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     {{value}}
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Project Name" prop="projectShortName" [flexGrow]=".6">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-projectShortName"
                                type="text"
                                placeholder="Filter by Project Name"
                                [(ngModel)]="flags.filter.pjMemReq.projectShortName"
                                (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'projectShortName', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project Name</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <a (click)="goToPjEdit(value)" id="li-{{value}}" class="wordWrap">
                        {{value}}
                     </a>
                     <!-- <a
                        (click)="pjToEdit=row.name; flags.showProjectEditForm=true; newOrEdit = 'edit'; acc.toggle('projectEdit');"
                        id="li-{{value}}"
                        style="cursor: pointer">
                           <font class="projName">{{value}}</font>
                     </a> -->
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Project Status" prop="projectStatus" [flexGrow]=".4">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-projectStatus"
                                type="text"
                                placeholder="Filter by Project Status"
                                [(ngModel)]="flags.filter.pjMemReq.projectStatus"
                                (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'projectStatus', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span id="pjMemReq-pjStatus-{{row.userName}}">{{value}}</span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="PI/Proxy Approver" prop="approver_name" [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>PI/Proxy Approver</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <select class="form-control select-approver dropdownOption"
                        id="approver-name-for-{{row.projectShortName}}"
                        [(ngModel)]="row.approver_name"

                        (change)="domainAdminSelectOption(row.approver_name, rowIndex, 'admProjectMembershipRequest','approver')"
                        >
                        <option *ngIf="!row.approver_name" [ngValue]="null" selected>
                           Select Approver
                        </option>
                        <option *ngFor="let s of row.piproxies" [ngValue]="s.piProxy">
                           {{s.piProxy}}
                        </option>
                     </select>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Comments" prop="comment" [flexGrow]=".9">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="pjMemReq-filter-comment"
                                type="text"
                                placeholder="Filter by Comment"
                                [(ngModel)]="flags.filter.pjMemReq.comment"
                                (keyup)="updateFilter('pjMemReq', lists.projectMembershipRequests, 'comment', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Comment</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <div class="comment-column">
                        <span class="floating-label-wrapper">
                           <textarea
                              autosize
                              rows="1"
                              id="projMem-comment-{{row.userName}}"
                              type="text"
                              class="form-control autosize"
                              [ngClass]="{'normal-size': !flags.isOnFocus}"
                              placeholder="Comment"
                              [(ngModel)]="row.comment"
                              (focus)="autosizeFn('projMem-comment-'+row.userName)"
                              (blur)="getSmall('projMem-comment-'+row.userName)"
                              (keyup)="domainAdminSelectOption(row.comment, rowIndex, 'admProjectMembershipRequest','comment', row)">
                           </textarea>
                        </span>
                     </div>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Project Member Status" prop="memberStatus" [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Member Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <select
                        ng-class="{'double-wide' : row.memberStatus === 'Approved-pending MUA/Ack'}"
                        class="form-control dropdownOption wrapText"
                        [(ngModel)]="row.memberStatus"
                        id="btn"
                        (change)="domainAdminProjectOption(row.memberStatus, rowIndex, lists.projectMembershipRequests.indexOf(row));">

                        <option value="Requested">Requested</option>
                        <option value="Approved">Approved</option>
                        <option value="Approved-pending MUA/Ack">Approved-pending MUA/Ack</option>
                        <option value="Rejected">Rejected</option>
                     </select>
                     <button
                        *ngIf="flags.index == rowIndex && flags.tableSelected === 'admProjectMembershipRequest' && flags.showSubmit"
                        class="btn btn-primary btn-md"
                        id="submitBtnPjMemReq"
                        (click)="adminRequestAction('projectMembershipRequest', row.memberStatus, row.id, lists.projectMembershipRequests.indexOf(row))">
                        Submit
                     </button>
                     <a *ngIf="row.memberStatus ==='Requested' && row.accountStatus !=='System Created'"
                        (click)="membershipReminder(row.userName, row.projectShortName)"
                        class="membership-reminder"
                        id="reminder-{{rowIndex}}">
                        Membership Reminder
                     </a>
                  </ng-template>
               </ngx-datatable-column>
            </ngx-datatable>
          </div>
       </div>

       <!-- REACTIVATION REQUESTS -->
       <div class="request-section react-requests-table userAdmnComp" *ngIf="flags.show.reactReq">
          <h4>Reactivation Requests</h4>
          <p *ngIf="!flags.hideReactRequest">These are account requests that are waiting on approval from the account
             approver and the
             sponsor. When both have been approved, the account can be created. If one of them rejects the
             account, it can be deleted. Either action can also be done prematurely if necessary.
          </p>
          <span *ngIf="flags.hideReactRequest">Click to show Reactivation Requests</span>
          <div *ngIf="flags.bootstrapAlert === 'admReactRequest'">
             <alert-msg [(alerts)]="domainAdminDashboardAlerts"></alert-msg>
          </div>
          <div class="requests-table condensed" *ngIf="!flags.hideReactRequest">

            <ngx-datatable
               id="admReactRequest"
               class="bootstrap card expandable centerText"
               [limit]="20"
               columnMode="flex"
               [rowHeight]="auto"
               [headerHeight]="50"
               [footerHeight]="50"
               [scrollbarH]="true"
               [rows]="lists.reactivationRequests"
               [sorts]="[{prop: 'userName', dir: 'asc'}]">

               <ngx-datatable-column name="Username"            prop="userName"           [flexGrow]=".6">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                         <input  id="reactReq-filter-userName"
                                 type="text"
                                 placeholder="Filter User"
                                 [(ngModel)]="flags.filter.reactReq.userName"
                                 (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'userName', 'and')">
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>User Name</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <a
                        class="text-primary1 bold"
                        style="cursor:pointer"
                        id="aEdit-{{row.userName}}"
                        (click)="adminEditRecord(value)">
                        {{value}}
                     </a>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Name"                prop="dispName"           [flexGrow]="1.00">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-dispName"
                                type="text"
                                placeholder="Filter Name"
                                [(ngModel)]="flags.filter.reactReq.dispName"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'dispName', 'and')">
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Name</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span [innerHtml]="row.dispName" class="text-primary1 wordWrap"></span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Date Submitted"      prop="dateSubmitted"      [flexGrow]=".76">
                 <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Date Submitted</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span [textContent]="convertUTCtoCDT(row.dateSubmitted)"></span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Account Status"      prop="accountStatus"      [flexGrow]=".76">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-accountStatus"
                                type="text"
                                placeholder="Filter Status"
                                [(ngModel)]="flags.filter.reactReq.accountStatus"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'accountStatus', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Account Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span>{{row.accountStatus}}</span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Projects"            prop="projectShortName"   [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-projectShortName"
                                type="text"
                                placeholder="Filter Project"
                                [(ngModel)]="flags.filter.reactReq.projectShortName"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'projectShortName', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Projects</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <a (click)="goToPjEdit(value)"
                        id="{{row.projectShortName}}"
                        class="text-primary1 wordWrap"
                        >
                        {{value}}
                     </a>
                     <!-- <a
                        (click)="initProjectAdminEditForm('Project Administration', row.projectShortName, 'edit')"
                        id="{{row.projectShortName}}"
                        class="text-primary1 wordWrap"
                        style="cursor: pointer">
                           <font class="projName">{{value}}</font>
                     </a> -->
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Project Membership Status" prop="memberStatus" [flexGrow]="1.00">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-memberStatus"
                                type="text"
                                placeholder="Filter Membership"
                                [(ngModel)]="flags.filter.reactReq.memberStatus"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'memberStatus', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Membership Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span class="wrapText">{{row.memberStatus}}</span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Affiliation"         prop="institution"        [flexGrow]=".8">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-institution"
                                type="text"
                                placeholder="Filter Affiliation"
                                [(ngModel)]="flags.filter.reactReq.institution"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'institution', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Affiliation</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <ul class="list-unstyled" *ngFor="let affiliation of row.institutions">
                        <li [ngClass]="{'institution-primary': affiliation.primary}" class="col1 affiliation wordWrap" [textContent]="affiliation.name">
                     </ul>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="US Citizen"          prop="usCitizen"          [flexGrow]=".6">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-usCitizen"
                                type="text"
                                placeholder="Filter Citizen"
                                [(ngModel)]="flags.filter.reactReq.usCitizen"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'usCitizen', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>US Citizen</b></span>
                  </ng-template>
                  <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                     <span>
                        <i *ngIf="row.usCitizen"  class="fas fa-check checkmark"></i>
                        <i *ngIf="!row.usCitizen" class="fas fa-times removeIcon"></i>
                     </span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="593 Expiration Date" prop="expirationDate593"  [flexGrow]=".8">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-expirationDate593"
                                type="text"
                                placeholder="Filter Exp"
                                [(ngModel)]="flags.filter.reactReq.expirationDate593"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'expirationDate593', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>593 Expiration Date</b></span>
                  </ng-template>
                  <ng-template let-row="row" value="value" ngx-datatable-cell-template>
                     <span>{{ convertUTCtoCDT(row.expirationDate593) }}</span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="593 Status"          prop="status593"          [flexGrow]=".6">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-status593"
                                type="text"
                                placeholder="Filter Status"
                                [(ngModel)]="flags.filter.reactReq.status593"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'status593', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>593 Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <span>{{ convertUTCtoCDT(row.status593) }}</span>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Comments"            prop="comment"            [flexGrow]=".75">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                     <div>
                        <input  id="reactReq-filter-comment"
                                type="text"
                                placeholder="Filter Comments"
                                [(ngModel)]="flags.filter.reactReq.comment"
                                (keyup)="updateFilter('reactReq', lists.reactivationRequests, 'comment', 'and')"
                        >
                     </div>
                     <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Comment</b></span>
                  </ng-template>
                  <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                     <div class="comment-column">
                        <span class="floating-label-wrapper">
                           <textarea   autosize rows="1"
                                       id="react-comment-{{row.userName}}"
                                       type="text"
                                       class="form-control autosize"
                                       placeholder="Comment"
                                       [(ngModel)]="row.comment"
                                       (focus)="autosizeFn('react-comment-'+row.userName)"
                                       (blur)="getSmall('react-comment-'+row.userName)"
                                       [ngClass]="{'normal-size': !flags.isOnFocus}"
                                       (keyup)="domainAdminSelectOption(row.comment, rowIndex, 'admReactRequest','comment', row)"
                                       style="width:100%;">
                           </textarea>
                        </span>
                     </div>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column name="Reactivation Status"                           [flexGrow]=".8">
                     <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Reactivation Status</b></span>
                     </ng-template>
                     <ng-template let-row="row" value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <div class="action">
                           <select  class="form-control dropdownOption"
                                    [(ngModel)]="row.ms_state_change"
                                    id="reactStatus-{{row.userName}}"
                                    ngDefaultControl
                                    name="ms_state_change"
                                    (change)="domainAdminSelectOption(row.ms_state_change, rowIndex, 'admReactRequest','ms_state')">
                              <option value="Requested">Requested</option>
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                           </select>
                           <button
                              *ngIf="flags.index == rowIndex && flags.tableSelected === 'admReactRequest' && flags.showSubmit"
                              id="submitBtnReactReq"
                              class="btn btn-primary btn-md"
                              (click)="adminRequestAction('reactivationRequest', flags.ms_state,  row.accountId, rowIndex)">
                              Submit
                           </button>
                        </div>
                  </ng-template>
               </ngx-datatable-column>
            </ngx-datatable>
          </div>
       </div>

</div>

 <div class="dashboard edit-record-wrapper" *ngIf="flags.editRecord">
    <domain-admin-choose-acct-cat *ngIf="!flags.account_cat_selected"></domain-admin-choose-acct-cat>
 </div>

 <div id="loading-overlay" *ngIf="lists.loadingUserRequests">
    <div class="spinner"></div>
    <br />
    Loading Requests...
 </div>

 <br>
 <a (click)="goToTop()" style="cursor:pointer">Top of page</a>
