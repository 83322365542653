var institutionModule = (function(){
    "use strict";
    var publicStuff;
    var alertError = {type : 'danger'};
    var alertSuccess = {type : 'success'};
    var alerts;
    var cookies , http , httpLegacy , scope , timeout , win;
            cookies = http = httpLegacy = scope = timeout = win = null;  // cached services
    var jsServices = {};
    var comMod = null;
    var valMod = null;
    publicStuff ={
        init        : init,
        adminSearchInstitutions : adminSearchInstitutions,
        selectInstitutions : selectInstitutions,
        validate    : validate,
        selectOption : selectOption,
        requestAction : requestAction,
        setServices  : setServices
    };

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        httpLegacy    = jsServices.http;
        http    = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;

        comMod = jsServices.commonModule;
        valMod = jsServices.validationModule;
    }

    // http : the http service. When unit testing, it is the mock http service
    // scope.lists: needed lists by the UI, for example: projects, institutions
    // scope.flags: needed by the UI to know what options to show to the user
    function init(scope, http, pageName, q){
        scope.domainAdminDashboardAlerts = [];
        alerts = scope.domainAdminDashboardAlerts;
        comMod.getSetContext(scope, pageName, function(){
            scope.flags = {
                displayedPageName: pageName,
                fullWidth : true,
                domainAdmin: true,
                showInstitutionAdminDashboard : true,
                matchedInstitution : null,
                searchedInstitution: null,
                submit : null,
                showSubmit : null,
                missing : null,
                index : null,
                garbagefrominstmod : 12,
                requestType : null,
                delta : false,
                bootstrapAlert : 'getInstitutionsError',
            };
            scope.form = {};
            scope.adminAlerts = [];
            if (!scope.lists){
                scope.lists = {};
            }
        });
    }

     function adminSearchInstitutions(http, timeout, flags, form, lists, searchInstitutions, alerts){

        if(searchInstitutions.length < 2){
            flags.searchCompleted = false;
            flags.tooBig = false;

        } else{
            comMod.onStopCalling(1000, function(){
                var params = {params : {searchInstitutions : searchInstitutions}};
                lists.loadingInstitutions = true;
            
                http.get("/admin/searchInstitutions", params)
                    .then(function(response){
                        var resp = response.data
                        if(resp.success){
                            flags.matchedInstitution = resp.match;
                            //var matching = searchInstitutions;
                            //var len = resp.institutionRecords.length;

                            //flags.matchedInstitution = resp.institutionRecords;
                            //for(var i = 0; i < len; i++){
                               //if(resp.institutionRecords[i].name.match(matching)){
                                 //   flags.matchedInstitution = resp.institutionRecords[i].name;
                              // }

                          //  }

                            flags.searchCompleted = resp.success;
                            var arrayOfInstitutions = resp.institutionRecords;
                            if(arrayOfInstitutions.length < 100){
                                lists.institutionRecords = arrayOfInstitutions;
                                lists.originalInstitutions = comMod.objCopy(arrayOfInstitutions);
                                flags.refreshSearch = searchInstitutions
                            } else{
                                // when the wildcard search is too big, then it will search for whole-word match
                                var object = selectInstitutions(searchInstitutions, arrayOfInstitutions, 100);
                                if(!object.tooMany){
                                    lists.institutionRecords = object.results;
                                    flags.refreshSearch = searchInstitutions;
                                    lists.originalInstitutions = comMod.objCopy(arrayOfInstitutions);
                                } else{
                                    flags.tooBig = true;
                                    alerts.push({
                                        type : 'info',
                                        msg : 'Too many records to display, please continue typing'
                                    });
                                }

                            }

                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                        lists.loadingInstitutions = false;

                    }).catch(function(response, status){
                        alertError.msg = response.data;
                        alerts.push(alertError);
                        lists.loadingInstitutions = false;
                    })//.finally(function(){
                //});
            });
        }

    }


    function selectInstitutions(str, institutions, max){
        var s, results, eResults, obj, i, p, ret, u, match;
        var maxRows = 300;
        if (max) maxRows = max;
        var show = false;

        obj = {tooMany: false, found: null, results: null};
        if (!str || (str.length < 2)) {
            return obj;
        }
        if (!institutions){
            return obj;
        }
        s = str.toLowerCase();
        results = [];

        for (i in institutions){
            u = institutions[i];
            show = false;
            if (u.name.toLowerCase().indexOf(s) > -1){
                show = true;
            }
            if (show){
                results.push(u);
            }
        }
        eResults = [];
        if (results.length > maxRows){
            // found too many,  now search by exact word
            for (i in institutions){
                u = institutions[i];
                show = false;
                if (u.name.toLowerCase() == s){
                    show = true;
                }
                if (show){
                    eResults.push(u);
                }
            }
        }
        if (eResults.length > 0){
            results = eResults;
        }
        if (results.length < maxRows){
            obj.results = results;
        }else{
            obj.tooMany = true;
            obj.found = results.length;
            obj.results = results.slice(0,maxRows);
        }
        return obj;
    }



    function selectOption(action, lists, flags, index, tableName, type, alert){
        if(!action && ['comment', 'mua_expiration_date','mua_start_date'].indexOf(type) < 0){
            flags.showSubmit = false;
            flags.tableSelected = null;
            flags.index = 0;
            return;
        } else{
            flags.searchCompleted = false;
            flags.tableSelected = tableName;
            flags.showSubmit = false;
            flags.bootstrapAlert = tableName;
            if(type){
                flags.showSubmit = true;
                flags.index = index;
                alert.shift();
            }
            if(type === 'ms_state'){
                flags.ms_state = action;

            } else if(type === 'mua_agreement_category'){
                flags.mua_agreement_category = action;

            } else if(type === 'mua_ms_state'){
                flags.mua_ms_state = action;
            }
        }
    }

    function requestAction(http, cookies, flags, lists, requestType, ms_state, id, index, alerts, q){
        lists.loadingInstitutions = true;
        var position = window.pageYOffset || document.documentElement.scrollTop;
         var plural = requestType + 's',
            type = '', action = '', apiType = '', p = {}, apiType = '/svc/',
            name = lists[plural][index].name, method = 'POST',
            mua_agreement_category = 'Not Specified', mua_ms_state = 'Inactive',
            errorFlag = false;
        if(ms_state && id){
            var submitRequest = {};
            var searchId = 'id=' + id;
            var requestType = 'Institution';
            submitRequest = {status: ms_state};
            p = {form : submitRequest, search : searchId};
            http.put(apiType + requestType, p)
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        delete flags.ms_state;
                        alerts.push({
                            msg : 'Institution status for ' + name + ' has been set to Updated',
                            type : 'success'
                        });
                    } else {
                        alerts.push({
                            msg : 'Institution error ' + resp.error,
                            type : 'danger'
                        });
                    }
                }).catch(function(response, status){
                    alerts.push({
                        msg : response.data,
                        type : 'danger'
                    });
                });
        }
        if(lists[plural][index].mua_start_date || lists[plural][index].mua_expiration_date ||
            lists[plural][index].comment || lists[plural][index].mua_id ||
            lists[plural][index].mua_agreement_category || lists[plural][index].mua_ms_state){
            var submitRequest = {};
            submitRequest.mua_start_date =      lists[plural][index].mua_start_date;
            submitRequest.mua_expiration_date = lists[plural][index].mua_expiration_date;
            if(!submitRequest.mua_start_date){
                submitRequest.mua_start_date = '';
            }
            if(!submitRequest.mua_expiration_date){
                submitRequest.mua_expiration_date = '';
            }
            if(submitRequest.mua_start_date && submitRequest.mua_expiration_date){
                if(submitRequest.mua_start_date > submitRequest.mua_expiration_date){
                    alerts.push({
                        msg : "Start date cannot be greater than end date",
                        type : 'danger'
                    });
                    errorFlag = true;
                }
            }
            if(!submitRequest.mua_start_date && submitRequest.mua_expiration_date){
                alerts.push({
                    msg : "Start date cannot be null",
                    type : 'danger'
                });
                errorFlag = true;
            }
            submitRequest.institution_id = lists[plural][index].id;
            submitRequest.comment = lists[plural][index].comment;
            if(lists[plural][index].mua_id !== null){
                id = lists[plural][index].mua_id;
                method = 'PUT'
            }
            if(lists[plural][index].mua_agreement_category !== null){
                submitRequest.mua_agreement_category = lists[plural][index].mua_agreement_category;
            }else{
                submitRequest.mua_agreement_category = mua_agreement_category;
            }
            if(lists[plural][index].mua_ms_state !== null){
                submitRequest.member_status = lists[plural][index].mua_ms_state;
            }else{
                submitRequest.member_status = mua_ms_state;
            }
            if(!errorFlag){
                requestType = 'MUAInstitution';
                var p = {form : submitRequest};
                if(method === 'POST'){
                    http.post(apiType + requestType, p)
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                var postSuccessAlert = {
                                    msg : 'MUA status for ' + name + ' has been set to Created',
                                    type : 'success'
                                };
                                remDuplAndPush(alerts, postSuccessAlert);

                            } else {
                                var postErrAlert = {
                                    msg : 'MUA Creation error ' + resp.error,
                                    type : 'danger'
                                };
                                remDuplAndPush(alerts, postErrAlert);
                            }
                        }).catch(function(response, status){
                            var postErrorAlert = {
                                msg : response.data,
                                type : 'danger'
                            };
                            remDuplAndPush(alerts, postErrorAlert);
                        });
                } else if(method === 'PUT') {
                    p.search = 'id=' + id
                    http.put(apiType + requestType, p)
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                var putSuccessAlert = {
                                    msg : 'MUA status for ' + name + ' has been set to Updated',
                                    type : 'success'
                                };
                                remDuplAndPush(alerts, putSuccessAlert);
                            } else{
                                var putErrAlert = {
                                    msg : 'MUA Updation error ' + resp.error,
                                    type : 'danger'
                                };
                                remDuplAndPush(alerts, putErrAlert);
                            }
                        }).catch(function(response, status){
                        var putErrorAlert = {
                            msg : response.data,
                            type : 'danger'
                        };
                        remDuplAndPush(alerts, putErrorAlert);
                    });
                }
            }
        }
        delete flags.ms_state;
        errorFlag = false;
        adminSearchInstitutions(http, timeout, flags, false, lists, flags.refreshSearch, alerts);
        flags.showSubmit = null;
        lists.loadingInstitutions = false;
    }

function remDuplAndPush(alerts, newAlert){
    var hasDuplMsg = false;
    if(alerts && alerts.length > 0){
        hasDuplMsg = alerts.filter(function(item){
            return(item.msg === newAlert.msg);
        }).length > 0;
    }
    if(!hasDuplMsg){alerts.push(newAlert);}
}

    function validate(http, timeout, form, name, flags, alerts, scope){
        flags.fieldErrors = {};
        flags.submit = false;
        flags.delta = false;
        flags.okToSubmit = false;

        valMod.validate('institutions', form, flags.fieldErrors);

        if(!flags.fieldErrors.searchInstitutions){
            flags.searchInstitutions = true;

        } else{
            flags.searchInstitutions = false;
        }
        flags.missing = valMod.computeMissing(flags.fieldErrors);
        flags.okToSubmit = !flags.missing;
        // now see if there are any changes to be saved
        flags.delta = false;
     }

    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = {institutionModule}
