// User Admin Module

var domainAdminModule =
    (function(){
            "use strict";
            var publicStuff;
            var alertError = {type : 'danger'};
            var alertSuccess = {type : 'success'};
            var cookies, httpLegacy, http, scope, timeout, win, form, lists, flags, alerts, comMod, valMod, dayjs,
                edRecMod, otherAccounts;
            var cookies = httpLegacy = http = scope = timeout = win = form = lists = flags = alerts = comMod = dayjs = edRecMod = otherAccounts;
            var jsServices = {};

            publicStuff = {
                init : init,
                validate : validate,
                requestAction : requestAction,
                adminSearchUser : adminSearchUser,
                editRecord : editRecord,
                saveRecord : saveRecord,
                selectOption : selectOption,
                setServices : setServices,
                projectOption : projectOption,
                editResources : editResources,
                unixGroupList : unixGroupList,
                netGroupList : netGroupList,
                userHasRequest : userHasRequest,
                addOtherAccount : addOtherAccount,
                editOtherAccount : editOtherAccount,
                allocationRequestAction : allocationRequestAction,
                allocationRequestSubmit : allocationRequestSubmit,
                projectRequestAction : projectRequestAction,
                projectRequestSubmit : projectRequestSubmit,
                showAllocation : showAllocation,
                celsAllocationInfo : celsAllocationInfo,
                createUserAccount : createUserAccount,
                accountProjects : accountProjects,
                isDeletedStatus : isDeletedStatus,
                selectAllToApprove : selectAllToApprove,
                selectAllocQuarter : selectAllocQuarter,
                selectAllocSystem : selectAllocSystem,
                projectList : projectList,
                membershipReminder : membershipReminder,
                createServiceEmail : createServiceEmail,
                getApproverId : getApproverId
            };

            function setServices(services){
                var i, serv;
                for(i in services){
                    serv = services[i];
                    jsServices[serv.sname] = serv;
                }
                cookies = jsServices.cookies;
                httpLegacy = jsServices.http;
                http = jsServices.httpNew;
                scope = jsServices.scope;
                timeout = jsServices.timeoutObj.timeout;
                win = jsServices.window;
                comMod = jsServices.commonModule;
                valMod = jsServices.validationModule;
                edRecMod = jsServices.editRecordModule;
                dayjs = jsServices.dayjs;
                otherAccounts = jsServices.otherAccountsModule;
            }

            function initScopeNames(scope){
                form = scope.form, flags = scope.flags, lists = scope.lists, alerts = scope.domainAdminDashboardAlerts;
                if(!scope.editUserView){
                    getRequests(http, lists, alerts, function(){
                        listOfProjectsRequestedIDs(lists);
                        allocationRequests(http, lists, alerts);
                    });
                }
            }

            function init(scope, pageName){
                comMod.explorerPage(false);
                scope.containerFluid = true;
                scope.allocationLimit = 20;
                scope.domainAdminDashboardAlerts = [];
                alerts = scope.domainAdminDashboardAlerts;
                scope.flags.editRecord = false;
                scope.lists.userRecords = [];

                comMod.getSetContext(scope, pageName, function(){
                    scope.flags = {
                        displayedPageName : pageName,
                        hideHomePage : true,
                        domainAdmin : true,
                        adminPageLevel : 4,
                        level : 4,
                        prevSavedForm : null,
                        fieldErrors : {},
                        delta : false,
                        editRecord : null,
                        submit : null,
                        altEmail : null,
                        showSubmit : null,
                        matchedName : null,
                        index : null,
                        requestType : null,
                        hideFormSection : null,
                        createOtherAccount : false,
                        missing : null,
                        viewDetails : null,
                        changedIdentity : false,
                        newServiceAcct : false,
                        admin_testing : false,
                        other_account_testing : false,
                        hideDeleteQA : false,
                        bootstrapAlert : 'getRequestsError',
                        showDomainAdminDashboard : true

                    };
                    scope.adminAlerts = [];
                    scope.form = {};

                    if(!scope.lists){
                        scope.lists = {};
                        scope.lists.prevSavedAllocationRequests = [];
                    }
                    if(!scope.sharedLists){
                        scope.sharedLists = {};
                    }

                    if(!scope.lists.countries){
                        comMod.getCountries(scope.lists);
                    }
                    getApiLists(http, scope.lists);
                });
                initScopeNames(scope);
                //
            }

            function validate(http, timeout, form, name, flags, alerts){
                flags.fieldErrors = {};
                flags.submit = false;
                flags.delta = false;
                flags.okToSubmit = false;
                valMod.validate('domainAdmin', form, flags.fieldErrors);
                if(!flags.fieldErrors.searchName){
                    flags.searchName = true;

                } else{
                    flags.searchName = false;
                }

                if(name === 'email'){
                    var e = flags.fieldErrors.email;
                    var validEmail = valMod.validEmailFormat(form.email);
                    if(!validEmail){
                        flags.fieldErrors.email = {};
                        flags.fieldErrors.email.error = "Enter a valid Email format";
                        form.firstName = form.lastName = form.preferredName = null;
                    }
                    if(validEmail){
                        // we have a good email format
                        comMod.onStopCalling(700, function(){
                            var p = {email : form.email};
                            flags.emailMatched = false;
                            http.get('/admin/searchEmail', {params : p})
                                .then(function(response){
                                    var resp = response.data;
                                    var errorMsg = 'Email Match Not Found';
                                    flags.changedIdentity = false;
                                    if(resp.success){
                                        if(resp.match){
                                            form.firstName = resp.data.firstName;
                                            form.lastName = resp.data.lastName;
                                            form.identity_id = resp.data.identity_id;
                                            form.preferredName = resp.data.preferredName;
                                            flags.emailMatched = resp.match;
                                            alertSuccess.msg = 'Email Match Found';
                                            alerts.push(alertSuccess);
                                            // call validate again as we now have required form fields from email identity
                                            timeout(function(){
                                                edRecMod.validateAccountUpdate(form, flags);
                                            }, 700);

                                        } else{
                                            form.firstName = form.lastName = form.preferredName = null;
                                            flags.emailMatched = resp.match;
                                            alertError.msg = errorMsg;
                                            alerts.push(alertError);

                                        }

                                    }
                                }).catch(function(response, status){
                                alertError.msg = response.data.detail;
                                alerts.push(alertError);
                            });
                        });
                    }
                } else{
                    flags.missing = valMod.computeMissing(flags.fieldErrors);
                    flags.okToSubmit = !flags.missing;
                    flags.delta = false;
                }

            }

            function getRequests(http, lists, alerts, callback){
                if(!lists){lists = {}}
                lists.userRecords = [];
                lists.pageRefresh = 'Loading Requests...';
                var allUserRequests = ['projects', 'projectMembership', 'unixGroup'];
                var promises = allUserRequests.map(function(apiName){
                    return http.get("/admin/" + apiName + "Requests");
                });
                return Promise.all(promises)
                    .then(function(data){
                        data.forEach(function(response){
                            var resp = response.data;
                            if(resp.success){
                                Object.keys(resp).forEach(function(key){
                                    // make list of what tables have data OR don't
                                    if(Array.isArray(resp[key]) && lists.requestData){
                                        if(resp[key].length){lists.requestData[key] = true} else{lists.requestData[key] = false}
                                    }
                                    lists[key] = resp[key];
                                    if(resp.unixGroupRequests || resp.projectMembershipRequests){
                                        addFilterName(lists[key]);
                                        lists.pageRefresh = false;
                                    }

                                });
                            } else{
                                alertError.msg = resp.error + ' on api ' + response.config.url;
                                alerts.push(alertError);
                            }
                        });
                        lists.domainAdminData = true;
                    }).catch(function(response, status){
                        lists.domainAdminData = false;
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    }).finally(function(){
                        comMod.clearFilterCache();
                        if(callback){
                            callback();
                        }
                    });

            }

            function addFilterName(arr){
                Object.keys(arr).forEach(function(k){
                    arr[k].filterName = arr[k].preferredName.concat(arr[k].firstName, arr[k].middleName, arr[k].lastName);

                    if(Array.isArray(arr[k].institutions)){
                        //
                        var inst = '';
                        arr[k].institutions.forEach(function(dict){
                            inst += inst.concat(dict.name + ',');
                        });
                        arr[k].filterAffiliation = inst;
                    }
                    if(Array.isArray(arr[k].projectShortNameList)){
                        var sn = '';
                        arr[k].projectShortNameList.forEach(function(name){sn += name + ',';});
                        arr[k].filterProjectShortNameList = sn;
                    }
                })

            }

            function allocationRequests(http, lists, alerts, cb){
                var d = {};
                var completeAllocationList = [];
                lists.allocationRequests = [];
                lists.prevSavedAllocationRequests = [];
                lists.pageRefresh = 'Loading Allocation Requests...';
                http.get("admin/allocationsRequests")
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            if(lists['requestData']){
                                if(resp.allocationsRequests.length){lists['requestData']['allocationsRequests'] = true}
                                else {lists['requestData']['allocationsRequests'] = false}
                            }
                            Object.keys(resp.allocationsRequests).forEach(function(i){
                                d = resp.allocationsRequests[i];
                                // we do not show display allocation requests for requested projects.
                                if(d.status === "Requested" && !requestedProjectIdMatch(lists.projectsRequestedIDs, d.project_id)){
                                    // Add Business Quarter
                                    getFiscalQuarter(d, function(quarter){
                                        d.cels_quarter = quarter;
                                        if(!d.amount_granted){d.amount_granted = d.amount_requested;}
                                        completeAllocationList.push(resp.allocationsRequests[i]);
                                    })
                                }
                            });

                        } else{
                               if(resp.error.error.detail == 'Please login.  It appears your session has timed out.'){
                                    location.assign('/login');
                                }
                                else{
                                   alertError.msg = resp.error;
                                   alerts.push(alertError);
                               }
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                    lists.allocationRequests = JSON.parse(JSON.stringify(completeAllocationList));
                    lists.prevSavedAllocationRequests = JSON.parse(JSON.stringify(lists.allocationRequests));
                    lists.pageRefresh = false;
                    if(cb){cb(true)}
                });
            }

            function getFiscalQuarter(d, callback){
                // need to handle one object at a time.
                // page views displayed information differ based on status
                if(!d.start_date){return}
                var year = d.start_date.slice(0, 4);
                var qtrAndYear;
                // CELS quarter 1 = October 1-December 31, not standard Jan 1st.
                var cels_qtr = dayjs.utc(d.start_date).quarter() + 1;
                if(cels_qtr === 5){cels_qtr = 1;year = parseInt(year) + 1}
                qtrAndYear = "Q" + cels_qtr + " " + year;
                callback(qtrAndYear);
            }

            function celsAllocationInfo(allocationRequest, callback){
                Object.keys(allocationRequest).forEach(function(dict){
                    var d = allocationRequest[dict];
                    getFiscalQuarter(d, function(quarter){d.cels_quarter = quarter;});
                });
                callback(allocationRequest);
            }

            function requestedProjectIdMatch(arr, element){
                if(arr){
                    for(var i = 0 ; i < arr.length ; i++){
                        if(arr[i] === element){
                            return true;
                        }
                    }
                }
                return false;
            }

            function listOfProjectsRequestedIDs(lists){
                if(!lists){return}
                lists.projectsRequestedIDs = [];
                lists.prevSavedProjectsRequests = [];
                if(lists.projectsRequests){
                    Object.keys(lists.projectsRequests).forEach(function(key){
                        // make list of requested project ids
                        // these will not be displayed in allocations table
                        lists.projectsRequestedIDs.push(lists.projectsRequests[key].id);
                    });
                }
                comMod.objCopyGuts(lists.projectsRequests, lists.prevSavedProjectsRequests);

            }

            function unixGroupList(http, lists, alerts){
                var p = {search : "%"};
                http.get("/svc/UnixGroup", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            lists.allUnixGroups = resp.data;
                            resp.data.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function projectList(http, lists, alerts){
                var p = {search : "%"};
                http.get("/svc/Project", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            lists.allProjects = resp.data;
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function projectMembership(http, lists, alerts, name, callback){
                var searchString = 'username=' + name;
                var p = {search : searchString};
                http.get("/svc/ProjectAccount", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            callback(resp);
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function adminSearchUser(http, timeout, flags, form, lists, searchName, alerts){
                comMod.onStopCalling(100, function(){
                    if(!searchName || searchName.length < 2){
                        flags.searchCompleted = false;
                        flags.tooBig = false;
                        lists.userRecords = [];
                        return;
                    }
                    flags.showSubmit = false;
                    http.get("/admin/searchUser", {params : {searchName : searchName}})
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                flags.matchedName = resp.match;
                                flags.searchCompleted = resp.success;
                                var arrayOfAccounts = JSON.parse(JSON.stringify(resp.userRecords));
                                if(arrayOfAccounts.length < 100){
                                    lists.userRecords = arrayOfAccounts;
                                    flags.refreshSearch = searchName;

                                } else{
                                    // when the wildcard search is too big, then it will search for whole-word match
                                    var object = comMod.selectUsers(searchName, arrayOfAccounts, 100);
                                    if(!object.tooMany){
                                        lists.userRecords = object.results;
                                        flags.refreshSearch = searchName;

                                    } else{
                                        flags.tooBig = true;
                                        alerts.push({
                                            type : 'info',
                                            msg : 'Too many records to display, please continue typing'
                                        });
                                    }

                                }

                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);
                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    }).finally(function(){
                    });
                });

            }

            function editRecord(http, flags, form, lists, name, scope, cb){
                flags.delta = false;
                flags.emailMatched = false;
                flags.apiType = 'admin/';
                flags.fieldErrors = {};
                flags.arrayFieldErrors = {};
                flags.account_cat_selected = true;
                flags.hideDeleteQA = false;
                lists.userRecords = [];
                isPendingInstitution(lists);
                var accountProjects;
                flags.getApiNames = ['account', 'accountEmails', 'accountPhones', 'accountResources', 'accountSshPublicKeys'];
                // accountProjects is returned by SVC api

                projectMembership(http, lists, alerts, name, function(res){
                    accountProjects = res.data;
                    edRecMod.getRecord(http, flags, lists, name, function(response){
                        var resp = response.success;
                        if(resp){
                            flags.prevSavedForm = {};
                            flags.deletedMemberships = [];
                            response.accountProjects = accountProjects;
                            unixGroupsInherited(name, function(inherited){
                                if(response.accountResources['UnixGroup']){
                                    response.accountResources['UnixGroup'] = response.accountResources['UnixGroup'].concat(inherited)
                                }
                                if(!scope.form){scope.form = form}
                                comMod.objCopyGuts(response, scope.form);

                                // For Service and Test Accounts
                                if(scope.form.accountCategory !== "User"){
                                    if(scope.form.accountEmails.length === 2){
                                        scope.form.email = form.accountEmails[1].email;
                                    } else{
                                        scope.form.email = scope.form.accountEmails[0].email;
                                    }
                                }
                                flags.prevSavedForm = JSON.parse(JSON.stringify(scope.form));
                                if(scope.form.accountCategory === "Service" && !scope.form.serviceEmail){
                                    flags.userName = scope.form.userName;
                                    otherAccounts.mailingList(http, lists, alerts);
                                    otherAccounts.accountServiceEmail(http, lists, flags, scope.form, alerts, function(){
                                        if(!scope.form.serviceEmail){scope.form.serviceEmail = '';}
                                        comMod.objCopyGuts(scope.form, flags.prevSavedForm)
                                    });
                                }

                                if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){

                                    // made copy of data from apis, lets open form page
                                    flags.editRecord = true;
                                    //check for missing data
                                    edRecMod.validateAccountUpdate(scope.form, flags, true);
                                    // validateLists for each dict
                                    comMod.validateListsAfterGet(flags, scope.form);
                                    //Will now display "Required Field" on page for any missing required items.
                                    findDeletedMemberships(accountProjects);
                                    if(cb){cb(response)}

                                }

                                flags.disableInput = false;

                            });

                        } else{
                            if(cb){cb(response)}
                            flags.bootstrapAlert = 'editRecordError'
                            alertError.msg = response.error;
                            scope.domainAdminDashboardAlerts.push(alertError);
                        }

                    });
                });
            }
            function unixGroupsInherited(name, callback){
                http.get('admin/unixGroupsInherited', {params : {userName : name}})
                    .then(function(resp){
                        resp.data.unixgroups.forEach(function(dict){
                            dict.inherited = true;
                            dict.memberStatus = "Inherited"
                        });
                        callback(resp.data.unixgroups)
                    }).catch(function(response, status){
                });
            }

            function saveRecord(http, form, flags, lists, sharedLists, cookies, httpLegacy, scope, cb){
                //check what apis to call based on what changed
                var postMsg = '', putMsg = '';
                scope.adminAlerts = [];
                scope.domainAdminDashboardAlerts = [];
                flags.showDomainAdminDashboard = true;
                edRecMod.apisToCall(flags, form);
                if(flags.missing){
                    delete flags.missing
                }
                //keys with List
                if(flags.saveOption === 'cancel'){
                    flags.editRecord = false;
                    flags.createOtherAccount = false;
                    flags.newServiceAcct = false;
                    // lets get latest user requests for dashboard
                    refreshPageData(http, form, flags, lists, alerts);
                    return;
                }

                if(flags.saveOption === 'LDAPSync'){
                    var p = {search : 'user=' + form.userName};
                    http.get('/svc/LDAPSync', {params : p})
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                alertSuccess.msg = "Successfully LDAP Sync";
                                scope.adminAlerts.push(alertSuccess);
                            } else{
                                alertError.msg = response.data.error;
                                scope.domainAdminDashboardAlerts.push(alertError);
                                flags.bootstrapAlert = 'editRecordError';
                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                        flags.bootstrapAlert = 'editRecordError';
                    });
                    return;
                }

                if(flags.putsToCall.length){
                    // disable changing form until apis complete
                    flags.disableInput = true;
                    edRecMod.putRecord(http, form, flags, lists, cookies, function(response){
                        var resp = response.success;
                        if(resp){
                            // account api response always last for unknown reason, so did it this way so 'Account Info' always listed first in Message
                            if(flags.account){
                                putMsg = 'Account Info, ';
                            }
                            alertSuccess.msg = 'Successfully Updated ' + putMsg + flags.putsToCall;
                            scope.adminAlerts.push(alertSuccess);
                            if(cb){cb(resp)}
                        } else{
                            alertError.msg = response.error;
                            flags.disableInput = false;
                            scope.adminAlerts.push(alertError);
                        }
                        if(scope.adminAlerts.length > 0){
                            refreshPageData(http, form, flags, lists);
                        }
                    });
                    cleanUpName(flags.putsToCall);
                }
                if(flags.postsToCall.length){
                    edRecMod.postRecord(http, form, flags, lists, cookies, function(response){
                        var resp = response.success;
                        if(resp){
                            alertSuccess.msg = 'Successfully Added ' + flags.postsToCall;
                            scope.adminAlerts.push(alertSuccess);
                            refreshPageData(http, form, flags, lists);
                        } else{
                            alertError.msg = response.error;
                            scope.adminAlerts.push(alertError);
                        }

                    });
                    cleanUpName(flags.postsToCall);
                }

                // Done sending any KEYS with LIST
                // account not List so handled separate put function
                if(flags.account && flags.account.id){
                    flags.apiType = 'admin/';
                    if(form.accountCategory === "Service" || form.accountCategory === "Test"){
                        for(var key in flags.account){
                            if(key.endsWith('Name') && !key.endsWith('userName')) delete flags.account[key];
                            flags.okToSubmit = flags.delta = false;
                        }
                    }

                    if(flags.account.accountType === 'Annual'){
                        if(!form.deactivation_date){
                            alertError.msg = 'Please Select Deactivation Date';
                            scope.adminAlerts.push(alertError);
                            return;
                        } else{
                            flags.account.deactivation_date = form.deactivation_date;
                        }

                    } else if(flags.account.accountType === 'Permanent'){
                        flags.account.deactivation_date = null;
                    }
                    if(flags.account.accountResources){delete flags.account.accountResources}
                    edRecMod.putAccount(http, form, flags, lists, cookies, function(response){
                        var resp = response.success;
                        if(resp){
                            // check if certFavorHost was changed
                            if(flags.account.certFavorHost === false || flags.account.certFavorHost){
                                comMod.getCertifiedHosts(flags, sharedLists);
                            }

                            if(!flags.putsToCall.length){
                                // PUT message doesn't exist, create one

                                alertSuccess.msg = 'Successfully Updated Account Info';
                                scope.adminAlerts.push(alertSuccess);
                                if(cb){cb(resp)}
                                refreshPageData(http, form, flags, lists);
                            }

                            delete flags.account;

                        } else{
                            alertError.msg = 'Account ' + response.error;
                            scope.adminAlerts.push(alertError);
                            delete flags.account;
                        }
                    });
                }

            }

            function cleanUpName(arrayToCleanUp){
                for(var i = 0 ; i < arrayToCleanUp.length ; i++){
                    arrayToCleanUp[i] = arrayToCleanUp[i].replace('account', ' ');
                }
            }

            function refreshPageData(http, form, flags, lists){
                flags.bootstrapAlert = null;
                if(flags.saveOption === 'exit'){
                    lists.userRecords = [];
                    flags.editRecord = false;
                    flags.createOtherAccount = false;
                } else if(flags.saveOption === 'continue'){
                    //Refresh Data on Update Page if user clicks "Save and Continue"
                    editRecord(http, flags, form, lists, form.userName, {});
                }
                //Refresh Data on Main Page
                if(flags.saveOption === 'exit' || flags.saveOption === 'cancel'){
                    scope.adminAlerts = [];
                    if(flags.domainAdmin){
                        //getRequests(http, lists, alerts, function(){lists.pageRefresh = false;});
                    } else if(flags.otherAccountAdmin){
                        return;

                    }
                }

            }

            function editResources(http, cookies, flags, form, lists, id, type, scope){
                var msgName = '';
                var apiName = 'accountResources'
                // assumes UTC
                var date = dayjs();
                var cdata = {};
                var isPut = false;
                if(type !== 'Project'){
                    flags.apiType = 'admin/'
                    cdata.form = {resource_type : type, resource_type_id : id, status : 'Active'};
                    cdata.userName = form.userName;
                } else{
                    if(isDeletedStatus(id)){
                        // This membership already exists and is Deleted Status
                        // need to find the bridge id and add with PUT
                        form.accountProjects.forEach(function(elem){
                            if(elem.project_id === id){
                                var searchId = 'id=' + elem.id;
                                isPut = true;
                                var putObject = {
                                    account_id : form.id,
                                    member_status : "Active",
                                    project_id : id
                                };
                                var p = {form : putObject, search : searchId};
                                http.put('/svc/ProjectAccount', p)
                                    .then(function(response){
                                        if(response.data.success){
                                            alertSuccess.msg = 'Successfully added project ' + elem.project;
                                            scope.domainAdminDashboardAlerts.push(alertSuccess);
                                            editRecord(http, flags, form, lists, form.userName, scope);
                                        } else{
                                            alertError.msg = response.data.error;
                                            alerts.push(alertError);
                                        }
                                    }).catch(function(data){
                                    lists.errorEditRecord = data.detail;
                                }).finally(function(){
                                });
                            }
                        });
                    }

                    flags.apiType = 'svc/'
                    apiName = 'ProjectAccount'
                    cdata.form = {
                        account_id : form.id,
                        default_project : false,
                        member_status : "Active",
                        project_id : id,
                        project_role : "Member",
                        receive_reports : false,
                        requested_date : date,
                        status_date : date
                    };
                }

                if(type === 'UnixGroup'){
                    msgName = 'Unix Group';
                } else if(type === 'Project'){
                    if(isPut){return}
                    msgName = 'Project';
                } else{msgName = 'Net Group'}
                http.post(flags.apiType + apiName, cdata)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = 'Successfully added ' + msgName;
                            scope.adminAlerts.push(alertSuccess);
                            editRecord(http, flags, form, lists, form.userName, scope);

                        } else{
                            alertError.msg = msgName + ' ' + resp.error;
                            scope.adminAlerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    scope.adminAlerts.push(alertError);
                });

            }

            function editOtherAccount(http, cookies, flags, form, lists, cat, scope, option, cb){
                // we get identity from email, we dont send email back
                if(flags.account.email){delete flags.account.email}
                if(scope.domainAdminDashboardAlerts){
                    delete scope.domainAdminDashboardAlerts
                }
                if(scope.adminAlerts){
                    scope.adminAlerts = [];
                }
                var cdata = {form : {id : form.id}};
                var action = 'Updated'
                if(form.comment){
                    cdata.form.comment = form.comment;
                }
                if(cat === 'Service' && (form.serviceEmail || form.serviceEmail === null)){
                    cdata.form.serviceEmail = form.serviceEmail;
                    delete form.serviceEmail;
                }

                cdata.form.identity_id = form.identity_id;
                if(form.accountType){
                    cdata.form.accountType = form.accountType;
                    if(form.accountType === 'Annual'){
                        if(!form.deactivation_date){
                            alertError.msg = 'Please Select Deactivation Date';
                            scope.adminAlerts.push(alertError);
                           return;
                        } else{cdata.form.deactivation_date = form.deactivation_date}
                    } else{
                        cdata.form.deactivation_date = null;
                    }
                }
                http.put('admin/update' + cat + 'Account', cdata)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = 'Successfully ' + action + ' ' + cat + ' Account ' + form.userName;
                            // good to call other apis now
                            flags.saveOption = option;
                            if(cb){cb(resp)}
                            saveRecord(http, form, flags, lists, null, cookies, httpLegacy, scope, function(){
                                otherAccounts.getAllOtherAccounts(http, lists, scope.adminAlerts, function(){
                                    otherAccounts.serviceAccountEmails(http, lists, scope.adminAlerts);
                                });
                            });
                            flags.changedIdentity = true;
                        } else{
                            flags.error = resp.error;
                            alertError.msg = resp.error;
                            scope.adminAlerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response;
                    scope.adminAlerts.push(alertError);
                });
            }

            function addOtherAccount(http, cookies, flags, form, lists, cat, scope, cb){
                var cdata = {form : {}};
                scope.adminAlerts = [];
                cdata.form.userName = form.userName;
                if(form.accountType === 'Annual'){
                    cdata.form.deactivation_date = form.deactivation_date;
                }
                if(form.comment){
                    cdata.form.comment = form.comment;
                }
                if(cat === 'Service' || cat === 'Test'){
                    cdata.form.id = form.identity_id;

                }
                if(cat === 'Service' && form.serviceEmail){
                    cdata.form.serviceEmail = form.serviceEmail;
                }
                if(form.shell){
                    cdata.form.shell = form.shell;
                }

                http.post('admin/add' + cat + 'Account', cdata)
                    .then(function(response){
                        var resp = response.data;
                        var name = '';
                        if(form.userName){
                            name = form.userName;
                        } else{
                            name = form.email;
                        }
                        if(resp.success){
                            flags.emailMatched = false;
                            flags.newServiceAcct = true;
                            alertSuccess.msg = 'Successfully Added ' + cat + ' Account for ' + name;
                            scope.adminAlerts.push(alertSuccess);
                            otherAccounts.getAllOtherAccounts(http, lists, alerts, function(){
                                otherAccounts.serviceAccountEmails(http, lists, alerts);
                                timeout(function(){
                                    saveRecord(http, form, flags, lists, null, cookies, httpLegacy, scope)
                                    //scope.adminSaveRecord('cancel');
                                }, 2000);
                            });

                        } else{
                            alertError.msg = resp.error;
                            scope.adminAlerts.push(alertError);
                        }
                        window.scrollTo(0, 0);
                        if(cb){cb(resp)}

                    }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.adminAlerts.push(alertError);
                });

            }

            function selectOption(action, lists, flags, index, tableName, type, alert){
                if(!action){
                    flags.showSubmit = false;
                    flags.tableSelected = null;
                    flags.index = 0;
                    return;
                } else{
                    flags.searchCompleted = false;
                    flags.tableSelected = tableName;
                    flags.showSubmit = false;
                    flags.bootstrapAlert = tableName;

                    if(type){
                        flags.showSubmit = true;
                        flags.index = index;
                        alert.shift();
                    }
                    if(type === 'ms_state'){
                        flags.ms_state = action;
                    } else if(type === 'approver'){
                        flags.piApprover = JSON.parse(action);

                    }
                }
            }

            function getProxyUsername(name, lists){
                var piproxies = lists;
                if(typeof piproxies !== "undefined"){
                    for(var i = 0 ; i < piproxies.length ; i++){
                        for(var item in piproxies[i]){
                            if(piproxies[i][item] === name){
                                return piproxies[i].proxyUsername;
                            }
                        }

                    }

                }
            }

            function projectOption(type, action, lists, flags, sortedIndex, originalIndex, alerts){
                if(type === 'projectMembershipRequest'){flags.tableSelected = 'admProjectMembershipRequest';}
                if(type === 'unixGroup'){flags.tableSelected = 'unixGroupRequest'}
                if(type === 'projectRequest'){flags.tableSelected = 'admProjRequest'}
                if(!action){
                    flags.showSubmit = false;
                    flags.tableSelected = null;
                    flags.index = 0;
                    return;
                } else{
                    if(alerts){alerts.shift()}
                    if(flags.tableSelected === 'admProjectMembershipRequest' && lists.projectMembershipRequests.length && lists.projectMembershipRequests[originalIndex].approver_name){
                        flags.piApprover = {};
                        flags.piApprover.piProxy = lists.projectMembershipRequests[originalIndex].approver_name;

                    }

                    //flags.hideMsg = true;
                    flags.searchCompleted = false;
                    flags.showSubmit = true;
                    flags.index = sortedIndex;
                    flags.ms_state = action;

                }
            }

            function userHasRequest(userName, lists){
                // check all requests for username
                //es6
                //let names = [...lists.projectMembershipRequests, ...lists.unixGroupRequests, ...lists.allocationRequests, ...lists.unixGroupRequests];
                var names = lists.projectMembershipRequests.concat(lists.unixGroupRequests, lists.allocationRequests, lists.unixGroupRequests);
                if(typeof names !== "undefined"){
                    for(var i = 0 ; i < names.length ; i++){
                        for(var item in names[i]){
                            if(names[i][item] === userName){
                                return true
                            }
                        }
                    }
                }
            }

            function showAllocation(status, list){
                if(!list){return}
                if(typeof list !== "undefined"){
                    for(var i = 0 ; i < list.length ; i++){
                        for(var item in list[i]){
                            if(list[i][item] === status){
                                return true
                            }
                        }
                    }
                }
            }

            function isPendingInstitution(lists){
                var pending = lists.instActPend;
                if(typeof pending !== "undefined"){
                    for(var i = 0 ; i < pending.length ; i++){
                        for(var item in pending[i]){
                            if(pending[i][item] === 'Pending'){
                                var str = '**'
                                if(pending[i].name.includes(str)){
                                    return;
                                } else{
                                    pending[i].name = '** ' + pending[i].name;
                                }
                            }
                        }
                    }
                }
            }

            function requestAction(http, cookies, flags, lists, requestType, ms_state, id, index, alerts){
                var position = window.pageYOffset || document.documentElement.scrollTop;
                var plural = requestType + 's', name = lists[plural][index].preferredName,
                    type = '', action = '', submitRequest = {id : id}, apiType = '', p = {};

                if(!ms_state && requestType === 'projectMembershipRequest'){
                    return;
                }
                if(ms_state){
                    submitRequest.status = ms_state;
                }
                if(lists[plural][index].comment !== null){
                    submitRequest.comment = lists[plural][index].comment;
                }
                if(lists[plural][index].deactivation_date !== null && lists[plural][index].accountType !== 'Permanent'){
                    submitRequest.deactivation_date = lists[plural][index].deactivation_date;

                }
                if(lists[plural][index].deactivation_date === null && lists[plural][index].accountType !== 'Permanent' && plural === 'userRecords'){
                    alertError.msg = 'Please Select Deactivation Date';
                    alerts.push(alertError);
                    return;
                } else if(lists[plural][index].deactivation_date !== null && lists[plural][index].accountType === 'Permanent'){
                    submitRequest.deactivation_date = null;
                } else if(requestType === 'projectMembershipRequest'){
                    flags.bootstrapAlert = 'admProjectMembershipRequest';
                    if(lists[plural][index].approver_name){
                        submitRequest.approver_name = getProxyUsername(lists[plural][index].approver_name, lists[plural][index].piproxies);
                    }
                    if(flags.piApprover){
                        submitRequest.approver_name = getProxyUsername(flags.piApprover.piProxy, lists[plural][index].piproxies);
                        delete flags.piApprover;
                    }

                    if((ms_state === 'Approved' || ms_state === 'Rejected') && !submitRequest.approver_name){
                        submitRequest.approver_name = scope.user.userName;
                    }

                } else if(requestType === 'unixGroupRequest'){
                    flags.bootstrapAlert = 'unixGroupRequest';
                    requestType = 'manageUnixGroups';
                    // approver will be the admin changing the status
                    if(submitRequest.status){
                        submitRequest.memberStatus = submitRequest.status;
                        submitRequest.approver_id = flags.domainAdmin_approver_id;
                        delete submitRequest.status;
                    }
                    //  }

                } else{
                    submitRequest.accountType = lists[plural][index].accountType;
                }
                // NO SYSTEM REQUEST FOR CELS ON THIS PAGE
                p.form = [submitRequest];
                http.put('/admin/' + requestType, p)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            delete flags.ms_state;
                            if(requestType === 'accountRequest'){
                                type = 'Account';
                            } else if(requestType === 'projectMembershipRequest'){
                                type = lists[plural][index].projectShortName + ' project membership';
                            } else if(requestType === 'unixGroupRequest'){
                                type = lists[plural][index].resourceName + ' unix group membership';
                            } else if(requestType === 'userRecord'){
                                type = 'User Record';
                                adminSearchUser(http, timeout, flags, false, lists, flags.refreshSearch, alerts)
                            } else if(requestType === 'AccountSystem'){
                                type = 'System Request ';
                                name = lists[plural][index].username;
                            }
                            if(ms_state){
                                action = ms_state;
                            } else{
                                action = "Updated";
                            }
                            alertSuccess.msg = type + ' status for ' + name + ' has been set to ' + action;
                            alerts.push(alertSuccess);

                        } else{
                            alertError.msg = 'Membership ' + resp.error;
                            alerts.push(alertError);
                        }

                        getRequests(http, lists, alerts, function(){
                            timeout(function(){
                                // page has been repainted with new data, set to previous scroll position
                                window.scrollTo(0, position);
                            }, 300);
                        });

                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });

            }

            function getApiLists(http, lists){
                if(!lists.usaStates){
                    lists.usaStates = comMod.getUsaStates();
                }

                if(!lists.careerLevels){
                    comMod.careerLevels(lists);
                }

                if(!lists.instActPend){
                    comMod.getInstActPend(lists);
                }

                if(!lists.allUnixGroups){
                    unixGroupList(http, lists, alerts);
                }

                if(!lists.allNetGroups){
                    netGroupList(http, lists, alerts);
                }
                if(!lists.allProjects){
                    projectList(http, lists, alerts);
                }

            }

            function netGroupList(http, lists, alerts){
                var p = {search : "%"};
                http.get("/svc/NetGroup", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            resp.data.sort(function(a, b){
                                return a.name.localeCompare(b.name);
                            });
                            lists.allNetGroups = resp.data;
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function allocationRequestAction(flags, lists, type, action){
                scope.adminAlerts = [];
                var lastSavedList = [], allocationChanges;
                flags.tableSelected = 'admAllocRequest';
                if((isNaN(action) || !action && action !== '0') && type === 'amount'){
                    action = "0";
                    alertError.msg = 'Please enter a number';
                    scope.adminAlerts.push(alertError);
                    flags.allocationChanges = false;
                    return action
                }

                // Only compare list of IDs that changed
                var previousStatus = lists.prevSavedAllocationRequests.filter(function(o1){
                    return lists.allocationRequests.some(function(o2){
                        // return the ones with equal id
                        return o1.id === o2.id;
                    });
                });
                if(type === 'status'){lists.allocationRequests.status = '';}
                lastSavedList = JSON.parse(JSON.stringify(lists.allocationRequests));
                allocationChanges = edRecMod.getFormChanges(lastSavedList, previousStatus);
                flags.allocationChanges = allocationChanges.filter(Boolean);
            }

            async function allocationRequestSubmit(flags, lists, action, alerts, cb){
                if(alerts){scope.domainAdminDashboardAlerts = alerts}
                if(flags.allocationChanges.length){
                    var allChangesArray = flags.allocationChanges;
                    var count = 0;
                    var numberOfAllocToSubmit = flags.allocationChanges.length
                    lists.pageRefresh = true
                    document.getElementById('page-loading').classList.add("active");
                    // must be for loop for async
                    for (const dict of allChangesArray) {
                        var searchId = 'id=' + dict.id;
                        var finDict = {approver: scope.user.userName};
                        if(dict.status) {
                            finDict.status = dict.status;
                            if(!dict.amount_granted) {
                                // user did not make change to granted amt since last GET
                                // get the last amount saved from the request
                                // if requested = granted means we have no granted amt saved in DB
                                // either way, granted amt submitted with approval will be same amt that user sees on the screen
                                getAllocationRequestAmount(lists.allocationRequests, dict.id, (resp) =>{
                                    finDict.amount_granted = resp;
                                });
                            }
                        }
                        if(dict.amount_granted) {finDict.amount_granted = dict.amount_granted}
                        if(dict.amount_requested) {finDict.amount_requested = dict.amount_requested}
                        if(dict.reason) {finDict.reason = dict.reason}
                        var p = {form: finDict, search: searchId};
                        await promiseFulfilledAlloc('/svc/Allocation', p).then((response) => {
                            if(response.data.success) {
                                count += 1;
                                if(scope.domainAdminDashboardAlerts.length) {scope.domainAdminDashboardAlerts = []}
                                if(lists.pageRefresh) {alertSuccess.msg = 'Approved ' + count + ' allocations...'}
                                scope.domainAdminDashboardAlerts.push(alertSuccess);
                                flags.bootstrapAlert = 'allocationRequests';
                                flags.allocationChanges = false;
                            } else{
                                alertError.msg = response.data.error;
                                alerts.push(alertError);
                            }
                        }).catch(function (data) {
                            lists.errorEditRecord = data.detail;
                        }).finally(function(){
                            // most important is here

                            if(count == allChangesArray.length) {
                                document.getElementById('page-loading').classList.remove("active");
                                alertSuccess.msg = 'Successfully Updated ' + numberOfAllocToSubmit + ' Allocation Requests';
                                comMod.clearFilterCache();
                                allocationRequests(http, lists, alerts, function(res){
                                    // let page know we have new data and we can set filter again.
                                    if(cb){cb(res)}
                                });
                                flags.hideApproveAllBtn = false;
                            }
                        });
                    }
                }
            }

            function promiseFulfilledAlloc(apiName, paramsToSend) {
                return new Promise(resolve => {
                    var allocApproval = http.put(apiName, paramsToSend);
                    resolve(allocApproval);
                });
            }
            function filterPreSavedProjRequests (prevSavedForm, finalSavedForm){
                // get previous state of current filtered projects
                let filterPreSavedProjRequests = prevSavedForm.filter(o1 => finalSavedForm.some(o2 => o1.id === o2.id));
                return filterPreSavedProjRequests;
            }

            function getAllocationRequestAmount(list, id, callback){
                Object.keys(list).forEach(function(dict){
                    if(list[dict].id === id){
                        callback(list[dict].amount_granted);
                    }
                });
            }

            function projectRequestAction(lists, flags){
                scope.adminAlerts = [];
                var lastSavedList = [], projectRequestChanges;
                if(lists){scope.lists = lists}
                if(flags){scope.flags = flags}
                comMod.objCopyGuts(scope.lists.projectsRequests, lastSavedList);
                projectRequestChanges = edRecMod.getFormChanges(lastSavedList, filterPreSavedProjRequests (lists.prevSavedProjectsRequests, lastSavedList));
                scope.flags.projectRequestChanges = projectRequestChanges.filter(Boolean);
            }

            function projectRequestSubmit(action, alert, cb){
                if(alert){scope.domainAdminDashboardAlerts = alert}
                if(action === 'cancel'){
                    scope.lists.projectsRequests = JSON.parse(JSON.stringify(scope.lists.prevSavedProjectsRequests));
                    delete scope.flags.projectRequestChanges;
                    return;
                } else{
                    if(scope.flags.projectRequestChanges.length){
                        var allChangesArray = scope.flags.projectRequestChanges;
                        Object.keys(allChangesArray).forEach(function(dict){
                            var searchId = 'id=' + allChangesArray[dict].id;
                            var requestStatus = allChangesArray[dict].projectStatus;
                            var finDict = {status : requestStatus};
                            var p = {form : finDict, search : searchId};
                            http.put('/svc/Project', p)
                                .then(function(response){
                                    var resp = response.data;
                                    if(resp.success){
                                        if(requestStatus !== 'Approved'){
                                            if(requestStatus === 'Rejected'){
                                                rejectProjectRequestAllocation(allChangesArray[dict].id, function(resp){
                                                    if(resp.success){
                                                        getRequests(http, lists, alerts, function(){
                                                            listOfProjectsRequestedIDs(lists);
                                                            alertSuccess.msg = 'Rejected Project Request'
                                                            scope.domainAdminDashboardAlerts.push(alertSuccess);
                                                            scope.flags.projectRequestChanges = [];
                                                        });
                                                    }
                                                })
                                            }

                                        } else{
                                            getProjectAccount(alerts, resp.data[0].name, function(project){
                                                var projObj = project.data[0];
                                                approveMemberOfApprovedProject(projObj, function(response){
                                                    scope.flags.bootstrapAlert = 'admProjRequest';
                                                    alertSuccess.msg = 'Successfully Updated Project Request'
                                                    scope.domainAdminDashboardAlerts.push(alertSuccess);
                                                    scope.flags.projectRequestChanges = [];
                                                    timeout(function(){
                                                        getRequests(http, lists, alerts, function(){
                                                            listOfProjectsRequestedIDs(lists);
                                                            allocationRequests(http, lists, alerts, function(res){
                                                                // let page know we have new data and we can set filter again.
                                                                if(cb){cb(res)}
                                                            });
                                                        });
                                                    }, 1000);
                                                });
                                            })
                                        }

                                    } else{
                                        alertError.msg = response.data.error;
                                        scope.domainAdminDashboardAlerts.push(alertError);
                                    }
                                }).catch(function(data){
                                lists.errorEditRecord = data.detail;
                            }).finally(function(){
                            });
                        });

                    }
                }
            }

            function getProjectAccount(alerts, name, callback){
                var searchName = "project==" + name;
                var p = {search : searchName};
                http.get("/svc/ProjectAccount", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            callback(resp);
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function findDeletedMemberships(arr){
                if(arr.length){
                    scope.flags.deletedMemberships = [];
                    arr.forEach(function(elem, i){
                        if(elem.member_status === 'Deleted'){
                            scope.flags.deletedMemberships.push(elem.project_id)
                        }
                    });
                }
            }

            function isDeletedStatus(id){
                if(scope.flags.deletedMemberships.includes(id)){
                    return true;
                }
            }

            function approveMemberOfApprovedProject(object, callback){
                var searchId = 'id=' + object.id;
                var newObject = {};
                newObject.member_status = "Active";
                delete object.id;
                delete object.domain;
                var p = {form : newObject, search : searchId};
                http.put('/svc/ProjectAccount', p)
                    .then(function(response){
                        if(response.data.success){
                            callback(response);
                        } else{
                            alertError.msg = response.data.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(data){
                    lists.errorEditRecord = data.detail;
                }).finally(function(){
                });
            }

            function rejectProjectRequestAllocation(project_id, callback){
                var p = {search : "project_id==" + project_id};
                // first get allocations for this project
                http.get("/svc/Allocation", {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            if(resp.data.length){
                                Object.keys(resp.data).forEach(function(elem){
                                    // next we need to reject each allocation of this rejected project.
                                    var searchId = 'id=' + resp.data[elem].id;
                                    var putObject = {status : "Rejected"};
                                    var p = {form : putObject, search : searchId};
                                    http.put('/svc/Allocation', p)
                                        .then(function(res){
                                            if(res.data.success){
                                                callback(res.data);
                                            } else{
                                                alertError.msg = res.data.error;
                                                alerts.push(alertError);
                                            }
                                        }).catch(function(data){
                                        lists.errorEditRecord = data.detail;
                                    }).finally(function(){
                                    });
                                });
                            } else{
                                // there were no allocations = gce OR jlse
                                callback(response.data);
                            }
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                });
            }

            function getApproverId(username, callback){
                http.get('/svc/Account', {params : {search : 'username=' + username}})
                    .then(function(response){
                        var res = response.data;
                        if(res.success){
                            callback(res.data[0].identity_id);
                        } else{
                            scope.authAlerts.push({
                                type : 'danger',
                                msg : res.error
                            });
                        }
                    })
                    .catch(function(response, status){
                        alertError.msg = response.data;
                        scope.authAlerts.push(alertError);
                    });
            }

            function createUserAccount(username, alerts, cb){
                if(alerts){scope.domainAdminDashboardAlerts = alerts;}
                var cdata = {form : {}};
                cdata.form.username = username;
                http.post('svc/CreateUser', cdata)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = 'Successfully Created User Account for ' + username;
                            scope.domainAdminDashboardAlerts.push(alertSuccess);
                        } else{
                            alertError.msg = resp.error;
                            scope.domainAdminDashboardAlerts.push(alertError);
                        }
                        window.scrollTo(0, 0);
                        if(cb){cb(resp)}
                    }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.domainAdminDashboardAlerts.push(alertError);
                });
            }

            function createServiceEmail(form, lists, alerts, cb){
                if(alerts.length){alerts = []}
                if(alerts){scope.domainAdminDashboardAlerts = alerts;}
                var cdata = {form : {name : form.newServiceEmailName, status : "Active", mailing_list : form.newServiceEmail}};
                http.post('svc/MailingList', cdata)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = 'Successfully Created Service Email';
                            scope.domainAdminDashboardAlerts.push(alertSuccess);
                            otherAccounts.mailingList(http, lists, alerts);
                        } else{
                            alertError.msg = resp.error;
                            scope.domainAdminDashboardAlerts.push(alertError);
                        }
                        window.scrollTo(0, 0);
                        if(cb){cb(resp)}
                    }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.domainAdminDashboardAlerts.push(alertError);
                });
            }

            function accountProjects(objectToSend, formID, callback){
                objectToSend.search = "id=" + objectToSend.form[0].id;
                objectToSend.form[0].account_id = formID;
                objectToSend.form[0].project_id = objectToSend.form[0].projectId;
                delete objectToSend.form[0].id;
                delete objectToSend.form[0].projectId;
                objectToSend.form = objectToSend.form[0];
                callback(objectToSend);
            }

            function selectAllToApprove(f){
                if(f){comMod.objCopyGuts(f, flags);}

                filteredAllocQtr('approve', flags, function(){
                    allocationRequestAction(flags, lists, 'status', 'Approved');
                })
            }

            function selectAllocQuarter(f){
                if(f){flags = f}
                flags.hideApproveAllBtn = true;
                filteredAllocQtr('filter', flags, function(){})
            }

            function selectAllocSystem(f) {
                if (f) {flags = f}
                filteredAllocSystem('filter', flags, function () {})
            }


            function filteredAllocQtr(type, flags, callback){
                var selectedFilterQtr = flags.filter.allocationRequests.cels_quarter;
                if(!selectedFilterQtr){return}
                var newString = selectedFilterQtr[0].toUpperCase() + selectedFilterQtr.slice(1);
                if(lists.allocationRequests.length){
                    lists.allocationRequests.forEach(function(elem, i){
                        if(elem.cels_quarter === newString){
                            if(type === 'approve'){
                                flags.hideApproveAllBtn = true;
                                elem.status = 'Approved';
                            } else{flags.hideApproveAllBtn = false; }
                        }
                    });
                }
                callback();
            }

            function filteredAllocSystem(type, flags, callback) {
                var selectedFilterSystem = flags.filter.allocationRequests.system;
                if (!selectedFilterSystem) {return}
                if (lists.allocationRequests.length) {
                    lists.allocationRequests.forEach(function (elem, i) {
                        if (elem.system === selectedFilterSystem) {
                            if (type === 'approve') {
                                flags.hideApproveAllBtn = true;
                                elem.status = 'Approved';
                            } else {
                                flags.hideApproveAllBtn = false;
                            }
                        }
                    });
                }
                callback();
            }


            function membershipReminder(username, project, alerts, flags){
                if(alerts){scope.domainAdminDashboardAlerts = alerts;}
                if(flags){ flags.bootstrapAlert = 'admProjectMembershipRequest'}
                var cdata = {userName : username, projectShortName : project};
                http.post('admin/remindPIRequest', cdata)
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = 'Successfully sent email reminder to PI';
                            scope.domainAdminDashboardAlerts.push(alertSuccess);
                            getRequests(http, lists, alerts, function(){
                                listOfProjectsRequestedIDs(lists);
                            });
                        } else{
                            alertError.msg = resp.error;
                            scope.domainAdminDashboardAlerts.push(alertError);
                        }
                        window.scrollTo(0, 0);
                    }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.domainAdminDashboardAlerts.push(alertError);
                });
            }

// ----------- public stuff gets returned -----------
            return publicStuff;

        }

    )
    ();

module.exports = {domainAdminModule}
