
<span id="search-row-{{index+1}}" class="singleFilter">
     <div class="searchTable">
        <span class="searchCol sCol vTop">
            <span *ngIf="filter.active && index>0">&</span>
        </span>
        <!-- search_fields -->
        <span class="searchCol sfld">
            <select id="search-{{lists.searchPath.replace('svc/','')}}-{{index}}"
                    [(ngModel)]="filter.filter_name"
                    ngDefaultControl
                    name="selectedSf"
                    (change)="checksearchfields(filterlist, filter, filter.filter_name, 'searchField', filtervalues)"
                    class="fitContent">
                <option value="" disabled selected>Filter List</option>
                <option *ngFor="let sf of filtervalues.searchFields" [ngValue]="sf.filter_name">
                    {{sf.filter_name}}
                </option>
            </select>
        </span>
        <!-- operators -->
        <span class="searchCol">
            <select id="searchOpr-{{lists.searchPath.replace('svc/','')}}-{{index}}"
                    *ngIf="filter.selectedSf.operators.length > 0"
                    [(ngModel)]="filter.selectedOp"
                    ngDefaultControl
                    name="filter.selectedOp"
                    (change)="checksearchfields(filterlist, filter, filter.selectedOp, 'searchOpr')">
                <option value=null disabled selected>Operator</option>
                <option *ngFor="let opr of filter.selectedSf.operators" [ngValue]="opr">
                    {{opr.op}}
                </option>
            </select>
        </span>
        <!-- Values -->
        <span class="searchCol">
            <!-- date-picker -->
            <span *ngIf="filter.selectedOp && filter.showCalendar && !filter.showRange && !filter.showList"
            class="searchCol">
                <ub3date 
                    [form]="filter" 
                    key="selectedVal" 
                    (onChange)="checksearchfields(filterlist, filter, filter.selectedVal, 'searchVal')">
                </ub3date>

            </span>
            <!-- Values -->
            <span *ngIf="filter.selectedOp && filter.selectedSf.requires_value && !filter.showCalendar && !filter.showRange && !filter.showList" class="inline">
                <input  id="searchVal-{{lists.searchPath.replace('svc/','')}}-{{index}}"
                        [(ngModel)]="filter.selectedVal"
                        ngDefaultControl
                        name="filter.selectedVal"
                        class="fitContent"
                        type="text"
                        placeholder="value"
                        (keyup)="checksearchfields(filterlist, filter, filter.selectedVal, 'searchVal')">
            </span>
            <!-- add <<null>> values -->
            <span *ngIf="filter.selectedOp && filter.selectedSf.requires_value && filter.showNull && !filter.showRange && !filter.showList" class="inline nullFld">
                <button class="btn btn-light"
                        id="nullBtn"
                        [(ngModel)]="filter.selectedVal"
                        ngDefaultControl
                        name="filter.selectedVal"
                        (click)="checksearchfields(filterlist, filter, flags.nullTag, 'searchVal')">NULL
                </button>
            </span>
            <!-- for Range -->
            <span *ngIf="filter.selectedSf.requires_value && filter.showRange && !filter.showList" class="rangeFld">
                <span>From:</span>&nbsp;
                <input *ngIf="!filter.showCalendar"
                        id="searchVal1"
                        [(ngModel)]="filter.selectedValues[0]"
                        ngDefaultControl
                        name="filter.selectedValues0"
                        class="fitContent"
                        type="text"
                        placeholder="value1">
                <span *ngIf="filter.showCalendar && filter.selectedOp" class="searchCol">
                    <ub3date [form]="filter.selectedValues" key="0" (onChange)="doNothing()"></ub3date>
                </span> &nbsp;&nbsp;

                <span>To:</span> &nbsp;
                <input *ngIf="!filter.showCalendar"
                        id="searchVal2"
                        [(ngModel)]="filter.selectedValues[1]"
                        ngDefaultControl
                        name="filter.selectedValues1"
                        class="fitContent"
                        type=text
                        placeholder="value2"
                        (keyup)="checksearchfields(filterlist, filter, filter.selectedValues, 'searchVal')">
                <span *ngIf="filter.showCalendar && filter.selectedOp" class="searchCol">
                    <ub3date [form]="filter.selectedValues" key="1"
                        (onChange)="checksearchfields(filterlist, filter, filter.selectedValues, 'searchVal')">
                    </ub3date>
                </span>
            </span>
            <!-- for List -->
            <span *ngIf="filter.showList">
                <input id="list+{{$index}}"
                        [(ngModel)]="filter.selectedVal"
                        ngDefaultControl
                        name="filter.selectedVal"
                        type="text"
                        class="searchList"
                        placeholder="enter values separated by comma"
                        (keyup)="checksearchfields(filterlist, filter, filter.selectedVal, 'searchVal')">
            </span>
        </span>
        <span class="searchCol sCol vTop">
            <!-- remove a filter -->
            <button class="btn btn-primary btn-md boldFont sfBtn remFltrBtn" (click)="removefilter(index, filterlist)" *ngIf="filter.showRemove" id="removeButton-{{index}}" uib-tooltip="click to remove this search filter"
            tooltip-placement="right">-</button>
        </span>
    </div>
</span>
