
// validation module

var validationModule = 
(function(){

    var publicStuff, validationRules;
    var initialized = false;
    var cookies , http, httpNew , scope , timeout , win ;
    cookies = http = httpNew = scope = timeout = win = null;  // cached services
    var jsServices = {};
    var comMod = null;
    var reqSent = false;
    var clog = null;
    validationRules = {};       // this is the cache, so that backend gets called only 
                                // once per formName per session
    var cachedE = {};
    var cachedY = {};

    publicStuff ={
        name: 'validationModule',
        computeMissing  : computeMissing,
        init            : init,
        getRules        : getRules,         // only used in displayRules.html. needed for unit tests
        overWriteScope  : overWriteScope,
        setServices     : setServices,
        validate        : validate,   
        validateNow     : validateNow,   
        validEmailFormat: validEmailFormat,
        // validateEmailFormat: validateEmailFormat,
        validateUniqueEmail: validateUniqueEmail
    };

    /*
    function setServices(c, h, hNew, s, t, w, cm){
        comMod = cm ? cm : commonModule;
        if (initialized) return;
        cookies = c;
        http = h;
        httpNew = hNew;
        scope = s;
        timeout = t;
        win = w;
        initialized = true;
    }
    */

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        http    = jsServices.http;
        httpNew = jsServices.httpNew;
        scope   = jsServices.scope;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;
        comMod = jsServices.commonModule;
        clog = comMod.ub3consoleLog;
    }

    function init(formNames, callback){

        /*
        if (reqSent){
            // to make sure this gets called only once per session. this is wrong
            // It needs to be called after every login
            if (callback) callback();
            return;
        }
        */

        // new approach :   get all of them with one shot
        reqSent = true;
        http.get('/public/allValidationRules')
            .subscribe(function(resp){
                var i, formName, rulesForOneForm;

                if (resp.success && resp.rules){
                    //verify that we got all the needed ones
                    for (i in formNames){
                        formName = formNames[i];
                        if (resp.rules[formName]){
                            rulesForOneForm = validationRules[formName] = resp.rules[formName];
                            addCustomValidation(formName, rulesForOneForm);
                        }else{
                            //clog('should we say something to user?, - no validation rules');
                        }
                    }
                }else{
                    //clog(71, 'error', resp.error);
                }
                if (callback) callback();
            }); /*
            .error(function(e){
                //$scope.gotError = data ;
                //alert('bad stuff');
            }); */
    }

    function overWriteScope(s){
        scope = s;
    }

    function getRules(formName){
        return validationRules[formName] ;
    }

    // this set of custom validations might need to be made more specific in the future. If the need arises.
    function addCustomValidation(formName, formRules){
        // Define custom validation rules for each field
        //

        function addIt(fieldName,fn){
            if (!formRules){
                return;
            }
            if (formRules[fieldName]){       // if this field is part of the formName
                formRules[fieldName].custom = fn;
            }
        }

        function userNameCustomValidator(form){

            if (!form || !form.userName) {
                return null ;
            }
            
            var re = new RegExp(scope.domainGlobals.usernameRegex) ;

            if (! re.test(form.userName)){
                return scope.domainGlobals.usernameHelp ;
            }
            return null;
        }

        function addUserNameCustomValidation(){
            if (!scope || !scope.domainGlobals){
                timeout(addUserNameCustomValidation, 300);
                return;
            }
            addIt('userName', userNameCustomValidator);
        }
        addUserNameCustomValidation();

        addIt('workStreet1', function(form){
            if (!form || !form.workStreet1 ) return null ;
            return  /p[. ]+o[. ]*box/.test(form.workStreet1.toLowerCase())
                    ? 'P.O. Box addresses are not allowed'
                    : null ;
        });
        addIt('shippingStreet1', function(form){
            if (!form || !form.shippingStreet1) return null ;
            return  /p[. ]+o[. ]*box/.test(form.shippingStreet1.toLowerCase())
                    ? 'P.O. Box addresses are not allowed'
                    : null ;
        });
        addIt('workZip', function(form){
            if (!form || !form.workZip) return null ;
            return checkZip(form.workCountry, form.workZip);
        });

        addIt('shippingZip', function(form){
            if (!form || !form.shippingZip) return null ;
            return checkZip(form.shippingCountry, form.shippingZip);
        });
         addIt('zip', function(form){
            if (!form || !form.zip) return null ;
            return checkZip(form.country, form.zip);
        });

        addIt('altEmail', function(form){

            if (form.email === form.altEmail){
                return 'Email and Alternate Email need to be different' ;
            }
            return null ;
        });

        addIt('middleName', function(form){    // no middle name
            if (form.nmi){
                if (form.middleName){
                    form.middleName = '';
                }
            }else{
                if (!form.middleName){
                    return 'Middle name is required'; 
                }
            }
            return null ;
        });

        addIt('storageJustification', function(form){
            var msg = 'Please enter storage justification when storage is greater than ';
           // alert(form.parentProjectShortName);

            if ((form.parentProjectShortName === 'gce' || form.division === 'gce') && form.storage > 100){
                if (!form.storageJustification || (form.storageJustification == '')) {
                    return msg + '100';
                }
            }
            if ((form.parentProjectShortName === 'lcrc' || form.division === 'lcrc') && form.storage > 1){
                if (!form.storageJustification || (form.storageJustification == '')) {
                    return msg + '1';
                }
            }
            return null;
        });

        addIt('securityQuestion', function(form){
            if (form.securityAnswer && (form.securityAnswer != '')){
                if (!form.securityQuestion || (form.securityQuestion == '')) { 
                    return 'Please enter a security question for your security answer'; 
                }
            }
            return null;
        });

        addIt('securityAnswer', function(form){
            // clean up to only acccept alphanumerics,  and turn to lowercase, preserve spaces
            
            if (form.securityAnswer && (form.securityAnswer != '***')){
                var x = form.securityAnswer ;
                x = x.split(' ').join('_');
                x = x.replace(/\W/g, '').toLowerCase() ;
                x = x.split('_').join(' ');
                x = comMod.replaceAll(x, ' ','');
                if (x.length < 3){
                    return 'Enter at least 3 alphanumeric characters';
                }
            }

            if (form.securityQuestion && (form.securityQuestion != '')){
                if (!form.securityAnswer || (form.securityAnswer == '')) { 
                    return 'Please enter a security answer for your security question'; 
                }
            }
            return null;
        });

        addIt('phoneNumber', function(form, ferror){
                if (!form.phoneNumber || (form.phoneNumber == '')) { 
                    return 'Please enter a phoneNumber'; 
                }
                var cc = form.selectedCountry ? form.selectedCountry : comMod.getCountryCode(form.phoneCountry, scope.lists);
                if (form.phoneNumber && form.phoneNumber !== '' && form.status !== 'Deleted' && cc){
                    var phone = encodeURIComponent(cc+form.phoneNumber);
                    checkPhone(phone, form, ferror); //async call
                }
                return null;
        });

        addIt('password', function(form){
            if (!form.password) return null ;
    
            if (/^\d/.test(form.password))          return 'Password cannot start with a number' ;
            if (/\d$/.test(form.password))          return 'Password cannot end with a number' ;
            if (/^[a-zA-Z]*$/.test(form.password))  return 'Password cannot be all letters' ;
            if (/^\d*$/.test(form.password))        return 'Password cannot be all numbers' ;
            if (/^[\d\-\_]$/.test(form.password))    
                return 'The password looks too similar to an identification number.  (A phone number, for example)' ;
            if (! /\d/.test(form.password))           return 'Password must contain a number.' ;
            if (! /\W/.test(form.password))         
                return "Password must contain a special character in it. ('#' or '{', for example)" ;

            return null ;
        });

        addIt('confirmPassword', function(form){
            if (!form) return null ;
            return (form.password != form.confirmPassword) ? 'Confirm password does not match password' : null;
        });

        addIt('orcid', function(form, ferror, fldNameEdited, prevSavedForm){
            if (!form) return null ;
            if (!form.orcid || (form.orcid == '')) return null;
            form.orcid = comMod.tweakOrcid(form.orcid, fldNameEdited, prevSavedForm);
            if ((form.orcid.length > 0) && (form.orcid.length != 19)){
                return 'ORCID must be 16 digits long';
            }
            //if (form.orcid == '') delete form['orcid']; // this line was causing bug #5014
            if (form.orcid == '') form['orcid'] = '';
            return null;
        });

    }

// validate phone number
    function checkPhone(longPh, form, ferror){

        if (!form.accountPhones) return;  // so account request works

        var phId;
        var idx = null;

        idx = form.accountPhones.findIndex(function(item){
            return item.phoneNumber === form.phoneNumber;
        });
        phId = form.accountPhones[idx].id;

        if(!phId) return;

        var path = scope.user.role === 'limited' ? scope.user.role : 'auth';
        var aName= "/" + path + "/validPhone" ;
        var url = aName+"?phoneNumber="+longPh;
        http.get(url)
            .success(function(resp){
                if (!resp.success) {
                    ferror.phoneNumber = {error: resp.error, required: true, label: 'Phone Number'};
                }
            })
            .error(function(e){
                ferror.phoneNumber = {error: e, required: true, label: 'Phone Number' };
            });
    }

    function checkZip(country, zip){
        var z5, z54;
        if (!zip) return null ;
        if (country != 'United States') return null;

        z5 = /^\d{5}$/.test(zip);
        z54= /^\d{5}(-\d{4})?(?!-)$/.test(zip);

        return  (z5 || z54 || (zip == 'XX'))
                ? null
                //: '(sample format: 60439 or 60439-3271 or XX for international)';
                : '(sample format: 60439 or 60439-3271)';

    }

    function validate(formName, form, ferror, row, fromPageLoad, fldName, prevSavedForm){
        comMod.doWhenAPICallsDone(function(){
            validateNow(formName, form, ferror, row, fromPageLoad, fldName, prevSavedForm);
        });
    }
    
    function validateNow(formName, form, ferror, row, fromPageLoad, fldNameEdited, prevSavedForm){
        var fieldName, v, rules, rc, f, y, e ;
        var rulesForOneForm;

        if (!form) return ;

        rulesForOneForm = getRules(formName);


        // fixme - DEFUNCT REMOVE, using !angular
        if (scope && scope.explorerType && scope.flags){
            if ((cb = scope.flags.currBranch) && cb.state && cb.state.prevSavedForm){
                //cb.state.delta = !comMod.isEqual(cb.state.prevSavedForm, form);
                cb.state.delta = !angular.equals(cb.state.prevSavedForm, form);
            }else{
                scope.flags.delta = false;
                if (scope.flags.prevSavedForm){
                    scope.flags.delta = !angular.equals(scope.flags.prevSavedForm, form);
                }
            }
        }
        for (fieldName in rulesForOneForm){
            rules = rulesForOneForm[fieldName] ;
            y = getY(formName, fieldName);
            if (y && (y > 0)){
                rules.y = y;
            }
            v = form[fieldName];
            v = ((typeof v) == 'string') ? v.trim() : v ;
            delete ferror[fieldName];

            // these are the generic validation rules defined

            if (!validType(rules.type, v)){
                ferror[fieldName] = {error: 'expected value: '+v+ ' to be type: '+rules.type, label: rules.label} ;
            }

            if ((rules.type == 'int') && v  && (v != '') && (!isNumeric(v))){
                ferror[fieldName] = {error: 'enter only numbers', label: rules.label} ;
            }

            if (rules.type == 'float' && v  && (v != '') && (!isNumeric(v))){
                ferror[fieldName] = {error: 'enter only numbers', label: rules.label} ;
            }

            if (rules.type == 'email' && v  && (v != '')){
                if (!validEmailFormat(v)){
                    ferror[fieldName] = {error: 'Enter a valid Email format', label: rules.label };
                }
            }

            if ((rules.type != 'array') && rules.min_chars && rules.max_chars){
                if (rules.min_chars && v && (v.length < rules.min_chars)){
                    ferror[fieldName] = {error: 'enter at least ' + rules.min_chars + ' characters', label: rules.label };
                }
                if(fieldName === 'phoneNumber' && form.selectedCountry){
                    v = form.selectedCountry+v;
                }

                if (rules.max_chars && v && (v.length > rules.max_chars)){
                    ferror[fieldName] = {error: 'enter at most ' + rules.max_chars+ ' characters', label: rules.label };
                }
            }

            if (rules.required){
                if (rules.type == 'bool'){
                    if ((v != true) && (v != false)){
                        ferror[fieldName] = {error: null, required: true, label: rules.label} ;
                    }
                }else if (rules.type == 'array'){
                    if (!v || v.length == 0){
                        ferror[fieldName] = {error: null, required: true, label: rules.label} ;
                    }
                }else{
                    if ((v == null) || (v == '') || ((typeof v) == 'undefined')) {
                        ferror[fieldName] = {error: null, required: true, label: rules.label} ;
                    }
                }
            }

            if ( rules.required && ((v == null) || ((typeof v) == 'undefined')) ){
                if (((typeof v) != 'boolean') && (v == '')) {
                    ferror[fieldName] = {error: null, required: true, label: rules.label} ;
                }
            }
            //DONE
            if (!rules.required && ((v == '') || (v == null) || ((typeof v) == 'undefined')) ){
                ferror[fieldName] = {error: null, optional: true, label: rules.label} ;
            }

            // more specific custom validation rules are run/checked here
            if (rules.custom){
                if ((fieldName == 'userName') && (formName != 'accountRequest') && (formName != 'limitedLoginA')){
                    continue;   // skip the custom validation call
                }
                f = rules.custom;    // extract the custom validation function

                // we are passing form as a parameter because
                // the validator is custom, and it may need access
                // to other fields
                rc = f(form, ferror, fldNameEdited, prevSavedForm);       // run the validation
                if (rc) {                   // found an error if not null
                    ferror[fieldName] = {error: rc, label: rules.label };
                }
            }
            if (ferror[fieldName]){
                if (ferror[fieldName].required) {
                    ferror[fieldName]['dispRequired'] = true;
                }
                if (row){ 
                    e = getElement(formName, fieldName, row);
                    if (e){
                        ferror[fieldName]['domElement'] = e;
                    }
                }
            }
        }

        if (fromPageLoad) {
            // skip the supression of required field messages
            return;
        }  
        /*
        // now, only display 'required' messages on fields on or above the focused field.
        // if none is focused, then show NONE of the 'required' messages
        var ae = document.activeElement ;
        var focusFieldName = (ae && ae.id && (ae.id != 'body')) ? ae.id : null ;
        
        if(formName == 'accountRequest'){
            var y;
            if(focusFieldName){
                // assign to f, then compare if not null
                var focusY = (f = rulesForOneForm[focusFieldName]) ? f.y : null;
                if(focusY && y){
                    // loop and clear out any 'required' flags below the focus field
                    for(fieldName in ferror){
                        e = ferror[fieldName];
                        y = (rulesForOneForm[fieldName] && rulesForOneForm[fieldName].y)
                            ? rulesForOneForm[fieldName].y
                            : null;
                        if(e.required && y && (y > focusY)){
                            e.dispRequired = false;
                        }
                    }
                }
            } else{

                // nothing focused.  cursor is not in any input field
                // set all required to not be displayed
                for(fieldName in ferror){
                    e = ferror[fieldName];
                    if(e.required){
                        e.dispRequired = false;
                    }
                }

            }
        }
        */
    }

    // boolean function
    function validType(expectedType, v){
            var t = typeof v;

            if (!v || v == '') return true;

            if(expectedType == 'bool' && (t != "boolean")){
                return false;
            }
            if(expectedType == 'str'  && ((t != "string") && (t != 'number'))){
                return false;
            }
            if(expectedType == 'enum' && ((t != "string") && (t != 'number'))){
                return false;
            }
            if(expectedType == 'int'  && ((t != "number") &&  !isNumeric(v))){
                return false;
            }
            if(expectedType == 'float'  && ((t != "number") &&  !isNumeric(v))){
                return false;
            }
            return true;
    }

    function getElement(formName, fieldName, row){
        if (!cachedE[formName]){
            cachedE[formName] = {};
        }
        if (row && !cachedE[formName][row]){
            cachedE[formName][row] = {};
        }
        var e = row ? cachedE[formName][row][fieldName] 
                    : cachedE[formName][fieldName] ;
        if (!e){
            e = row ? document.getElementById(row + '_' + fieldName) 
                    : document.getElementById(fieldName) ;

            if (!e) {
                return null;
            }
            if (row) {
                cachedE[formName][row][fieldName] = e;
            }else{
                cachedE[formName][fieldName] = e;
            }
        }
        e.autocomplete = 'nope';    
        return e;
    }

    function getY(formName, fieldName){
        var e = getElement(formName, fieldName);
        if (!e) return 0;

        if (!cachedY[formName]){
            cachedY[formName] = {};
        }
        var y = cachedY[formName][fieldName];
        if (!y){
            y = e.getBoundingClientRect().y ;
            if (y == 0){
                return 0;
            }
            cachedY[formName][fieldName] = y;
        }
        return y;
    }

    function isNumeric(s){
        return (!isNaN(s) && (s != '')) ;
/*
    var i = parseInt(s);
    return (i.toString() == s) || (i == 0);
*/
    }
    function validDateFormat(s){
        
        if (((typeof s) == 'string') && (s.length > 10)){    // maybe running from the server?
            var a,b,y,m,d;
            a = s.split('T');
            if (a.length < 2) return false;
            b = a[0].split('-');
            if (b.length < 3) return false;
            y = b[0];
            m = b[1];
            d = b[2];
            if (!isNumeric(y) || (y.length != 4)) return false;
            if (!isNumeric(m) || (m.length != 2) || (m < '01') || (m > '12')) return false;
            if (!isNumeric(d) || (d.length != 2) || (d < '01') || (d > '31')) return false;
            return true;
        }
        if (!s || (s == '') || (s == 'Invalid Date')) return false;
        return ((typeof s) == 'object') ;
    }
    function validateUniqueEmail(row, callback){
        var path = scope.user.role === 'limited' ? scope.user.role : 'auth';
        var apiStr= "/" + path + "/uniqueEmail?email=";
        var email = encodeURIComponent(row.email);
        var url = apiStr+email;
        http.get(url)
            .success(function(resp){
                var respVal = (resp.success) ? null : resp.error;
                callback(respVal);
            })
            .error(function(e){
                callback(e);
            });
    }
    // not used yet (This is the actual function to be used for Email format validation instead of validEmailFormat())
    function validateEmailFormat(email, ferror){

        var path = scope.user.role === 'limited' ? scope.user.role : 'auth';
        var apiStr= "/" + path + "/validEmail?email=";
        var encEmail = encodeURIComponent(email);
        var url = apiStr+encEmail;
        http.get(url)
            .success(function(resp){
                var respVal = (resp.success) ? null : resp.error;
                ferror[email] = {error: respVal, label: 'Email'};
                return resp.success;
            })
            .error(function(e){
                ferror[email] = {error: e, label: 'Email'};
                return e;
            });
    }

    // changed so that it accepts it this small:  a@b.c
    function validEmailFormat(e){
        var ar, domain;
        if (!e) return false;

        if (e.indexOf(' ') >= 0)
            return false;

        if (e.indexOf('@') < 1)
            return false;

        ar = e.split('@');      // ar = ['a', 'b.c']
        domain = ar[1];         // domain = 'b.c'
        if (domain.indexOf('.') < 1)    // if there is nothing to the left of the dot
            return false;

        ar = domain.split('.'); // ar = ['b', 'c']
        if (ar.length < 1)      // we need at least 2 entries in the domain name
            return false;

        if (ar[1].length < 1)   // 'c' needs to be at least one character long
            return false;

        return true;
    }
    function calculateAge(birthday) { // birthday is a date
        if ((typeof birthday) == 'string'){
            birthday = new Date(birthday);
        }
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    // this one is expecting messages to be in this format:   
    //   {fieldName1: {label: "text in here", error: "error text message", required: true or false}, fieldname2: ......   }
    //
    function computeMissing(messages){
        var r, e, w, oneMessage, fieldName;
        r = '' ;
        for (fieldName in messages){
            oneMessage = messages[fieldName];
            if (oneMessage && oneMessage.required){
                //w += (fieldName + ': ') ;
                r += (oneMessage.label + ', ') ;
            }
        }
        if (r != '') {
            r = 'Required: ' + r;
        }
        e = '';
        for (fieldName in messages){
            oneMessage = messages[fieldName];
            if (oneMessage && oneMessage.error){
                //w += (fieldName + ': ') ;
                e += (oneMessage.label + ': ' + oneMessage.error + '; ') ;
            }
        }
        if (e != ''){
            e = 'Errors: ' + e;
        }
        w = ((r != '') && (e != '')) 
            ? (r + ' ---- ' + e)
            : (r + e) ;
        return (w != '') ? w : null;
    }


    // ----------- public stuff gets returned -----------
    return publicStuff ;

})();

module.exports = {validationModule};
/*if ( (typeof module) != 'undefined'){
  // we are in angular 2+
  var c = "module.exports = {validationModule}" ;
  eval(c);
}*/

