import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import { projectAdminModule } from 'projectAdminModule';
import {CommonService, Ub3httpService} from '@common/services';

var t = null;
var scope = null;
let http: any = null;
var clog = null;

@Component({
  selector: 'project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {

  pjName = null;    // short project name
  newOrEdit = null;
  flags = {newProject:null, 
           editProject:null, 
           readyToEdit:null, 
           delta:null, 
           dispLoading:null, 
           missing:null,
           showProjectEditForm:null,
           showProjectsList:null,
           pjaOkToSave:null,
           showAddUser:null,
           readError:null,
           currBranch:null
  };
  lists = {scienceTypes:null, 
           projAclList:null, 
           catalystsForFieldOfStudy:null, 
           instActPend:null, 
           allocations:null,
           projectAccounts:null 
  };
  sharedLists = {certifiedHOsts:null, catalystsForFieldOfStudy:null, certifiedHosts:null};

  form = {
            projectShortName: '',
            projectTitle: '',
            fieldOfStudy: '',
            catalyst: '',
            internalContact: '',
            paFavorHost: '',
            bpiObjList: [],
            fundingAgency: '',
            industry: '',
            otherSystems: '',
            scienceDescription: '',
            projDescription: '',
            fyRequested: '',
            associatedGroup: '',
            url: '',
            status: '',
            public: '',
            requires_approval: '',
            priority: '',
          };
  fieldErrors = null;
  authAlerts = [];
  bpi = null;
  instListChanged = false;
  displayTable = true;

  constructor(
    public h: Ub3httpService,
    private router: Router,
    private commonService: CommonService
  ) {
    scope = t = this;
    http = h;
    clog = t.commonService.ub3consoleLog;
    t.fieldErrors = this.commonService.getSharedObject('warning', 'fieldErrors');
  }

  ngOnInit(): void {
    document.body.classList.remove('public-page');
    t.flags.readyToEdit = false;
    setTimeout(doit, 300);

    function doit(){
        var urlParams = t.commonService.urlSearchParamsArray();
        t.pjName = urlParams.get('name');
        t.newOrEdit = urlParams.get('opt');

        t.commonService.objCopyGuts({}, t.fieldErrors);
        t.flags.showAddUser = false;
        t.flags.filterStr = '';
        t.lists.matchedUsers = [];
        projectAdminModule.init(t, 'Project Admin Module', t.pjName, t.newOrEdit);
        t.commonService.doWhenAPICallsDone(function(){
            t.getCatalysts(t.form.fieldOfStudy);
            if (!t.form.paFavorHost) { t.form.paFavorHost = '';}
            t.pjaValidate(null);
            t.flags.readyToEdit = true;
        });
        t.flags.filter = {
          projAcc: {
            userName:'',
            firstName:'',
            lastName:'',
            preferredName:'',
            email:'',
            accountStatus:'',
            projectRole:'',
            memberStatus:'',
            comment:'',
          }
        };
    }
  }

  validatePA(){
    projectAdminModule.validatePA(scope.flags, scope.lists);
  }
  updateFilter(colName){
    t.commonService.onStopCalling(700, function(){
      t.updateFilterNow(colName);
    });
  }
  updateFilterNow(colName){
        t.commonService.filter('projAcc', t.lists.projectAccounts, colName, 'and', t.flags.filter['projAcc']);
        // trigger table repaint
        t.displayTable = false;
        setTimeout(function(){t.displayTable = true;}, 100);
  }

  pjaValidate(fName?:string) {
    projectAdminModule.validate(scope.form, fName, scope.flags, scope.lists);
    t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(scope.flags.fieldErrors, t.fieldErrors);
    });
  };
  matchFavorHostToCatalyst(form, certifiedHosts, catalysts){
    projectAdminModule.matchFavorHostToCatalyst(form, certifiedHosts, catalysts);
  }
  putProject(saveOption){
    scope.flags.saveOption = saveOption;
    projectAdminModule.putProject (scope.form, scope.flags, scope.lists, scope);
    t.commonService.doWhenAPICallsDone(function(){
        t.getCatalysts(t.form.fieldOfStudy);
        if (saveOption == 'getOut'){
            // make browser 'go back' to previous page 
            window.history.go(-1);
        }
    });
  };
  cancelEdit(){
    window.history.go(-1);
  }
  getCatalysts(fieldOfStudy) {
    if (!fieldOfStudy || (fieldOfStudy == '')) return;

    projectAdminModule.getCatalysts(fieldOfStudy, function(clist){
        var i, id, found, obj, catNum, goodList;

        // first massage the list and only keep the ones to be displayed
        goodList = [];
        for (i in clist){
            obj = clist[i];
            if (obj.selectable && obj.fieldOfStudy != 'UNKNOWN'){
                goodList.push(obj);
            }
        }
        scope.lists.catalystsForFieldOfStudy = goodList;

        // the user just changed field of Study.  So now we have a new list of possible catalysts
        // If the current catalyst is not in this new list, empty it.
        catNum = t.form.catalyst ? parseInt(t.form.catalyst) : null;
        if (catNum){
            found = false;
            for (i in goodList){
                id = goodList[i].id;
                if (catNum == id){
                    found = true;
                    break;
                }
            }
            if (!found) {delete t.form['catalyst'];}
        }
    });
  };

  dispCatalystOption(currCatalystValue, objFromList){
    var disp =  (parseInt(currCatalystValue) == objFromList.id) || (objFromList.selectable && (objFromList.fieldOfStudy != 'UNKNOWN')) ;
    return disp;
  };

  bpiListChange(idx, newIdx) {
    if (! scope.form.bpiObjList){
        scope.form.bpiObjList = [];
    }
    projectAdminModule.bpiListChange(scope.form.bpiList, scope.form.bpiObjList, idx, newIdx, scope.lists.instActPend);
  };

  resetProjectAccounts(){
    projectAdminModule.resetProjectAccounts(scope.lists);
    projectAdminModule.validatePA(scope.flags, scope.lists);
    t.authAlerts = [];
  };
  matchUsers(str, allAccounts){
    t.commonService.matchUsersNG(str, allAccounts, t.flags, t.lists);
  }
  addProjectAccount(pjName, userName, comment){
    projectAdminModule.addProjectAccount(pjName, userName, comment, scope.flags, scope.lists, scope);
  };  
  updateProjectAccounts = function(pjName){
    projectAdminModule.updateProjectAccounts(pjName, scope.lists, scope.flags, function(){

        // do this so the edit form refreshes and updates new PI if/when changed
        // any unsaved changes to fields in that form will be lost

        //old ng1 call
        //scope.initProjectEditForm(pjName, 'edit');

        // new ng2+ call
        t.ngOnInit();

    }, scope);
  };
  bpiListDel = function(idx) {
    projectAdminModule.bpiListDel(scope.form.bpiList, scope.form.bpiObjList, idx);
    showMsg();
  };
  bpiListAdd(idx) {
    if (! scope.form.bpiObjList){
        scope.form.bpiObjList = [];
    }
    projectAdminModule.bpiListAdd(scope.form.bpiList, scope.form.bpiObjList, idx, scope.lists.instActPend);
    setTimeout(function(){t.bpi = '';}, 300);
    showMsg();
  };
  postProject(opt){
    var pjName = scope.form.projectShortName;
    t.flags.submitError = null;
    t.authAlerts = [];
    projectAdminModule.postProject(scope.form, scope.flags, scope.lists);
    t.commonService.doWhenAPICallsDone(function(){
        if (t.flags.okToSubmit && (!t.flags.submitError) && (t.authAlerts.length == 0)){
            if (opt && (opt == 'then-edit')){
                // go to the edit page for this new project
                //window.location.hash = '/projectEdit?name=' + pjName + '&opt=edit' ;
                setTimeout(function(){
                    t.router.navigateByUrl('/projectEdit?name=' + pjName + '&opt=edit');
                    window.location.reload();   // without reload,  page does not update
                }, 500);
            }else{
                //t.onEditDone();
                // make browser go back instead
                window.history.go(-1);
            }
        }
    });
  };
  adminEditRecord(name){
    this.router.navigateByUrl('userEdit?userName='+name);
  };
  navigateToAclPage(acltagname){
    this.router.navigateByUrl('/aclAdmin?name=' + acltagname);
  }

}

function showMsg(){
    t.instListChanged = true;
    setTimeout(function(){
        t.instListChanged = false;
    }, 5000);
}
