
// Account Reactivate Module
var accountReactivateModule = (function(){

        "use strict";

        var timeout, cookies, http, httpLegacy, scope, win, comMod, valMod, edrMod, acUpMod ;
        var jsServices = {};
        timeout= cookies= http= httpLegacy = scope= win= comMod= valMod= edrMod = acUpMod= null;
        timeout = null;

        var publicStuff;
        publicStuff = {
            init                : init,
            isSaveDisabled      : isSaveDisabled,
            getAccountInfo      : getAccountInfo,
            getSurveyData       : getSurveyData,
            reactivateRequest   : reactivateRequest,
            reactAnswerVerified : reactAnswerVerified,
            saveAndReturnLater  : saveAndReturnLater,
            setServices         : setServices,
            submitReactivate    : submitReactivate,
            validate            : validate,
        };

        function init(httpLegacy, http, cookies, sc, pageName, backFromFavor, q, ti, survey){

            scope = sc;
            comMod.publicPage(true);
            /*
            if (survey){
                scope.isReactivateSurvey = true;
                var targetElement = angular.element(document.querySelector('#body'));
                targetElement.addClass('doe-data-survey');
            }
            */
            timeout = ti;
            if(!scope.user || comMod.isEqual(scope.user, {})){
                // not ready, wait a little bit
                comMod.ub3consoleLog('waiting for user to be ready');
                timeout(function(){ 
                    init(httpLegacy, http, cookies, scope, pageName, backFromFavor, q, ti, survey);
                }, 300);
                return;
            }
                if (!scope.flags) { scope.flags = {};}
                addItemsToObj ({
                    displayedPageName : pageName,
                    hasError: {},       // ng2+ needs this
                    hideHomePage : true,
                    reactivateAccount : true,
                    reactLevel : 4,
                    level : 0,
                    fieldErrors : {},
                    missing : null,
                    answerVerified : false,
                    apiType : 'limited/',
                    okToSubmit : true,
                    disableReactivation : false,
                    LLStage : 'A'
                }, scope.flags);

                if(!scope.form){
                    scope.form = {};
                }
                if(!scope.lists){
                    scope.lists = {};
                }
                if(!scope.lists.activeProjects){
                    if (scope.user && (scope.user.role == 'limited')){
                        comMod.getLimitedProjectsList(scope.lists);
                    }
                }
                if(scope.isReactivateSurvey){
                    scope.form.projectShortNameList = 'ALCF_Data_Survey';
                    scope.flags.disableHref = 'Reactivate an Account';
                    scope.flags.isReactivateSurvey = true;
                    scope.flags.reactivateType = 'survey';
                } else{
                    scope.flags.reactivateType = 'reactivate';
                }

                domainGlobals(scope.lists, scope.flags);
                var u = scope.user;
                var c = comMod.getLocalStorage('anl-UB3-LLF');
                var u = scope.user;
                if(c && u && u.signedIn && (u.role == 'limited')){
                    // this is needed when user returns from favor
                    // displays previous state of data when they left
                    c.email = u.email;
                    c.firstName = u.firstName;
                    c.lastName = u.lastName;
                    scope.LLF = c;

                    if(c.answerVerified){
                        scope.flags.level = 5;
                        scope.flags.answerVerified = true;
                    }
                    if(c.projectShortNameList){
                        scope.form.projectShortNameList = c.projectShortNameList;
                        scope.flags.level = 6.5;
                    }

                    //

                    if(!scope.lists.institutions){
                        comMod.getLimitedInstitutionsList(httpLegacy, scope.lists);
                    }

                    if(!scope.lists.countries){
                        comMod.getLimitedCountries(scope.lists);
                    }

                    if(!scope.form.id && scope.form.projectShortNameList){
                        // timeout to ensure we have access as limited user
                        timeout(function(){ 
                            getAccountInfo(httpLegacy, http, scope.form, scope.flags, scope.lists, q, scope.LLF, scope);
                        }, 300);
                        return;
                    }
                }

        }

        // this function used in ng10 only
        function getSurveyData(scope, q){

            comMod.objCopyGuts({
                displayedPageName : 'Reactivate an Account',
                hasError: {},       // ng2+ needs this
                hideHomePage : true,
                reactivateAccount : true,
                reactLevel : 4,
                level : 0,
                fieldErrors : {},
                missing : null,
                answerVerified : false,
                apiType : 'limited/',
                okToSubmit : true,
                disableReactivation : false,
                //LLStage : 'A'
            }, scope.flags);

            if(!scope.form){
                scope.form = {};
            }
            if(!scope.lists){
                scope.lists = {};
            }
            if (!scope.lists.usaStates){
                scope.lists.usaStates = comMod.getUsaStates();
            }
            if(!scope.lists.activeProjects){
                comMod.getLimitedProjectsList(scope.lists);
            }
            scope.form.projectShortNameList = 'ALCF_Data_Survey';
            scope.flags.disableHref = 'Reactivate an Account';
            scope.flags.isReactivateSurvey = true;
            scope.flags.reactivateType = 'survey';


            domainGlobals(scope.lists, scope.flags);
            var u = scope.user;
            var c = comMod.getLocalStorage('anl-UB3-LLF');
            var u = scope.user;
            if(c && u && u.signedIn && (u.role == 'limited')){
                // this is needed when user returns from favor
                // displays previous state of data when they left
                c.email = u.email;
                c.firstName = u.firstName;
                c.lastName = u.lastName;
                scope.LLF = c;

                if(c.answerVerified){
                    scope.flags.level = 5;
                    scope.flags.answerVerified = true;
                }
                if(c.projectShortNameList){
                    scope.form.projectShortNameList = c.projectShortNameList;
                    scope.flags.level = 6.5;
                }

                //

                if(!scope.lists.institutions){
                    comMod.getLimitedInstitutionsList(httpLegacy, scope.lists);
                }

                if(!scope.lists.countries){
                    comMod.getLimitedCountries(scope.lists);
                }

                if(!scope.form.id && scope.form.projectShortNameList){
                    getAccountInfo(httpLegacy, http, scope.form, scope.flags, scope.lists, q, scope.LLF, scope);
                }
            }
        }

        function setServices(services){
            // fixme - should only be included in alcf, should not be packaged with cels src
            var i, serv;
            for (i in services){
              serv = services[i];
              jsServices[serv.sname] = serv;
            }    
            cookies = jsServices.cookies;
            if (!cookies.getObject) cookies.getObject = cookies.get;
            httpLegacy = jsServices.http;
            http    = jsServices.httpNew;
            timeout = jsServices.timeoutObj.timeout;
            win     = jsServices.window;
    
            comMod = jsServices.commonModule;
            valMod = jsServices.validationModule;
            edrMod = jsServices.editRecordModule;
            acUpMod = jsServices.accountUpdateModule;
        }


        function validate(httpLegacy, http, form, flags, lists, q, cookies, LLF, scope){
            if (scope && scope.isReactivateSurvey){
                // franky world, validation is mixed with many other modules
                // don't clear out the errors object, since other field errors might be there 
                // from other module validations
            }else{
                flags.fieldErrors = {};
            };

            flags.noMatch = false;
            valMod.validate('accountReactivate', form, flags.fieldErrors);

            // special code for data survey
            if (scope && scope.isReactivateSurvey){
                            if (!form.comment){
                                // make country a required field
                                flags.fieldErrors.comment = {error: 'Country is required', required : true};
                            }
            }

            flags.missing = valMod.computeMissing(flags.fieldErrors);
            var cookieObj = comMod.getLocalStorage('anl-UB3-LLF');
            if ((typeof cookieObj) == 'string') cookieObj = JSON.parse(cookieObj);
            
            if(!form.projectShortNameList || (form.projectShortNameList == '')){
                flags.level = 5;
            }
            if(form.projectShortNameList && (form.projectShortNameList != '')){
                
                flags.level = 6.5;
                cookieObj.projectShortNameList = form.projectShortNameList;
                comMod.setLocalStorage('anl-UB3-LLF', cookieObj);

                if(!form.id){
                    getAccountInfo(httpLegacy, http, form, flags, lists, q, LLF, scope);
                }
            }
        }

        function reactAnswerVerified(cookies, scope){
            var cookieObj = comMod.getLocalStorage('anl-UB3-LLF');

            cookieObj.answerVerified = true;
            comMod.setLocalStorage('anl-UB3-LLF', cookieObj);

            if(scope.isReactivateSurvey){
                scope.reactValidate();
            }
        }

        function getAccountInfo(httpLegacy, http, form, flags, lists, q, LLF, scope){
            flags.getApiNames = ['account', 'addresses', 'emails', 'institutions', 'phones', 'projects', 'resources'];
            flags.prevSavedForm = {};
            flags.accountUpdate = true;
            //If User has a project show account update limited

            edrMod.getRecord(http, flags, lists, null, function(newForm){
                
                flags.prevSavedForm = {};
                lists.usaStates = comMod.getUsaStates();
                if(!lists.careerLevels){comMod.careerLevels(lists)}
                newForm.projectShortNameList = form.projectShortNameList;
                newForm.policyAlcfAck = form.policyAlcfAck ? true : false;
                // policyAck should be unchecked when page first loads
                newForm.policyAck = false;
                // if UNKNOWN clear and require user to choose from list
                if(newForm.employment_level ==='UNKNOWN'){
                        newForm.employment_level = '';
                }
                if(flags.isReactivateSurvey){
                    if(newForm.usCitizen){newForm.comment = 'United States';}
                    else {newForm.comment = '';}
                }
                //make them click this every time
                newForm.policyAck = false;
                
                //angular.copy(newForm, form);
                comMod.objCopyGuts(newForm, form);
                
                comMod.objCopyGuts(newForm, flags.prevSavedForm);
                var i, val;
                for (i in form.accountAddresses){
                    val = form.accountAddresses[i];
                    if(val.hasOwnProperty('method') && val.label == "Shipping"){
                        val['city'] = '';
                        val['country'] = '';
                        val['state'] = '';
                        val['street1'] = '';
                        val['street2'] = '';
                        val['street3'] = '';
                        val['zip'] = '';
                    }
                };

                if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){
                    // made copy of data from apis, lets open form page
                    flags.viewDetails = true;
                    acUpMod.myFavor(httpLegacy, flags, form);
                    // validate form again we have new data pushed in
                    edrMod.validateAccountUpdate(form, flags);
                    comMod.validateListsAfterGet(flags, form);
                }
            });
            comMod.doWhenAPICallsDone(function(){
                acUpMod.validateAllFields(form);
            });
        }

        function addressChecked(httpLegacy, flags, form, lists){
            form.addressVal = !form.addressVal;
            Object.keys(form.accountAddresses[0]).forEach(function(key, val){
                if(form.addressVal && key != 'label'){
                    form.accountAddresses[1][key] = form.accountAddresses[0][key];
                } else{
                    form.accountAddresses[1][key];
                }
            });
        }

        function isSaveDisabled(form, scope){
            if (scope.isReactivateSurvey){
                if (!form.comment || (form.comment == '')){
                    scope.flags.fieldErrors.comment = {
                        label: 'Country of citizenship',
                        error: 'Country is required', 
                        dispRequired : true, 
                        required : true
                    };
                    return true;
                }
                return false;
            } else{
                if (scope.flags.newFavorDataNeeded === 'true'){
                    return true;
                }
                if(!form.policyAck || !form.policyAlcfAck){
                    return true;
                }
            }
        }

        // Contextual Messaging Info to display
        function domainGlobals(lists, flags){
            httpLegacy.get("public/domainGlobals", {params : {}})
                .success(function(resp){
                    if(resp.success){
                        lists.domainGlobals = resp.domainGlobals;
                    }
                });
        }

        function saveAndReturnLater(scope){
            scope.flags.newFavorDataNeeded = false;
            scope.updateAccountInfo();
            comMod.signOut();
        }

        function submitReactivate(scope){
            if (scope.isReactivateSurvey){
                scope.updateAccountInfo();
                return;
            }
            scope.verifyAccount();
            if(!scope.confirmBtn && scope.isReactivateSurvey){
                return;
            }else{
                scope.updateAccountInfo();
            }
        }

        function addItemsToObj(src,tgt){
            var name;
            for (name in src){
                tgt[name] = src[name];
            }
        }

        function reactivateRequest(scope, http, cookies, form, flags, alerts){
            comMod.scrollToHasError(scope, function(){
                var modForm, emailNew, c;
                if(flags.errorStatus){ return;}

                form.accountEmails.forEach(function(dict){
                    if(dict.primary){emailNew = dict.email;}
                });

                modForm = {
                    // original values from GET
                    firstName : flags.prevSavedForm.firstName,
                    lastName : flags.prevSavedForm.lastName,
                    email : scope.user.email, // this would also work:  form.accountEmails[0].email
                    // 'if' they changed anything will be included here
                    projectShortName : form.projectShortNameList,
                    firstNameNew : form.firstName,
                    lastNameNew : form.lastName,
                    emailNew : emailNew
                }
                if(form.comment){
                    modForm.comment = form.comment;
                }

                if(flags.newFavorDataNeeded === 'true' && !flags.favorComplete && !flags.isReactivateSurvey){
                    alerts.push({
                        type : 'danger',
                        msg : "Unable to process the request. Please Enter Foreign National Info"
                    });
                    return;
                }

                var cdata = {form : modForm};

                flags.error = null;
                http.post(flags.apiType + "reactivationRequest", cdata)
                    .then(function(response){
                        if(response.success || (response.data && response.data.success)){
                            // reactivation is good
                            // ok to submit any account changes user made to form(s)
                            localStorage.removeItem('anl-UB3-LLF');
                            
                            flags.reactivateSubmitted = true;
                            delete form.projectShortNameList;
                            delete flags.fieldErrors;

                        } else{
                            flags.reactivateSubmitted = false;
                            alerts.push({
                                type : 'danger',
                                msg : response.error
                            });
                        }
                    }).catch(function(response, status){
                    if(response.status === 403){
                        alerts.push({
                            type : 'danger',
                            msg : 'Your session has expired. Please select "Cancel Reactivation Request", and try again.'
                        });
                    } else {
                        alerts.push({
                            type : 'danger',
                            msg : '404 not found'
                        });

                    }
                });
            });
        }

        // ----------- public stuff gets returned -----------
        return publicStuff;

})();

module.exports = {accountReactivateModule}
