<h2 style="color:#009900;">Logs Search</h2>
<div class="logs">
    <div>
        <span colspan="12" class="small">
            Note: To search through UB1 logs using username, use action_change.
        </span>
    </div>
    <!-- adding the search filter screen -->
    <multi-filter
          [addmore] = "addMore"
          [adminvalidate] = "adminValidate"
          [checksearchfields] = "checkSearchFields"
          [clearsearch] = "clearSearch"
          [closeduplmodal] = "closeDuplModal"
          [downloadsearchresults] = "downloadSearchResults"
          [dupmsg] = "flags.dupWarnMsg"
          [filterhelp] = "filterHelp"
          [filterlist] = "flags.filters"
          [filterrows] = "lists.filterRows"
          [filtervalues] = "lists.filterValues"
          [flags] = "flags"
          [form] = "form"
          [init] = "initMultiFilter"
          [lists] = "lists"
          [path] = "lists.searchPath"
          [removefilter] = "removeFilter"
          [resp] = "resp"
          [rows2display] = "rows2display"
          [searchresults] = "searchResults"
          shownextresults = "showNextResults"
          showprevresults = "showPrevResults"
          [submitfilters] = "submitFilters"
          updatesearch = "updateSearch">
    </multi-filter>

    <div *ngIf="!flags.getSearchHelp">
        <h4 style="color:red;"
            *ngIf="(lists.filterValues.searchFilterErrorMsg !== 'param searchLog not provided') && (!lists.filterRows.length)" id="errorMessage">
            {{lists.filterValues.searchFilterErrorMsg}}
        </h4>
        <h4 *ngIf="flags.showEmptyResults">No Details Found</h4>
        <warning fid="searchName"></warning>
        <div class="match-not-found" *ngIf="!flags.matchedName && flags.searchCompleted">Match not found</div>
    </div>

    <table class="clear filterNav">
        <tr *ngIf="!flags.getSearchHelp && lists.filterRows.length > 0" height="35px">
            <td id="totalRows-svcLog" *ngIf="lists.filterRows.length > 0">Total Rows: {{lists.filterRows.length}}</td>
            <td>
                Number of Rows to display:
                <input id="rowsPerPg-svcLog" class="displayRows" type="number" [(ngModel)]='rows2display'/>
            </td>
            <td>
                <button
                    id="clearSearchResults-svcLog"
                    class="btn btn-danger btn-sm"
                    (click)="lists.filterRows=[];">
                    Clear Search Results
                </button>
            </td>
        </tr>
    </table>
    <table class="table mb0 maxContent" *ngIf="!flags.getSearchHelp && lists.filterRows.length > 0">
        <tr>
            <td>
                <button class="toggleBtn btn btn-primary sfBtn"
                        id="expandAll"
                        (click)="toggleAction()"> Expand/Collapse All
                </button>
            </td>
        </tr>
    </table>

    <div id="searchResultLogsTable" *ngIf="!flags.getSearchHelp && lists.filterRows.length > 0">
        <ngx-datatable
            #logsTable
            class="bootstrap card expandable"
            [limit]="rows2display"
            [rowHeight]="50"
            [headerHeight]="50"
            [footerHeight]="50"
            [scrollbarH]="true"
            [rows]="lists.filterRows"
            [sorts]="[{prop: 'id', dir: 'desc'}]">

            <ngx-datatable-row-detail [rowHeight]="auto" #actionChangeMsgRow>
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div id="action_change" class="isRowVisible" *ngIf="row.action_change">
                        <div><b>Action Change</b></div>
                        <ul class="list-unstyled" *ngFor="let act of row.action_change">
                            <li> {{act}}</li>
                        </ul>
                    </div>
                    <div class="isRowVisible" *ngIf="row.msg">
                        <div><b>Msg</b></div>
                        <ul class="list-unstyled" *ngFor="let mesg of row.msg">
                            <li> {{mesg}}</li>
                        </ul>
                    </div>
                </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column name="Id"             prop="id"           width=90 ></ngx-datatable-column>
            <ngx-datatable-column name="Reference"      prop="reference"    width=90 ></ngx-datatable-column>
            <ngx-datatable-column name="Created Date"   prop="created_date"          ></ngx-datatable-column>
            <ngx-datatable-column name="Auth User"      prop="auth_user"             >
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span [innerHtml]="row.auth_user" class="wordWrap"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Level Name"     prop="levelname"    width=100></ngx-datatable-column>
            <ngx-datatable-column name="Action">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                    <div (click)="toggleExpandRow(row, expanded)">
                        <span>{{row.action}}</span>
                        <b  style="margin-left:5px; color: blue"
                        id="action{{rowIndex}}"
                            [class.datatable-icon-up]="expanded"
                            [class.datatable-icon-down]="!expanded"
                            title="Expand/Collapse Row">
                        </b>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action Info"    prop="action_info" width=690></ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
