export var appRoutes: any = {
    develop: {
        ub3: 'https://localhost:9900/accounts',
        ni: 'https://localhost:3333/ni',
        portal: 'https://localhost:4444',
        arm: 'https://localhost:5555/arm',
    },
    prod_local: {
        ub3: 'https://localhost:9002/accounts',
        portal: 'https://localhost:9002',
        ni: 'https://localhost:9002/ni',
        arm: 'https://localhost:9002/arm',
    },
    staging: {
        ub3: 'https://my-stg.alcf.anl.gov/accounts',
        ni: 'https://my-stg.alcf.anl.gov/ni',
        portal: 'https://my-stg.alcf.anl.gov',
        arm: 'https://my-stg.alcf.anl.gov/arm'
    },
    production:{
        ub3: 'https://my.alcf.anl.gov/accounts',
        ni: 'https://my.alcf.anl.gov/ni',
        portal: 'https://my.alcf.anl.gov',
        arm: 'https://my.alcf.anl.gov/arm',
    },
      production_internal:{
        ub3: 'https://my-internal.alcf.anl.gov/accounts',
        ni: 'https://my-internal.alcf.anl.gov/ni',
        portal: 'https://my-internal.alcf.anl.gov',
        arm: 'https://my-internal.alcf.anl.gov/arm',
    }
}

export var noticeToUsers: string = "This is for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy. <br />\n" +
    "Any or all uses of this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed to authorized site, Department of Energy, and law enforcement personnel, as well as authorized officials of other agencies, both domestic and foreign. By using this system, the user consents to such interception, monitoring, recording, copying, auditing, inspection, and disclosure at the discretion of authorized site or Department of Energy personnel.\n" +
    "Unauthorized or improper use of this system may result in administrative disciplinary action and civil and criminal penalties. By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use. LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this warning."

