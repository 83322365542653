<div class="row aclTagPage">
    <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card ub3-card acls">
            <h2 class="aclHeading">ACL Tags</h2>
            <div class="aclNote">
                Currently ACL Tags restrict Domain Admins from adding users to projects/unixgroups/systems on administration screens. ACL Tags do not restrict changes on user screens.
            </div>
            <!-- Alert Section -->
            <div *ngIf="authAlerts.length > 0">
                <div class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index">
                <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
                    {{ alert.msg }}
                </ngb-alert>
                </div>
            </div>

            <!-- Create & List ACL Tags -->
            <div class="card-body" *ngIf="!flags.openDetailsSection">
                <h4>Create New ACL Tags</h4>
                <table class="table-condensed aclTableH" id="aclCreateTable">
                        <tbody>
                            <tr>
                                <td>
                                    ACL Tag Name:
                                    <input  type="text"
                                            class="form-control ml5"
                                            id="aclTagName"
                                            placeholder="ACL Tag Name"
                                            [(ngModel)]="aclForm.name"
                                            (ngModelChange)="validateAcl();checkForExisting(aclForm)">
                                </td>
                                <td>
                                    Access Type:
                                    <select id="aclTagType"
                                            [(ngModel)]="aclForm.acltag_access_type"
                                            ngDefaultControl
                                            name="aclTagType"
                                            (ngModelChange)="validateAcl()"
                                            class="form-control ml5">
                                            <option value="none" [disabled]="true">None</option>
                                            <option value={{at.name}} *ngFor="let at of lists.availableAccesstypes">{{at.name}}</option>
                                    </select>
                                </td>
                                <td>
                                    Assigned by<br>Super User Only?
                                    <ul class="list-inline inlineBlk">
                                        <li>
                                           <label><input type="radio" [(ngModel)]="aclForm.superuser_assigned" id="superuserYes"
                                              name="superUser"
                                              [value]=true><span>Yes</span></label>
                                        </li>
                                        <li>
                                           <label><input type="radio" [(ngModel)]="aclForm.superuser_assigned" id="superuserNo"
                                              name="superUser" [value]=false><span>No</span></label>
                                        </li>
                                     </ul>
                                </td>
                                <td>
                                    Comment:
                                    <textarea rows="auto" cols="40"
                                            class="form-control ml5"
                                            id="aclComment"
                                            placeholder="ACL Tag Comment"
                                            [(ngModel)]="aclForm.comment"></textarea>
                                </td>
                                <td>
                                    <button type='submit'
                                        class="btn btn-primary btn-md aclBtn"
                                        (click)="createAclTag(aclForm)"
                                        id="createAclTag"
                                        [disabled]="!aclFormCompleted"
                                        uib-tooltip=""
                                        tooltip-placement="right">
                                        Create ACL Tag
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                </table>
                <h4>List of all ACL Tags</h4>
                <div id="aclTagList" class="aclTagList">
                    <ngx-datatable
                        #aclList
                        id="aclList"
                        class="bootstrap card expandable lfont"
                        columnMode="flex"
                        [rowHeight]="auto"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [rows]="lists.allAclTags"
                        [sorts]="[{prop: 'name', dir: 'asc'}]">
                        <ngx-datatable-column name="ACL Tag Name" prop="name" [flexGrow]=".5">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <a
                                    class="text-primary1 bold"
                                    id="name_{{value}}"
                                    (click)="goToAclEditPage(row)">
                                    {{value}}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="ACL Tag Type" prop="acltag_access_type" [flexGrow]=".4">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Super User" prop="superuser_assigned" [flexGrow]=".3">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Comment" prop="comment" [flexGrow]="1">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>

            <!-- ACL Tag Details -->
            <div class="card-body" *ngIf="flags.openDetailsSection">
                <div class="aclCloseEditSection">
                    <button
                        class="btn btn-primary btn-lg right"
                        id="closeEdit"
                        (click)="closeEditPage()">
                            Close Edit page
                    </button>
                </div>
                <h4 id="editAclTag">Edit ACL Tag: <b>{{selectedRow.name}}</b></h4>
                <div class="aclEditSecion">
                    <table class="table-condensed aclTableH" id="aclEditTable">
                            <tbody>
                                <tr>
                                    <td>
                                        ACL Tag Name:
                                        <input 
                                            type="text" class="form-control"
                                            id="selectedName"
                                            autocomplete="nope"
                                            placeholder="Name"
                                            [(ngModel)]="selectedRow.name"
                                            (ngModelChange)="validateEdit(selectedRow); checkForExisting(selectedRow);">
                                    </td>
                                    <td>
                                        Access Type:
                                        <select id="selectedType"
                                            [(ngModel)]="selectedRow.acltag_access_type"
                                            (change)="validateEdit(selectedRow)"
                                            class="form-control">
                                            <option value={{at.name}} *ngFor="let at of lists.availableAccesstypes">{{at.name}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        Assigned by<br>Super User Only?
                                        <ul class="list-inline inlineBlk">
                                            <li>
                                            <label>
                                                    <input type="radio"
                                                        [(ngModel)]="selectedRow.superuser_assigned"
                                                        id="selectedSuperUserYes"
                                                        name="superUser"
                                                        (change)="validateEdit(selectedRow)"
                                                        [value]=true>
                                                    <span class="ml5">Yes</span>
                                                </label>
                                            </li>
                                            <li>
                                                <label>
                                                    <input type="radio"
                                                        [(ngModel)]="selectedRow.superuser_assigned"
                                                        id="selectedSuperUserNo"
                                                        name="superUser"
                                                        (change)="validateEdit(selectedRow)"
                                                        [value]=false>
                                                    <span class="ml5">No</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        Comment:
                                        <textarea 
                                            class="form-control"
                                            id="selectedComment"
                                            rows="auto" cols="40"
                                            autocomplete="nope"
                                            placeholder="Comment"
                                            [(ngModel)]="selectedRow.comment"
                                            (ngModelChange)="validateEdit(selectedRow)"></textarea>
                                    </td>
                                    <td>
                                        <button type='submit'
                                            class="btn btn-primary btn-md aclBtn"
                                            (click)="modifyAclTag(selectedRow)"
                                            id="saveAclTagChanges"
                                            [disabled]="!flags.delta"
                                            uib-tooltip=""
                                            tooltip-placement="right">
                                            Save Changes
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
                <h4>Members & Resources associated with the ACL Tag: <b>{{selectedRow.name}}</b></h4>
                <div class="memResoSecion">
                    <div class="mt20">
                        <div *ngFor="let brg of lists.bridgeKeys">
                            <h5><button class="text-primary1 bold aclBrdgBtn">{{lists.aclBridges[brg].label}} ACL Tags</button></h5>
                            <table class="table-condensed aclTableH" id="{{brg}}AclAddTable">
                                    <thead><h4>Add ACL Tag for the following {{brg}}:</h4></thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{lists.aclBridges[brg].label}} Name:
                                                <input  type="text"
                                                        class="form-control ml5"
                                                        id="{{brg}}Name"
                                                        placeholder="Please type more than two characters"
                                                        [(ngModel)]="form.aclBrgForm[brg].name"
                                                        ngDefaultControl
                                                        name="{{brg}}Name"
                                                        (ngModelChange)="searchForBrgKey(form.aclBrgForm[brg].name, brg); getInfo(form.aclBrgForm[brg], brg)"
                                                        [ngbTypeahead]="lists.aclBridges[brg].searchFn">
                                            </td>
                                            <span *ngIf="form.aclBrgForm[brg].id">
                                                <span *ngIf="brg === 'account'">
                                                    <td>
                                                        First Name:<br>{{form.aclBrgForm[brg].first_name}}
                                                    </td>
                                                    <td>
                                                        Last Name:<br>{{form.aclBrgForm[brg].last_name}}
                                                    </td>
                                                    <td>
                                                        Primary Email:<br>{{form.aclBrgForm[brg].primary_email}}
                                                    </td>
                                                    <td>
                                                        Status:<br>{{form.aclBrgForm[brg].status}}
                                                    </td>
                                                    <td>
                                                        US Citizen:<br>{{form.aclBrgForm[brg].us_citizen}}
                                                    </td>
                                                </span>
                                                <span *ngIf="brg === 'project'">
                                                    <td>
                                                        Title:<br>{{form.aclBrgForm[brg].title}}
                                                    </td>
                                                    <td>
                                                        exportControlledSw:<br>{{form.aclBrgForm[brg].exportControlledSw}}
                                                    </td>
                                                </span>
                                            </span>
                                            <td>
                                                Comment:
                                                <textarea rows="auto" cols="40"
                                                        class="form-control ml5"
                                                        id="{{brg}}Comment"
                                                        placeholder="Comment"
                                                        [(ngModel)]="form.aclBrgForm[brg].comment"></textarea>
                                            </td>
                                            <td>
                                                <button type='submit'
                                                    class="btn btn-primary btn-md aclBtn"
                                                    (click)="addMemResoAclTag(selectedRow, form.aclBrgForm[brg], brg)"
                                                    id="{{brg}}AclTagAddBtn"
                                                    uib-tooltip=""
                                                    tooltip-placement="right"
                                                    [disabled]="!form.aclBrgForm[brg].id">
                                                    Add ACL Tag
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                    <h4>List of all members & resources with ACL Tag: <b>{{selectedRow.name}}</b></h4>
                    <div id="brigeAclTagList" class="aclTagList">
                        <ngx-datatable
                            #brdgAclList
                            id="brdgAclList"
                            class="bootstrap card expandable lfont"
                            columnMode="flex"
                            [rowHeight]="auto"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            [scrollbarH]="true"
                            [rows]="lists.combinedList"
                            [sorts]="[{prop: 'name', dir: 'asc'}]">
                            <ngx-datatable-column name="Name" prop="name" [flexGrow]=".5">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div>
                                        <span id="name_{{row.name}}">{{row.name}}</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Bridge Type" prop="bridge" [flexGrow]=".3">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div>
                                        <span id="bridge_{{row.name}}-{{row.bridge}}">{{row.bridge}}</span>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Comment" prop="comment" [flexGrow]=".6">
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <div>
                                        <input type="text"
                                                class="form-control ml5 inlineBlk brdgComnt"
                                                id="comment_{{row.name}}"
                                                placeholder="Comment"
                                                [(ngModel)]="row.comment"
                                                (ngModelChange)="enableSaveBtn(row, rowIndex)">
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [flexGrow]=".2" [sortable]='false'>
                                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                    <button type='submit'
                                            class="btn btn-primary btn-md"
                                            (click)="modifyAclTagBrdg(selectedRow, row, rowIndex)"
                                            id="saveBrdgChanges"
                                            *ngIf="flags.brdgDelta[rowIndex]">
                                            Save Changes
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
