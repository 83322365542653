import { Component, OnInit, Input } from '@angular/core';
import {CommonService} from '@common/services';

var ub3consoleLog = null;
var t = null;
@Component({
  selector: 'warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css']
})
export class WarningComponent implements OnInit {

  fieldErrors = null;

  constructor(private commonService: CommonService) { 
    t = this;
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.fieldErrors = this.commonService.getSharedObject('warning', 'fieldErrors');
  }

  @Input() fid: string;

  ngOnInit(): void {                
    //log('20 warning.ngOnInit', {fid: t.fid, err: t.fieldErrors});
  }

}
