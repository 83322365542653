<br>
<h2>{{flags.displayedPageName}}</h2>
<br>
<br>

        Filter by project name <img width=5px height=0 border=0>
        <input  
            type=text 
            id="cand"
            [(ngModel)]="flags.cand"
            (ngModelChange)="flags.piCand = ''; matchCandidateProject('byProjectName'); "
        >
        <img border=0 width=50px height=0px>
        <button 
            class="btn btn-primary btn-sm" 
            id="newProjectBtn"
            (click)="initProjectEditForm(flags.cand, 'new')"
            [disabled]="!flags.allowPjCreate"
            >
            Create new project: {{flags.cand}}
        </button>
        <img border=0 width=200px height=0px>
        <br>
        Filter by PI name <img width=5px height=0px border=0>
        <input  
            type=text 
            id="piCand"
            [(ngModel)]="flags.piCand" 
            (ngModelChange)="flags.cand = ''; matchCandidateProject('byPiName'); "
        >
        <br>
        <span *ngIf="lists.matchedProjects && lists.matchedProjects.length > 0">

            <ngx-datatable
                id="pjSearchResults"
                class="bootstrap card expandable centerText"
                columnMode="flex"
                [rowHeight]="auto"
                [headerHeight]="50"
                [footerHeight]="50"
                [scrollbarH]="true"
                [sorts]="[{prop: 'name', dir: 'asc'}]"
                [rows]="lists.matchedProjects">

                <ngx-datatable-column name="Name" prop="name" [flexGrow]="1">

                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Name</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <a 
                            (click)="initProjectEditForm(row.name, 'edit')"
                            id="edit_{{row.name}}"
                            style="cursor: pointer">
                            <font class="projName">{{row.name}}</font>
                        </a>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Title" prop="title" [flexGrow]="2.4">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Proj Title</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{row.title}}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="PI" prop="pi" [flexGrow]="1.3">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Principal Investigator</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{row.pi}} 
                        {{row.piPrefName ? '('+row.piPrefName+')' : ''}}
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column name="Status" prop="projectStatus" [flexGrow]="1">
                  <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                        <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project Status</b></span>
                  </ng-template>
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        {{row.projectStatus}} 
                  </ng-template>
                </ngx-datatable-column>

            </ngx-datatable>

        </span>
        <div *ngIf="flags.pjsListError">
            <font color="#ff0000">{{flags.pjsListError}}</font>
        </div>



