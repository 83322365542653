import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
import {pluginsAdminModule} from 'pluginsAdminModule';

let scope: any = {
  publicPage: function (b) {},
  lists: {},
  flags:{},
  form: {}
};
let authAlerts = [];
let t = null;
let http = null;
let cookies = null;

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.css']
})
export class PluginsComponent implements OnInit {
  lists = {
    install : false,
    plugins : false,
    isPeriodicUibOpen : false,
    isTriggerUibOpen  : false
  };
  authAlerts = [];
  periodics = [];
  triggers  = [];
  mobile: boolean;

  constructor(
    public cookie: CookieService,
    public h: Ub3httpService,
    public commonService: CommonService
  ) {
    t = this;
    http = h;
    cookies = cookie;
    t.lists = scope.lists;
    t.flags = scope.flags;
    t.form  = scope.form;
    t.authAlerts = authAlerts;
  }

  ngOnInit(): void {
    document.body.classList.remove('public-page');
    pluginsAdminModule.init(t, http, t.lists, 'Plugins', function(){
      t.editPlugins(t.lists);
    });
  }
  editPlugins(l){
    t.periodics = l.plugins[0].splitArr.periodics;
    t.triggers  = l.plugins[0].splitArr.trigger;
    t.periodics.forEach(function(item){
      item.edit = false;
    });
  }

  pluginDirInstall(){
    pluginsAdminModule.postPluginDirInstall(http, t.lists, cookies, function(s,l){
      t.authAlerts = s.authAlerts;
      t.lists = l;
      t.editPlugins(t.lists);
    });
  }
  pluginDirUninstall(){
    pluginsAdminModule.postPluginDirUninstall(http, t.lists, cookies, function(a){
      t.authAlerts = a;
    });
  }
  pluginActivateOrDeactivate(type, active){
    pluginsAdminModule.pluginActivateOrDeactivate(type, active, http, t.lists, cookies, function(a){
      t.authAlerts = a;
    });
  }
  pluginRunPeriodic(type){
    pluginsAdminModule.pluginRunPeriodics(http, t.lists, type, function(a){
      t.authAlerts = a;
    });
  }
  savePlugin(type){
    pluginsAdminModule.savePlugin(http, t.lists, cookies, type, function(a){
      t.authAlerts = a;
    });
  }
  pluginDirActivateOrDeactivate(type){
    if(type.active) {
      pluginsAdminModule.postPluginDirActivate(http, cookies, function(a){
        t.authAlerts = a;
      });
    } else {
      pluginsAdminModule.postPluginDirDeactivate(http, cookies, function(a){
        t.authAlerts = a;
      });
    }
  }
  setupPlugin(type){
    pluginsAdminModule.setupPlugin(http, cookies, type, function(a){
      t.authAlerts = a;
    });
  }
  closeAlert = function(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }

}
