import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import { joinProjectModule } from '../../../../../../../../frontend/joinProjectModule';

let t = null;
let http = null;
let cookies = null;

@Component({
  selector: 'join-project',
  templateUrl: './join-project.component.html',
  styleUrls: ['./join-project.component.css']
})
export class JoinProjectComponent implements OnInit {
  lists = {projectFailure: false, activeProjects:false};
  flags = {
    displayedPageName: '',
  };
  form = {};
  authAlerts =[];
  projDescShow = false;
  displayTable = true;

  @ViewChild('jpTable') table: any;
  @ViewChild("hdrTpl", {static: true}) hdrTpl: TemplateRef<any>;
  expanded: any = false;
  publicPage(b) { };
  constructor(
    private commonService: CommonService,
    public h: Ub3httpService,
    public cookie: CookieService,
  ) {
    t = this;
    http = h;
    cookies = cookie;
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    t.initJoinProject('Join project');
    t.flags.show= {
      actProj : false
    };
    t.flags.filter = {
      actProj: {
        name:'',
        title:'',
        pi:''
      }
    };
  }

  initJoinProject = function (pageName) {
    t.flags['displayedPageName'] = pageName;
    t.authAlerts =[];
    joinProjectModule.init(t, http, pageName, t.lists, t.flags, t.authAlerts);
  }

  initProjectInfo = function(obj, edit, ind, expanded){
    t.isVisiblemsg = false;
    t.projDescShow = false;
    t.projResultData = t.form;
    t.activeIndex = ind;
    t.projectObj = obj;
    t.authAlerts =[];
    // if(obj.isOpen) {
    //     obj.isOpen = false;
    //     return;
    // }
    // t.lists.activeProjects.forEach(function(val, key) {
    //     val.isOpen = false;
    //     if(val.name === obj.name) {
    //         val.isOpen = true;
    //     }
    // });
    joinProjectModule.setProjectInfo(obj.name, edit, http, t.form, t.flags, t.lists, cookies);
    t.commonService.doWhenAPICallsDone(function(){
      t.toggleExpandRow(obj, expanded);
    });
  };

  closeAlert = function(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }

  toggleExpandRow(row, isOpen) {
    t.table.rowDetail.collapseAllRows();
    if(!isOpen) {
      t.table.rowDetail.toggleExpandRow(row);
    }
  }

  projDesc = function(){
      t.projDescShow = !t.projDescShow;
  }

  joinProject = function(obj, projResultData, ind) {
    t.requestMember = ind;
    t.isVisiblemsg = true;
    t.authAlerts =[];
    joinProjectModule.joinProject(http, cookies, t.flags, t.form, t.authAlerts);
  }

  updateFilter(colName){
    t.commonService.onStopCalling(700, function(){
      t.updateFilterNow('actProj', t.lists.activeProjects, colName, 'and', function(){
        // trigger table repaint
        var temp = [];
        t.commonService.arrayCopy(t.lists.activeProjects, temp);
        t.lists.activeProjects = [];
        t.commonService.arrayCopy(temp, t.lists.activeProjects);
      });
    });
  }
  updateFilterNow(arrName, data, colName, op, cb){
    t.commonService.filter(arrName, data, colName, op, t.flags.filter[arrName]);
    setTimeout(function(){
      if(cb) cb();
    }, 50);
  }

}
