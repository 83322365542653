import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';
//import {UserService} from '@app/shared/services';

let t: any = null;
let http: any = null;
let cookies: any = null;
let ub3consoleLog = null;
let state = null;

@Component({
  selector: 'app-acl-tags',
  templateUrl: './acl-tags.component.html',
  styleUrls: ['./acl-tags.component.css']
})
export class AclTagsComponent implements OnInit {
  authAlerts = [];
  alertError = {type : 'danger', msg: ''};
  alertSuccess = {type : 'success', msg: ''};
  aclForm = {
    name : '',
    acltag_access_type : 'none',
    superuser_assigned : true,
    deleted : false,
    comment: ''
  };
  lists: any = {};
  flags = {
    openDetailsSection: null
  };
  selectedRow = {};
  aclFormCompleted = false;
  form: any = {
    aclBrgForm : {},
    aclNameFromUrl: null
  };

  constructor(
    public h: Ub3httpService,
    public commonService: CommonService,
   // private userService: UserService,
    private c: CookieService,
    private window: Window,
    private router: Router
  ) {
    t = this;
    http = h;
    cookies = c;
    ub3consoleLog = t.commonService.ub3consoleLog;
    state = commonService.getSharedObject('app','state');
  }
  @HostListener('window:popstate')
  onPopState() {
    t.ngOnInit();
  }
  ngOnInit(): void {
    if(window.location.hash.length > 0){
      t.form.aclNameFromUrl = (state.domain === 'alcf') ? (t.commonService.urlSearchParamsArray().get('name')) : (decodeURIComponent(window.location.hash).split(':name=')[1]);
    }
    t.lists.allAclTags = [];
    t.lists.availableAccesstypes = [
      // {name: 'Undefined Access Type'},
      {name: 'Denied'},
      {name: 'Read'},
      {name: 'Write'},
      {name: 'Execute'},
      {name: 'ReadWrite'},
      {name: 'ReadWriteExecute'}
    ];
    t.lists.combinedList = [];
    t.lists.aclBridges = {
      account: {
        searchFn: t.searchName,
        brdgApiName: 'AccountAclTag',
        svcApi: '/svc/Account',
        label: 'Account',
        aclTagList: t.lists.allAccAclTags
      }, 
      project: {
        searchFn: t.searchProject,
        brdgApiName: 'ProjectAclTag',
        svcApi: '/svc/Project',
        label: 'Project',
        aclTagList: t.lists.allProjAclTags
      }, 
      unixgroup: {
        searchFn: t.searchUg,
        brdgApiName: 'UnixGroupAclTag',
        svcApi: '/svc/UnixGroup',
        label: 'UnixGroup',
        aclTagList: t.lists.allUgAclTags
      }, 
      system: {
        searchFn: t.searchSystem,
        brdgApiName: 'SystemAclTag',
        svcApi: '/svc/System',
        label: 'System',
        aclTagList: t.lists.allSysAclTags
      }
    };

    t.lists.bridgeKeys = Object.keys(t.lists.aclBridges);
    document.body.classList.remove('public-page');
    t.getAclTagList();
    if(t.form.aclNameFromUrl){
      t.getAnAclTag(t.form.aclNameFromUrl);
    } else {
      t.flags.openDetailsSection = false;
    }
  }

  validateAcl(){
    t.aclFormCompleted = (t.aclForm.name && t.aclForm.acltag_access_type !== 'none');
  }

  // get details of an ACL tag
  getAnAclTag(aclTag){
    t.authAlerts = [];
    var p = {search : 'name='+aclTag};
    http.get("/svc/AclTag", {params : p})
        .then(function(response){
            var resp = response.data;
            if(resp && resp.success && resp.data && resp.data.length > 0){
              t.selectedRow = resp.data[0];
            } else {
                t.alertError.msg = resp.error;
                t.pushAlertToArray(t.alertError);
            }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
    t.commonService.doWhenAPICallsDone(function(){
      t.getAclTagDetails(t.selectedRow);
    });
  }

  // Create new ACL Tag
  createAclTag(aform){
    t.authAlerts = [];
    var cdata = {
      form : {
        acltag_access_type: aform.acltag_access_type,
        deleted: aform.deleted,
        force_delete: false,
        name:aform.name,
        superuser_assigned: aform.superuser_assigned,
        comment: aform.comment
      }
    };

    http.post("svc/AclTag", cdata)
        .then(function(response){
          var resp = response.data;
          if(resp && resp.success){
            t.getAclTagList();
            t.alertSuccess.msg = "Successfully added "+aform.name;
            t.pushAlertToArray(t.alertSuccess);
          } else {
              t.alertError.msg = resp.error;
              t.pushAlertToArray(t.alertError);
          }
        }).catch(function(response, status){
            t.alertError.msg = response.detail;
            t.pushAlertToArray(t.alertError);
        });
    t.commonService.doWhenAPICallsDone(function(){
      t.aclForm = {
        name : '',
        acltag_access_type : 'none',
        superuser_assigned : true,
        deleted : false,
        comment: ''
      };
    });
  }
  // get a list of all ACL tags
  getAclTagList(){
    t.authAlerts = [];
    var p = {search : "%"};
    http.get("/svc/AclTag", {params : p})
        .then(function(response){
            var resp = response.data;
            if(resp && resp.success){
                t.lists.allAclTags = resp.data;
            } else {
                t.alertError.msg = resp.error;
                t.pushAlertToArray(t.alertError);
            }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
  }

  // get information about the members or resources
  getInfo(aForm, key){
    t.commonService.onStopCalling(700, function(){
      t.getBrdgInfo(aForm, key);
    });
  }

  getBrdgInfo(aForm, key){
    t.authAlerts = [];
    var api = t.lists.aclBridges[key].svcApi;
    var p, msgLbl;
    if(key === 'account'){
      var username = aForm.name || aForm.username;
      p = {search : 'username='+username};
      msgLbl = "User Name";
    }
    if(key === 'project' || key === 'unixgroup' || key === 'system'){
      p = {search : 'name='+aForm.name};
      msgLbl = t.commonService.toTitleCase(key);
    }

    http.get(api, {params : p})
        .then(function(response){
            var resp = response.data;
            if(resp && resp.success){
              if(resp.data.length > 0){
                aForm.id = resp.data[0].id;
                if(key === 'account'){
                  aForm.first_name = resp.data[0].first_name;
                  aForm.last_name = resp.data[0].last_name;
                  aForm.primary_email = resp.data[0].primary_email;
                  aForm.status = resp.data[0].status;
                  aForm.us_citizen = resp.data[0].us_citizen;
                }
                if(key === 'project'){
                  aForm.title = resp.data[0].title;
                  aForm.exportControlledSw = resp.data[0].exportControlledSw;
                }
              } else {
                t.alertError.msg = msgLbl + " does not exist";
                t.pushAlertToArray(t.alertError);
              }
            } else {
                t.alertError.msg = resp.error;
                t.pushAlertToArray(t.alertError);
            }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
  }

    // typeahead
  searchForBrgKey(sname, key){
      t.authAlerts = [];
      var api = t.lists.aclBridges[key].svcApi;
      var searchKey;
      if(key === 'account'){
        searchKey = decodeURIComponent("'username=%25"+sname+"'");
      }
      if(key === 'project' || key === 'unixgroup' || key === 'system'){
        searchKey = decodeURIComponent("'name=%25"+sname+"'");
      }
      var p = {search: searchKey};
      http.get(api, {params : p})
          .then(function(response){
            var resp = response.data;
              if(resp && resp.data.length > 0 && resp.success){

                if(key === 'account'){
                  var userRec = resp.data.map((obj) => obj.username);
                  t.lists.userNames = userRec.filter((name) => name.toLowerCase().startsWith(sname.toLowerCase()));
                }
                if(key === 'project'){
                  var projRec = resp.data.map((obj) => obj.name);
                  t.lists.projNames = projRec.filter((name) => name.toLowerCase().startsWith(sname.toLowerCase()));
                }
                if(key === 'unixgroup'){
                  var ugRec = resp.data.map((obj) => obj.name);
                  t.lists.ugNames = ugRec.filter((name) => name.toLowerCase().startsWith(sname.toLowerCase()));
                }
                if(key === 'system'){
                  var sysRec = resp.data.map((obj) => obj.name);
                  t.lists.sysNames = sysRec.filter((name) => name.toLowerCase().startsWith(sname.toLowerCase()));
                }
              }
          }).catch(function(response, status){
            t.alertError.msg = response.data.detail;
            t.pushAlertToArray(t.alertError);
          });
  }

  //ngbTypeahead
  searchName = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.userNames && t.lists.userNames.filter(function(item){
            return item.toLowerCase().indexOf(term.toLowerCase()) > -1
          });
        })
      );
  }

  searchProject = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.projNames && t.lists.projNames.filter(function(item){
            return item.toLowerCase().indexOf(term.toLowerCase()) > -1
          });
        })
      );
  }

  searchUg = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.ugNames && t.lists.ugNames.filter(function(item){
            return item.toLowerCase().indexOf(term.toLowerCase()) > -1
          });
        })
      );
  }

  searchSystem = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.sysNames && t.lists.sysNames.filter(function(item){
            return item.toLowerCase().indexOf(term.toLowerCase()) > -1
          });
        })
      );
  }
  goToAclEditPage(row){
    if(state.domain === 'alcf'){
      this.router.navigateByUrl('/aclAdmin?name=' + row.name);
    } else {
      this.router.navigate(['/domain-admin/aclAdmin/:name=' + row.name]);
    }
    t.getAclTagDetails(row);
  }

  // get details about selected ACL tag
  getAclTagDetails(row){
    t.authAlerts = [];
    t.flags.delta = false;
    t.flags.brdgDelta = {};
    t.selectedRow = t.commonService.objCopy(row);
    t.flags.prevSelectedRow = t.commonService.objCopy(t.selectedRow);
    t.lists.bridgeKeys.forEach(function(key){
      t.form.aclBrgForm[key] = {};
    });
    t.flags.openDetailsSection = true;
    t.getAllMemResAclTagList(row.name);
  }

  modifyAclTag(sRow){
    t.authAlerts = [];
    var cdata = {
      form : {
        acltag_access_type: sRow.acltag_access_type,
        comment: sRow.comment,
        deleted: sRow.deleted,
        force_delete: sRow.force_delete,
        name:sRow.name,
        superuser_assigned: sRow.superuser_assigned
      },
      search: "id="+sRow.id
    };
    http.put("svc/AclTag", cdata)
        .then(function(response){
          var resp = response.data;
          if(resp && resp.success){
            t.getAclTagList();
            t.alertSuccess.msg = 'Successfully modified '+sRow.name;
            t.pushAlertToArray(t.alertSuccess);
            t.flags.prevSelectedRow = t.commonService.objCopy(sRow);
            t.closeEditPage();
          } else {
            t.alertError.msg = resp.error;
            t.pushAlertToArray(t.alertError);
          }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
  }

  closeEditPage(){
    t.authAlerts = [];
    if(t.selectedRow.domain === 'CELS' && t.form.aclNameFromUrl){
      window.location.hash = window.location.hash.split('/:')[0];
    } else if(window.location.hash.length > 0){
      var hash = window.location.hash.replace('#','').split('?')[0];
      window.location.hash = hash;
    }
    t.flags.openDetailsSection = false;
    t.selectedRow = {};
  }

  closeAlert(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }

  validateEdit(sRow){
    t.flags.delta = ! t.commonService.isEqual(t.flags.prevSelectedRow, sRow);
  }

  // check if the ACL tag already exists or not
  checkForExisting(arr){
    t.commonService.onStopCalling(700, function(){
      var aclExists = false;
      t.authAlerts = [];
      aclExists = t.lists.allAclTags.some( tag => tag.name === arr.name );
      if(aclExists){
        t.alertError.msg = 'ACL Tag already exists';
        t.pushAlertToArray(t.alertError);
        arr.name = '';
      }
    });
  }

  getAclReqPayload(sRow, aForm, key){
    var apiName =  t.lists.aclBridges[key].brdgApiName;
    var payload = {
      acltag_id: sRow.id,
      comment: aForm.comment,
      deleted: false,
      force_delete: false
    };
    if(key ==='account') {payload['account_id'] = aForm.id;}
    if(key ==='project') {payload['project_id'] = aForm.id;}
    if(key ==='unixgroup') {payload['unixgroup_id'] = aForm.id;}
    if(key ==='system') {payload['system_id'] = aForm.id;}
    var reqVal = {data:{}, apiName:'',name:''};
    reqVal.name = aForm.name;
    reqVal.apiName = 'svc/'+apiName;
    reqVal.data = {
      form : payload
    };
    return reqVal;
  }
  // add member or resource ACL bridge
  addMemResoAclTag(sRow, aForm, key){
    var alertsArr = [];
    t.addMemResoAclTagNow(sRow, aForm, key, function(alerts1, projAdded){
      alertsArr.push(alerts1);
      if(projAdded){
        var ugForm = {
          name: aForm.name,
          comment: aForm.comment
        };
        key = 'unixgroup';
        t.getBrdgInfo(ugForm, key);
        t.commonService.doWhenAPICallsDone(function(){
          t.addMemResoAclTagNow(sRow, ugForm, key, function(alerts2, projAdded){
            alertsArr.push(alerts2);
          });
        });
      }
    });
    t.commonService.doWhenAPICallsDone(function(){
      t.authAlerts = [];
      t.getAllMemResAclTagList(sRow.name);
      t.authAlerts = [...alertsArr];
        delete aForm.id;
        delete aForm.name;
        delete aForm.comment;
    });
  }
  addMemResoAclTagNow(sRow, aForm, key, callback){
    var alerts = {};
    var projAdded = false;
    var aclReqValues = t.getAclReqPayload(sRow, aForm, key);
    var cdata = aclReqValues.data;
    var apiName = aclReqValues.apiName;
    var name = aclReqValues.name;
    var brgReso = key + " " + name + " to ACL Tag " + sRow.name;
    http.post(apiName, cdata)
        .then(function(response){
          var resp = response.data;
          if(resp && resp.data && resp.data.length > 0 && resp.success){
            projAdded = (key === 'project');
            var succMsg = "Successfully added " + brgReso;
            alerts = {type : 'success', msg : succMsg};
          } else {
            var errMsg = t.showError(brgReso, key, resp.error);
            alerts = {type : 'danger', msg : errMsg};
          }
        }).catch(function(response, status){
            alerts = {type : 'danger', msg : response.detail};
        });
        t.commonService.doWhenAPICallsDone(function(){
          if(callback) callback(alerts, projAdded);
        });
  }
  showError(reso, key, err) {
    var errCodes = [
      'already exists',
      'does not exist'
    ];
    var errMsg = "Cannot add " + reso;
    errCodes.forEach(function(code){
      if(err.toLowerCase().includes(code.toLowerCase())){
        errMsg += ', because this '+ key + ' ' + code;
        return;
      }
    });
    return errMsg;
  }
  getMemResAclTagList(aclTagName, key){
    t.authAlerts = [];
    var apiName =  t.lists.aclBridges[key].brdgApiName;
    // keeping this for future improvement
    // t.userService.getAclBridgeDetails(apiName, aclTagName).subscribe(resp => {
    //   t.lists.aclBridges[key].aclTagList = resp.data;
    // }, error => {
    //   t.alertError.msg = error;
    //   t.pushAlertToArray(t.alertError);
    // });
    var p = {search : "acltagname=="+aclTagName};
    http.get('/svc/'+apiName, {params : p})
        .then(function(response){
            var resp = response.data;
            if(resp && resp.success){
              t.lists.aclBridges[key].aclTagList = resp.data;
            } else {
                t.alertError.msg = resp.error;
                t.pushAlertToArray(t.alertError);
            }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
  }
  getCombinedList(){
    t.lists.combinedList = [];
    t.lists.bridgeKeys.forEach(function(key){
      var aclList = t.lists.aclBridges[key].aclTagList;
      (aclList.length > 0) && aclList.forEach(function(obj){
        t.lists.combinedList.push({
          id: obj.id,
          brdgId : obj.account_id || obj.project_id  || obj.unixgroup_id || obj.system_id,
          name : obj.username || obj.project || obj.unixgroup || obj.system,
          bridge: obj.resource_type || 'Account',
          comment: obj.comment
        });
      });
    });
  }
  getAllMemResAclTagList(aclTagName){
    t.lists.bridgeKeys.forEach(function(key){
      t.getMemResAclTagList(aclTagName, key);
    });
    t.commonService.doWhenAPICallsDone(function(){
      t.getCombinedList();
      window.scrollTo(0, 0);
    });
  }

  // enable save button for a row if its comment is changed
  enableSaveBtn(row, ind){
    t.commonService.onStopCalling(700, function(){
      var key = row.bridge.toLowerCase();
      var name = '';
      var idx = t.lists.aclBridges[key].aclTagList.findIndex(function(item){
        if(key ==='account') name = item.username;
        if(key ==='project') name =  item.project;
        if(key ==='unixgroup') name =item.unixgroup;
        if(key ==='system') name = item.system;
        return name === row.name;
      });
      if(idx > -1){
        t.flags.brdgDelta[ind] = (t.lists.aclBridges[key].aclTagList[idx].comment !== row.comment);
      }
    });
  }
  // Modify ACL bridge changes
  modifyAclTagBrdg(sRow, row, ind){
    t.authAlerts = [];
    var key = row.bridge.toLowerCase();
    var apiName =  t.lists.aclBridges[key].brdgApiName;
    var payload = {
      acltag_id: sRow.id,
      comment: row.comment,
      deleted: false,
      force_delete: false
    };
    var changeKey = row.name;
    if(key ==='account') {payload['account_id'] = row.brdgId;}
    if(key ==='project') {payload['project_id'] = row.brdgId;}
    if(key ==='unixgroup') {payload['unixgroup_id'] = row.brdgId;}
    if(key ==='system') {payload['system_id'] = row.brdgId;}
    var cdata = {
      form : payload,
      search: "id="+row.id
    };
    http.put('svc/'+apiName, cdata)
        .then(function(response){
          var resp = response.data;
          if(resp && resp.success){
            t.flags.brdgDelta[ind] = false;
            t.alertSuccess.msg = 'Successfully modified '+changeKey;
            t.pushAlertToArray(t.alertSuccess);
            window.scrollTo(0, 0);
          } else {
            t.alertError.msg = resp.error;
            t.pushAlertToArray(t.alertError);
          }
        }).catch(function(response, status){
          t.alertError.msg = response.data.detail;
          t.pushAlertToArray(t.alertError);
        });
    t.commonService.doWhenAPICallsDone(function(){
      t.getAllMemResAclTagList(sRow.name);
    });
  }

  pushAlertToArray(alert){
    t.commonService.doWhenAPICallsDone(function(){
      t.authAlerts.push(alert);
    });
  }

}