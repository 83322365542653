<div class="manage-projects">
    <div class="reversed-white">
       <h1>{{flags.displayedPageName}}</h1>
       <div id="manageProject">Manage your project team and proxies</div>
    </div>
    <div class="loading-overlay" *ngIf="showSpinner">
        <div class="spinner-border" role="status">
            <span class="sr-only" id="spinner"></span>
        </div>
    </div>
    <div class="ub3-card white-container mt20">
        <div class="card-body white-container col-xs-12">
            <div>
                <a routerLink="/manageProjects" (click)='initManageProject("Project Management")' id="btpl">
                    Back to Project List
                </a>
            </div>
            <div id="projectDetails" class="ub3-card teamMemship">
                <h4 class="table-heading">Project Information</h4>
                <div class="card-body proj-body col-xs-12">
                    <div class="managementViewProjDetails">
                        <div *ngIf="authAlerts.length > 0 && !flags.isYourGroupOpen">
                            <div class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index"  id="alertMsg">
                                <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
                                    {{ alert.msg }}
                                </ngb-alert>
                            </div>
                        </div>
                        <div class="projDetails">
                        <div id="{{form.projectShortName}}"><span>Project Name: </span>{{form.projectShortName}}</div>
                        <div><span>Project Status: </span>{{form.status}}</div>
                        <div><span id="{{form.piFirstName}}" class="pi">PI:</span>{{form.piFirstName}} {{form.piLastName}} ({{form.piPreferredName}})</div>
                        <div><span class="piEmail">PI Email: </span>{{form.piEmail}}</div>
                        </div>
                        <div class="form-group minHt clear">
                            <div class="floating-label-wrapper">
                                <input  class="form-control inBox"
                                    type="text"
                                    [(ngModel)]="form.projectTitle"
                                    id="projectTitle"
                                    name="projectTitle"
                                    ngDefaultControl
                                    placeholder=" "
                                    (keyup)="validatePageDelayed();"
                                    size=40>
                                <label for="projectTitle" class="inputLbl">Project Title</label>
                            </div>
                        </div>
                        <warning fid="projectTitle"></warning>
                        <br>

                        <div class="form-group minHt clear">
                            <div class="floating-label-wrapper">
                                <input  class="form-control inBox"
                                    type="text"
                                    [(ngModel)]="form.fundingAgency"
                                    id="fundingAgency"
                                    name="fundingAgency"
                                    ngDefaultControl
                                    placeholder=" "
                                    (keyup)="validatePageDelayed();"
                                    size=40>
                                <label for="fundingAgency" class="inputLbl">Funding Sources</label>
                            </div>
                        </div>
                        <warning fid="fundingAgency"></warning>
                        <br>
                        <div class="mb20">
                            <input
                                type='checkbox'
                                (change)="validatePageNow()"
                                [(ngModel)]="form.industry">
                            <span class="table-heading">
                                Industry-related project
                            </span>
                            <div>
                                This project has team members from industry and/or the results are intended for industry use.
                            </div>
                        </div>
                        <div class="hidden" id="numInst">{{form.projectInstitutions && form.projectInstitutions.length}}</div>
                        <div id="projInstns" *ngFor="let f of form.projectInstitutions; let i = index;" class="acountInst">
                            <div *ngIf="f.status !== 'Deleted'" class="form-group floating-label-wrapper affiliation">
                                <label for="affiliation"></label>
                                <input style="visibility: hidden; position: absolute; left:-99999px" name="affiliation" type="text" [(ngModel)]="f.affiliation">

                                <input
                                    type="text"
                                    [(ngModel)]="f.affiliation"
                                    id="affiliation{{i}}"
                                    name="affiliation{{i}}"
                                    ngDefaultControl
                                    placeholder=" "
                                    [ngbTypeahead]="searchAccInstn"
                                    (ngModelChange)="validatePageDelayed(); f.institutionId = getInstitutionId(f.affiliation);"
                                    (blur)="validatePageNow()"
                                    class="form-control inBox"
                                    [disabled]="(f.id >= 0)"
                                    autocomplete="nope">
                                    <label for="affiliation{{i}}" class="inputLbl1">Project Institution</label>

                                <span class="cBox"
                                    id='primInst{{i}}'
                                    (click)="selectPrimaryInstitution(f, form.projectInstitutions);validatePageNow()">
                                    <!-- (blur)="validateInput('affiliation',$event,i)" -->
                                    <span class="cr">
                                        <i *ngIf="f.primaryInstitution"  class="cr-icon fas fa-check" id="temp{{i}}"></i>
                                    </span>
                                    <span class="font20 normalLnHt">Primary Institution</span>
                                </span>
                                <span *ngIf="f.primaryInstitution !==true" title="Delete Institution" class="delete-item">
                                    <button class="fas fa-trash-alt"
                                            *ngIf="f.primaryInstitution !== true"
                                            id="delInst{{i}}"
                                            (click)="confirmDelete(form.projectInstitutions, f)">
                                    </button>
                                </span>
                                <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                            </div>
                            <warning-a fn="accountInstitutions" fid="affiliation" row={{f.id}}>
                            </warning-a>
                        </div>
                        <div id="addAdditionalInst" class="addAdditional"
                            (click)="accountAddItem('projectInstitutions')">
                            <span class="fas fa-plus-circle normalLnHt" id="addInst"></span>
                            <span class="font20 normalLnHt">Add additional institution </span>
                        </div>
                        <div id="saveCancelBtns" *ngIf="flags.ok2Submit" class="list-inline">
                            <button id="saveProjDetails" class="btn btn-success btn-lg bigFont"
                                (click)="editProjectDetails('save')">SAVE PROJECT DETAILS
                            </button>
                            <a class="submit font20" id="cancel" (click)="editProjectDetails('cancel')">Cancel changes</a>
                        </div>
                    </div>
                    <div class="addnlProjDetailsHeader">
                        <div [ngClass]="AddnlProjDetails ? 'active-arrow' : 'default-arrow'">
                            <h4  id="requestAllocation" (click)="AddnlProjDetails = !AddnlProjDetails; managementViewDetails('Project Description')"><span class="caret"></span>
                                Additional Project Details
                            </h4>
                        </div>
                        <div *ngIf="AddnlProjDetails" id="addnlProjDetail">
                            <div>Other Systems: {{form.otherSystems}}</div>
                            <div>Science Domain:
                                <span *ngFor="let fosList of form.fieldOfStudy; let last = last">
                                    {{fosList.fieldOfStudy}}{{!last && form.fieldOfStudy.length > 1 ? ',' : ''}}
                                </span>
                            </div>
                            <div>Application Code: {{form.scienceDescription}}</div>
                            <div>Project URL: <a href={{form.url}} target="_blank">{{form.url}}</a></div>
                            <div id="projDesc">Project Description: <span style="display:block">{{form.projDescription}}</span></div>
                            <!-- Allocations -->
                            <div id="projAllocationsTable" class="projAlloc col-xs-8">
                                <div>Allocations:
                                    <div *ngIf="lists.projectAllocations.length>0" style="margin-top:-5px; font-size: smaller;">Time of the day for both start and end dates is 00:00:00 UTC on the date shown.</div>
                                </div>
                                <div>
                                    <ngx-datatable
                                        id="pjAllocTable"
                                        #pjAllocTable
                                        class="bootstrap card expandable"
                                        columnMode="flex"
                                        [rowHeight]="50"
                                        [headerHeight]="30"
                                        [footerHeight]="30"
                                        [scrollbarH]="true"
                                        [sorts]="[{prop: 'system', dir: 'asc'}]"
                                        [rows]="lists.projectAllocations">
                                        <ngx-datatable-column name="System" prop="system" [flexGrow]=".2">
                                            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                                <span class="datatable-header-cell-label draggable" (click)="sort()">
                                                    System
                                                </span>
                                            </ng-template>
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <span id="system-{{row.system}}-{{row.id}}">{{row.system}}</span> 
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Start" prop="start_date" [flexGrow]=".2">
                                            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                                <span class="datatable-header-cell-label draggable" (click)="sort()">
                                                    Start
                                                </span>
                                            </ng-template>
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <span id="startdate-{{row.system}}-{{row.id}}">{{row.start_date | date: 'yyyy-MM-dd'}} UTC</span>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="End" prop="end_date" [flexGrow]=".2">
                                            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                                <span class="datatable-header-cell-label draggable" (click)="sort()">
                                                    End
                                                </span>
                                            </ng-template>
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <span id="enddate-{{row.system}}-{{row.id}}">{{row.end_date | date: 'yyyy-MM-dd'}} UTC</span>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [flexGrow]=".4" [sortable]='false'>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="white-container col-xs-12">
            </div>
            <div id="teamMemshipSection" class="ub3-card col-xs-12 teamMemship">
                <h4 class="table-heading">Manage Team Membership</h4>
                <div class="memTableData">
                    <!-- <div class="next-arrows">
                        <span class="glyphicon glyphicon-menu-left" (click)="tableScroll('left',$event)"></span>
                        <span class="glyphicon glyphicon-menu-right" (click)="tableScroll('right',$event)"></span>
                    </div> -->
                    <div>
                        <table class="table update-account projectManageTable">
                            <thead>
                                <tr>
                                <th colspan="12">
                                    Team Members
                                </th>
                                </tr>
                            </thead>
                            <tr class="updateLbl">
                                <td colspan="12">
                                Update proxies and remove members.
                                </td>
                            </tr>
                            <tr *ngIf="lists.pendingStatus">
                                <td colspan="12" class="small">
                                *The Accounts team will approve the membership once it is verified that the user's institution has a user agreement with ANL and there is a signed acknowledgement form on file.
                                </td>
                            </tr>
                            <tr *ngIf="lists.accountInactivateStatus">
                                <td colspan="12" class="small">
                                **A user can login only if the Account status is "Active". The ALCF Accounts team will change the status from "Pending Approval" to "Active" after verifying the 593 (Foreign National Access Request) and user agreement/acknowledgement status for the user.
                                </td>
                            </tr>
                        </table>
                        <div>
                            <ngx-datatable
                                    #pjAcTable
                                    id="pjAcTable"
                                    class="bootstrap card expandable small"
                                    columnMode="flex"
                                    [rowHeight]="'auto'"
                                    [headerHeight]="30"
                                    [footerHeight]="30"
                                    [scrollbarH]="true"
                                    [rows]="lists.projectAccounts"
                                    [sorts]="[{prop: 'userName', dir: 'asc'}]">

                                <ngx-datatable-column name="User Name"   prop="userName" [flexGrow]=".55">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="{{row.userName}}">{{row.userName}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Preferred Name"   prop="preferredName" [flexGrow]=".65">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="{{row.preferredName.split(' ').join('_')}}"> {{row.preferredName}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="First Name"   prop="firstName" [flexGrow]=".52">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="{{row.firstName.split(' ').join('_')}}"> {{row.firstName}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Last Name"   prop="lastName" [flexGrow]=".52">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="{{row.lastName.split(' ').join('_')}}"> {{row.lastName}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Email"   prop="email" [flexGrow]="1">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="{{row.email.split('.').join('_').split('@').join('_')}}"> {{row.email}}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Account Status"   prop="accountStatus" [flexGrow]=".58">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span>
                                            <span id="accStatus-{{row.email.split('.').join('_').split('@').join('_')}}">{{row.accountStatus == 'Active' ? 'Active' : (row.accountStatus) + '**'}}</span>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Role"   prop="projectRole" [flexGrow]="0.45"></ngx-datatable-column>
                                <ngx-datatable-column name="Proxy"  prop="projectProxy" [flexGrow]="0.5">
                                    <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                        <ng-container>
                                            <span container="body" class="datatable-header-cell-label draggable" (click)="sort()" id="proxy" ngbTooltip="Stand-in for the PI. A delegate who can add/approve/remove members from projects and perform other administrative tasks on behalf of the PI." tooltipClass="projProxyToolTip" placement="top">Proxy</span>
                                        </ng-container>
                                    </ng-template>
                                    <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                        <span *ngIf="row.projectRole === 'PI'">
                                            <input class="styled-checkbox" [(ngModel)]="row.projectProxy" id="styled-checkbox-{{i}}"
                                                type="checkbox" checked>
                                            </span>
                                            <span *ngIf="row.projectRole !== 'PI'">
                                            <input
                                                id="styled-checkbox-{{row.userName}}"
                                                [(ngModel)]="row.projectProxy"
                                                type="checkbox"
                                                (click)="checkProxyChanges()">
                                            <label for="styled-checkbox-{{row.userName}}"></label>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Membership"   prop="memberStatus" [flexGrow]="0.65">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span id="membership-{{row.userName}}">
                                            <span>{{row.memberStatus == 'Approved-pending MUA/Ack' ? 'Approval Pending*' : row.memberStatus}}</span>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [flexGrow]="0.5" [sortable]='false'>
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span *ngIf="(row.memberStatus == 'Requested' && row.projectRole !== 'PI') && row.accountStatus !== 'System Created'">
                                            <button class="btn btn-primary btn-xs usrBtn"
                                                (click)="approveUser(form.projectShortName,row)" id="approve-{{row.userName}}">
                                                APPROVE
                                            </button>
                                            <button class="btn btn-danger btn-xs usrBtn rejectBtn"
                                                (click)="rejectUser(form.projectShortName,row)"
                                                id="reject-{{row.email.split('.').join('_').split('@').join('_')}}">
                                                REJECT
                                            </button>
                                        </span>
                                        <span *ngIf="row.memberStatus !== 'Requested'">
                                            <button *ngIf="row.projectRole !== 'PI'" class="btn btn-danger btn-xs usrBtn"
                                                (click)="deleteUser(form.projectShortName,row)" id="remove-{{row.userName}}" >
                                                REMOVE
                                            </button>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                        <br>
                        <div *ngIf="flags.paError" id="paErr">
                            <span>
                                <font color="#ff0000">{{flags.paError}}</font>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="saveCancelMemship" *ngIf="flags.pjaOkToSave" class="list-inline">
                    <button id="saveBtn" class="btn btn-success btn-lg bigFont"
                        (click)="updatePiProxies(form.projectShortName)">SAVE MEMBERSHIP
                    </button>
                    <a class="submit font20" id="cancelBtn" (click)="cancelProxyChanges(form.projectShortName)">Cancel changes</a>
                </div>
                <div *ngIf="authAlerts.length > 0 && !flags.isYourGroupOpen && !flags.changedProjectDetails">
                    <div class="alert-section" *ngFor="let alert of authAlerts; let aIndex = index">
                        <ngb-alert [type]="alert.type" (close)="closeAlert(aIndex)">
                            {{ alert.msg }}
                        </ngb-alert>
                    </div>
                </div>
                <div>
                    <table class="table update-account projectManageTable">
                        <thead>
                        <tr>
                            <th colspan="12">
                            Add Members
                            </th>
                        </tr>
                        </thead>
                        <tr>
                            <td colspan="12">
                                Search the list of users by entering username, first name, last name, or preferred name.
                            </td>
                        </tr>
                        <tr class="noBorder">
                            <td colspan="12" class="form-group">
                                <input type="text" class="form-control"
                                    (change)="matchUsers(flags.filterStr, false)"
                                    [(ngModel)]="flags.filterStr"
                                    id="filterStr"
                                    name="filterStr"
                                    ngDefaultControl
                                    placeholder="Add Users">
                                <button id="searchBtn" class="btn btn-primary btn-md" (click)="matchUsers(flags.filterStr, false)">
                                    SEARCH
                                </button>
                            </td>
                        </tr>
                        <tr class="noBorder">
                            <td colspan="12" class="small">Note: Only users who have an active ALCF account can be added to the
                                project from this screen. If the user does not have an active account, please tell
                                the user to reactivate their account and to choose this project upon reactivation.
                            </td>
                        </tr>
                        <tr *ngIf="lists.matchedUsers && lists.matchedUsers.length > 0">
                            <div id="pjau_Table">
                                <ngx-datatable
                                    #pjau
                                    id="pjau"
                                    class="bootstrap card expandable small"
                                    columnMode="flex"
                                    [rowHeight]="auto"
                                    [headerHeight]="30"
                                    [footerHeight]="30"
                                    [scrollbarH]="true"
                                    [rows]="lists.matchedUsers">

                                    <ngx-datatable-column name="Username"   prop="userName" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>{{row.userName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="First<br>Name"   prop="firstName" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>{{row.firstName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Last<br>Name"   prop="lastName" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>{{row.lastName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Preferred<br>Name"   prop="preferredName" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>{{row.preferredName}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Email"   prop="email" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span>{{row.email}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Account<br>Status"   prop="status" [flexGrow]="1">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <span id={{row.status}}>{{row.status}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [flexGrow]="1" [sortable]='false'>
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            <button class="btn btn-primary btn-sm usrBtn"
                                                *ngIf="row.status === 'Active' && !lists.pjaIndex[row.userName]"
                                                id="add-{{row.userName}}"
                                                (click)="addUser(form.projectShortName, row)">
                                                ADD
                                            </button>
                                            <span>{{displayMemberStatus(lists.pjaIndex[row.userName],row.status)}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>