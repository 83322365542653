
<data-collection 
                 [option]="option"
                 [callback]="onDataCollectionExit" 
                 [afterEnableCheck]="setDataCollFlag">
</data-collection>

<span *ngIf="!dataCollectionEnabled">

<div *ngIf="!flags.reactivateAccount" class="update-header reversed-white">
    <h1>Update ALCF account information</h1>
    <p>Please verify that your contact information is up to date.
       Your information will be shared with Argonne National Laboratory and may also be shared with the Department of Energy.
    </p>
 </div>
 <div class="ub3-card" *ngIf="!user.signedIn">
     <font color=red>Please sign in</font>
 </div>
 <div class="update-account ub3-card" *ngIf="user.signedIn">
    <div class="card-body">
       <div class="alert alert-danger" *ngIf="lists.errorAccountUpdate">{{lists.errorAccountUpdate}}</div>
       <div *ngIf="!lists.errorAccountUpdate">
          <div *ngIf="flags.bootstrapAlert === 'editRecordError'">
             <div uib-alert *ngFor="let alert of alerts"
               [ngClass]="'alert-' + (alert.type || 'warning')"
                close="closeAlert(i)" dismiss-on-timeout="99999">{{alert.msg}}
             </div>
          </div>
          <div uib-alert *ngFor="let alert of alerts"
            [ngClass]="'alert-' + (alert.type || 'warning')"
             close="closeAlert(i)" dismiss-on-timeout="99999">{{alert.msg}}
          </div>
          <h2 class="accountDetails">Account Details</h2>
          <table id="updateAccDetails" class="table-condensed table-user-details">
             <tbody>
                <tr *ngIf="!flags.hideAccountSection">
                   <td>
                      Username:
                   </td>
                   <td>
                      <span id="userName">{{form.userName}}</span>
                   </td>
                </tr>
                <tr *ngIf="!flags.hideAccountSection">
                   <td>
                      Status:
                   </td>
                   <td>
                      <span [textContent]="form.accountStatus" *ngIf="flags.accountUpdate"></span>
                   </td>
                </tr>
                <tr>
                   <td>
                      Account Deactivation Date:
                   </td>
                   <td>
                      <span [textContent]="convertUTCtoCDT(form.deactivationDate)" *ngIf="flags.accountUpdate"></span>
                   </td>
                </tr>
                <tr *ngIf="!flags.hideAccountSection && !form.usCitizen">
                   <td>
                      593 Expiration Date:
                   </td>
                   <td>
                      <span *ngIf="flags.expirationDate593 !== null"
                         [textContent]="convertUTCtoCDT(flags.expirationDate593)"></span>
                   </td>
                </tr>
                <tr *ngIf="lists.accountSystems">
                   <td>
                      Computing Resources:
                   </td>
                   <td class="resourceName">
                      <div class="resoureList">
                         <ng-container *ngIf="!flags.hideAccountSection">
                            <div *ngFor="let record of lists.accountSystems">
                                <span>{{record.resourceName}}{{!$last && lists.accountSystems.length > 1 ? ', ' : ''}}</span>
                            </div>
                         </ng-container>
                      </div>
                   </td>
                </tr>
                <tr *ngIf="!flags.reactivateAccount">
                   <td>
                      Crypto Key Token:
                   </td>
                   <td>
                      <span>{{form.cryptoKeyOption}}</span>
                      <span *ngIf="form.cryptoKeyOption == 'Physical' && !flags.reqMobileCryptoKey">
                         <button id="mobileToken" class="btn btn-primary" (click)="getMobileToken()">Request a mobile token</button>
                         <a class="fas fa-info-circle cryptoKeyInfo" href="{{ALCFPasscodeTokensURL}}" target="_blank"></a>
                      </span>
                   </td>
                </tr>
             </tbody>
          </table>
          <div *ngIf="flags.reactivateAccount && !flags.isReactivateSurvey" class="account cryptoKeyOption mb12">
             <ul>
                <li>
                   Crypto Key Token:
                </li>
                <li class="inputCheckbox">
                   <div class="radios">
                      <p>
                         <input type="radio" name="cryptoKeyOption" id="test1" [(ngModel)]="form.cryptoKeyOption"
                            value="Physical"
                            ngDefaultControl
                            (change)="valAllAcUpdFields(form)"
                            checked>
                         <label for="test1">Physical</label>
                      </p>
                      <p>
                         <input type="radio" id="test2" [(ngModel)]="form.cryptoKeyOption" name="cryptoKeyOption"
                            value="Mobile"
                            ngDefaultControl
                            (change)="valAllAcUpdFields(form)"
                         >
                         <label for="test2">Mobile</label>
                      </p>
                   </div>
                </li>
             </ul>
          </div>
          <!-------- UNIX SHELL ------->
          <!-- replace hard coded values with dropdown --->
          <div *ngIf="!isReactivateSurvey" class="account mb12">
             <ul>
                <li>
                   Unix Shell:
                </li>
                <li class="inputCheckbox">
                   <div class="radios">
                      <p>
                         <input type="radio" name="shell" id="test1" [(ngModel)]="form.shell" value="/bin/tcsh"
                            ngDefaultControl
                            (change)="valAllAcUpdFields(form)"
                            checked>
                         <label for="test1">tcsh</label>
                      </p>
                      <p>
                         <input type="radio" id="test2" [(ngModel)]="form.shell" name="shell" value="/bin/bash"
                            ngDefaultControl
                            (change)="valAllAcUpdFields(form)"
                         >
                         <label for="test2">bash</label>
                      </p>
                      <p>
                         <input type="radio" id="test3" [(ngModel)]="form.shell" name="shell" value="/bin/zsh"
                            ngDefaultControl
                            (change)="valAllAcUpdFields(form)"
                         >
                         <label for="test3">zsh</label>
                      </p>
                   </div>
                </li>
             </ul>
          </div>
          <!---------- NAME AND EMAIL ---------->
          <h2 class="clear">Name and Email</h2>
          <!-- First Name -->
          <div class="clear form-group floating-label-wrapper">
            <input type="text"
                class="form-control inBox"
                id="firstName"
                placeholder=" "
                [(ngModel)]="form.firstName"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)">
            <label for="firstName" class="inputLbl">Legal First/Given Name</label>
            <warning fid="firstName"></warning>
          </div>
          <!-- Middle Name -->
          <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError && flags.hasError.middleName}" id="nmi-div">
            <input type="text"
                class="form-control inBox"
                id="middleName"
                placeholder=" "
                [(ngModel)]="form.middleName"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)"
                maxlength="40">
            <label for="middleName" class="inputLbl">Legal Middle Name</label>
            <span class="cBox noMiddleName">
               <input type="checkbox"
                     [(ngModel)]="form.nmi" 
                     id="nmi"
                     class="align-checkbox"
                     (change)="valAllAcUpdFields(form); removeNmiErr(form.nmi)">
               <span class="align-text">No Middle Name</span>
            </span>
             <warning fid="middleName"></warning>
          </div>
          <!-- Last Name -->
          <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError.lastName}">

            <input type="text"
                class="form-control inBox"
                id="lastName"
                placeholder=" "
                [(ngModel)]="form.lastName"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)">
            <label for="lastName" class="inputLbl">Legal Last/Family Name</label>
             <warning fid="lastName"></warning>
          </div>
          <!-- Preferred Name -->
          <div class="form-group floating-label-wrapper" [ngClass]="{'error-section': flags.hasError.preferredName}">
            <input type="text"
                class="form-control inBox"
                id="preferredName"
                placeholder=" "
                [(ngModel)]="form.preferredName"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)"
                (focus)="setUUPreferredName()">
            <label for="preferredName" class="inputLbl">Preferred Name</label>
            <warning fid="preferredName"></warning>
          </div>
          <!-- Emails -->
          <div [hidden]="f.status === 'Deleted'" *ngFor="let f of form.accountEmails; let i = index">
             <div class="form-group floating-label-wrapper">
               <input type="text"
                   class="form-control inBox email-section"
                   id="email{{i}}"
                   placeholder=" "
                   [(ngModel)]="f.email"
                   autocomplete="nope"
                   (keyup)="valAllAcUpdFields(form)">
               <label for="email{{i}}" class="inputLbl">Email Address</label>
                <!--f.status ='Deleted' should not be set until confirmed 'YES' in modal -->

                <div class="cBox"
                   (click)="setPrimaryEmail(f, f.id, f.email); valAllAcUpdFields(form)">
                   <span>
                      <input type="checkbox" class="align-checkbox"
                       id="prim-email-{{i}}"
                       [(ngModel)]="f.primary">
                   </span>
                   <span class="align-text">Primary</span>
                   <div *ngIf="!hasPrimary(form.accountEmails)" class="warning noPrimary">
                      Primary is required
                   </div>
                </div>

                <div class="primary-select">
                  <span *ngIf="!f.primary"  title="Delete Email" class="delete-item">
                     <span class="fas fa-trash-alt"
                           id="del-email-{{i}}"
                           (click)="confirmDelete(f, form, 'email')">
                     </span>
                     <span class="status-pending" *ngIf="f.status ==='Pending'">
                        Pending
                     </span>
                  </span>
               </div>
 
                <warning-a fn="accountEmails" fid="email" row={{f.id}} id="warnEmail{{f.id}}"></warning-a>
 
             </div>
          </div>
          <div class="form-group section-ht">
             <span 
                class="fas fa-plus-circle addAdditional"
                id="addMoreEmails"
                (click)="accountAddItem('Emails'); valAllAcUpdFields(form)">
            </span>
             <span class="addIcon">Add additional emails</span>
          </div>
          <!-- Phone Numbers -->
          <div class="section-info update-account-phoneSection">
             <h2>Phone Numbers</h2>
             <div *ngIf="form.accountPhones && form.accountPhones.length === 0" class="no-phone-div">
               <font color="#c23a09">Required Field: Phone Number</font>
             </div>
             <table class="table-condensed table-user-details" *ngIf="form.accountPhones && form.accountPhones.length">
                <tbody>
                   <tr [hidden]="f.status === 'Deleted'" *ngFor="let f of form.accountPhones;  let i = index">
                      <td>
                         <table>
                            <tr class="tabledata">
                               <td class="update-account country">
                                  <div class="floating-label-wrapper">
                                     
                                     <input type="hidden" id="ph-country-{{i}}" value="{{f.phoneCountry}}"> <!-- for Cypress -->
                                     <input type="text"
                                        [(ngModel)]="f.selectedCountry"
                                        id="country{{i}}"
                                        placeholder="{{f.phoneCountry ? dialCode(f.phoneCountry) : 'Country Name'}}"
                                        autocomplete="nope"
                                        class="form-control"
                                        name="selectedCountry"
                                        (blur)="countryChange(f, form)"
                                        ngDefaultControl
                                        [ngbTypeahead]="searchCountry"
                                        ngbTooltip="Enter country name for numeric country code"
                                        placement="bottom"
                                        />

                                     <warning-a fn="accountPhones" fid="phoneCountry" row={{f.id}}></warning-a>
                                  </div>
                               </td>
                               <td class="update-account number phone-number min-wid-320">
                                  <div class="floating-label-wrapper">
                                     <input type="text" class="form-control"
                                        id='phoneNumber{{i}}'
                                        placeholder="phone number"
                                        [(ngModel)]="f.phoneNumber"
                                        autocomplete=“nope”
                                        (keyup)="valAllAcUpdFields(form)"
                                        />
                                     <warning-a fn="accountPhones" fid="phoneNumber" row={{f.id}}  id="warnPhone{{f.id}}"></warning-a>
                                  </div>
                               </td>
                               <td class="update-account extension min-wid-320">
                                  <div class="floating-label-wrapper ">
                                     <input type="text" id="extension{{i}}"
                                        placeholder="Ext.(opt.)"
                                        [(ngModel)]="f.extension"
                                        class="form-control"
                                        autocomplete="nope"
                                        (keyup)="valAllAcUpdFields(form)">
                                     <warning-a fn="accountPhones" fid="extension" row={{f.id}}></warning-a>
                                  </div>
                               </td>
                               <td class="phoneLabel">
                                  <div>
                                     <div class="floating-label-wrapper ">
                                        <select 
                                           [(ngModel)]="f.phoneLabel" 
                                           class="form-control"
                                           style="width:90px" 
                                           id="phoneLabel{{i}}"
                                           placeholder="Type"
                                           autocomplete="nope"
                                           (change)="valAllAcUpdFields(form)"
                                           (keyup)="valAllAcUpdFields(form)"
                                           >
                                           <option value='Mobile'>Mobile</option>
                                           <option value='Work'>Work</option>
                                           <option value='Home'>Home</option>
                                        </select>
                                        <warning-a fn="accountPhones" fid="phoneLabel" row={{f.id}}></warning-a>
                                     </div>
                                  </div>
                               </td>
                               <td class="select-primary-phone">

                                  <span class="up-acc cBox" (click)="selectPrimaryItem(f, form.accountPhones); valAllAcUpdFields(form)">
                                    <input type="checkbox"
                                           id="prim-phone-{{i}}"
                                           [(ngModel)]="f.primary">
                                    <span>Primary Phone</span>
                                  </span>
                                  <span  title="Delete phones" *ngIf="!f.primary"
                                    autocomplete="nope"
                                    style="margin-right: 10px;">
                                    <span
                                       id="del-phone-{{i}}"
                                       class="fas fa-trash-alt"
                                       placeholder="Phone Status"
                                       (click)="confirmDelete(f, form, 'phoneNumber')">
                                    </span>
                                    <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                                 </span>
                               </td>
                            </tr>
                         </table>
                      </td>
                   </tr>
                </tbody>
             </table>
             <!---------- PHONE NUMBERS ---------->
             <div>
                  <div *ngIf="addNewPhone" class="form-group section-ht">
                    <span class="fas fa-plus-circle addAdditional"
                        (click)="accountAddItem('Phones'); valAllAcUpdFields(form);" id="addBtn"></span>
                    <span class="addIcon">Add additional phone numbers</span>
                  </div>
             </div>
          </div>
          <!---------- ADDRESSES ---------->
          <ng-container *ngIf="form.accountAddresses">

          <div *ngFor="let f of form.accountAddresses; let i = index"
            [ngClass]="{'left-group' : f.label == 'Work', 'right-group' : f.label == 'Shipping'}">
             <div class="form-adress">
                <h2 class="addressHeader" [ngClass]="{shippingAdd: f.label == 'Shipping'}">{{f.label}} Address</h2>
                <div class="shippingAddress" *ngIf="f.label == 'Shipping'">
                   <div class="work-form">
                      <input 
                         type="checkbox" 
                         id="sameAsWorkCheckbox"
                         [(ngModel)]="form.addressVal" 
                         (change)="addressChecked(); valAllAcUpdFields(form);">
                      <label for="sameAsWorkCheckbox" id="sameAsWork">Same as Work Address</label>
                   </div>
                </div>
                <div class="form-group floating-label-wrapper" style="clear: both;">
                   <select
                      class="form-control inBox"
                      id="{{f.label}}-country"
                      [(ngModel)]="f.country"
                      placeholder=" "
                      [disabled]="f.label == 'Shipping' && form.addressVal"
                      (change)="onNoCountry(f); addressChange(f); valAllAcUpdFields(form);"
                      (blur)="zipHint = false"
                   >
                      <option value="" disabled>Select a Country</option>
                      <option *ngFor="let c of lists.countries" 
                              value="{{c.name}}">
                          {{c.name}}
                      </option>
                   </select>
                   <label for="{{f.label}}-country" class="inputLbl">Country</label>
                   <warning-a fn="accountAddresses" fid="country" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country == 'United States'" class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="zip{{i}}"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      autocomplete="nope"
                      [(ngModel)]="f.zip"
                      (change)="aupLocateByZip('accountAddresses', f, i); valAllAcUpdFields(form);addressChange(f)"
                      (keyup)="valAllAcUpdFields(form)"
                      >
                   <label for="zip{{i}}" class="inputLbl">Zip</label>
                   <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                </div>
                <div class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="street1{{i}}"
                      autocomplete="nope"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      [(ngModel)]="f.street1"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="street1{{i}}" class="inputLbl">Address Line 1</label>
                   <warning-a fn="accountAddresses" fid="street1" row={{f.id}}></warning-a>
                </div>
                <div class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="street2{{i}}"
                      autocomplete="nope"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      [(ngModel)]="f.street2"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="street2{{i}}" class="inputLbl">Address Line 2 (optional)</label>
                   <warning-a fn="accountAddresses" fid="street2" row={{f.id}}></warning-a>
                </div>
                <div class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="street3{{i}}"
                      autocomplete="nope"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      [(ngModel)]="f.street3"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="street3{{i}}" class="inputLbl">Address Line 3 (optional)</label>
                   <warning-a fn="accountAddresses" fid="street3" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country == 'United States'" class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="city{{i}}"
                      autocomplete="nope"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      [(ngModel)]="f.city"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="city{{i}}" class="inputLbl">City</label>
                   <warning-a fn="accountAddresses" fid="city" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country !='United States'" class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="city{{i}}"
                      autocomplete="nope"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      [(ngModel)]="f.city"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="city{{i}}" class="inputLbl">City/Town/Locality</label>
                   <warning-a fn="accountAddresses" fid="city" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country == 'United States'"
                   class="form-group floating-label-wrapper">
                   <input 
                      [(ngModel)]="f.state"
                      type="text"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      autocomplete="nope"
                      id='state{{i}}'
                      class="form-control inBox"
                      placeholder=" "
                      (focus)="zipHint = true"
                      (ngModelChange)="onStateChange(f, form)"
                      *ngIf="!mobile"
                      [ngbTypeahead]="searchState">

                   <label for="state{{i}}" class="inputLbl" *ngIf="!mobile">State Abbreviation</label>
                   <!--Mobile scrolling at bottom of screen when select gets focus-->
                   <select 
                      [(ngModel)]="f.state" 
                      class="form-control inBox"
                      id='state{{i}}'
                      autocomplete="nope"
                      placeholder="State Abbreviation"
                      (change)="valAllAcUpdFields(form); addressChange(f)"
                      (keyup)="valAllAcUpdFields(form)"
                      *ngIf="mobile">
                      <option value="">State Abbreviation</option>
                      <option *ngFor="let f of lists.usaStates" value="{{f.abr}}">{{f.name}}</option>
                   </select>
                   <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country != 'United States'" class="form-group floating-label-wrapper">
                   <input 
                      [(ngModel)]="f.state"
                      type="text"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      autocomplete="nope"
                      id='state{{i}}'
                      class="form-control inBox"
                      placeholder=" "
                      (focus)="zipHint = true"
                      (change)="addressChange(f); valAllAcUpdFields(form)"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="state{{i}}" class="inputLbl">State/Province Region</label>
                   <warning-a fn="accountAddresses" fid="state" row={{f.id}}></warning-a>
                </div>
                <div *ngIf="f.country != 'United States'" class="form-group floating-label-wrapper">
                   <input type="text" class="form-control inBox" id="zip{{i}}"
                      [disabled]="f.label == 'Shipping' && form.addressVal || !f.country"
                      placeholder=" "
                      autocomplete="nope"
                      [(ngModel)]="f.zip"
                      (change)="valAllAcUpdFields(form); addressChange(f)"
                      (keyup)="valAllAcUpdFields(form)">
                   <label for="zip{{i}}" class="inputLbl">Zip/Postal Code</label>
                   <warning-a fn="accountAddresses" fid="zip" row={{f.id}}></warning-a>
                </div>
             </div>
             <script type="text/ng-template" id="abrStateSelect.html">
                <a>
                <span [innerHtml]="match.model.name | uibTypeaheadHighlight:query">
                </span>
                </a>
             </script>
          </div>
          </ng-container>
          <!---------- EMPLOYER ---------->
          <div class="section-info" *ngIf="!flags.hideAccountSection">
             <h2>Employer</h2>
             <br><font size=-1>(The institution that is responsible for you and your work at the ALCF. Select 'Individual' if self-employed.)</font>
             <div *ngFor="let f of form.accountInstitutions ; let i = index"
                  [hidden]="f.status === 'Deleted'" 
                  class="acountInst">
                <div class="form-group floating-label-wrapper affiliations">
                   <input 
                      type="text"
                      id='affiliation{{i}}'
                      style="width:80%"
                      [(ngModel)]="f.affiliation"
                      name="affiliation"
                      ngDefaultControl
                      placeholder="Employer"
                      (ngModelChange)="valAllAcUpdFields(form)"
                      (keyup)="valAllAcUpdFields(form)"
                      class="form-control"
                      maxlength="50"
                      [disabled]="(f.id >= 0 || (f.primaryInstitution === true && form.accountInstitutions.length > 1))"
                      [ngbTypeahead]="instTypeAhead"
                      typeahead-show-hint="true"
                      typeahead-min-length="2"
                      (blur)="f.institutionId = getInstitutionId(f.affiliation)">
                   <input type="checkbox"
                          id="primaryCheckBox{{i}}"
                          class="align-inst-sec"
                          [(ngModel)]="f.primaryInstitution"
                          (click)="selectPrimaryInstitution(f, form.accountInstitutions);valAllAcUpdFields(form)">
                   <span class="align-inst-sec">Primary</span>

                   <span *ngIf="f.primaryInstitution !==true" title="Delete Institution"
                      class="delete-item">
                            <span class="fas fa-trash-alt" id="deleteInstitution{{i}}"
                                (click)="confirmDelete(f, form, 'affiliation')">
                            </span>
                   </span>
                   <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                   <warning-a fn="accountInstitutions" fid="affiliation" row={{f.id}} id="warnInstn{{f.id}}">
                   </warning-a>
                </div>
             </div>
             <div id="additionalInst">
                <span 
                   class="fas fa-plus-circle"
                   (click)="accountAddItem('Institutions'); valAllAcUpdFields(form);"
                   id="addAdditionalInstitution">
                </span> 
                <span class="addIcon">
                   Add additional employers
                </span>
             </div>
          </div>
          <!---------- Career Level ---------->
          <h2>Career Level</h2>
          <div class="form-group floating-label-wrapper ">
            <select 
               [(ngModel)]="form.employment_level"
               class="form-control inBox"
               id="employment_level"
               placeholder=" "
               (change)="valAllAcUpdFields(form)">
               <option value="" disabled></option>
               <option *ngFor="let f of lists.careerLevels" value="{{f.name}}">{{f.name}}</option>
            </select>
            <label for="employment_level" class="inputLbl">Choose your career level</label>
            <warning fid="employment_level"></warning>
          </div>
 
          <!---------- ORCID ---------->
          <h2>ORCID</h2>
          <div class="form-group floating-label-wrapper">
             <input type="text" class="form-control inBox"
                id="orcid" orcid-scopus="'orcid'"
                placeholder=" "
                [(ngModel)]="form.orcid"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)">
             <label for="orcid" class="inputLbl">Enter your ORCID</label>
             <warning fid="orcid"></warning>
          </div>
          <!---------- SCOPUSID ---------->
          <h2>SCOPUSID</h2>
          <div class="form-group floating-label-wrapper">
             <input type="text" class="form-control inBox" id="scopusId" orcid-scopus="'scopus'"
                placeholder=" "
                [(ngModel)]="form.scopusId"
                autocomplete="nope"
                (keyup)="valAllAcUpdFields(form)">
             <label for="scopusId" class="inputLbl">Enter your SCOPUSID</label>
             <warning fid="scopusId"></warning>
          </div>

          <div id="doe-data-survey-citizenship" *ngIf="isReactivateSurvey">
               <h2>Country of citizenship</h2>

               <div class="form-group floating-label-wrapper">
                     <label for="Country of Citizenship"></label>
                        <select
                           class="form-control inBox"
                           id="comment-country"
                           [(ngModel)]="form.comment"
                           placeholder=" "
                           (change)="valAllAcUpdFields(form)">
                           <option value="" disabled>Select a Country</option>
                           <option *ngFor="let c of lists.countries" 
                                 value="{{c.name}}">
                              {{c.name}}
                           </option>
                     </select>
                     <warning fid="comment"></warning>
               </div>
          </div>

          <!---------- SECURITY QUESTIONS ---------->
          <div *ngIf="!flags.reactivateAccount" class="section-info security-questions">
             <h2>Security Question(s)</h2>
             <div class="alert alert-danger" *ngIf="form.accountQuestions && form.accountQuestions.length < 1">
                Security Question and Answer are mandatory
             </div>
             <p class="Resources">You will need to know this question and answer for future security
                reasons, so it is important to remember what you enter.
             </p>
             <ul style="font-size: 20px" class="Resources">
                <li>Use a question and answer that is easy for you to answer and hard for others to guess.
                </li>
                <li>You may be asked this question to confirm your identity when speaking to user support.
                </li>
                <li>Do not share this question and answer.</li>
             </ul>
             <div [hidden]="f.status === 'Deleted'" *ngFor="let f of form.accountQuestions ; let i = index"
                class="repeated-questionForm">
                <h4 class="left-nav">Question {{i +1}}:</h4>
                <div class="repeatedSecurityQuestions Resources">
                   <div class="form-group floating-label-wrapper">
                      <label for="securityQuestion{{i}}"></label>
                      <input type="text" class="form-control" name="security_question" size="40"
                         ngDefaultControl
                         id='securityQuestion{{i}}'
                         autocomplete="nope"
                         placeholder="Question"
                         [(ngModel)]="f.securityQuestion"
                         (keyup)="valAllAcUpdFields(form)"
                         >
                     <span *ngIf="i > 0" title="Delete Question/Answer"
                        class="delete-item clear">
                        <span class="fas fa-trash-alt"
                           (click)="confirmDelete(f, form, 'securityQuestion')">
                        </span>
                        <span class="status-pending" *ngIf="f.status ==='Pending'">Pending</span>
                     </span>
                   </div>
                   <warning-a fn="accountQuestions" fid="securityQuestion" row={{f.id}} id="warnQuestion{{f.id}}"></warning-a>
                </div>
                <br>
                <div class="form-group floating-label-wrapper clear">
                   <div id="securityAnswerLabel">
                     <label for="securityAnswer{{i}}"></label>
                     <h4 class="left-nav">Answer {{i +1}}:</h4>
                   </div>
                   <br>
                   <div>
                     <input type="text"
                        class="form-control"
                        name="security_answer"
                        ngDefaultControl
                        size="40"
                        id='securityAnswer{{i}}'
                        autocomplete="nope"
                        placeholder="Answer"
                        [(ngModel)]="f.securityAnswer"
                        (keyup)="valAllAcUpdFields(form)">
                     <warning-a fn="accountQuestions" fid="securityAnswer" row={{f.id}} id="warnAnswer{{f.id}}"></warning-a>
                   </div>
                </div>
             </div>
             <div class="form-group section-ht" *ngIf="form.accountQuestions && form.accountQuestions.length <= 2">
                <span>
                   <span id="addQuestion" class="fas fa-plus-circle addAdditional" (click)="accountAddItem('Questions'); valAllAcUpdFields(form)"></span>
                   <span class="addIcon">Add Security Question</span>
                </span>
             </div>
             <table class="repeated-questionForm" *ngIf="form.accountQuestions && form.accountQuestions.length <= 2">
                <tr *ngFor="let f of flags.newSecurityQuestions ; let i = index">
                   <td>
                      <h4>
                         Question:
                      </h4>
                      <input type="text" class="form-control" name="security_question" size="40"
                         ngDefaultControl
                         id='securityQuestion{{i}}'
                         autocomplete="nope"
                         [(ngModel)]="f.securityQuestion"
                         (keyup)="valAllAcUpdFields(form)"
                         >
                      <warning-a fn="accountQuestions" fid="securityQuestion" row={{f.id}} ></warning-a>
                   </td>
                   <td>
                      <h4>Answer: </h4>
                      <input type="text" class="form-control" name="security_answer" size="40"
                         ngDefaultControl
                         id='securityAnswer{{i}}'
                         [(ngModel)]="f.securityAnswer"
                         autocomplete="nope"
                         (keyup)="valAllAcUpdFields(form)">
                      <warning-a fn="accountQuestions" fid="securityAnswer" row={{f.id}}></warning-a>
                   </td>
                </tr>
                <tr class="errorMessage" *ngIf="errorMessage">
                   <td>Please Enter the Security Question and answer</td>
                </tr>
             </table>
          </div>
          <br>
          <!---------- CITIZENSHIP ---------->
          <table class="citizenship" *ngIf="!isReactivateSurvey">
             <tr>
                <td>
                   <h2 id="auCit">Citizenship</h2>
                   <br>
                </td>
             </tr>
             <tr>
                <td>
                   <p class="usCitizenship">U.S. Citizen:
                      <span id="usCitizen" [textContent]="!form.usCitizen ? 'No' : 'Yes'"></span>
                   </p>
                   <div *ngIf="!form.usCitizen">
                      <p>Foreign National Access expiration date:
                         <span [textContent]=convertUTCtoCDT(flags.expirationDate593)></span>
                      </p>
                      <p class="renew-info">To renew your ALCF foreign national access, you will need to re-enter
                         your foreign national information in the ALCF Foreign National Access Request form.
                      </p>
                   </div>
                   <div *ngIf="flags.newFavorDataNeeded ==='true' && !flags.favorComplete">
                      <div>
                         <button 
                             (click)="flags.preFavorNotice=true; goToTopOfCitizenArea('upd')" 
                             [disabled]="(!flags.allReqdFieldsComplete) || (flags.fldHasError)"
                             class="btn btn-primary btn-lg" 
                             id="enterForeignNationalInfo">
                         Enter Foreign National Info
                         </button>
                         <span *ngIf="!flags.allReqdFieldsComplete">
                            <font color=red id="efni-err-msg">
                                Please enter all required fields (<b>in red</b>) before pressing this button
                            </font>
                         </span>
                      </div>
                      <div *ngIf="flags.preFavorNotice" class="round-corner prefavor-notice">
                         <docs-needed></docs-needed>
                         <br><br>
                         Please gather all these documents before you click the Visit button below.
                         <br>
                         <button  (click)="tryToGoToFavor()" 
                                  class="btn btn-primary btn-lg" 
                                  [disabled]="(!flags.allReqdFieldsComplete) || (flags.fldHasError)"
                                  id="visitRequestForm">
                              Visit Access Request Form
                         </button>
                         <button (click)="flags.preFavorNotice=false"
                            class="btn btn-primary btn-lg">
                              Cancel
                         </button>
                         <span *ngIf="flags.preFavorReq">
                            <br>
                            <font color=red>{{flags.preFavorReq}}</font>
                         </span>
                      </div>
                   </div>
                   <span *ngIf="flags.loading">
                            <br><font color=green><b>Loading Foreign National Entry Form. Please wait ...</b></font>
                            <img src="/frontend/loading.gif">
                   </span>
                </td>
             </tr>
             <tr>
                <td>
                   <p class="renew-info usCitizenship">Please contact <a href="mailto:{{domainGlobals.helpDeskEmail}}"
                      [textContent]="domainGlobals.helpDeskEmail"></a> if your
                      citizenship status has changed.
                   </p>
                </td>
             </tr>
          </table>
          <!---------- TERMS OF USE ---------->
          <div class="policyAgreement" *ngIf="!isReactivateSurvey">
             <h2>Terms of Use</h2>
             <div class="acc-upd-p">
                <policy-agreement-option [form]="form" [onCheckBoxChange]="valAllAcUpdFieldsH"></policy-agreement-option>
             </div>
             <div class="acc-upd-p">
               <div class="up-acc cBox primaryCheckBox" required>
                  <input type="checkbox" id="verify" [(ngModel)]="confirmSaveChecked" (change)="verifyAccount()">
                  <span>I verify that my account details are correct</span>
               </div>
             </div>
          </div>
          <div class="updated-message" *ngIf="alerts.length > 0">
 
             <div uib-alert *ngFor="let alert of alerts; let i = index"
               [ngClass]="'alert-' + (alert.type || 'warning')"
                close="closeAlert(i)" dismiss-on-timeout="99999" id="alert_msg_{{i}}">{{alert.msg}}
             </div>
          </div>

          <!-- SUBMIT SAVE BUTTONS -->
          <div class="updateAcountBtn">
             <!-- ACCOUNT UPDATE -->
             <input 
                type="submit"
                id="continueAndSave"
                *ngIf="!flags.reactivateAccount" 
                class="btn btn-success btn-lg"
                value="CONFIRM AND SAVE"
                (click)="updateAccountInfo()" 
                [disabled]="isAccountUpdateSaveDisabled(form)">

             <!-- ACCOUNT DATA SURVEY -->
             <input 
                type="submit"
                id="subReactReq"
                *ngIf="flags.reactivateAccount && isReactivateSurvey" 
                [disabled]="!dataSurveyOkToSave"
                class="btn btn-success btn-lg" 
                value="SUBMIT ACCOUNT INFO"
                (click)="submitReactivate()">
             <span *ngIf="!flags.reactivateAccount" id="cancelAccUpdate" (click)="cancelAccountUpdate()"><a class="cancelRequest"><u>Cancel account updates</u></a></span>
             <span class="cancel-reactivation-request" *ngIf="flags.reactivateAccount">
               <a id="cancelRequest"
                  (click)="cancelAccountReactivation(isReactivateSurvey)">
                     <u>{{isReactivateSurvey ? 'Cancel Account Survey' : 'Cancel Reactivation Request'}}</u>
               </a>
             </span>
          </div>
          <div class="alert alert-danger" *ngIf="lists.errorEditRecord">{{lists.errorEditRecord}}</div>
       </div>
    </div>
 </div>
 <!---------- MEMBERSHIPS ---------->
 <div *ngIf="!flags.reactivateAccount && !lists.errorAccountUpdate" class="sub-section">
    <h1 class="reversed-white">Memberships</h1>
    <p>These are your current project memberships and UNIX groups. If you need to make changes,
       please visit the appropriate web page
    </p>
    <div class="card ub3-card">
       <div class="card-body">
          <div class="user-records-table acc-upd">
             <table class="table update-account accountFormTable">
                <thead>
                   <th colspan="12">
                      <h4 class="table-heading" id="pjMemTitle">Project Membership</h4>
                   </th>
                </thead>
                <tbody>
                   <tr>
                      <td colspan="12" style="padding-left:5px;">
                         <span>{{form.projectList && (form.projectList.length > 0) ? 'If you need to make changes, please visit Join Project Page' : 'There are no projects assigned to the account'}}</span>
                      </td>
                   </tr>
                   <tr>
                      <td colspan="12" class="updateRecordTableSection">
                         <div *ngIf="form.projectList && form.projectList.length >= 1">
                             <!-- Add sort to this table -->
                            <table class="updateRecordTable">
                               <thead>
                                  <tr>
                                     <th colspan="2">Project</th>
                                     <th colspan="3">Project Title</th>
                                     <th colspan="2" class="truncate-word">Membership Status</th>
                                     <th colspan="2">PI Name</th>
                                  </tr>
                               </thead>
                               <tbody class="update-account word-break">
                                   <!--
                                  <tr *ngFor="let record of form.projectList |  orderBy:sortInfo.updateRecord.sortBy:sortInfo.updateRecord.reverse">
                                   -->
                                  <tr *ngFor="let record of form.projectList">
                                     <td class="tableDetails" colspan="2">
                                        <span *ngIf="!record.proxy || record.proxy && record.membershipStatus !== 'Active'">
                                           {{record.projectShortName}}
                                        </span>
                                        <!--
                                        <a  href="/projectAdmin?edit={{record.projectShortName}}"
                                           *ngIf="record.proxy && record.membershipStatus === 'Active'">
                                           <font color="#5555ff">{{record.projectShortName}}</font>
                                        </a>
                                        -->
                                        <span 
                                           *ngIf="record.proxy && record.membershipStatus === 'Active'"
                                           style="cursor:pointer"
                                           id="pj-{{record.projectShortName}}"
                                           (click)="goToMngPj(record.projectShortName)">
                                           <font color="#5555ff">{{record.projectShortName}}</font>
                                        </span>
                                     </td>
                                     <td class="tableDetails" colspan="3"
                                         [textContent]="record.projectTitle"
                                         id="projTitle-{{record.projectShortName}}"
                                         >
                                     </td>
                                     <td class="tableDetails" colspan="2"
                                         [textContent]="record.membershipStatus"
                                         id="mem-status-for-{{record.projectShortName}}"
                                         >
                                     </td>
                                     <td class="tableDetails" colspan="2" [innerHtml]="record.piPreferredName"></td>
                                  </tr>
                                  <tr></tr>
                               </tbody>
                            </table>
                         </div>
                      </td>
                   </tr>
                </tbody>
             </table>
          </div>
          <div *ngIf="form.unixgroups">
             <div class="user-records-table">
                <table class="table update-account ugTable">
                   <thead>
                      <th colspan="12">
                         <h4 class="table-heading">UNIX Groups</h4>
                      </th>
                   </thead>
                   <tbody>
                      <tr>
                         <td colspan="12" style="padding-left:5px;">
                            <ng-container *ngIf="form.unixgroups.length > 0">
                                If you need to make changes, please visit manage Unix Groups page
                            </ng-container> 
                            <ng-container *ngIf="!form.unixgroups.length">
                                There are no UNIX Groups assigned to the account
                            </ng-container> 
                         </td>
                      </tr>
                      <tr>
                         <td colspan="12" class="updateRecordTableSection">
                            <div *ngIf="form.unixgroups.length >= 1">
                                <!-- add sorting to this table -->
                               <table class="updateRecordTable">
                                  <thead>
                                     <tr>
                                        <th colspan="6">Unix Group</th>
                                        <th colspan="6">Membership Status</th>
                                     </tr>
                                  </thead>
                                  <tbody class="update-account word-break">
                                      <!--
                                     <tr *ngFor="let record of form.unixgroups | orderBy:sortInfo.unixGroups.sortBy:sortInfo.unixGroups.reverse">
                                      -->
                                     <tr *ngFor="let record of form.unixgroups">
                                        <td class="tableDetails" colspan="6" [textContent]="record.resourceName"></td>
                                        <td class="tableDetails" colspan="6" [textContent]="record.membershipStatus"></td>
                                     </tr>
                                     <tr></tr>
                                  </tbody>
                               </table>
                            </div>
                         </td>
                      </tr>
                   </tbody>
                </table>
             </div>
          </div>
 
       </div>
    </div>
 </div>
 
</span>
 