        <div class="up-acc cBox primaryCheckBox" (click)="openAgreement('lg', 'anl')">

            <input type='checkbox' 
                   id="anl" 
                   [(ngModel)]="form.policyAck" 
                   (ngModelChange)="cbChange()"
                   ngDefaultControl 
                   name="policyAck">
            <span ngbTooltip="to change this, please Read the terms of service" placement="bottom">
                I have read and agree to Argonne's <a>Information Technology Access Agreement</a>
            </span>
        </div>
        <br>
        <div class="up-acc cBox primaryCheckBox clear" (click)="openAgreement('lg', 'alcf')">

            <input type='checkbox' 
                   id="alcfCheck" 
                   [(ngModel)]="form.policyAlcfAck" 
                   (ngModelChange)="cbChange()"
                   ngDefaultControl
                   name="policyAlcfAck">
            <span ngbTooltip="to change this, please Read the terms of service" placement="bottom">
                I have read and agree to the &nbsp;<a> ALCF's Additional Resource Agreement </a>
            </span>
        </div>
        <br>
