


<span *ngIf="flags.newProject">
    <font size=+1><b>
    Adding new project
    </b></font>
    <div *ngIf="flags.sameUnixGroupWarning">
        <font color="#ee5555"><b>
        Warning:
        A Unix group by the name '{{form.projectShortName}}'
        already exists.
        </b></font>
    </div>
</span>
<span *ngIf="flags.editProject">
  Editing project information & project users.
    <span id="spinner" *ngIf="flags.dispLoading">
        <span *ngIf="!flags.readyToEdit"> Loading: </span>
        <span *ngIf="flags.readyToEdit" > Loaded:  </span>

        <span *ngFor="let task of flags.loaded | keyvalue">
            <span *ngIf="!task.value"><font color="#aaaaaa">{{task.key}}</font> </span>
            <span *ngIf="task.value" >{{task.key}} </span>
        </span>
    </span>
</span>
<span id="readyToEdit" [hidden]="false" *ngIf="flags.readyToEdit"><font color="#eeeeee">ready</font></span>


<br><br>

<table cellspacing="1" cellpadding="1" border="0" *ngIf="true || flags.readyToEdit">
<tbody>

<tr id="projectEditAlert0"
    *ngIf="!flags.pjaOkToSave && (!flags.delta || (flags.currBranch && !flags.currBranch.state.delta))">
    <td colspan=2>
    <div 
        uib-alert
        *ngFor="let alert of authAlerts ; let i = index"
        [ngClass]="'alert-' + (alert.type || 'warning')"
        close="closeAlert(i)"
        dismiss-on-timeout="99999">{{alert.msg}}
    </div>
    </td>
</tr>
<tr>
  <th nowrap>Project Name</th>
  <td id="projName">
    <b>{{form.projectShortName}}</b>
  </td>
</tr>
<tr>
  <th nowrap>Project Title</th>
  <td>
        <input type="text" [(ngModel)]="form.projectTitle"
            id="projectTitle"
            class="projOneLiner"
            (ngModelChange)="pjaValidate()"
            size=40
        >
        <warning fid="projectTitle"></warning>
  </td>
</tr>
<tr>
  <th nowrap>PI Email</th>
  <td>
        <input type="text" size="39" 
               autocomplete="nope"
                id="piEmail"
                class="projOneLiner"
                *ngIf="flags.newProject"
                [(ngModel)]="form.piEmail" 
                (ngModelChange)="pjaValidate('piEmail')">
        <span *ngIf="flags.editProject">{{form.piEmail}}</span>
        <warning fid="piEmail"></warning>
  </td>
</tr>
<tr>
  <th nowrap>PI First Name</th>
  <td>
        <input type="text" size="39" 
               autocomplete="nope"
                id="piFirstName"
                class="projOneLiner"
                *ngIf="flags.newProject"
                [(ngModel)]="form.piFirstName" 
                [disabled]="flags.emailMatched"
                (ngModelChange)="pjaValidate('piFirstName')">
        <span *ngIf="flags.editProject">{{form.piFirstName}}</span>
        <warning fid="piFirstName"></warning>
  </td>
</tr>
<tr>
  <th nowrap>PI Last Name</th>
  <td>
        <input type="text" size="39" 
                id="piLastName"
                class="projOneLiner"
                *ngIf="flags.newProject"
                [(ngModel)]="form.piLastName" 
                [disabled]="flags.emailMatched"
                (ngModelChange)="pjaValidate('piLastName')">
        <span *ngIf="flags.editProject">{{form.piLastName}}</span>
        <warning fid="piLastName"></warning>
  </td>
</tr>
<tr>
  <th nowrap>PI Preferred Name</th>
  <td>
        <input type="text" size="39" 
                id="piPreferredName"
                class="projOneLiner"
                *ngIf="flags.newProject"
                [(ngModel)]="form.piPreferredName" 
                [disabled]="flags.emailMatched"
                (ngModelChange)="pjaValidate('piPreferredName')">
        <span *ngIf="flags.editProject">{{form.piPreferredName}}</span>
        <warning fid="piPreferredName"></warning>
  </td>
</tr>

<tr>
    <th nowrap>Field of Study</th>
<td>
<select [(ngModel)]="form.fieldOfStudy" 
        id="fieldOfStudy"
        class="projOneLiner"
        (ngModelChange)="pjaValidate(); getCatalysts(form.fieldOfStudy);">
    <option value=undefined [disabled]="true">--Please select--</option>
    <option value={{s}} *ngFor="let s of lists.scienceTypes">{{s}}</option>
</select>
<warning fid="fieldOfStudy"></warning>

</td></tr>

<tr>
  <th nowrap>Catalyst</th>
  <td>
    <select [(ngModel)]="form.catalyst"
            (ngModelChange)="pjaValidate(); matchFavorHostToCatalyst(form, sharedLists.certifiedHosts, lists.catalystsForFieldOfStudy);"
            id="catalyst"
            class="projOneLinerAuto"
            >
        <option value="">- None - </option>
        <ng-container *ngFor="let c of lists.catalystsForFieldOfStudy">
          <option value="{{c.id}}">
             {{c.preferredName}}
          </option>
        </ng-container>
    </select>
    <warning fid="catalyst"></warning>
    Internal Contact
    <input type=checkbox id="IntlContact" [(ngModel)]="form.internalContact" (ngModelChange)="pjaValidate()">
  </td>
</tr>
<tr>
  <th nowrap>FAVOR Host</th>
  <td>
    <select [(ngModel)]="form.paFavorHost"
            (ngModelChange)="pjaValidate()"
            id="paFavorHost"
            class="projOneLiner"
            >
        <option value="">- None - </option>
        <option *ngFor="let c of sharedLists.certifiedHosts"
                value="{{c.id}}">
            {{(c.firstName + ' ' + c.lastName) == c.preferredName ? c.preferredName : c.firstName +' '+c.lastName+' : '+c.preferredName}}
        </option>
    </select>
    <warning fid="paFavorHost"></warning>
  </td>
</tr>
<tr *ngFor="let inst of form.bpiObjList; let i = index">
  <th nowrap>
        <span *ngIf="i == 0">
            Primary Institution
        </span>
        <span *ngIf="i > 0">
            <font color="#888888">
            Alternate Institution
            </font>
        </span>
  </th>
  <td>
        <select [(ngModel)]="inst.idStr" 
                id="primaryInst"
                class="projOneLinerLg"
                (ngModelChange)="bpiListChange(i, inst.idStr); pjaValidate();">

            <option *ngFor="let j of lists.instActPend" 
                value="{{j.id}}">{{j.status == 'Pending' ? '** ' : ''}}{{j.name}}</option>
        </select>
        <button class="btn btn-danger btn-xs"
                id="del-{{i}}"
                *ngIf="i > 0"
                (click)="bpiListDel(i); pjaValidate();">
                x
        </button>
        <span *ngIf="inst.status == 'Pending'">
                <font color="#ff0000">Pending</font>
        </span>
        <br>
  </td>
</tr>
<tr>
  <th nowrap>
        <span *ngIf="form.bpiObjList && form.bpiObjList.length == 0">
            Primary Institution
        </span>
        <span *ngIf="form.bpiObjList && (form.bpiObjList.length > 0)">
            <font color="#88aa88">
            add Institution -->
            </font>
        </span>
  </th>
  <td>
    <select [(ngModel)]="bpi" 
            id="institutionSelect"
            class="projOneLinerLg"
            (ngModelChange)="bpiListAdd(bpi); pjaValidate();">

            <option value=""></option>
            <option 
                *ngFor="let i of lists.instActPend" 
                value="{{i.id}}">
                  {{i.status == 'Pending' ? '** ' : ''}}{{i.name}}
            </option>
    </select>

    <warning fid="bpiList"></warning>
  </td>    
</tr>
<tr *ngIf="instListChanged">
    <td></td>
    <td><font color=orange id="instListMsg">
            Changes to this list of institutions will be saved after pressing any of the Save Project buttons below.
        </font>
    </td>
</tr>

<tr>
  <th nowrap valign=top>Associated Funding</th>
  <td>
        <input type="text" [(ngModel)]="form.fundingAgency"
            (ngModelChange)="pjaValidate()"
            id="fundingAgency"
            class="projOneLiner"
            size=40
        >
        <warning fid="fundingAgency"></warning>
  </td>
</tr>

<tr>
    <th nowrap valign=top>Industry-Related Project</th>
    <td>
          <input type="checkbox" 
              [(ngModel)]="form.industry"
              (ngModelChange)="pjaValidate()"
              id="industry"
          >
          This project has team members from industry and/or the results are intended for industry use
          <warning fid="industry"></warning>
    </td>
  </tr>

<tr>
  <th nowrap valign=top>Other Systems</th>
  <td>
        <textarea cols="94" [(ngModel)]="form.otherSystems"
            (ngModelChange)="pjaValidate()"
            id="otherSystems"
        ></textarea>
        <warning fid="otherSystems"></warning>
  </td>
</tr>
<tr>
<th nowrap valign=top>Science Description</th>
  <td>
        <textarea cols="94" [(ngModel)]="form.scienceDescription" 
                id="scienceDescription"
                (ngModelChange)="pjaValidate()"></textarea>
        <warning fid="scienceDescription"></warning>
</td>
</tr>
<tr>
  <th nowrap valign=top>Project Description</th>
  <td>
        <textarea cols="94" rows="5" 
            id="projDescription"
            [(ngModel)]="form.projDescription" 
            (ngModelChange)="pjaValidate()"></textarea>
        <warning fid="projDescription"></warning>
  </td>
</tr>
<tr>
  <th nowrap>First Request Year</th>
  <td>
        <input type="text" size="50" 
            id="fyRequested"
            class="projOneLiner"
            [(ngModel)]="form.fyRequested" 
            (ngModelChange)="pjaValidate()">
        <warning fid="fyRequested"></warning>
  </td>
</tr>
<tr>
  <th nowrap>Associated Group</th>
  <td>
        <input type="text" size="50" 
            id="associatedGroup"
            class="projOneLiner"
            [(ngModel)]="form.associatedGroup" 
            (ngModelChange)="pjaValidate()">
        <warning fid="associatedGroup"></warning>
  </td>
</tr>
<tr>
  <th nowrap>URL</th>
  <td>
        <input type="text" size="50" 
            id="url"
            class="projOneLiner"
            [(ngModel)]="form.url" 
            (ngModelChange)="pjaValidate()">
        <warning fid="url"></warning>
  </td>
</tr>

<tr>
  <th nowrap>Status</th>
  <td>
        <input type="radio" 
            [(ngModel)]="form.status" 
            id="status"
            value="Active" 
            (ngModelChange)="pjaValidate()">
        Active
        <input type="radio" [(ngModel)]="form.status" value="Inactive" (ngModelChange)="pjaValidate()" id="project_Status_Inactive">
        Inactive
        <input type="radio" [(ngModel)]="form.status" value="Deleted" (ngModelChange)="pjaValidate()"id="project_Status_Deleted">
        Deleted
        <warning fid="status"></warning>
  </td>
</tr>

<tr>
  <th nowrap>Public</th>
  <td>
        <input type="checkbox" 
            [(ngModel)]="form.public" 
            id="public"
            (ngModelChange)="pjaValidate()">
        <warning fid="public"></warning>
  </td>
</tr>
<tr>
    <th nowrap>Requires Approval</th>
    <td>
          <input type="checkbox" 
              [(ngModel)]="form.requires_approval" 
              id="reqApprov"
              (ngModelChange)="pjaValidate()">
          <warning fid="requires_approval"></warning>
    </td>
</tr>

<tr>
       <th nowrap>Priority</th>
    <td>
        <input type="text" size="4" 
            id="priority"
            class="projOneLinerSml"
            [(ngModel)]="form.priority" 
            value="" 
            (ngModelChange)="pjaValidate()">
        <warning fid="priority"></warning>
    </td>
</tr>
<tr>
  <th valign=top>Allocations</th>
  <td>
    <ngx-datatable id="allocations"
        class="bootstrap card expandable centerText"
        columnMode="flex"
        [rowHeight]="30"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [sorts]="[{prop: 'system', dir: 'asc'}]"
        [rows]="lists.allocations">

        <ngx-datatable-column name="System" prop="system" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                  <span class="datatable-header-cell-label draggable" (click)="sort()">
                      System
                  </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span id="a-system-{{row.allocation_identifier}}">{{row.system}}</span> 
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Alloc.Id" prop="allocation_identifier" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span class="datatable-header-cell-label draggable" (click)="sort()">
                    Alloc.Id
                </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span id="a-allocid-{{row.allocation_identifier}}">{{row.allocation_identifier}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Award Cat" prop="award_category" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span class="datatable-header-cell-label draggable" (click)="sort()">
                    Award Cat.
                </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span id="a-awdcat-{{row.allocation_identifier}}">{{row.award_category}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Award Type Name" prop="award_type_name" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span class="datatable-header-cell-label draggable" (click)="sort()">
                    Award Type Name
                </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span id="a-awdtype-{{row.allocation_identifier}}">{{row.award_type_name}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Start" prop="start_date" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span class="datatable-header-cell-label draggable" (click)="sort()">
                    Start
                </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span id="a-startdate-{{row.allocation_identifier}}">{{row.start_date | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}} UTC</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="End" prop="end_date" [flexGrow]=".75">
            <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span class="datatable-header-cell-label draggable" (click)="sort()">
                    End
                </span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span id="a-enddate-{{row.allocation_identifier}}">{{row.end_date | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}} UTC</span>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>

  </td>
</tr>



<tr>
  <th></th>
  <td align="left">
        <br>
        <span *ngIf="flags.newProject">
            <button class="btn btn-primary btn-xs" 
                    id="createBtn"
                    [disabled]="!flags.okToSubmit || !flags.delta"
                    (click)="postProject()">
                Create Project and Exit
            </button>
        </span>
        <span *ngIf="flags.newProject">
            <img width="10" height="0">
            <button class="btn btn-primary btn-xs" 
                    id="createBtnStay"
                    [disabled]="!flags.okToSubmit || !flags.delta"
                    (click)="postProject('then-edit')">
                Create Project and Continue to Edit
            </button>
        </span>
        <span *ngIf="flags.editProject">
            <button class="btn btn-primary btn-xs ml5"
                    id="saveAndContinueBtn"
                    [disabled]="!flags.okToSubmit || !flags.delta"
                    (click)="putProject('continue')">
                Save Project Info and continue editing
            </button>
            <button class="btn btn-primary btn-xs ml5"
                    id="saveAndGetOut"
                    [disabled]="!flags.okToSubmit || !flags.delta"
                    (click)="putProject('getOut')">
                Save Project Info
            </button>
        </span>

        <span *ngIf="flags.missing" ngbTooltip={{flags.missing}} placement="top" class="ml5">
            <font color="orange"> What's missing? </font>
        </span>

        <!--  Need to go back to previous page if Cancel -->
        <button class="btn btn-warning btn-xs ml5" id="cancel" (click)="cancelEdit()">
                Cancel
        </button>
        <span *ngIf="false">
            <br>
            sharedLists: <json-formatter json="sharedLists" open="0"></json-formatter>
            <br>
            lists: <json-formatter json="lists" open="0"></json-formatter>
            <br>
            form: <json-formatter json="form" open="0"></json-formatter>
            <br>
            flags: <json-formatter json="flags" open="0"></json-formatter>
        </span>
  </td>
</tr>
<!-- show alerts here sometimes - Beginning of Project Membership list -->
<!-- Only show alerts here when member's list is big. If small, the user can see the alerts at the bottom of the page -->
<tr *ngIf="authAlerts && lists.projectAccounts && (authAlerts.length > 0) && (lists.projectAccounts.length > 10)" id="projectEditAlert1">
    <td colspan=2>
        <div *ngFor="let a of authAlerts">
            <font *ngIf="a.type=='danger'" color=red>
                {{a.msg}}
            </font>
            <font *ngIf="a.type!='danger'" color=green>
                {{a.msg}}
            </font>          
        </div>
    </td>
</tr>
<tr *ngIf="flags.readError">
    <td colspan=2><font color=red>{{flags.readError}}</font></td>
</tr>
</tbody>
</table>


<br>

<table *ngIf="flags.editProject && flags.readyToEdit" border=0 class="table table-striped projEditTable" >
    <tr>
        <td class="w30">
            Project Membership for <b>{{form.projectShortName}}</b>
            <span id="numberOfMembers"> ({{lists.projectAccounts ? lists.projectAccounts.length : 0}})</span>
        </td>
        <td class="w30" *ngIf="flags.paError" id="paErrorAlert1">
            <span>
                <font color="#ff0000">{{flags.paError}}</font>
            </span>
        </td>
        <td class="w30">
            <button class="btn btn-primary btn-xs"
                    *ngIf="lists.projectAccounts && (lists.projectAccounts.length > 0)"
                    [disabled]="!flags.pjaOkToSave" id="saveBtn"
                    (click)="updateProjectAccounts(form.projectShortName)">
                Save Project Membership
            </button>
            <img width=30px height=0px border=0>
            <button class="btn btn-primary btn-xs" id="reset"

                    [disabled]="!flags.pjaDelta"
                    (click)="resetProjectAccounts()">
                Reset
            </button>
        </td>
    </tr>
    <tr class="w80" *ngIf="lists.projectAccounts && displayTable">
        <ngx-datatable
            #pjAcMemTable
            id="pjAcMemTable"
            class="bootstrap card expandable centerText"
            [columnMode]="force"
            [limit]="100"
            [rowHeight]="'auto'"
            [headerHeight]="40"
            [footerHeight]="40"
            [scrollbarH]="true"
            [rows]="lists.projectAccounts"
            [sorts]="[{prop: 'userName', dir: 'asc'}]">

            <ngx-datatable-column name="Username" prop="userName" [maxWidth]="100">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-userName"
                                type="text"
                                placeholder="Filter by User Name"
                                [(ngModel)]="flags.filter.projAcc.userName"
                                (keyup)="updateFilter('userName')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>User Name</b></span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <a
                        class="text-primary1 bold"
                        style="cursor:pointer"
                        id="{{row.userName}}"
                        (click)="adminEditRecord(value)">
                        {{value}}
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="firstName" [maxWidth]="100">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-firstName"
                                type="text"
                                placeholder="Filter by First Name"
                                [(ngModel)]="flags.filter.projAcc.firstName"
                                (keyup)="updateFilter('firstName')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>First Name</b></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last name" prop="lastName" [maxWidth]="100">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-lastName"
                                type="text"
                                placeholder="Filter by Last Name"
                                [(ngModel)]="flags.filter.projAcc.lastName"
                                (keyup)="updateFilter('lastName')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Last Name</b></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Pref.Name" prop="preferredName" [maxWidth]="110">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-preferredName"
                                type="text"
                                placeholder="Filter by Preferred Name"
                                [(ngModel)]="flags.filter.projAcc.preferredName"
                                (keyup)="updateFilter('preferredName')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Preferred Name</b></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-email"
                                type="text"
                                placeholder="Filter by Email"
                                [(ngModel)]="flags.filter.projAcc.email"
                                (keyup)="updateFilter('email')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Email</b></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Account<br>Status" prop="accountStatus" [maxWidth]="65">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-accountStatus"
                                type="text"
                                placeholder="Filter by Account Status"
                                [(ngModel)]="flags.filter.projAcc.accountStatus"
                                (keyup)="updateFilter('accountStatus')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Account Status</b></span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span id="accStat-{{row.userName}}">{{row.accountStatus}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Project<br>Role" prop="projectRole" [maxWidth]="125">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-projectRole"
                                type="text"
                                placeholder="Filter by Project Role"
                                [(ngModel)]="flags.filter.projAcc.projectRole"
                                (keyup)="updateFilter('projectRole')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Project Role</b></span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <select id="projRole-{{row.userName}}" [(ngModel)]="row.projectRole"  (ngModelChange)="validatePA()">
                        <option value="Member">Member</option>
                        <option value="Co-PI" >Co-PI</option>
                        <option value="PI">PI</option>
                        <option value="Sub-Project PI">Sub-Project PI</option>
                    </select>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Proxy" prop="projectProxy" [maxWidth]="60">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <input type=checkbox [(ngModel)]="row.projectProxy"   (ngModelChange)="validatePA()" data-id="{{row.userName}}_proxy" id="{{row.userName}}_proxy" class="tcenter">
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Receive<br>Reports" prop="receiveReports" [maxWidth]="60">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <input type=checkbox [(ngModel)]="row.receiveReports" (ngModelChange)="validatePA()" class="tcenter">
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Default" prop="defaultProject" [maxWidth]="60">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <input type=checkbox [(ngModel)]="row.defaultProject" (ngModelChange)="validatePA()" class="tcenter">
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Membership<br>State" prop="memberStatus" [width]="150">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-memberStatus"
                                type="text"
                                placeholder="Filter by Membership Status"
                                [(ngModel)]="flags.filter.projAcc.memberStatus"
                                (keyup)="updateFilter('memberStatus')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Membership Status</b></span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <select 
                        *ngIf="flags.validMemberStatus"
                        [(ngModel)]="row.memberStatus"  
                        id="pms{{row.domId}}"
                        (ngModelChange)="validatePA()">
                        <option 
                                *ngFor="let opt of flags.validMemberStatus[row.userName]" 
                                value="{{opt}}">
                          {{opt}}
                        </option>
                    </select>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Comment" prop="comment" [width]="350">
                <ng-template hdrTpl let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <div>
                       <input  id="projAcc-filter-comment"
                                type="text"
                                placeholder="Filter by Comment"
                                [(ngModel)]="flags.filter.projAcc.comment"
                                (keyup)="updateFilter('comment')"
                       >
                    </div>
                    <span class="datatable-header-cell-label draggable" (click)="sort()"><b>Comment</b></span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <input type=text     [(ngModel)]="row.comment"        (ngModelChange)="validatePA()" id="{{row.userName}}_comment">
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </tr>
    <tr>
        <td class="w30" id="paErrorAlert2">
            <span>
                <font color="#ff0000" *ngIf="flags.paError">{{flags.paError}}</font>
            </span>
            <!-- show alerts here sometimes  - End of Project Membership list-->
            <ng-container 
                id="projectEditAlert2"
                *ngIf="!flags.pjaOkToSave && (!flags.delta || (flags.currBranch && !flags.currBranch.state.delta)) && (lists.projectAccounts && (lists.projectAccounts.length > 10))">
                <div 
                    uib-alert
                    *ngFor="let alert of authAlerts ; let i = index"
                    [ngClass]="'alert-' + (alert.type || 'warning')"
                    close="closeAlert(i)"
                    dismiss-on-timeout="99999">{{alert.msg}}
                </div>
            </ng-container>
        </td>
        
        <td class="w30">
            <button class="btn btn-primary btn-xs"
                    *ngIf="lists.projectAccounts && (lists.projectAccounts.length > 0)"
                    [disabled]="!flags.pjaOkToSave" id="saveProjectMembership"
                    (click)="updateProjectAccounts(form.projectShortName)">
                Save Project Membership
            </button>
            <img width=30px border=0>
            <button id="resetProjAcc"
                    class="btn btn-primary btn-xs"
 
                    [disabled]="!flags.pjaDelta"
                    (click)="resetProjectAccounts()">
                Reset
            </button>
            <span *ngIf="flags.saving">
                <img src="/frontend/loading.gif"> Saving ...
            </span>
        </td>
    </tr>
    <tr>
        <td class="w20">
            <button class="btn btn-primary btn-xs" id="addUserBtn" (click)="flags.showAddUser=!flags.showAddUser;">
                {{flags.showAddUser ? 'Hide list of other users' : 'Add new user'}}
            </button>
        </td>
        <td class="w80" *ngIf="flags.showAddUser">
            Filter the list below by typing a user name, first name, last  name, or preferred name:
            <input type=text 
                id="addUser"
                [(ngModel)]="flags.filterStr"
                (ngModelChange)="matchUsers(flags.filterStr, true)"
                size=20>
            <span *ngIf="flags.tooMany">
                found {{flags.rowsFound}}, only displaying {{lists.matchedUsers.length}}
            </span>
            <span *ngIf="!flags.tooMany && lists.matchedUsers && (lists.matchedUsers.length > 0)">
                {{lists.matchedUsers.length}} found
            </span>
        </td>
    </tr>
</table>

<div *ngIf="flags.showAddUser && flags.editProject">
    <ngx-datatable
        #pjau
        id="pjauTable"
        class="bootstrap card expandable"
        columnMode="flex"
        [rowHeight]="auto"
        [headerHeight]="30"
        [footerHeight]="30"
        [scrollbarH]="true"
        [draggable]="false"
        [rows]="lists.matchedUsers">
        <ngx-datatable-column name="Username" prop="userName" [flexGrow]="0.25">
        </ngx-datatable-column>
        <ngx-datatable-column name="First Name" prop="firstName" [flexGrow]="0.25">
        </ngx-datatable-column>
        <ngx-datatable-column name="Last Name" prop="lastName" [flexGrow]="0.25">
        </ngx-datatable-column>
        <ngx-datatable-column name="Preferred Name" prop="preferredName" [flexGrow]="0.5">
        </ngx-datatable-column>
        <ngx-datatable-column name="Account Status" prop="status" [flexGrow]="0.25">
        </ngx-datatable-column>
        <ngx-datatable-column name="Comment" prop="comment" [flexGrow]="1">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="usrComment">
                    <input type=text [(ngModel)]="row.comment" *ngIf="!lists.pjaIndex[row.userName]" class="projOneLiner" id= "comment">
                </div>
                <span>
                    <button class="btn btn-primary btn-xs"
                        *ngIf="!lists.pjaIndex[row.userName]"
                        id="addBtn_{{row.userName}}"
                        (click)="addProjectAccount(form.projectShortName, row.userName, comment)">
                        Add
                    </button>  
                    <span *ngIf="lists.pjaIndex[row.userName]">
                        Already a member
                    </span>
                </span>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<!-- Project ACL Bridge -->
<div id="projAclBrdg" *ngIf="lists.projAclList && lists.projAclList.length" class="col-md-6 col-lg-6 col-xl-6 mtb20">
    <div class="card ub3-card projAclTable">
        <div class="card-body mtb20">
            <h4>ACL Tags associated with the Project: <span class="name">  </span><span
                class="name bold">{{form.projectShortName}}</span>
            </h4>
            <div id="projAclTagsTable">
                <ngx-datatable
                    #projAclTable
                    id="projAclTable"
                    class="bootstrap card expandable"
                    columnMode="flex"
                    [rowHeight]="auto"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [scrollbarH]="true"
                    [rows]="lists.projAclList"
                    [sorts]="[{prop: 'acltagname', dir: 'asc'}]">
                        <ngx-datatable-column name="Name" prop="acltagname" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div class="projects projShNameTest">
                                    <a  style="cursor: pointer"
                                        id="{{row.acltagname}}"
                                        (click)="navigateToAclPage(row.acltagname)">
                                        <font color=blue>
                                            {{row.acltagname}}
                                        </font>
                                    </a>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Comment" prop="comment" [flexGrow]=".6">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <span class="project-membership-comment">
                                    {{row.comment}}
                                </span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Status" prop="deleted" [flexGrow]=".6">
                        </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
<!-- Always show alerts here  - End of Edit section -->
<span id="projectEditAlert3">
  <div 
    uib-alert
    *ngFor="let alert of authAlerts ; let i = index"
    [ngClass]="'alert-' + (alert.type || 'warning')"
    close="closeAlert(i)"
    dismiss-on-timeout="99999">{{alert.msg}}
  </div>
</span>
<br><br>
