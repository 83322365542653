var manageUnixGroupsModule =
    (function(){
            "use strict";
            var publicStuff;
            var cookies, httpLegacy, http, scope, timeout, win, comMod, valMod;
            cookies = httpLegacy = http = scope = timeout = win = comMod = valMod = null;  // cached services
            var jsServices = {};
            var alertError = {type : 'danger'};
            var alertSuccess = {type : 'success'};
            var win, cscope ;                       // services cache

            publicStuff = {
                init : init,
                validate : validate,
                viewGroup : viewGroup,
                findGroup : findGroup,
                editGroup : editGroup,
                saveChanges : saveChanges,
                selectNewUG : selectNewUG,
                setServices : setServices,
                updateGroupView : updateGroupView
            };
            function setServices(services){
                var i, serv;
                for (i in services){
                  serv = services[i];
                  jsServices[serv.sname] = serv;
                }
                cookies  = jsServices.cookies;
                httpLegacy = jsServices.http;
                http     = jsServices.httpNew;
                scope    = jsServices.scope;
                timeout  = jsServices.timeoutObj.timeout;
                win      = jsServices.window;
                comMod   = jsServices.commonModule;
                valMod   = jsServices.validationModule;
            }

            function init(scope, httpLegacy, http, pageName, lists, flags, form, w){
                win = w;
                cscope = scope;
                scope.publicPage(true);
                flags.prevSavedForm = {};
                flags.ugSearch = false;
                flags.joinedUnixGroup = false;
                scope.authAlerts = [];
                lists.matchedUsers = [];
                lists.groupSearch = [];
                form.selectedGroup = {};
                // call api everytime we enter page for current data
                manageYourUnixGroups(http, flags, form, scope.authAlerts);
                if(!scope.flags){
                    scope.flags = {
                        displayedPageName : pageName,
                        hideManageUnixGroups : false,
                        hideHomePage : true,
                        adminPageLevel : 4,
                        level : 4,
                        fieldErrors : {},
                        delta : false,
                        submit : null,
                        index : null,
                        missing : null,
                        ug_testing : false
                    };
                }
                if(!scope.form){
                    scope.form = {};
                }
                if(!scope.lists){
                    scope.lists = {};
                }
                if(!lists.allUnixGroups){
                    getAllUnixGroups(http, scope.flags, scope.lists, scope.authAlerts);
                }
            }

            function validate(form, flags){
                flags.fieldErrors = {};
                flags.submit = false;
                flags.delta = false;
                flags.okToSubmit = true;
                valMod.validate('manageUnixGroups', form, flags.fieldErrors);
            }


            function refreshViewGroup(http, flags, form, alerts){
                http.get("auth/viewUnixGroup", {params : {unixgroup_id : form.unixgroup_id}})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            form.users = resp.users;
                            form.public = resp.public;
                            comMod.objCopyGuts(form, flags.prevSavedForm);
                        } else{
                            flags.groupOwner = false;
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                });
            }

            function saveChanges(http, form, flags, lists, cookies, alerts){
                if(flags.dataThatChanged.manageYourUnixGroups){
                    // ensure there are no 'null' values
                    var array = flags.dataThatChanged.manageYourUnixGroups.filter(Boolean);
                        // DONT send back all with dict(s) id and field name that changed
                        // only need dict of the new default
                        var cleanArray = [];
                        array.forEach(function(oneDict){
                            if(oneDict.default){cleanArray.push(oneDict)}
                        });
                        array = cleanArray;


                    http.put("auth/manageUnixGroups", {form : array})
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                alertSuccess.msg = 'Successfully Updated Default Group ';
                                alerts.push(alertSuccess);
                                flags.okToSubmit = false;
                                manageYourUnixGroups(http, flags, form, alerts);
                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);
                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    });
                } else if(flags.dataThatChanged.users){
                    var usersArray = flags.dataThatChanged.users.filter(Boolean);
                    http.put("owner/manageUnixGroups", {form : usersArray})
                        .then(function(response){
                            var resp = response.data;
                            if(resp.success){
                                alertSuccess.msg = 'Successfully Updated Owner in group ';
                                alerts.push(alertSuccess);
                                flags.okToSubmit = false;
                                refreshViewGroup(http, flags, form, alerts);
                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);

                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    });
                }
                comMod.doWhenAPICallsDone(function(){
                    if(flags.dataThatChanged.users){
                       timeout(function(){document.getElementById('rowAlert').scrollIntoView();},1000);
                    } else {
                        win.scrollTo(0,0);
                    }
                });
            }

            function manageYourUnixGroups(http, flags, form, alerts){
                flags.okToSubmit = false;
                http.get("auth/manageUnixGroups")
                    .then(function(response){
                        var resp = response.data ? response.data : response;
                        if(resp.success){
                            form.manageYourUnixGroups = resp.manageUnixGroups;
                            comMod.objCopyGuts(form, flags.prevSavedForm);
                            updateGroupIndex(form);
                        } else{
                            alerts.push({
                                type : 'danger', msg : resp.error
                            });
                        }
                    }).catch(function(response, status){
                        flags.hideManageUnixGroups = true;
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    });
            }

            function updateGroupIndex(form){
                // useful for fast indexing
                // message current membership status on user search always
                form.unixGroupIndex = {};
                for(var i in form.users){
                    var member = form.users[i];
                    form.unixGroupIndex[member.userName] = member;
                }
            }

            function viewGroup(http, httpLegacy, flags, form, lists, owner, unixGroupId, name, index, scope){
                delete form.id;
                form.users = [];
                scope.authAlerts = [];
                flags.groupView = true;
                flags.groupOwner = owner;
                flags.index = index;
                form.name = name;
                form.searchName = '';
                if(!flags.isYourGroupOpen) flags.isYourGroupOpen = {};
                if(flags.isYourGroupOpen[unixGroupId]){
                  flags.isYourGroupOpen[unixGroupId] = false;
                } else {
                  flags.isYourGroupOpen = {};
                  flags.isYourGroupOpen[unixGroupId] = true;
                }
                // wipe this search list out as they are now viewing new Group
                lists.matchedUsers = [];
                http.get("auth/viewUnixGroup", {params : {unixgroup_id : unixGroupId}})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            form.users = resp.users;
                            form.public = resp.public;
                            form.unixgroup_id = unixGroupId;
                            comMod.objCopyGuts(form, flags.prevSavedForm);
                        } else{
                            flags.groupOwner = false;
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    scope.authAlerts.push(alertError);
                }).finally(function(){
                    updateGroupIndex(form);
                });

                if(owner === true){
                    comMod.getUsersNotSystemCreated(httpLegacy, lists, flags);
                }

            }

            function updateGroupView(http, form, flags, unixGroupId){
                http.get("auth/viewUnixGroup", {params : {unixgroup_id : unixGroupId}})
                    .then(function(response){
                        var resp = response.data ? response.data : response;
                        if(resp.success){
                            form.users = resp.users;
                            form.public = resp.public;
                            form.unixgroup_id = unixGroupId;
                            comMod.objCopyGuts(form, flags.prevSavedForm);
                            updateGroupIndex(form);
                        } else{
                            flags.groupOwner = false;
                        }
                    }).catch(function(response, status){
                        // no error message here ?
                });
            }

            function getAllUnixGroups(http, flags, lists, alerts){
                http.get("auth/allUnixGroups")
                    .then(function(response){
                        var resp = response.data ? response.data : response;
                        if(resp.success){
                            lists.allUnixGroups = resp.allGroups;
                        } else{
                            alerts.push({
                                type : 'danger',
                                msg : resp.error
                            });
                        }

                    }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    });
            }

            //clean up upon new UG selection
            function selectNewUG(flags, form, lists, selectedGroup){
                lists.groupSearch = [];
                if(selectedGroup && selectedGroup.id) {
                    form.id = '';
                    form.requiresApproval = null;
                    flags.ugSearch = true;
                    flags.joinedUnixGroup = false;
                }
            }

            function findGroup(http, flags, form, lists, id, alerts){
                http.get("auth/findUnixGroup", {params : {unixgroup_id : id}})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            flags.matchedName = resp.match;
                            flags.searchCompleted = resp.success;
                            lists.groupSearch = [];
                            var selectedGroup = resp.viewGroup;
                            form.id = selectedGroup.id;
                            form.requiresApproval = selectedGroup.requiresApproval;
                            lists.groupSearch.push(selectedGroup);
                            // disable search button after getting the result
                            flags.ugSearch = false;
                        } else{
                            alerts.push({
                                type : 'danger',
                                msg : resp.error
                            });
                        }

                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
            }

            function editGroup(http, form, flags, cookies, lists, selected, type, action, index, alerts, timeout){
                var object = {}, apiToCall = "manageUnixGroups", method = 'PUT', msg = '', role;
                if(type === 'default'){
                    role = 'auth';
                    object.default = true;
                    object.id = selected;
                    msg = 'Changed Default Group';
                } else if(type === 'remove'){
                    flags.isYourGroupOpen = {};
                    role = 'auth';
                    object.memberStatus = 'Deleted';
                    object.id = selected;
                    var resName = form.manageYourUnixGroups[index].resourceName;
                    msg = 'Removed ' + resName + ' Group';
                } else if(type === 'deleteMember'){
                    role = 'owner';
                    apiToCall = "removeMemberUnixGroup"
                    object.memberStatus = 'Deleted';
                    object.id = selected;
                    msg = 'Removed ' + form.users[index].preferredName + ' from Group '+ flags.currGrpName;
                } else if(type === 'addUser'){
                    role = 'owner';
                    object.unixgroup_id = form.unixgroup_id;
                    object.userName = selected;
                    method = 'POST';
                    msg = 'Added User to Group';
                    apiToCall = 'addMemberUnixGroup';
                } else if(type === 'addYourMembership'){
                    role = 'auth';
                    object.unixgroup_id = selected;
                    msg = 'Added to Group';
                    method = 'POST';
                    apiToCall = 'joinPublicUnixGroup';
                }
                // NOW PUT OR POST ?
                var cdata = {form : object};
                if(method === 'PUT'){
                    http.put(role + '/' + apiToCall, cdata)
                        .then(function(response){
                            var resp = response.data ? response.data : response;
                            if(resp.success){
                                alertSuccess.msg = 'Successfully ' + msg;
                                alerts.push(alertSuccess);
                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);
                            }
                            //delay for plugins to finish
                            timeout(function(){
                                if(type === 'deleteMember') {
                                    updateGroupView(http, form, flags, form.unixgroup_id);
                                } else {
                                   manageYourUnixGroups(http, flags, form, alerts);
                                }
                            }, 900);
                        }).catch(function(response, status){
                            alertError.msg = response.data.detail;
                            alerts.push(alertError);
                        });
                } else if(method === 'POST'){
                    http.post(role + '/' + apiToCall, cdata)
                        .then(function(response){
                            var resp = response.data ? response.data : response;
                            if(resp.success){
                                if(resp.joinUnixGroup){
                                    msg = resp.joinUnixGroup;
                                    flags.joinedUnixGroup = true;
                                    form.selectedGroup = '';
                                    alertSuccess.msg = msg;
                                    alerts.push(alertSuccess);
                                }
                                if(type === 'addUser'){
                                    updateGroupView(http, form, flags, form.unixgroup_id);
                                } else if(type === 'addYourMembership'){
                                    manageYourUnixGroups(http, flags, form, alerts);
                                    getAllUnixGroups(http, flags, lists, alerts);
                                }

                            } else{
                                alertError.msg = resp.error;
                                alerts.push(alertError);
                            }
                        }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    });
                }
                
                comMod.doWhenAPICallsDone(function(){
                    var id = (Object.keys(flags.isYourGroupOpen).length === 0) ? 'grpAlert' : 'rowAlert';
                    var ele = document.getElementById(id);
                    ele.scrollIntoView();
                        //init(cscope, http, http, "Manage UNIX Groups", lists, flags, form, win);
                        // timeout(function(){
                            // win.location.reload();
                            //cscope.initManageUnixGroups("Manage UNIX Groups");
                            //manageYourUnixGroups(http, flags, form, alerts);
                            //getAllUnixGroups(http, flags, lists, alerts);
                        // }, 1000)
                });
                
            }

            return publicStuff;
        }
    )();
module.exports = {manageUnixGroupsModule}
