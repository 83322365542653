
<!--  
    parameters for this component:
        path    referes to the path of the API function (for example 'svc/Account')
        method  referes to the method name (example: 'POST')
        fid     name of the field,  similar name as the one used in ngModel
-->

<ng-container  *ngIf="arrayFieldErrors[path][method][fid]" >

    <div class="warning inputwarning" 
        *ngIf="arrayFieldErrors[path][method][fid].error"
    >
        <span class="glyphicon glyphicon-remove-sign"></span>
        <span>{{arrayFieldErrors[path][method][fid].error}}</span>
    </div>
    <div class="warning inputwarning" *ngIf="arrayFieldErrors[path][method][fid].dispRequired">
        <span class="glyphicon glyphicon-remove-sign"></span>
        <span><font color=red>Required Field</font></span>
    </div>

</ng-container>
