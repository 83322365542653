
You will be redirected to the ALCF Foreign National Access Request
form to upload digital copies of your documents.
Once you complete this step, you will be brought back to this form.
<br><br>                
The data you have changed on this form is auto-saved when you leave this page and go to ALCF Foreign National Access Request site.
<br><br>
<b>Upload digital copies of the following documents:</b>
<pre>
   - Latest CV/resume (PDF format only) with no gaps in time. If there is a gap of 
     6 months or more, reason for the gap is required.
   - Passport
   - Following USCIS documents where applicable :
      Green card/LPR
      Visa
      Other supporting documents as appropriate such as 
          I-20, I-797, I-485, I-94, I-90, DS-2019, I-551, I-512, I-589, EAD.
</pre>

Only one file per document category can be uploaded. BMP, JPEG, JPG, PDF, or PNG are 
the acceptable file types for all documents except the CV, which has to be in a PDF format. 
The maximum file size in each case is 10 MB.
You will have 
<b>{{ domainGlobals.inactivityTimeoutUserSession / 60 }} minutes</b>
to complete the ALCF Foreign National Access Request Form after 
which the session will time out and you will have to fill out the information again. 
