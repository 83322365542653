
<ng-container *ngIf="fid && fieldErrors && fieldErrors[fid]">

    <div *ngIf="fieldErrors[fid].error" class="warning inputwarning has-error">
        <span id="err-{{fid}}">{{fieldErrors[fid].error}}</span>
    </div>
    <div *ngIf="fieldErrors[fid].dispRequired" class="warning inputwarning has-error" >
        <span id="req-{{fid}}">Required Field</span>
    </div>

</ng-container>

