


// Account Reactivate Module
var accReactModule = (function(){

    "use strict";

    var timeout, cookies, http, httpLegacy, scope, win, comMod, valMod, edrMod, dayjs ;
    var jsServices = {};
    timeout= cookies= http= httpLegacy = scope= win= comMod= valMod= edrMod = dayjs = null;
    timeout = null;
    var ub3consoleLog = null;

    var publicStuff;
    publicStuff = {
        directingToFavor : directingToFavor,
        getAccountInfo : getAccountInfo,
        init : init,
        initFormValues : initFormValues,
        favorDataNeededOrPoliciesNotAcknowledged : favorDataNeededOrPoliciesNotAcknowledged,
        onNoCountry : onNoCountry,
        reactAnswerVerified : reactAnswerVerified,
        reactivateRequest : reactivateRequest,
        saveAccountInfo : saveAccountInfo,
        saveAndReturnLater : saveAndReturnLater,
        setServices : setServices,
        setPreferredName : setPreferredName,
        submitReactivate : submitReactivate,
        validateAllFields : validateAllFields,
        validateAllFieldsNow: validateAllFieldsNow,
        validateEmail : validateEmail,
        validateList : validateList,
        validateUniqueEmail : validateUniqueEmail
    };

    function init(httpLegacy, http, cookies, sc, pageName, backFromFavor, q, t, survey){
        scope = sc;
        comMod.publicPage(true);
        timeout = t;
        ub3consoleLog = comMod.ub3consoleLog;
        if(!scope.user || comMod.isEqual(scope.user, {})){
            // not ready, wait a little bit
            timeout(function(){ 
                init(httpLegacy, http, cookies, scope, pageName, backFromFavor, q, t, survey);
            }, 300);
            return;
        }
        comMod.getSetContext(scope, pageName, function(){
            scope.flags = {
                displayedPageName : pageName,
                hasError: {},       // ng2+ needs this
                hideHomePage : true,
                reactivateAccount : true,
                reactLevel : 4,
                level : 0,
                fieldErrors : {},
                missing : null,
                answerVerified : false,
                apiType : 'limited/',
                okToSubmit : true,
                disableReactivation : false,
                LLStage : 'A'
            };

            if(!scope.form){
                scope.form = {};
            }
            if(!scope.lists){
                scope.lists = {};
            }

            scope.flags.reactivateType = 'reactivate';

            domainGlobals(scope.lists, scope.flags);
        });
        if (scope.user && scope.user.signedIn && (scope.user.role == 'limited')){
            initFormValues(scope);
        }

    }

    function setServices(services){
        // fixme - should only be included in alcf, should not be packaged with cels src
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        cookies = jsServices.cookies;
        if (!cookies.getObject) cookies.getObject = cookies.get;
        httpLegacy = jsServices.http;
        http    = jsServices.httpNew;
        timeout = jsServices.timeoutObj.timeout;
        win     = jsServices.window;

        comMod = jsServices.commonModule;
        valMod = jsServices.validationModule;
        edrMod = jsServices.editRecordModule;
        dayjs = jsServices.dayjs;
    }

    function initFormValues(scope){
        var c = comMod.getLocalStorage('anl-UB3-LLF');
        var pjList = cookies.get('anl-UB3-act-react-pj');
        var u = scope.user;

        if(u && u.signedIn && (u.role == 'limited')){
 
            scope.flags.level = 6.5;

            if(!scope.lists.institutions){
                comMod.getLimitedInstitutionsList(httpLegacy, scope.lists);
            }

            if(!scope.lists.countries){
                comMod.getLimitedCountries(scope.lists);
            }

            if(!scope.lists.activeProjects){
                comMod.getLimitedProjectsList(scope.lists);
                comMod.doWhenAPICallsDone(function(){
                    scope.lists.activeProjects = comMod.sortArrayOnKey(scope.lists.activeProjects, 'name', false);
                });
            }

            if(!scope.form.id || !scope.form.projectShortNameList){
                // timeout to ensure we have access as limited user
                timeout(function(){ 
                    getAccountInfo(httpLegacy, http, scope.form, scope.flags, scope.lists, scope, function(){
                        if (pjList && pjList != ''){
                            scope.form.projectShortNameList = pjList;
                        }
                    });
                }, 300);
                return;
            }
        }


        if(c && u && u.signedIn && (u.role == 'limited')){
            c.email = u.email;
            c.firstName = u.firstName;
            c.lastName = u.lastName;
            scope.LLF = c;

            if(c.answerVerified){
                scope.flags.level = 5;
                scope.flags.answerVerified = true;
            }
            if(c.projectShortNameList){
                scope.form.projectShortNameList = c.projectShortNameList;
                scope.flags.level = 6.5;
            }

            if(!scope.lists.institutions){
                comMod.getLimitedInstitutionsList(httpLegacy, scope.lists);
            }

            if(!scope.lists.countries){
                comMod.getLimitedCountries(scope.lists);
            }



            if(!scope.form.id && scope.form.projectShortNameList){
                // timeout to ensure we have access as limited user
                timeout(function(){ 
                    getAccountInfo(httpLegacy, http, scope.form, scope.flags, scope.lists, scope);
                }, 300);
                return;
            }
        }
    }
    // Previously, below function was called only when we change a project name or comment.
    // But it was causing problems when we select another project or change comment the second time
    function validate(httpLegacy, http, form, flags, lists, q, cookies, LLF, scope){
        flags.fieldErrors = {};
        flags.noMatch = false;
        valMod.validate('accountReactivate', form, flags.fieldErrors);
        flags.missing = valMod.computeMissing(flags.fieldErrors);
        var cookieObj = comMod.getLocalStorage('anl-UB3-LLF');
        if ((typeof cookieObj) == 'string') cookieObj = JSON.parse(cookieObj);
        
        if(!form.projectShortNameList || (form.projectShortNameList == '')){
            flags.level = 5;
        }
        if(form.projectShortNameList && (form.projectShortNameList !== '')){
            flags.level = 6.5;
            cookieObj.projectShortNameList = form.projectShortNameList;
            comMod.setLocalStorage('anl-UB3-LLF', cookieObj);
        }
    }

    function reactAnswerVerified(cookies, scope){
        var obj = comMod.getLocalStorage('anl-UB3-LLF');

        obj.answerVerified = true;
        comMod.setLocalStorage('anl-UB3-LLF', obj);
    }

    function getAccountInfo(httpLegacy, http, form, flags, lists, scope, cb){
        flags.getApiNames = ['account', 'addresses', 'emails', 'institutions', 'phones', 'projects', 'resources'];
        flags.prevSavedForm = {};
        flags.accountUpdate = true;
        //If User has a project show account update limited

        edrMod.getRecord(http, flags, lists, null, function(newForm){
            
            flags.prevSavedForm = {};
            lists.usaStates = comMod.getUsaStates();
            if(!lists.careerLevels){comMod.careerLevels(lists)}
            newForm.projectShortNameList = form.projectShortNameList;
            newForm.policyAlcfAck = form.policyAlcfAck ? true : false;
            // policyAck should be unchecked when page first loads
            newForm.policyAck = false;
            // if UNKNOWN clear and require user to choose from list
            if(newForm.employment_level ==='UNKNOWN'){
                    newForm.employment_level = '';
            }
            //make them click this every time
            newForm.policyAck = false;
            comMod.objCopyGuts(newForm, form);
            comMod.objCopyGuts(newForm, flags.prevSavedForm);

            // This next line was here in error. We were assuming that 
            // because we are reading account info, therefore the security answer
            // was already verified. This was wrong and it was an undesired
            // side effect.
            //flags.answerVerified = true;

            flags.reactivateSubmitted = false;

            var i, val;
            for (i in form.accountAddresses){
                val = form.accountAddresses[i];
                if(val.hasOwnProperty('method') && val.label == "Shipping"){
                    val['city'] = '';
                    val['country'] = '';
                    val['state'] = '';
                    val['street1'] = '';
                    val['street2'] = '';
                    val['street3'] = '';
                    val['zip'] = '';
                }
            };

            if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){
                // made copy of data from apis, lets open form page
                flags.viewDetails = true;
                myFavor(httpLegacy, flags, lists);
                // validate form again we have new data pushed in
                edrMod.validateAccountUpdate(form, flags);
                comMod.validateListsAfterGet(flags, form);
            }
        });
        comMod.doWhenAPICallsDone(function(){
            validateAllFields(form);
            if (cb) cb();
        });
    }
    function myFavor(http, flags, lists){
        http.get(flags.apiType + "favor").success(function(resp){
            if(resp.success){
                var f = resp.favor[0];
                if(f){
                    flags.newFavorDataNeeded = f.newFavorDataNeeded;
                    flags.expirationDate593 = f.expirationDate593;
                }
            } else{
                flags.newFavorDataNeeded = false;
            }
        }).error(function(data){
            lists.errorAccountUpdate = data.data.detail;
        });
    };


    function directingToFavor(win, http, form, flags, lists, cookies, timeout, callback){
        var a = null;

        //page  = 'https://apps-test.anl.gov/registration/user_bases/new' ;
        var page = lists.domainGlobals.favorRedirectPath;
        
        a = comMod.commaDelimitedToArray(form.projectShortNameList);
        step1();
        
        function step1(){
            //a[1] = 'more stuff'; 
            var p = {params: {projectShortNameList : a}};
            var api = flags.apiType + "favorHostId";
            http.get(api, p).success(function(resp){
                if(resp.success){
                    // we have the FAVOR sponsor id
                    step2(resp.favorHostId);
                } else{
                    if(flags.reactivateAccount){
                        flags.disableReactivation = true;
                    }
                }
            }).error(function(data){
                lists.errorAccountUpdate = data.data.detail;
            });
        }

        function step2(favorHostId){
            // call saveForLater, so that usCitizen field gets saved
            // Also, Janet wants favorHost to be part of the form for Foreign Nationals

            if(!flags.reactivateAccount){
                flags.createUbRequestId = 'createUbRequestId';
            } else if(flags.reactivateAccount){
                flags.createUbRequestId = 'reactivateCreateUbRequestId';
            }

            var formCopy = comMod.objCopy(form);
            // // arrays are not copied correctly.
            // formCopy.affiliationList = form.affiliationList;

            formCopy.favorHost = favorHostId;
            var p = {favorHostId : favorHostId,}
            var api = flags.apiType + flags.createUbRequestId;
            http.post(api, p)
                .success(function(hresp){
                    //gresp = hresp;
                    if(hresp.success){
                        var ubRequestId = hresp.code;
                        step3(favorHostId, ubRequestId);
                        flags.disableReactivation = false;
                    } else{
                        flags.disableReactivation = true;
                    }
                }).error(function(data){
                if(flags.reactivateAccount){
                    lists.errorAccountUpdate = 'Your session has expired. Please select "Cancel Reactivation Request", and try again.';

                } else{
                    lists.errorAccountUpdate = data.data.detail;
                }

            });
        }

        function step3(favorHostId, ubRequestId){
            //window.sessionStorage.setItem('finalData', form);

            // for issue 1295 : this next block is for the new FAVOR feature where we can prepopulate their form with more info
            var EXTRA_PARAMS = true; // enable this when FAVOR delivers their changes to their prod site  OR to test MOCK with more params
            // if you enable this, then also change the file src/backend/userbase/settings.py
            // look for EXTRA_PARAMS
            if (EXTRA_PARAMS){
                // in production or test site. POST method
                var iform = makeParameters(form);
                iform.sponsor_id = favorHostId;
                iform.ub_request_id = ubRequestId ;
                // this next line redirects the browser to another website with POST method
                //alert('going to favor page '+page);
                comMod.sendForm(page, iform); 
            }else{
                url = page + '?sponsor_id=' + favorHostId + '&ub_request_id=' + ubRequestId;
                //url ="https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage";
                // this next line redirects the browser to another website
                win.location.assign(url);
                if(callback){
                    callback();
                }
            }
        }
        function makeParameters(form){
            var i, e, ai, inst, email;
            inst = email = null;
            // get the primary one from this array
            for (i in form.accountEmails){
                    e = form.accountEmails[i];
                    if (e.primary){
                        email = e.email;
                    }
            }
            // get the primary Institution one from this array
            for (i in form.accountInstitutions){
                    ai = form.accountInstitutions[i];
                    if (ai.primaryInstitution){
                        inst = ai.affiliation;
                    }
            }
            var addr = (form.accountAddresses.length > 0) 
                        ? form.accountAddresses[0]
                        : {};

            if (inst.length > 40) { inst = inst.slice(0,40);}

            return {
                name_first: form.firstName,
                name_mid:   form.nmi ? 'NMI' : (form.middleName ? form.middleName : 'NMI'),
                name_last: form.lastName,
                email_address: email,
                alternate_email_address: form.altEmail ? form.altEmail : '',
                us_citizen: 'N',
                visit_purpose_code: 15,
                favor_created:'N',
                employer_name:  inst,
                employer_addr_ln_1: addr.street1,
                employer_addr_ln_2: addr.street2,
                employer_addr_ln_3: addr.street3,
                employer_addr_city: addr.city,
                employer_addr_state: addr.state,
                employer_addr_zip: addr.zip,
                employer_addr_country: addr.country ? addr.country.toUpperCase() : '',
            };
        }

    }

    // do validation for all fields in the form, including arrays.
    // do not take into account the bottom 3 checkboxes.
    //
    function validateAllFields(form, callback){
        scope.confirmSaveChecked = false;
        scope.confirmBtn = false;
        comMod.onStopCalling(700, function(){
            validateAllFieldsNow(form);
            if (callback) callback();
        });
    }
    function validateAllFieldsNow(form){ //this function is asynchronous now

        var formNames, ar, i, j, row, formName, ok, skip, id, e;
        // for this to work, call:
        // validateInput(fieldName, event, index)

        copyWorkToShipping(form);
        scope.alerts = [];
        scope.flags.missing = null;
        edrMod.validateAccountUpdate(form, scope.flags);
        
        if (scope.flags.fieldErrors.status) delete scope.flags.fieldErrors['status'];
        if (scope.flags.fieldErrors.temp_ub_request_id) delete scope.flags.fieldErrors['temp_ub_request_id'];
        if (scope.flags.reactivateAccount) {
            if (scope.flags.fieldErrors.accountQuestions) delete scope.flags.fieldErrors['accountQuestions'];
        }
        scope.flags.missing = valMod.computeMissing(scope.flags.fieldErrors);

        scope.flags.arrayFieldErrors = {};

        formNames = [  
            'accountEmails', 
            'accountPhones', 
            'accountAddresses',
            'accountInstitutions',
            'accountQuestions'
        ];
        ok = !scope.flags.missing;
        for (i in formNames){
            formName = formNames[i];
            ar = form[formName];
            
            for (j in ar){
                row = ar[j];
                skip = (row.status === 'Deleted');
                if (!skip) {
                    
                    validateList(formName, row, row.id);
                    ok = ok && (!scope.flags.missing);
                }
            }
        }
        scope.flags.updFormComplete = ok;

        // these extra steps are for angular 2+.  hasError is used to determine which display class to use
        scope.flags.hasError = {};
        for (id in scope.flags.fieldErrors){
            e = scope.flags.fieldErrors[id];
            
            scope.flags.hasError[id] =  (e && (e.error || e.required) && scope.flags.scrollError);
        }
        // email validation for uniqueness
        form.accountEmails = comMod.getUniqueEmails(form.accountEmails);
        for (var key in form.accountEmails) {
          var elem = form.accountEmails[key];
          if(elem.status !== 'Deleted'){
            validateUniqueEmail(elem, scope.flags.arrayFieldErrors, scope);
          }
        }
    }    
    function copyWorkToShipping(form){
        if (!form.addressVal) return;

        var workAddress = form.accountAddresses[0];
        Object.keys(form.accountAddresses[0]).forEach(function(key, val){
            if ((key != 'label') && (key != 'id') && (key != 'method')){
                form.accountAddresses[1][key] = workAddress[key];
            }
        });
    }
    function validateList(formName, obj, id) {
        var form = scope.form;
        if (formName == 'accountInstitutions'){
            form.accountInstitutions = comMod.uniqueUAInstitutions(form.accountInstitutions);
        }
        if (formName == 'projectInstitutions'){
            form.projectInstitutions = comMod.uniqueUAInstitutions(form.projectInstitutions);
            formName = 'accountInstitutions';
            scope.flags.origFormName = 'projectInstitutions';
        }
        comMod.validateList(formName, obj, id, scope.flags, form);
    };

    function validateUniqueEmail(row, arrayFieldErrors, scope){
        var t = scope;
        if(!row || !row.email) return;
        var emValReqd = false;
        var prevEmailArr = [];
        var checkNewEmail = false;
        var prevEmailChanged = false;

        // check if the email is in the previously saved emails list and has changed
        prevEmailArr = t.flags.prevSavedForm.accountEmails.filter(function(pEmailItem){
            return (pEmailItem.id === row.id);
        });

        prevEmailChanged = ((prevEmailArr.length === 1) && (prevEmailArr[0].email !== row.email));

        // check for new emails
        if(prevEmailArr.length === 0){
            var nameInPrevEmailsArr = false;
            var delNameInAccEmailsArr = false;
            // check if the new name was already added to the account and now in deleted status
            nameInPrevEmailsArr = t.flags.prevSavedForm.accountEmails.filter(function(pEmailItem){
                return (pEmailItem.email === row.email);
            }).length>0;
            if(nameInPrevEmailsArr){
                delNameInAccEmailsArr = t.form.accountEmails.filter(function(aEmailItem){
                    return (aEmailItem.email === row.email && aEmailItem.status === 'Deleted');
                }).length>0;
            }
    
            checkNewEmail = (!nameInPrevEmailsArr || (nameInPrevEmailsArr && !delNameInAccEmailsArr));
        }

        emValReqd = (checkNewEmail || prevEmailChanged);
        // do email validation
        if(emValReqd) {
            valMod.validateUniqueEmail(row, function(resp){
                arrayFieldErrors.accountEmails[row.id]['email'] = {error: resp, label: 'Email'};
            });
        }
    }

    function onNoCountry(obj, form){
        var i, value;
        for (i in form.accountAddresses){
            value = form.accountAddresses[i];
            if(value.label == obj.label){
                Object.keys(value).forEach(function(k, val){
                    if(k != 'label' && k != 'id' && k != 'country' && k != 'method'){
                        value[k] = '';
                    }
                });
            }
        }

    }

    function setPreferredName(form){
        var f = form ;
        if (f.firstName)     f.firstName = f.firstName.trim();
        if (f.lastName)      f.lastName  = f.lastName.trim();
        if (f.preferredName) f.preferredName = f.preferredName.trim();
        if (!f.preferredName || (f.preferredName == '')){
            f.preferredName = 
                (f.firstName  ? f.firstName+' '  : '') +
                (f.middleName ? f.middleName+' ' : '') + 
                (f.lastName   ? f.lastName   : '');
            f.preferredName = f.preferredName.trim();
        }
    }

    function favorDataNeededOrPoliciesNotAcknowledged(form, scope){
        if (scope.flags.newFavorDataNeeded === 'true'){
            return true;
        }
        if(!form.policyAck || !form.policyAlcfAck){
            return true;
        }
        return false;
    }
    function validateEmail(obj, email) {
        obj.valid = valMod.validEmailFormat(email);
    }

    // Contextual Messaging Info to display
    function domainGlobals(lists, flags){
        httpLegacy.get("public/domainGlobals", {params : {}})
            .success(function(resp){
                if(resp.success){
                    lists.domainGlobals = resp.domainGlobals;
                }
            });
    }

    function saveAndReturnLater(scope){
        scope.flags.newFavorDataNeeded = false;
        scope.updateAccountInfo();
        comMod.doWhenAPICallsDone(function(){
            comMod.signOut(null, scope, false);
        });
    }

    function submitReactivate(scope, beforeRedirect, callback){
        scope.verifyAccount();
        scope.updateAccountInfo(beforeRedirect, callback);
    }

    function reactivateRequest(scope, http, cookies, form, flags, alerts, callback){
        comMod.scrollToHasError(scope, function(){
            var c, modForm, emailNew;
            if(flags.errorStatus){ return;}
            
            form.accountEmails.forEach(function(dict){
                if(dict.primary){emailNew = dict.email;}
            });

            modForm = {
                // original values from GET
                firstName : flags.prevSavedForm.firstName,
                lastName : flags.prevSavedForm.lastName,
                email : scope.user.email, // this would also work:  form.accountEmails[0].email
                // 'if' they changed anything will be included here
                projectShortName : form.projectShortNameList,
                firstNameNew : form.firstName,
                lastNameNew : form.lastName,
                emailNew : emailNew
            }
            if(form.comment){
                modForm.comment = form.comment;
            }

            if(flags.newFavorDataNeeded === 'true' && !flags.favorComplete){
                alerts.push({
                    type : 'danger',
                    msg : "Unable to process the request. Please Enter Foreign National Info"
                });
                return;
            }

            var cdata = {form : modForm};
            flags.error = null;
            http.post(flags.apiType + "reactivationRequest", cdata)
                .then(function(response){
                    if(response.success || (response.data && response.data.success)){
                        // reactivation is good
                        // ok to submit any account changes user made to form(s)
                        localStorage.removeItem('anl-UB3-LLF');

                        flags.reactivateSubmitted = true;
                        delete form.projectShortNameList;
                        delete flags.fieldErrors;

                    } else{
                        flags.reactivateSubmitted = false;
                        alerts.push({
                            type : 'danger',
                            msg : response.error
                        });
                    }
                    if(callback) callback();

                }).catch(function(response, status){
                    if(response.status === 403){
                        alerts.push({
                            type : 'danger',
                            msg : 'Your session has expired. Please select "Cancel Reactivation Request", and try again.'
                        });
                    } else {
                        alerts.push({
                            type : 'danger',
                            msg : '404 not found'
                        });

                    }
                    if(callback) callback();
                });
        });
    }

    function saveAccountInfo(form, flags, lists, q, alerts, beforeRedirect, callback){
        var accepted, fn;

        form.accountEmails.forEach(function(dict){
            if(dict.hasOwnProperty('valid')){
                delete dict.valid;
            }
        });
        var accountUpdated = false;
        if (!beforeRedirect){
         if(flags.newFavorDataNeeded === 'true' && !flags.favorComplete){
            alerts.push({
                type : 'danger',
                msg : "Unable to process the request. Please Enter Foreign National Info"
            });
         }
        }
        //check what apis to call based on what changed
        var postMsg = '', putMsg = '';
        edrMod.apisToCall(flags, form);

        //keys with List
        if(flags.putsToCall.length > 0){
            //putMsg = 'Updated';
            edrMod.putRecord(http, form, flags, lists, cookies, function(response){
                // Callback To display success OR error messages to user on page.
                var resp = response.success;
                if(resp){
                    alerts.push({
                        type : 'success',
                        msg : 'Successfully Updated ' + flags.putsToCall
                    });

                } else{
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });
        }
        if(flags.postsToCall.length > 0){
            edrMod.postRecord(http, form, flags, lists, cookies, function(response){
                var resp = response.success;
                if(resp){
                    alerts.push({
                        type : 'success',
                        msg : 'Successfully Added ' + flags.postsToCall
                    });

                } else{
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });
        }
        // Done sending any KEYS with LIST
        // account not List so handled separate put function

        // we will always call account API on submit
        // 1. timestamp - validationDate
        // 2. Reset PRE-FAVOR after returning from FAVOR
        comMod.doWhenAPICallsDone(function(){
            if(flags.error) return;
            if(!flags.account){flags.account = {}}
            if(flags.account){
                flags.account.firstName = form.firstName;
                if(!flags.newFavorDataNeeded  || flags.newFavorDataNeeded ==='false'){
                    var rightNowUTC = dayjs.utc(); // get this moment in UTC based on browser
                    flags.account.validation_date = rightNowUTC.format('YYYY-MM-DD HH:mm:ss');
                }
                flags.account.id = form.id;
                if(flags.account.projectShortNameList){
                    // should never exist on this object OR included on account api form
                delete flags.account.projectShortNameList
                }

            }
            if(flags.account.id){
                accepted = [
                    'accountCategory',
                    'accountStatus',
                    'activationDate',
                    'cryptoKeyOption',
                    'deactivationDate',
                    'employment_level',
                    'firstName',
                    'id',
                    'lastName',
                    'middleName',
                    'nameTitle',
                    'nmi',
                    'orcid',
                    'policyAck',
                    'preferredName',
                    'scopusId',
                    'shell',
                    'usCitizen',
                    'userName',
                    'validation_date',
                ];

                for (fn in flags.account){
                    if (accepted.indexOf(fn) < 0){
                        delete flags.account[fn];
                    }
                }

                edrMod.putAccount(http, form, flags, lists, cookies, function(response){
                    // Callback To display success OR error messages to user on page.
                    var resp = response.success;
                    if(resp){
                        alerts.push({
                            type : 'success',
                            msg : 'Successfully Updated Account'
                        });
                        // auth/account PUT gets called everytime and called last in order, so call GET on its success
                        // No need check if other PUT/POST APIs were called..
                        // Note: this is different behavior from ADMIN APIs
                        if(!accountUpdated){
                            accountUpdated = true;
                            // if reactivateAccount, getData calls reactivate function and leaves account update view.
                            // else calls GETS refersh for account update and remains on same page
                            // there is no callback used  on main function updateAccountInfo ()
                            //callback();
                        }
                        delete flags.account;

                    } else{
                        alerts.push({
                            type : 'danger',
                            msg : 'Account ' + response.error
                        });
                        delete flags.account;

                    }

                });
            }

            comMod.doWhenAPICallsDone(function(){
                timeout(function(){
                    if (beforeRedirect){
                        return; // if user is getting redirected to FAVOR, there is no 
                        // point on reading and refreshing data right right now.
                    }

                    if(flags.reactivateAccount && accountUpdated){
                        // Submit Reactivation was Clicked
                        // Account Update POST/PUT was successful on backend OR you wouldn't be here
                        // Its safe to call reactivation request now
            
                        scope.alerts = [];
                        reactivateRequest(scope, http, cookies, scope.form, scope.flags, scope.alerts, function(){

                                if (callback){
                                    var i, a, allSaved = true;
                                    // find out if save/submit was 100% successfull
                                    for (i in alerts){
                                        a = alerts[i];
                                        if (a.type == 'danger'){
                                            allSaved = false;
                                        }
                                    }
                                    callback(allSaved);
                                }
                        });
                    }
                },1000);
            });
        });
    }

    function getData(http, form, flags, lists, name, q, alerts, scope, accountUpdated, beforeRedirect){

            // Just GET the DATA
            flags.fieldErrors = {};
            flags.arrayFieldErrors = {};
            flags.getApiNames = ['account', 'addresses', 'questions', 'emails', 'institutions', 'phones', 'projects', 'resources', 'unixgroups'];

            if(flags.accountUpdate){
                flags.apiType = 'auth/';
            } 
            if(flags.reactivateAccount){
                flags.apiType = 'limited/';
            }

            edrMod.getRecord(http, flags, lists, name, function(response){
                var resp = response.success;
                if(resp){
                    // if they checked policyAlcfAck, don't make them select again while on page.
                    if(form.policyAlcfAck){response.policyAlcfAck = form.policyAlcfAck;}
                    // policyAck should be unchecked when page first loads
                    if(!accountUpdated){response.policyAck = false}
                    // if UNKNOWN clear and require user to choose from list
                    if(response.employment_level ==='UNKNOWN'){
                        response.employment_level = '';
                    }
                    flags.prevSavedForm = {};
                    //angular.copy(response, form);
                    comMod.objCopyGuts(response, form);
                    //angular.copy(response, flags.prevSavedForm);
                    comMod.objCopyGuts(response, flags.prevSavedForm);

                    if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){
                        edrMod.validateAccountUpdate(form, flags, true);
                        comMod.validateListsAfterGet(flags, form);
                    }
                } else{
                    flags.bootstrapAlert = 'editRecordError';
                    alerts.push({
                        type : 'danger',
                        msg : response.error
                    });
                }
            });

    }


    // ----------- public stuff gets returned -----------
    return publicStuff;

})();

module.exports = {accReactModule}
