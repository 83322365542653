

<table id="top-api-function-names-list" class="ub3tableV">
    <thead><tr><th colspan=8>Other Screens - Service API - Direct Table Access</th></tr></thead>
    <tbody>
    <tr>
        <td *ngFor="let column of apiFunctionColumns" valign=top>
            <ng-container *ngFor="let row of column">
                <a  id="{{row.name}}" *ngIf="func !== row"
                    style="cursor: pointer"
                    (click)="clickFunction(row.path, row.formName, row); resp.response = null; ">
                  {{row.name}}
                </a>
                <span *ngIf="func === row">
                    <font color="#009900"><b>{{row.name}} </b></font>
                </span>
                <br>
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>
<br>



<ng-container *ngIf="func">
    <ng-container id="choose-method-name">
        <font class="svcName"><b>{{func.path}}</b></font>
        &nbsp; &nbsp; 
        <ng-container *ngFor="let m of func.methods">
            <a  *ngIf="!method || (m.name != method.name)"
                (click)="clickMethodSection(m, func.formName, func.path)"
                style="cursor: pointer"
                id="{{func.name}}-{{m.name}}" 
            >
                <font color=#0000ff>
                    {{m.crud}}
                </font>
                <span *ngIf="m.name && (m.name == 'PUT') && all[func.path] && all[func.path][m.name] && all[func.path][m.name].search">
                    <font color=#cccccc>{{all[func.path][m.name].search}}</font>
                </span>
            </a>
            <b *ngIf="method && (m.name == method.name)" id="{{func.name}}-{{m.name}}">
                [
                {{m.crud}}
                <span *ngIf="(m.name == 'PUT') && all[func.path][method.name].search">
                    <font color=#cccccc>{{all[func.path][method.name].search}}</font>
                </span>
                ]
            </b>
            &nbsp; &nbsp; 
        </ng-container>

    </ng-container>

    <ng-container id="get-search" *ngIf="method && (method.name == 'GET')"> 
      <div class="logs col-sm-12">
        <div>
            <multi-filter
                [adminvalidate] = "adminValidate"
                [addmore] = "addMore"
                [checksearchfields] = "checkSearchFields"
                [clearsearch] = "clearSearch"
                [closeduplmodal] = "closeDuplModal"
                [downloadsearchresults] = "downloadSearchResults"
                [dupmsg] = "flags.dupWarnMsg"
                [filterhelp] = "filterHelp"
                [filtervalues] = "lists.filterValues[func.path]"
                [filterlist] = "flags.filters[func.path]"
                [filterrows] = "lists.rows[func.path].all"
                [flags] = "flags"
                [form] = "form"
                [init] = "initMultiFilter"
                [lists] = "lists"
                [path] = "func.path"
                [removefilter] = "removeFilter"
                [rows2display] = "rows2display"
                [resp] = "resp"
                shownextresults = "showNextResults"
                showprevresults = "showPrevResults"
                [submitfilters] = "submitFilters"
                [searchresults] = "searchResults"
                updatesearch = "updateSearch"
                >
            </multi-filter>
        </div>
        <ng-container id="get-search-output"
            *ngIf="resp && resp.response && resp.response.showIt &&
                             lists && lists.svcOut && lists.svcOut[func.path]">
                <!-- This logic assumes the following:
                        1. Ordered json dictionary
                        2. All keys must be provided in every row
                        Note: Maybe in the future we will change this to handle a list of un-ordered dictionaries and missing keys. 
                --> 
            <span *ngIf="resp.response.data && (resp.response.data.length > 0)">
                <table id="rowInfoTable" class="clear filterNav">
                    <tr *ngIf="!flags.getSearchHelp && lists.filterRows[func.path].length > 0" height="35px">
                        <td *ngIf="lists.filterRows[func.path].length > 0">Total Rows:
                            <span id="totalRows">{{lists.filterRows[func.path].length}}</span>
                        </td>
                        <td>
                            Number of Rows to display:
                            <input id="rowsPerPage" class="displayRows" type="number" [(ngModel)]='rows2display'/>
                        </td>
                        <td>
                            <button
                                class="btn btn-danger btn-sm"
                                id="clearSearchResults"
                                (click)="lists.svcOut[func.path]=null">
                                Clear Search Results
                            </button>
                        </td>
                        <td class="pl20">
                            <span *ngIf="(method.name == 'GET') && (resp.response.showIt) && (resp.response.data.length > 0)">
                                <input type=checkbox [(ngModel)]="flags.abbreviate"><span class="ml5">Abbreviate long field values</span>
                            </span>
                        </td>
                        <td>
                            <a (click)="colDispSettings=!colDispSettings" style="cursor:pointer" id="selCols">
                                <i class="fas fa-cog"></i>
                                {{colDispCount}} of {{colCount}} columns displayed
                            </a>
                        </td>
                    </tr>
                </table>
                <div *ngIf="colDispSettings">
                    <table id="column-selection"class="ub3tableV">
                        <thead><tr><th colspan=8>
                            Select which columns to display 
                            <img width=80 border=0>
                            <a (click)="colDispChange(func.path, 'check-all')"     style="cursor:pointer" id=  "selectAll">Select all</a> / 
                            <a (click)="colDispChange(func.path, 'un-check-all')"  style="cursor:pointer" id="unSelectAll">unselect all</a>
                        </th></tr></thead>
                        <tbody>
                        <tr>
                            <td *ngFor="let col of colsToDisplay" valign=top nowrap>
                                <ng-container *ngFor="let key of col">
                                    <ng-container *ngIf="key != 'htmlId'">
                                        <input type=checkbox class="dispColCheckbox"
                                            id="{{key}}"
                                            [(ngModel)]="lists.rows[func.path].disp[key]"
                                            (change)="colDispChange(func.path, key)"
                                        >
                                        {{key}}<br>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </span>
            <br>
            <div *ngIf="lists.rows[func.path].all.length > 0">
                <ngx-datatable
                    id="searchResultTable"
                    class="bootstrap card expandable"
                    [limit]="rows2display"
                    columnMode="flex"
                    [rowHeight]="auto"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [scrollbarH]="true"
                    [draggable]="false"
                    [rows]="lists.rows[func.path].all">

                    <ngx-datatable-column name="id" [resizeable]="false" [draggable]="false" [flexGrow]="colDispCount > 21 ? colDispCount / 21 : 0.5">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <a  id="{{row.htmlId}}"
                            *ngIf="func.canEdit"
                            style="text-decoration:none; cursor:pointer;"
                            (click)="svcInitPutForm2(func.path, func.formName, row, func.methods, 'PUT')">
                            <img height="14px" width="14px" src="/frontend/assets/pen-solid.svg" id="pen-{{value}}">
                            <span style="margin-left: 10px" id="id-{{row.id}}">{{row.id}}</span>
                        </a>
                        <span *ngIf="!func.canEdit">{{row.id}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ng-container *ngFor="let f of lists.rows[func.path].all[0] | keyvalue; index as i">
                        <!-- the rest of the columns -->
                        <ngx-datatable-column 
                            [flexGrow]=".75"
                            [draggable]="false"
                            [resizeable]="false"
                            name="{{f.key}}"
                            prop="{{f.key}}"
                            *ngIf="(f.key != 'id') && (f.key != 'htmlId')">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <span [ngClass]="{'abbr': flags.abbreviate,'expandAll': !flags.abbreviate}">{{value}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
            <div *ngIf="lists.rows[func.path].all.length == 0">
                There are no results that match with the search criteria
            </div>
            <br>
        </ng-container>
      </div>
    </ng-container>

    <ng-container id="put-post-create-update"
            *ngIf="method && ((method.name == 'POST') || (method.name =='PUT'))"> 
        <br>
        <table id="editing-form"
               class="ub3tableH"
               border=0 
               *ngIf="flags.openSection[func.path][method.name]">

            <thead>
            <tr id="editing-form-header" 
                *ngIf="method.params.indexOf('form') >= 0" 
                [ngClass]="svc1">

                <th [ngClass]="svc1" nowrap> 
                    <span *ngIf="flags.dfn">
                        Field name /
                        <a (click)="flags.dfn = false;" style="text-decoration: none; cursor:pointer">
                            <font color="#7777ff">Label</font> 
                        </a>
                    </span>
                    <span *ngIf="!flags.dfn">
                        Label /
                        <a (click)="flags.dfn = true;" style="text-decoration: none; cursor:pointer">
                            <font color="#7777ff">Field name</font> 
                        </a>
                    </span>
                </th>
                <th style="text-align: right;">
                    <a (click)="flags.ral = false" style="text-decoration: none; cursor: pointer"> 
                        <img width="14px" src="/frontend/assets/align-left-solid.svg">
                    </a>
                    <img width="5px">
                    <a (click)="flags.ral = true"  style="text-decoration: none; cursor: pointer"> 
                        <img width="14px" src="/frontend/assets/align-right-solid.svg">
                    </a>
                </th>
                <th></th>
                <th>send</th>
                <th>null</th>
                <th>blank</th>
                <th></th>
                <th nowrap>
                    <a *ngIf="!flags.sideJson" 
                       (click)="toggleSideJson(func.path, method.name, func.formName)" 
                       style="text-decoration: none; cursor: pointer"> 
                        <img width="14px" src="/frontend/assets/expand-alt-solid.svg" id="jsonButton">
                        json
                    </a>
                    <a *ngIf=" flags.sideJson" 
                       (click)="toggleSideJson(func.path, method.name, func.formName)" 
                       style="text-decoration: pointer; cursor: pointer"> 
                        <img width="14px" src="/frontend/assets/compress-alt-solid.svg">
                        json
                    </a>
                </th>
            </tr>
            </thead>
            <tbody>
            <ng-container id="editing-form-fields"
                *ngFor="let field of v9rules[func.formName]; let i = index">

            <tr [ngClass]="field.rules.update_name ? 'svc2' : 'svc3'"
                *ngIf="(method.params.indexOf('form') >= 0) && (field.rules.search_name!='email_list__email') && (field.rules.display || (method.name == 'POST'))">

                <td *ngIf="flags.ral" 
                    colspan=2 
                    valign=top 
                    style="text-align: right" 
                    id='L-{{flags.dfn ? field.name : field.rules.label}}'>
                    {{flags.dfn ? field.name : field.rules.label}} 
                </td>
                <td *ngIf="!flags.ral" 
                    colspan=2 
                    valign=top 
                    style="text-align: left" id='L-{{flags.dfn ? field.name : field.rules.label}}'>
                    {{flags.dfn ? field.name : field.rules.label}}
                </td>
                <td valign="top">
                    <input
                        id="{{field.name}}"
                        *ngIf="(field.rules.type == 'str') || (field.rules.type == 'email') "
                        [(ngModel)]="all[func.path][method.name].form[field.name]"
                        [disabled]="(method.name == 'PUT') && field.rules.disabled"
                        size="60"
                        type="text"
                        (keyup)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)">
                    <ng-container *ngIf="jsonObjs[field.name] || (jsonObjs[field.name] == '')">
                        <json-editor [options]="mkJsonOptions()" 
                                     [data]="jsonObjs[field.name]"
                                     (change)="getData($event, func.path, method.name, field.name)"></json-editor>
                    </ng-container>

                    <div *ngIf="(field.rules.type == 'date')">
                        <span class="input-group">
                            <ub3date 
                                [form]="all[func.path][method.name].form" 
                                [key]="field.name"
                                [trigger]="all[func.path][method.name].form[field.name]"
                                (onChange)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name, null, $event)"
                                >
                            </ub3date>
                        </span>
                    </div>
                    <input 
                        id="{{field.name}}"
                        *ngIf="field.rules.type == 'int'"
                        [(ngModel)]="all[func.path][method.name].form[field.name]"
                        [disabled]="(method.name == 'PUT') && field.rules.disabled"
                        size="60"
                        type="text"
                        (keyup)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)"
                    >
                    <input
                        id="{{field.name}}"
                        *ngIf="field.rules.type == 'float'"
                        [(ngModel)]="all[func.path][method.name].form[field.name]"
                        [disabled]="(method.name == 'PUT') && field.rules.disabled"
                        size="60"
                        type="text"
                        (keyup)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)"
                    >

                    <span *ngIf="field.rules.type == 'bool'">
                        <input
                            type="checkbox"
                            [hidden]="true"
                            id="{{field.name}}"
                            *ngIf="field.rules.type == 'bool'"
                            [(ngModel)]="all[func.path][method.name].form[field.name]"
                            [disabled]="((method.name == 'PUT') && field.rules.disabled)"
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)"
                        > 
                        <input
                            type="radio"
                            name="{{'yesno_' + field.name }}"
                            id=  "{{'yes_' + field.name }}"
                            *ngIf="field.rules.type == 'bool'"
                            [(ngModel)]="yesno[func.path][method.name].form[field.name]"
                            value="yes"
                            [disabled]="((method.name=='PUT') && field.rules.disabled)"
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], 'yesno_' + field.name)"
                        > Yes
                        <input
                            type="radio"
                            name="{{'yesno_' + field.name }}"
                            id=  "{{'no_' + field.name }}"
                            *ngIf="field.rules.type == 'bool'"
                            [(ngModel)]="yesno[func.path][method.name].form[field.name]"
                            value="no"
                            [disabled]="((method.name == 'PUT') && field.rules.disabled)"
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], 'yesno_' + field.name)"
                        > No
                    </span>

                    <select 
                        id="{{field.name}}"
                        *ngIf="field.rules.type == 'enum'"
                        [(ngModel)]="all[func.path][method.name].form[field.name]"
                        [disabled]="(method.name == 'PUT') && field.rules.disabled"
                        (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)"
                    >
                        <option value="">- None -</option>
                        <option *ngFor="let enumStr of field.rules.enum" value="{{enumStr}}">
                            {{enumStr}}
                        </option>
                    </select>
                    <textarea 
                        id="{{field.name}}"
                        class="trueFont"
                        *ngIf="field.rules.type == 'textArea'"
                        [(ngModel)]="all[func.path][method.name].form[field.name]"
                        (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name)"
                        [disabled]="(method.name == 'PUT') && field.rules.disabled"
                        rows="8" cols="110"
                        wrap="off"
                    >
                        {{all[func.path][method.name].form[field.name]}}
                    </textarea>
                </td>
                <td valign=top>
                        <input type=checkbox 
                            [(ngModel)] ="flags.send[func.path][method.name][field.name]" 
                            id="{{field.name}}_sendCheckbox"
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name, 'send')">
                </td>
                <td valign=top>
                        <input type=checkbox 
                            [(ngModel)] ="flags.isNull[func.path][method.name][field.name]"
                            id="{{field.name}}_nullCheckBox" 
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name, 'null')">
                </td>
                <td valign=top>
                        <input type=checkbox 
                            [(ngModel)] ="flags.isBlank[func.path][method.name][field.name]" 
                            id="{{field.name}}_blankCheckBox"
                            (change)="svcValidate(func.path, method.name, func.formName, all[func.path][method.name], field.name, 'blank')">
                </td>
                
                <td valign=top>
                    <warning-svc path="{{func.path}}" method="{{method.name}}" fid="{{field.name}}">
                    </warning-svc>
                </td>
                <td *ngIf="(i == 1) && flags.sideJson" 
                    [attr.rowspan]="v9rules[func.formName].length" 
                    valign=top>
                    <ngx-json-viewer 
                        *ngIf="aCopy && aCopy[func.path] && aCopy[func.path][method.name]" 
                        [(json)]="aCopy[func.path][method.name]" 
                        [expanded]="true">
                    </ngx-json-viewer>
                </td>
            </tr>
            </ng-container>
            </tbody>

            <thead>
            <tr id="editing-form-footer">
                <th colspan=3></th>
                <th>send</th>
                <th>null</th>
                <th>blank</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
        </table>
        <span *ngIf="((method.name == 'PUT') || (method.name == 'POST')) && func.path.indexOf('.py') < 0">
            <button 
                [disabled]="!enableFormSubmitButton"
                class="btn btn-success btn-sm"
                (click)="svcRequest(method.name, func.path, all[func.path][method.name], resp)" 
                id="{{method.name}}{{func.func}}">
                {{method.crud}} {{func.path}}
            </button>
        </span>
    </ng-container>
    <br>
    <br>
    <ng-container id="all-requests-json-output" *ngIf="resp">
          <div *ngIf="resp.response">
            Response: <ngx-json-viewer [json]="resp.response" [expanded]="false" id="resp1"> </ngx-json-viewer>
            <div style="display:none">
              lists.rows: <ngx-json-viewer [json]="lists.rows" [expanded]="false" id="rows"> </ngx-json-viewer>
              lists.svcOut: <ngx-json-viewer [json]="lists.svcOut" [expanded]="false" id="rows"> </ngx-json-viewer>
            </div>
            <div *ngIf="resp.response.success">
              success: <span id="9success">{{resp.response.success}}</span>
            </div>
          </div>
          <br>
          <div *ngIf="resp.error">
              <span valign=top nowrap>errors:</span>
              <span style="color:red;" id="errorMsg">
                  {{resp.error}}
              </span>
          </div>
          <br><br>
    </ng-container>

</ng-container>

<span id="function-specification-details">
    <a  *ngIf="!flags.specJson" 
        (click)="flags.specJson=true" 
        style="text-decoration: none; cursor:pointer;"> 
    <font color="#aaaaaa">
        <img width="14px" src="/frontend/assets/expand-alt-solid.svg" id="fSpecBtn">
        Developer information
        - Information about internal javascript structures usefull for debugging for frontend developers.</font>
    </a>

    <a  *ngIf=" flags.specJson" 
        (click)="flags.specJson=false;" 
        style="text-decoration: none; cursor:pointer"> 
      <img width="14px" src="/frontend/assets/compress-alt-solid.svg">
      <b>Collapse developer information</b>
    </a>
    <ngx-json-viewer *ngIf="flags.specJson" [json]="func" [expanded]="false">
    </ngx-json-viewer>

    <ng-container *ngIf="flags.specJson">
        <table *ngFor="let form_name of vRules | keyvalue">
            <tr><td><b>{{form_name.key}}</b></td></tr>
            <tr *ngFor="let field_name of form_name.value | keyvalue">
                <td>{{field_name.key}}</td>
                <td *ngFor="let rule_name of field_name.value | keyvalue">
                    {{rule_name.key}} : {{rule_name.value}}
                </td>
            </tr>
        </table>

        <ng-container *ngIf="func && func.path">
            {{func.name}} DB  Info: 
            <ngx-json-viewer [json]="lists.dbInfo[func.path]" [expanded]="false">
            </ngx-json-viewer>
        </ng-container>

        <ng-container *ngIf="lists">
            lists: 
                <ngx-json-viewer [json]="lists" [expanded]="false">
                </ngx-json-viewer>
        </ng-container>

        <ng-container *ngIf="resp">
            resp:
                <ngx-json-viewer [json]="resp" [expanded]="false">
                </ngx-json-viewer>
        </ng-container>
    </ng-container>

</span>

