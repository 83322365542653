var manageProjectModule =
    (function(){
        "use strict";
        var publicStuff;
        var cookies, http, httpNew, scope, timeout, win, comMod, projAdmn, valMod, edRecMod;
        cookies = http = httpNew = scope = timeout = win = comMod = projAdmn = valMod = edRecMod = null;  // cached services
        var jsServices = {};
        var lastSavedProjectAccounts = null;
        var alertError = {type : 'danger'};
        var alertSuccess = {type : 'success'};
        var piName;
        publicStuff = {
            init : init,
            checkIfProjExists : checkIfProjExists,
            setProjectDetails : setProjectDetails,
            getProjectDetails : getProjectDetails,
            addUser : addUser,
            checkProxyChanges : checkProxyChanges,
            validateProjectManagment : validateProjectManagment,
            updatePiProxies : updatePiProxies,
            cancelProxyChanges: cancelProxyChanges,
            deleteUser : deleteUser,
            approveUser : approveUser,
            rejectUser : rejectUser,
            editProjectDetails : editProjectDetails,
            getNewProjInstList : getNewProjInstList,
            setServices: setServices
        };

        function setServices(services){
            var i, serv;
            for (i in services){
              serv = services[i];
              jsServices[serv.sname] = serv;
            }
            cookies  = jsServices.cookies;
            http     = jsServices.http;
            httpNew  = jsServices.httpNew;
            scope    = jsServices.scope;
            timeout  = jsServices.timeoutObj.timeout;
            win      = jsServices.window;
            comMod   = jsServices.commonModule;
            projAdmn = jsServices.projectAdminModule;
            valMod   = jsServices.validationModule;
            edRecMod = jsServices.editRecordModule;
        }
        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user
        function init(scope, http, httpNew, lists, flags, pageName, cb){
            scope.publicPage(true);
            flags.prevSavedForm = {};
            flags.filterStr = '';
            flags.okToSubmit = false;
            flags.displayedPageName = pageName;
            scope.form = {};
            if(!scope.lists){
                scope.lists = lists = {};
            }
            comMod.getProxyProjects(http, lists);

            comMod.getUsersNotSystemCreated(http, lists, flags);

            if(!scope.lists.institutions){
                comMod.getInstitutionsList(http, lists);
            }

            if(scope.authAlerts){
                scope.authAlerts = [];
            }
            comMod.setUser(scope, function(){
              piName = scope.user.userName;
            });
            comMod.doWhenAPICallsDone(function(){
                if(cb) cb(lists);
            });
        }

        function setProjectDetails(pjName, newOrEdit, http, form, flags, lists, cookies, callback){
            flags.newProject = (newOrEdit == 'new');
            flags.editProject = (newOrEdit == 'edit');
            flags.submitError = null;
            lists.matchedUsers = [];
            lists.projectAllocations = [];
            if(newOrEdit == 'edit'){
                getproxyProject(function(){
                    getProjAllocations(pjName, function(allocn){
                        lists.projectAllocations = [...allocn];
                    });
                    comMod.doWhenAPICallsDone(function(){
                        if(callback) callback();
                    });
                });
            } else{
                comMod.objCopyGuts({projectShortName : pjName}, form);
                form.status = 'active';
                validate(form, flags);
                if(callback) callback();
            }

            function getproxyProject(cb){
                getProjectDetails(http, form.projectShortName, flags, form, lists, cookies);
                comMod.doWhenAPICallsDone(function(){
                    getProxyDetails(http, form.projectShortName, flags, lists, cookies);
                    comMod.doWhenAPICallsDone(function(){
                        if(cb) cb();
                    });
                });
            }
        }

        function getProjectDetails(httpNew, projectShortName, flags, form, lists, cookies){
            var url;

            var p = {projectShortName : projectShortName};
            httpNew.get('/proxy/projectDetails', {params : p})
                .then(function(resp){
                    var f = (resp.data) ? resp.data : resp;
                    if(f){
                        flags.prevSavedForm = comMod.objCopy(f);
                        Object.assign(form, f);
                    }
                }).catch(function(response, status){
                lists.error = response.data.detail;
            });
        }

        function getProxyDetails(http, projectShortName, flags, lists, cookies){
            var url;

            lists.pendingStatus = false;
            lists.accountInactivateStatus = false;
            var p = {projectShortName : projectShortName};
            http.get('/proxy/projectAccounts', {params : p})
                .success(function(resp){
                    if(resp.success){
                        lists.projectAccounts = resp.projectAccounts;
                        lists.pendingStatus = lists.projectAccounts.some(function(item){
                            return item.memberStatus === "Approved-pending MUA/Ack"
                        });
                        lists.accountInactivateStatus = lists.projectAccounts.some(function(item){
                            return item.accountStatus !== "Active"
                        });
                        // this next call is so that the projectAdmin page displays
                        // correct data
                        projAdmn.mkMemberStatusTransitionRules(
                            lists.projectAccounts, lists);

                        lastSavedProjectAccounts = comMod.objCopyArray(lists.projectAccounts);
                        flags.pjaDelta = false;
                        // this next dictionary is usefull for fast indexing
                        lists.pjaIndex = {};
                        for(var i in lists.projectAccounts){
                            var pa = lists.projectAccounts[i];
                            lists.pjaIndex[pa.userName] = pa;
                        }
                        validatePI(flags, lists);
                    } else{
                        scope.authAlerts && scope.authAlerts.push({type: 'danger', msg: resp.error});
                        removeProjectAccounts(flags, lists);
                    }
                })
                .error(function(data, status, headers, config){
                    lists.error = resp.data.detail;
                    removeProjectAccounts(flags, lists);
                });

        }
        function removeProjectAccounts(flags, lists) {
            lists.projectAccounts = null;
            flags.okToSubmit = false;
            flags.pjaOkToSave = false;
            setTimeout(function(){
                scope.authAlerts = [];
                window.location.assign("#/manageProjects");
            }, 1000);
        }

        function getProjAllocations(pjName, callback){
            var p = {projectShortName : pjName};
            httpNew.get('/auth/projectAllocations', {params : p})
                .then(function(resp){
                    var f = (resp.data) ? resp.data : resp;
                    if(f){
                        callback(f.projectAllocations);
                    }
                }).catch(function(response, status){
                    lists.error = response.data.detail;
                });
        }

        function checkIfProjExists(http, uid, cookies, alerts, callback){
            var p = {unixgroup_id : uid};
            http.get('/auth/unixgroupHasProject', {params : p})
                .success(function(resp){
                    if(resp.success){
                        return callback(resp.unixgroupHasProject);
                    } else {
                        alertError.msg = resp.error;
                        alerts && alerts.push(alertError);
                    }
                })
                .error(function(data, status, headers, config){
                    alertError.msg = data.data.detail;
                    alerts && alerts.push(alertError);
                });

        }
        function addUser(pjName, row, http, cookies, flags, lists, alerts){

            var cdata = {
                projectShortName : pjName, userName : row.userName
            };

            http.post("/proxy/manageProjects", cdata)
                .success(function(hresp){
                    if(hresp.success){
                        var dispName = row.firstName + ' ' + row.lastName;
                        alertSuccess.msg = dispName+' has been added to '+pjName;
                        alerts && alerts.push(alertSuccess);
                        getProxyDetails(http, pjName, flags, lists, cookies);
                    } else{
                        flags.submitError = hresp.error;
                        alertError.msg = flags.submitError;
                        alerts && alerts.push(alertError);
                    }
                })
                .error(function(data){
                    lists.error = data.data.detail;
                    alertError.msg = lists.error;
                    alerts && alerts.push(alertError);
                });
        }

        function deleteUser(pjName, http, cookies, lists, flags, pa, alerts){

            var cdata = {
                id : pa.id,
                projectShortName : pjName,
                projectProxy : pa.projectProxy,
                memberStatus : 'Deleted',

            };

            http.put("/proxy/manageProjects", cdata)
                .success(function(hresp){
                    if(hresp.success){
                        alertSuccess.msg = 'Successfully Removed '+pa.userName+' from Group '+pjName;
                        alerts && alerts.push(alertSuccess);
                        if(pa.userName === piName){
                          removeProjectAccounts(flags, lists);
                        } else {
                          getProxyDetails(http, pjName, flags, lists, cookies);
                        }
                    } else{
                        flags.submitError = hresp.error;
                        alertError.msg = flags.submitError;
                        alerts && alerts.push(alertError);
                    }
                })
                .error(function(data){
                    lists.error = data.data.detail;
                    alertError.msg = lists.error;
                    alerts && alerts.push(alertError);
                });

        }

        function approveUser(pjName, http, cookies, lists, flags, obj, alerts){

            var cdata = {
                id : obj.id,
                projectShortName : pjName,
                projectProxy : obj.projectProxy,
                memberStatus : 'Approved',

            };
            http.put("/proxy/manageProjects", cdata)
                .success(function(hresp){
                    if(hresp.success){
                        getProxyDetails(http, pjName, flags, lists, cookies);
                    } else{
                        flags.submitError = hresp.error;
                        alertError.msg = flags.submitError;
                        alerts && alerts.push(alertError);
                    }
                })
                .error(function(data){
                    lists.error = data.data.detail;
                    alertError.msg = lists.error;
                    alerts && alerts.push(alertError);
                });
        }

        function rejectUser(pjName, http, cookies, lists, flags, obj, alerts){

            var cdata = {
                id : obj.id,
                projectShortName : pjName,
                projectProxy : obj.projectProxy,
                memberStatus : 'Rejected',

            };

            http.put("/proxy/manageProjects", cdata)
                .success(function(hresp){
                    if(hresp.success){
                        getProxyDetails(http, pjName, flags, lists, cookies);
                    } else{
                        flags.submitError = hresp.error;
                        alertError.msg = flags.submitError;
                        alerts && alerts.push(alertError);
                    }
                })
                .error(function(data){
                    lists.error = data.data.detail;
                    alertError.msg = lists.error;
                    alerts && alerts.push(alertError);
                });
        }

        function updatePiProxies(pjName, http, cookies, lists, flags, alerts){

            var a, b, i, diff, todo;
            //checkBoxesToPja(lists.projectAccounts);
            todo = 0;
            for(i in lists.projectAccounts){
                a = lists.projectAccounts[i];
                b = lastSavedProjectAccounts[i];
                diff = a.id && !comMod.isEqual(a, b);
                if(diff){
                    flags.saving = true;
                    todo++;
                    if(a.userName === piName) var hasPiProxyChanged = true;
                    updatePiProxy(pjName, a, http, cookies, flags, alerts, function(){
                        todo--;
                        if(todo == 0){
                            flags.saving = false;
                            if(hasPiProxyChanged){
                              removeProjectAccounts(flags, lists);
                            } else {
                              getProxyDetails(http, pjName, flags, lists, cookies);
                            }
                        }
                    });

                }
            }

            function updatePiProxy(pjName, pjAccount, http, cookies, flags, alerts, callback){

                var cdata = {
                    id : pjAccount.id,
                    projectShortName : pjName,
                    projectProxy : pjAccount.projectProxy,
                    memberStatus : pjAccount.memberStatus,

                };

                http.put("/proxy/manageProjects", cdata)
                    .success(function(hresp){
                        if(hresp.success){
                            alertSuccess.msg = 'Saved Changes';
                            alerts && alerts.push(alertSuccess);
                            callback();

                        } else{
                            alertError.msg = hresp.error;
                            alerts && alerts.push(alertError);
                            callback();
                        }
                    })
                    .error(function(data){
                        lists.error = data.data.detail;
                        alertError.msg = lists.error;
                        alerts && alerts.push(alertError);
                        callback();
                    });
            }
        }
        function cancelProxyChanges(pjName, http, cookies, lists, flags) {
          getProxyDetails(http, pjName, flags, lists, cookies);
        }
        function checkProxyChanges(flags, lists){
            comMod.onStopCalling(300, function(){
                checkProxyChangesNow(flags, lists);
            });
        }

        function checkProxyChangesNow(flags, lists){
            if(!lists) return;
            if(!lists.projectAccounts) return;
            var diff = false;
            flags.paError = null;
            var curr = comMod.sortArrayOnKey(lists.projectAccounts,    'id', false);
            var prev = comMod.sortArrayOnKey(lastSavedProjectAccounts, 'id', false);
            for(var i in curr){
                var pa = curr[i];
                if (pa.id){
                    diff = (prev[i].projectProxy !== pa.projectProxy);
                    if(diff) break;
                }
            }
            flags.pjaOkToSave = diff;
        }

        function validatePI(flags, lists){
            if(!lists) return;
            if(!lists.projectAccounts) return;

            var a, b, i, curr, prev;
            var diff = false;
            flags.paError = null;

            curr = comMod.sortArrayOnKey(lists.projectAccounts,    'id', false);
            prev = comMod.sortArrayOnKey(lastSavedProjectAccounts, 'id', false);

            for(i in curr){
                a = curr[i] ;
                if (a.id){
                    b = prev[i] ; 
                    diff = !comMod.isEqual(a, b);
                    if(diff){
                        break;
                    }
                }
            }
            flags.pjaOkToSave = diff;
        }

        function validateProjectManagment(form, flags){
            flags.fieldErrors = {};
            valMod.validate('manageProjects', form, flags.fieldErrors);
            flags.missing = valMod.computeMissing(flags.fieldErrors);
            if(flags.editProject && flags.missing === "Required: Employer, "){
                delete flags.missing;
            }
            flags.okToSubmit = !flags.missing;
            if(!form.projectTitle) {return;}
            {comMod.checkIfDataChanged(form, flags);}
        }

        // get the latest project institution list
        function getNewProjInstList(form, flags){
            var p = {projectShortName : form.projectShortName};
            httpNew.get('/proxy/projectDetails', {params : p})
                .then(function(resp){
                    form.projectInstitutions = resp.data.projectInstitutions;
                    // angular.copy(form, flags.prevSavedForm);
                    comMod.objCopyGuts(form, flags.prevSavedForm);
                }).catch(function(response, status){

                }).finally(function(){
            });
        }

        function editProjectDetails(httpNew, form, flags, lists, cookies, alerts, action){
            projectEdit(httpNew, form, flags, lists, cookies, alerts, action, function() {
                form.projectInstitutions = form.projectInstitutions.filter(function (item) {
                    return item.affiliation;
                });
                // This fixes institution issues on project details page (#2440)
                getNewProjInstList(form, flags);
            });
        }
        function projectEdit(httpNew, form, flags, lists, cookies, alerts, action, callback){
            if(action === 'cancel'){
                // angular.copy(flags.prevSavedForm, form);
                comMod.objCopyGuts(flags.prevSavedForm, form);
                flags.okToSubmit = false;
                return;
            }
            comMod.scrollToHasError(scope, function(){
                if(flags.errorStatus){ return;}
                var dictForPut = {}
                comMod.objCopyGuts(form, flags.finalSavedForm);
                flags.dataThatChanged = edRecMod.getFormChanges(flags.finalSavedForm, flags.prevSavedForm);
                if(flags.dataThatChanged){
                    for(var key in flags.dataThatChanged){
                        if(!Array.isArray(form[key])){
                            dictForPut[key] = form[key];
                        }
                    }


                   if(Object.getOwnPropertyNames(dictForPut).length !== 0){
                        var apiName = "proxy/projectDetails";
                        httpNew.put(apiName, {projectShortName : form.projectShortName, form : dictForPut})
                            .then(function(response){
                                var resp = response.data;
                                if(resp.success){
                                    alerts.push({
                                        msg : 'Successfully Updated ' + form.projectShortName,
                                        type : 'success'
                                    });
                                    flags.okToSubmit = false;
                                    flags.changedProjectDetails = true;
                                } else{
                                    alerts.push({
                                        msg : resp.error,
                                        type : 'danger'
                                    });
                                }
                            }).catch(function(response, status){
                                alerts.push({
                                    msg : response.data.detail,
                                    type : 'danger'
                                });
                        });
                    }

                }

                if(flags.dataThatChanged.projectInstitutions){
                    // ensure there are no 'null' values
                    var apiName = "proxy/projectInstitutions";
                    var array = flags.dataThatChanged.projectInstitutions.filter(Boolean);
                    var arrayForPut = [];
                    var itemsProcessed = 0;

                    array.forEach(function(dict){
                        itemsProcessed++;
                        if(dict.method){
                            // we will send this as a POST
                            // remove ui specific junk we don't need to send
                            delete dict.method;
                            delete dict.id;
                            if(!dict.affiliation){
                                flags.okToSubmit = false;
                            } else {
                                httpNew.post(apiName, {projectShortName : form.projectShortName, form : dict})
                                    .then(function(response){
                                        var resp = response.data;
                                        if(resp.success){
                                            alerts.push({
                                                msg : 'Successfully Added ' + dict.affiliation,
                                                type : 'success'
                                            });
                                            flags.okToSubmit = false;
                                            flags.changedProjectDetails = true;
                                        } else{
                                            alerts.push({
                                                msg : resp.error,
                                                type : 'danger'
                                            });
                                        }
                                    }).catch(function(response, status){
                                        alerts.push({
                                            msg : response.data.detail,
                                            type : 'danger'
                                        });
                                });
                            }
                        } else{
                            arrayForPut.push(dict);
                        }

                        // make sure loop has finished before calling put API

                        if(itemsProcessed === array.length && arrayForPut.length){
                            httpNew.put(apiName, {projectShortName : form.projectShortName, form : arrayForPut})
                                .then(function(response){
                                    var resp = response.data;
                                    if(resp.success){
                                        var instArr = [];
                                        var instns = '';
                                        arrayForPut.forEach(function(item){
                                            var i = form.projectInstitutions.findIndex(function(inst){
                                                return inst.id === item.id;
                                            });
                                            instArr.push(form.projectInstitutions[i].affiliation);
                                        });
                                        instns = comMod.strArrWDelimiters(instArr, ',', '&');
                                        alerts.push({
                                            msg : 'Successfully Updated ' + instns,
                                            type : 'success'
                                        });
                                        flags.okToSubmit = false;
                                        flags.changedProjectDetails = true;
                                    } else{
                                        alerts.push({
                                            msg : resp.error,
                                            type : 'danger'
                                        });
                                    }
                                }).catch(function(response, status){
                                    alerts.push({
                                        msg : response.data.detail,
                                        type : 'danger'
                                    });
                            });
                        }
                    });
                }
            });
            comMod.doWhenAPICallsDone(function(){
                if(callback) callback();
            });

        }


        // ----------- public stuff gets returned -----------
        return publicStuff;

    })();
module.exports = {manageProjectModule}
