
import { Component, OnInit, Input } from '@angular/core';
import { accountRequestModule } from 'accountRequestModule';
import { allocRequestModule } from 'allocRequestModule';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import {limLoginModule} from './limLoginModule';

var t = null;
var scope = null;
var clog = null;

// This module will attempt to do away with all the insanity of states and variations 
// of the limited login steps. 
// These steps apply to Alloc Req, Accnt Req, Accnt React, dataSurvey
// This is the master state diagram: for local variable: stage
//
//  1   user needs to enter email, first name, last name
//  2   code to user's email has been sent, user needs to enter code
//  3   email code has been typed by user, and verified by backend
//  4   User needs to create security question and answer
//  4.5 User has sent Q & A to backend
//  5   User is challenged with Q and needs to enter A
//  6   All done with this module
//
// Note: for alloc Req: steps 4, 4.5, 5 are not used


@Component({
  selector: 'limited-login',
  templateUrl: './limited-login.component.html',
  styleUrls: ['./limited-login.component.scss']
})
export class LimitedLoginComponent implements OnInit {

  @Input() flags: any;
  @Input() user : any;
  @Input() after : any;

  fieldErrors = null;
  domainGlobals = null;
  lists = {};
  state = null;
  stage = 1;
  LLF = {
    nmi: false,
    firstName: '',
    lastName: ''
  };

  constructor(
      private commonService: CommonService, 
      private http: Ub3httpService,
      private cookies: CookieService) { 
    
    t = scope = this;
    clog = t.commonService.ub3consoleLog;
    t.fieldErrors = t.commonService.getSharedObject('warning', 'fieldErrors');
    t.state       = t.commonService.getSharedObject('app', 'state');
    t.lists.domainGlobals = t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');

    // don't allow remains of previous signed in states. Only allow limited login state to persist
    if (t.state && t.state.user && (t.state.user.signedIn == true) && (t.state.user.role != 'limited')){
        t.commonService.signOut(null, t.state, true);
    }
  }



  // this function is called on initial page load, this includes reload/refresh
  ngOnInit(): void {
      limLoginModule.pageLoadInit(t, t.commonService, t.http);
  }

  validateLL(form, fieldName?: string) {
    t.flags.wrongAnswer = null;
    if (fieldName && (fieldName == 'nmi')){
        if (form.nmi){
            form.middleName = '';
        }
    }

    if (t.flags.reactivateAccount || t.flags.isReactivateSurvey){
      t.commonService.validateLL(form, t.flags, setTimeout, function(){
          t.commonService.doWhenAPICallsDone(function(){
            t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
          });
      });
      return;
    }

    accountRequestModule.validateLL(form, t.flags, setTimeout, function(){
      t.commonService.doWhenAPICallsDone(function(){
        t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      });
    });
  };
  validateLLNow(form) {
    t.flags.wrongAnswer = null;

    if (form.nmi){
            form.middleName = '';
    }

    if (t.flags.reactivateAccount || t.flags.isReactivateSurvey){
      t.commonService.validateLLNow(form, t.flags);
      t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      return;
    }

    accountRequestModule.validateLLNow(form, t.flags);
    t.commonService.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
  };

  // trigger email code to be sent by Email
  doLimitedLogin(form){
    t.flags.askForCodeError = null;
    t.flags.accessCode = null;
    t.flags.extraMessage = null;
    t.commonService.setLocalStorage('anl-UB3-LLF', form);
    // if (t.flags.reactivateAccount && !t.flags.isReactivateSurvey){
    var f = t.flags;
    if (f.reactivateAccount || f.isReactivateSurvey || f.needNewAccount || f.allocReq){
      t.commonService.limitedLogin(form, scope, function(LLState){
            if (LLState.error && (LLState.error != '')){
                t.validateLLNow(form);  // validate here again, so that submit button is enabled
                                        // if all fields are complete
                t.flags.askForCodeError = t.flags.submitError;
                if (t.flags.okToSubmit){
                  if (f.reactivateAccount){
                    t.flags.extraMessage = LLState.error;
                  }else{
                    t.flags.extraMessage = "Please contact account support for help.";
                    if (LLState.beError){
                        t.flags.extraMessage += (' ' + LLState.beError);
                    }
                    if (LLState.error){
                        t.flags.extraMessage += (' ' + LLState.error);
                    }
                  }
                }
                return;
            }
            // we are here because email code was just sent by email to the user
            var obj = t.commonService.objCopy(form);
            obj.LLState = LLState;
            t.stage = obj.stage = 2;
            t.commonService.setLocalStorage('anl-UB3-LLF', obj);
      });
      return;
    }
  }

  verifyEmailCode(code) {
    t.commonService.onStopCalling(700, function(){
      var f = t.flags;
      if (f.reactivateAccount || f.isReactivateSurvey || f.needNewAccount || f.allocReq){

        limLoginModule.verifyEmailCode(code, t.flags.codeLength, t.user.action, t.http, t.cookies, function(output){

            t.flags.accessCode = output.cleanCode;
            t.validateLL(t.LLF);

            t.flags.emailCodeVerified = output.emailCodeVerified;
            if (output.emailCodeVerified){

                // update cookie
                var obj = t.commonService.getLocalStorage('anl-UB3-LLF');
                obj.emailCodeVerified = true;
                obj.askForCode = false;
                obj.securityQuestion = output.securityQuestion;
                obj.stage = output.stage;
                t.commonService.setLocalStorage('anl-UB3-LLF', obj);

                t.flags.LLStage = output.LLStage;
                t.flags.askForCodeError = output.askForCodeError;
                t.flags.emailCodeVerified = output.emailCodeVerified;
                t.flags.level = output.level;
                t.flags.readError = output.readError;

                t.stage = output.stage;
                t.user.securityQuestion = output.securityQuestion;
                if (f.allocReq){
                  t.commonService.setUser(t, function(){
                    t.stage = scope.flags.level = 6; // all done
                    if (t.after){
                        t.after(t.LLF);
                    }
                  });
                }
            }else{
                // . network error or similar problem
                //
                if (output.askForCodeError && (output.askForCodeError.indexOf('Click on the') >= 0)){
                    t.stage = 1;
                }
                if (output.askForCodeError && (typeof(output.askForCodeError) == 'string')) {
                    t.flags.askForCodeError = output.askForCodeError;
                }
                if (output.askForCodeError && output.askForCodeError.error){
                    t.flags.askForCodeError = output.askForCodeError.error.detail;
                }
                t.flags.accessCode = ''; // make user type the code again
            }
        }); 
        return;
      }
    });
  };

  createQA(form){
    var p;
    scope.desiredUserName = form.userName ;
    p = {securityQuestion: form.securityQuestion, securityAnswer: form.securityAnswer};
    scope.flags.saving = true;
    scope.flags.QAsaved = false;
    scope.flags.Aerror = null;
    t.http.post("/limited/createQuestionAnswer", p)
        .success(function(resp){
            if (resp.success){
                scope.flags.QAsaved = true;
                t.commonService.setUser(t, function(){

                    t.http.post("/limited/verifySecurityAnswer", p)
                          .success(function(r2){
                                if (r2.success){
                                    t.commonService.setUser(t, function(){
                                        t.stage = scope.flags.level = 6; // all done
                                        if (t.after){
                                            t.after(t.LLF);
                                        }
                                    });
                                }else{
                                    scope.flags.Aerror = r2.error;
                                }
                           });
                });
                
            }else{
                var friendlyMessage = "Limited Login session could not be created, possible network failure, or db down error: Network error, please try again.";
                scope.flags.Aerror = friendlyMessage + ' ' + resp.error.message ;
            }
            scope.flags.saving = false;
        })
        .error(function(msg){
            if (msg.data && msg.data.detail){
                if (msg.status == 403){
                    msg.data.detail = 'Your session has expired. Please select "Cancel account request", and try again.'
                }
                scope.flags.readError = msg.data.detail;
            }
            if (t.after){
                t.after(t.LLF);
            }
        });
  }

  securityAnswer(event){
    var errorInput = event.target;
    if(event.target.value.length > 0){
            t.removeInputErrors(errorInput);
            // event.target.parentNode.children[2].children[1].classList.remove('has-error');
            // $scope.scrollToHasError();
    }
  }

  removeInputErrors(errorInput){
    var errorInputParentsArr, i, val;
    errorInputParentsArr = [];
    while (errorInput) {
                errorInputParentsArr.unshift(errorInput);
                errorInput = errorInput.parentNode;
                if(errorInputParentsArr.length == 3){
                    break;
                }
    }
    for (i in errorInputParentsArr){
        val = errorInputParentsArr[i];
        if (val.classList.contains('error-section')){
                    val.classList.remove('error-section');
        } 
    }
    /*
    angular.forEach(errorInputParentsArr,function(val,key){
                if(val.classList.contains('error-section')){
                    val.classList.remove('error-section');
                } 
    });
    */
  }

  verifySecurityAnswer(answer,event) {
    // this timeout is throwing an error, commenting out.  Seemed to be inherited bad code
    /*
    setTimeout(function(){
        if(event.target.parentNode.children[2].children[1].value.length < 3 && t.flags['Aerror']){
            event.target.parentNode.children[2].children[1].classList.add('has-error');
            t.scrollToHasError();
        }          
    },500);
    */
    //accountRequestModule.overWriteScope(scope);

    if (t.flags.reactivateAccount || t.flags.isReactivateSurvey){

        t.commonService.verifySecurityAnswer(scope, answer, function(){
          // we get here after backend confirms that security answer is correct
          t.stage = 6;
          accountRequestModule.getLists(t.lists);

          if (t.after)
            t.after(t.LLF);  // call callback if it was provided
        });
        return;
    }

    accountRequestModule.verifySecurityAnswer(answer, function(){
        // we get here after backend confirms that security answer is correct
        accountRequestModule.getLists(t.lists);
        t.stage = 6;
        if (t.after)
          t.after(t.LLF);  // call callback if it was provided
    });
  }

  scrollToHasError(callback){
    t.commonService.scrollToHasError(t, callback);
  }

  cancelAccountRequest(){
    accountRequestModule.delSaveForLater(function(){
        t.commonService.signOut(0, t, false);
    });
  }

  cancelAccountReactivation = function(){
        t.commonService.signOut(null, scope);
  }
  cancelAllocRequest = function(){
    allocRequestModule.cancelAllocRequest(scope);
    t.flags.displayARForm = scope.flags.displayARForm;
  }

}


