
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import { manageProjectModule } from 'manageProjectModule';
import {editRecordModule} from 'editRecordModule';

let t = null;
let http = null;
let cookies = null;
let ub3consoleLog = null;
let comMod = null;

@Component({
  selector: 'project-management-edit',
  templateUrl: './project-management-edit.component.html',
  styleUrls: ['./project-management-edit.component.css']
})
export class ProjectManagementEditComponent implements OnInit {
  lists= {
    pendingStatus: false,
    accountInactivateStatus: false,
    projectAccounts: null,
    matchedUsers: null,
    pjaIndex: null,
    projectAllocations: null
  };
  flags= {
    displayedPageName: '',
    ok2Submit: false,
    changedProjectDetails: false,
    isYourGroupOpen: false,
    paError: false,
    pjaOkToSave: false,
    filterStr: '',
  };
  form = {
    projectShortName: '',
    status: '',
    piFirstName: '',
    piLastName: '',
    piPreferredName: '',
    piEmail: '',
    projectTitle: '',
    fundingAgency: '',
    industry: '',
    projectInstitutions: null,
    otherSystems: '',
    fieldOfStudy: null,
    scienceDescription: '',
    url: '',
    projDescription: ''
  };
  pjNameInUrl = null;
  previousAccounts = [];
  currentAccounts = [];
  authAlerts = [];
  confirmSaveChecked = false;
  confirmBtn = false;
  alerts = [];
  projDataShow = false;
  AddnlProjDetails = false;
  projDataDescShow = false;
  fieldErrors = {};
  arrayFieldErrors = {};
  hasChanges = false;
  showSpinner = true;
  publicPage(b) { };

  constructor(
    private commonService: CommonService,
    public h: Ub3httpService,
    public cookie: CookieService,
    private router: Router
  ) {
    t = this;
    http = h;
    cookies = cookie;
    t.flags.displayedPageName = 'Project Management';
    comMod = t.commonService;
    ub3consoleLog = comMod.ub3consoleLog;
    t.fieldErrors = comMod.getSharedObject('warning', 'fieldErrors');
    t.arrayFieldErrors = comMod.getSharedObject('warning', 'arrayFieldErrors');
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    t.pjNameInUrl = null;
    t.lists.projectAllocations = [];
    t.initManageProject(t.flags.displayedPageName);
    var urlParams = t.commonService.urlSearchParamsArray();
    t.pjNameInUrl = urlParams && urlParams.get('name');
    comMod.doWhenAPICallsDone(function(){
        t.initManageProjectInfo(t.pjNameInUrl, 'edit');
        comMod.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
    });
  }

  initManageProject(pageName) {
    t.flags.displayedPageName = pageName;
    manageProjectModule.init(t, http, http, t.lists, t.flags, pageName, function(lst){
      comMod.objCopyGuts(lst, t.lists);
      t.activeProxyProjects = t.lists.proxyProjects && t.lists.proxyProjects.filter(function(pj){
        return pj.projectStatus === 'Active';
      });
      t.showYourProjects();
    });
  }
  showYourProjects(){
    t.currentProxyProjects = (t.isProjActive) ? t.activeProxyProjects : t.lists.proxyProjects;
  }

  initManageProjectInfo(pjName, newOrEdit){
    t.hasChanges = false;
    t.previousAccounts = [];
    t.currentAccounts = [];
    t.authAlerts = [];
    t.flags.ok2Submit = false;
    t.form.projectShortName = pjName;
    manageProjectModule.setProjectDetails(pjName, newOrEdit, http, t.form, t.flags, t.lists, cookies, function(){
      t.previousAccounts = t.lists;
      comMod.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
      window.scrollTo(0,0);
      t.showSpinner = false;
    });
  }
  FindError(){
    var errorInputElement = document.querySelectorAll('.has-error');
    if(errorInputElement && errorInputElement.length > 0){
        t.confirmSaveChecked = false;
        t.confirmBtn = false;
    }
  }
  removeInputErrors(errorInput){
    var errorInputParentsArr = [];
    while (errorInput) {
                errorInputParentsArr.unshift(errorInput);
                errorInput = errorInput.parentNode;
                if(errorInputParentsArr.length == 3){
                    break;
                }
    }
    errorInputParentsArr.forEach(function(val,key){
        if(val.classList.contains('error-section')){
            val.classList.remove('error-section');
        }
    });
  }
  validateInput(fieldName, event, ind){
    t.FindError();
    var errorInput = event.target;
    var fieldID = fieldName+''+ind;
    t.confirmSaveChecked = false;
    t.confirmBtn = false;
    t.alerts = [];
    if(ind){
        if(errorInput.id == fieldID && errorInput.value.length > 0){
           t.removeInputErrors(errorInput);
        }
    }
    else{
        t.removeInputErrors(errorInput);
    }
  }
  searchAccInstn = function(text$: Observable<string>){
    return text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(function(term){
          return t.lists.institutions.filter(function(item){
            return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          }).map(function(inst) {
            return (inst.id, inst.name);
          });
        })
      );
  }

  selectPrimaryInstitution(row, array){
    if (!row || !row.affiliation || (row.affiliation == '')){
        return;
    }
    array.forEach(function(b){
        b.primaryInstitution = (row === b);
    });
  };
  validatePageDelayed(){
    comMod.onStopCalling(700, function(){
      t.validatePageNow();
    });
    
  }
  validatePageNow(){
    comMod.objCopyGuts({}, t.arrayFieldErrors);
    comMod.objCopyGuts({}, t.fieldErrors);
    t.validateProjectManagment();
    if(t.form.projectInstitutions){
      t.form.projectInstitutions = comMod.uniqueUAInstitutions(t.form.projectInstitutions);
      t.validateInstitution(function(){
        comMod.objCopyGuts(t.flags.arrayFieldErrors, t.arrayFieldErrors);
      });
    }
    t.checkForFieldWarnings(t.flags);
  }
  // validation for simple fields (non-array fields)
  validateProjectManagment(){
    manageProjectModule.validateProjectManagment(t.form, t.flags);
    comMod.objCopyGuts(t.flags.fieldErrors, t.fieldErrors);
  };

  // validation for one institution
  validateInstitution(callback) {
    var formName = 'accountInstitutions';
    t.flags.origFormName = 'projectInstitutions';
     t.form.projectInstitutions.forEach(function(rowObj){
      comMod.validateList(formName, rowObj, rowObj.id, t.flags, t.form);
    });
    callback();
  };

  checkForFieldWarnings(flags){
    var arrFldErrors = false;
    var fldErrors = false;
    if(flags.arrayFieldErrors && flags.arrayFieldErrors.accountInstitutions){
      var accInst = flags.arrayFieldErrors.accountInstitutions;
      if(Object.keys(accInst).length > 0){
        Object.keys(accInst).forEach(function(item){
          if(accInst[item].hasOwnProperty('affiliation')){
            arrFldErrors = true;
            return;
          }
        });
      }
    }
    if(flags.fieldErrors){
      var fefa = flags.fieldErrors.fundingAgency;
      var fept = flags.fieldErrors.projectTitle;
      if((fefa && fefa.error)||(fept && (fept.error || fept.dispRequired))) {
        fldErrors = true;
      }
    }
    t.hasChanges = flags.dataThatChanged && Object.keys(flags.dataThatChanged).length > 0;

    // Save button should be displayed only if there are changes with no warning messages on the form
    flags.ok2Submit = (!(arrFldErrors || fldErrors)) && t.hasChanges;
  }

  confirmDelete(arr, obj){
    obj.status = 'Deleted';
    if(obj.method === 'post'){
        var index = arr.indexOf(obj);
        arr.splice(index, 1);
        delete t.flags.arrayFieldErrors['accountInstitutions'][obj.id];
    }
    t.validatePageNow();
  };

  accountAddItem(type){
      editRecordModule.addItem(t.form, t.flags, type);
  };

  editProjectDetails(action){
      t.flags.ok2Submit = false;
      t.authAlerts = [];
      manageProjectModule.editProjectDetails(http, t.form, t.flags, t.lists,  cookies, t.authAlerts, action);
  };

  managementViewDetails(name){
      if(name === "Project Description"){
          t.projDataDescShow = !t.projDataDescShow;
      }

      if(name === "project Details") {
          t.projDataShow = !t.projDataShow;
      }
  }

  checkProxyChanges(){
      t.authAlerts = [];
      manageProjectModule.checkProxyChanges(t.flags, t.lists);
  };

  approveUser(pjName,obj){
      t.authAlerts = [];
      manageProjectModule.approveUser(pjName, http, cookies, t.lists, t.flags, obj, t.authAlerts);
  };

  rejectUser(pjName,obj){
      t.authAlerts = [];
      manageProjectModule.rejectUser(pjName, http, cookies, t.lists, t.flags, obj, t.authAlerts);
  };

  deleteUser(pjName,obj){
      t.authAlerts = [];
      manageProjectModule.deleteUser(pjName, http, cookies, t.lists, t.flags, obj, t.authAlerts);
  };

  cancelProxyChanges(pjName){
      manageProjectModule.cancelProxyChanges(pjName, http, cookies,  t.lists, t.flags);
  };

  updatePiProxies(pjName){
      t.authAlerts = [];
      var proxyAlerts = [];
      manageProjectModule.updatePiProxies(pjName, http, cookies,  t.lists, t.flags, proxyAlerts);
      comMod.doWhenAPICallsDone(function(){
        t.authAlerts = comMod.removeDuplicates(proxyAlerts);
      });
  };

  addUser(pjName, row){
      t.authAlerts = [];
      manageProjectModule.addUser(pjName, row, http, cookies, t.flags, t.lists, t.authAlerts);
  };

  matchUsers(str, allAccounts){
      comMod.matchUsers(str, allAccounts, t);
  };

  getInstitutionId(name){
      return comMod.getInstitutionId(name, t.lists);
  };
  displayMemberStatus(userObj, acctStatus){
      return comMod.displayMemberStatus(userObj, acctStatus);
  };
  closeAlert = function(index){
    if (t.authAlerts){
      t.authAlerts.splice(index, 1);
    }
  }

}