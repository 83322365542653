import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {validationModule} from 'validationModule';
import {domainAdminModule} from '../../../../../../../../frontend/domainAdminModule';
import {projectAdminModule} from '../../../../../../../../frontend/projectAdminModule';
import {editRecordModule} from '../../../../../../../../frontend/editRecordModule';
import {CookieService} from 'ngx-cookie-service';
import {CommonService, Ub3httpService} from '@common/services';

let http: any = null;
let cookies: any = null;
let t = null;
let createOthFunctions = null;
let ub3consoleLog = null;
let currentPath = null;

@Component({
  selector: 'user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {
  flags:any  = {
    allowEdit:{},
    filterProjectMembershipRequest: {}
  };
  lists = {
    userRecords: [],
    uRecsOut: [],
    accountRequests: [],
    accReqsOut: [],
    reactivationRequests: [],
    loadingUserRequests: false,
    savedLists: {}
  };
  form = {searchName: null};
  domainAdminDashboardAlerts = [];
  openPIRejectModal = false;
  okToProceed = false;
  filteredCount = null;
  donePaging1=false;
  donePaging2=false;
  acReqCount=0;
  warningUser = null;
  
  // this one is used in ub3tbl component
  uat1titles = [
    {id:"userName"            ,label:"Username"},
    {id:"dispName"            ,label:"Name"},
    {id:"primaryEmail"        ,label:"Preferred Email"},
    {id:"alternateEmail"      ,label:"Alternate Email"},
    {id:"accountType"         ,label:"Account Type"},
    {id:"accountCategory"     ,label:"Account Category"},
    {id:"affiliations"        ,label:"Affiliation"},
    {id:"ack"                 ,label:"User Ack", sort:false, doFilter:false},   // for sort & doFilter, default is true
    {id:"status"              ,label:"Account Status"},
    {id:"deactivation_date"   ,label:"Deactivation Date"},
    {id:"usCitizen"           ,label:"US Citizen"},
    {id:"favorExpirationDate" ,label:"593 Expiration Date"},
    {id:"favorStatus"         ,label:"593 Status"},
    {id:"validationDate"      ,label:"User Updated"},
    {id:"comment"             ,label:"Comment"},
  ];
  admAcctRequestTitles = [
    {id:"userName"            ,label:"User name"},
    {id:"dispName"            ,label:"Name"},
    {id:"dateRequested"       ,label:"Date Requested", doFilter:false},
    {id:"accountType"         ,label:"Accout Type"},
    {id:"institutions"        ,label:"Affiliation"},
    {id:"projectShortNameList",label:"Projects"},   // for sort & doFilter, default is true
    {id:"projectPIList"       ,label:"Project PI"},
    {id:"projectMemberStateList",label:"Project Membership Status"},
    {id:"usCitizen"           ,label:"US Citizen"},
    {id:"expirationDate593"   ,label:"593 Expiration Date"},
    {id:"status593"           ,label:"593 Status"},
    {id:"comment"             ,label:"Comment"},
    {id:"ms_state_change"     ,label:"Account Status"},
  ];

  @ViewChild('admAcctRequest') accTable: any;

  constructor(
    private router: Router,
    private c: CookieService,
    h: Ub3httpService,
    private window: Window,
    private commonService: CommonService
  ) {
    t = this;
    cookies = c;
    http = h;
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.flags = t.commonService.getSharedObject('user-admin', 'flags');
    t.flags.allowEdit = {};
    t.lists = t.commonService.getSharedObject('user-admin', 'lists');
    createOthFunctions = t.commonService.getSharedObject('create-other-accounts', 'functions');
  }

  ngOnInit(): void {
    document.body.classList.remove('public-page');
    currentPath = window.location.hash.replace('#/','');
    domainAdminModule.init(t, 'User Administration', Promise);
    t.commonService.doWhenAPICallsDone(function(){
        t.acReqCount = t.lists.accountRequests.length;
    });

    t.lists.accReqsOut= [];
    t.lists.uRecsOut = [];
    t.flags.editRecord = false;
    t.flags.isOnFocus = false;
    t.flags.allowEdit = {};
    if(!t.flags.filterProjectMembershipRequest) {
      t.flags.filterProjectMembershipRequest = {};
    }
    t.setCheckBoxesDefaults();
    t.clearFilter(this.flags);
    this.form.searchName = null;
  }
  setCheckBoxesDefaults(){
        t.flags.show= { // section default show/hide settings
            search   :true,
            acReq    :false,
            pjMemReq :false,
            reactReq :false
        };
  }
  clearFilter(flags){
    flags.filter = {
      acReq: {
        userName:'',
        dispName:'',
        accountType:'',
        filterAffiliation:'',
        projectShortNameList:'',
        projectPIList:'',
        status593:'',
        comment:'',
      },
      pjMemReq: { 
        userName:'', 
        lastName:'', 
        filterAffiliation:'',
        accountStatus:'',
        projectShortName:'',
        projectStatus:'',
        comment:'',
      },
      reactReq:{
        userName:'',
        dispName:'',
        accountStatus:'',
        projectShortName:'',
        memberStatus:'',
        institution:'',
        usCitizen:'',
        expirationDate593:'',
        status593:'',
        comment:''
      }
    };
  }
  searchByNoEmail(){
    t.adminSearchUser('<<NOEMAILS>>');
  }
  searchUser(event){
    t.adminSearchUser(event.target.value);
  }
  // toggles editing on one row
  allowEdit(userName){
    if (t.flags.allowEdit && t.flags.allowEdit[userName]){
        // its on,  turn it off
        t.flags.allowEdit = {};
    }else{
        // its off,  turn it on
        t.flags.allowEdit = {};
        t.flags.allowEdit[userName] = true;
    }
  }
  checkSearchName(){
    t.domainAdminDashboardAlerts = [];
    if(!t.form.searchName){
      t.lists.userRecords = [];
      t.filteredCount = null;
      t.flags.searchCompleted = false;
      domainAdminModule.getRequests(t.lists, Promise, t.domainAdminDashboardAlerts, null);
      t.setCheckBoxesDefaults();
    }
  }

  comHover(userName, val){
    t.flags['big'] = {};
    t.flags['big'][userName] = val;
  }
  adminSearchUserAndSave(str){
    t.adminSearchUser(str, function(){
      t.lists.savedLists = {
        accountRequests           : JSON.parse(JSON.stringify(t.lists.accountRequests)),
        projectMembershipRequests : JSON.parse(JSON.stringify(t.lists.projectMembershipRequests)),
        reactivationRequests      : JSON.parse(JSON.stringify(t.lists.reactivationRequests))
      };
    });
  }
  async adminSearchUser(str, callback){
    t.donePaging1 = false;
    t.setCheckBoxesDefaults();
    t.clearFilter(t.flags);
    t.lists.uRecsOut = [];
    await domainAdminModule.adminSearchUserNow(http, t.flags, t.lists, str.trim(), t.domainAdminDashboardAlerts);
    await domainAdminModule.get4reqTypes(t.lists, Promise, t.domainAdminDashboardAlerts, function(){
      t.acReqCount = t.lists.accountRequests.length;
    });
    if(callback){          
      callback();
    }
  }
  goToPjEdit(pjName){
    t.router.navigate(['/projectEdit'], {queryParams: {name: pjName, opt: 'edit'}});
  }

  adminValidate(name){
    t.domainAdminDashboardAlerts = [];
    domainAdminModule.validate(http, setTimeout, t.form, name, t.flags, t.domainAdminDashboardAlerts, t);
  }

  adminEditRecord(name, status, category){
    if((status && status === "System Created") || (category && (category === 'Test' || category === 'Service'))){
      this.router.navigateByUrl('createOtherAccountsForm?fromPage='+currentPath+'&userName=' + name);
    } else {
      this.router.navigateByUrl('userEdit?userName='+name);
    }
  }
  toggleDisplay(sectionName){
    t.flags.show[sectionName] = !t.flags.show[sectionName];
  }
  onDonePaging(){
        t.donePaging1 = true;
  }
  updateUIcount(origRowCount, filteredRowCount){
        if (!filteredRowCount || (filteredRowCount == origRowCount)){
            t.filteredCount = origRowCount;
            return;
        }
        t.filteredCount = filteredRowCount + '/' + origRowCount ;
  }
  onDonePaging2(){
    t.donePaging2 = true;
  }
  updateUIcount2(origRowCount, filteredRowCount){
    
    if (!filteredRowCount || (filteredRowCount == origRowCount)){
        t.acReqCount = origRowCount;
        return;
    }
    t.acReqCount = filteredRowCount + '/' + origRowCount ;
  }

  domainAdminProjectOption(action, sortedIndex, originalIndex){
    t.domainAdminDashboardAlerts = [];
    domainAdminModule.projectOption(action, t.lists, t.flags, sortedIndex, originalIndex, t.domainAdminDashboardAlerts);
    t.commonService.doWhenAPICallsDone(function(){
      domainAdminModule.selectOption(action, t.lists, t.flags, sortedIndex, 'admProjectMembershipRequest', 'ms_state', t.domainAdminDashboardAlerts);
    });
  }

  domainAdminSelectOption(fldValue, index, tableName, fldName, row){
    if(fldName === 'ms_state' && tableName === 'admAcctRequest'){
      t.flags.showSubmit = false;
//      t.accTable.rowDetail.collapseAllRows(); // sheeja, why was this needed?
      if(fldValue === 'Rejected' && row.projectPIList.includes(row.dispName.split('<br>')[0])){
        t.openPIRejectModal = true;
        t.warningUser = row.userName;
        t.okToProceed = false;
//        t.accTable.rowDetail.toggleExpandRow(row); // sheeja, why was this needed?
        t.flags.piIndex = index;
        return;
      }
    }
    
    t.domainAdminDashboardAlerts = [];
    // turn fldValue to a string, this is what domainAdminModule.selectOption wants
    if (fldName === 'comment' && fldValue === ''){
      row.comment = null;
      fldValue = null;
    }
    if (fldName == 'approver'){
      var pjMemReq, reqObj, i, proxy;
      pjMemReq = t.lists.projectMembershipRequests;

   
      reqObj = pjMemReq.filter(function(item){

      // Check all the proxies for this user

        for(i = 0; i < item.piproxies.length; i++)
        {

          if(item.piproxies[i].piProxy === fldValue)

            return(item.piproxies[i]).piProxy;
        }

      });
      // in this scenario, fldValue is a string, the name of a piProxy
      for (i in reqObj[0].piproxies){
        proxy = reqObj[0].piproxies[i];
        if (proxy.piProxy === fldValue){
          fldValue = JSON.stringify(proxy);

        }
      }
    }
    domainAdminModule.selectOption(fldValue, t.lists, t.flags, index, tableName, fldName, t.domainAdminDashboardAlerts);
  };

  adminRequestAction(requestType, ms_state, id, index){
    var userName;
    if(requestType == 'projectMembershipRequest'){
      var userName = t.flags.filter.pjMemReq.userName;
    }
    if(requestType == 'userRecord'){
      var i, row;
      userName=null;
      for (i in t.lists.userRecords){
        row = t.lists.userRecords[i];
        if (row.accountId == id){
            userName = row.userName;
            break;
        }
      }
    }
    
    var viewSettings = JSON.parse(JSON.stringify(t.flags.show));
    // set all view flags to false, and remember their settings
    // this is to prevent flashing of a large number of users while the data is
    // reloaded from backend and redisplayed
    t.flags.show = {}; //{search:false, acReq:false, pjMemReq:false, reactReq:false};

    domainAdminModule.requestAction(http, cookies, t.flags, t.lists, requestType, ms_state, id, index, t.domainAdminDashboardAlerts, Promise);
    t.commonService.doWhenAPICallsDone(function(){
   
      t.commonService.clearFilterCache();
      clearFilterString(requestType);
      if(t.flags.filterProjectMembershipRequest[userName]){
            if ((requestType == 'projectMembershipRequest') || (requestType == 'userRecord' )){
                t.flags.filterProjectMembershipRequest[userName] = false;

                var temp = t.domainAdminDashboardAlerts;    // save error messages
                t.clickPlusMinus(userName);
                t.domainAdminDashboardAlerts = temp;    // restore error messages
            }
      }

      // do this last step at the end, otherwise lots of flashing
      t.flags.show = viewSettings;  // restore original 
    });
  };

  membershipReminder(username, project){
    t.domainAdminDashboardAlerts = [];
    t.flags.bootstrapAlert = 'admProjectMembershipRequest';
    domainAdminModule.membershipReminder(username, project, t.domainAdminDashboardAlerts, function(){

        // reload data
        domainAdminModule.getPjMemReqs(function(newArray){
            domainAdminModule.setDisplayName(newArray);
            domainAdminModule.addFilterName(newArray);
            t.lists.projectMembershipRequests = [];
            t.commonService.clearFilterCache();
            setTimeout(function(){
                // do this assignement a little later. This will give time to Angular to detect this change
                t.lists.projectMembershipRequests = newArray;
                t.updateFilterNow('pjMemReq', t.lists.projectMembershipRequests, 'projectShortname', 'and', true);
            }, 300);
        });
    });

  }

  adminFilterProjByUserName(userName, clearFilter){
    domainAdminModule.filterByUserName(userName, t.flags, t.lists, clearFilter);
  };

  clickPlusMinus(userName){
    var item;
    t.domainAdminDashboardAlerts = [];
    for(item in t.flags.filterProjectMembershipRequest){
      if(item !== userName){
        t.flags.filterProjectMembershipRequest[item] = false;
      }
    }
    t.flags.filterProjectMembershipRequest[userName] = !t.flags.filterProjectMembershipRequest[userName];
    var openAllSections = t.flags.filterProjectMembershipRequest[userName];
  
    if (!openAllSections && t.form && t.form.searchName && (t.form.searchName != '')){
        //'minus' sign was clicked
        t.adminSearchUser(t.form.searchName);
    } else {
       //'plus' sign was clicked
      t.filterByUserName(openAllSections, userName);
    }
  }

  filterByUserName(enable, userName){
    t.flags.filter.acReq.userName    = (enable ? userName : ''); 
    setTimeout(function(){
        //set this filter by dom element id
        var btn = document.getElementById('admAcctRequest-show-filter');
        if (btn) btn.click();
        setTimeout(function(){
            var fi  = document.getElementById("admAcctRequest-userName-filter")  as HTMLInputElement;
            if (fi) {fi.value = (enable ? userName: '');}
        }, 200);
    }, 200);

    t.flags.filter.pjMemReq.userName = (enable ? userName : '');
    t.flags.filter.reactReq.userName = (enable ? userName : '');

    t.updateFilterNow('acReq'   , t.lists.accountRequests          , 'userName', 'last', enable);
    t.updateFilterNow('pjMemReq', t.lists.projectMembershipRequests, 'userName', 'last', enable);
    t.updateFilterNow('reactReq', t.lists.reactivationRequests     , 'userName', 'last', enable);
  };

  updateFilter(arrName, data, colName, op){
    t.commonService.onStopCalling(700, function(){
      t.updateFilterNow(arrName, data, colName, op, true);
    });
  }

  updateFilterNow(arrName, data, colName, op, enable){
        t.commonService.filter(arrName, data, colName, op, t.flags.filter[arrName]);
        // trigger table repaint
        t.flags.show[arrName] = false;
        setTimeout(function(){
            t.flags.show[arrName] = (enable || (arrName == 'search')) ? true : false;
        }, 100);

        //document.getElementById('filter-'+colName).click(); // this line doesn't seem to do anything
  }

  convertUTCtoCDT(d){
    return t.commonService.convertUTCtoCDT(d);
  }

  initProjectAdminEditForm(pageName, pjName, newOrEdit){
    t.flags['displayedPageName'] = pageName;
    projectAdminModule.init(t, pageName);
    t.commonService.doWhenAPICallsDone(function(){
      projectAdminModule.setupEditForm(pjName, newOrEdit, t.form, t.flags, t.lists);
    });
  }

  autosizeFn(id){
    this.flags.isOnFocus = true;
    var elem = document.getElementById(id);
    elem.style.overflow = 'hidden';
    elem.style.height = elem.scrollHeight + 'px';
  }

  getSmall(id){
    this.flags.isOnFocus = false;
    var elem = document.getElementById(id);
    elem.style.height = 'auto';
  }

  goToTop(){
    window.scrollTo(0,0);
  }

  proceedRejectPI(row, val){
    t.okToProceed = val;
    t.flags.showSubmit = val;
    if(!t.okToProceed){
      row.ms_state_change = row.accountStatus;
    } else {
      t.domainAdminDashboardAlerts = [];
      domainAdminModule.selectOption(row.ms_state_change, t.lists, t.flags, t.flags.piIndex, 'admAcctRequest', 'ms_state', t.domainAdminDashboardAlerts);
    }
    setTimeout(function(){
      t.openPIRejectModal = false;
    }, 100);
  }

}


function clearFilterString(tableName){
  var map, sectionName, dict, key;
  map = {
    'accountRequest' : 'acReq', 
    'projectMembershipRequest': 'pjMemReq',
    'reactivationRequest': 'reactReq',
  };
  sectionName = map[tableName];
  dict = t.flags.filter[sectionName];
  for (key in dict){
    if (dict[key] != '') dict[key] = '';
  }
}
